import React from 'react';
import i18n from '../../i18n';
import { RenderButtons, RenderStatus } from '../../components/ReactTable/renderFunctions';
import { DELETE_INVITED_USER_MODAL_ID } from './constants';

export const getCrumbs = (userId, myRoleType) => (myRoleType === 'end_user' ? [
  { name: i18n.t('settings'), url: '/my-devices' },
  { name: i18n.t('invitedUsers') }
] : [
  { name: i18n.t('SMUs'), url: '/users' },
  { name: i18n.t('settings'), url: `/users/${userId}` },
  { name: i18n.t('invitedUsers') }
]);

export const getColumns = (myRoleType) => [
  {
    Header: i18n.t('fName'),
    accessor: '',
    minWidth: 180,
    sortable: false,
    Cell: ({ original }) => `${original.first_name || ''} ${original.last_name || ''}`
  },
  {
    Header: i18n.t('email'),
    accessor: 'email',
    minWidth: 180,
    sortable: false
  },
  {
    Header: i18n.t('status'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    sortable: false,
    filterable: false
  },
  {
    sortable: false,
    accessor: '',
    width: 70,
    className: '',
    filterable: false,
    show: !['viewer', 'pv_installer_employee_read_only'].includes(myRoleType),
    // eslint-disable-next-line react/prop-types
    Cell: ({ original }) => (
      <div className="btn-cell">
        {RenderButtons(
          original,
          { deleteInvitedUser: true, modalId: DELETE_INVITED_USER_MODAL_ID, title: 'delete' }
        )}
      </div>
    )
  }
];
