import { createSelector } from 'reselect';

const getIsLoading = (state) => state.signUp.isLoading;
const getAllDataLists = (state) => state.dataList;
const getSendSuccessfull = (state) => state.signUp.sendSuccessfull;
const getEmail = (state) => state.signUp.email;
const getVerifyEmail = (state) => state.signUp.verifyEmail;
const getResendEmail = (state) => state.signUp.resendEmail;
const getChangeEmail = (state) => state.signUp.changeEmail;

export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const allDataListsSelector = createSelector([getAllDataLists], (dataList) => dataList);
export const sendSuccessfullSelector = createSelector([getSendSuccessfull], (sendSuccessfull) => sendSuccessfull);
export const emailSelector = createSelector([getEmail], (email) => email);
export const verifyEmailSelector = createSelector([getVerifyEmail], (verifyEmail) => verifyEmail);
export const resendEmailSelector = createSelector([getResendEmail], (resendEmail) => resendEmail);
export const changeEmailSelector = createSelector([getChangeEmail], (changeEmail) => changeEmail);
