import React from 'react';
import { Field } from 'redux-form';
import i18n from '../../../../i18n';
import RadioButtons from '../../../../components/ReduxFormFields/RadioButtons';

export const AccessRole = () => (
  <>
    <div>
      <h6 className="freq-text">
        {i18n.t('access')}
        :
      </h6>
      <Field
        name="role_type"
        component={RadioButtons}
        props={{
          variants: [
            {
              label: <span className="white dataSMID">{i18n.t('accessReadOnly')}</span>,
              value: 'pv_installer_employee_read_only'
            },
            {
              label: <span className="white dataSMID">{i18n.t('accessReadAndWrite')}</span>,
              value: 'pv_installer_employee'
            }
          ]
        }}
      />
    </div>
  </>
);
