import { createSelector } from 'reselect';

const rolesWithCustomLogo = ['pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem'];

const getOverviewData = (state) => state.overview;
const getUser = (state) => state.signIn?.user;

export const overviewDataSelector = createSelector([getOverviewData], (data) => data);
export const visibleWebSelector = createSelector([getUser], (user) => (rolesWithCustomLogo.includes(user?.role?.type)));
export const compLogoSelector = createSelector(
  [getUser],
  (user) => user?.company_logo
);
