import React from 'react';
import PropTypes from 'prop-types';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const styles = ({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontWeight: 'normal',
    fontSize: 13,
    maxWidth: 280
  }
});

/**
 * Renders tooltip
 */
const CustomizedTooltip = (props) => {
  const {
    tooltipInsideRowHeader,
    className,
    customClasses,
    title,
    placement,
    style
  } = props;

  const popperClasses = tooltipInsideRowHeader
    ? `${className} tooltipInsideRowHeader`
    : className;

  return (
    <Tooltip
      title={(
        <React.Fragment>
          {title}
        </React.Fragment>
      )}
      classes={{ popper: popperClasses, ...customClasses }}
      placement={placement}
      enterTouchDelay={0}
      leaveTouchDelay={10000}
    >
      <i className="la la-info-circle" style={style} />
    </Tooltip>
  );
};

CustomizedTooltip.propTypes = {
  tooltipInsideRowHeader: PropTypes.bool,
  className: PropTypes.string.isRequired,
  customClasses: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

CustomizedTooltip.defaultProps = {
  tooltipInsideRowHeader: false,
  placement: 'bottom',
  customClasses: {},
  style: undefined
};

export default styled(CustomizedTooltip)(styles);
