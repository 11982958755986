import {
  RenderDate,
  RenderStatus,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = () => ([
  {
    Header: i18n.t('company'),
    id: 'my_company',
    accessor: (d) => (d.my_company ? d.my_company.name : 'No company'),
    minWidth: 180
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: 160,
    className: ''
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: RenderStatus,
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: RenderDate
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: false,
        editUser: { preLink: '/setting-profile/pv-installer-users/' },
        view: { preLink: '/pv-installers/' },
        delete: true
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
