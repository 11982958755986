import * as actionTypes from './constants';

export function rootSignIn(userId) { // epic
  return {
    type: actionTypes.ROOT_SIGN_IN,
    payload: {
      userId
    }
  };
}
