import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SwipeableViews from 'react-swipeable-views';
import SignUpCompany from './SignUpCompany';
import SignUpUser from './SignUpUser';
import ChangeEmail from './ChangeEmail';
import Logo from '../../components/Logo';
import i18n from '../../i18n';
import { LanguageSelector } from '../../components/UIKit';
import Footer from '../../components/Footer';
import Preloader from '../../components/Preloader';
import { isLoadingSelector } from './selectors';
import CreatingNewUserNoteWindow from '../../components/CreatingNewUserNoteWindow';
import { openModalWindow, closeModalWindow } from '../ModalWindow/actions';
import { CREATING_NEW_USER_NOTE_ID } from '../../components/CreatingNewUserNoteWindow/constants';

import '../../App.css';
import './index.scss';

/**
 * @description renders SignUp page depending on tab
 * @memberof module:SignUp
 * @extends Component
 */

const SignUpLayout = (props) => {
  const {
    sendSuccessfull,
    sentEmails,
    resendEmail,
    actions,
    changeEmail,
    companies,
    sm_id,
    email,
    onSubmitChangeEmail,
    onSubmitUser,
    openQRScanner,
    onSubmitCompany,
    isLoading,
    closeModalWindow: closeModal,
    openModalWindow: openModal,
    t
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * redirect to some tab
   * @param {number} num - number of tab
   */
  const goToTab = useCallback(
    (num) => {
      if (num === 1) {
        openModal(CREATING_NEW_USER_NOTE_ID, null);
      } else {
        setCurrentIndex(num);
      }
    },
    [openModal]
  );

  const toggleCreatingNewUserNoteModal = useCallback(
    (num) => {
      setCurrentIndex(num);
      closeModal(CREATING_NEW_USER_NOTE_ID);
    },
    [closeModal]
  );

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <CreatingNewUserNoteWindow
        modalID={CREATING_NEW_USER_NOTE_ID}
        toggleModal={toggleCreatingNewUserNoteModal}
      />
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div
          className={`m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center ${sendSuccessfull ? 'display-none' : ''}`}
        >
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-6 col-lg-8 col-md-10 col-sm-10">
                {isLoading ? (
                  <Preloader />
                ) : (
                  <>
                    <div className="m-login__head">
                      <h3 className="m-login__title">{i18n.t('creatingAccbtn')}</h3>
                    </div>
                    <div className="smu_pvi">
                      {changeEmail ? (
                        <div className="container-fluid">
                          <ChangeEmail
                            enableReinitialize
                            onSubmit={onSubmitChangeEmail}
                            initialValues={{
                              old_email: sentEmails.email
                            }}
                            t={t}
                          />
                        </div>
                      ) : (
                        <div className="container-fluid">
                          <div className="">
                            <ul className="nav nav-pills nav-fill nav-tabs-items" role="tablist">
                              <div className="nav-item" onClick={goToTab.bind(this, 0)}>
                                <a
                                  className={
                                    currentIndex === 0
                                      ? 'nav-link tab-heading active'
                                      : 'nav-link tab-heading'
                                  }
                                >
                                  {i18n.t('SMUcaps')}
                                </a>
                              </div>
                              <div className="nav-item" onClick={goToTab.bind(this, 1)}>
                                <a
                                  className={
                                    currentIndex === 1
                                      ? 'nav-link tab-heading active'
                                      : 'nav-link tab-heading'
                                  }
                                >
                                  {i18n.t('PVIcaps')}
                                </a>
                              </div>
                            </ul>
                          </div>
                          <SwipeableViews
                            index={currentIndex}
                            onChangeIndex={goToTab.bind(this)}
                          >
                            <div>
                              <SignUpUser
                                enableReinitialize
                                initialValues={{ sm_id, email }}
                                companies={companies}
                                onSubmit={onSubmitUser}
                                openQRScanner={openQRScanner}
                                t={t}
                              />
                            </div>
                            <div>
                              <SignUpCompany onSubmit={onSubmitCompany} t={t} />
                            </div>
                          </SwipeableViews>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* begin thank you page */}
        <div
          className={`m-grid__item m-grid__item--fluid thank-you-cont row align-items-center ${sentEmails?.email && sendSuccessfull ? '' : 'display-none'}`}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-sm-8">
                <h1>{i18n.t('thank')}</h1>
                <p>
                  {resendEmail ? (
                    <Fragment>{i18n.t('resentingMail')}</Fragment>
                  ) : (
                    <Fragment>{i18n.t('sentingMail')}</Fragment>
                  )}
                  {' '}
                  <span className="thank_you_email">
                    {sentEmails?.email}
                  </span>
                  .
                  <br />
                  {i18n.t('activateLink')}
                </p>
                <p className="mail-links">
                  {!resendEmail && (
                    <Fragment>
                      <button type="button" onClick={actions.resendToEmail}>
                        {i18n.t('resendMessage')}
                      </button>
                    </Fragment>
                  )}
                  {!changeEmail && !resendEmail && <Fragment>&emsp;or&emsp;</Fragment>}
                  {!changeEmail && (
                    <a onClick={actions.changeEmailAction}>{i18n.t('changeYmail')}</a>
                  )}
                </p>
                <p className="warning-par">
                  {i18n.t('adviceMakeSure')}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end thank you page */}
        <Footer />
      </div>
    </div>
  );
};

SignUpLayout.propTypes = {
  sendSuccessfull: PropTypes.bool,
  sentEmails: PropTypes.instanceOf(Object).isRequired,
  resendEmail: PropTypes.bool,
  actions: PropTypes.instanceOf(Object).isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  changeEmail: PropTypes.func,
  companies: PropTypes.instanceOf(Array).isRequired,
  sm_id: PropTypes.string,
  email: PropTypes.string,
  onSubmitChangeEmail: PropTypes.func.isRequired,
  onSubmitUser: PropTypes.func.isRequired,
  openQRScanner: PropTypes.func.isRequired,
  onSubmitCompany: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  t: PropTypes.instanceOf(Object)
};

SignUpLayout.defaultProps = {
  sendSuccessfull: undefined,
  resendEmail: undefined,
  changeEmail: undefined,
  sm_id: undefined,
  email: undefined,
  isLoading: false,
  t: undefined
};

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps, { openModalWindow, closeModalWindow })(SignUpLayout);
