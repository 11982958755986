import { ReactTableDefaults } from 'react-table';
import { translate } from 'react-i18next';
import i18n from '../../i18n';
/**
 * Sets default parameters for react-table.
 * @memberof module:ReactTable
 */
const TableDefaultSettings = () => {
  Object.assign(
    ReactTableDefaults, {
      minRows: 1,
      showPagination: false,
      resizable: true,
      getLoadingProps: () => ({ className: 'tableLoadingBG' }),
      getNoDataProps: (params) => (params.loading ? { style: { display: 'none' } } : { className: 'customNoData' }),
      noDataText: i18n.t('noRowsFound'),
      loadingText: `${i18n.t('load')}...`
    }
  );
  return null;
};

export default translate('')(TableDefaultSettings);
