import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import i18n from '../../../i18n';
import { isLoadingSelector, modalDataSelector } from './selectors';
import { getSubSensorsFromSensors } from '../../UserDetails/selectors';
import { addNotification } from '../../NotificationGenerator/actions';

import './index.css';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * Modal window to confirm deleting device
 * @memberof module:UserDevices
 */
const DeleteDeviceWindow = (props) => {
  const { handleOnClose, onSubmit, data, modalID, isLoading, subSensors,
    addNotification: addNotificationFromProps
  } = props;

  const deleteDeviceHandler = useCallback(
    () => {
      const isSubSensor = subSensors?.some((sensor) => sensor.id === data.id);
      if (!isSubSensor && subSensors?.length) {
        handleOnClose();
        addNotificationFromProps({
          type: 'error',
          text: i18n.t('subMetersExistError')
        });
      } else {
        onSubmit({ id: data.id });
      }
    }, [addNotificationFromProps, data.id, handleOnClose, onSubmit, subSensors]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="delete-device-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('confirmDelDevice')}</div>
            <div className="text-confirmation-detailed">{i18n.t('deleteDeviceWarning')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              type="button"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              onClick={deleteDeviceHandler}
            >
              <i className="fa fa-trash" />
              {' '}
              {i18n.t('delete')}
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

DeleteDeviceWindow.propTypes = {
  modalID: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
  subSensors: PropTypes.instanceOf(Array),
  onSubmit: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool
};

DeleteDeviceWindow.defaultProps = {
  modalID: '',
  subSensors: undefined,
  data: {},
  isLoading: false
};

const mapStateToProps = createStructuredSelector({
  data: modalDataSelector,
  subSensors: getSubSensorsFromSensors,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps, { addNotification })(DeleteDeviceWindow);
