/**
 * Array of languages to select in guests pages
 * @ignore
 */
export const options = [
  {
    value: 'english',
    label: 'English'
  },
  {
    value: 'deutsch',
    label: 'Deutsch'
  },
  {
    value: 'french',
    label: 'French'
  },
  {
    value: 'italian',
    label: 'Italian'
  },
  {
    value: 'danish',
    label: 'Danish'
  }
];
