import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PuffLoader from 'react-spinners/PuffLoader';
import {
  email,
  required,
  parseToLowerCase
} from '../../../validation';
import CustomTextInput from '../../../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../../../ModalWindow';
import i18n from '../../../../i18n';
import { closeModalWindow } from '../../../ModalWindow/actions';
import { INVITE_MODAL_ID } from '../../constants';
import { getInviteUserModalSelector, getCurrentFormValueSelector, getInviteUserLoadingSelector } from '../../store/selectors';
import { fetchInviteUser } from '../../store/actions';
import './index.scss';
import CancelButton from '../../../../components/UIKit/CancelButton';

const InviteUserModal = ({
  fetchInviteUser: fetchInviteUserAction,
  closeModalWindow: closeModalWindowAction,
  pristine,
  modal,
  reset,
  invitedUserEmail,
  invalid,
  userId,
  isLoading
}) => {
  const handleOnClose = () => closeModalWindowAction(INVITE_MODAL_ID);
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchInviteUserAction(invitedUserEmail, userId);
  };

  useEffect(() => {
    if (!modal.opened) reset();
  }, [modal.opened]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow modalID={INVITE_MODAL_ID}>

      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('inviteUser')}</h5>
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <PuffLoader
            size={50}
            color="#f7bd27"
            speedMultiplier={3}
          />
        </div>
      )
        : (
          <>
            <div className="modal-body">
              <form
                id={INVITE_MODAL_ID}
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
              >
                <div className="form-group m-form__group input-field">
                  <Field
                    name="email"
                    component={CustomTextInput}
                    label={`${i18n.t('email')} *`}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, email]}
                    parse={parseToLowerCase}
                    preventAuto
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={INVITE_MODAL_ID}
                disabled={pristine || invalid}
                type="submit"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              >
                <i className="fa fa-paper-plane" />
                {' '}
                {i18n.t('sendInvite')}
              </button>
            </div>
          </>
        )}
    </ModalWindow>
  );
};

InviteUserModal.propTypes = {
  closeModalWindow: PropTypes.func.isRequired,
  fetchInviteUser: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  invitedUserEmail: PropTypes.string,
  invalid: PropTypes.bool,
  userId: PropTypes.string,
  isLoading: PropTypes.bool
};

InviteUserModal.defaultProps = {
  modal: { opened: false },
  invitedUserEmail: null,
  invalid: false,
  userId: null,
  isLoading: false
};

const form = reduxForm({
  form: 'inviteEmailForm'
})(InviteUserModal);

const mapStateToProps = createStructuredSelector({
  modal: getInviteUserModalSelector,
  invitedUserEmail: getCurrentFormValueSelector,
  isLoading: getInviteUserLoadingSelector
});

export default connect(mapStateToProps, { closeModalWindow, fetchInviteUser })(form);
