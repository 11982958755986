/* eslint-disable */
import React, { Fragment, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box } from '@mui/material';
import i18n from '../../../../../i18n';
import Tooltip from '../../../../../components/Tooltip';
import {
  isSupportContractFlagAllowedToBeChangedSelector,
  userIdSelector,
  userSubscriptionSelector
} from '../../../selectors';
import { redirectToPaymentPage } from '../../../../Payments/actions';
import { getColumns } from './columns';
import { ItemsList } from '../ItemsList';

/**
 * User info part.
 * @memberof module:UserDetails
 * @param {UserObject} user User Object
 */
const UserInfo = ({
  user,
  myRoleType,
  userSubscription,
  redirectToPayment,
  currentUserDeviceId
}) => {
  const { currentProductVersion, webViewUrl } = userSubscription || {};
  const { product } = currentProductVersion || {};

  const redirectToPaymentHandler = useCallback(() => redirectToPayment(currentUserDeviceId, webViewUrl), [currentUserDeviceId, redirectToPayment, webViewUrl]);
  const columns = useMemo(() => getColumns({ user, myRoleType, product, redirectToPaymentHandler }), [myRoleType, product, user]);
  const isEndUser = 'end_user' === myRoleType
  const settingProfileUrl = isEndUser ? '/setting-profile/' : `/setting-profile/solar-managers-users/${user._id}/`;

  return (
    <>
      <Box className="main-info tab-wrapper common-info" sx={{ p: 3, pr: 10 }}>
        {user?._id && (
          <div className="user-info-link">
            <Link
              to={settingProfileUrl}
              title={i18n.t('editTooltip')}
              className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
            >
              <i className="la la-edit" />
            </Link>
          </div>
        )}
        <Box className='main-content'>
          <ItemsList columns={columns} />
        </Box>
      </Box >
      <Box className='tab-wrapper main-info doc-section' sx={{ p: 3, pr: 8 }}>
        <h4 className="list-item-title main-title">
          {i18n.t('documentationTitle')}
          <Tooltip title={i18n.t('documentationTooltip')} />
        </h4>
        {user?._id && (
          <>
            <div className="user-info-link">
              <Link
                to={`/documentation/${user._id}`}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
                title={i18n.t('editTooltip')}
              >
                <i className="la la-external-link" />
              </Link>
            </div>

            <ul className='list flex'>
              <li className="list-item">
                <h4 className="list-item-title">
                  {i18n.t('uploadedFiles')}:
                </h4>
              </li>
              <li>
                <p className='count'>{user.uploadedFiles || 0}</p>
              </li>
            </ul>

          </>
        )}
      </Box>
      <Box className='tab-wrapper main-info share-profile' sx={{ p: 3, pr: 8 }}>
        <h4 className="list-item-title main-title">
          {i18n.t('shareProfile')}
          <Tooltip title={i18n.t('shareProfileTooltip')} />
        </h4>
        {user?._id && (
          <>
            <div className="user-info-link">
              <Link
                to={{ pathname: `/users/invited-users`, state: { userId: user._id, myRoleType } }}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
                title={i18n.t('invitedUsers')}
              >
                <i className="la la-external-link" />
              </Link>
            </div>

            <ul className='list flex'>
              <li className="list-item">
                <h4 className="list-item-title">
                  {i18n.t('invitedUsers')}:
                </h4>
              </li>
              <li>
                <p className='count'>{user.userViewersCount || 0}</p>
              </li>
            </ul>

          </>
        )}
      </Box>
    </>
  )
};

UserInfo.propTypes = {
  userSubscription: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  isAllowToShowSubscription: PropTypes.bool.isRequired,
  redirectToPayment: PropTypes.func.isRequired,
  currentUserDeviceId: PropTypes.string,
  myRoleType: PropTypes.string
};

UserInfo.defaultProps = {
  myRoleType: undefined,
  userSubscription: undefined,
  currentUserDeviceId: undefined
};

const mapStateToProps = createStructuredSelector({
  userSubscription: userSubscriptionSelector,
  currentUserDeviceId: userIdSelector,
  isAllowToShowSubscription: isSupportContractFlagAllowedToBeChangedSelector
});

const mapDispatchToProps = (dispatch) => ({
  redirectToPayment: (endUserId, url) => dispatch(redirectToPaymentPage({ endUserId, url }))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
