import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import store from '../../../store';
import { openModalWindow } from '../../../containers/ModalWindow/actions';
import { dataListReloadData } from '../../../containers/DataList/actions';
import { usersSensorsRequest } from '../../../containers/DevicesTable/actions';

import '../index.scss';

const openModal = (modalID, data) => store.dispatch(openModalWindow(modalID, data));
const reloadDataList = (dataListID) => store.dispatch(dataListReloadData(dataListID));
/**
 * Render function for React-Table. Column with buttons.
 * @memberof module:ReactTable
 * @param  {Object} props
 * @param  {string} props.id - user id
 * @param  {string} props.device_group - device setting
 * @param  {string} props.type - device type
 * @param  {string} props.ip - device ip
 * @param  {string} props.data - device data
 * @param  {string} props.note - monitoring error note
 * @param  {Object} showBnts - Defines the button what will be shown.
 * @param  {Object} props.inputDevice - input device
 * @param  {string} props.description - description tag
 * @param  {number} props.inputDeviceFunction - load guard function for input device
 * @param  {Object[]} props.affectedDevices - array of affected devices
 */
const RenderButtons = (
  {
    id,
    device_group,
    type,
    ip,
    data,
    tag,
    note,
    email,
    sm_id,
    _id,
    inputDevice,
    description,
    inputDeviceFunction,
    affectedDevices
  },
  showBnts = {}
) => (
  <Fragment>
    {showBnts.sendEmail && (
      <a
        title={i18n.t('sendEmail')}
        href={`mailto:${email}?subject=${sm_id}`}
        className="send-email-btn btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i id="send-email-icon" className="send-email-icon la la-envelope" />
      </a>
    )}
    {showBnts.editNote && (
      <a
        title={i18n.t('editNote')}
        onClick={openModal.bind(null, 'editNote', { id: _id, note })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i className="la la-pencil-square" />
      </a>
    )}
    {showBnts.devices && (
      <Link
        to={`${showBnts.devices.preLink}${id || _id}/`}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        title={i18n.t('settings')}
      >
        <i className="la la-cog" />
      </Link>
    )}
    {showBnts.view && (
      <Link
        title={i18n.t('detailsTooltip')}
        to={`${showBnts.view.preLink}${_id || id}`}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i className="la la-file-text" />
      </Link>
    )}
    {showBnts.editUser && (
      <Link
        to={`${showBnts.editUser.preLink}${_id || id}/`}
        className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        title={i18n.t('editProfile')}
      >
        <i className="la la-user" />
      </Link>
    )}
    {showBnts.edit && (
      <Link
        to={`${showBnts.edit.preLink}${id}/`}
        className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        title={i18n.t('editTooltip')}
      >
        <i className="la la-edit" />
      </Link>
    )}
    {showBnts.editRow && (
      <a
        title={i18n.t('editTooltip')}
        onClick={() => {
          reloadDataList('deviceTags');
          // FIXME: replace with type checking
          if (affectedDevices) {
            store.dispatch(usersSensorsRequest(store.getState()?.userDevices?.user?._id));
            return openModal('editRow', {
              type,
              loadGuardDeviceId: _id,
              inputDevice: inputDevice?._id,
              description,
              inputDeviceFunction,
              affectedDevices: affectedDevices.map(({ _id: inputDeviceId }) => inputDeviceId)
            });
          }
          return openModal('editRow', {
            id,
            device_group,
            type,
            ip,
            tag: tag?._id,
            data,
            inputDevice,
            description,
            inputDeviceFunction,
            affectedDevices
          });
        }}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i className="la la-edit" />
      </a>
    )}
    {showBnts.remove && (
      <a
        title={i18n.t('delete')}
        onClick={openModal.bind(null, 'removeData', { id: _id })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
      >
        <i className="la la-trash" />
      </a>
    )}
    {showBnts.delete && (
      <a
        title={i18n.t('removeBtn')}
        onClick={openModal.bind(null, 'deleteData', { id: _id })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
      >
        <i className="la la-close" />
      </a>
    )}
    {showBnts.downloadFile && (
      <a
        title={i18n.t('downloadFile')}
        onClick={openModal.bind(null, 'downloadFile', { id: _id })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i className="la la-download download-icon" />
      </a>
    )}
    {(showBnts.deleteFile || showBnts.deleteInvitedUser) && (
      <a
        title={i18n.t(showBnts.title || 'deleteFile')}
        onClick={openModal.bind(null, showBnts.modalId || 'deleteFilesModal', showBnts.deleteInvitedUser ? { id: _id } : { ids: [_id] })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
      >
        <i className="la la-trash" />
      </a>
    )}

  </Fragment>
);
RenderButtons.propTypes = {
  email: PropTypes.string,
  sm_id: PropTypes.string,
  id: PropTypes.string,
  tag: PropTypes.string,
  device_group: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  note: PropTypes.string,
  data: PropTypes.string.isRequired,
  _id: PropTypes.string,
  inputDevice: PropTypes.instanceOf(Object),
  description: PropTypes.string,
  inputDeviceFunction: PropTypes.string,
  affectedDevices: PropTypes.instanceOf(Array)
};

RenderButtons.defaultProps = {
  id: null,
  email: undefined,
  tag: undefined,
  inputDevice: undefined,
  inputDeviceFunction: undefined,
  description: undefined,
  affectedDevices: undefined,
  note: undefined,
  _id: undefined,
  sm_id: undefined
};

export default RenderButtons;
