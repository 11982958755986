import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for affected devices.
 * @memberof module:ReactTable
 * @param {Object} props - General props object
 * @param {Object} props.original - object with properties to render
 * @param {Object[]} props.original.affectedDevices - Array of affected devices
 */
const renderAffectedDevices = ({ original: { affectedDevices } }) => (
  Array.isArray(affectedDevices)
    ? (
      affectedDevices.map(({ _id, device_group, tag }) => (
        <React.Fragment key={_id}>
          {tag ? tag.name : i18n.t(device_group)}
          <br />
        </React.Fragment>
      ))
    )
    : null
);

renderAffectedDevices.propTypes = {
  affectedDevices: PropTypes.instanceOf(Array).isRequired
};

export default renderAffectedDevices;
