import * as actionTypes from './constants';

export function getNotAttachedEndUsers(params, modalID) {
  return {
    type: actionTypes.GET_NOT_ATTACHED_END_USERS,
    payload: {
      params,
      modalID
    }
  };
}

export function setNotAttachedEndUsers(endUsers) {
  return {
    type: actionTypes.SET_NOT_ATTACHED_END_USERS,
    payload: {
      endUsers
    }
  };
}

export function getOEMsList(params) {
  return {
    type: actionTypes.GET_OEMS_LIST,
    payload: {
      params
    }
  };
}

export function setOEMsList(oems) {
  return {
    type: actionTypes.SET_OEMS_LIST,
    payload: {
      oems
    }
  };
}

export function getPVInstallersList(params) {
  return {
    type: actionTypes.GET_PV_INSTALLERS_LIST,
    payload: {
      params
    }
  };
}

export function setPVInstallersList(pvInstallers) {
  return {
    type: actionTypes.SET_PV_INSTALLERS_LIST,
    payload: {
      pvInstallers
    }
  };
}

export function getAllNotAttachedInstallers(params) {
  return {
    type: actionTypes.GET_ALL_NOT_ATTACHED_INSTALLERS,
    payload: {
      params
    }
  };
}

export function setAllNotAttachedInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_NOT_ATTACHED_INSTALLERS,
    payload: {
      installers
    }
  };
}

export function getAllAttachedInstallers(params) {
  return {
    type: actionTypes.GET_ALL_ATTACHED_INSTALLERS,
    payload: {
      params
    }
  };
}

export function setAllAttachedInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_ATTACHED_INSTALLERS,
    payload: {
      installers
    }
  };
}

export function getAllNotAttachedOems(params) {
  return {
    type: actionTypes.GET_ALL_NOT_ATTACHED_OEMS,
    payload: {
      params
    }
  };
}

export function setAllNotAttachedOems(oems) {
  return {
    type: actionTypes.SET_ALL_NOT_ATTACHED_OEMS,
    payload: {
      oems
    }
  };
}

export const setLoadingSupport = (payload) => ({ type: actionTypes.SET_LOADING_SUPPORT, payload });
