import moment from 'moment-timezone';

// returns scaleType, min, max
const parseURLDashboardConfig = (config) => {
  const { type } = config;
  switch (type) {
    case 'range': {
      const { from, to } = config;
      return { min: +from, max: +to };
    }
    case 'realtime': {
      const { duration } = config;
      if (duration <= 0) return {};
      const max = moment().valueOf();
      const min = moment().diff(+duration).valueOf();
      return { min, max };
    }
    default: {
      if (typeof type === 'undefined') return {};
      return { scaleType: type };
    }
  }
};

export default parseURLDashboardConfig;
