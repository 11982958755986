import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LargeButton } from '../../../../components/ReduxFormFields/SimpleButton';

import { openModalWindow } from '../../../ModalWindow/actions';
import i18n from '../../../../i18n';
import { UPLOAD_MODAL_ID, allowedRolesForUpdateAndDeleteFiles } from '../../constants';
import { ReactComponent as CloseIco } from '../../../../assets/images/svg/close.svg';
import './index.scss';


export const DocumentationTableHeader = ({
  filesCount,
  selectedRowsIds,
  resetSelectedRows,
  deleteFiles,
  downloadFiles,
  openModalWindow: openModalWindowAction,
  myRoleType
}) => {
  const isAllowedToUpdateAndDeleteFiles = allowedRolesForUpdateAndDeleteFiles.includes(myRoleType);

  const handleDownloadFiles = () => {
    downloadFiles(selectedRowsIds);
  };

  return (
    <div className="documentation-header-container">
      <div className="documentation-header">
        <h2 className="documentation-header-title">
          {i18n.t('uploadedFiles')}
        </h2>
        {filesCount > 0 && (
          <div className="documentation-header-subtitle">
            {filesCount}
            {' '}
            {i18n.t('items')}
          </div>
        )}
      </div>

      {
        selectedRowsIds.length
          ? (
            <div className="documentation-header-selected-items">
              <p className="count">
                <span>{selectedRowsIds.length}</span>
                {' '}
                <span>{i18n.t('Selected')}</span>
              </p>
              <div className="btn-upd-dashb">
                <button
                  type="button"
                  className="close-btn"
                  onClick={resetSelectedRows}
                >
                  <CloseIco />
                </button>
              </div>

              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                onClick={handleDownloadFiles}
              >
                {i18n.t('download')}
              </button>
              {isAllowedToUpdateAndDeleteFiles && (
                <button
                  type="button"
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn delete-btn"
                  onClick={deleteFiles}
                >
                  {i18n.t('delete')}
                </button>
              )}
            </div>
          )
          : (
            isAllowedToUpdateAndDeleteFiles && (
              <LargeButton
                type="button"
                onClick={openModalWindowAction.bind(null, UPLOAD_MODAL_ID)}
                isDownloadIcon
                title={i18n.t('uploadFiles')}
                className="upload-files-btn"
              />
            )
          )
      }

    </div>
  );
};


DocumentationTableHeader.propTypes = {
  filesCount: PropTypes.number.isRequired,
  selectedRowsIds: PropTypes.instanceOf(Array).isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  downloadFiles: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired
};

export default connect(null, { openModalWindow })(DocumentationTableHeader);
