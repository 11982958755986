import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import moment from 'moment-timezone';
import * as actionTypes from './constants';
import { handleError } from '../../api_helper';
import { cancelSignIn, signInCheckJWT } from '../SignIn/actions';
import strapi from '../../strapi';

function rootSignInEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.ROOT_SIGN_IN),
    map((action) => action.payload),
    switchMap(({
      userId
    }) => {
      strapi.storeConfig.cookie.options.expires = moment().add(30, 'days').toDate();
      return from(strapi.request('get', '/../users/backdoor', {
        params: { userId }
      })).pipe(
        catchError(handleError),
        mergeMap((result) => {
          if (result?.jwt) {
            strapi.clearToken();
            strapi.setToken(result.jwt);
            return of(
              signInCheckJWT()
            );
          }
          return cancelSignIn(result);
        })
      );
    })
  );
}

export default combineEpics(
  rootSignInEpic
);
