import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Parent = styled.div`
  text-align: center;
  color: rgba(255,255,255,.7);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
`;

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Parent>
          <h1>Oops! Something went wrong...</h1>
        </Parent>
      );
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ErrorHandler.defaultProps = {
  children: null
};

export default ErrorHandler;
