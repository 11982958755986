import {
  RenderEmail, RenderDate, RenderStatus, RenderButtons, RenderFullName
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'first_name',
    minWidth: 180,
    className: '',
    Cell: (row) => RenderFullName(row)
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: 225,
    className: 'email-text-td',
    Cell: (row) => RenderEmail(row)
  },
  {
    Header: i18n.t('phoneNumber'),
    accessor: 'phone',
    minWidth: 180,
    className: 'phone_num-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        editUser: { preLink: '/setting-profile/solar-admin/' },
        remove: myRoleType === 'root'
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
