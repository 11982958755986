import React from 'react';
import PropTypes from 'prop-types';

import ModalWindow from '../../../ModalWindow';
import CustomDatePicker from '../../../../components/CustomDatePicker';

import './index.scss';

/**
 * Renders calendar picker
 * @memberof module:UsersTable
 */
class CalendarPickerModal extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { modal, reset } = this.props;
    const { modal: nextModal } = nextProps;
    if (modal && (!nextModal || (modal.opened && !nextModal.opened))) {
      reset();
    }
  }

  render() {
    const {
      handleChange,
      modalID,
      from,
      maxDetail
    } = this.props;

    return (
      <div className="calendarModal">
        <ModalWindow modalID={modalID}>
          <div className="modal-body">
            <CustomDatePicker
              value={from}
              handleChange={handleChange}
              maxDetail={maxDetail}
            />
          </div>
        </ModalWindow>
      </div>
    );
  }
}

CalendarPickerModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  reset: PropTypes.func,
  modal: PropTypes.instanceOf(Object),
  from: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.number
  ]),
  maxDetail: PropTypes.string
};

CalendarPickerModal.defaultProps = {
  modal: {
    data: {}
  },
  reset: undefined,
  from: undefined,
  maxDetail: undefined
};

export default CalendarPickerModal;
