import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  formValueSelector,
  reduxForm,
  change
} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { createStructuredSelector } from 'reselect';

import ModalWindow from '../../containers/ModalWindow';
import Preloader from '../Preloader';
import CustomDateInput from '../ReduxFormFields/CustomDateInput';
import CustomDatePicker from '../CustomDatePicker';
import {
  required,
  validateDate,
  validateSameOrBeforeDate,
  validateSameOfAfterDate,
  validateDateAfterToday
} from '../../containers/validation';
import {
  dataExportModalInitialValues,
  dataExportModalSelector,
  isLoadingSelector
} from './selectors';
import i18n from '../../i18n';

import './styles.scss';
import CancelButton from '../UIKit/CancelButton';

/**
 * Export all clients data form
 */
const DATA_EXPORT_FORM_ID = 'dataExportForm';

/**
 * "From date" - date field input name
 */
const FROM_DATE = 'fromDate';

/**
 * "To date" - date field input name
 */
const TO_DATE = 'toDate';

/**
 * Datepicker mask
 */
const DATEPICKER_MASK = 'DD/MM/YYYY';

const DataExportWindow = (props) => {
  const {
    handleOnClose,
    handleSubmit,
    modalID,
    isLoading,
    fromDate,
    toDate,
    modal,
    reset
  } = props;

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  /**
   * Sets field value for [DATA_EXPORT_FORM_ID]
   * @param {string} field Redux form field name
   * @param {number} value Redux form field value
   */
  const changeFormFieldValue = (field, value) => {
    const { dispatch } = props;
    dispatch(change(DATA_EXPORT_FORM_ID, field, value));
  };

  /**
   * If date is valid then get start of the day for the date
   * and change form fromDate field value
   * @param {number} value From date value
   */
  const handleDatePickerFromDateChange = (value) => {
    const nextValue = moment(value).startOf('day');
    if (nextValue.isValid()) {
      changeFormFieldValue(FROM_DATE, nextValue.valueOf());
    }
  };

  /**
   * If date is valid and if 'To' date is equal to 'today'
   * then set 'To' equal to current moment date
   * @param {number} value "To date"
   */
  const handleDatePickerToDateChange = (value) => {
    const today = moment();
    const nextValue = moment(value);

    if (nextValue.isValid()) {
      if (nextValue.isSame(today, 'day')) {
        changeFormFieldValue(TO_DATE, today.valueOf());
      } else {
        changeFormFieldValue(TO_DATE, nextValue.endOf('day').valueOf());
      }
    }
  };

  /**
   * Validates if fromDate value is the same or before toDate value
   * @param {number} value fromDate timestamp value
   * @returns {undefined|string}
   */
  const fromDateBeforeToDateValidator = useCallback(
    (value) => validateSameOrBeforeDate(value, toDate),
    [toDate]
  );

  /**
   * Validates if toDate value is the same of after fromDate value
   * @param {number} value toDate timestamp value
   * @returns {undefined|string}
   */
  const toDateAfterFromValidator = useCallback(
    (value) => validateSameOfAfterDate(fromDate, value),
    [fromDate]
  );

  /**
   * Validates if the value is the same as today date or before today date
   * @param {number} value timestamp
   * @returns {undefined|string}
   */
  const dataPickerValidator = (value) => moment(value)
    .startOf('day')
    .isSameOrBefore(moment().startOf('day'));

  /**
   * Get date which should be disabled in datepicker view
   * @param {object} current moment object
   * @returns {boolean}
   */
  const disableDate = (current) => current
    .startOf('day')
    .isAfter(moment().startOf('day'));

  /**
   * Formats the value from the Redux store to be displayed in the field input.
   * @param {number|string} value timestamp, date string
   * @param {string} name redux form field name
   * @returns {number|string}
   */
  const formatDate = (value) => {
    if (Number.isInteger(value) && moment(value).isValid()) {
      return moment(value).format(DATEPICKER_MASK);
    }

    return value;
  };

  /**
   * Convert whatever value the user has entered into the value
   * that you want stored in the Redux store for the [FROM_DATE] field.
   * @param {string} value current value
   * @param {string|number} previousValue previous value
   * @param {object} allValues all current form values
   * @param {object} previousAllValues previous all form values
   * @returns {string|number}
   */
  const normalizeFromDate = (value) => {
    const timestampFromStr = Number(value);
    if (Number.isInteger(timestampFromStr) && moment(timestampFromStr).isValid()) {
      return timestampFromStr.valueOf();
    }

    const dateStrictFromValue = moment(value, DATEPICKER_MASK, true);
    if (dateStrictFromValue.isValid()) {
      return dateStrictFromValue.valueOf();
    }

    return value;
  };

  /**
   * Convert whatever value the user has entered into the value
   * that you want stored in the Redux store for the [TO_DATE] field.
   * @param {string} value current value
   * @param {string|number} previousValue previous value
   * @param {object} allValues all current form values
   * @param {object} previousAllValues previous all form values
   * @returns {string|number}
   */
  const normalizeToDate = (value) => {
    const today = moment();

    const timestampFromStr = Number(value);
    if (Number.isInteger(timestampFromStr) && moment(timestampFromStr).isValid()) {
      if (moment(timestampFromStr).isSame(today, 'day')) {
        return today.valueOf();
      }
      return timestampFromStr.valueOf();
    }

    const dateStrictFromValue = moment(value, DATEPICKER_MASK, true);
    if (dateStrictFromValue.isValid()) {
      if (dateStrictFromValue.isSame(today, 'day')) {
        return today.valueOf();
      }
      return dateStrictFromValue.valueOf();
    }

    return value;
  };

  return (
    <div className="data-export-modal">
      <ModalWindow modalID={modalID}>
        <div className="modal-header">
          <h5 className="modal-title">
            <i className="la la-download download-icon" />
            <span>{i18n.t('dataExportModalTitle')}</span>
          </h5>
        </div>
        {isLoading ? (
          <div className="employee-add-prealoder-container">
            <Preloader />
          </div>
        ) : (
          <div>
            <div className="modal-body">
              <form
                id="dataExportForm"
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
              >
                <div className="d-flex justify-content-center">
                  <div className="d-flex flex-column datepicker-col">
                    <div className="d-flex justify-content-center align-items-top datepicker-input-field-container">
                      <span className="date-input-label">
                        {i18n.t('fromDateLabel')}
                      </span>
                      <div className="form-group m-form__group datepicker-input-field">
                        <Field
                          name={FROM_DATE}
                          component={CustomDateInput}
                          className="m-input"
                          autoComplete="off"
                          validate={[required, validateDate, fromDateBeforeToDateValidator, validateDateAfterToday]}
                          preventAuto
                          placeholder={DATEPICKER_MASK}
                          format={formatDate}
                          normalize={normalizeFromDate}
                        />
                      </div>
                    </div>
                    <CustomDatePicker
                      value={fromDate}
                      handleChange={handleDatePickerFromDateChange}
                      className="export-data-datepicker-container"
                      validateDate={dataPickerValidator}
                      disabledDate={disableDate}
                    />
                  </div>
                  <div className="d-flex flex-column datepicker-col">
                    <div className="d-flex justify-content-center align-items-top datepicker-input-field-container">
                      <span className="date-input-label">
                        {i18n.t('toDateLabel')}
                      </span>
                      <div className="form-group m-form__group datepicker-input-field">
                        <Field
                          name={TO_DATE}
                          component={CustomDateInput}
                          className="m-input"
                          autoComplete="off"
                          validate={[required, validateDate, toDateAfterFromValidator, validateDateAfterToday]}
                          preventAuto
                          placeholder={DATEPICKER_MASK}
                          format={formatDate}
                          normalize={normalizeToDate}
                        />
                      </div>
                    </div>
                    <CustomDatePicker
                      value={toDate}
                      handleChange={handleDatePickerToDateChange}
                      className="export-data-datepicker-container"
                      validateDate={dataPickerValidator}
                      disabledDate={disableDate}
                    />
                  </div>
                </div>
              </form>
              <div className="d-flex justify-content-center export-all-client-data-info">
                <span>{i18n.t('exportAllClientsDataInfo')}</span>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="submit"
                form="dataExportForm"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              >
                {i18n.t('confirmBtn')}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    </div>
  );
};

DataExportWindow.propTypes = {
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  fromDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  toDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  initialValues: PropTypes.shape({
    fromDate: PropTypes.number.isRequired,
    toDate: PropTypes.number.isRequired
  })
};

DataExportWindow.defaultProps = {
  modal: {
    data: {}
  },
  toDate: undefined,
  fromDate: undefined,
  isLoading: false,
  initialValues: {
    fromDate: moment().subtract(1, 'years').startOf('day').valueOf(),
    toDate: moment().valueOf()
  }
};

const form = reduxForm({
  form: DATA_EXPORT_FORM_ID
})(DataExportWindow);

const dataExportFormSelector = formValueSelector(DATA_EXPORT_FORM_ID);

const mapStateToProps = createStructuredSelector({
  fromDate: (state) => dataExportFormSelector(state, FROM_DATE),
  toDate: (state) => dataExportFormSelector(state, TO_DATE),
  modal: dataExportModalSelector,
  initialValues: dataExportModalInitialValues,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps)(form);
