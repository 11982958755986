import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { replace } from 'react-router-redux';

import i18n from '../../../i18n';
import { changeScaleType } from '../actions';
import { chartSelector } from '../selectors';
import { chartChangeRange, getRange } from '../utils';
import queryURLFormat from '../../../utils/queryURLFormat';


const LoadManagementChartControls = (props) => {
  const {
    changeScaleType: changeScaleTypeFromProps,
    replace: replaceFromProps,
    openDatePicker,
    realTimeData,
    intervalStr,
    scaleType,
    location,
    zoom,
    chart
  } = props;

  /**
   * @param {number | string} value next scaleType
   */
  const chartControlsHandler = (value) => {
    const { from, to } = getRange(value, chart);
    if (typeof value === 'number' || value === 'back' || value === 'forward') {
      chartChangeRange(from, to, replaceFromProps, location.pathname);
    } else {
      replaceFromProps(location.pathname + queryURLFormat({ type: value }));
    }

    changeScaleTypeFromProps({ type: value });
  };

  return (
    <div className="container-fluid chart-controls-wrapper">
      <div className="row justify-content-between">
        <div className="col-auto">
          <button
            onClick={chartControlsHandler.bind(null, 't')}
            className={
              scaleType?.type === 't'
                ? 'btn-today navig-btn-dashb navig-btn-dashb-act'
                : 'btn-today navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('today')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'h')}
            className={
              scaleType?.type === 'h' ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('h')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'd')}
            className={
              scaleType?.type === 'd' ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('d')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'w')}
            className={
              scaleType?.type === 'w' ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('w')}
          </button>
        </div>
        <div className="col-auto mr-auto d-flex align-items-start">
          {zoom && (
            <React.Fragment>
              <button
                onClick={chartControlsHandler.bind(null, 0.5)}
                type="button"
                className="navig-btn-dashb btn-plus-zoom-dashb"
                disabled={scaleType?.type === 'h'}
              >
                +
              </button>
              <button
                onClick={chartControlsHandler.bind(null, 2)}
                type="button"
                className="navig-btn-dashb btn-min-zoom-dashb"
                disabled={scaleType?.type === 'w'}
              >
                -
              </button>
            </React.Fragment>
          )}
          {realTimeData && (
            <div className="liveDataStatus">
              <div className="recIco" />
            </div>
          )}
        </div>
        <div className="col-auto d-flex align-items-center">
          <button
            onClick={openDatePicker}
            type="button"
            className="navig-btn-dashb btn-calendar-dashb"
          >
            <i className="flaticon-event-calendar-symbol fa" />
          </button>
          <span className="date-dashb">{intervalStr}</span>
          <button
            onClick={chartControlsHandler.bind(null, 'back')}
            type="button"
            className="navig-btn-dashb btn-plus-zoom-dashb"
          >
            <i className="la-angle-left la" />
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'forward')}
            type="button"
            className="navig-btn-dashb btn-min-zoom-dashb"
          >
            <i className="la-angle-right la" />
          </button>
        </div>
      </div>
    </div>
  );
};

LoadManagementChartControls.propTypes = {
  scaleType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.instanceOf(Object).isRequired,
  replace: PropTypes.instanceOf(Object).isRequired,
  chart: PropTypes.instanceOf(Object).isRequired,
  changeScaleType: PropTypes.func.isRequired,
  openDatePicker: PropTypes.func.isRequired,
  zoom: PropTypes.bool.isRequired,
  intervalStr: PropTypes.string,
  realTimeData: PropTypes.bool
};

LoadManagementChartControls.defaultProps = {
  scaleType: undefined,
  realTimeData: undefined,
  intervalStr: null
};

const mapStateToProps = createStructuredSelector({
  chart: chartSelector
});

export default connect(mapStateToProps, {
  changeScaleType,
  replace
})(LoadManagementChartControls);
