import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dataListSendRequest } from './actions';
import { allDataListsSelector } from './selectors';
import { setPagesSettingQueries, setPagesSettingColumnsWidth } from '../PagesSettings/actions';
import { getPagesSettingSelector } from '../PagesSettings/selectors';

export const DataListV2 = ({
  Component,
  componentProps,
  listID,
  listURL,
  transformResponse
}) => {
  const dispatch = useDispatch();

  const { [listID]: dataList } = useSelector(allDataListsSelector);
  const { [listID]: settings } = useSelector(getPagesSettingSelector);

  const settingRef = useRef([]);

  const [id, desc] = settings.queries._sort.split(':');

  const handleSort = (sorting) => {
    const [{ id: sortId, desc: isDesc }] = sorting;
    const _sort = `${sortId}:${isDesc ? 'desc' : 'asc'}`;
    const params = {
      listURL,
      params: { ...settings.queries, _sort },
      transformResponse
    };

    dispatch(dataListSendRequest(listID, params));
    dispatch(setPagesSettingQueries(listID, { _sort }));
  };

  const onResizedChange = (all) => {
    settingRef.current = all;
  };

  const savePageColumnWidth = () => {
    const transformedData = settingRef.current.reduce((acc, { id: sortId, value }) => ({ ...acc, [sortId]: value }), {});
    dispatch(setPagesSettingColumnsWidth(listID, transformedData));
  };

  useEffect(() => {
    if (listID && listURL) {
      const params = {
        listURL,
        params: settings.queries,
        transformResponse
      };
      dispatch(dataListSendRequest(listID, params));
    }
  }, [listID, listURL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('beforeunload', savePageColumnWidth);

    return () => {
      savePageColumnWidth();
      window.removeEventListener('beforeunload', savePageColumnWidth);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Component
      onSortedChange={handleSort}
      data={dataList?.data}
      sorted={[{ id, desc: desc === 'desc' }]}
      loading={dataList?.loading}
      onResizedChange={onResizedChange}
      {...componentProps}
    />
  );
};

DataListV2.propTypes = {
  listID: PropTypes.string.isRequired,
  Component: PropTypes.func,
  componentProps: PropTypes.instanceOf(Object),
  listURL: PropTypes.string,
  transformResponse: PropTypes.func
};

DataListV2.defaultProps = {
  componentProps: {},
  transformResponse: undefined,
  Component: undefined,
  listURL: undefined
};
