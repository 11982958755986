import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import smartMeterIcon from '../../../../../assets/images/svg/energy-measurement.svg';

const SmartMeterTooltip = (props) => {
  const { currentPowerInvSm } = props;

  return (
    <>
      <img src={smartMeterIcon} alt="Smart Meter Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {currentPowerInvSm || defaultValue}
          {i18n.t('w')}
        </span>
      </div>
    </>
  );
};

SmartMeterTooltip.propTypes = {
  currentPowerInvSm: PropTypes.number
};

SmartMeterTooltip.defaultProps = {
  currentPowerInvSm: undefined
};

export default SmartMeterTooltip;
