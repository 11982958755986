import { RECEIVE_OVERVIEW_DATA } from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case RECEIVE_OVERVIEW_DATA: {
      return {
        ...action.payload.data
      };
    }
    default:
      return state;
  }
};
