import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
/**
 * Render function for React-Table. Column for status of user
 * @memberof module:ReactTable
 * @param  {string} value - status of user
 */
const RenderStatus = ({ value }) => (value === 'active' ? (
  <Fragment>{i18n.t(value)}</Fragment>
) : (
  <Fragment>
    <span className="error_status_yes">{i18n.t(value)}</span>
  </Fragment>
));

RenderStatus.propTypes = {
  value: PropTypes.string.isRequired
};

export default RenderStatus;
