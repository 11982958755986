import * as actionTypes from './constants';

export function sendRequest(data) { // epic
  return {
    type: actionTypes.SEND_REQUEST,
    payload: {
      data
    }
  };
}

export function receiveNotificationInfo(data) { // reducer
  return {
    type: actionTypes.SAVE_NOTIFICATION_DATA,
    payload: {
      data
    }
  };
}
