import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { createStructuredSelector } from 'reselect';
import i18n from '../../i18n';
import { userSelector } from './selectors';
import { EMPLOYEE_ROLE_TYPES } from '../../containers/UserDetails/constants';

/**
 * Array of all tabs and role types
 * @constant
 * @memberof module:TopBar
 */
const tabsByRoles = [
  {
    label: 'overview',
    link: '/overview',
    allowedRoles: ['root', 'solar_admin', 'pv_installer', 'oem', 'support', 'viewer', ...EMPLOYEE_ROLE_TYPES]
  },
  {
    label: 'SMUs',
    link: '/users',
    allowedRoles: ['root', 'solar_admin', 'pv_installer', 'support', 'viewer', 'oem', ...EMPLOYEE_ROLE_TYPES]
  },
  {
    label: 'employees',
    link: '/employees',
    allowedRoles: ['pv_installer']
  },
  {
    label: 'pvInstallers',
    link: '/pv-installers',
    allowedRoles: ['root', 'solar_admin', 'support', 'viewer', 'oem']
  },
  {
    label: 'oem',
    link: '/oem',
    allowedRoles: ['root', 'solar_admin', 'support', 'viewer']
  },
  {
    label: 'support',
    link: '/supports',
    allowedRoles: ['root', 'solar_admin']
  },
  {
    label: 'viewers',
    link: '/viewers',
    allowedRoles: ['root', 'solar_admin']
  },
  {
    label: 'admins',
    link: '/admins',
    allowedRoles: ['root']
  },
  {
    label: 'installedSM',
    link: '/installed-sm',
    allowedRoles: ['root', 'solar_admin']
  },
  {
    label: 'monitoring',
    link: '/monitoring',
    allowedRoles: ['root', 'solar_admin', 'support', 'pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES]
  }
];
/**
 * Renders tabs (relative to user role who is viewing) for TopBar.
 * @memberof module:TopBar
 * @param {Object} param Must contain next Object: { user: { role: { type: 'string' } }, ... }
 */
const MainTabs = ({ user }) => {
  if (!user || !user.role) return null;
  const isDocumentation = window.location.pathname.split('/').some((elem) => elem.includes('documentation'));

  return (
    <ul className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right">
      {tabsByRoles.map(
        (tab) => {
          if (tab.allowedRoles.includes(user.role.type) && tab.label === 'employees' && user?.my_company?.company_abbreviation) {
            return null;
          }
          return tab.allowedRoles.includes(user.role.type)
            ? (
              <li
                key={shortid.generate()}
                className="nav-item m-tabs__item"
              >
                <NavLink
                  className={`nav-link m-tabs__link ${isDocumentation && tab.label === 'SMUs' ? 'active show' : ''}`}
                  activeClassName="active show"
                  to={tab.link}
                  {...tab.componentProps}
                >
                  {i18n.t(tab.label)}
                </NavLink>
              </li>
            )
            : null;
        }
      )}
    </ul>
  );
};

MainTabs.propTypes = {
  user: PropTypes.instanceOf(Object)
};

MainTabs.defaultProps = {
  user: {}
};

const mapStateToProps = createStructuredSelector({
  user: userSelector
});

export default connect(mapStateToProps)(MainTabs);
