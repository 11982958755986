import * as actionTypes from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_ALL_INSTALLERS: {
      return {
        ...state,
        pvInstallersList: action.payload.installers
      };
    }
    case actionTypes.SET_ALL_CONNECTED_INSTALLERS: {
      return {
        ...state,
        allPvInstallersList: action.payload.installers
      };
    }
    default:
      return state;
  }
};
