import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openModalWindow } from '../../../ModalWindow/actions';
import i18n from '../../../../i18n';
import { allowedRolesForInviteUsers, INVITE_MODAL_ID } from '../../constants';

import './index.scss';

export const InviteUsersTableHeader = ({
  openModalWindow: openModalWindowAction,
  myRoleType
}) => {
  const isAllowedToInviteUser = allowedRolesForInviteUsers.includes(myRoleType);
  return (
    <div className="invited-users-header-container">
      <h2 className="invited-users-header-title">
        {i18n.t('invitedUsers')}
      </h2>
      {isAllowedToInviteUser && (
        <button
          type="button"
          onClick={openModalWindowAction.bind(null, INVITE_MODAL_ID)}
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp invite-btn"
        >
          {i18n.t('inviteUser')}
        </button>
      )}
    </div>
  );
};

InviteUsersTableHeader.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired
};

export default connect(null, { openModalWindow })(InviteUsersTableHeader);
