import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, startWith, endWith } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import * as actionTypes from './constants';
import { addNotification } from '../NotificationGenerator/actions';
import {
  setNotAttachedToViewerEndUsers,
  setOEMsForViewerList,
  setPVInstallersForViewerList,
  setAllNotAttachedTOViewerInstallers,
  setAllNotAttachedToViewerOems,
  setLoading
} from './actions';
import { modalStopLoading } from '../ModalWindow/actions';
import i18n from '../../i18n';

function getNotAttachedEndUsersToViewerEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_NOT_ATTACHED_TO_VIEWER_END_USERS),
    map((action) => action.payload),
    switchMap(({ params, modalID }) => from(strapi.request('get', '/../users/get-not-attached-to-viewer-end-users', { params })).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const stopLoadingAction = modalID ? [modalStopLoading(modalID)] : [];
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return of(setNotAttachedToViewerEndUsers(result.list.map(({ _id, first_name, last_name, isChecked = false }) => ({ _id, name: `${first_name} ${last_name}`, isChecked }))));
          }
          return of(
            ...stopLoadingAction,
            addNotification({
              type: 'error',
              text: i18n.t('error')
            })
          );
        }
        return of(
          ...stopLoadingAction,
          addNotification({
            type: 'error',
            text: result?.message
          })
        );
      }),
      startWith(setLoading({ isNotAttachedUsersLoading: true })),
      endWith(setLoading({ isNotAttachedUsersLoading: false }))
    )
    )
  );
}

function getOEMsForViewerListEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_FOR_VIEWER_LIST),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/get-oem-users-attached-to-viewer', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result?.list)) {
            return setOEMsForViewerList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      })
    )
    )
  );
}

function getPVInstallersForViewerListEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_PV_INSTALLERS_FOR_VIEWER_LIST),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/get-pv-installer-users-attached-to-viewer', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setPVInstallersForViewerList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      })
    )
    )
  );
}

function getAllNotAttachedToViewerPVInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ params }) => from(
      strapi.request('get', '/../users/get-pv-installer-users-not-attached-to-viewer', { params })
    ).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setAllNotAttachedTOViewerInstallers(result.list.map(({ _id, my_company: { name }, isChecked = false }) => ({ _id, name, isChecked })));
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoading({ isPvInstallersListForViewerLoading: true })),
      endWith(setLoading({ isPvInstallersListForViewerLoading: false }))
    )
    )
  );
}

function getAllNotAttachedToViewerOEMsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/get-oem-users-not-attached-to-viewer', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setAllNotAttachedToViewerOems(result.list.map(({ _id, oem: { name }, isChecked = false }) => ({ _id, name, isChecked })));
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoading({ isOemsListForViewerLoading: true })),
      endWith(setLoading({ isOemsListForViewerLoading: false }))
    )
    )
  );
}

function getOEMsListForViewerEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_LIST_FOR_VIEWER),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../oem', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result?.list)) {
            return setOEMsForViewerList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoading({ isOemsListForViewerLoading: true })),
      endWith(setLoading({ isOemsListForViewerLoading: false }))
    )
    )
  );
}

function getPVInstallersListForViewerEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_PV_INSTALLERS_FOR_VIEWER_ALL_LIST),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/pv-installer-users', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setPVInstallersForViewerList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoading({ isPvInstallersListForViewerLoading: true })),
      endWith(setLoading({ isPvInstallersListForViewerLoading: false }))
    )
    )
  );
}

export default combineEpics(
  getNotAttachedEndUsersToViewerEpic,
  getOEMsListForViewerEpic,
  getAllNotAttachedToViewerOEMsEpic,
  getPVInstallersListForViewerEpic,
  getAllNotAttachedToViewerPVInstallersEpic,
  getOEMsForViewerListEpic,
  getPVInstallersForViewerListEpic
);
