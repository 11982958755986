import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';
import { countries, countryLabelsBack, countryLabels } from './constants';
import i18n from '../../../i18n';
import './index.css';

/**
 * Renders languageSelector for redux form
 * @memberof module:ReduxFormFields
 */
const LanguageSelector = ({
  input,
  label,
  id,
  buttonClassName,
  meta: { touched, error },
  ...custom
}) => (
  <Fragment>
    <ReactFlagsSelect
      {...input}
      {...custom}
      selectButtonClassName={`${buttonClassName} ${custom.disabled ? `${buttonClassName}_disabled` : ''}`}
      rfsKey={id}
      onSelect={(country) => input.onChange(countryLabels[country])}
      countries={countries}
      customLabels={countryLabels}
      selected={countryLabelsBack[input.value] || 'US'}
      selectedSize={18}
    />
    {touched && error && (
    <div className="inputCustomWarning">
      {i18n.t(error)}
    </div>
    )}
  </Fragment>
);

LanguageSelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  buttonClassName: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired
};

LanguageSelector.defaultProps = {
  label: undefined,
  id: undefined,
  buttonClassName: ''
};

export default LanguageSelector;
