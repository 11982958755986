import { createSelector } from 'reselect';

import { dataListID } from '../../containers/Users/constants';

const getAllDataLists = (state) => state?.dataList;

export const usersDataListSelector = createSelector(
  [getAllDataLists],
  // dataListID -> sm_users -> id only for users page
  (allDataLists) => allDataLists[dataListID] || {}
);

export const usersDataListParamsSelector = createSelector(
  [usersDataListSelector],
  (usersDataList) => usersDataList?.params || {}
);

export const isInstallationCompletedSelector = createSelector(
  [usersDataListParamsSelector],
  (usersDataListParams) => usersDataListParams?.isInstallationCompleted
);

export const supportContractFlagSelector = createSelector(
  [usersDataListParamsSelector],
  (usersDataListParams) => usersDataListParams?.supportContractFlag
);
