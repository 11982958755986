/**
 * Removes/shifts ranges (of devices usage) which is older then expectedFrom from active devices
 * (Activity should be sorted by timestamp to this function work properly).
 * Mutates activity.
 * @param activity {Array} Array of ranges
 * @param expectedFrom {Number} Minimal timestamp in new chart (milliseconds)
 */
export const removeOldRanges = (activity, expectedFrom) => {
  for (let i = 0; i < activity.length; i += 1) {
    const range = activity[i];
    if (typeof range !== 'undefined') {
      if (range.x <= expectedFrom) {
        if (range.x2 <= expectedFrom) {
          activity.splice(i, 1);
        } else {
          range.x = expectedFrom;
        }
      }
    } else {
      break;
    }
  }
};

export default removeOldRanges;
