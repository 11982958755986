import { from, of } from 'rxjs';
import moment from 'moment-timezone';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, finalize } from 'rxjs/operators';
import * as actionTypes from '../constants';
import strapi from '../../../strapi';
import { handleErrorDetailed } from '../../../api_helper';
import {
  fillEmptySpacesByZero,
  groupAllData,
  parseMinifiedData,
  setEndForBarChart,
  trimDataByRange
} from '../utils';
import { chartScaleChanged } from '../actions';
import { addNotification } from '../../NotificationGenerator/actions';
import {
  getRangeByScaleType,
  getChartType,
  batteryDischargingNegation,
  fillEmptyRanges,
  generateEmptyBuffer
} from './utils';
import { parseData } from '../utils/activeDevices';
import getChartResolutionByIndex from '../utils/getChartResolutionByIndex';
import analytics from '../../../analytics';
import getEventObj, { GET_POINTS } from '../../../analytics/events';

const tenSeconds = 10000;
const oneWeekAndOneHour = 608400000;

/**
 * Changes chart data range by scaleType (see {@link module:Dashboard getRangeByScaleType}
 * Is used with buttons: "Today,h,D,W,M,Y,+,-,<,>"
 * @memberof module:DashboardEpics
 */
export default function chartChangeScaleEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.CHART_WILL_CHANGE_SCALE),
    map((action) => action.payload.scaleType),
    switchMap((scaleType) => {
      const {
        dashboard: {
          chart,
          user: {
            chart_settings
          }
        },
        signIn: {
          user: {
            demo
          }
        }
      } = state$.value;

      const {
        from: fromDate, to: toDate, expectedScaleMS, limitsReceived
      } = getRangeByScaleType(scaleType, chart);
      const chartType = getChartType(expectedScaleMS);

      const scaleMS = toDate.diff(fromDate);
      const realTimeData = (moment().diff(toDate) <= tenSeconds && scaleMS <= oneWeekAndOneHour);
      const fromMS = fromDate.valueOf();
      const toMS = toDate.valueOf();
      const timePeriod = ['monthly', 'daily'];

      const chartResolution = getChartResolutionByIndex(chart?.groupPixelWidth);

      const version = chartResolution === 'low' && scaleMS <= actionTypes.ONE_WEEK_MS
        ? 'v3'
        : 'v2';

      const hourURL = timePeriod.includes(chartType) ? '-hour' : '';

      const url = chart.myself
        ? `/../data/${version}/get-my${hourURL}-points?from=${fromDate.toISOString()}&to=${toDate.toISOString()}${version === 'v3' ? '&chartResolution=low' : ''}`
        : `/../data/${version}/get-user${hourURL}-points/${chart.gatewayID}?from=${fromDate.toISOString()}&to=${toDate.toISOString()}${version === 'v3' ? '&chartResolution=low' : ''}`;

      return from(strapi.request('get', url)).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(GET_POINTS, {}, { myself: chart.myself, version, hourURL }));
        }),
        mergeMap((result) => {
          if (!result.error) {
            // TODO: Remove after fix on backend
            if (demo && (chartType === 'monthly' || chartType === 'daily')) {
              result.data = trimDataByRange(result.data, {
                from: toDate.valueOf(),
                to: fromDate.valueOf()
              });
            }

            result.data = parseMinifiedData(result.data);

            const isStreaming = Object
              .entries(result.data)
              .some((el) => Array.isArray(el) && el[1].length);

            if (timePeriod.includes(chartType)) {
              setEndForBarChart(
                groupAllData(result.data, fromMS, chartType),
                chartType
              );
            } else {
              fillEmptySpacesByZero({
                data: result.data,
                interval: result.interval,
                from: fromMS,
                to: toMS
              });
            }
            const config = {
              scaleMS, expectedScaleMS, scaleType, chartType, limitsReceived, realTimeData
            };
            const response = {
              ...result,
              data: {
                ...result.data
              },
              active_devices: parseData(result.active_devices, fromMS, toMS, chart_settings)
            };
            if (result.data.battery_discharging) {
              batteryDischargingNegation(result.data.battery_discharging);
            }
            fillEmptyRanges(response.active_devices, fromMS, toMS);

            const buffer = realTimeData && isStreaming ? generateEmptyBuffer(response.active_devices) : undefined;

            return of(
              chartScaleChanged(
                fromMS,
                toMS,
                config,
                response,
                buffer
              )
            );
          }
          return of(addNotification({
            type: 'error',
            text: result.message
          }));
        })
      );
    })
  );
}
