import { from, of } from 'rxjs';
import { catchError, map, mergeMap, groupBy, switchMap, finalize } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { dataListReceived, dataListGotError, attachSubSensors } from './actions';
import analytics from '../../analytics';
import getEventObj, { ERRORMONITORING_TICKETS } from '../../analytics/events';

function dataListSendRequestEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.DATA_LIST_SEND_REQUEST),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap(
      (group) => group.pipe(
        switchMap(
          // eslint-disable-next-line arrow-body-style
          ({ listID, dataList: { listURL, params, transformResponse, subSensorsConfig, isParentExist }, showNotification }) => {
            return from(strapi.request('get', listURL, { params })).pipe(
              catchError(handleErrorDetailed),
              finalize(() => {
                analytics.sendEvent(getEventObj(ERRORMONITORING_TICKETS, {}, { listURL }));
              }),
              mergeMap(
                (result) => {
                  if (!result.error) {
                    const parsedResult = transformResponse ? transformResponse(result) : { data: result };
                    if (isParentExist) {
                      return of(
                        attachSubSensors(listID, listURL, parsedResult, subSensorsConfig.parentSensorUrl)
                      );
                    }
                    return of(
                      dataListReceived(listID, listURL, parsedResult)
                    );
                  }

                  if (showNotification) {
                    return of(
                      dataListGotError(listID, listURL, result.message),
                      addNotification({
                        type: 'error',
                        text: result.message
                      })
                    );
                  }

                  return of(
                    dataListGotError(listID, listURL, result.message)
                  );
                }
              )
            );
          }
        )
      )
    )
  );
}

function dataListReloadDataEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.DATA_LIST_RELOAD_DATA),
    map((action) => action.payload),
    mergeMap(({ listID, isSubSensorUrl }) => {
      const { dataList } = state$.value;
      const { listURL, params, transformResponse } = dataList[listID];
      return from(strapi.request('get', isSubSensorUrl || listURL, isSubSensorUrl ? {} : { params })).pipe(
        catchError(handleErrorDetailed),
        mergeMap(
          (result) => {
            if (!result.error) {
              const parsedResult = transformResponse ? transformResponse(result) : { data: result };
              if (isSubSensorUrl) {
                return of(
                  attachSubSensors(listID, isSubSensorUrl, parsedResult, listURL)
                );
              }
              return of(
                dataListReceived(listID, listURL, parsedResult)
              );
            }
            return of(
              dataListGotError(listID, listURL, result.message),
              addNotification({
                type: 'error',
                text: result.message
              })
            );
          }
        )
      );
    })
  );
}

export default combineEpics(
  dataListSendRequestEpic,
  dataListReloadDataEpic
);
