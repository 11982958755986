import moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { cloneDeep } from 'lodash';
import * as actionTypes from '../constants';
import { calculateDelay } from '../utils';
import {
  fillEmptyDataBetweenLastPoints,
  removeOldPoints,
  addNewPoint,
  generateEmptyBuffer
} from './utils';
import { chartChangedInRealTime, chartDefineBuffer } from '../actions';
import { updatedActiveDevices, removeOldRanges } from '../utils/activeDevices';

const applyMainChart = (
  prevMainChart,
  { data: updData, dataSum: updDataSum }
) => {
  const result = {
    data: cloneDeep(prevMainChart.data),
    dataSum: cloneDeep(prevMainChart.dataSum)
  };
  const updKeys = Object.keys(updData);
  for (let i = 0; i < updKeys.length; i += 1) {
    const key = updKeys[i];

    if (['temperatures', 'devicesCurrentPower', 'invertersCurrentPower', 'batteriesSoc'].includes(key)) {
      if (!result.data[key]) {
        result.data[key] = {};
      }

      Object.keys(result.data[key]).forEach((sensorId) => {
        if (updData[key][sensorId]) {
          result.data[key][sensorId].push(...updData[key][sensorId]);
        }
      });
    } else {
      if (!result.data[key]) {
        result.data[key] = [];
      }

      result.data[key].push(...updData[key]);

      if (!result.dataSum[key]) {
        result.dataSum[key] = 0;
      }

      result.dataSum[key] += updDataSum[key];
    }
  }

  result.dataSum.self_consumption_abs += updDataSum.self_consumption_abs;

  return result;
};

const logBig = (text) => {
  console.log(`%c ${text} <<<`, 'background: gray; color: white; font-size: 20px');
};

/**
 * Function is called when receive new data from sockets.
 * By using util functions it updates charts data
 * (pushes new / removes old points; sets zero points)
 * and updates dashboards statistic.
 * @function
 * @memberof module:DashboardEpics
 */
export default function dashboardUpcCurrentEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.DASHBOARD_UPD_CURRENT),
    map((action) => action.payload),
    map(({ rawSensorData }) => {
      const {
        newPoint,
        active_devices
      } = rawSensorData;

      const {
        dashboard: {
          chart,
          chartBuffer,
          user
        }
      } = state$.value;

      const battery = user?.chart_settings?.additional_battery_curve;

      if (chart.realTimeData && !chart.loading) {
        if (chart.data && newPoint) {
          const minDelay = calculateDelay(1577, chart.scaleMS);
          logBig(`Buffering for ${Math.ceil(minDelay)} ms`);

          // Save updates to buffer
          if (chartBuffer) {
            logBig('Buffering ON');
            addNewPoint(chartBuffer.mainChart, rawSensorData, { battery });
            const { activity, devices } = chartBuffer.activeDevices;
            const newActiveDevices = updatedActiveDevices(newPoint.timestamp, active_devices, chart.fromMS, activity, devices);
            const newBuffer = {
              bufferCreated: chartBuffer.bufferCreated,
              mainChart: chartBuffer.mainChart,
              activeDevices: newActiveDevices
            };

            // Clear buffer and apply updates
            const { bufferCreated } = chartBuffer;
            logBig(`Time left: ${Math.ceil(bufferCreated + minDelay - Date.now())} ms`);
            if (minDelay < 20000
              || bufferCreated + minDelay <= Date.now()
            ) {
              const chartUpdates = applyMainChart(chart, newBuffer.mainChart);
              logBig('Buffer was cleared.');

              const expectedTo = newPoint.timestamp;
              let expectedFrom = expectedTo - chart.expectedScaleMS;
              if (chart.scaleType === 't' && moment(expectedTo).startOf('day') !== moment(expectedFrom).startOf('day')) {
                expectedFrom = moment(expectedTo).startOf('day').valueOf();
              }

              const scaleMS = expectedTo - expectedFrom;
              if (chart.data.consumption.length > 0) {
                removeOldPoints(chartUpdates, expectedFrom);
                removeOldRanges(newActiveDevices.activity, expectedFrom);

                Object.entries(chartUpdates.data).forEach(([chartDataKey, chartData]) => {
                  if (Array.isArray(chartData)) {
                    const dataLength = chartData.length;

                    if (dataLength > 1) {
                      const prevLastPoint = chartData[dataLength - 2];
                      const intervalMS = chart.dataSingleInterval * 4.1;

                      if (newPoint.timestamp - prevLastPoint[0] > intervalMS
                        && ((prevLastPoint[1] !== null && !['battery_charging', 'battery_discharging', 'x_battery_discharging'].includes(chartDataKey)) || newPoint?.[chartDataKey] != null)) {
                        fillEmptyDataBetweenLastPoints({
                          data: chartUpdates.data[chartDataKey],
                          interval: chart?.interval,
                          value: null
                        });
                      }
                    }
                  } else if (typeof chartData === 'object' && chartData !== null) {
                    Object.entries(chartData).forEach(([_id, values]) => {
                      if (Array.isArray(values)) {
                        const dataLength = values.length;

                        if (dataLength > 1) {
                          const prevLastPoint = values[dataLength - 2];
                          const intervalMS = chart.dataSingleInterval * 4.1;

                          if (newPoint.timestamp - prevLastPoint[0] > intervalMS && (prevLastPoint[1] !== null || newPoint?.[_id] !== null)) {
                            fillEmptyDataBetweenLastPoints({
                              data: chartUpdates.data[chartDataKey][_id],
                              interval: chart?.interval,
                              value: chartDataKey === 'temperatures' ? null : 0
                            });
                          }
                        }
                      }
                    });
                  }
                });
              }

              // clear buffer and apply updates
              return chartChangedInRealTime(chartUpdates.data, chartUpdates.dataSum, expectedFrom, expectedTo, scaleMS, newActiveDevices, generateEmptyBuffer(newActiveDevices));
            }
            logBig('Adding data to buffer');
            return chartDefineBuffer(newBuffer); // save updates to buffer
          }

          logBig('Buffering if OFF');
        }
      }
      return { type: 'empty' };
    })
  );
}
