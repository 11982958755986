/* eslint space-before-function-paren: 0 */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { clone, pick } from 'lodash';
// eslint-disable-next-line import/no-named-as-default-member
import CustomSearchField from './CustomSearchField';
// eslint-disable-next-line import/no-named-as-default-member
import ExpanderMultiFilter from './ExpanderMultiFilter';
import {
  monitoringGetInstallers,
  monitoringClearFilters,
  employeeSendRequest
} from '../actions';
import { dataListSendRequest } from '../../DataList/actions';
import '../index.css';
import { DATA_LIST_ID, GET_INSTALLERS_PARAMS, EMPLOYEE_LIST_ID, WORK_STATUS_OBJ } from '../constants';
import i18n from '../../../i18n';
import {
  allDataSelector,
  allInstallersListSelector,
  employeesForFilterSelector,
  monitoringSelector,
  myRoleTypeSelector,
  pvInstallerFilterSelector,
  userCompanyIdSelector,
  userIdSelector
} from './selectors';
import { oemIdSelector } from '../selectors';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
import { getPagesSettingSelector } from '../../PagesSettings/selectors';
import { setPagesSettingQueries } from '../../PagesSettings/actions';

const transformResponse = (data) => {
  const clonedData = clone(data.list);
  const user = {
    first_name: 'Unknown',
    last_name: 'User',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };
  const gateway = {
    sm_id: 'Unknown Id',
    _id: 'Unknown Id',
    signal: 'not connected',
    name: 'Unknown Name',
    id: 'Unknown Id',
    lastErrorDate: new Date(),
    errorStatus: 'Unknown Status'
  };

  const company = {
    _id: 'Unknown Id',
    name: 'Unknown Name'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }

    if (!obj.gateway) {
      obj.gateway = gateway;
    }

    if (!obj.company) {
      obj.company = company;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

export const MainMonitoringFilterV2 = () => {
  const dispatch = useDispatch();

  const { [DATA_LIST_ID]: settings } = useSelector(getPagesSettingSelector);
  const monitoring = useSelector(monitoringSelector);
  const myRoleType = useSelector(myRoleTypeSelector);
  const allInstallers = useSelector(allInstallersListSelector);
  const userId = useSelector(userIdSelector);
  const employeesForFilter = useSelector(employeesForFilterSelector);
  const allData = useSelector(allDataSelector);
  const pvInstallerFilter = useSelector(pvInstallerFilterSelector);
  const userCompanyId = useSelector(userCompanyIdSelector);
  const oemId = useSelector(oemIdSelector);

  const parseInstallers = () => {
    const allInstallersList = (allInstallers || []).filter((obj) => Object.hasOwnProperty.call(obj, 'my_company'));
    return { options: allInstallersList.map((el) => ({ name: el.my_company.name, id: el.my_company._id, admin: el.my_company.admin })) };
  };

  const parseEmployees = () => {
    // TODO! need to refactor this old logic
    const assignList = { options: [] };

    if (!pvInstallerFilter) return assignList;

    if (['root', 'solar_admin', 'support', 'oem'].includes(myRoleType) && settings.queries.companies) {
      pvInstallerFilter.value.forEach((pvInstaller) => {
        assignList.options.push({
          name: `Admin ${pvInstaller.name}`,
          id: pvInstaller.admin
        });
      });

      const employeesForFilterEntries = Object.entries(employeesForFilter || {});

      const pvInstallerIds = pvInstallerFilter.value.map((el) => el.id);

      employeesForFilterEntries.reduce((acc, employeesForFilterEntry) => {
        if (pvInstallerIds.includes(employeesForFilterEntry[0])) {
          return acc.concat(...employeesForFilterEntry[1].data);
        }
        return acc;
      }, [])
        .forEach((el) => {
          assignList.options.push({
            name: `${el.first_name} ${el.last_name}`,
            id: el._id
          });
        });
    }

    if (['pv_installer', ...EMPLOYEE_ROLE_TYPES].includes(myRoleType)) {
      if (Array.isArray(allData) && allData.length) {
        assignList.options.push({
          name: `Admin ${allData[0].company.name} Company`,
          id: allData[0].company.admin?._id
        });
      }

      if (Object.values(employeesForFilter).length) {
        Object.values(employeesForFilter)[0].data.forEach((el) => {
          assignList.options.push({
            name: `${el.first_name} ${el.last_name}`,
            id: el._id
          });
        });
      }
    }

    return assignList;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedInstallersList = useMemo(parseInstallers, [allInstallers, settings.queries.companies]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedIAsignedList = useMemo(parseEmployees, [myRoleType, settings.queries.companies, pvInstallerFilter?.value, employeesForFilter, allData]);
  const isActiveResetBtn = Object.values(
    pick(settings.queries, ['done', 'search', 'supportContractStatus', 'isInstallationCompleted', 'companies', 'assigned', 'status'])
  ).some((value) => Boolean(value));

  console.log(isActiveResetBtn, 'isDisabledResetBtn');
  const getEmployees = (data) => {
    const listURL = '../users/employee';

    const idOfInstaller = data.companies?.split(',').pop();

    const params = {
      _limit: 50,
      _start: 0,
      _sort: 'first_name:asc',
      company: idOfInstaller
    };

    dispatch(employeeSendRequest(idOfInstaller, EMPLOYEE_LIST_ID, { listURL, params, transformResponse }, 'employeesForFilter'));
  };

  const setFields = (data, isRefetchEmployee) => {
    const extraParams = { oems: myRoleType === 'oem' ? oemId : undefined };

    dispatch(setPagesSettingQueries(DATA_LIST_ID, data));
    dispatch(dataListSendRequest(DATA_LIST_ID, {
      listURL: '../errormonitoring/tickets',
      params: { ...settings.queries, ...data, ...extraParams },
      transformResponse
    }));

    if (isRefetchEmployee) {
      getEmployees(data);
    }
  };

  const resetFilters = () => {
    setFields({
      done: false,
      search: undefined,
      supportContractStatus: undefined,
      isInstallationCompleted: undefined,
      companies: undefined,
      assigned: undefined,
      status: undefined,
      _sort: 'lastErrorDate:asc'
    });
    dispatch(monitoringClearFilters());
  };

  useEffect(() => {
    const getInstallersUrl = {
      support: '/../users/get-supported-pv-installer-users',
      oem: `/../oem/connected-pv-installers/${userId}`,
      default: '/../users/pv-installer-users'
    };

    const GET_INSTALLERS_URL = getInstallersUrl[myRoleType] || getInstallersUrl.default;

    if (['root', 'solar_admin', 'support', 'oem'].includes(myRoleType)) {
      dispatch(monitoringGetInstallers(GET_INSTALLERS_URL, GET_INSTALLERS_PARAMS));
    } else {
      getEmployees({ value: [{ id: userCompanyId }] });
    }
  }, [myRoleType]); // eslint-disable-line

  return (
    <div className="main-filter-monitoring-container m--margin-top-20 m--margin-bottom-30">
      <div className="row d-flex align-items-start">
        <div className="custom-gap col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto">
          <span className="search-field">
            {i18n.t('search')}
            :
          </span>
          <CustomSearchField
            placeholder={i18n.t('searchAllTablesElements')}
            fieldName="CustomSearch"
            listID={DATA_LIST_ID}
            onChangeField={setFields}
            initialValueForCustomSearch={settings.queries.search || ''}
          />
        </div>
        {
          (['root', 'solar_admin', 'support', 'oem'].includes(myRoleType)) && (
            <div className="custom-gap col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto">
              <span>
                {i18n.t('PV Installer')}
                :
              </span>
              <ExpanderMultiFilter
                defaultValue={i18n.t('selectPV')}
                fieldName="PVInstallerFilter"
                id="pvisntaller_filter-reg"
                choosingMultiList={parsedInstallersList}
                queryName="companies"
                onChangeField={setFields}
                listID={DATA_LIST_ID}
                monitoring={monitoring}
              />
            </div>
          )
        }

        <div className="custom-gap col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto">
          <span>
            {i18n.t('assign')}
            :
          </span>
          <ExpanderMultiFilter
            defaultValue={i18n.t('selectAssign')}
            fieldName="AssignFilter"
            queryName="assigned"
            id="assign_filter-reg"
            choosingMultiList={parsedIAsignedList}
            onChangeField={setFields}
            listID={DATA_LIST_ID}
            monitoring={monitoring}
          />
        </div>

        <div className="custom-gap col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto">
          <span>
            {i18n.t('workStatusFilterLabel')}
            :
          </span>
          <ExpanderMultiFilter
            defaultValue={i18n.t('workStatusFilterPlaceholder')}
            fieldName="workStatusFilter"
            queryName="status"
            id="status_filter-reg"
            choosingMultiList={WORK_STATUS_OBJ}
            onChangeField={setFields}
            listID={DATA_LIST_ID}
            monitoring={monitoring}
          />
        </div>

        <div className="switch-container col-xl-auto col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto align-self-center">
          <div className="d-flex align-items-center">
            <div className="monitoring-switch d-flex align-items-center">
              <Switch
                onChange={(value) => setFields({ isInstallationCompleted: value || undefined })}
                checked={settings.queries.isInstallationCompleted}
                width={36}
                height={18}
                onColor="#ffb008"
                className="react-switch"
              />
            </div>
            <span>{i18n.t('installationStage')}</span>
          </div>

          <div className="d-flex align-items-center">
            <div className="monitoring-switch d-flex align-items-center">
              <Switch
                onChange={(value) => setFields({ supportContractStatus: value || undefined })}
                checked={settings.queries.supportContractStatus}
                width={36}
                height={18}
                onColor="#ffb008"
                className="react-switch"
              />
            </div>
            <span>{i18n.t('onlySupportContracts')}</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="monitoring-switch d-flex align-items-center">
              <Switch
                onChange={(value) => setFields({ done: value })}
                checked={settings.queries.done}
                width={36}
                height={18}
                onColor="#ffb008"
                className="react-switch"
              />
            </div>
            <span>{i18n.t('archivedErrors')}</span>
          </div>
        </div>

        <div className="resetBtn col-xl-auto col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 align-self-center">
          <button
            className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst exportBtn ${isActiveResetBtn ? '' : 'btn-disabled'}`}
            type="button"
            onClick={resetFilters}
            disabled={!isActiveResetBtn}
          >
            {i18n.t('resetFilters')}
          </button>
        </div>
      </div>
    </div>
  );
};
