import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LicenseBlock = styled.label``;

const LicenseTitle = styled.div`
  font-size: 19px;
  color: ${(props) => (props.selected ? '#ffb822' : '#fff')};
  margin: 2px 0 4px 0;
  
  ${LicenseBlock}:hover & {
    ${(props) => !props.selected && 'color: #ffe5ad;'}
  }
`;

const LicenseDescription = styled.div`
  color: #8f939b;
  margin: 0px 0 5px 34px;
`;

const LicenseElement = ({
  name, title, description, value, selected, disabled, needBR, callback
}) => {
  const id = `radio_${name}_${value}`;
  return (
    <>
      <LicenseBlock
        htmlFor={id}
        className="customRadio"
      >
        <LicenseTitle selected={selected}>
          <input
            type="radio"
            name={name}
            id={id}
            onChange={callback}
            value={value}
            checked={selected}
            disabled={disabled}
          />
          <div className={`checkmark ${disabled ? 'disabled' : ''}`} />
          {title}
        </LicenseTitle>
        <LicenseDescription>{description}</LicenseDescription>
      </LicenseBlock>
      {needBR && <br />}
    </>
  );
};

LicenseElement.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  needBR: PropTypes.bool
};

LicenseElement.defaultProps = {
  selected: false,
  name: undefined,
  description: '',
  disabled: false,
  needBR: true
};

export default LicenseElement;
