import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';
import SignUpForm2 from './SignUpForm2';
import { signUpVerify, signUpSend } from './actions';
import '../../App.css';
import { transformLanguageForAPI, changeLanguageTo } from '../../i18n';
import {
  errorMessageSelector,
  initialValuesSelector,
  stopMessageSelector,
  verifiedSelector
} from './selectors';

import { parsePhoneNumber } from '../validation';

/**
 * @module SignUp2
 */
/**
 * @description renders Sign Up page(second step)
 * @memberof module:SignUp2
 * @extends Component
 */

const SignUp2 = (props) => {
  const { verified, initialValues, errorMessage, stopMessage, t } = props;

  useEffect(() => {
    const { signUpVerify: signUpVerifyFromProps, match, i18n } = props;
    const { verifyCode } = match.params;
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lang') ? urlParams.get('lang') : i18n.language;
    changeLanguageTo(language);
    signUpVerifyFromProps(verifyCode, transformLanguageForAPI(language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Sends request to register company account
   */

  const onSubmit = (values) => {
    const { signUpSend: signUpSendFromProps, i18n } = props;
    signUpSendFromProps({
      ...values,
      language: transformLanguageForAPI(i18n.language),
      phone: parsePhoneNumber(values?.phone || undefined)
    });
  };

  return (
    <SignUpForm2
      onSubmit={onSubmit}
      verified={verified}
      initialValues={initialValues}
      errorMessage={errorMessage}
      stopMessage={stopMessage}
      enableReinitialize
      t={t}
    />
  );
};

SignUp2.propTypes = {
  signUpVerify: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  signUpSend: PropTypes.func.isRequired,
  verified: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  errorMessage: PropTypes.string,
  stopMessage: PropTypes.string,
  i18n: PropTypes.instanceOf(Object),
  t: PropTypes.instanceOf(Object)
};

SignUp2.defaultProps = {
  verified: undefined,
  initialValues: undefined,
  errorMessage: undefined,
  stopMessage: undefined,
  i18n: {},
  t: undefined
};

const mapStateToProps = createStructuredSelector({
  verified: verifiedSelector,
  initialValues: initialValuesSelector,
  errorMessage: errorMessageSelector,
  stopMessage: stopMessageSelector
});

export default connect(mapStateToProps, {
  push,
  signUpVerify,
  signUpSend
})(SignUp2);
