import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

/**
 * All available input functions
 */
const functions = {
  1: 'switchOffBlocked',
  2: 'restrictToMin',
  3: 'restrictTo50',
  4: 'increaseToMax',
  5: 'fromGridMaximumUsage',
  6: 'fromGridBlocked'
};

/**
 * Render function for React-Table. Column for input device function.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {string} props.original.inputDeviceFunction - Input device function id
 */
const renderInputDeviceFunction = ({ original: { inputDeviceFunction } }) => (
  functions?.[inputDeviceFunction]
    ? <>{i18n.t(functions[inputDeviceFunction])}</>
    : null
);

renderInputDeviceFunction.propTypes = {
  inputDeviceFunction: PropTypes.string.isRequired
};

export default renderInputDeviceFunction;
