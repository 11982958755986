import * as actionTypes from './constants';

export function forgotPasswordSend(counter, fields) {
  return {
    type: actionTypes.FORGOT_PASSWORD_SEND,
    payload: {
      counter,
      fields
    }
  };
}

export function forgotPasswordReceived(email, verifyEmail) {
  return {
    type: actionTypes.FORGOT_PASSWORD_RECEIVED,
    payload: {
      email,
      verifyEmail
    }
  };
}

export function forgotPasswordCanceled(message) {
  return {
    type: actionTypes.FORGOT_PASSWORD_CANCELED,
    payload: {
      message
    }
  };
}
