import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Row from './Row';

const Table = ({ data }) => (
  <table className="company-details-table">
    <tbody>
      {data.map((row) => <Row key={uuidv4()} data={row} />)}
    </tbody>
  </table>
);

Table.propTypes = {
  data: PropTypes.instanceOf(Object)
};

Table.defaultProps = {
  data: []
};

export default Table;
