import * as actionTypes from './constants';
import { SETTING_PROF_GET_USER_SUCC } from '../SettingProfile/constants';

const initialState = {
  verifyingJWT: true
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SIGN_OUT_AND_GO_TO:
    case actionTypes.USER_LOGOUT: {
      return {};
    }
    case actionTypes.SIGN_IN_GO_BACK_IF_SUCC:
      return state.userDevices
        ? state
        : {
          needGoBackAfterLogin: action.payload.needGoBackAfterLogin
        };
    case actionTypes.SIGN_IN_UPDATE_USER:
    case actionTypes.SIGN_IN_VALID_JWT:
    case actionTypes.SIGN_IN_RECEIVED:
      return {
        user: action.payload.user
      };
    case SETTING_PROF_GET_USER_SUCC: // при відкриванні юзер профайл обновлювати даного користувача
      if (action.payload.myself) {
        return {
          user: action.payload.initialData
        };
      }
      return state;
    case actionTypes.SIGN_IN_INVALID_JWT:
      return {
        needGoBackAfterLogin: state.needGoBackAfterLogin
      };
    default:
      return state;
  }
};
