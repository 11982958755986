import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import DataList from '../../../DataList';
import TabContentContainer from '../../components/TabContentContainer';

import { SENSORS_LIST_ID } from '../../../UserDetails/constants';

import i18n from '../../../../i18n';

import columns from '../../columns';

/**
 * @module DevicesTable
 */
/**
 * Smart Meter tab from DevicesTable
 * @memberof module:DevicesTable
 */
const SmartMeterTab = (props) => {
  const {
    transformResponse,
    isExistSmartMeter,
    openAddDevices,
    defaultParams,
    subMetersUrl,
    sensorsData,
    myRoleType,
    devicesUrl,
    userId,
    total
  } = props;

  const viewers = ['viewer', 'pv_installer_employee_read_only'];

  const subSensorsConfig = {
    parentSensorUrl: devicesUrl
  };

  const columnsSmartMeter = useMemo(
    () => columns(myRoleType, userId, 'smart-meter', total, sensorsData),
    [myRoleType, sensorsData, total, userId]
  );

  const columnsSubMeters = useMemo(
    () => columns(myRoleType, userId, 'sub-meter', total, sensorsData),
    [myRoleType, sensorsData, total, userId]
  );

  const [subTableColumns, setSubTableColumns] = useState([...columnsSubMeters]);

  const onResizedChange = useCallback(
    (newResized) => {
      const columnsWidth = subTableColumns.map((column) => {
        const currentColumn = newResized.find((resized) => resized.id === column.id);

        if (currentColumn) {
          return {
            ...column,
            minWidth: currentColumn.value,
            width: currentColumn.value
          };
        }
        return column;
      });
      setSubTableColumns(columnsWidth);
    },
    [subTableColumns]
  );

  return (
    <>
      {!viewers.includes(myRoleType) && !isExistSmartMeter && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddDevices}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addSmartMeterBtn')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded smart-meters-data-list">
        <DataList
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            onResizedChange,
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columnsSmartMeter,
            showPagination: false,
            sorted: []
          }}
        />
      </div>
      {isExistSmartMeter && (
        <>
          <Tabs onSelect={() => { }} selectedTabClassName="sub-meter" className="sub-meter">
            <TabList>
              <Tab disabled>
                <TabContentContainer className="sub-meter-container">{i18n.t('Sub Meters')}</TabContentContainer>
              </Tab>
            </TabList>
            {/* fix warning: There should be an equal number of 'Tab' and 'TabPanel' in `UncontrolledTabs` */}
            <TabPanel />
          </Tabs>
          {!viewers.includes(myRoleType) && (
          <div className="devices-tab-panel col-auto">
            <a
              onClick={openAddDevices}
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
            >
              <span>{i18n.t('addSubMetersBtn')}</span>
            </a>
          </div>
          )}
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded sub-meter-data-list">
            <DataList
              listID={SENSORS_LIST_ID}
              listURL={subMetersUrl}
              params={defaultParams}
              transformResponse={transformResponse}
              type="smart-meter"
              subSensorsConfig={subSensorsConfig}
              Component={ReactTable}
              componentProps={{
                // eslint-disable-next-line arrow-body-style
                NoDataComponent: () => {
                  return (
                    <div
                      className="rt-noData customNoData"
                      style={{
                        top: '50%'
                      }}
                    >
                      {i18n.t('noRowsFound')}
                    </div>
                  );
                },
                defaultPageSize: 100,
                className: '-highlight m-datatable__table',
                columns: subTableColumns,
                showPagination: false,
                sorted: []
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

SmartMeterTab.propTypes = {
  myRoleType: PropTypes.string,
  devicesUrl: PropTypes.string.isRequired,
  subMetersUrl: PropTypes.string.isRequired,
  userId: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  sensorsData: PropTypes.instanceOf(Object),
  openAddDevices: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  isExistSmartMeter: PropTypes.bool.isRequired
};

SmartMeterTab.defaultProps = {
  userId: undefined,
  myRoleType: undefined,
  sensorsData: undefined,
  total: undefined
};

export default SmartMeterTab;
