import { DEVICES_LIMIT } from './constants';
import getColor from './getColor';

const updateDeviceList = (devices, newDevice) => {
  const isTag = Object.prototype.hasOwnProperty.call(newDevice, 'tag');
  const name = isTag ? newDevice.tag : newDevice.type;
  const device = devices.find((d) => (d.id === newDevice.id && d.isTag === isTag));

  if (typeof device === 'undefined') {
    if (devices.length >= DEVICES_LIMIT) {
      return null;
    }
    const y = devices.push({
      id: newDevice.id,
      name,
      color: newDevice.color || getColor(name, isTag),
      isTag
    }) - 1;
    devices[y].y = y;
    return devices[y];
  }
  return device;
};

export default updateDeviceList;
