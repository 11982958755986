import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

/**
 * Utils
 * @ignore
 */
import {
  getUniqueTypesList
} from '../utils';
import {
  getDeviceTagsOptions
} from '../utils/getDeviceTagOptions';

/**
 * Constants
 * @ignore
 */
import {
  addDeviceFormId,
  allowedAffectedDeviceTypes,
  editDeviceFormId
} from '../constants';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';

const getIsDisabledOauth2EditModal = (state) => state.sensorsOauth2?.isDisabledOauth2EditModal;
const getSensorsConfigurationFile = (state) => state.devicesTable?.sensorsConfigurationFile;
const getSensorsPresettings = (state) => state.devicesTable.sensorsPresettings;
const getProfileName = (state, props) => props?.initialValues?.data?.ctaType;
const getDevicesTypesList = (state) => state.devicesTable?.devicesTypesList;
const getSensorsOauth2FormData = (state) => state.sensorsOauth2?.formData;
const getDeviceTags = (state) => state.dataList?.deviceTags?.data || [];
const getModalData = (state) => state.modals?.removeData?.data || {};
const getSensors = (state) => state.devicesTable?.usersSensors;
const getIsLoading = (state) => state.modals?.isLoading;
const getModalIP = (state) => state.modals?.searchIP;
const getUserDevice = (state) => state.userDevices;
const getUser = (state) => state.signIn?.user;
const getModals = (state) => state.modals;
const getInstalledSM = (state) => state.dataList?.installed_sm;


/**
 * GetUserDevice selectors
 * @ignore
 */
export const devicesListSelector = createSelector(
  [getUserDevice],
  (userDevices) => userDevices?.devicesList
);

export const loadSelector = createSelector(
  [getUserDevice],
  (userDevices) => userDevices?.load
);

export const ipsSelector = createSelector(
  [getUserDevice],
  (userDevices) => userDevices?.ips
);

export const gatewayIdSelector = createSelector(
  [getUserDevice],
  (userDevices) => userDevices?.user?.gateway?._id
);

/**
 * GetSensors selectors
 * @ignore
 */
export const sensorsSelector = createSelector(
  [getSensors],
  (sensors) => sensors
);

export const affectedDevicesOptionsSelector = createSelector(
  [getSensors, getDeviceTags],
  (sensors, deviceTags) => sensors
    .filter(({ type }) => allowedAffectedDeviceTypes.includes(type))
    .map(({ _id, device_group, tag }) => ({
      label: tag
        ? getDeviceTagsOptions(deviceTags).find((deviceTag) => deviceTag.value === tag)?.label
        : i18n.t(device_group),
      value: _id
    }))
);

export const inputDevicesOptionsSelector = createSelector(
  [getSensors],
  (sensors) => sensors
    .filter(({ type }) => ['Input Device'].includes(type))
    .map(({ _id, device_group, description }) => ({
      label: description ? description : i18n.t(device_group),
      value: _id
    }))
);


/**
 * GetSensorsConfigurationFile selectors
 * @ignore
 */
export const sensorsConfigurationFileSelector = createSelector(
  [getSensorsConfigurationFile],
  (file) => file?.data
);

export const isExpiredSensorsConfigurationFileSelector = createSelector(
  [getSensorsConfigurationFile],
  (data) => {
    const { createdAt } = data || {};
    const expiredConfigDate = new Date(createdAt?.setDate(createdAt?.getDate() + 1));
    const currentDate = new Date();
    return currentDate > expiredConfigDate;
  }
);

/**
 * Other selectors
 * @ignore
 */
export const modalsSelector = createSelector(
  [getModals],
  (modals) => modals
);

export const myRoleTypeSelector = createSelector(
  [getUser],
  (user) => user?.role?.type
);

export const isLoadingSelector = createSelector(
  [getIsLoading],
  (isLoading) => !!isLoading
);

export const sensorsPresettingsSelector = createSelector(
  [getSensorsPresettings],
  (sensorsPresettings) => sensorsPresettings
);

export const modalDataSelector = createSelector(
  [getModalData],
  (data) => data
);

export const profileNameSelector = createSelector(
  [getProfileName],
  (ctaType) => ctaType
);

export const modalIPSelector = createSelector(
  [getModalIP],
  (ip) => ip
);

export const devicesTypesList = createSelector(
  [getDevicesTypesList],
  (types) => getUniqueTypesList(types)
);

export const deviceTagsSelector = createSelector(
  [getDeviceTags],
  (deviceTags) => getDeviceTagsOptions(deviceTags)
);

export const sensorsOauth2FormDataSelector = createSelector(
  [getSensorsOauth2FormData],
  (data) => data
);

export const isDisabledOauth2EditModalSelector = createSelector(
  [getIsDisabledOauth2EditModal],
  (data) => data
);

export const getInstalledSMSearchParamsSelector = createSelector(
  [getInstalledSM],
  (installed_sm) => installed_sm?.params
);

/**
 * AddDeviceWindow selectors
 */
const addDeviceFormSelector = formValueSelector(addDeviceFormId);

export const addDeviceFormProfile_name = (state) => addDeviceFormSelector(state, 'profile_name');
export const addDeviceFormDevice_group = (state) => addDeviceFormSelector(state, 'device_group');
export const addDeviceFormType = (state) => addDeviceFormSelector(state, 'type');
export const addDeviceFormData = (state) => addDeviceFormSelector(state, 'data');


/**
 * EditDeviceWindow selectors
 */
const editDeviceFormSelector = formValueSelector(editDeviceFormId);

export const editDeviceFormDevice_group = (state) => editDeviceFormSelector(state, 'device_group');
export const editDeviceFormType = (state) => editDeviceFormSelector(state, 'type');
export const editDeviceFormData = (state) => editDeviceFormSelector(state, 'data');
