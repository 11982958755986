import { from, of } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import axios from 'axios';
import { push } from 'react-router-redux';
import Cookies from 'universal-cookie';

import { setPaymentUrlSuccess, setPaymentUrlFailure } from './actions';
import { handleErrorDetailed } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/actions';
import i18n from '../../i18n';
import * as paymentsTypes from './constants';
import { SOLAR_PAYMENT_SERVICE } from '../../config';

function getPaymentUrlRequestEpic(action$) {
  return action$.pipe(
    ofType(paymentsTypes.FETCH_PAYMENT_URL_REQUEST),
    switchMap(() => {
      const cookies = new Cookies();
      const jwt = cookies.get('jwt');
      return from(axios.get(
        `${SOLAR_PAYMENT_SERVICE}/sm/web-view-url`,
        {
          headers: {
            Authorization: jwt.startsWith('Bearer ') ? jwt : `Bearer ${jwt}`
          }
        }
      )).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error && result?.data?.webViewUrl) {
            return of(
              setPaymentUrlSuccess(result.data.webViewUrl)
            );
          }
          return of(
            setPaymentUrlFailure(),
            push('/payments'),
            addNotification({
              type: 'error',
              text: result.message || i18n.t('error')
            })
          );
        })
      );
    })
  );
}

function redirectToPaymentPageEpic(action$) {
  return action$.pipe(
    ofType(paymentsTypes.REDIRECT_TO_PAYMENT_PAGE),
    switchMap(({ payload: { endUserId, url } }) => {
      const cookies = new Cookies();
      const jwt = cookies.get('jwt');
      if (!jwt) {
        return of(
          addNotification({
            type: 'error',
            text: i18n.t('error')
          })
        );
      }

      if (url) {
        const userIdPath = endUserId ? `/${endUserId}` : '';

        window.location.href = `${url}/${jwt}${userIdPath}?app=web`;
        return of(
          addNotification({
            type: 'success',
            text: i18n.t('success')
          })
        );
      }

      return from(axios.get(
        `${SOLAR_PAYMENT_SERVICE}/web-view-url`,
        {
          headers: {
            Authorization: jwt.startsWith('Bearer ') ? jwt : `Bearer ${jwt}`
          }
        }
      )).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error && result?.data?.webViewUrl) {
            window.location.href = `${result.data.webViewUrl}/${jwt}/${endUserId}?app=web`;
            return of(
              addNotification({
                type: 'success',
                text: i18n.t('success')
              })
            );
          }
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        })
      );
    })
  );
}

export default combineEpics(
  getPaymentUrlRequestEpic,
  redirectToPaymentPageEpic
);
