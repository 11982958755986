export const DATA_LIST_ID = 'company_sm_oem';

export const GET_ALL_INSTALLERS = 'GET_ALL_INSTALLERS';
export const SET_ALL_INSTALLERS = 'SET_ALL_INSTALLERS';
export const GET_ALL_CONNECTED_INSTALLERS = 'GET_ALL_CONNECTED_INSTALLERS';
export const SET_ALL_CONNECTED_INSTALLERS = 'SET_ALL_CONNECTED_INSTALLERS';

/**
 * AddPVInstallerToOEMWindow form ids
 */
export const addPvInstallerToOEMForm = 'addPvInstallerToOEMForm';

export const addPvInstallerToOEM = 'addPvInstallerToOEM';

export const GET_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'my_company:asc'
};

export const GET_ALL_CONNECTED_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'first_name:asc'
};

export const GET_INSTALLERS_URL = {
  support: '/../users/get-supported-pv-installer-users',
  default: '/../users/pv-installer-users'
};

export const COMPANY_DATALIST_ID = 'current_company';

export const subTabs = ['end-users', 'employees'];

export const getConnectedInstallersRequestData = (params) => {
  const {
    myRoleType,
    supportOemId,
    companyOemId,
    defaultRoleParams
  } = params;

  const connectedInstallersRequest = {
    support: {
      GET_CONNECTED_INSTALLERS_URL: '/../users/get-supported-pv-installer-users',
      GET_CONNECTED_INSTALLERS_PARAMS: { ...GET_ALL_CONNECTED_INSTALLERS_PARAMS, oemId: supportOemId }
    },
    default: {
      GET_CONNECTED_INSTALLERS_URL: `/../oem/connected-pv-installers/${companyOemId}`,
      GET_CONNECTED_INSTALLERS_PARAMS: { ...GET_ALL_CONNECTED_INSTALLERS_PARAMS, ...defaultRoleParams }
    }
  };

  const URL = GET_INSTALLERS_URL[myRoleType] || GET_INSTALLERS_URL.default;

  const {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS
  } = connectedInstallersRequest?.[myRoleType] || connectedInstallersRequest.default;

  return {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS,
    URL
  };
};

export const getAllInstallersRequestData = (params) => {
  const {
    myRoleType,
    companyOemId,
    supportRoleParams,
    defaultRoleParams
  } = params;

  const getConnectedInstallersRequest = {
    support: {
      GET_ALL_INSTALLERS_URL: '/../users/get-supported-pv-installer-users',
      GET_ALL_INSTALLERS_PARAMS: supportRoleParams
    },
    default: {
      GET_ALL_INSTALLERS_URL: `/../oem/connected-pv-installers/${companyOemId}`,
      GET_ALL_INSTALLERS_PARAMS: defaultRoleParams
    }
  };

  const { GET_ALL_INSTALLERS_URL, GET_ALL_INSTALLERS_PARAMS } = getConnectedInstallersRequest[myRoleType] || getConnectedInstallersRequest.default;

  return {
    GET_ALL_INSTALLERS_URL,
    GET_ALL_INSTALLERS_PARAMS
  };
};
