import * as actionTypes from './constants';

const initialState = {
  selected: [],
  versionsList: [],
  hardwaresList: [],
  licensesList: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.INSTALLED_SM_REPLACE_SELECTED:
      return {
        ...state,
        selected: action.payload.newSelected
      };
    case actionTypes.INSTALLED_SM_SEND_UPDATE:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.INSTALLED_SM_SEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.INSTALLED_SM_REQUEST_STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.INST_SM_FW_VERSIONS_RECEIVED:
      return {
        ...state,
        versionsList: action.payload.versionsList
      };
    case actionTypes.INST_SM_CHANGE_DEBUGGING:
      return state;
    case actionTypes.FETCH_HARDWARE_LIST_SUCCESS:
      return {
        ...state,
        hardwaresList: action.payload.hardwareList?.hardware,
        licensesList: action.payload.hardwareList?.licenses,
        defaultLicenseId: action.payload.hardwareList?.defaultLicenseId
      };
    default:
      return state;
  }
};
