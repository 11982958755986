import React, { Component } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './index.css';
import { countryLetters, countriesSymbols } from './constants';
import i18n from '../../../i18n';

class UserPhoneInput extends Component {
  constructor(props) {
    super(props);
    const { country, input: { value: phone } } = props;
    this.state = {
      phone,
      country,
      phoneCountryCode: ''
    };
    this.setPhone = this.setPhone.bind(this);
    this.input = props.input;
  }

  /**
   * Set phone number into input field not connected to the end user's country but phone number country code
   * @param {string} phoneNumber string with phone number from input
   */
  setPhone(phoneNumber) {
    const { input } = this.props;
    this.setState({ phone: phoneNumber });
    const parsedNumber = phoneNumber && parsePhoneNumber(phoneNumber);
    if (parsedNumber) {
      const { country: countryFromPhone = '', countryCallingCode = '', nationalNumber = '' } = parsedNumber;
      this.setState({ phoneCountryCode: countryFromPhone });
      input.onChange(countryCallingCode + nationalNumber);
    } else {
      input.onChange('');
    }
  }

  UNSAFE_componentWillMount() {
    const { phone, country } = this.state;
    const parsedNumber = phone && parsePhoneNumber(`+${phone.replace(/\D/g, '')}`);
    if (parsedNumber) {
      const { country: countryFromPhone = '', number = '' } = parsedNumber;
      this.setState({ phone: number, phoneCountryCode: countryFromPhone || countryLetters[country] });
    }
  }

  render() {
    const { label, disabled, meta: { touched, error } } = this.props;
    const { phone } = this.state;

    return (
      <>
        <p className="phone-label">
          {label}
        </p>
        <PhoneInput
          {...this.input}
          international
          withCountryCallingCode
          countryOptionsOrder={countriesSymbols}
          value={phone}
          disabled={disabled}
          onChange={this.setPhone}
          countries={countriesSymbols}
        />
        {touched && error && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )}
      </>
    );
  }
}

UserPhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired
};

UserPhoneInput.defaultProps = {
  country: '',
  disabled: false
};

export default connect(null, { change })(UserPhoneInput);
