import React from 'react';
import PropTypes from 'prop-types';

const renderPlant = (props) => {
  const {
    original: { user, plant: userPlant }
  } = props;
  const { plant } = user;

  return <>{plant || userPlant}</>;
};

renderPlant.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default renderPlant;
