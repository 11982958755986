import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { closeModalWindow, modalStopLoading } from './actions';
import i18n from '../../i18n';
/**
 * It is called when form from modal window is submitet
 * @memberof module:ModalWindow
 * @async
 */
function modalWindowSendEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.MODAL_WINDOW_SEND),
    map((action) => action.payload),
    switchMap(
      ({
        modalID,
        requestConfig: {
          method,
          url,
          isSubSensor,
          ...requestConfig
        },
        cb,
        reloadPage
      }) => (
        from(strapi.request(method, url, requestConfig)).pipe(
          catchError(handleErrorDetailed),
          mergeMap(
            (result) => {
              if (!result.error) {
                if (typeof reloadPage === 'boolean') {
                  window.location.reload();
                  return modalStopLoading(modalID);
                }
                if (typeof reloadPage === 'string') {
                  window.location.replace(reloadPage);
                  return modalStopLoading(modalID);
                }
                return cb ? of(
                  modalStopLoading(modalID),
                  closeModalWindow(modalID),
                  cb(result, isSubSensor),
                  addNotification({
                    type: 'success',
                    text: i18n.t('success')
                  })
                ) : of(
                  modalStopLoading(modalID),
                  closeModalWindow(modalID),
                  addNotification({
                    type: 'success',
                    text: i18n.t('success')
                  })
                );
              }
              return of(
                modalStopLoading(modalID),
                closeModalWindow(modalID),
                addNotification({
                  type: 'error',
                  text: result.message
                })
              );
            }
          )
        )
      ))
  );
}

export default combineEpics(
  modalWindowSendEpic
);
