import additionalFields from './additionalFields';

/**
 * Check if need to display field for some devices by role and returns the result of  the checking.
 * @param {string} deviceName
 * @param {string} fieldName
 * @param {string} myRoleType
 * @returns {boolean}
 */
export const displayField = (deviceName, fieldName, myRoleType) => {
  if (!additionalFields?.[fieldName]) {
    return false;
  }
  const additionalPermissionsForDisplayingDevices = [
    {
      deviceName: 'CTA heat pump',
      roles: {
        root: true,
        solar_admin: true,
        pv_installer: true,
        pv_installer_employee: true,
        support: true,
        oem: true,
        viewer: false,
        pv_installer_employee_read_only: false,
        end_user: [
          'boilerTempOverproductionEnable',
          'boilerTempOverproduction',
          'boilerTempNormal',
          'boilerTempReduced',
          'comfortOffsetEnable',
          'ComfortOffsetMinimum',
          'ComfortOffsetMaximum',
          'heatpumpControlMethod',
          'minPower'
        ].includes(fieldName)
      }
    }
  ];
  const targetDevice = additionalPermissionsForDisplayingDevices.find((device) => device.deviceName === deviceName);
  // Display the field if the device (deviceName) does not have additional rules for the device.
  if (typeof targetDevice === 'undefined') {
    return true;
  }
  const renderDeviceField = targetDevice?.roles?.[myRoleType];
  // If field does not have special permission, than display the field, else display if the permission is true.
  return typeof renderDeviceField !== 'boolean' ? true : renderDeviceField;
};

export default displayField;
