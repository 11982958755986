import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import PuffLoader from 'react-spinners/PuffLoader';
import { connect } from 'react-redux';
import store from '../../../store';
import ModalWindow from '../../ModalWindow';
import { ipsSelector, loadSelector, modalIPSelector } from './selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

const parseIps = (data) => Object.entries(data).map((item) => ({
  ...item
}));

const SearchIPWindow = (props) => {
  const { modalID, modal, reset, title, handleOnClose, load, ips } = props;

  const pushValue = (value) => {
    const formID = modal?.data?.formID;

    if (formID) {
      store.dispatch(change(formID, 'ip', value));
    }
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className={`modal-body ${load ? 'm-0' : ''}`}>
        {load && (
          <div className="mainPreLoaderParent">
            <div className="mainPreLoader">
              <PuffLoader
                size={70}
                color="#f7bd27"
                speedMultiplier={3}
              />
            </div>
          </div>
        )}
        {ips && (
          <ul className="ip-list">
            {parseIps(ips).map((ip) => (
              <li key={shortid.generate()} className="nav-item m-tabs__item ip-item">
                <span>
                  <a
                    className="nav-link m-tabs__link ip-link"
                    onClick={() => {
                      pushValue(ip[1]);
                      handleOnClose();
                    }}
                  >
                    <span className="ip-name">{ip[0]}</span>
                    <br />
                    <span className="ip-value">ip: &nbsp;</span>
                    {ip[1]}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
      </div>
    </ModalWindow>
  );
};

SearchIPWindow.propTypes = {
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  load: PropTypes.bool,
  ips: PropTypes.instanceOf(Object)
};

SearchIPWindow.defaultProps = {
  modal: {
    data: {}
  },
  load: true,
  ips: {}
};

const form = reduxForm({
  form: 'searchIPform'
})(SearchIPWindow);

const mapStateToProps = createStructuredSelector({
  modal: modalIPSelector,
  load: loadSelector,
  ips: ipsSelector
});

export default connect(mapStateToProps)(form);
