import React from 'react';
import i18n from '../../../../../i18n';
import LoadManagement from '../../../components/LoadManagement';

const allowedRoles = [
  'root',
  'solar_admin',
  'support',
  'viewer',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'oem',
  'end_user'
];

export const getColumns = ({ user = {}, myRoleType }) => [
  [{
    title: 'offset',
    titleClasses: 'general-tab-item-title',
    tooltip: 'offsetTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <p>
        <span>
          {user.settings?.offset_watt || 0}
        </span>
        <span className="params">{i18n.t('watt')}</span>
      </p>
    )
  },
  {
    title: 'kWPeakOutputLabel',
    titleClasses: 'general-tab-item-title',
    tooltip: 'kWPeakOutputTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <p>
        <span>
          {user.settings?.kWp || 0}
        </span>
        <span className="params">{i18n.t('kWPeakOutputUnits')}</span>
      </p>
    )
  },
  {
    title: 'houseFuseLabel',
    titleClasses: 'general-tab-item-title',
    tooltip: 'houseFuseTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <p>
        <span>
          {user.settings?.houseFuse || 0}
        </span>
        <span className="params">{i18n.t('houseFuseUnits')}</span>
      </p>
    )
  },
  {
    isAllowed: allowedRoles.includes(myRoleType),
    Value: LoadManagement
  }
  ],
  [
    {
      title: 'lowRate',
      noDots: true,
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => null
    },
    {
      title: 'mondayFriday',
      titleClasses: 'general-tab-item-title',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => (
        <p>
          {user.settings && `${user.settings.low_m_f_from} - ${user.settings.low_m_f_to}`}
        </p>
      )
    },
    {
      title: 'saturday',
      titleClasses: 'general-tab-item-title',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => (
        <p>
          {user.settings && `${user.settings.low_sat_from} - ${user.settings.low_sat_to}`}
        </p>
      )
    },
    {
      title: 'sunday',
      titleClasses: 'general-tab-item-title',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => (
        <p>
          {user.settings && `${user.settings.low_sun_from} - ${user.settings.low_sun_to}`}
        </p>
      )
    }
  ]
];
