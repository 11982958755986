/* eslint-disable import/no-named-as-default-member */
import { translate } from 'react-i18next';
import React from 'react';
import * as workerTimers from 'worker-timers';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import SignUp2 from './containers/SignUp2';
import RootSignIn from './containers/RootSignIn';
import OAuthSignIn from './containers/OAuthSignIn';
import ForgotPassForm from './containers/ForgotPass';
import ResetPassForm from './containers/ResetPass';
import SettingProfile from './containers/SettingProfile';
import Users from './containers/Users';
import Employees from './containers/Employees';
// eslint-disable-next-line import/no-named-as-default-member
import PvInstallers from './containers/PvInstallers';
import OEM from './containers/OEM';
import NotFound from './containers/NotFound';
import Supports from './containers/Supports';
import SupportDetails from './containers/SupportDetails';
import Viewers from './containers/Viewers';
// eslint-disable-next-line import/no-named-as-default-member
import InstalledSM from './containers/InstalledSM';
import UserDetails from './containers/UserDetails';
import CompanyDetails from './containers/CompanyDetails';
import PVInstallersDetails from './containers/PVInstallersDetails';
import Admins from './containers/Admins';
// eslint-disable-next-line import/no-named-as-default-member
import Monitoring from './containers/Monitoring';
import MonitoringNotification from './containers/MonitoringNotification';
import Terms from './containers/Terms';
import VerifyChangeEmail from './containers/VerifyChangeEmail';
import {
  ProtectedRoute, OnlyGuests, HomePage, CheckAuthorization, AuthorizationApp
} from './containers/Authorization';
import DashboardInfo from './components/DashboardInfo';
import PowerFlowDetailsInfo from './components/PowerFlowDetailsInfo';
import PredictionChart from './containers/PredictionChart';
import BasicAuthSignIn from './containers/BasicAuthSignIn';
import NoAccess from './containers/NoAccess';
import LoadManagementDashboard from './containers/LoadManagementDashboard';
import BurkhalterAuth from './containers/Burkhalter';
import { APP_ID, SOLAR_MANAGER_APP_ID } from './config';
import Overview from './containers/Overview';
import Documentation from './containers/Documentation';
// TODO: to comment before release sprint-7 (uncomment after)
// import PaymentsAuthorization from './containers/Payments/Authorization';
// import Payments from './containers/Payments';
import ViewersDetails from './containers/ViewersDetails';
import InvitedUsers from './containers/InvitedUsers';
import { EMPLOYEE_ROLE_TYPES } from './containers/UserDetails/constants';


function retry(fn, retriesLeft = 2, interval = 1200) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        workerTimers.setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const Dashboard = React.lazy(() => retry(() => import('./containers/Dashboard')));

const SensorsOauth2 = React.lazy(() => import('./containers/SensorsOauth2'));

const PredictionChartError = React.lazy(() => import('./containers/PredictionChartError'));

const authSSOBurkhalter = (APP_ID === SOLAR_MANAGER_APP_ID)
  ? [{
    path: '/auth/sso/burkhalter',
    component: BurkhalterAuth,
    key: '#authSSOBurkhalter'
  }]
  : [];


/**
 * List of all routes.
 */
const routes = [
  ...authSSOBurkhalter,
  {
    path: '/check-authorization',
    exact: true,
    component: CheckAuthorization(HomePage),
    key: '#check-authorization'
  },

  // Routes for WebView
  {
    path: '/mobile/powerFlowDetails/info/:jwt',
    exact: true,
    component: translate()(PowerFlowDetailsInfo),
    key: '#powerFlowDetails-info'
  },
  {
    path: '/dashboard-info',
    exact: true,
    component: translate()(DashboardInfo),
    key: '#dashboard-info'
  },
  {
    path: '/dashboard-app/:userID/:jwt/:groupPixelWidth?',
    exact: true,
    component: AuthorizationApp(Dashboard, false, { disableAutoReload: true, disableURLParams: true }),
    key: '#dashboard-app'
  },
  {
    path: '/dashboard-app-v2/:userID/:jwt/:groupPixelWidth?',
    exact: true,
    component: AuthorizationApp(Dashboard),
    key: '#dashboard-app'
  },
  {
    path: '/prediction-chart/:jwt',
    exact: true,
    component: AuthorizationApp(PredictionChart, ['end_user']),
    key: '#prediction'
  },
  {
    path: '/mobile/prediction-chart-error/:jwt',
    exact: true,
    component: AuthorizationApp(PredictionChartError, ['end_user']),
    key: '#predictionChartError'
  },

  // Private route
  {
    path: '/root-login',
    exact: true,
    component: ProtectedRoute(RootSignIn, ['root']),
    key: '#rootLoginPage'
  },

  // Regular routes
  {
    path: '/terms',
    exact: true,
    component: Terms,
    key: '#termsPage'
  },
  {
    path: '/login',
    exact: true,
    component: OnlyGuests(SignIn),
    key: '#loginPage'
  },
  {
    path: '/oauth/authorize',
    exact: true,
    component: OnlyGuests(OAuthSignIn),
    key: '#oauthLoginPage'
  },
  {
    path: '/basic/authorize',
    exact: true,
    component: OnlyGuests(BasicAuthSignIn),
    key: '#basicAuthLoginPage'
  },
  {
    path: '/signup/:sm_id?',
    exact: true,
    component: OnlyGuests(SignUp),
    key: '#signUpPage'
  },
  {
    path: '/verify/:verifyCode/',
    exact: true,
    component: OnlyGuests(SignUp2),
    key: '#verifySignUpPage'
  },
  {
    path: '/forgot-password',
    exact: true,
    component: OnlyGuests(ForgotPassForm),
    key: '#forgotPasswordPage'
  },
  {
    path: '/reset-password/:verifyCode/',
    exact: true,
    component: OnlyGuests(ResetPassForm),
    key: '#resetPasswordPage'
  },
  {
    path: '/email-verify/:verifyCode/',
    exact: true,
    component: ProtectedRoute(VerifyChangeEmail),
    key: '#changeEmailVerifyPage'
  },
  {
    path: '/my-devices/',
    exact: true,
    component: ProtectedRoute(UserDetails, ['end_user']),
    key: '#myDevicesPage'
  },
  {
    path: '/dashboard/',
    exact: true,
    strict: true,
    component: ProtectedRoute(Dashboard, ['end_user']),
    key: '#dashboardPage'
  },
  {
    path: '/setting-profile/',
    exact: true,
    strict: true,
    component: ProtectedRoute(SettingProfile),
    key: '#settingProfilePage'
  },
  {
    path: '/setting-profile/:roleLink/:userID',
    exact: true,
    component: ProtectedRoute(SettingProfile, ['root', 'solar_admin', 'pv_installer', 'viewer', 'support', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#settingProfileUserPage'
  },
  {
    path: '/users',
    exact: true,
    component: ProtectedRoute(Users, ['root', 'solar_admin', 'pv_installer', 'viewer', 'support', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#usersPage'
  },
  {
    path: '/users/invited-users',
    exact: true,
    component: ProtectedRoute(InvitedUsers),
    key: '#invited'
  },
  {
    path: '/users/:userID', // user devices
    exact: true,
    component: ProtectedRoute(UserDetails, ['root', 'solar_admin', 'pv_installer', 'viewer', 'support', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#userViewPage'
  },
  {
    path: '/dashboard/:userID',
    exact: true,
    component: ProtectedRoute(Dashboard, ['root', 'solar_admin', 'pv_installer', 'viewer', 'support', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#dasboardUserPage'
  },
  {
    path: '/dashboard/:userID/debug/',
    exact: true,
    component: ProtectedRoute(Dashboard, ['root', 'solar_admin', 'pv_installer', 'viewer', 'support', ...EMPLOYEE_ROLE_TYPES], { debug: true }),
    key: '#dasboardUserPageDebug'
  },
  {
    path: '/pv-installers',
    exact: true,
    component: ProtectedRoute(PvInstallers, ['root', 'solar_admin', 'viewer', 'support', 'oem']),
    key: '#pvInstallersPage'
  },
  {
    path: '/oem',
    exact: true,
    component: ProtectedRoute(OEM, ['root', 'solar_admin', 'viewer', 'support']),
    key: '#oemPage'
  },
  {
    path: '/pv-installers/:companyID',
    exact: true,
    component: ProtectedRoute(CompanyDetails, ['root', 'solar_admin', 'viewer', 'support', 'oem']),
    key: '#pvInstallersViewPage'
  },
  {
    path: '/oem/:companyID',
    exact: true,
    component: ProtectedRoute(PVInstallersDetails, ['root', 'solar_admin', 'viewer', 'support']),
    key: '#OEMViewPage'
  },
  {
    path: '/supports',
    exact: true,
    component: ProtectedRoute(Supports, ['root', 'solar_admin']),
    key: '#supportsPage'
  },
  {
    path: '/supports/:supportID',
    exact: true,
    component: ProtectedRoute(SupportDetails, ['root', 'solar_admin']),
    key: '#supportsViewPage'
  },
  {
    path: '/admins',
    exact: true,
    component: ProtectedRoute(Admins, ['root']),
    key: '#adminsPage'
  },
  {
    path: '/viewers',
    exact: true,
    component: ProtectedRoute(Viewers, ['root', 'solar_admin']),
    key: '#viewersPage'
  },
  {
    path: '/viewers/:viewerID',
    exact: true,
    component: ProtectedRoute(ViewersDetails, ['root', 'solar_admin']),
    key: '#supportsViewPage'
  },
  {
    path: '/installed-sm',
    exact: true,
    component: ProtectedRoute(InstalledSM, ['root', 'solar_admin']),
    key: '#installedSMPage'
  },
  {
    path: '/monitoring/notification',
    exact: true,
    component: ProtectedRoute(MonitoringNotification, ['pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#monitoringPage'
  },
  {
    path: '/monitoring',
    exact: true,
    component: ProtectedRoute(Monitoring, ['root', 'solar_admin', 'support', 'pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES]),
    key: '#monitoringPage'
  },
  {
    path: '/employees',
    exact: true,
    component: ProtectedRoute(Employees, ['pv_installer']),
    key: '#employeesPage'
  },
  {
    path: '/load-management-dashboard/',
    exact: true,
    strict: true,
    component: ProtectedRoute(LoadManagementDashboard, ['end_user']),
    key: '#loadManagementDashboardPage'
  },
  {
    path: '/load-management-dashboard/:userId',
    exact: true,
    component: ProtectedRoute(LoadManagementDashboard, ['root', 'solar_admin', 'support', 'pv_installer', 'oem', 'viewer', ...EMPLOYEE_ROLE_TYPES]),
    key: '#loadManagementDashboard'
  },
  {
    path: '/no-access',
    exact: true,
    component: ProtectedRoute(NoAccess, ['support', 'viewer']),
    key: '#noAccessPage'
  },
  {
    path: '/sensor/auth/oauth2',
    exact: true,
    component: ProtectedRoute(SensorsOauth2, ['root', 'solar_admin', 'support', 'pv_installer', 'oem', 'end_user', ...EMPLOYEE_ROLE_TYPES]),
    key: '#sensorAuthOauth2'
  },
  {
    path: '/overview',
    exact: true,
    component: ProtectedRoute(Overview, ['root', 'viewer', 'solar_admin', 'pv_installer', 'oem', 'support', ...EMPLOYEE_ROLE_TYPES]),
    key: '#overview'
  },
  {
    path: '/documentation/:userId',
    exact: true,
    component: ProtectedRoute(Documentation),
    key: '#documentation'
  },
  // TODO: to comment before release sprint-7 (uncomment after)
  // {
  //   path: '/payments',
  //   exact: true,
  //   component: PaymentsAuthorization(Payments, ['end_user'], { repeat: true }),
  //   key: '#payments'
  // },
  {
    path: '/',
    exact: true,
    component: ProtectedRoute(HomePage),
    key: '#homePage'
  },
  {
    component: NotFound,
    key: '#notFoundPage'
  }
];

export default routes;
