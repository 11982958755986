import React from 'react';
import {
  renderMonitoringTableButtons,
  renderUserNameMonitoring,
  RenderDate,
  RenderWorkStatus,
  RenderPriorityStatus,
  RenderSignalIcon,
  RenderAssignedStatus,
  renderLastLogin
} from '../../components/ReactTable/renderFunctions';
import { EditColumns } from '../../components/EditColumns';
import renderPlant from '../../components/ReactTable/renderFunctions/renderPlant';
import i18n from '../../i18n';
import './index.css';

const columns = (myRoleType, { columns: additionalColumns, width }) => ([
  {
    Header: i18n.t('signal'),
    id: 'signal',
    minWidth: width.signal,
    className: 'monitoringSignalColumn',
    Cell: RenderSignalIcon
  },
  {
    Header: i18n.t('plantLabel'),
    id: 'plant',
    minWidth: width.plant,
    className: '',
    Cell: renderPlant,
    show: additionalColumns.plant
  },
  {
    Header: i18n.t('eUser'),
    id: 'name',
    minWidth: width.name,
    className: '',
    Cell: renderUserNameMonitoring
  },
  {
    Header: i18n.t('PV Installer'),
    id: 'company',
    accessor: (d) => (d.company?.name || i18n.t('noComp')),
    minWidth: width.company,
    className: 'pv_installer-td',
    show: (['root', 'solar_admin', 'support'].includes(myRoleType))
  },
  {
    Header: i18n.t('Creation Date'),
    id: 'createdAt',
    accessor: (d) => (d.createdAt || i18n.t('dd.mm.yyyy')),
    minWidth: width.createdAt,
    className: 'creation-date-td',
    Cell: RenderDate
  },
  {
    Header: i18n.t('lastErrorDate'),
    accessor: 'lastErrorDate',
    minWidth: width.lastErrorDate,
    className: 'last_error_date-td',
    Cell: RenderDate
  },
  {
    Header: <div className="rt-resizable-header-content">{i18n.t('assigned')}</div>,
    id: 'assigned',
    accessor: 'assigned',
    Cell: (row) => RenderAssignedStatus(row),
    minWidth: width.assigned,
    className: 'assigned-td',
    resizable: false
  },
  {
    Header: () => <div className="rt-resizable-header-content">{i18n.t('priority')}</div>,
    accessor: 'priority',
    minWidth: width.priority,
    Cell: RenderPriorityStatus,
    className: 'priority-td',
    resizable: false
  },
  {
    Header: () => <div className="rt-resizable-header-content">{i18n.t('workStatus')}</div>,
    accessor: 'status',
    minWidth: width.status,
    resizable: false,
    Cell: RenderWorkStatus,
    className: 'work_status-td rt-resizable-header-content'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => renderLastLogin(row),
    show: additionalColumns.last_login
  },
  {
    Header: i18n.t('installationDate'),
    accessor: 'installationDate',
    minWidth: width.installationDate,
    className: 'installation_date-td',
    // eslint-disable-next-line arrow-body-style
    Cell: (row) => {
      return RenderDate({ value: row.original?.user?.createdAt });
    },
    show: additionalColumns.installationDate
  },
  {
    Header: EditColumns,
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => renderMonitoringTableButtons(
      row.original,
      {
        sendEmail: (['root', 'solar_admin', 'support', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem'].includes(myRoleType)),
        editNote: (['root', 'solar_admin', 'support', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem'].includes(myRoleType)),
        devices: { preLink: '/users/' }
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
