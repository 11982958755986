import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import '../Users/index.css';
import TopBar from '../../components/TopBar';
import { CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import SearchField from '../../components/SearchField';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { LargeButton } from '../../components/ReduxFormFields/SimpleButton';
import columns from './columns';
import AddOEMWindow from './AddOEMWindow';
import DeleteOEMWindow from './DeleteOEMWindow';
import { DATA_LIST_ID } from './constants';
import { APP_ID } from '../../config';
import { userInfoSelector } from '../Users/selectors';

/**
 * @module OEM
 */

const transformResponse = (data) => ({
  total: data.total,
  data: data.list
});

/**
 * Renders PV installers page
 * @memberof module:OEM
 */
class OEM extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);

    configSavedSettings(
      this,
      'pv-installers',
      {
        expander: true,
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'oem:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded } = this.state;
    const {
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: sendModal,
      userInfo,
      myRoleType
    } = this.props;

    const getListURLUsers = {
      support: '/../users/get-supported-oem-users',
      viewer: `/../users/get-oem-users-attached-to-viewer?viewerId=${userInfo?._id}`,
      default: '/../oem'
    };

    const listUrlOEMs = getListURLUsers[myRoleType] || getListURLUsers.default;

    const sendAddUser = (fields) => sendModal(
      'addData',
      {
        method: 'post',
        url: '/../oem',
        data: {
          ...fields,
          app_identifier: APP_ID
        }
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    const sendRemoveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    const sendArchiveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../oem/${id}`
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    return (
      <div className="main-table-container">
        <AddOEMWindow
          modalID="addData"
          onSubmit={sendAddUser}
          handleOnClose={closeModal.bind(null, 'addData')}
        />
        <DeleteOEMWindow
          modalID="removeData"
          onSubmit={sendRemoveUser}
          onArchive={sendArchiveUser}
          handleOnClose={closeModal.bind(null, 'removeData')}
        />
        <TopBar updateButton showNavigation />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {` - ${i18n.t('OEM')}`}
            </span>
          </div>
          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="col-xl-12">
              <div className="row align-items-center">
                <div
                  className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 mr-auto"
                >
                  <DataListFilter
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'email,first_name,last_name,my_company,street,city,zip,phone',
                      placeholder: i18n.t('searchAllTablesElements')
                    }}
                    listID={DATA_LIST_ID}
                  />
                </div>
                {myRoleType !== 'viewer' && (
                  <div className="col-auto order-3 btn-sm-us">
                    <LargeButton
                      onClick={openModal.bind(null, 'addData', null)}
                      title={i18n.t('addOEMbtn')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataList
              listID={DATA_LIST_ID}
              listURL={listUrlOEMs}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                SubComponent: CompanyInfoSub,
                manual: true,
                onExpandedChange: (all) => {
                  this.saveExpanderChanges(all);
                },
                expanded
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

OEM.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

OEM.defaultProps = {
  userInfo: {}
};

const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(OEM);
