import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import TableDefaultSettings from './components/ReactTable/TableDefaultSettings';
import store, { history, persistor } from './store';
import App from './App';
import NotificationList from './containers/NotificationGenerator/NotificationList';
import './index.css';
import './assets/styles/style.scss';
import './i18n';

const theme = createTheme();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <Fragment>
            <TableDefaultSettings />
            <App />
            <NotificationList />
          </Fragment>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);
