export const DATA_REAL_TIME = 'data_real_time';
export const LOGIN_EVENT = 'login_event';
export const LOGOUT_EVENT = 'logout_event';
export const GET_POINTS = 'get_points';
export const DATA_REAL_TIME_GATEWAY = 'data_real_time_gateway';
export const ERRORMONITORING_TICKETS = 'errormonitoring_tickets';
export const USERS_DOCUMENTATION_FILES = 'users_documentation_files';
export const DATA_EXPORT_USERS_INFO = 'data_export_users_info';
export const DATA_EXPORT_GATEWAYS_INFO = 'data_export_gateways_info';
export const DATA_EXPORT_GATEWAY_DATA = 'data_export_gateway_data';
export const USER_ME = 'user_me';
export const USER_UPDATE = 'user_update';
export const AUTH_LOCAL = 'auth_local';

const getEventObj = (eventName, eventParams = {}, otherParams = {}) => {
  switch (eventName) {
    case DATA_REAL_TIME: {
      const { myself } = otherParams;
      if (myself) {
        return {
          nameEvent: DATA_REAL_TIME,
          params: {
            action_url: '/data/real-time',
            ...eventParams
          }
        };
      }
      return {
        nameEvent: DATA_REAL_TIME_GATEWAY,
        params: {
          action_url: '/data/real-time/{gatewayId}',
          ...eventParams
        }
      };
    }
    case LOGIN_EVENT: {
      return {
        nameEvent: 'login_event',
        params: {
          action_url: '/login',
          action: 'User login',
          ...eventParams
        }
      };
    }
    case LOGOUT_EVENT: {
      return {
        nameEvent: 'logout_event',
        params: {
          action_url: '/logout',
          action: 'User logout',
          ...eventParams
        }
      };
    }
    case GET_POINTS: {
      const { myself, version, hourURL } = otherParams;
      if (!myself) {
        if (version === 'v3') {
          if (hourURL === '-hour') {
            return ({
              nameEvent: 'v3_get_user_hour_points',
              params: {
                action_url: '/data/v3/get-user-hour-points',
                ...eventParams
              }
            });
          }
          return ({
            nameEvent: 'v3_get_user_points',
            params: {
              action_url: '/data/v3/get-user-points',
              ...eventParams
            }
          });
        }
        if (version === 'v2') {
          if (hourURL === '-hour') {
            return ({
              nameEvent: 'v2_get_user_hour_points',
              params: {
                action_url: '/data/v2/get-user-hour-points',
                ...eventParams
              }
            });
          }
          return ({
            nameEvent: 'v2_get_user_points',
            params: {
              action_url: '/data/v2/get-user-points',
              ...eventParams
            }
          });
        }
      }

      if (version === 'v3') {
        if (hourURL === '-hour') {
          return ({
            nameEvent: 'v3_get_my_hour_points',
            params: {
              action_url: '/data/v3/get-my-hour-points',
              ...eventParams
            }
          });
        }
        return ({
          nameEvent: 'v3_get_my_points',
          params: {
            action_url: '/data/v3/get-my-points',
            ...eventParams
          }
        });
      }
      if (version === 'v2') {
        if (hourURL === '-hour') {
          return ({
            nameEvent: 'v2_get_my_hour_points',
            params: {
              action_url: '/data/v2/get-my-hour-points',
              ...eventParams
            }
          });
        }
        return ({
          nameEvent: 'v2_get_my_points',
          params: {
            action_url: '/data/v2/get-my-points',
            ...eventParams
          }
        });
      }
      return undefined;
    }
    case ERRORMONITORING_TICKETS: {
      const { listURL } = otherParams;
      if (listURL && listURL !== '/../errormonitoring/tickets') return undefined;
      return {
        nameEvent: 'errormonitoring_tickets',
        params: {
          action_url: '/../errormonitoring/tickets',
          ...eventParams
        }
      };
    }
    case USERS_DOCUMENTATION_FILES: {
      return {
        nameEvent: 'users_documentation_files',
        params: {
          action_url: '/users-documentation/files/{userId}',
          ...eventParams
        }
      };
    }
    case DATA_EXPORT_USERS_INFO: {
      return {
        nameEvent: 'data_export_users_info',
        params: {
          action_url: '/../data/export-users-info',
          ...eventParams
        }
      };
    }
    case DATA_EXPORT_GATEWAYS_INFO: {
      return {
        nameEvent: 'data_export_gateways_info',
        params: {
          action_url: '/../data/export-gateways-info',
          ...eventParams
        }
      };
    }
    case DATA_EXPORT_GATEWAY_DATA: {
      return {
        nameEvent: 'data_export_gateway_data',
        params: {
          action_url: '/../data/export-gateway-data',
          ...eventParams
        }
      };
    }
    case USER_ME: {
      const { url } = otherParams;
      if (url && url !== '/../user/me') return undefined;
      return {
        nameEvent: 'user_me',
        params: {
          action_url: '/../user/me',
          ...eventParams
        }
      };
    }
    case USER_UPDATE: {
      const { myself, myRoleType } = otherParams;
      if (myself && myRoleType !== 'oem') return undefined;
      return {
        nameEvent: 'user_update',
        params: {
          action_url: '/../user/update',
          ...eventParams
        }
      };
    }
    case AUTH_LOCAL: {
      return {
        nameEvent: 'auth_local',
        params: {
          action_url: '/../auth/local',
          ...eventParams
        }
      };
    }
    default:
      return undefined;
  }
};

export default getEventObj;
