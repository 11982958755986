import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { sendPropFromField } from '../actions';
import '../index.css';
/**
 * Renders Custom Search Field for tables
 * @param  {string} placeholder - placeholder of search input
 */
class CustomSearchField extends Component {
  handleChange = (value) => {
    const {
      fieldName,
      sendPropFromField: sendPropFromFieldProps,
      onChangeField
    } = this.props;

    sendPropFromFieldProps([fieldName], value);
    onChangeField({ search: value || undefined });
  };

  render() {
    const { placeholder, initialValueForCustomSearch } = this.props;

    return (
      <div className="custom-search-container form-group m-form__group row align-items-center">
        <div className="m-input-icon m-input-icon--left col-md-12">
          <DebounceInput
            className="form-control m-input m-input--solid search customSearch"
            placeholder={placeholder}
            id="customSearch"
            minLength={3}
            debounceTimeout={300}
            onChange={(event) => this.handleChange(event.target.value.trim())}
            value={initialValueForCustomSearch}
          />
          <span className="m-input-icon__icon m-input-icon__icon--left search">
            <span><i className="la la-search" /></span>
          </span>
        </div>
      </div>
    );
  }
}

CustomSearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  sendPropFromField: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValueForCustomSearch: PropTypes.string
};

CustomSearchField.defaultProps = {
  initialValueForCustomSearch: null
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  sendPropFromField
})(CustomSearchField);
