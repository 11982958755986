import * as actionTypes from './constants';

export function usersSensorsRequest(userId) {
  return {
    type: actionTypes.USERS_SENSORS_REQUEST,
    payload: {
      userId
    }
  };
}

export function usersSensorsReceived(data) {
  return {
    type: actionTypes.USERS_SENSORS_RECEIVED,
    payload: {
      data
    }
  };
}

export function saveDevicesTypesList(data) {
  return {
    type: actionTypes.SAVE_DEVICES_TYPES_LIST,
    payload: {
      data
    }
  };
}

export function getSensorsConfigurationFile() { // epic
  return {
    type: actionTypes.GET_SENSORS_CONFIGURATION_FILE
  };
}

export function receiveSensorsConfigurationFile(data) { // reducer
  return {
    type: actionTypes.RECEIVE_SENSORS_CONFIGURATION_FILE,
    payload: {
      data
    }
  };
}

export function setDeviceIdRtu(payload) { // epic
  return {
    type: actionTypes.SET_DEVICE_ID_RTU,
    payload
  };
}
