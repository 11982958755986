import { createSelector } from 'reselect';
import { dataListPviId } from './constants';

const getInitialData = (state) => state.settingProfile.initialData;
const getMyUser = (state) => state.signIn.user;
const getSmIdData = (state) => state.modals?.confirmationWindow?.data;
const getIsLoading = (state) => state.settingProfile.isLoading;
const getDataListCompanies = (state) => state.dataList?.[dataListPviId]?.data;
const getDataListOems = (state) => state.settingProfile?.oemsList;
const getPvInstaller = (state) => state.settingProfile?.initialData?.company;
const getOem = (state) => state.settingProfile?.initialData?.connectedOem;
const getConfirmationModal = (state) => state.modals?.confirmationWindow?.data || {};
const getChangeSMIDModal = (state) => state.modals.changeSMID;
const getChangePasswordModal = (state) => state.modals.changePassword;
const getModalIsLoading = (state) => state.modals?.isLoading;
const getChangeEmailModal = (state) => state.modals.changeEmail;
const getIsChangeColumnsModalOpened = (state) => state.modals?.changeColumn?.opened;

export const initialDataSelector = createSelector([getInitialData], (initialData) => initialData);
export const myUserSelector = createSelector([getMyUser], (user) => user);
export const emailSelector = createSelector([getMyUser], (user) => user?.email);
export const smIdDataSelector = createSelector([getSmIdData], (data) => data);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const dataListCompaniesSelector = createSelector([getDataListCompanies], (data) => data);
export const dataListOemsSelector = createSelector([getDataListOems], (oemsList) => oemsList);
export const pvInstallerSelector = createSelector([getPvInstaller], (company) => company);
export const oemSelector = createSelector([getOem], (oem) => oem);
export const confirmationModalSelector = createSelector([getConfirmationModal], (modal) => modal);
export const changeSMIDModalSelector = createSelector([getChangeSMIDModal], (modal) => modal);
export const changePasswordSelector = createSelector([getChangePasswordModal], (modal) => modal);
export const modalIsLoadingSelector = createSelector([getModalIsLoading], (isLoading) => !!isLoading);
export const changeEmailModalSelector = createSelector([getChangeEmailModal], (modal) => modal);
export const isChangeColumnsModalOpenedSelector = createSelector([getIsChangeColumnsModalOpened], (isOpen) => isOpen);
