import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { sensorsConfigurationFileSelector, deviceFormSelector, modalsSelector } from './selectors';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import './index.scss';

const TokenString = (props) => {
  const {
    input,
    label,
    showTooltip,
    meta: { touched, error },
    className,
    languageToSync,
    sensorsConfigurationFile,
    deviceForm,
    modals,
    deviceName,
    ...custom
  } = props;

  const { values } = deviceForm || {};
  const { data, device_group } = values || {};
  const { secret = '', identifier = '', callbackUrl } = data || {};

  const isDisabled = !(secret && identifier && callbackUrl) && !['Bosch HC Dryer', 'Bosch HC Washer', 'Bosch HC Dishwasher'].includes(deviceName);
  const currentSensorConfigurationFile = sensorsConfigurationFile?.find((sensor) => device_group?.includes(sensor?.device_group));
  const { flow } = currentSensorConfigurationFile || {};
  const { authorizationCodeLink } = flow || {};
  const { url, queryParameters } = authorizationCodeLink || {};

  const savedUrl = new URL(window.location.href);
  const state = { modals, deviceForm, savedUrl, currentSensorConfigurationFlow: flow };

  const recordId = `sensor_oauth2_${uuidv4()}`;

  const recordInfo = {
    recordId,
    url: `${window.location.origin}/sensor/auth/oauth2`
  };

  const buildRedirectedURL = url && new URL(url);

  queryParameters?.forEach((query) => {
    const { name, type, value, field } = query;

    switch (type) {
      case 'value':
        buildRedirectedURL.searchParams.append(name, value);
        break;
      case 'field':
        buildRedirectedURL.searchParams.append(name, data?.[field]);
        break;
      default:
        buildRedirectedURL.searchParams.append(name, window.btoa(JSON.stringify(recordInfo)));
        break;
    }
  });

  const generateToken = () => {
    sessionStorage.setItem(recordId, JSON.stringify(state));

    const { href: redirectedURL } = buildRedirectedURL || {};
    const decodedRedirectedURL = decodeURIComponent(redirectedURL);

    window.location.replace(decodedRedirectedURL);
  };

  return (
    <>
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="left" />
        </div>
      )}
      <TextField
        variant="standard"
        label={label}
        {...input}
        {...custom}
        disabled={['Bosch HC Dryer', 'Bosch HC Washer', 'Bosch HC Dishwasher'].includes(deviceName)}
        classes={{
          root: `customTextField tokenStringField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          },
          endAdornment: (
            <button
              type="button"
              className="generateTokenBtn"
              onClick={generateToken}
              disabled={isDisabled}
            >
              {i18n.t('generateToken')}
            </button>
          )
        }}
        InputLabelProps={{ className: 'inputLabel' }}
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning">{i18n.t(error)}</div>
      )}
    </>
  );
};

TokenString.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  deviceName: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  languageToSync: PropTypes.string,
  addNotification: PropTypes.func.isRequired,
  sensorsConfigurationFile: PropTypes.instanceOf(Object).isRequired,
  deviceForm: PropTypes.instanceOf(Object).isRequired,
  modals: PropTypes.instanceOf(Object).isRequired
};

TokenString.defaultProps = {
  languageToSync: '',
  showTooltip: ''
};

const mapStateToProps = createStructuredSelector({
  sensorsConfigurationFile: sensorsConfigurationFileSelector,
  deviceForm: deviceFormSelector,
  modals: modalsSelector
});

export default connect(mapStateToProps)(TokenString);
