import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Preloader from '../Preloader';
import ModalWindow from '../../containers/ModalWindow';
import { isLoadingSelector, removeDataModalSelector } from '../UIKit/selectors';
import { PrimaryButton, CancelButton } from '../UIKit';

import i18n from '../../i18n';

import './index.scss';

/**
 * Renders delete End user confirmation modal window for Users page
 * @memberof module:UsersTable
 */
const DeleteUserWindow = (props) => {
  const {
    onSubmit,
    onArchive,
    modalID,
    handleOnClose
  } = props;

  const [mode, setMode] = useState(0);
  const [title, seTitle] = useState('delUser');
  const [confirmation, setConfirmation] = useState('confirmDelOrAcriveUser');
  const isLoading = useSelector(isLoadingSelector);
  const modal = useSelector(removeDataModalSelector);

  useEffect(() => {
    switch (mode) {
      case 1:
        setConfirmation('confirmDelUser');
        break;
      case 2:
        seTitle('archiveUser');
        setConfirmation('confirmArchiveUser');
        break;
      default:
        break;
    }

    if (mode !== 0 && !modal?.opened) {
      setMode(0);
    }
  }, [modal?.opened, mode]);

  const data = modal?.data || {};

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t(title)}</h5>
      </div>
      {isLoading ? (
        <div className="user-delete-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t(confirmation)}</div>
          </div>
          <div className={`modal-footer ${mode === 0 && 'justify-content-between'}`}>
            <CancelButton onClickHandler={handleOnClose} />
            {mode === 2 && (
              <PrimaryButton
                onClickHandler={onArchive.bind(null, { id: data.id })}
                i18nLabel="archive"
                iconClass="fa fa-archive"
              />
            )}
            {mode === 1 && (
              <PrimaryButton
                onClickHandler={onSubmit.bind(null, { id: data.id })}
                i18nLabel="delete"
                iconClass="fa fa-trash"
              />
            )}
            {mode === 0 && (
              <>
                <PrimaryButton
                  onClickHandler={() => setMode(1)}
                  i18nLabel="delete"
                  iconClass="fa fa-trash"
                />
                <PrimaryButton
                  onClickHandler={() => setMode(2)}
                  i18nLabel="archive"
                  iconClass="fa fa-archive"
                />
              </>
            )}
          </div>
        </div>
      )
      }
    </ModalWindow>
  );
};

DeleteUserWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  onArchive: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default DeleteUserWindow;
