import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { createStructuredSelector } from 'reselect';
import columnsEndUser from './columnsEndUser';
import columnsPVI from './columnsPVI';
import columnsOEM from './columnsOEM';
import TopBar from '../../components/TopBar';
import { UserInfoSub, CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import SupportInfo from './components/SupportInfo';
import SearchField from '../../components/SearchField';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
// eslint-disable-next-line import/no-named-as-default-member
import AddUserWindow from './components/AddEndUsersToSupportWindow';
import DeleteUserWindow from './components/DeleteEndUserWindow';
import AddInstallerWindow from './components/AddPVInstallerToSupportWindow';
import DeleteInstallerWindow from './components/DeletePVInstallerWindow';
import AddOEMWindow from './components/AddOEMToSupportWindow';
import DeleteOEMWindow from './components/DeleteOEMWindow';
import { getAllAttachedInstallers, getAllNotAttachedOems, getAllNotAttachedInstallers, getNotAttachedEndUsers } from './actions';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
// eslint-disable-next-line import/no-named-as-default-member
import ChooseLicenseWindow from '../../components/ChooseLicenseWindow';
import TabContent from './components/TabContent';
import {
  addEndUsersToSupportModalID,
  addPVInstallersToSupportModalID,
  addOEMToSupportModalID,
  dataListIDSupport,
  dataListIDEndUsers,
  dataListIDPVInstallers,
  dataListIDOEMs,
  dataListIDTabs,
  getSupportsParams,
  getEndUsersParams,
  getInstallersParams,
  getOEMSParams
} from './constants';
import { supportDataListSelector, visibleWebSelector } from './selectors';

class SupportDetails extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columnsEndUser();
    this.columnsPVI = columnsPVI();
    this.columnsOEM = columnsOEM();

    configSavedSettings(
      this,
      'support-details',
      {
        expander: true,
        subTabs: true,
        pathName,
        historyKey
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const {
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: modalSend,
      getAllNotAttachedOems: getAllNotAttachedOemsDispatch,
      getAllNotAttachedInstallers: getAllNotAttachedInstallersDispatch,
      getNotAttachedEndUsers: getNotAttachedEndUsersDispatch,
      visibleWeb,
      supportDataList,
      match
    } = this.props;
    const { expanded, activeTab } = this.state;

    const { supportID } = match.params;
    const supportDataListURL = `/../users/support/${supportID}`;
    const connectedEndUsersUrl = `/../users/get-supported-end-users?supportUserId=${supportID}`;
    const connectedPvInstallersUrl = `/../users/get-supported-pv-installer-users?supportUserId=${supportID}`;
    const connectedOemsUrl = `/../users/get-supported-oem-users?supportUserId=${supportID}`;
    const supportHasLoaded = !!supportDataList.data?._id;

    const transformResponse = (data) => ({
      total: data.total,
      data: data.list
    });

    const subTabs = ['SMUs', 'pvInstallers', 'OEM'];

    // Add end-users
    const sendAddEndUsers = (userIds) => {
      modalSend(
        addEndUsersToSupportModalID,
        {
          method: 'put',
          url: '/../users/add-users-to-support',
          data: {
            supportUserId: supportID,
            userIds
          }
        },
        () => {
          getNotAttachedEndUsersDispatch({ supportUserId: supportID, ...getEndUsersParams });
          return dataListReloadData(dataListIDEndUsers);
        }
      );
    };

    // Delete an end-user
    const sendDeleteEndUser = (props) => {
      const { id } = props;
      return modalSend(
        'deleteData',
        {
          method: 'put',
          url: '/../users/remove-users-from-support',
          data: {
            supportUserId: supportID,
            userIds: [id]
          }
        },
        () => {
          getNotAttachedEndUsersDispatch({ supportUserId: supportID, ...getEndUsersParams });
          return dataListReloadData(dataListIDEndUsers);
        }
      );
    };

    // Add PV Installer
    const sendAddInstallers = (userIds) => {
      modalSend(
        addPVInstallersToSupportModalID,
        {
          method: 'put',
          url: '/../users/add-users-to-support',
          data: {
            supportUserId: supportID,
            userIds
          }
        },
        () => {
          getAllNotAttachedInstallersDispatch({ supportUserId: supportID, ...getInstallersParams });
          return dataListReloadData(dataListIDPVInstallers);
        }
      );
    };

    // Delete PV Installer
    const sendDeletePVInstaller = ({ id }) => modalSend(
      'deleteData',
      {
        method: 'put',
        url: '/../users/remove-users-from-support',
        data: {
          supportUserId: supportID,
          userIds: [id]
        }
      },
      () => {
        getAllNotAttachedInstallersDispatch({ supportUserId: supportID, ...getInstallersParams });
        return dataListReloadData(dataListIDPVInstallers);
      }
    );

    // Add OEMs
    const sendAddOEMs = (userIds) => {
      modalSend(
        addOEMToSupportModalID,
        {
          method: 'put',
          url: '/../users/add-users-to-support',
          data: {
            supportUserId: supportID,
            userIds
          }
        },
        () => {
          getAllNotAttachedOemsDispatch({ supportUserId: supportID, ...getOEMSParams });
          return dataListReloadData(dataListIDOEMs);
        }
      );
    };

    // Delete an OEM
    const sendDeleteOEM = ({ id }) => modalSend(
      'deleteData',
      {
        method: 'put',
        url: '/../users/remove-users-from-support',
        data: {
          supportUserId: supportID,
          userIds: [id]
        }
      },
      () => {
        getAllNotAttachedOemsDispatch({ supportUserId: supportID, ...getOEMSParams });
        return dataListReloadData(dataListIDOEMs);
      }
    );

    let activeTabIndex = subTabs.indexOf(activeTab);
    if (activeTabIndex === -1) {
      activeTabIndex = 0;
    }

    const searchByFields = {
      SMUs: 'sm_id,email,first_name,last_name,street,city,zip,phone',
      pvInstallers: 'sm_id,email,first_name,last_name,street,city,zip,phone,my_company',
      OEM: 'sm_id,email,first_name,last_name,street,city,zip,phone,oem'
    };

    return (
      <Fragment>
        <TopBar
          visibleWeb={visibleWeb}
          showNavigation
        />
        <DataList
          listID={dataListIDSupport}
          listURL={supportDataListURL}
          Component={SupportInfo}
          params={{ ...getSupportsParams }}
        />

        {supportHasLoaded && (
          <div className="container-fluid">
            <div
              className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10"
              style={{ margin: '30px 0 10px' }}
            >
              <DataListFilter
                Component={SearchField}
                componentProps={{
                  searchByFields: searchByFields?.[activeTab] || searchByFields.SMUs,
                  placeholder: i18n.t('searchAllTablesElements')
                }}
                listID={dataListIDTabs[activeTabIndex]}
              />
            </div>
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index) => this.changeSubTab(subTabs[index])}
            >
              <TabList>
                <Tab>
                  <TabContent title={i18n.t('SMUs')} />
                </Tab>
                <Tab>
                  <TabContent title={i18n.t('pvInstallers')} />
                </Tab>
                <Tab>
                  <TabContent title={i18n.t('OEM')} />
                </Tab>
              </TabList>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <div className="pvInstallerAddBtn">
                    <a
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                      onClick={openModal.bind(null, addEndUsersToSupportModalID, null)}
                    >
                      <span>
                        <span>{i18n.t('addSMUbtn')}</span>
                      </span>
                    </a>
                  </div>
                  <DeleteUserWindow
                    modalID="deleteData"
                    onSubmit={sendDeleteEndUser}
                    handleOnClose={closeModal.bind(null, 'deleteData')}
                  />
                  <AddUserWindow
                    modalID={addEndUsersToSupportModalID}
                    handleSubmit={sendAddEndUsers}
                    supportUserId={supportID}
                    handleOnClose={closeModal.bind(null, addEndUsersToSupportModalID)}
                  />
                  <DataList
                    listID={dataListIDEndUsers}
                    listURL={connectedEndUsersUrl}
                    params={{ ...getEndUsersParams }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columns,
                      SubComponent: UserInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <div className="pvInstallerAddBtn">
                    <a
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                      onClick={openModal.bind(null, addPVInstallersToSupportModalID, null)}
                    >
                      <span>
                        <span>{i18n.t('addPVIbtn')}</span>
                      </span>
                    </a>
                  </div>
                  <DeleteInstallerWindow
                    modalID="deleteData"
                    onSubmit={sendDeletePVInstaller}
                    handleOnClose={closeModal.bind(null, 'deleteData')}
                  />
                  <AddInstallerWindow
                    modalID={addPVInstallersToSupportModalID}
                    handleSubmit={sendAddInstallers}
                    supportUserId={supportID}
                    handleOnClose={closeModal.bind(null, addPVInstallersToSupportModalID)}
                  />
                  <DataList
                    listID={dataListIDPVInstallers}
                    listURL={connectedPvInstallersUrl}
                    params={{ ...getInstallersParams }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsPVI,
                      SubComponent: CompanyInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                  <ChooseLicenseWindow />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <div className="pvInstallerAddBtn">
                    <a
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                      onClick={openModal.bind(null, addOEMToSupportModalID, null)}
                    >
                      <span>
                        <span>{i18n.t('addOEMbtn')}</span>
                      </span>
                    </a>
                  </div>
                  <DeleteOEMWindow
                    modalID="deleteData"
                    onSubmit={sendDeleteOEM}
                    handleOnClose={closeModal.bind(null, 'deleteData')}
                  />
                  <AddOEMWindow
                    modalID={addOEMToSupportModalID}
                    handleSubmit={sendAddOEMs}
                    supportUserId={supportID}
                    handleOnClose={closeModal.bind(null, addOEMToSupportModalID)}
                  />
                  <DataList
                    listID={dataListIDOEMs}
                    listURL={connectedOemsUrl}
                    params={{ ...getOEMSParams }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsOEM,
                      SubComponent: CompanyInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
            </Tabs>
            <DataListPagination
              Component={Pagination}
              listID={dataListIDTabs[activeTabIndex]}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        )}
        <Footer />
      </Fragment>
    );
  }
}

SupportDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  getAllNotAttachedOems: PropTypes.func.isRequired,
  getNotAttachedEndUsers: PropTypes.func.isRequired,
  getAllNotAttachedInstallers: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  supportDataList: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired
};

SupportDetails.defaultProps = {
  visibleWeb: false
};

const mapStateToProps = createStructuredSelector({
  supportDataList: supportDataListSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  getAllNotAttachedOems,
  getAllNotAttachedInstallers,
  closeModalWindow,
  getNotAttachedEndUsers,
  modalWindowSend,
  getAllAttachedInstallers
})(SupportDetails);
