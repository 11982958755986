/* eslint-disable import/no-named-as-default-member */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment-timezone';
import columns from './columns';
import columnsEmp from './columnsEmp';
import columnsOEM from './columnsOEM';
import TopBar from '../../components/TopBar';
import { UserInfoSub, CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import CompanyInfo from './components/CompanyInfo';
import SearchField from '../../components/SearchField';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import AddEmployeeWindow from './components/AddEmployeeWindow';
import DeleteEmployeeWindow from './components/DeleteEmployeeWindow';
import AddUserWindow from './components/AddUserWindow';
import DeleteUserWindow from '../../components/DeleteUserWindow';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { companyDataListID, getOEMsDefaultParams } from './constants';
import { companyDataListSelector, oemIdSelector, visibleWebSelector } from './selectors';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);
    this.columnsEmp = columnsEmp(myRoleType);
    this.columnsOEM = columnsOEM(myRoleType);

    configSavedSettings(
      this,
      'company-details',
      {
        expander: true,
        subTabs: true,
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'first_name:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  componentDidMount() {
    moment.tz.setDefault();
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded, activeTab } = this.state;
    const {
      myRoleType,
      companyDataList,
      match,
      oemId,
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: modalSend,
      visibleWeb
    } = this.props;

    const getUsersUrl = {
      support: '/../users/get-supported-end-users',
      oem: `../oem/connected-end-users/${oemId}/${companyDataList?.data?.my_company?._id}`,
      default: '/../users/web/v1/end-users'
    };

    const companyIdField = {
      support: { pvInstallerId: companyDataList?.data?.my_company?._id },
      default: { company: companyDataList?.data?.my_company?._id }
    };

    const getOEMsUrl = {
      support: '/../users/get-supported-oem-users',
      default: `/../oem/connected-oems/${companyDataList?.data?._id}`
    };

    const getOEMsParams = {
      support: {
        ...getOEMsDefaultParams,
        pvInstallerId: companyDataList?.data?.my_company?._id
      },
      default: getOEMsDefaultParams
    };

    const { companyID } = match.params;
    const companyDataListURL = `/../users/pv-installer-users/${companyID}`;
    const companyHasLoaded = !!companyDataList.data?.my_company;
    const dataListIDUsers = 'company_sm_users';
    const listURLUsers = getUsersUrl[myRoleType] || getUsersUrl.default;
    const listURLEmployee = '/../users/employee';
    const listOEM = getOEMsUrl[myRoleType] || getOEMsUrl.default;
    const pvInstallersEndUsersParams = {
      ...this.defaultParams,
      ...(companyIdField[myRoleType] || companyIdField.default)
    };
    const paramsOEM = getOEMsParams[myRoleType] || getOEMsParams.default;

    const transformResponse = (data) => ({
      total: data.total,
      data: data.list
    });

    const subTabs = ['end-users', 'employees', 'oem'];
    const allowedToAddEndUser = ['root', 'solar_admin', 'support', 'pv_installer', ...EMPLOYEE_ROLE_TYPES].includes(myRoleType);
    const allowedToAddEmployee = ['root', 'solar_admin', 'support', 'pv_installer'].includes(myRoleType);

    const sendAddEmployee = (fields) => modalSend(
      'addEmployee',
      {
        method: 'post',
        url: '/../users/employee',
        data: fields
      },
      () => dataListReloadData(dataListIDUsers)
    );

    const sendArchiveEmployee = ({ id }) => modalSend(
      'removeData',
      {
        method: 'delete',
        url: `/../users/employee/${id}`
      },
      () => dataListReloadData(dataListIDUsers)
    );

    const sendAddUser = (fields) => modalSend(
      'addUser',
      {
        method: 'post',
        url: '/../users/solar-managers-users',
        data: fields
      },
      () => dataListReloadData(dataListIDUsers)
    );

    const sendArchiveUser = ({ id }) => modalSend(
      'removeData',
      {
        method: 'delete',
        url: `/../users/solar-managers-users/${id}`
      },
      () => dataListReloadData(dataListIDUsers)
    );

    const sendRemoveUser = ({ id }) => modalSend(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(dataListIDUsers)
    );

    let activeTabIndex = subTabs.indexOf(activeTab);
    if (activeTabIndex === -1) activeTabIndex = 0;

    return (
      <Fragment>
        <TopBar visibleWeb={visibleWeb} showNavigation />
        <DataList listID={companyDataListID} listURL={companyDataListURL} Component={CompanyInfo} />
        {companyHasLoaded && (
          <div className="container-fluid">
            <div className="d-flex">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10">
                <DataListFilter
                  Component={SearchField}
                  componentProps={{
                    searchByFields: 'sm_id,email,first_name,last_name,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListIDUsers}
                />
              </div>
            </div>

            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index) => this.changeSubTab(subTabs[index])}
            >
              <TabList>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div
                          className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                        >
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('SMUs')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div
                          className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                        >
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('employees')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {!['oem'].includes(myRoleType) && (
                  <Tab>
                    <div className="row tabs-cont align-items-center">
                      <div className="col-auto mr-auto">
                        <div className="m-portlet__head-tools">
                          <div
                            className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                          >
                            <div className="nav-item m-tabs__item">
                              <div className="nav-link m-tabs__link">{i18n.t('OEM')}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
              </TabList>

              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {allowedToAddEndUser && (
                    <div className="pvInstallerAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, 'addUser', null)}
                      >
                        <span>
                          <span>{i18n.t('addSMUbtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <DeleteUserWindow
                    modalID="removeData"
                    onSubmit={sendRemoveUser}
                    onArchive={sendArchiveUser}
                    handleOnClose={closeModal.bind(null, 'removeData')}
                  />
                  <AddUserWindow
                    modalID="addUser"
                    onSubmit={sendAddUser}
                    initialValues={{ pv_installer: companyDataList.data.my_company._id }}
                    handleOnClose={closeModal.bind(null, 'addUser')}
                  />
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listURLUsers}
                    params={pvInstallersEndUsersParams}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columns,
                      SubComponent: UserInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {allowedToAddEmployee && (
                    <div className="pvInstallerAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, 'addEmployee', null)}
                      >
                        <span>
                          <span>{i18n.t('addEmployeebtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <DeleteEmployeeWindow
                    modalID="removeData"
                    onArchive={sendArchiveEmployee}
                    onSubmit={sendRemoveUser}
                    handleOnClose={closeModal.bind(null, 'removeData')}
                  />
                  <AddEmployeeWindow
                    modalID="addEmployee"
                    onSubmit={sendAddEmployee}
                    initialValues={{ pv_installer: companyDataList.data.my_company._id, role_type: 'pv_installer_employee' }}
                    handleOnClose={closeModal.bind(null, 'addEmployee')}
                  />
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listURLEmployee}
                    params={{ ...this.defaultParams, company: companyDataList.data.my_company._id }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsEmp,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listOEM}
                    params={paramsOEM}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsOEM,
                      SubComponent: CompanyInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
            </Tabs>
            <DataListPagination
              Component={Pagination}
              listID={dataListIDUsers}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        )}
        <Footer />
      </Fragment>
    );
  }
}

CompanyDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  companyDataList: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool,
  oemId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

CompanyDetails.defaultProps = {
  visibleWeb: false
};

const mapStateToProps = createStructuredSelector({
  companyDataList: companyDataListSelector,
  oemId: oemIdSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(CompanyDetails);
