import { uniq } from 'lodash';

export const getCategoriesOfDevices = (data) => {
  const categories = [];
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      categories.push(data[i].y);
    }
  }
  return uniq(categories).sort().reverse();
};

export default getCategoriesOfDevices;
