export const SIGN_UP_RECEIVED = 'SIGN_UP_RECEIVED';
export const SIGN_UP_SEND = 'SIGN_UP_SEND';
export const SIGN_UP_CHANGE_EMAIL = 'SIGN_UP_CHANGE_EMAIL';
export const SIGN_UP_CHANGE_EMAIL_SEND = 'SIGN_UP_CHANGE_EMAIL_SEND';
export const SIGN_UP_CHANGE_EMAIL_SUCC = 'SIGN_UP_CHANGE_EMAIL_SUCC';
export const SIGN_UP_RESEND = 'SIGN_UP_RESEND';
export const SIGN_UP_QR_RECEIVED = 'SIGN_UP_QR_RECEIVED';
export const RECEIVED_INFORMATION_FROM_SERVER = 'RECEIVED_INFORMATION_FROM_SERVER';

export const SIGN_UP_USER_FORM = 'signUpUserForm';
