import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import omit from 'lodash/omit';
import CollapseContainer from './components/CollapseContainer';
import plusIcon from '../../../assets/images/svg/plus.svg';
import { defaultListOfDays } from './helper';
import { isEditModalFormOpenedSelector } from './selector';

import './index.scss';

const SwitchingTimes = memo((props) => {
  const {
    input: {
      value,
      onChange
    },
    meta: {
      error,
      touched
    },
    isEditModalFormOpened
  } = props;
  // eslint-disable-next-line arrow-body-style
  const [listOfSwitch, setListOfSwitch] = useState(() => {
    return (Array.isArray(value) && value.length)
      ? value.map((switchOption) => ({ ...switchOption, key: uuid4() }))
      : [];
  });

  useEffect(() => {
    if (listOfSwitch.length && !isEditModalFormOpened) {
      setListOfSwitch([]);
    } else if (
      ((Array.isArray(value) && value.length) && isEditModalFormOpened)
      || ((Array.isArray(value) && value.length))
    ) {
      setListOfSwitch(value.map((switchOption) => ({ ...switchOption, key: uuid4() })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Control 'listOfSwitch' state, use 'onChange' and set new values for Redux 'switchingTimes' field
   */
  useEffect(() => {
    if (Array.isArray(value) && value.length) {
      if (listOfSwitch.length) {
        onChange(listOfSwitch.map((switchOption) => omit(switchOption, ['key'])));
      } else {
        onChange([]);
      }
    }
    if ((!Array.isArray(value) || (Array.isArray(value) && !value.length)) && listOfSwitch.length) {
      onChange([{ activeDays: defaultListOfDays }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfSwitch, onChange]);

  /**
   * Handle switchingTimes create
   * @returns {array} updated switchingTimes
   */
  const handleCreateNewSwitch = () => setListOfSwitch((prevArray) => [...prevArray, { activeDays: defaultListOfDays, key: uuid4() }]);

  /**
   * Handle switchingTimes delete
   * @param {string} someKey uuid key
   * @returns {array} updated switchingTimes
   */
  // eslint-disable-next-line arrow-body-style
  const handleDeleteNewSwitch = useCallback((someKey) => setListOfSwitch((prevArray) => {
    return prevArray.filter((option) => option.key !== someKey);
  }), []);

  /**
   * Handle switchingTimes change
   * @param {string} someKey uuid key
   * @param {object} someData one of value
   * @returns {array} updated switchingTimes
   */
  const handleChangeSwitch = (someKey, someData) => (setListOfSwitch((prevArray) => {
    const indexOfCurrentSwitch = prevArray.findIndex((object) => object.key === someKey);
    const currentSwitch = prevArray.find((option) => option.key === someKey);
    const newListOfSwitches = [
      ...prevArray.slice(0, indexOfCurrentSwitch),
      { ...currentSwitch, ...someData, key: someKey },
      ...prevArray.slice(indexOfCurrentSwitch + 1, prevArray.length)
    ];
    return newListOfSwitches;
  }));

  /**
   * Object with start errors equal undefined
   * @constant {object}
   */
  const defaultErrorObject = {
    fromError: undefined,
    toError: undefined,
    daysError: undefined,
    actionError: undefined
  };
  return (
    <div className="switching-times__container">
      <div className="switching-times__collapse-container">
        <>
          {listOfSwitch.map(({ key }, index) => (
            <CollapseContainer
              key={key}
              touched={touched}
              globalError={(Array.isArray(error) && error[index]) || defaultErrorObject}
              value={listOfSwitch.find((day) => day.key === key)}
              handleChangeSwitch={handleChangeSwitch.bind(null, key)}
              handleDeleteNewSwitch={handleDeleteNewSwitch.bind(null, key)}
            />
          ))}
          <div className="switching-times__add-new-switch">
            <img
              src={plusIcon}
              alt="plusIcon"
              onClick={handleCreateNewSwitch}
            />
          </div>
        </>
      </div>
    </div>
  );
});

SwitchingTimes.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.instanceOf(Array),
  isEditModalFormOpened: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func
};

SwitchingTimes.defaultProps = {
  onChange: () => { },
  value: [],
  isEditModalFormOpened: undefined
};
const mapStateToProps = createStructuredSelector({
  isEditModalFormOpened: isEditModalFormOpenedSelector
});

export default connect(mapStateToProps)(SwitchingTimes);
