import * as actionTypes from './constants';

export function resetPassSend(language, fields) { // epic
  return {
    type: actionTypes.RESET_PASS_SEND,
    payload: {
      language,
      fields
    }
  };
}
