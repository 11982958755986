import { find, get } from 'lodash';
import * as workerTimers from 'worker-timers';
import i18n from '../../../i18n';

const formatPeacks = (value) => `${Math.round(value)} W`;

const sleep = (ms) => new Promise((resolve) => workerTimers.setTimeout(resolve, ms));

export default async function calculatePeaks(peak_production, peak_consumption) {
  await sleep(50);
  const peaks = { lines: [] };
  const customPeaks = {};

  if (peak_production) {
    const prodDataMax = get(this.chart, ['yAxis', '0', 'series', '0', 'dataMax']);
    const prodPoints = get(this.chart, ['series', '0', 'points']);
    if (prodDataMax) {
      const prodMaxValue = get(
        find(prodPoints, (point) => {
          if (point.y === prodDataMax) {
            customPeaks.production = {
              value: point.y,
              x: point.plotX,
              y: point.plotY
            };
            return true;
          }
          return false;
        }),
        'y'
      );
      if (prodMaxValue) {
        peaks.prodMaxValue = prodMaxValue;
        peaks.lines.push({
          value: prodMaxValue,
          color: '#c59125',
          dashStyle: '4px 4px',
          width: 2,
          label: {
            text: `${i18n.t('prodMax')}: ${formatPeacks(prodMaxValue)}`,
            useHTML: true,
            style: {
              color: 'var(--primary-color)',
              background: '#25272d',
              fontSize: '13px',
              fontWeight: 500,
              transform: 'translateY(-3px)'
            }
          }
        });
      }
    }
  }

  if (peak_consumption) {
    const consDataMax = get(this.chart, ['yAxis', '0', 'series', '3', 'dataMax']);
    const consPoints = get(this.chart, ['series', '3', 'points']);
    if (consDataMax) {
      const consMaxValue = get(
        find(consPoints, (point) => {
          if (point.y === consDataMax) {
            customPeaks.consumption = {
              value: point.y,
              x: point.plotX,
              y: point.plotY
            };
            return true;
          }
          return false;
        }),
        'y'
      );
      if (consMaxValue) {
        peaks.consMaxValue = consMaxValue;
        peaks.lines.push({
          value: consMaxValue,
          color: '#30639a',
          dashStyle: '4px 4px',
          width: 2,
          label: {
            text: `${i18n.t('consMax')}: ${formatPeacks(consMaxValue)}`,
            useHTML: true,
            align: 'right',
            style: {
              color: '#3998f9',
              background: '#25272d',
              fontSize: '13px',
              fontWeight: 500,
              transform: 'translate(-30px, -3px)'
            }
          }
        });
      }
    }
  }
  console.log('%c PEAKS: ', 'background: #DCDCDC', peaks);
  this.setState({ peaks });
}
