import * as actionTypes from './constants';

export const getPaymentUrlRequest = () => ({
  type: actionTypes.FETCH_PAYMENT_URL_REQUEST
});

export const setPaymentUrlSuccess = (url) => ({
  type: actionTypes.FETCH_PAYMENT_URL_SUCCESS,
  payload: {
    url
  }
});

export const setPaymentUrlFailure = (url) => ({
  type: actionTypes.FETCH_PAYMENT_URL_FAILURE,
  payload: {
    url
  }
});

export const redirectToPaymentPage = ({ endUserId, url }) => ({
  type: actionTypes.REDIRECT_TO_PAYMENT_PAGE,
  payload: {
    endUserId,
    url
  }
});

export const goToAfterLogin = (pathName) => ({
  type: actionTypes.GO_TO_AFTER_LOGIN,
  payload: {
    pathName
  }
});
