import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import './index.css';
import _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import columns from './columns';
import TopBar from '../../components/TopBar';
import { UserInfoSub } from '../../components/ReactTable/renderFunctions';
import SearchField from '../../components/SearchField';
import { DataListPagination, DataListFilterV2, DataListV2 } from '../DataList';
import AddUserWindow from './AddUserWindow';
import ChangeColumnsModalWindow from '../../components/ChangeColumnsModalWindow';
import DeleteUserWindow from '../../components/DeleteUserWindow';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import AdditionalDataListFilters from '../../components/AdditionalDataListFilters';
import Pagination from '../../components/ReactTable/Pagination';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import Footer from '../../components/Footer';
import { LargeButton } from '../../components/ReduxFormFields/SimpleButton';
import getListURLUsers from './utils/getListURLUsers';
import { dataListID, switchesArray } from './constants';
import {
  userInfoSelector,
  userSelector,
  visibleWebSelector
} from './selectors';
import {
  isInstallationCompletedSelector,
  supportContractFlagSelector
} from '../../components/AdditionalDataListFilters/selectors';
import { getPagesSettingSelector } from '../PagesSettings/selectors';
import { setPagesSettingColumnsWidth } from '../PagesSettings/actions';


/**
 * @module UsersTable
 */
const transformResponse = (data) => {
  const clonedData = _.clone(data.list);
  const user = {
    first_name: 'Unknown User',
    last_name: '',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

/**
 * Renders page for list of End users
 * @memberof module:UsersTable
 */
class UsersTable extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      userInfo,
      pagesSettings
    } = this.props;
    this.state = {
      columns: columns(myRoleType, pagesSettings[dataListID])
    };
    this.listURL = getListURLUsers({ myRoleType, userInfoId: userInfo._id });
    this.defaultParams = pagesSettings[dataListID].queries;
  }

  componentDidUpdate(prevProps) {
    const { pagesSettings: { [dataListID]: columnsPrevSettings } } = prevProps;
    const { myRoleType, pagesSettings: { [dataListID]: columnsNextSettings } } = this.props;

    if (!_.isEqual(columnsPrevSettings.columns, columnsNextSettings.columns)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ columns: columns(myRoleType, columnsNextSettings) });
    }
  }

  saveExpanderChanges = (all) => {
    this.setState({ expanded: all });
  };

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded } = this.state;
    const {
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: sendModal,
      myRoleType,
      visibleWeb
    } = this.props;
    const limitPermissionRoles = ['oem', 'viewer', 'pv_installer_employee_read_only'];

    const sendAddUser = (fields) => {
      if (myRoleType === 'pv_installer' && !fields.connectedOem) {
        delete fields.sendOemTemplate;
      }

      const updatedFields = (fields?.pv_installer && fields.pv_installer === 'null')
        ? _.omit(fields, ['pv_installer', 'connectedOem'])
        : { ...fields };

      return sendModal(
        'addData',
        {
          method: 'post',
          url: '/../users/solar-managers-users',
          data: updatedFields
        },
        () => dataListReloadData(dataListID)
      );
    };

    const sendArchiveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../users/solar-managers-users/${id}`
      },
      () => dataListReloadData(dataListID)
    );

    const sendRemoveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(dataListID)
    );

    return (
      <div className="main-table-container">
        {/* // TODO: Why we render modals but not open them */}
        {myRoleType !== 'oem' && (
          <AddUserWindow
            modalID="addData"
            onSubmit={sendAddUser}
            handleOnClose={closeModal.bind(null, 'addData')}
            myRoleType={myRoleType}
          />
        )}
        <DeleteUserWindow
          modalID="removeData"
          onSubmit={sendRemoveUser}
          onArchive={sendArchiveUser}
          handleOnClose={closeModal.bind(null, 'removeData')}
        />
        <ChangeColumnsModalWindow dataListID={dataListID} columns={switchesArray} />
        <TopBar
          updateButton
          showNavigation
          visibleWeb={visibleWeb}
        />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {' '}
              -
              {' '}
              {i18n.t('SMUs')}
            </span>
          </div>
          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-20">
            <div className="col-xl-12">
              <div className="row align-items-top">
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 m--margin-bottom-10">
                  <DataListFilterV2
                    listURL={this.listURL}
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'sm_id,email,first_name,last_name,company,street,city,zip,plant',
                      placeholder: i18n.t('searchAllTablesElements')
                    }}
                    listID={dataListID}
                    transformResponse={transformResponse}
                  />
                </div>
                <AdditionalDataListFilters
                  dataListID={dataListID}
                  transformResponse={transformResponse}
                  listURL={this.listURL}
                />
                {!limitPermissionRoles.includes(myRoleType) && (
                  <div className="col-auto ml-auto pr-0 btn-sm-us m--margin-bottom-10">
                    <LargeButton
                      onClick={openModal.bind(null, 'addData', null)}
                      title={i18n.t('addSMUbtn')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataListV2
              listID={dataListID}
              listURL={this.listURL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.state.columns,
                SubComponent: UserInfoSub,
                manual: true,
                onExpandedChange: (all) => this.saveExpanderChanges(all),
                expanded
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={dataListID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

UsersTable.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  visibleWeb: PropTypes.bool,
  userInfo: PropTypes.instanceOf(Object),
  pagesSettings: PropTypes.instanceOf(Object).isRequired
};

UsersTable.defaultProps = {
  visibleWeb: undefined,
  userInfo: {}
};

const mapStateToProps = createStructuredSelector({
  _users: userSelector,
  userInfo: userInfoSelector,
  visibleWeb: visibleWebSelector,
  supportContractFlag: supportContractFlagSelector,
  isInstallationCompleted: isInstallationCompletedSelector,
  pagesSettings: getPagesSettingSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  setPagesSettingColumnsWidth
})(UsersTable);
