import * as actionTypes from './constants';

const initialState = {
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_RECEIVED:
      return {
        ...action.payload,
        sendSuccessfull: true
      };
    case actionTypes.SIGN_UP_CHANGE_EMAIL:
      return {
        ...state,
        sendSuccessfull: false,
        changeEmail: true
      };
    case actionTypes.SIGN_UP_CHANGE_EMAIL_SUCC:
      return {
        ...state,
        ...action.payload,
        sendSuccessfull: true
      };
    case actionTypes.SIGN_UP_RESEND:
      return {
        ...state,
        resendEmail: true
      };
    case actionTypes.SIGN_UP_SEND:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.RECEIVED_INFORMATION_FROM_SERVER:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SIGN_UP_QR_RECEIVED:
      return {
        qr_data: action.payload.data
      };
    default:
      return state;
  }
};
