import React from 'react';
import './index.scss';
/**
 * Renders Solar Manager Footer for all pages
 */
const Footer = () => (
  <div className="mainFooter">
    <div className="poweredBy">
      Powered by
      <a
        href="https://www.solarmanager.ch/"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        Solar Manager
      </a>
    </div>
  </div>
);

export default Footer;
