import React from 'react';
import { Tooltip } from '@mui/material';

import './index.scss';
import { defaultValue } from './constants';
import CarChargingTooltip from './components/CarChargingTooltip';
import SwitchesTooltip from './components/SwitchesTooltip';
import SmartPlugTooltip from './components/SmartPlugTooltip';
import BatteryTooltip from './components/BatteryTooltip';
import HeatpumpTooltip from './components/HeatpumpTooltip';
import WaterHeaterTooltip from './components/WaterHeaterTooltip';
import EnergyMeasurementTooltip from './components/EnergyMeasurementTooltip';
import CarChargingSystemTooltip from './components/CarChargingSystemTooltip';
import InverterTooltip from './components/InverterTooltip';
import SmartMeterTooltip from './components/SmartMeterTooltip';
import InputDeviceTooltip from './components/InputDeviceTooltip';
import V2XTooltip from './components/V2XTooltip';
import TemperatureMeasurementTooltip from './components/TemperatureMeasurementTooltip';
import HomeAppliancesTooltip from './components/HomeAppliancesTooltip';

const devicesType = [
  'Car Charging',
  'Switches',
  'Smart Plug',
  'Heatpump',
  'Battery',
  'Water Heater',
  'Energy Measurement',
  'Car Charging System',
  'Inverter',
  'Smart Meter',
  'Input Device',
  'V2X',
  'Temperature',
  'Appliances'
];

/**
 * Render function for React-Table. Column for sensors data
 * @memberof module:ReactTable
 * @param  {object} original - props of the gateway
 */
export const renderSensorData = (props) => {
  const {
    sensorsData,
    original: { id, type, device_group, data }
  } = props;

  const { warmWaterDevices, warmWaterDevices2Step, warmWaterDevices3Step } = data || {};

  const sensorData = sensorsData?.find((el) => el._id === id);
  const {
    switchState,
    inputState,
    heatingAdjustment,
    onTimeToday,
    tempInside,
    tempOutside,
    warmWaterTemp,
    currentOperationState,
    energyToday,
    consumedForLast24h,
    currentWaterTemp,
    currentPower,
    currentPowerInvSm,
    SOC,
    currentPercentOn,
    currentPowerBattery,
    dischargedForLast24Hours,
    chargedForLast24Hours,
    homeApplianceStatus,
    remainingTime
  } = sensorData || {};

  // converted currentPower from W to kW, just divide the currentPower value by 1000
  const convertedCurrentPower = currentPower ? Number(currentPower) / 1000 : defaultValue;
  const convertedCurrentPowerBattery = currentPowerBattery
    ? Number(currentPowerBattery) / 1000
    : defaultValue;

  const renderTooltipContent = {
    'Car Charging': (
      <CarChargingTooltip
        switchState={switchState}
        consumedForLast24h={consumedForLast24h}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    Switches: (
      <SwitchesTooltip
        switchState={switchState}
        consumedForLast24h={consumedForLast24h || defaultValue}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    'Smart Plug': (
      <SmartPlugTooltip
        switchState={switchState}
        convertedCurrentPower={convertedCurrentPower}
        consumedForLast24h={consumedForLast24h}
      />
    ),
    Battery: (
      <BatteryTooltip
        convertedCurrentPower={convertedCurrentPower}
        SOC={SOC}
      />
    ),
    Heatpump: (
      <HeatpumpTooltip
        heatingAdjustment={heatingAdjustment}
        onTimeToday={onTimeToday}
        tempInside={tempInside}
        tempOutside={tempOutside}
        warmWaterTemp={warmWaterTemp}
        currentOperationState={currentOperationState}
        energyToday={energyToday}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    'Water Heater': (
      <WaterHeaterTooltip
        currentWaterTemp={currentWaterTemp}
        convertedCurrentPower={convertedCurrentPower}
        currentPercentOn={currentPercentOn}
        consumedForLast24h={consumedForLast24h}
        device_group={device_group}
        warmWaterDevices={warmWaterDevices}
        warmWaterDevices2Step={warmWaterDevices2Step}
        warmWaterDevices3Step={warmWaterDevices3Step}
      />
    ),
    'Energy Measurement': (
      <EnergyMeasurementTooltip
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    'Car Charging System': (
      <CarChargingSystemTooltip
        consumedForLast24h={consumedForLast24h}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    Inverter: (
      <InverterTooltip currentPowerInvSm={currentPowerInvSm} />
    ),
    'Smart Meter': (
      <SmartMeterTooltip currentPowerInvSm={currentPowerInvSm} />
    ),
    'Input Device': (
      <InputDeviceTooltip inputState={inputState} />
    ),
    V2X: (
      <V2XTooltip
        convertedCurrentPowerBattery={convertedCurrentPowerBattery}
        SOC={SOC}
        chargedForLast24Hours={chargedForLast24Hours}
        dischargedForLast24Hours={dischargedForLast24Hours}
      />
    ),
    Temperature: (
      <TemperatureMeasurementTooltip currentWaterTemp={currentWaterTemp} />
    ),
    Appliances: (
      <HomeAppliancesTooltip remainingTime={remainingTime} homeApplianceStatus={homeApplianceStatus} />
    )
  };

  return (
    <>
      {devicesType.includes(type) ? (
        <Tooltip
          title={<div className="sensorData-container">{renderTooltipContent[type]}</div>}
          classes={{ tooltip: 'sensorData-tooltip', popper: 'sensorData-popper' }}
          enterTouchDelay={0}
          leaveTouchDelay={10000}
        >
          <i className="la la-info-circle" />
        </Tooltip>
      ) : null}
    </>
  );
};

export default renderSensorData;
