import * as actionTypes from './constants';

export function getNotAttachedToViewerEndUsers(params, modalID) {
  return {
    type: actionTypes.GET_NOT_ATTACHED_TO_VIEWER_END_USERS,
    payload: {
      params,
      modalID
    }
  };
}

export function setNotAttachedToViewerEndUsers(endUsers) {
  return {
    type: actionTypes.SET_NOT_ATTACHED_TO_VIEWER_END_USERS,
    payload: {
      endUsers
    }
  };
}

export function getOEMsForViewerList(params) {
  return {
    type: actionTypes.GET_OEMS_FOR_VIEWER_LIST,
    payload: {
      params
    }
  };
}

export function setOEMsForViewerList(oems) {
  return {
    type: actionTypes.SET_OEMS_FOR_VIEWER_LIST,
    payload: {
      oems
    }
  };
}

export function getPVInstallersForViewerList(params) {
  return {
    type: actionTypes.GET_PV_INSTALLERS_FOR_VIEWER_LIST,
    payload: {
      params
    }
  };
}

export function getPVInstallersForViewerAllList(params) {
  return {
    type: actionTypes.GET_PV_INSTALLERS_FOR_VIEWER_ALL_LIST,
    payload: {
      params
    }
  };
}

export function setPVInstallersForViewerList(pvInstallers) {
  return {
    type: actionTypes.SET_PV_INSTALLERS_FOR_VIEWER_LIST,
    payload: {
      pvInstallers
    }
  };
}

export function getAllNotAttachedToViewerInstallers(params) {
  return {
    type: actionTypes.GET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS,
    payload: {
      params
    }
  };
}

export function setAllNotAttachedTOViewerInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS,
    payload: {
      installers
    }
  };
}

export function setAllAttachedToViewerInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_ATTACHED_TO_VIEWER_INSTALLERS,
    payload: {
      installers
    }
  };
}

export function getAllNotAttachedToViewerOems(params) {
  return {
    type: actionTypes.GET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS,
    payload: {
      params
    }
  };
}

export function setAllNotAttachedToViewerOems(oems) {
  return {
    type: actionTypes.SET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS,
    payload: {
      oems
    }
  };
}

export function getOEMsListForViewer(params) {
  return {
    type: actionTypes.GET_OEMS_LIST_FOR_VIEWER,
    payload: {
      params
    }
  };
}


export const setLoading = (payload) => ({ type: actionTypes.SET_LOADING, payload });
