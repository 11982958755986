import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import ReactTable from 'react-table';

import DataList, { dataListReloadData } from '../../../DataList';
import { closeModalWindow, modalWindowSend, openModalWindow } from '../../../ModalWindow/actions';
import { addEndUsersToViewerModalID, dataListIDEndUsersForViewer, getEndUsersForViewerParams } from '../../constants';
// eslint-disable-next-line import/named
import { AddEndUserToViewerWindow, DeleteEndUserWindow } from '../../modals';
import { columnsEndUser } from '../../columns';
import i18n from '../../../../i18n';
import { UserInfoSub } from '../../../../components/ReactTable/renderFunctions';
import { getNotAttachedToViewerEndUsers } from '../../actions';

const EndUsersTab = (props) => {
  const {
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    closeModalWindow: closeModal,
    connectedEndUsersUrl,
    transformResponse,
    onExpandedChange,
    viewerID,
    expanded
  } = props;
  const columns = useMemo(() => columnsEndUser(), []);
  const dispatch = useDispatch();

  // Delete an end-user
  const sendDeleteEndUser = ({ id }) => modalSend(
    'deleteData',
    {
      method: 'put',
      url: '/../users/remove-viewer-from-users',
      data: {
        viewerId: viewerID,
        userIds: [id]
      }
    },
    () => {
      dispatch(getNotAttachedToViewerEndUsers({ viewerId: viewerID, ...getEndUsersForViewerParams }));
      return dataListReloadData(dataListIDEndUsersForViewer);
    }
  );

  // Add end-users
  const sendAddEndUsers = (userIds) => {
    modalSend(
      addEndUsersToViewerModalID,
      {
        method: 'put',
        url: '/../users/attach-viewer-to-users',
        data: {
          viewerId: viewerID,
          userIds
        }
      },
      () => {
        dispatch(getNotAttachedToViewerEndUsers({ viewerId: viewerID, ...getEndUsersForViewerParams }));
        return dataListReloadData(dataListIDEndUsersForViewer);
      }
    );
  };

  return (
    <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
      <div className="pvInstallerAddBtn">
        <a
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
          onClick={openModal.bind(null, addEndUsersToViewerModalID, null)}
        >
          <span>
            <span>{i18n.t('addSMUbtn')}</span>
          </span>
        </a>
      </div>
      <DeleteEndUserWindow
        modalID="deleteData"
        onSubmit={sendDeleteEndUser}
        handleOnClose={closeModal.bind(null, 'deleteData')}
      />
      <AddEndUserToViewerWindow
        modalID={addEndUsersToViewerModalID}
        handleSubmit={sendAddEndUsers}
        viewerUserId={viewerID}
        handleOnClose={closeModal.bind(null, addEndUsersToViewerModalID)}
      />
      <DataList
        listID={dataListIDEndUsersForViewer}
        listURL={connectedEndUsersUrl}
        params={{ ...getEndUsersForViewerParams }}
        transformResponse={transformResponse}
        Component={ReactTable}
        componentProps={{
          defaultPageSize: 0,
          className: '-highlight m-datatable__table',
          columns,
          SubComponent: UserInfoSub,
          manual: true,
          onExpandedChange,
          expanded
        }}
      />
    </div>
  );
};

EndUsersTab.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  viewerID: PropTypes.string.isRequired,
  connectedEndUsersUrl: PropTypes.string.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  expanded: PropTypes.instanceOf(Object)
};

EndUsersTab.defaultProps = {
  expanded: undefined
};

export default connect(null, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(EndUsersTab);
