export const DOMAINAPI = process.env.REACT_APP_DOMAINAPI || 'https://dev-api.solar-manager.ch';
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://dev-socket.solar-manager.ch';
export const SOLAR_PAYMENT_SERVICE = process.env.REACT_APP_SOLAR_PAYMENT_SERVICE || 'https://api-payment-dev.solarmanager.ch';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@solarmanager.ch';
export const REACT_GA_TRACKING_ID = process.env.REACT_APP_REACT_GA_TRACKING_ID || 'G-RKH6CYRZ5T';

export const DEFAULT_TIMEZONE = 'UTC';

export const APP_ID = 'solarManagerEndUserApp';

export const HELION_APP_ID = 'helionEndUserApp';

export const SOLAR_MANAGER_APP_ID = 'solarManagerEndUserApp';

// TODO: remove after testing update ci
window.appConfig = {
  DOMAINAPI: process.env.REACT_APP_DOMAINAPI,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  SOLAR_PAYMENT_SERVICE: process.env.REACT_APP_SOLAR_PAYMENT_SERVICE,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  REACT_GA_TRACKING_ID: process.env.REACT_APP_REACT_GA_TRACKING_ID
};
