import { createSelector } from 'reselect';

import { DATA_EXPORT_MODAL } from './constant';

const getIsLoading = (state) => state?.modals?.isLoading;

const getDataExportModal = (state) => state?.modals?.[DATA_EXPORT_MODAL];

/**
 * Data export modal selector
 */
export const dataExportModalSelector = createSelector([getDataExportModal], (modal) => modal);

/**
 * Initial values for all clients data export form
 */
export const dataExportModalInitialValues = createSelector([getDataExportModal], (modal) => modal?.data?.initialValues);

/**
 * Modal loading selector
 */
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
