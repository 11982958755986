import React, {
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flowRight, omit } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs';
import 'react-table/react-table.css';


/**
 * Components
 * @ignore
 */
import DataList from '../DataList';
import TabContentContainer from './components/TabContentContainer';
import {
  AddDeviceWindowHoc,
  DeleteDeviceWindow,
  EditDeviceWindowHoc,
  SearchIPWindow
} from './modals';
import {
  DevicesTab,
  InputDevicesTab,
  InvertersTab,
  LoadGuardTab,
  SmartMeterTab
} from './Tabs';


/**
 * Actions
 * @ignore
 */
import {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
} from '../ModalWindow/actions';
import { sensorsOauth2AllowedToDisabledEditModal } from '../SensorsOauth2/actions';
import { dataListReloadData } from '../DataList/actions';


/**
 * Selectors
 * @ignore
 */
import {
  checkMyRoleTypeSelector,
  editModalDataSelector,
  getUserId,
  isDisabledOauth2EditModalSelector,
  sensorsSelector,
  titleForIpModalWindowSelector
} from './selectors';
import {
  getDataFromSensors,
  getSubSensorsFromSensors,
  lastSensorsDataSelector
} from '../UserDetails/selectors';


import {
  transformFieldsForAPI,
  omitFakeField,
  getAddTitle
} from './utils';
import { SENSORS_LIST_ID } from '../UserDetails/constants';

import i18n from '../../i18n';

import './styles.scss';

const tagsListID = 'deviceTags';

/**
 * @module UserDevices
 */
/**
 * Table with user devices in page UserDetails
 * @memberof module:UserDevices
 */
const DevicesTable = (props) => {
  const {
    sensorsOauth2AllowedToDisabledEditModal: sensorsOauth2AllowedToDisabledEditModalFromProps,
    dataListReloadData: dataListReloadDataFromProps,
    closeModalWindow: closeModalWindowFromProps,
    modalWindowSend: modalWindowSendFromProps,
    openModalWindow: openModalWindowFromProps,
    isDisabledOauth2EditModal,
    searIpWIndowTitle,
    editModalData,
    dataSensors,
    sensorsData,
    myRoleType,
    subSensors,
    sensors,
    owner,
    endUserId,
    userId = owner
  } = props;
  const isExistSmartMeter = !!dataSensors.length && dataSensors[0].device_type === 'smart-meter';

  const [tabIndex, setTabIndex] = useState(0);
  const [type, setType] = useState(isExistSmartMeter ? 'sub-meter' : 'devices');

  const devicesUrl = `/../sensors/${type}/${userId}`;
  const loadGuardUrl = `/../loadguards/${userId}`;
  const tagsListURL = `/sensortags/${userId}`;
  const subMetersUrl = `/../sensors/sub-meter/${userId}`;

  const updatedType = isExistSmartMeter ? 'sub-meter' : type;

  const getNewDevices = dataListReloadData.bind(null, SENSORS_LIST_ID);

  const changeType = (index) => {
    const deviceTypes = {
      0: 'devices',
      1: 'inverters',
      2: 'smart-meter',
      3: 'input-devices',
      4: 'loadguards'
    };

    if (deviceTypes?.[index]) {
      setType(deviceTypes[index]);
      setTabIndex(index);
    } else {
      throw new Error('unknown devices');
    }
  };

  const devicesTypeTitleForBtn = useMemo(() => getAddTitle(updatedType), [updatedType]);

  const omitDevicePassword = (fields) => {
    const newFields = { ...fields };
    const oldFields = { ...props.sensors.data.find(({ _id }) => _id === newFields.id) };
    if (
      newFields?.data?.Password
      && oldFields?.data?.Password
      && newFields.data.Password === oldFields.data.Password
    ) {
      const data = omit(newFields.data, ['Password']);
      return { ...newFields, data };
    }
    return newFields;
  };

  const getNewTags = () => dataListReloadDataFromProps(tagsListID);

  const openAddDevices = () => {
    getNewTags();
    openModalWindowFromProps('addDevice', null);
  };

  const closeAddDevice = () => closeModalWindowFromProps('addDevice');

  const closeEditDevice = () => closeModalWindowFromProps('editRow');

  const closeRemoveDevice = () => closeModalWindowFromProps('removeData');

  const closeSearchIP = () => closeModalWindowFromProps('searchIP');

  const addDeviceRequest = (fields) => {
    const devicesUrls = {
      loadguards: `/../loadguards/${userId}`,
      'sub-meter': `/../sensors/sub-meter/${userId === 'my' ? endUserId : userId}`,
      default: `/../sensors/${type}/${userId}`
    };

    const devicesUrlAdd = devicesUrls?.[updatedType] || devicesUrls.default;

    return modalWindowSendFromProps(
      'addDevice',
      {
        method: 'post',
        url: devicesUrlAdd,
        data: transformFieldsForAPI(omitFakeField(fields)),
        isSubSensor: updatedType === 'sub-meter' && subMetersUrl
      },
      getNewDevices
    );
  };

  const removeDeviceRequest = ({ id }) => {
    const devicesUrls = {
      loadguards: '/../loadguards',
      'sub-meter': `/../sensors/sub-meter/${userId === 'my' ? endUserId : userId}`,
      default: `/../sensors/${type}/${userId}`
    };
    const isSubSensor = subSensors?.some((sensor) => sensor.id === id);
    const devicesUrlRemove = devicesUrls?.[isSubSensor ? 'sub-meter' : type] || devicesUrls.default;

    return modalWindowSendFromProps(
      'removeData',
      {
        method: 'delete',
        url: `${devicesUrlRemove}/${id}`,
        isSubSensor: isSubSensor && subMetersUrl
      },
      getNewDevices
    );
  };

  const editDeviceRequest = (fields) => {
    const devicesUrls = {
      loadguards: '/../loadguards',
      'sub-meter': `/../sensors/sub-meter/${userId === 'my' ? endUserId : userId}`,
      default: `/../sensors/${type}/${userId}`
    };
    const isSubSensor = subSensors?.some((sensor) => sensor.id === fields?.id);

    const devicesUrlEdit = devicesUrls?.[isSubSensor ? 'sub-meter' : type] || devicesUrls.default;
    const omitFields = flowRight(omitDevicePassword, omitFakeField);

    if (!isDisabledOauth2EditModal) {
      sensorsOauth2AllowedToDisabledEditModalFromProps(true);
    }

    return modalWindowSendFromProps(
      'editRow',
      {
        method: 'put',
        url:
          type === 'loadguards'
            ? `${devicesUrlEdit}/${fields.loadGuardDeviceId}`
            : `${devicesUrlEdit}/${fields.id}`,
        data: transformFieldsForAPI(omitFields(fields)),
        isSubSensor: isSubSensor && subMetersUrl
      },
      getNewDevices
    );
  };

  const transformResponse = (data) => ({
    total: data.length,
    data
  });

  const defaultParams = {
    _limit: 1000,
    _sort: 'priority:asc'
  };

  const { total } = sensors;

  const generalTabProps = {
    userId,
    myRoleType,
    total,
    transformResponse,
    defaultParams,
    ...(type !== 'loadguards') ? {
      sensorsData,
      openAddDevices,
      devicesUrl
    } : {}
  };

  return (
    <>
      <DataList
        listID={tagsListID}
        listURL={tagsListURL}
        forceKeepData
      />

      <AddDeviceWindowHoc
        modalID="addDevice"
        handleOnClose={closeAddDevice}
        onSubmit={addDeviceRequest}
        deviceType={updatedType}
        title={devicesTypeTitleForBtn}
      />

      <EditDeviceWindowHoc
        modalID="editRow"
        handleOnClose={closeEditDevice}
        onSubmit={editDeviceRequest}
        deviceType={updatedType}
        enableReinitialize
        initialValues={editModalData}
      />

      <SearchIPWindow
        modalID="searchIP"
        handleOnClose={closeSearchIP}
        title={searIpWIndowTitle}
      />

      <DeleteDeviceWindow
        modalID="removeData"
        handleOnClose={closeRemoveDevice}
        onSubmit={removeDeviceRequest}
      />

      <div className="container-fluid">
        <div className="table-devices-body">
          <Tabs selectedIndex={tabIndex} onSelect={changeType}>

            <TabList>
              <Tab>
                <TabContentContainer>
                  {i18n.t('devices')}
                </TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>
                  {i18n.t('inverters')}
                </TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>
                  {i18n.t('Smart Meter')}
                </TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>
                  {i18n.t('inputDevices')}
                </TabContentContainer>
              </Tab>
              <Tab className="react-tabs__tab load-guard-tab-item">
                <TabContentContainer className="load-guard-tab-container">
                  {i18n.t('loadGuard')}
                </TabContentContainer>
              </Tab>
            </TabList>

            <TabPanel>
              <DevicesTab
                {...generalTabProps}
              />
            </TabPanel>

            <TabPanel>
              <InvertersTab
                {...generalTabProps}
              />
            </TabPanel>

            <TabPanel>
              <SmartMeterTab
                {...generalTabProps}
                isExistSmartMeter={isExistSmartMeter}
                subMetersUrl={subMetersUrl}
              />
            </TabPanel>

            <TabPanel>
              <InputDevicesTab
                {...generalTabProps}
              />
            </TabPanel>

            <TabPanel>
              <LoadGuardTab
                {...generalTabProps}
                loadGuardUrl={loadGuardUrl}
                getNewTags={getNewTags}
              />
            </TabPanel>

          </Tabs>
        </div>
      </div>
    </>
  );
};

DevicesTable.propTypes = {
  editModalData: PropTypes.instanceOf(Object).isRequired,
  sensors: PropTypes.instanceOf(Object).isRequired,
  owner: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  searIpWIndowTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  sensorsData: PropTypes.instanceOf(Object),
  dataSensors: PropTypes.instanceOf(Array),
  subSensors: PropTypes.instanceOf(Array),
  sensorsOauth2AllowedToDisabledEditModal: PropTypes.func.isRequired,
  isDisabledOauth2EditModal: PropTypes.bool.isRequired,
  dataListReloadData: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  myRoleType: PropTypes.string,
  endUserId: PropTypes.string,
  userId: PropTypes.string
};

DevicesTable.defaultProps = {
  searIpWIndowTitle: undefined,
  sensorsData: undefined,
  myRoleType: undefined,
  userId: undefined,
  endUserId: undefined,
  subSensors: undefined,
  dataSensors: []
};

const mapStateToProps = createStructuredSelector({
  isDisabledOauth2EditModal: isDisabledOauth2EditModalSelector,
  searIpWIndowTitle: titleForIpModalWindowSelector,
  editModalData: editModalDataSelector,
  sensorsData: lastSensorsDataSelector,
  dataSensors: getDataFromSensors,
  subSensors: getSubSensorsFromSensors,
  owner: checkMyRoleTypeSelector,
  endUserId: getUserId,
  sensors: sensorsSelector
});

export default connect(mapStateToProps, {
  sensorsOauth2AllowedToDisabledEditModal,
  dataListReloadData,
  closeModalWindow,
  modalWindowSend,
  openModalWindow
})(DevicesTable);
