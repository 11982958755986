import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import i18n from '../../../i18n';
import { isLoadingSelector, modalsRemoveDataSelector } from '../selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * Renders confirmation window before sending to updated selected rows (installed SM page)
 * @memberof module:InstalledSM
 */
class UpdateConfirmationWindow extends React.Component {
  render() {
    const
      {
        confirmUpdate, handleOnClose,
        modalID, isLoading
      } = this.props;
    return (
      <ModalWindow
        modalID={modalID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('updFirmware')}</h5>
        </div>
        {isLoading ? (<div className="installedsm-delete-prealoder-container"><Preloader /></div>) : (
          <>
            <div className="modal-body">
              <div className="text-confirmation">{i18n.t('confirmUpdateSMU')}</div>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={confirmUpdate}
              >
                {i18n.t('update')}
              </button>
            </div>
          </>
        )}
      </ModalWindow>
    );
  }
}

UpdateConfirmationWindow.propTypes = {
  confirmUpdate: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

UpdateConfirmationWindow.defaultProps = {
  isLoading: false
};
const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: modalsRemoveDataSelector
});

export default connect(mapStateToProps)(UpdateConfirmationWindow);
