import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PuffLoader from 'react-spinners/PuffLoader';
import { translate } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { signInCheckJWT, signInValidJWT, signInInvalidJWT } from '../SignIn/actions';
import Preloader from '../../components/Preloader';
import { userSelector, verifyingJWTSelector } from './selectors';

export default (Component) => {
  /**
   * Checks if user is NOT authorized. If he is NOT authorized, it allows him to open guests page. Otherwise it does redirect to home page.
   * @memberof module:PermissionChecker
   */
  const OnlyGuests = (props) => {
    const
      {
        user,
        verifyingJWT,
        tReady,
        i18n,
        t
      } = props;

    useEffect(() => {
      const
        {
          signInCheckJWT: signIn,
          signInInvalidJWT: signInInv
        } = props;

      const cookies = new Cookies();

      const jwt = cookies.get('jwt');
      if (jwt) {
        if (verifyingJWT) {
          signIn();
        }
      } else {
        signInInv();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT || !tReady) {
      return (
        <div className="mainPreLoaderParent">
          <div className="mainPreLoader">
            <PuffLoader
              size={70}
              color="#f7bd27"
              speedMultiplier={3}
            />
          </div>
        </div>
      );
    }

    if (user) {
      return (
        <Redirect to="/" />
      );
    }
    return (
      <Suspense fallback={<Preloader />}>
        <Component {...props} i18n={i18n} t={t} />
      </Suspense>
    );
  };


  OnlyGuests.propTypes = {
    signInCheckJWT: PropTypes.func.isRequired,
    signInInvalidJWT: PropTypes.func.isRequired,
    verifyingJWT: PropTypes.bool,
    user: PropTypes.instanceOf(Object),
    i18n: PropTypes.instanceOf(Object).isRequired,
    t: PropTypes.instanceOf(Object).isRequired,
    tReady: PropTypes.bool.isRequired
  };

  OnlyGuests.defaultProps = {
    user: undefined,
    verifyingJWT: undefined
  };

  const mapStateToProps = createStructuredSelector({
    user: userSelector,
    verifyingJWT: verifyingJWTSelector
  });

  return translate()(
    connect(
      mapStateToProps,
      { signInCheckJWT, signInValidJWT, signInInvalidJWT }
    )(OnlyGuests)
  );
};
