export const filteredListOfOptions = [
  {
    key: undefined,
    value: 'workStatusFilterPlaceholder'
  },
  {
    key: true,
    value: 'yes'
  },
  {
    key: false,
    value: 'no'
  }
];
