import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SimpleButton = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .65rem 1rem;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  box-shadow: none;

  &:hover:hover:not(:disabled):not(.active) {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }`;


const SimpleLargeButton = styled(SimpleButton)`
  line-height: 1.5;
`;

const SimpleMediumButton = styled(SimpleButton)`
  line-height: 1;
`;

const SimpleSmallButton = styled(SimpleButton)`
  line-height: 0.5;
`;
/**
 * Renders button for all cases
 * @memberof module:ReactTable
 */
const LargeButton = ({ onClick, title, isDownloadIcon, ...props }) => (
  <SimpleLargeButton
    onClick={onClick}
    {...props}
  >
    {isDownloadIcon && (<i className="la la-download download-icon" />)}
    {title}
  </SimpleLargeButton>
);

const MediumButton = ({ onClick, title }) => (
  <SimpleMediumButton
    onClick={onClick}
  >
    {title}
  </SimpleMediumButton>
);

const SmallButton = ({ onClick, title }) => (
  <SimpleSmallButton
    onClick={onClick}
  >
    {title}
  </SimpleSmallButton>
);

LargeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDownloadIcon: PropTypes.bool,
  title: PropTypes.string.isRequired
};

LargeButton.defaultProps = {
  isDownloadIcon: false
};

MediumButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

SmallButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export { LargeButton, MediumButton, SmallButton };
