import {
  RenderSignal, RenderError, RenderStatus, RenderButtons, RenderHeaderWithTooltip, RenderGateway, RenderDate, renderUserName
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType) => ([
  {
    Header: i18n.t('SMID'),
    id: 'sm_id',
    accessor: (d) => (d.gateway?.sm_id || undefined),
    Cell: (row) => RenderGateway(row),
    minWidth: 180,
    className: 'sm_id'
  },
  {
    Header: i18n.t('fName'),
    id: 'last_name',
    Cell: renderUserName,
    minWidth: 180
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    id: 'signal',
    accessor: (d) => (d.gateway?.signal || 'not connected'),
    minWidth: 170,
    Cell: (row) => RenderSignal(row),
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('error'),
    id: 'hasErrors',
    accessor: 'error',
    minWidth: 100,
    Cell: (row) => RenderError(row),
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: { preLink: '/users/' },
        editUser: { preLink: '/setting-profile/solar-managers-users/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
