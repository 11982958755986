import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';

import '../Users/index.css';
import { createStructuredSelector } from 'reselect';
import TopBar from '../../components/TopBar';
import { CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import SearchField from '../../components/SearchField';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { LargeButton } from '../../components/ReduxFormFields/SimpleButton';
import ChooseLicenseWindow from '../../components/ChooseLicenseWindow';
import columns from './columns';
import AddPvInstallerWindow from './AddPvInstallerWindow';
import DeletePvInstallerWindow from './DeletePvInstallerWindow';
import { pvInstallersSendRequest } from './actions';
import { DATA_LIST_ID } from './constants';
import { userInfoSelector, visibleWebSelector } from './selectors';
import { APP_ID } from '../../config';

/**
 * @module PvInstallers
 */

const transformResponse = (data) => ({
  total: data.total,
  data: data.list
});

/**
 * Renders PV installers page
 * @memberof module:PvInstallers
 */
class PvInstallers extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);

    configSavedSettings(
      this,
      'pv-installers',
      {
        expander: true,
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'company:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  exportCSV = () => {
    const { pvInstallersSendRequest: sendRequest } = this.props;
    sendRequest(
      'get',
      '/../users/exports/pv-installers',
      { responseType: 'blob' },
      { errorMessage: 'exportRequestError', downloadFile: true }
    );
  };

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded } = this.state;
    const {
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: sendModal,
      myRoleType,
      userInfo,
      visibleWeb
    } = this.props;

    const isAdmin = myRoleType === 'solar_admin' || myRoleType === 'root';
    const limitPermissionRoles = ['oem', 'viewer'];

    const getListURLUsers = {
      support: '/../users/get-supported-pv-installer-users',
      oem: `/../oem/connected-pv-installers/${userInfo?._id}`,
      viewer: `/../users/get-pv-installer-users-attached-to-viewer?viewerId=${userInfo?._id}`,
      default: '/../users/pv-installer-users'
    };

    const listURL = getListURLUsers[myRoleType] || getListURLUsers.default;

    const sendAddUser = (fields) => sendModal(
      'addData',
      {
        method: 'post',
        url: '/../users/pv-installer-users',
        data: {
          ...fields,
          app_identifier: APP_ID
        }
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    const sendRemoveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    const sendArchiveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../users/pv-installer-users/${id}`
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    return (
      <div className="main-table-container">
        {myRoleType !== 'oem' && <ChooseLicenseWindow />}
        <AddPvInstallerWindow
          modalID="addData"
          onSubmit={sendAddUser}
          handleOnClose={closeModal.bind(null, 'addData')}
        />
        <DeletePvInstallerWindow
          modalID="removeData"
          onSubmit={sendRemoveUser}
          onArchive={sendArchiveUser}
          handleOnClose={closeModal.bind(null, 'removeData')}
        />
        <TopBar visibleWeb={visibleWeb} updateButton showNavigation />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {` - ${i18n.t('pvInstallers')}`}
            </span>
          </div>
          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="col-xl-12">
              <div className="row align-items-center">
                <div
                  className={`col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 ${
                    isAdmin ? '' : 'mr-auto'
                  }`}
                >
                  <DataListFilter
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'email,first_name,last_name,my_company,street,city,zip,phone',
                      placeholder: i18n.t('searchAllTablesElements')
                    }}
                    listID={DATA_LIST_ID}
                  />
                </div>
                {isAdmin && (
                  <div className={`col-4 col-lg-2 order-2 ${isAdmin ? 'mr-auto' : ''}`}>
                    <button
                      type="button"
                      onClick={this.exportCSV}
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                    >
                      {i18n.t('exportInstallers')}
                    </button>
                  </div>
                )}
                {!limitPermissionRoles.includes(myRoleType) && (
                  <div className="col-auto order-3 btn-sm-us">
                    <LargeButton
                      onClick={openModal.bind(null, 'addData', null)}
                      title={i18n.t('addPVIbtn')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataList
              listID={DATA_LIST_ID}
              listURL={listURL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                SubComponent: CompanyInfoSub,
                manual: true,
                onExpandedChange: (all) => {
                  this.saveExpanderChanges(all);
                },
                expanded
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

PvInstallers.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  pvInstallersSendRequest: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  userInfo: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool
};

PvInstallers.defaultProps = {
  userInfo: {},
  visibleWeb: false
};

const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  pvInstallersSendRequest
})(PvInstallers);
