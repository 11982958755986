import { createSelector } from 'reselect';
import { companyDataListID } from './constants';

const getCompanyDataList = (state) => state?.dataList;
const getUser = (state) => state.signIn?.user;
const getIsLoading = (state) => state?.modals?.isLoading;
const getModal = (state) => state?.modals?.addEmployee;
const getRemoveModalData = (state) => state?.modals?.removeData;

export const companyDataListSelector = createSelector([getCompanyDataList], (companyDataList) => companyDataList[companyDataListID] || {});
export const oemIdSelector = createSelector([getUser], (user) => user?._id);
export const visibleWebSelector = createSelector([getUser], (user) => user?.oem?.visible_web);
export const compLogoSelector = createSelector([getUser], (user) => user?.company_logo);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const modalSelector = createSelector([getModal], (modal) => modal);
export const removeModalSelector = createSelector([getRemoveModalData], (modal) => modal);
