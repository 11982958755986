import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';
import i18n from '../../../i18n';
import { countries, countryLabelsBack, countryLabels } from './constants';

import './index.css';

/**
 * Renders languageSelector for redux form
 * @memberof module:ReduxFormFields
 */
const ContrySelector = ({
  input,
  id,
  label,
  buttonClassName,
  meta: { touched, error },
  ...custom
}) => (
  <Fragment>
    <ReactFlagsSelect
      {...input}
      {...custom}
      rfsKey={id}
      selectButtonClassName={`${buttonClassName} ${custom.disabled ? `${buttonClassName}_disabled` : ''}`}
      onSelect={(country) => input.onChange(countryLabels[country])}
      countries={countries}
      customLabels={countryLabels}
      selected={countryLabelsBack[input.value]}
      selectedSize={18}
      placeholder={`${i18n.t('select')}`}
    />
    {touched && error && (
    <div className="inputCustomWarning">
      {i18n.t(error)}
    </div>
    )}
  </Fragment>
);

ContrySelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  buttonClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired
};

export default ContrySelector;
