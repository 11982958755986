import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { push } from 'react-router-redux';

import { handleError, trimAllFields } from '../../api_helper';
import * as SignInTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { receiveSignIn } from '../SignIn/actions';
import i18n, { changeLanguageTo } from '../../i18n';

function resetPassSendEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.RESET_PASS_SEND),
    map((action) => action.payload.fields),
    switchMap((fields) => (
      from(strapi.request('post', '/../auth/reset-password', {
        data: trimAllFields(fields)
      })).pipe(
        catchError(handleError),
        mergeMap(
          (result) => {
            if (result?.jwt) {
              strapi.setToken(result.jwt);
              changeLanguageTo(result.user, true);
              return of(
                receiveSignIn(result.user, false),
                push(result.user.role.type === 'end_user' ? '/' : '/users/'),
                addNotification({
                  type: 'success',
                  text: i18n.t('resetPasswordSuccess')
                })
              );
            }

            return of(
              addNotification({
                type: 'error',
                text: result
              })
            );
          }
        )
      )
    ))
  );
}

export default combineEpics(
  resetPassSendEpic
);
