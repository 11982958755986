import i18n from '../../../i18n';
import fieldsForDevices from '../utils/fieldsForDevices';

const devicesForUpdateWarmWaterDevices = ['1StepOnOff', 'PV Ready Switch'];
const devicesForUpdateWarmWaterDevices2Step = ['2StepControl', 'SG Ready Switch'];
const devicesForUpdateWarmWaterDevices3Step = ['3StepControl'];
/**
 * update default field list
 * according from: https://docs.google.com/document/d/1_jeQxuev38R-w6A-43PtRjtf0bxfqN6g
 * @function getUpdatedFieldList
 * @param {string} deviceName Name of switch device
 * @param {Object} data
 * @return {Array}
 */
export const getUpdatedFieldList = (deviceName, data) => {
  if (!deviceName || !data) return [];

  const fieldsList = [...(fieldsForDevices[deviceName] || [])];

  if (devicesForUpdateWarmWaterDevices.includes(deviceName) && data.warmWaterDevices) {
    const switchType = i18n.t(`warmWaterDevices.options.${data.warmWaterDevices}`);

    switch (switchType) {
      case 'Smart-me Relais':
        fieldsList.splice(3, 0, 'User', 'Password', 'SerialNumber');

        return fieldsList.map((item) => (item === 'relaisNumber' ? 'relaisNumber2' : item));

      case 'Shelly 1':
      case 'Shelly 1PM':
      case 'Shelly 3EM Switch':
      case 'Shelly EM Switch':
      case 'Shelly Pro 1PM':
        return fieldsList.filter((item) => item !== 'relaisNumber');
      case 'Shelly 2.5':
      case 'Shelly Pro 2PM':
        return fieldsList.map((item) => (item === 'relaisNumber' ? 'relaisNumber2' : item));
      default:
        return fieldsList;
    }
  }

  if (devicesForUpdateWarmWaterDevices2Step.includes(deviceName) && data.warmWaterDevices2Step) {
    const switchType = i18n.t(`warmWaterDevices2Step.options.${data.warmWaterDevices2Step}`);

    switch (switchType) {
      case 'GUDE 2312 with measurement':
      case 'Shelly Pro 2PM':
        return fieldsList.filter((item) => item !== 'relaisChooseSet');
      case 'Smart-me Relais':
        fieldsList.splice(3, 0, 'User', 'Password', 'SerialNumber');
        return fieldsList.filter((item) => item !== 'relaisChooseSet');

      default:
        return fieldsList;
    }
  }

  if (devicesForUpdateWarmWaterDevices3Step.includes(deviceName) && data.warmWaterDevices3Step) {
    const switchType = i18n.t(`warmWaterDevices3Step.options.${data.warmWaterDevices3Step}`);

    switch (switchType) {
      case 'GUDE 2312 with measurement':
        return fieldsList.filter((item) => item !== 'relaisChooseSet2');

      default:
        return fieldsList;
    }
  }

  return fieldsList;
};
