import { findDeviceByCategoryId } from '../findDeviceByCategoryId';

const setColorFromStore = (id, chart_settings) => {
  let color;
  if (chart_settings) {
    const { devicesCurrentPower, batteriesSoc } = chart_settings;
    color = findDeviceByCategoryId(batteriesSoc, id)?.color || findDeviceByCategoryId(devicesCurrentPower, id)?.color;
  }
  return color;
};

export default setColorFromStore;
