import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userDataSendRequest } from '../../actions';
import DevicesTable from '../../../DevicesTable';
import i18n from '../../../../i18n';
import { LargeButton } from '../../../../components/ReduxFormFields/SimpleButton';
import { houseFuseSelector, loadManagementSelector, userSelector } from '../../selectors';
import { closeModalWindow, openModalWindow, modalWindowSend } from '../../../ModalWindow/actions';
import { DATA_EXPORT_MODAL } from '../../../../components/DataExportWindow/constant';
import DataExportWindow from '../../../../components/DataExportWindow';
import UserDetailsTabs from '../../UserDetailsTabs';
import '../../index.scss';
import analytics from '../../../../analytics';
import getEventObj, { DATA_EXPORT_GATEWAY_DATA } from '../../../../analytics/events';

/**
 * This layout is rendered when page is being viewed by end_user
 * @memberof module:UserDetails
 */
const ViewByEndUsers = (props) => {
  const {
    myRoleType,
    user,
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    closeModalWindow: closeModal,
    houseFuse,
    loadManagement
  } = props;

  const sendEndUserDataExportDateRange = (fields) => {
    const { fromDate, toDate } = fields;
    const from = new Date(fromDate).toISOString();
    const to = new Date(toDate).toISOString();

    analytics.sendEvent(getEventObj(DATA_EXPORT_GATEWAY_DATA));

    return modalSend(DATA_EXPORT_MODAL, {
      method: 'post',
      url: '/../data/export-gateway-data',
      data: {
        gatewayIds: [user?.gateway?._id],
        from,
        to
      }
    });
  };

  return (
    <div>
      <div className="settings-header-block device-top-bar">
        <div className="col-md-12">
          <div
            className="row align-items-center justify-content-between"
            style={{ height: '62px' }}
          >
            <div className="col-auto">
              <h6 className="set-prof-head">{i18n.t('SettingsSMU')}</h6>
            </div>
            <div className="col-auto">
              <Link
                to="/dashboard/"
                className="btn m-btn--pill m-btn--air btn-secondary btn-settings btn-popup-canc back-to-dashboard"
              >
                <span>{i18n.t('back')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <UserDetailsTabs user={user} myRoleType={myRoleType} />
      <div className="container-fluid">
        <div className="d-flex justify-content-end order-3 btn-sm-us export-button">
          <LargeButton
            onClick={openModal.bind(null, DATA_EXPORT_MODAL, {
              initialValues: {
                fromDate: moment()
                  .subtract(1, 'years')
                  .startOf('day')
                  .valueOf(),
                toDate: moment().valueOf()
              }
            })}
            title={i18n.t('exportData')}
            isDownloadIcon
          />
        </div>
      </div>
      <DevicesTable showTopBar myRoleType={myRoleType} />
      {user.gateway && (
        <div className="container-fluid">
          <Link
            to="/load-management-dashboard/"
            className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right dashboard-btn mr-4 ${(!houseFuse || !loadManagement) && 'dashboard-btn-disabled'}`}
          >
            {i18n.t('loadManagementBtn')}
          </Link>
        </div>
      )}
      <DataExportWindow
        modalID={DATA_EXPORT_MODAL}
        onSubmit={sendEndUserDataExportDateRange}
        handleOnClose={closeModal.bind(null, DATA_EXPORT_MODAL)}
      />
    </div>
  );
};

ViewByEndUsers.propTypes = {
  myRoleType: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  houseFuse: PropTypes.number.isRequired,
  loadManagement: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  user: userSelector,
  houseFuse: houseFuseSelector,
  loadManagement: loadManagementSelector
});

export default connect(mapStateToProps, {
  userDataSendRequest,
  openModalWindow,
  modalWindowSend,
  closeModalWindow
})(ViewByEndUsers);
