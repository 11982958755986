import React, { memo } from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 * @ignore
 */
import { Field } from 'redux-form';
import CustomTextInput from '../CustomTextInput';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';

/**
 * Validation
 * @ignore
 */
import { normalizeLength } from '../../../containers/validation';

/**
 * Render DescriptionTagField
 * @function DescriptionTagField
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.deviceType one of: inverters, smart-meter, input-devices, ...
 */
const DescriptionTagField = (props) => {
  const {
    deviceType
  } = props;

  return (
    ['input-devices'].includes(deviceType)
        && (
        <div className="form-group m-form__group input-field">
          <Field
            name="description"
            component={CustomTextInput}
            label={i18n.t('description')}
            className="m-input"
            normalize={normalizeLength(25)}
          />
        </div>
        )
  );
};

DescriptionTagField.propTypes = {
  deviceType: PropTypes.string.isRequired
};

export default memo(DescriptionTagField);
