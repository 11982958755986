import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import carChargingOnIcon from '../../../../../assets/images/svg/car-charging-on.svg';
import carChargingOffIcon from '../../../../../assets/images/svg/car-charging-off.svg';
import carChargingIcon from '../../../../../assets/images/svg/car-charging.svg';

const renderCarChargingIcon = {
  0: carChargingOffIcon,
  1: carChargingIcon,
  2: carChargingOnIcon
};

const CarChargingTooltip = (props) => {
  const {
    switchState,
    consumedForLast24h,
    convertedCurrentPower
  } = props;

  return (
    <>
      <img
        src={
          renderCarChargingIcon[switchState]
            ? renderCarChargingIcon[switchState]
            : carChargingOffIcon
        }
        alt="Car Charging Icon"
      />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        {Number.isFinite(consumedForLast24h) && <span>{i18n.t('last24h')}</span>}
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        {Number.isFinite(consumedForLast24h) && (
          <span>
            {consumedForLast24h}
            {i18n.t('kwh')}
          </span>
        )}
      </div>
    </>
  );
};

CarChargingTooltip.propTypes = {
  switchState: PropTypes.number,
  consumedForLast24h: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

CarChargingTooltip.defaultProps = {
  consumedForLast24h: undefined,
  switchState: undefined
};

export default CarChargingTooltip;
