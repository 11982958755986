import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/actions';
import { REQUEST_ACCESS_TOKEN } from './constants';
import { openModalWindow } from '../ModalWindow/actions';
import { receiveFormData } from './actions';
import strapi from '../../strapi';
import i18n from '../../i18n';

function requestAccessToken(action$) {
  return action$.pipe(
    ofType(REQUEST_ACCESS_TOKEN),
    map((action) => action.payload),
    switchMap(({ body, currentModalId, currentModalData }) => from(
      strapi.request('post', '/../sensors/auth/oauth2', {
        data: {
          sensor: {
            device_group: currentModalData?.device_group
          },
          body
        }
      })
    ).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const tokenString = result && !result?.error && typeof result === 'object' ? JSON.stringify(result) : '';

        const newModalData = {
          ...currentModalData,
          data: {
            ...currentModalData.data,
            tokenString
          }
        };

        if (tokenString) {
          return of(
            openModalWindow(currentModalId, newModalData),
            receiveFormData(newModalData)
          );
        }

        return of(
          openModalWindow(currentModalId, newModalData),
          receiveFormData(newModalData),
          addNotification({
            type: 'error',
            text: result?.message || i18n.t('error')
          })
        );
      })
    )
    )
  );
}

export default combineEpics(requestAccessToken);
