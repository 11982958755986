import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ReactTable from 'react-table';
import PuffLoader from 'react-spinners/PuffLoader';
import i18n from '../../i18n';
import TopBar from '../../components/TopBar';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getColumns, getCrumbs } from './helpers';
import { InviteUsersTableHeader, DeleteInvitedUserModal, InviteUserModal } from './components';
import { getInvitedUsersSelector, getInvitedUsersLoadingSelector } from './store/selectors';
import { fetchInvitedUsers } from './store/actions';
import './index.scss';

const InvitedUsers = ({
  location,
  invitedUsers,
  isInvitedUsersLoading,
  fetchInvitedUsers: fetchInvitedUsersAction
}) => {
  const { userId, myRoleType } = location.state || {};
  const crumbs = useMemo(() => getCrumbs(userId, myRoleType), [userId, myRoleType]);
  const columns = useMemo(() => getColumns(myRoleType), [myRoleType]);

  const tableProps = {
    columns,
    data: invitedUsers,
    className: '-highlight m-datatable__table',
    pageSize: 1000,
    getTdProps: () => ({ style: { margin: 0, padding: '10px 0', marginLeft: 25 } }),
    getTheadThProps: () => ({ style: { margin: 0, padding: '10px 0', marginLeft: 25 } }),
    noDataText: i18n.t('noInvitedUsers')
  };

  useEffect(() => {
    if (userId) fetchInvitedUsersAction(userId);
  }, [userId]);// eslint-disable-line

  return (
    <>
      <DeleteInvitedUserModal userId={userId} />
      <InviteUserModal userId={userId} />
      <TopBar updateButton showNavigation />
      {isInvitedUsersLoading ? (
        <div className="d-flex position-absolute h-100 w-100 top-0 left-0 justify-content-center align-items-center">
          <PuffLoader
            size={50}
            color="#f7bd27"
            speedMultiplier={3}
          />
        </div>
      )
        : (
          <Box sx={{ p: 2 }} className="invited-users-wrapper">
            <Breadcrumbs crumbs={crumbs} />
            <InviteUsersTableHeader myRoleType={myRoleType} />
            <ReactTable {...tableProps} />
          </Box>
        )}
    </>
  );
};

InvitedUsers.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  invitedUsers: PropTypes.instanceOf(Array).isRequired,
  isInvitedUsersLoading: PropTypes.bool.isRequired,
  fetchInvitedUsers: PropTypes.func.isRequired
};


const mapStateToProps = createStructuredSelector({
  invitedUsers: getInvitedUsersSelector,
  isInvitedUsersLoading: getInvitedUsersLoadingSelector
});
export default connect(mapStateToProps, { fetchInvitedUsers })(InvitedUsers);
