import {
  RenderDate,
  RenderStatus
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';


const columnsEmp = () => ([
  {
    width: 35
  },
  {
    Header: i18n.t('company'),
    id: 'oemCompany',
    accessor: (d) => (d.oem ? d.oem.name : 'No company'),
    minWidth: 180,
    className: ''
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: 160,
    className: 'city-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  }
]);

export default columnsEmp;
