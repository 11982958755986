import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import i18n from '../../../i18n';
import { monitoringSendApplyWithFields } from '../../../containers/Monitoring/actions';
import { DATA_LIST_ID, DATA_LIST_URL } from '../../../containers/Monitoring/constants';
import analytics from '../../../analytics';
import getEventObj, { ERRORMONITORING_TICKETS } from '../../../analytics/events';
/**
 * Render function for React-Table. Column for status of user
 * @memberof module:ReactTable
 * @param  {string} value - priority status of user
 */
const RenderPriorityStatus = (props) => {
  const { value, original } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const clickHandler = (priority) => {
    const data = {
      targets: [
        original._id
      ],
      values: {
        priority
      }
    };
    analytics.sendEvent(getEventObj(ERRORMONITORING_TICKETS));
    dispatch(monitoringSendApplyWithFields(DATA_LIST_URL, DATA_LIST_ID, data));
    setOpen(false);
  };

  return (
    <div className="priority-dropdown">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`priority-dropdown-btn priority-dropdown-${value}`}
      >
        {i18n.t(value)}
        <i className="fa fa-caret-down" />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={clickHandler.bind(null, 'low')} className="priority_status_low">{i18n.t('low')}</MenuItem>
                  <MenuItem onClick={clickHandler.bind(null, 'medium')} className="priority_status_medium">{i18n.t('medium')}</MenuItem>
                  <MenuItem onClick={clickHandler.bind(null, 'high')} className="priority_status_high">{i18n.t('high')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

RenderPriorityStatus.propTypes = {
  value: PropTypes.string.isRequired,
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderPriorityStatus;
