import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select, { Creatable } from 'react-select';
import i18n from '../../i18n';

/**
 * Pushes new value to options
 * @param input {Object}
 * @param custom {Object}
 */
const pushNewOption = (input, custom) => {
  const { options } = custom;
  const { value } = input;
  if (Array.isArray(options)
    && typeof value !== 'undefined'
    && value !== null
    && value !== ''
    && typeof options.find((option) => (option.value === value)) === 'undefined') {
    options.unshift({ value, label: value });
  }
};

/**
 * Renders selector for redux form
 * @memberof module:ReduxFormFields
 */
const CustomSelect = ({
  creatable,
  maxLength,
  newOptionCreatedCB,
  input,
  meta: { touched, error },
  disabled,
  defaultValue,
  ...custom
}) => {
  const props = {
    clearable: false,
    ...input,
    ...custom,
    onInputChange: (value) => (Number.isSafeInteger(maxLength) ? value.substring(0, maxLength) : value),
    onChange: (selected) => {
      input.onChange(selected ? selected.value : null);
    },
    onBlur: () => {
      input.onBlur(input.value);
    },
    disabled,
    className: disabled ? 'disabeldSelect' : ''
  };

  if (creatable) {
    const onNewOptionClick = (prop) => {
      input.onChange(prop.value);
      if (typeof newOptionCreatedCB === 'function') newOptionCreatedCB(prop.value);
    };

    pushNewOption(input, custom);

    return (
      <Fragment>
        <Creatable
          {...props}
          onNewOptionClick={onNewOptionClick}
        />
        {touched && error && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Select {...props} searchable={false} />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </Fragment>
  );
};

CustomSelect.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  creatable: PropTypes.bool,
  newOptionCreatedCB: PropTypes.func,
  meta: PropTypes.instanceOf(Object).isRequired,
  defaultValue: PropTypes.instanceOf(Object),
  maxLength: PropTypes.number,
  disabled: PropTypes.bool
};

CustomSelect.defaultProps = {
  newOptionCreatedCB: undefined,
  creatable: false,
  maxLength: null,
  disabled: false,
  defaultValue: undefined
};

export default CustomSelect;
