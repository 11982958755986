import { createSelector } from 'reselect';
import {
  addEndUsersToViewerForm,
  addEndUsersToViewerModalID,
  addOEMToViewerForm,
  addOEMToViewerModalID,
  addPVInstallersToViewerForm,
  addPVInstallersToViewerModalID,
  dataListIDEndUsersForViewer,
  dataListIDOEMsForViewer,
  dataListIDPVInstallersForViewer,
  dataListIDViewer
} from './constants';

const getUser = (state) => state.signIn?.user;

const getAttachedPVInstallersForViewer = (state) => state.dataList?.[dataListIDPVInstallersForViewer]?.data;
const getAttachedEndUsersForViewer = (state) => state.dataList?.[dataListIDEndUsersForViewer]?.data;
const getAttachedOemsForViewer = (state) => state.dataList?.[dataListIDOEMsForViewer]?.data;
const getViewerDataList = (state) => state.dataList?.[dataListIDViewer] || {};

const getAddPVInstallersModal = (state) => state.modals?.[addPVInstallersToViewerModalID];
const getAddEndUsersModal = (state) => state.modals[addEndUsersToViewerModalID];
const getAddOemModal = (state) => state.modals?.[addOEMToViewerModalID];
const getDeleteDataModal = (state) => state.modals.deleteData;
const getIsLoading = (state) => state.modals?.isLoading;

const getRegisteredFieldsEndUserForViewer = (state) => state.form?.[addEndUsersToViewerForm]?.registeredFields;
const getRegisteredFieldsInstallerForViewer = (state) => state.form?.[addPVInstallersToViewerForm]?.registeredFields;
const getRegisteredFieldsOemForViewer = (state) => state.form?.[addOEMToViewerForm]?.registeredFields;

const getNotAttachedPVInstallersForViewer = (state) => state.viewerDetails?.notAttachedPVInstallers || [];
const getNotAttachedEndUsersForViewer = (state) => state.viewerDetails?.notAttachedEndUsers || [];
const getNotAttachedOEMsForViewer = (state) => state.viewerDetails?.notAttachedOEMs || [];
const getOemsForViewerForViewer = (state) => state.viewerDetails?.oems || [];
const getPvInstallersForViewer = (state) => state.viewerDetails?.pvInstallers || [];

const getIsPvInstallersListForViewerLoading = (state) => state.viewerDetails?.isPvInstallersListForViewerLoading;
const getIsOemsListForViewerLoading = (state) => state.viewerDetails?.isOemsListForViewerLoading;
const getIsNotAttachedUsersLoading = (state) => state.viewerDetails?.isNotAttachedUsersLoading;

export const viewerDataListSelector = createSelector([getViewerDataList], (viewerDataList) => viewerDataList);
export const visibleWebSelector = createSelector([getUser], (user) => user?.oem?.visible_web);
export const compLogoSelector = createSelector([getUser], (user) => user?.company_logo);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const deleteDataModalSelector = createSelector([getDeleteDataModal], (deleteData) => deleteData);

export const attachedPVInstallersSelectorForViewer = createSelector([getAttachedPVInstallersForViewer], (data) => data);
export const attachedEndUsersSelectorForViewer = createSelector([getAttachedEndUsersForViewer], (data) => data);
export const attachedOemsSelectorForViewer = createSelector([getAttachedOemsForViewer], (data) => data);

export const addPVInstallersModalSelector = createSelector([getAddPVInstallersModal], (addPVInstallersModal) => addPVInstallersModal);
export const addEndUsersModalSelector = createSelector([getAddEndUsersModal], (addEndUsersModal) => addEndUsersModal);
export const addOemModalSelector = createSelector([getAddOemModal], (addOemModal) => addOemModal);

export const notAttachedPVInstallersModalSelectorForViewer = createSelector([getNotAttachedPVInstallersForViewer], (notAttachedPVInstallersForViewer) => notAttachedPVInstallersForViewer);
export const notAttachedEndUsersSelectorForViewer = createSelector([getNotAttachedEndUsersForViewer], (notAttachedEndUsersForViewer) => notAttachedEndUsersForViewer);
export const notAttachedOEMsSelectorForViewer = createSelector([getNotAttachedOEMsForViewer], (notAttachedOEMsForViewer) => notAttachedOEMsForViewer);

export const pvInstallersSelectorForViewer = createSelector([getPvInstallersForViewer], (pvInstallers) => pvInstallers);
export const oemsSelectorForViewer = createSelector([getOemsForViewerForViewer], (oemsForViewer) => oemsForViewer);

export const registeredFieldsInstallerSelectorForViewer = createSelector([getRegisteredFieldsInstallerForViewer], (registeredFields) => registeredFields);
export const registeredFieldsEndUserSelectorForViewer = createSelector([getRegisteredFieldsEndUserForViewer], (registeredFields) => registeredFields);
export const registeredFieldsOemSelectorForViewer = createSelector([getRegisteredFieldsOemForViewer], (registeredFields) => registeredFields);

export const getIsLoadingSelectorForViewersEndUsers = createSelector(
  [
    getIsNotAttachedUsersLoading,
    getIsOemsListForViewerLoading,
    getIsPvInstallersListForViewerLoading
  ],
  (isNotAttachedUsersLoading, isOemsListForViewerLoading, isPvInstallersListForViewerLoading) => (
    {
      isNotAttachedUsersLoading,
      isOemsListForViewerLoading,
      isPvInstallersListForViewerLoading
    }
  )
);
