import * as actionTypes from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.MODAL_WINDOW_OPEN: {
      return {
        ...state,
        [action.payload.modalID]: {
          ...state[action.payload.modalID],
          opened: true,
          data: action.payload.data
        }
      };
    }
    case actionTypes.MODAL_WINDOW_CLOSE: {
      return {
        ...state,
        [action.payload.modalID]: {
          ...state[action.payload.modalID],
          data: {},
          opened: false
        }
      };
    }
    case actionTypes.MODAL_WINDOW_SEND: {
      return {
        ...state,
        [action.payload.modalID]: {
          ...state[action.payload.modalID],
          requestConfig: action.payload.requestConfig,
          cb: action.payload.cb,
          reloadPage: action.payload.reloadPage
        },
        isLoading: true
      };
    }
    case actionTypes.MODAL_STOP_LOADING: {
      return {
        ...state,
        [action.payload.modalID]: {
          ...state[action.payload.modalID]
        },
        isLoading: false
      };
    }
    case actionTypes.MODAL_START_LOADING: {
      return {
        ...state,
        [action.payload.modalID]: {
          ...state[action.payload.modalID]
        },
        isLoading: true
      };
    }
    default:
      return state;
  }
};
