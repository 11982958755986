import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as workerTimers from 'worker-timers';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import Preloader from '../../components/Preloader';
import { isLoadingSelector, modalDataSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * Renders delete employee confirmation modal window for employee page
 * @memberof module:Employee
 */
class DeleteEmployeeWindow extends React.Component {
  state = { mode: 0 };

  componentDidUpdate() {
    const { modal } = this.props;
    const { mode } = this.state;
    if (!modal.opened && mode !== 0) {
      // eslint-disable-next-line
      workerTimers.setTimeout(
        // eslint-disable-next-line consistent-return
        () => this.setState((state, props) => {
          if (state.mode !== 0 && !props.modal.opened) return { mode: 0 };
        }),
        500
      );
    }
  }

  render() {
    const { handleOnClose, onSubmit, modal, modalID, isLoading } = this.props;
    const { mode } = this.state;
    const title = 'delEmployee';
    let confirmation = 'confirmDelEmployee';
    if (mode === 1) {
      confirmation = 'confirmPermanentlyDelEmployee';
    }

    const data = modal ? modal.data : {};
    return (
      <ModalWindow modalID={modalID}>
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t(title)}</h5>
        </div>
        { isLoading ? (<div className="employee-delete-prealoder-container"><Preloader /></div>) : (
          <div>
            <div className="modal-body">
              <div className="text-confirmation">{i18n.t(confirmation)}</div>
            </div>
            <div className="modal-footer ">
              <CancelButton onClickHandler={handleOnClose} />
              {mode === 1 && (
                <button
                  type="button"
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                  onClick={onSubmit.bind(null, { id: data.id })}
                >
                  <i className="fa fa-trash" />
                  {` ${i18n.t('delete')}`}
                </button>
              )}
              {mode === 0 && (
                <>
                  <button
                    type="button"
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                    onClick={() => this.setState({ mode: 1 })}
                  >
                    <i className="fa fa-trash" />
                    {` ${i18n.t('delete')}`}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

DeleteEmployeeWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DeleteEmployeeWindow.defaultProps = {
  isLoading: false,
  modal: {
    data: {}
  }
};

const mapStateToProps = createStructuredSelector({
  modal: modalDataSelector,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps)(DeleteEmployeeWindow);
