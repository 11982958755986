import React, { memo, useState } from 'react';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import Closed from '../Closed';
import Opened from '../Opened';

import './index.scss';

const CollapseSwitchOver = memo((props) => {
  const {
    value,
    handleChangeSwitch,
    touched,
    globalError
  } = props;
  const [isOpenCollapse, setOpenCollapse] = useState((value.switchAction !== undefined) ? false : true);

  /**
   * Handle change opened collapse status
   */
  const handleToggleCollapse = () => setOpenCollapse((oldStatus) => !oldStatus);

  return (
    <div className="collapse-container">
      <Collapse
        in={isOpenCollapse}
        timeout={200}
        easing={{ enter: 'cubic-bezier(0.4, 0.0, 0.2, 1);', exit: 'linear' }}
      >
        <Opened
          handleToggleCollapse={handleToggleCollapse}
          globalError={globalError}
          touched={touched}
          value={value}
          handleChangeSwitch={handleChangeSwitch}
        />
      </Collapse>
      <Collapse
        in={!isOpenCollapse}
        timeout={200}
        easing={{ enter: 'cubic-bezier(0.4, 0.0, 0.2, 1);', exit: 'linear' }}
      >
        <Closed handleToggleCollapse={handleToggleCollapse} value={value} />
      </Collapse>
    </div>
  );
});

CollapseSwitchOver.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  globalError: PropTypes.instanceOf(Object).isRequired,
  handleChangeSwitch: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired
};

export default CollapseSwitchOver;
