import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { connect } from 'react-redux';

import DataList from '../../../DataList';

import columns from '../../columns';

import { openModalWindow } from '../../../ModalWindow/actions';
import { usersSensorsRequest } from '../../actions';

import { SENSORS_LIST_ID } from '../../../UserDetails/constants';

import i18n from '../../../../i18n';

/**
 * @module DevicesTable
 */
/**
 * Load Guard tab from DevicesTable
 * @memberof module:DevicesTable
 */
const LoadGuardTab = (props) => {
  const {
    userId,
    myRoleType,
    defaultParams,
    loadGuardUrl,
    total,
    getNewTags,
    transformResponse,
    openModalWindow: openModalWindowFromProps,
    usersSensorsRequest: usersSensorsRequestFromProps
  } = props;

  const openAddLoadGuard = () => {
    getNewTags();
    usersSensorsRequestFromProps(userId);
    openModalWindowFromProps('addDevice', null);
  };

  return (
    <>
      {['viewer', 'end_user', 'pv_installer_employee_read_only'].includes(myRoleType) ? null : (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddLoadGuard}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addLoadGuard')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataList
          listID={SENSORS_LIST_ID}
          listURL={loadGuardUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columns(myRoleType, userId, 'loadguards', total),
            showPagination: false,
            sorted: []
          }}
        />
      </div>
    </>
  );
};

LoadGuardTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  openModalWindow: PropTypes.func.isRequired,
  usersSensorsRequest: PropTypes.func.isRequired,
  loadGuardUrl: PropTypes.string.isRequired,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  getNewTags: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired
};

LoadGuardTab.defaultProps = {
  userId: undefined,
  myRoleType: undefined,
  total: undefined
};

export default connect(null, {
  openModalWindow,
  usersSensorsRequest
})(LoadGuardTab);
