import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';
import { createStructuredSelector } from 'reselect';
import DevicesTable from '../../../DevicesTable';
import TopBar from '../../../../components/TopBar';
import { getUserInfo, userDataSendRequest } from '../../actions';
import i18n from '../../../../i18n';
import { LargeButton } from '../../../../components/ReduxFormFields/SimpleButton';
import {
  houseFuseSelector,
  loadManagementSelector,
  supportEmailSubjectSelector,
  selectedUserSelector,
  visibleWebSelector
} from '../../selectors';
import './index.scss';
import { SUPPORT_EMAIL } from '../../../../config';
import { DATA_EXPORT_MODAL } from '../../../../components/DataExportWindow/constant';
import { closeModalWindow, openModalWindow, modalWindowSend } from '../../../ModalWindow/actions';
import DataExportWindow from '../../../../components/DataExportWindow';
import UserDetailsTabs from '../../UserDetailsTabs';
import analytics from '../../../../analytics';
import getEventObj, { DATA_EXPORT_GATEWAY_DATA } from '../../../../analytics/events';

const allowToShowNewSupportBtn = [
  'root',
  'solar_admin',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'oem'
];

/**
 * This layout is rendered when page is being viewed by one of ('solar_admin', 'pv_installer', 'pv_installer_employee', 'viewer', 'support')
 * @memberof module:UserDetails
 */
const ViewByAdmins = (props) => {
  const {
    myRoleType,
    user,
    userID,
    visibleWeb,
    emailSubject,
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    closeModalWindow: closeModal,
    houseFuse,
    loadManagement
  } = props;

  const sendEndUserDataExportDateRange = (fields) => {
    const { fromDate, toDate } = fields;
    const from = new Date(fromDate).toISOString();
    const to = new Date(toDate).toISOString();

    analytics.sendEvent(getEventObj(DATA_EXPORT_GATEWAY_DATA));

    return modalSend(DATA_EXPORT_MODAL, {
      method: 'post',
      url: '/../data/export-gateway-data',
      data: {
        gatewayIds: [user?.gateway?._id],
        from,
        to
      }
    });
  };

  return (
    <Fragment>
      <TopBar showNavigation visibleWeb={visibleWeb} />
      <div className="container-fluid">
        <div className="subheader-smu-table">
          <span className="emp-span">
            <i className="fa fa-home icons-employees" />
            -&nbsp;
            <Link to="/users" className="br-crump">
              {i18n.t('SMUs')}
            </Link>
            {' '}
            -&nbsp;
            {i18n.t('settings')}
          </span>
        </div>
        <UserDetailsTabs user={user} myRoleType={myRoleType} />
        <div className="d-flex justify-content-end order-3 btn-sm-us export-button">
          {allowToShowNewSupportBtn.includes(myRoleType) && (
            <Tooltip
              title={<div className="support-btn-container">{i18n.t('newSupportTooltip')}</div>}
              classes={{ tooltip: 'support-btn-tooltip', popper: 'support-btn-popper' }}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
            >
              <a href={`mailto:${SUPPORT_EMAIL}?subject=${emailSubject}`} className="support-btn">
                <i className="las la-headset headset-icon" />
                {i18n.t('newSupportBtnTitle')}
              </a>
            </Tooltip>
          )}
          <LargeButton
            onClick={openModal.bind(null, DATA_EXPORT_MODAL, {
              initialValues: {
                fromDate: moment()
                  .subtract(1, 'years')
                  .startOf('day')
                  .valueOf(),
                toDate: moment().valueOf()
              }
            })}
            title={i18n.t('exportData')}
            isDownloadIcon
          />
        </div>
      </div>
      <DevicesTable userId={userID} myRoleType={myRoleType} />
      {user.gateway && (
        <div className="container-fluid">
          <Link
            to={`/dashboard/${userID}/`}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right dashboard-btn"
          >
            {i18n.t('dashbo')}
          </Link>
          <Link
            to={`/load-management-dashboard/${userID}/`}
            className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right dashboard-btn mr-4 ${(!houseFuse || !loadManagement) && 'dashboard-btn-disabled'}`}
          >
            {i18n.t('loadManagementBtn')}
          </Link>
        </div>
      )}
      <DataExportWindow
        modalID={DATA_EXPORT_MODAL}
        onSubmit={sendEndUserDataExportDateRange}
        handleOnClose={closeModal.bind(null, DATA_EXPORT_MODAL)}
      />
    </Fragment>
  );
};

ViewByAdmins.propTypes = {
  myRoleType: PropTypes.string,
  user: PropTypes.instanceOf(Object).isRequired,
  userID: PropTypes.string.isRequired,
  visibleWeb: PropTypes.bool,
  emailSubject: PropTypes.string.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  houseFuse: PropTypes.number.isRequired,
  loadManagement: PropTypes.bool.isRequired
};

ViewByAdmins.defaultProps = {
  myRoleType: undefined,
  visibleWeb: undefined
};

const mapStateToProps = createStructuredSelector({
  user: selectedUserSelector,
  visibleWeb: visibleWebSelector,
  emailSubject: supportEmailSubjectSelector,
  houseFuse: houseFuseSelector,
  loadManagement: loadManagementSelector
});

export default connect(mapStateToProps, {
  getUserInfo,
  userDataSendRequest,
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(ViewByAdmins);
