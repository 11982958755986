import React from 'react';
import PropTypes from 'prop-types';
import FormCompany from './FormCompany'; //eslint-disable-line
import FormEmployee from './FormEmployee';
import FormUser from './FormUser';
import FormSupport from './FormSupport';
import FormAdmin from './FormAdmin';
import getConnectedOem from '../../utils/getConnectedOem';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';
/**
 * Destructuring user date depending for role type
 * @function getInitialValues
 * @param {Object} initialData Fields of user
 * @memberof module:SettingProfile
 */
export const getInitialValues = (initialData) => {
  const userRoleType = initialData?.role?.type;
  if (userRoleType === 'end_user') {
    return (
      {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        old_email: initialData?.email,
        old_sm_id: initialData?.gateway ? initialData?.gateway?.sm_id : '',
        password: initialData?.password,
        street: initialData?.street,
        zip: initialData?.zip,
        company: initialData?.company?._id || '',
        connectedOem: getConnectedOem(initialData),
        city: initialData?.city,
        language: initialData?.language,
        avatarURL: initialData?.avatar ? initialData?.avatar?.url : false,
        status: initialData?.status ? initialData?.status : '',
        country: initialData?.country || '',
        company_name: initialData?.company_name || '',
        phone: initialData?.phone || '',
        plant: initialData?.plant || '',
        note: initialData?.note || ''
      }
    );
  }
  if (userRoleType === 'pv_installer') {
    return (
      {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        old_email: initialData?.email,
        password: initialData?.password,
        street: initialData?.street,
        zip: initialData?.zip,
        phone: initialData?.phone,
        city: initialData?.city,
        language: initialData?.language,
        webpage: initialData?.webpage ? initialData?.webpage : '',
        avatarURL: initialData?.avatar ? initialData?.avatar?.url : false,
        companyLogoUrl: initialData?.company_logo ? initialData?.company_logo?.url : false,
        company: initialData?.my_company ? initialData?.my_company?.name : '',
        country: initialData?.country || '',
        link_for_logo: initialData?.company_logo ? initialData?.my_company?.link_for_logo : '',
        status: initialData?.status ? initialData?.status : '',
        visible_web: initialData?.my_company ? initialData?.my_company?.visible_web : undefined,
        visible_installer_app: initialData?.my_company ? initialData?.my_company?.visible_installer_app : undefined,
        visible_end_user_app: initialData?.my_company ? initialData?.my_company?.visible_end_user_app : undefined,
        note: initialData?.note || ''
      }
    );
  }
  if (EMPLOYEE_ROLE_TYPES.includes(userRoleType)) {
    return (
      {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        old_email: initialData?.email,
        password: initialData?.password,
        street: initialData?.street,
        zip: initialData?.zip,
        phone: initialData?.phone,
        city: initialData?.city,
        language: initialData?.language,
        avatarURL: initialData?.avatar ? initialData?.avatar?.url : false,
        country: initialData?.country || '',
        status: initialData?.status ? initialData?.status : '',
        note: initialData?.note || '',
        role_type: initialData?.role?.type || 'pv_installer_employee'
      }
    );
  }

  if (userRoleType === 'solar_admin') {
    return (
      {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        old_email: initialData?.email,
        password: initialData?.password,
        street: initialData?.street,
        zip: initialData?.zip,
        phone: initialData?.phone,
        city: initialData?.city,
        link_for_logo: initialData?.my_company?.link_for_logo,
        language: initialData?.language,
        webpage: initialData?.webpage ? initialData?.webpage : '',
        avatarURL: initialData?.avatar ? initialData?.avatar?.url : false,
        country: initialData?.country || '',
        status: initialData?.status ? initialData?.status : '',
        note: initialData?.note || ''
      }
    );
  }
  if (userRoleType === 'oem') {
    const {
      first_name,
      last_name,
      email,
      password,
      street,
      zip,
      phone,
      city,
      language,
      webpage = '',
      avatar,
      company_logo = false,
      oem,
      note,
      country = '',
      status = ''
    } = initialData;
    return (
      {
        first_name,
        last_name,
        old_email: email,
        password,
        street,
        zip,
        phone,
        city,
        language,
        webpage,
        link_for_logo: oem?.link_for_logo || '',
        avatarURL: avatar?.url || false,
        companyLogoUrl: company_logo?.url,
        company: oem?.name || '',
        visible_web: oem?.visible_web || undefined,
        visible_installer_app: oem?.visible_installer_app || undefined,
        visible_end_user_app: oem?.visible_end_user_app || undefined,
        country,
        status,
        note: note || ''
      }
    );
  }
  return ( // admin , support, viewer
    {
      first_name: initialData?.first_name,
      last_name: initialData?.last_name,
      old_email: initialData?.email,
      password: initialData?.password,
      street: initialData?.street,
      zip: initialData?.zip,
      city: initialData?.city,
      phone: initialData?.phone,
      link_for_logo: initialData?.my_company?.link_for_logo,
      language: initialData?.language,
      avatarURL: initialData?.avatar ? initialData?.avatar?.url : false,
      country: initialData?.country || '',
      status: initialData?.status ? initialData?.status : '',
      note: initialData?.note || ''
    }
  );
};
/**
 * Renders edit profile layout depending on role type
 * @function GetLayout
 * @memberof module:SettingProfile
 * @param {Object} options Contains userRoleType and any another props that will be passed to the layout component
 * @param {string} options.userRoleType Role type of user
 */
export const GetLayout = ({ userRoleType, ...restProps }) => {
  const installersRoles = ['pv_installer', 'oem'];
  if (userRoleType === 'end_user') {
    return (
      <FormUser {...restProps} userRoleType={userRoleType} />
    );
  }
  if (installersRoles.includes(userRoleType)) {
    return (
      <FormCompany {...restProps} userRoleType={userRoleType} />
    );
  }
  if (EMPLOYEE_ROLE_TYPES.includes(userRoleType)) {
    return (
      <FormEmployee {...restProps} userRoleType={userRoleType} />
    );
  }
  if (userRoleType === 'solar_admin') {
    return (
      <FormAdmin {...restProps} userRoleType={userRoleType} />
    );
  }
  return (
    <FormSupport {...restProps} userRoleType={userRoleType} />
  );
};

GetLayout.propTypes = {
  userRoleType: PropTypes.string
};

GetLayout.defaultProps = {
  userRoleType: ''
};
