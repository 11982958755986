/**
 * Parses query string from location
 * @param queryString {String}
 * @returns {Object}
 */
const queryURLParser = (queryString) => {
  let qs = queryString;
  if (qs?.[0] === '?') qs = qs.substr(1);
  const queries = qs?.split('&');
  const parsed = {};
  for (let i = 0; i < queries?.length; i += 1) {
    const [key, value] = queries?.[i].split('=');
    parsed[key] = value;
  }
  return parsed;
};

export default queryURLParser;
