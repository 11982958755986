const chartResolutions = [
  {
    name: 'original',
    value: 0
  },
  {
    name: 'high',
    value: 1
  },
  {
    name: 'medium',
    value: 2
  },
  {
    name: 'low',
    value: 4
  }
];

/**
 *
 * @description Get chart resolution by group pixel width
 * @param {number} index group pixel width: 0, 1, 2, 4
 * @returns {string} chart resolution: original, high, medium, low
 */
const getChartResolutionByIndex = (index = 4) => chartResolutions.find(({ value }) => value === index)?.name;

export default getChartResolutionByIndex;
