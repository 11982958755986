import { createSelector } from 'reselect';
import i18n from '../../i18n';

const getSensors = (state) => state.dataList?.sensors || {};
const getEditModalData = (state) => state.modals?.editRow?.data || {};
const getVisibleWeb = (state) => state.settingProfile?.initialData?.company?.visible_web;
const getLoad = (state) => state.userDevices?.load;
const getUser = (state) => state.signIn?.user;
const getIsDisabledOauth2EditModal = (state) => state.sensorsOauth2?.isDisabledOauth2EditModal;
const getAllModals = (state) => state?.modals;

export const sensorsSelector = createSelector(
  [getSensors],
  (sensors) => sensors
);

export const editModalDataSelector = createSelector(
  [getEditModalData],
  (editModalData) => ({ ...editModalData, data: { ...editModalData.data, ip: editModalData.ip } })
);

export const visibleWebSelector = createSelector(
  [getVisibleWeb],
  (visibleWeb) => visibleWeb
);

export const titleForIpModalWindowSelector = createSelector(
  [getLoad],
  (load) => (load ? i18n.t('Scanning IP') : i18n.t('Choose IP'))
);

export const checkMyRoleTypeSelector = createSelector(
  [getUser],
  (user) => (user?.role?.type === 'end_user' && 'my')
);

export const isDisabledOauth2EditModalSelector = createSelector(
  [getIsDisabledOauth2EditModal],
  (data) => data
);

export const selectAllModals = createSelector(
  [getAllModals],
  (modals) => modals
);

export const getUserId = createSelector(
  [getUser],
  (user) => user?._id
);
