import React, { useState } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './index.css';
import { countries, countriesSymbols } from './constants';
import i18n from '../../../i18n';
import { parseStringOfNumbers } from '../../../containers/validation';
/**
   * Set phone number into input field and set country into redux store
   * @param {string} phoneNumber string with phone number from input
   */
const CommonPhoneInput = ({
  input,
  meta: { touched, error },
  phone,
  label,
  disabled,
  change: changeField
}) => {
  const [phoneNumber, setPhoneNumber] = useState(phone ? `+${parseStringOfNumbers(phone)}` : '');

  const setPhone = (value) => {
    setPhoneNumber(value);

    const parsedNumber = value && parsePhoneNumber(value);

    if (parsedNumber) {
      input.onChange(parsedNumber.nationalNumber);
      const { country: newCountry } = parsedNumber;
      const selectedCountry = newCountry && countries[newCountry];
      changeField('settingProfileForm', 'country', selectedCountry);
    } else {
      input.onChange('');
      changeField('settingProfileForm', 'country', undefined);
    }
  };

  return (
    <>
      <p className="phone-label">
        {label}
      </p>
      <PhoneInput
        {...input}
        international
        countryOptionsOrder={countriesSymbols}
        value={phoneNumber}
        disabled={disabled}
        onChange={setPhone}
        countries={countriesSymbols}
      />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

CommonPhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  change: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  phone: PropTypes.string
};

CommonPhoneInput.defaultProps = {
  phone: '',
  disabled: false
};

export default connect(null, { change })(CommonPhoneInput);
