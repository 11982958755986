/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import i18n from '../../i18n';
import { ReactComponent as FileIco } from '../../assets/images/svg/file.svg';
import { RenderButtons } from '../../components/ReactTable/renderFunctions';
import { transformBytesToNiceSize } from './helpers';
import { allowedRolesForUpdateAndDeleteFiles } from './constants';

export const getColumns = (myRoleType) => [
  {
    Header: i18n.t('name'),
    accessor: 'fileName',
    minWidth: 180,
    sortable: false,
    Cell: ({ value }) => (
      <div className="documentation-table-cell">
        <FileIco />
        <p>{value}</p>
      </div>
    )
  },
  {
    Header: i18n.t('size'),
    accessor: 'fileSize',
    minWidth: 180,
    className: '',
    sortable: false,
    Cell: ({ value }) => transformBytesToNiceSize(value)
  },
  {
    Header: i18n.t('lastModified'),
    accessor: 'updatedAt',
    minWidth: 180,
    className: '',
    filterable: false,
    sortable: false,
    Cell: ({ value }) => moment(value).format('DD.MM.YYYY LT')
  },
  {
    accessor: '',
    width: 120,
    className: '',
    filterable: false,
    sortable: false,
    Cell: ({ original }) => (
      <div className="table-cell-btn-wrapper">
        {RenderButtons(
          original,
          { downloadFile: true, deleteFile: allowedRolesForUpdateAndDeleteFiles.includes(myRoleType) }
        )}
      </div>
    )
  }
];
