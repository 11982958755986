import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import i18n from '../../../i18n';
import Tooltip from '../../../components/Tooltip';
/**
 * Renders selector for switch number on device
 * @memberof module:UserDevices
 */
const Select = ({
  input,
  label,
  meta: { touched, error },
  disabled,
  required,
  options,
  showTooltip,
  placeholder,
  ...custom
}) => {
  const optionsArray = typeof options === 'function'
    ? options()
    : options;
  const onChange = (selected) => input.onChange(selected ? selected.value : null);
  const onBlur = () => {
    input.onBlur(input.value);
  };

  return (
    <Fragment>
      {!!showTooltip && (
        <div className="switch-tooltip select">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <div className="flags-select-label">{required ? `${i18n.t(label)} *` : i18n.t(label)}</div>
      <ReactSelect
        clearable={false}
        {...input}
        {...custom}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={disabled ? 'disabeldSelect' : ''}
        placeholder={placeholder || `${i18n.t(label)}`}
        options={optionsArray}
      />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </Fragment>
  );
};

Select.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  showTooltip: PropTypes.string,
  options: PropTypes.PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.func
  ]).isRequired,
  placeholder: PropTypes.string
};

Select.defaultProps = {
  placeholder: '',
  disabled: false,
  showTooltip: ''
};

export default Select;
