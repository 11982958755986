import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { push, goBack } from 'react-router-redux';
import { map, switchMap, catchError, mergeMap, finalize, ignoreElements, concatMap } from 'rxjs/operators';
import moment from 'moment-timezone';

import { handleError, handleErrorDetailed } from '../../api_helper';
import * as SignInTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import {
  receiveSignIn,
  cancelSignIn,
  signInInvalidJWT,
  signInCheckJWT,
  signInUpdateUser
} from './actions';

import i18n, { changeLanguageTo } from '../../i18n';
import { APP_ID } from '../../config';
import analytics from '../../analytics';
import getEventObj, { AUTH_LOCAL, USER_ME } from '../../analytics/events';

function signInEpic(action$, state$) {
  return action$.pipe(
    ofType(SignInTypes.SIGN_IN),
    map((action) => action.payload.data),
    switchMap(({
      remember, email, password, language
    }) => {
      strapi.clearToken();
      strapi.storeConfig.cookie.options.expires = remember
        ? moment().add(30, 'days').toDate()
        : null;

      return from(strapi.request('post', '/../auth/local', {
        data: {
          identifier: email,
          password,
          app: APP_ID,
          isWeb: true
        },
        params: {
          language
        }
      })).pipe(
        catchError(handleError),
        finalize(() => {
          analytics.sendEvent(getEventObj(AUTH_LOCAL));
        }),
        map((result) => {
          if (result?.jwt) {
            strapi.setToken(result.jwt);
            const language_ = result.user.language.toLowerCase();
            const {
              signIn: { needGoBackAfterLogin }
            } = state$.value;
            changeLanguageTo(language_, true);
            return receiveSignIn(result.user, result.jwt, remember, needGoBackAfterLogin);
          }
          return cancelSignIn(result);
        })
      );
    })
  );
}

function receiveSignIpEpic(action$) {
  return (
    action$.pipe(
      ofType(SignInTypes.SIGN_IN_RECEIVED),
      map((action) => action.payload),
      mergeMap(({ user, needGoBack }) => of(
        signInCheckJWT(),
        needGoBack
          ? goBack()
          : push(user.role.type === 'end_user' ? '/' : '/users/'),
        addNotification({
          type: 'success',
          text: i18n.t('youAreIn')
        })
      )
      )
    )
  );
}

function cancelSignInEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.SIGN_IN_CANCELED),
    map((action) => action.payload.message),
    switchMap((message) => of(
      addNotification({
        type: 'error',
        text: message
      })
    ))
  );
}

function logoutEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.USER_LOGOUT),
    switchMap(() => {
      strapi.clearToken();
      return of(
        push('/login'),
        addNotification({
          type: 'success',
          text: i18n.t('youAreOut')
        })
      );
    })
  );
}

function signInCheckJWTEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.SIGN_IN_CHECK_JWT),
    // eslint-disable-next-line arrow-body-style
    concatMap(() => {
      return (
        from(strapi.request('get', '/../user/me', {
          params: {
            app: APP_ID,
            isWeb: true
          }
        })).pipe(
          catchError(handleErrorDetailed),
          finalize(() => {
            analytics.sendEvent(getEventObj(USER_ME));
          }),
          mergeMap((result) => {
            if (result?.error) {
              strapi.clearToken();

              return of(
                signInInvalidJWT(),
                addNotification({
                  type: 'error',
                  text: result.message
                })
              );
            }

            return of(signInUpdateUser(result));
          })
        )
      );
    })
  );
}

function signInUpdateUserEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.SIGN_IN_UPDATE_USER),
    map((action) => action.payload.user),
    finalize((user) => {
      const language = user.language.toLowerCase();
      changeLanguageTo(language, true);
    }),
    ignoreElements()
  );
}

export default combineEpics(
  signInEpic,
  receiveSignIpEpic,
  cancelSignInEpic,
  logoutEpic,
  signInCheckJWTEpic,
  signInUpdateUserEpic
);
