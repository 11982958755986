import * as actionTypes from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.CHANGE_EMAIL_VERIFY:
      return {
        loading: true
      };
    default:
      return state;
  }
};
