import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, groupBy } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { employeeReceived, clearPropsFromFields, addChoosedCompany, monitoringReplaceSelected, monitoringInstallersReceived, errorInfoReceived } from './actions';
import { dataListReloadData } from '../DataList/actions';

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function monitoringGetInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.MONITORING_GET_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(strapi.request('get', url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return monitoringInstallersReceived(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

function employeeSendRequestEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.EMPLOYEE_SEND_REQUEST),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap(
      (group) => group.pipe(
        switchMap(
          ({ companyID, listID, field, dataList: { listURL, params, transformResponse } }) => (
            from(strapi.request('get', listURL, { params })).pipe(
              catchError(handleErrorDetailed),
              mergeMap(
                (result) => {
                  if (!result.error) {
                    const parsedResult = transformResponse ? transformResponse(result) : { data: result };
                    return of(
                      employeeReceived(companyID, listID, listURL, parsedResult, field)
                    );
                  }

                  return of(
                    addNotification({
                      type: 'error',
                      text: result.message
                    })
                  );
                }
              )
            )
          )
        )
      )
    )
  );
}

function changeFieldsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.CHANGE_FIELDS),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap(
      (group) => group.pipe(
        switchMap(
          ({ url, listID, config }) => (
            from(strapi.request('put', url, { data: config })).pipe(
              catchError(handleErrorDetailed),
              mergeMap(
                (result) => {
                  if (result && !result.error) {
                    return of(
                      dataListReloadData(listID),
                      monitoringReplaceSelected([]),
                      clearPropsFromFields(),
                      addChoosedCompany([])
                    );
                  }
                  return of(
                    addNotification({
                      type: 'error',
                      text: result.message
                    })
                  );
                }
              )
            )
          )
        )
      )
    )
  );
}

function getErrorInfoEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ERROR_INFO),
    map((action) => action.payload),
    switchMap(({ url, taskId }) => from(strapi.request('get', url)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result)) {
            return errorInfoReceived(result, taskId);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

export default combineEpics(
  employeeSendRequestEpic,
  monitoringGetInstallersEpic,
  changeFieldsEpic,
  getErrorInfoEpic
);
