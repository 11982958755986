import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  required,
  notEqualsTime,
  time,
  offset,
  normalizeTime,
  validateKWP,
  validateHouseFuse
} from '../../validation';
import CustomTextInput from '../../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../../ModalWindow';
import i18n from '../../../i18n';
import { generalSettingsModalSelector } from '../selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

const validateTimePeriodMF = notEqualsTime('low_m_f_from');
const validateTimePeriodSat = notEqualsTime('low_sat_from');
const validateTimePeriodSun = notEqualsTime('low_sun_from');

/**
 * Modal window for editing general settings of user
 * @memberof module:UserDetails
 */
const EditGeneralSettingsWindow = (props) => {
  const { modal, reset, handleOnClose, handleSubmit, modalID, pristine } = props;

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('generalSettings')}</h5>
      </div>
      <div className="modal-body">
        <form
          id="editGeneralSettAdm"
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field">
            <Field
              name="offset_watt"
              component={CustomTextInput}
              label={i18n.t('offsetWatt')}
              className="m-input"
              validate={[required, offset]}
            />
          </div>
          <div className="form-group m-form__group input-field">
            <Field
              name="kWp"
              component={CustomTextInput}
              label={i18n.t('kWPeakOutputLabel')}
              className="m-input"
              validate={[validateKWP]}
              showTooltip={i18n.t('kWPeakOutputTooltip')}
            />
          </div>
          <div className="form-group m-form__group input-field">
            <Field
              name="houseFuse"
              component={CustomTextInput}
              label={i18n.t('houseFuseLabel')}
              className="m-input"
              validate={[validateHouseFuse]}
              showTooltip={i18n.t('houseFuseTooltip')}
            />
          </div>
          <div className="tarif-header" style={{ textAlign: 'center', marginBottom: '10px' }}>
            {i18n.t('lowRate')}
          </div>
          <div className="row">
            <div className="col-12 general-sett-modal-label">{i18n.t('mondayFriday')}</div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_m_f_from"
                component={CustomTextInput}
                label={i18n.t('from')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time]}
              />
            </div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_m_f_to"
                component={CustomTextInput}
                label={i18n.t('to')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time, validateTimePeriodMF]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 general-sett-modal-label">{i18n.t('saturday')}</div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_sat_from"
                component={CustomTextInput}
                label={i18n.t('from')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time]}
              />
            </div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_sat_to"
                component={CustomTextInput}
                label={i18n.t('to')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time, validateTimePeriodSat]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 general-sett-modal-label">{i18n.t('sunday')}</div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_sun_from"
                component={CustomTextInput}
                label={i18n.t('from')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time]}
              />
            </div>
            <div className="form-group m-form__group input-field col-6">
              <Field
                name="low_sun_to"
                component={CustomTextInput}
                label={i18n.t('to')}
                className="m-input col-6"
                autoComplete="off"
                normalize={normalizeTime}
                validate={[required, time, validateTimePeriodSun]}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <button
          form="editGeneralSettAdm"
          disabled={pristine}
          type="submit"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
        >
          <i className="fa fa-save" />
          {i18n.t('save')}
        </button>
      </div>
    </ModalWindow>
  );
};

EditGeneralSettingsWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  pristine: PropTypes.bool.isRequired
};

EditGeneralSettingsWindow.defaultProps = {
  modal: {
    data: {}
  }
};

const form = reduxForm({
  form: 'EditGeneralSettings'
})(EditGeneralSettingsWindow);

const mapStateToProps = createStructuredSelector({
  modal: generalSettingsModalSelector
});

export default connect(mapStateToProps)(form);
