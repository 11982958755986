import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import columns from './columns';
import TopBar from '../../components/TopBar';
import SearchField from '../../components/SearchField';
import AddEmployeeWindow from './AddEmployeeWindow';
import DeleteEmployeeWindow from './DeleteEmployeeWindow'; // eslint-disable-line import/no-named-as-default-member
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { visibleWebSelector } from './selectors';

/**
 * @module Employee
 */
const dataListID = 'employee';
const listURL = '/../users/employee';
const transformResponse = (data) => ({
  total: data.total,
  data: data.list
});

/**
 * Renders employee page
 * @memberof module:Employee
 */
class Employees extends Component {
  constructor(props) {
    super(props);

    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);

    configSavedSettings(
      this,
      'employees',
      {
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'first_name:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  render() {
    const {
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: sendModal,
      visibleWeb
    } = this.props;

    const sendAddUser = (fields) => sendModal(
      'addData',
      {
        method: 'post',
        url: '/../users/employee',
        data: fields
      },
      () => dataListReloadData(dataListID)
    );

    const sendRemoveUser = ({ id }) => sendModal(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(dataListID)
    );
    return (
      <div className="main-table-container">
        <AddEmployeeWindow
          modalID="addData"
          onSubmit={sendAddUser}
          handleOnClose={closeModal.bind(null, 'addData')}
          initialValues={{ role_type: 'pv_installer_employee' }}
        />
        <DeleteEmployeeWindow
          modalID="removeData"
          onSubmit={sendRemoveUser}
          handleOnClose={closeModal.bind(null, 'removeData')}
        />
        <TopBar updateButton showNavigation visibleWeb={visibleWeb} />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {` - ${i18n.t('employees')}`}
            </span>
          </div>
          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="col-xl-12">
              <div className="row align-items-center">
                <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10 order-1 mr-auto">
                  <DataListFilter
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'email,first_name,last_name,city',
                      placeholder: i18n.t('searchAllTablesElements')
                    }}
                    listID={dataListID}
                  />
                </div>
                <div className="col-auto order-2 btn-sm-us">
                  <a
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                    onClick={openModal.bind(null, 'addData', null)}
                  >
                    <span>
                      <span>{i18n.t('addEmployeebtn')}</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataList
              listID={dataListID}
              listURL={listURL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                manual: true
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={dataListID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Employees.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  visibleWeb: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired
};

Employees.defaultProps = {
  visibleWeb: undefined
};

const mapStateToProps = createStructuredSelector({
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(Employees);
