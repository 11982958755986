import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import store from '../../../store';
import { openModalWindow } from '../../../containers/ModalWindow/actions';
import { MODAL_ID } from '../../ChooseLicenseWindow/constants';

import '../index.scss';

/**
 * @const {array<string>}
 * @name helionLicenseId
 * @description helionLicenseId array of Helion licenses id,
 * if the licenseId is in the array, you need to hide the edit button next to those licenses
 */
const helionLicenseId = ['10', '11', '12'];

const openModal = (modalID, data) => store.dispatch(openModalWindow(modalID, data));

const renderLicense = (props) => {
  const {
    value: {
      userId, licenseId, licenseTitle, hide, parentListId
    },
    original: {
      _id,
      initialLicense
    }
  } = props;

  const { name, licenseCode } = initialLicense || {};

  if (hide) return null;

  return (
    <>
      {!helionLicenseId.includes(licenseId) && (
        <a
          title={i18n.t('editTooltip')}
          onClick={() => openModal(MODAL_ID, {
            userId,
            license: licenseId,
            parentListId,
            gatewayId: _id,
            licenseCode
          })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
          style={{
            margin: '-2px 2px 2px 0'
          }}
        >
          <i className="la la-pencil" />
        </a>
      )}
      <span className={helionLicenseId.includes(licenseId) ? 'paddingLeft' : ''}>{licenseTitle || name}</span>
    </>
  );
};

renderLicense.propTypes = {
  value: PropTypes.instanceOf(Object)
};

renderLicense.defaultProps = {
  value: {}
};

export default renderLicense;
