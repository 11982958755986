import * as actionTypes from './constants';
import { SIGN_OUT_AND_GO_TO, USER_LOGOUT } from '../SignIn/constants';

const initialState = {
  goToAfterLogin: '',
  url: '',
  loading: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_URL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.FETCH_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        url: action.payload.url,
        loading: false
      };
    case actionTypes.FETCH_PAYMENT_URL_FAILURE:
      return {
        ...state,
        loading: false
      };
    case actionTypes.GO_TO_AFTER_LOGIN:
      return {
        ...state,
        goToAfterLogin: action.payload.pathName
      };
    case SIGN_OUT_AND_GO_TO:
      return {
        ...initialState,
        goToAfterLogin: state.goToAfterLogin
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
