import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import i18n from '../../../i18n';
import Tooltip from '../../Tooltip';
/**
 * Render function for React-Table. Column for device errors.
 * @memberof module:ReactTable
 * @param  {object} original - props of the company details
 */
const RenderDevicesError = ({ original }) => (
  Array.isArray(original.errorCodes)
    ? original.errorCodes.map(({ errorCode }) => (
      <span
        key={shortid.generate()}
        className="error_status_yes"
      >
        {`${errorCode} `}
        {i18n.exists(`error_${errorCode}`) && <Tooltip title={i18n.t(`error_${errorCode}`)} customClasses={{ popper: 'tooltipInsideRow' }} />}
      </span>
    ))
    : (
      <Fragment>
        <span className="error_status_no">{i18n.t('no')}</span>
      </Fragment>
    )
);

RenderDevicesError.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderDevicesError;
