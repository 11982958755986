import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { email, required, minLength, maxLength, parseToLowerCase } from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import CustomSelect from '../../components/ReduxFormFields/CustomSelect';
import ModalWindow from '../ModalWindow';
import DataList from '../DataList';
import Preloader from '../../components/Preloader';
import i18n from '../../i18n';
import { getOEMs, getOEMsByID, clearOEMs } from './actions';
import RadioButtons from '../../components/ReduxFormFields/RadioButtons';
import Tooltip from '../../components/Tooltip';
import { dataListPviId, getPvInstallersConfig } from './constants';
import { isLoadingSelector, modalSelector, companiesSelector, oemsSelector } from './selectors';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';
import CancelButton from '../../components/UIKit/CancelButton';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

const getAdminId = (companies, pviId) => {
  if (!pviId) {
    return undefined;
  }
  const chosenCompany = companies.find((item) => item.id === pviId) || {};
  return chosenCompany?.adminId;
};

const transformResponse = (response) => {
  const data = response.list
    .filter((user) => user?.my_company?._id && user?.my_company?.name && user?.my_company?.admin)
    .map(({ my_company: { _id, name, admin: adminId } }) => ({ id: _id, _id, name, adminId }));
  return { data };
};

/**
 * Renders add End user modal window for Users page
 * @memberof module:UsersTable
 */
const AddUserWindow = (props) => {
  const {
    handleOnClose,
    handleSubmit,
    myRoleType,
    modalID,
    pristine,
    valid,
    isLoading,
    companies,
    oems,
    getOEMsByID: getOEMsByIDProps,
    initialize,
    formValues,
    modal,
    clearOEMs: clearOEMsFromProps
  } = props;

  const hasSupportRights = ['root', 'solar_admin', 'support'].includes(myRoleType);
  const hasInstallerRights = ['pv_installer_employee', 'pv_installer'].includes(myRoleType);

  const { connectedOem: chosenOEM, pv_installer: chosenPVInstaller } = formValues;

  const companiesList = companies.map((item) => ({
    value: item._id,
    label: item.name
  }));
  if (companiesList.length) {
    companiesList.unshift({ value: 'null', label: i18n.t('NoPVinstaller') });
  }

  const oemsList = oems?.map((item) => ({
    value: item.oem._id,
    label: `${item.oem.name}`
  }));
  if (oemsList) {
    oemsList.push({ value: 'null', label: i18n.t('NoOEM') });
  }

  useEffect(() => {
    const { getOEMs: getOEMsProps } = props;
    if (hasInstallerRights) {
      getOEMsProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modal?.opened && (chosenPVInstaller || hasInstallerRights)) {
      initialize({ ...formValues, connectedOem: oemsList?.[0].value });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oems, chosenPVInstaller, modal?.opened, hasInstallerRights]);

  useEffect(() => {
    if (hasSupportRights) {
      initialize({ ...formValues });
      const adminId = getAdminId(companies, chosenPVInstaller);
      const request = {
        support: {
          url: '/../users/get-supported-oem-users',
          params: { pvInstallerId: chosenPVInstaller },
          ready: chosenPVInstaller && chosenPVInstaller !== 'null'
        },
        default: {
          url: `/../oem/connected-oems/${adminId}`,
          params: {},
          ready: !!adminId
        }
      };

      const { url, params, ready } = request?.[myRoleType] || request.default;

      if (ready) {
        getOEMsByIDProps(url, params);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSupportRights, chosenPVInstaller, myRoleType]);

  useEffect(() => {
    if (!modal?.opened) {
      initialize();
      clearOEMsFromProps();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOEMsFromProps, modal?.opened]);

  const { url, params } = getPvInstallersConfig[myRoleType] || getPvInstallersConfig.default;

  const displayOemDropdown = ((hasSupportRights && chosenPVInstaller && chosenPVInstaller !== 'null')
      || hasInstallerRights)
    && !!oemsList?.length;
  const displayRadioButtons = !!(chosenOEM && chosenOEM !== 'null');

  return (
    <ModalWindow modalID={modalID}>
      <DataList
        listID={dataListPviId}
        listURL={url}
        params={params}
        transformResponse={transformResponse}
      />
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>
      {isLoading ? (
        <div className="preloader-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id="addUserAdm"
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="sm_id"
                  component={CustomTextInput}
                  label={i18n.t('reqSMID')}
                  className="m-input"
                  validate={[required, minLen3, maxLen50]}
                />
              </div>
              {myRoleType !== 'pv_installer' && !EMPLOYEE_ROLE_TYPES.includes(myRoleType) && (
                <div className="form-group m-form__group input-field">
                  <div className="flags-select-label">{i18n.t('reqPVI')}</div>
                  <Field
                    name="pv_installer"
                    component={CustomSelect}
                    placeholder={i18n.t('selectPVI')}
                    options={companiesList}
                    validate={[required]}
                  />
                </div>
              )}
              <div className="form-group m-form__group input-field">
                <Field
                  name="email"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                />
              </div>
              {displayOemDropdown && (
                <>
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('oem')}
                      <Tooltip title={i18n.t('oemTooltip')} />
                    </div>
                    <Field
                      name="connectedOem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={oemsList}
                    />
                  </div>
                  <div
                    className={`form-group m-form__group input-field ${
                      displayRadioButtons ? '' : 'disabled'
                    } radio-oem`}
                  >
                    <Field
                      name="sendOemTemplate"
                      component={RadioButtons}
                      props={{
                        disabled: !displayRadioButtons,
                        defaultValue: displayRadioButtons,
                        variants: [
                          {
                            label: <span className="white dataSMID">{i18n.t('oemEmail')}</span>,
                            value: true
                          },
                          {
                            label: (
                              <span className="white dataSMID">{i18n.t('installerEmail')}</span>
                            ),
                            value: false
                          }
                        ]
                      }}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              form="addUserAdm"
              disabled={pristine && !valid}
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              <i className="fa fa-paper-plane" />
              {i18n.t('send')}
            </button>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddUserWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(Array),
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  getOEMs: PropTypes.func.isRequired,
  getOEMsByID: PropTypes.func.isRequired,
  clearOEMs: PropTypes.func.isRequired,
  pv_installer: PropTypes.string,
  connectedOem: PropTypes.string,
  oems: PropTypes.instanceOf(Array),
  formValues: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool
};

AddUserWindow.defaultProps = {
  modal: {
    data: {}
  },
  oems: [],
  companies: [],
  isLoading: false,
  pv_installer: '',
  connectedOem: ''
};

const form = reduxForm({
  form: 'addUserForm'
})(AddUserWindow);

const selector = formValueSelector('addUserForm');

const mapStateToProps = createStructuredSelector({
  formValues: (state) => selector(state, 'sendOemTemplate', 'sm_id', 'pv_installer', 'email', 'connectedOem'),
  isLoading: isLoadingSelector,
  modal: modalSelector,
  companies: companiesSelector,
  oems: oemsSelector
});

export default connect(mapStateToProps, { getOEMs, getOEMsByID, clearOEMs })(form);
