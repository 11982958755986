const getListURLUsers = ({ myRoleType, userInfoId }) => {
  switch (myRoleType) {
    case 'support':
      return '/../users/get-supported-end-users';
    case 'viewer':
      return `/../users/get-attached-to-viewer-end-users?viewerId=${userInfoId}`;
    case 'oem':
      return `/../oem/connected-end-users/${userInfoId}`;
    default:
      return '/../users/web/v1/end-users';
  }
};

export default getListURLUsers;
