import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../../../ModalWindow';
import i18n from '../../../../i18n';

import { DELETE_FILES_MODAL_ID } from '../../constants';
import { closeModalWindow } from '../../../ModalWindow/actions';
import { deleteFiles } from '../../store/actions';
import { deleteModalFilesIdsSelector } from '../../store/selectors';

import './index.scss';
import CancelButton from '../../../../components/UIKit/CancelButton';

const DeleteFilesModal = ({
  closeModalWindow: closeModalWindowAction,
  ids,
  deleteFiles: deleteFilesAction
}) => {
  const handleOnClose = () => {
    closeModalWindowAction(DELETE_FILES_MODAL_ID);
  };

  const handleDelete = () => {
    deleteFilesAction(ids);
  };


  return (
    <ModalWindow modalID={DELETE_FILES_MODAL_ID}>
      <div className="modal-header limit-size-header">
        <h5 className="modal-title download-title">
          {i18n.t('deleteFiles')}
        </h5>
      </div>
      <div className="modal-body">
        <p className="message">
          {i18n.t('deleteFilesConfirmation')}
        </p>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <button
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
          onClick={handleDelete}
        >
          <i className="fa fa-trash" />
          {' '}
          {i18n.t('delete')}
        </button>
      </div>
    </ModalWindow>
  );
};

DeleteFilesModal.propTypes = {
  closeModalWindow: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string),
  deleteFiles: PropTypes.func.isRequired
};

DeleteFilesModal.defaultProps = {
  ids: []
};

const mapStateToProps = createStructuredSelector({
  ids: deleteModalFilesIdsSelector
});
export default connect(mapStateToProps, {
  closeModalWindow,
  deleteFiles
})(DeleteFilesModal);
