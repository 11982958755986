import * as actionTypes from './constants';

const initialState = {
  notAttachedEndUsers: [],
  oems: [],
  pvInstallers: [],
  notAttachedPVInstallers: [],
  attachedPVInstallers: [],
  notAttachedOEMs: [],
  isNotAttachedUsersLoading: false,
  isOemsListForSupportLoading: false,
  isPvInstallersListForSupportLoading: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_NOT_ATTACHED_END_USERS: {
      return {
        ...state,
        notAttachedEndUsers: action.payload.endUsers
      };
    }
    case actionTypes.SET_OEMS_LIST: {
      return {
        ...state,
        oems: action.payload.oems
      };
    }
    case actionTypes.SET_PV_INSTALLERS_LIST: {
      return {
        ...state,
        pvInstallers: action.payload.pvInstallers
      };
    }
    case actionTypes.SET_ALL_NOT_ATTACHED_INSTALLERS: {
      return {
        ...state,
        notAttachedPVInstallers: action.payload.installers
      };
    }
    case actionTypes.SET_ALL_ATTACHED_INSTALLERS: {
      return {
        ...state,
        attachedPVInstallers: action.payload.installers
      };
    }
    case actionTypes.SET_ALL_NOT_ATTACHED_OEMS: {
      return {
        ...state,
        notAttachedOEMs: action.payload.oems
      };
    }
    case actionTypes.SET_LOADING_SUPPORT: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
