import { createSelector } from 'reselect';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';

const rolesWithCustomLogo = ['pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES];

const getUser = (state) => state.signIn?.user;
const getSelectedRows = (state) => state.monitoring?.selectedRows;
const getAllDataOnPage = (state) => state.dataList?.monitoring?.data;
const getAllData = (state) => state.monitoring?.data;
const getChoosedEmployees = (state) => state.monitoring?.employees;
const getChoosedCompany = (state) => state.monitoring?.choosedCompany?.value;
const getArchivedErrors = (state) => state.monitoring?.checked;
const getModal = (state) => state.modals.editNote;
const getSupportContractChecked = (state) => state.monitoring?.supportContractChecked;
const getInstallationCompleted = (state) => state.monitoring?.isInstallationCompleted;
const getText = (state) => state.monitoring.newText;
const getNoteText = (state) => state.form?.monitoringNoteForm?.values?.note;
const getIsLoading = (state) => state.modals?.isLoading;

export const companyUserIdSelector = createSelector([getUser], (user) => user?.my_company?._id || user?.company?._id);
export const selectedRowsSelector = createSelector([getSelectedRows], (selectedRows) => selectedRows);
export const allDataOnPageSelector = createSelector([getAllDataOnPage], (data) => data);
export const allDataSelector = createSelector([getAllData], (data) => data);
export const choosedEmployeesSelector = createSelector([getChoosedEmployees], (employees) => employees);
export const choosedCompanySelector = createSelector([getChoosedCompany], (value) => value);
export const archivedErrorsSelector = createSelector([getArchivedErrors], (checked) => checked);
export const compLogoSelector = createSelector([getUser], (user) => user?.company_logo);
export const visibleWebSelector = createSelector([getUser], (user) => (rolesWithCustomLogo.includes(user?.role?.type)));
export const modalSelector = createSelector([getModal], (editNote) => editNote);
export const supportContractCheckedSelector = createSelector([getSupportContractChecked], (supportContractChecked) => supportContractChecked);
export const installationCompletedSelector = createSelector([getInstallationCompleted], (isInstallationCompleted) => isInstallationCompleted);
export const textSelector = createSelector([getText], (text) => text);
export const noteTextSelector = createSelector([getNoteText], (note) => note);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const oemIdSelector = createSelector([getUser], (user) => user?.oem?._id);

export const initialValuesSelector = createSelector(
  [getModal],
  (modal) => ({
    note: modal?.data?.note || ''
  })
);
