import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  formValueSelector,
  getFormValues,
  reduxForm
} from 'redux-form';

/**
 * Components
 * @ignore
 */
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import { LongCheckboxArr } from '../../../components/LongCheckboxArr';
import Checkbox from '../../../components/ReduxFormFields/Checkbox';
import { CancelButton } from '../../../components/UIKit';
import Preloader from '../../../components/Preloader';
import ModalWindow from '../../ModalWindow';

/**
 * Actions
 * @ignore
 */
import {
  getNotAttachedToViewerEndUsers,
  getPVInstallersForViewerAllList,
  setNotAttachedToViewerEndUsers,
  getOEMsListForViewer
} from '../actions';

/**
 * Selectors
 * @ignore
 */
import {
  registeredFieldsEndUserSelectorForViewer,
  getIsLoadingSelectorForViewersEndUsers,
  notAttachedEndUsersSelectorForViewer,
  pvInstallersSelectorForViewer,
  addEndUsersModalSelector,
  oemsSelectorForViewer,
  isLoadingSelector
} from '../selectors';

import {
  getInstallersForViewerParams,
  getEndUsersForViewerParams,
  addEndUsersToViewerForm,
  getOEMSForViewerParams,
  selectAllCheckbox
} from '../constants';
import {
  getPVInstallersListOptions,
  getOEMsListOptions
} from '../utils';

import i18n from '../../../i18n';

/**
 * Renders add End User to viewer modal window for ViewerDetails page
 * @memberof module:ViewerDetails
 */
const AddEndUserToViewerWindow = (props) => {
  const {
    selectedPVInstaller,
    filteredEndUsers,
    handleSubmit,
    handleOnClose,
    pvInstallers,
    viewerUserId,
    selectedOEM,
    modalID,
    modal,
    reset,
    oems,
    change,
    isLoadingState: { isNotAttachedUsersLoading, isOemsListForViewerLoading, isPvInstallersListForViewerLoading }
  } = props;
  const dispatch = useDispatch();

  const selectedEndUsers = useMemo(() => filteredEndUsers.filter(({ isChecked }) => isChecked).length, [filteredEndUsers]);

  const isLoadingData = isOemsListForViewerLoading || isPvInstallersListForViewerLoading || (isNotAttachedUsersLoading && !filteredEndUsers.length);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = filteredEndUsers.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit(dataForReq);
    reset();
  };

  const pickSearchOptions = (option, value) => {
    dispatch(getNotAttachedToViewerEndUsers(
      {
        viewerId: viewerUserId,
        ...getEndUsersForViewerParams,
        oemId: selectedOEM && selectedOEM !== 'all' ? selectedOEM : undefined,
        pvInstallerId: selectedPVInstaller && selectedPVInstaller !== 'all' ? selectedPVInstaller : undefined,
        [option]: value && value !== 'all' ? value : undefined
      }));
    change(selectAllCheckbox, false);
  };

  const handleChange = (value) => {
    if (isNotAttachedUsersLoading) return;

    const copy = [...filteredEndUsers];
    const indexOfPoint = copy.findIndex((point) => point._id === value);
    copy[indexOfPoint] = {
      ...copy[indexOfPoint],
      isChecked: !copy[indexOfPoint].isChecked
    };
    dispatch(setNotAttachedToViewerEndUsers(copy));

    const isAllIsChecked = copy.every((point) => point.isChecked);
    change(selectAllCheckbox, isAllIsChecked);
  };

  const handleChangeAll = (event) => {
    dispatch(setNotAttachedToViewerEndUsers(filteredEndUsers.map((point) => ({ ...point, isChecked: event.target.checked }))));
  };

  useEffect(() => {
    if (!modal.opened) {
      dispatch(setNotAttachedToViewerEndUsers(filteredEndUsers.map((point) => ({ ...point, isChecked: false }))));
      reset();
    }
  }, [modal.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getNotAttachedToViewerEndUsers({ viewerId: viewerUserId, ...getEndUsersForViewerParams }));
    dispatch(getOEMsListForViewer(getOEMSForViewerParams));
    dispatch(getPVInstallersForViewerAllList(getInstallersForViewerParams));

    return () => dispatch(setNotAttachedToViewerEndUsers([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow customClass="max-width-550" modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>
      {isLoadingData ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={addEndUsersToViewerForm}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="row users-filter">
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="oem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={getOEMsListOptions(oems)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('oemId', value)}
                      disabled={isNotAttachedUsersLoading}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="pvInstaller"
                      component={CustomSelect}
                      placeholder={i18n.t('selectPVI')}
                      options={getPVInstallersListOptions(pvInstallers)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('pvInstallerId', value)}
                      disabled={isNotAttachedUsersLoading}
                    />
                  </div>
                </div>
              </div>
              {!!filteredEndUsers.length && (
                <div className="form-group m-form__group checkbox-row">
                  <Field
                    type="checkbox"
                    name={selectAllCheckbox}
                    component={Checkbox}
                    className="m-input"
                    autoComplete="off"
                    label={i18n.t('selectAllEndUsers')}
                    labelClass=""
                    onChange={handleChangeAll}
                    disabled={isNotAttachedUsersLoading}
                  />
                </div>
              )}
              <LongCheckboxArr
                formId={addEndUsersToViewerForm}
                handleChange={handleChange}
                noDataText="noUser"
                checkedData={filteredEndUsers}
                disabled={isNotAttachedUsersLoading}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              {i18n.t('Selected')}
              {': '}
              {selectedEndUsers}
            </p>
            <div className="modal-buttons">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={addEndUsersToViewerForm}
                disabled={!selectedEndUsers}
                type="submit"
                className="ml-2 btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav installer-to-oem-btn"
              >
                {i18n.t('add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddEndUserToViewerWindow.propTypes = {
  isLoadingState: PropTypes.instanceOf(Object).isRequired,
  filteredEndUsers: PropTypes.instanceOf(Object),
  pvInstallers: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  oems: PropTypes.instanceOf(Object),
  handleOnClose: PropTypes.func.isRequired,
  viewerUserId: PropTypes.string.isRequired,
  modalID: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  selectedPVInstaller: PropTypes.string,
  selectedOEM: PropTypes.string,
  handleSubmit: PropTypes.func
};

AddEndUserToViewerWindow.defaultProps = {
  filteredEndUsers: [],
  pvInstallers: [],
  oems: [],
  modal: {},
  selectedPVInstaller: undefined,
  handleSubmit: undefined,
  selectedOEM: undefined
};

const form = reduxForm({
  form: addEndUsersToViewerForm
})(AddEndUserToViewerWindow);

const selector = formValueSelector(addEndUsersToViewerForm);

const mapStateToProps = createStructuredSelector({
  filteredEndUsers: notAttachedEndUsersSelectorForViewer,
  isLoadingState: getIsLoadingSelectorForViewersEndUsers,
  regFields: registeredFieldsEndUserSelectorForViewer,
  pvInstallers: pvInstallersSelectorForViewer,
  modal: addEndUsersModalSelector,
  isLoading: isLoadingSelector,
  oems: oemsSelectorForViewer,
  selectedPVInstaller: (state) => selector(state, 'pvInstaller'),
  selectedItems: getFormValues(addEndUsersToViewerForm),
  selectedOEM: (state) => selector(state, 'oem')
});

export default connect(mapStateToProps)(form);
