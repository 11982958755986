import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  email,
  required,
  notEqualValue,
  parseToLowerCase
} from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import { CHANGE_EMAIL_FORM } from './constants';
import { changeEmailModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * ChangeEmailWindow form id
 */
const changeEmail = 'changeEmail';

/**
 * Checks if the old email is UNEQUAL to the new email
 */
const newEmailShouldDiffer = notEqualValue('old_email', i18n.t('newEmailShouldDiffer'));

/**
 * Modal window for changing email from edit profile page.
 * @memberof module:SettingProfile
 */
const ChangeEmailWindow = (props) => {
  const { handleOnClose, handleSubmit, modalID, pristine, modal, reset } = props;

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeMail')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={changeEmail}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field">
            <Field
              name="email"
              component={CustomTextInput}
              label={i18n.t('reqNewMail')}
              className="m-input"
              autoComplete="off"
              validate={[required, email, newEmailShouldDiffer]}
              parse={parseToLowerCase}
              preventAuto
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <button
          form={changeEmail}
          disabled={pristine}
          type="submit"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
        >
          {i18n.t('changeBtn')}
        </button>
      </div>
    </ModalWindow>
  );
};

ChangeEmailWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

ChangeEmailWindow.defaultProps = {
  modal: {
    data: {}
  }
};

const form = reduxForm({
  form: CHANGE_EMAIL_FORM
})(ChangeEmailWindow);

const mapStateToProps = createStructuredSelector({
  modal: changeEmailModalSelector
});

export default connect(mapStateToProps)(form);
