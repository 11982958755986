import React from 'react';
import { Redirect } from 'react-router';
import strapi from '../../strapi';

const BurkhalterAuth = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  if (token) {
    window.localStorage.setItem('jwt', token);
    strapi.setToken(token);
  }
  return <Redirect to="/" />;
};

export default BurkhalterAuth;
