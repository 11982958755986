export const INVITE_MODAL_ID = 'inviteUserModal';
export const DELETE_INVITED_USER_MODAL_ID = 'deleteInvitedUser';
export const allowedRolesForInviteUsers = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'support', 'oem', 'end_user'];


export const actionTypes = {
  GET_INVITED_USERS_REQUEST: 'GET_INVITED_USERS_REQUEST',
  DELETE_INVITED_USER_REQUEST: 'DELETE_INVITED_USER_REQUEST',
  INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',

  SET_IS_INVITED_USERS_LOADING: 'SET_IS_INVITED_USERS_LOADING',
  SET_IS_DELETE_INVITED_USER_LOADING: 'SET_IS_DELETE_INVITED_USER_LOADING',
  SET_IS_INVITE_USER_LOADING: 'SET_IS_INVITE_USER_LOADING',

  SET_INVITED_USERS: 'SET_INVITED_USERS'
};
