import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';
import i18n from '../../i18n';

const StyledLabel = styled.label`
  &:before {
    ${(props) => props?.labelCircleColor && css`
      background: ${props.labelCircleColor} !important;
    `}
  }
`;

/**
 * Renders checkbox for redux form
 * @memberof module:ReduxFormFields
 * @param  {Object} props - React component properties
 * @param  {Object} props.input
 * @param  {string} props.label - label text
 * @param  {string} props.labelClass - class of label
 * @param  {Object} props.meta
 * @param  {boolean} props.meta.touched
 * @param  {boolean} props.meta.error
 */
const Checkbox = ({
  input,
  showTooltip,
  label,
  labelClass,
  meta: { touched, error },
  languageToSync,
  defaultValue,
  labelCircleColor,
  ...custom
}) => {
  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div className="has-danger">
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <StyledLabel
        htmlFor={custom.id}
        className={`m-checkbox m-checkbox--air m-checkbox--state-warning ${labelClass}`}
        labelCircleColor={labelCircleColor}
      >
        <input
          {...input}
          {...custom}
        />
        {label}
        <span />
        {touched && (error || languageToSync) && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )}
      </StyledLabel>
    </div>
  );
};

Checkbox.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  labelClass: PropTypes.string,
  languageToSync: PropTypes.string,
  showTooltip: PropTypes.bool,
  labelCircleColor: PropTypes.string,
  defaultValue: PropTypes.bool
};

Checkbox.defaultProps = {
  languageToSync: '',
  showTooltip: false,
  labelCircleColor: '',
  label: undefined,
  labelClass: undefined,
  defaultValue: undefined
};

export default Checkbox;
