import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { createStructuredSelector } from 'reselect';
import i18n from '../../../i18n';
import { userSelector } from './selectors';

/**
 * Array of semi tabs and role types in monitoring
 * @constant
 * @memberof module:Monitoring
 */
const tabsByRoles = [
  {
    label: 'monitoring',
    link: '/monitoring',
    allowedRoles: ['root', 'solar_admin', 'support', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem']
  },
  {
    label: 'notification',
    link: '/monitoring/notification',
    allowedRoles: ['pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem']
  }
];
/**
 * Renders tabs (relative to user role who is viewing) for Monitoring.
 * @memberof module:Monitoring
 * @param {Object} param Must contain next Object: { user: { role: { type: 'string' } }, ... }
 */
const SemiTabs = ({ user }) => {
  if (!user || !user.role) return null;

  return (
    <ul className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x">
      {tabsByRoles.map(
        (tab) => (
          tab.allowedRoles.includes(user.role.type)
            ? (
              <li
                key={shortid.generate()}
                className="nav-item m-tabs__item"
              >
                <NavLink
                  className="nav-link m-tabs__link"
                  activeClassName="active show"
                  exact
                  to={tab.link}
                  {...tab.componentProps}
                >
                  {i18n.t(tab.label)}
                </NavLink>
              </li>
            )
            : null
        )
      )}
    </ul>
  );
};

SemiTabs.propTypes = {
  user: PropTypes.instanceOf(Object)
};

SemiTabs.defaultProps = {
  user: {}
};

const mapStateToProps = createStructuredSelector({
  user: userSelector
});

export default connect(mapStateToProps)(SemiTabs);
