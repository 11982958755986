import { createSelector } from 'reselect';

const getFilesCount = (state) => state.userDevices?.user.uploadedFiles;
const getFilesCountForEndUser = (state) => state.signIn?.user.uploadedFiles;

const getFiles = (state) => state.documentation?.files;
const getFilesLoading = (state) => state.documentation?.filesLoading;
const getSelectedFiles = (state) => state.documentation?.selectedFiles;
const getFilesForUpload = (state) => state.documentation?.filesForUpload;
const getIsUploadLoading = (state) => state.documentation?.isUploadLoading;

const getDeleteModal = (state) => state.modals?.deleteFilesModal;
const getDownloadModal = (state) => state.modals?.downloadFile;

export const filesCountSelector = createSelector([getFilesCount], (filesCount) => filesCount || 0);
export const filesCountSelectorForEndUser = createSelector([getFilesCountForEndUser], (filesCount) => filesCount || 0);

export const filesSelector = createSelector([getFiles], (files) => files);
export const filesLoadingSelector = createSelector([getFilesLoading], (filesLoading) => filesLoading);
export const selectedFilesSelector = createSelector([getSelectedFiles], (selectedFiles) => selectedFiles);
export const filesForUploadSelector = createSelector([getFilesForUpload], (filesForUpload) => filesForUpload);
export const isUploadLoadingSelector = createSelector([getIsUploadLoading], (isUploadLoading) => isUploadLoading);

export const deleteModalFilesIdsSelector = createSelector([getDeleteModal], (deleteModal) => deleteModal?.data?.ids);
export const downloadModalFileIdSelector = createSelector([getDownloadModal], (downloadModal) => downloadModal?.data?.id);
