import { PRESET_COLORS, DEVICE_DEFAULT_COLOR } from './constants';

const getColor = (name, isTag, isUniqueDevice = false, device) => {
  if (!isTag && PRESET_COLORS[name]) {
    return PRESET_COLORS[name];
  }

  if (isUniqueDevice) {
    return PRESET_COLORS[device];
  }
  return DEVICE_DEFAULT_COLOR; // TODO: add random / hash-based color generation
};

export default getColor;
