import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { setOEMs } from './actions';

function getOEMsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS),
    map((action) => action.payload),
    switchMap(() => from(strapi.request('get', '/../oem/connected-oems')).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

function getOEMsByIDEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_BY_ID),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(strapi.request('get', url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

export default combineEpics(
  getOEMsEpic, getOEMsByIDEpic
);
