import { from, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap, finalize } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as actionTypes from '../constants';
import strapi from '../../../strapi';
import { handleErrorDetailed } from '../../../api_helper';
import { dataReceived, dashboardUpdCurrent } from '../actions';
import { addNotification } from '../../NotificationGenerator/actions';
import { CURRENT_VALUES_TIME_RANGE } from '../constants';
import analytics from '../../../analytics';
import getEventObj, { DATA_REAL_TIME } from '../../../analytics/events';

/**
 * Changes chart data range by scaleType (see {@link module:Dashboard getRangeByScaleType}
 * Is used with buttons: "Today,h,D,W,M,Y,+,-,<,>"
 * @memberof module:DashboardEpics
 */
export default function updateChart(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.CHART_WILL_UPDATE),
    map((action) => action.payload.scaleType),
    switchMap(() => {
      const {
        dashboard: {
          chart: {
            myself,
            data: {
              consumption,
              production
            }
          }
        },
        settingProfile: {
          initialData: { gateway }
        }
      } = state$.value;

      const url = myself
        ? '/data/real-time'
        : `/data/real-time/${gateway._id}`;

      return from(strapi.request('get', url, { params: { isWeb: true } })).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(DATA_REAL_TIME, {}, { myself }));
        }),
        mergeMap((result) => {
          if (!result.error) {
            const lastTimestamp = (consumption?.length ? consumption[consumption.length - 1][0] : 0)
              || (production?.length ? production[production.length - 1][0] : 0);

            const newPoint = result?.rawSensorData?.newPoint;

            // if new point timestamp is valid then update chart and dashboard current values with new point data
            if (newPoint?.timestamp
              && newPoint.timestamp >= Date.now() - CURRENT_VALUES_TIME_RANGE
              && newPoint.timestamp >= lastTimestamp) {
              return of(
                dashboardUpdCurrent(result?.rawSensorData),
                dataReceived(result)
              );
            }
            return of(dataReceived(result));
          }

          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        })
      );
    })
  );
}
