import { RECEIVE_CLOUD_STATUS_MESSAGE, REQUEST_CLOUD_STATUS_MESSAGE } from './constants';

export function requestCloudStatusMessage() { // epic
  return {
    type: REQUEST_CLOUD_STATUS_MESSAGE
  };
}

export function receiveCloudStatusMessage(message) { // reducer
  return {
    type: RECEIVE_CLOUD_STATUS_MESSAGE,
    payload: {
      message
    }
  };
}
