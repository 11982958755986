import React, {
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  FormSection
} from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { omit } from 'lodash';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';


/**
 * Components
 * @ignore
 */
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import RenderFieldsByDevName from '../components/RenderFieldsByDevName';
import IPField from '../../../components/ReduxFormFields/IPField';
import TagField from '../../../components/ReduxFormFields/TagField';
import DescriptionTagField from '../../../components/ReduxFormFields/DescriptionTagField';


/**
 * Actions
 * @ignore
 */
import {
  closeModalWindow
} from '../../ModalWindow/actions';
import {
  getSensorsConfigurationFile
} from '../actions';


/**
 * Utils
 * @ignore
 */
import {
  additionalFields
} from '../utils';


/**
 * Selectors
 * @ignore
 */
import {
  isExpiredSensorsConfigurationFileSelector,
  isDisabledOauth2EditModalSelector,
  sensorsConfigurationFileSelector,
  editDeviceFormDevice_group,
  devicesListSelector,
  profileNameSelector,
  myRoleTypeSelector,
  editDeviceFormData,
  editDeviceFormType,
  isLoadingSelector,
  modalsSelector
} from './selectors';


/**
 * Validation
 * @ignore
 */
import {
  required
} from '../../validation';

/**
 * Constants
 * @ignore
 */
import {
  editDeviceFormId
} from '../constants';
import CancelButton from '../../../components/UIKit/CancelButton';


/**
 * EditDeviceWindow form id
 */
const formID = editDeviceFormId;

/**
 * Modal window for editing device
 * @memberof module:UserDevices
 */
const EditDeviceWindow = (props) => {
  const {
    getSensorsConfigurationFile: getSensorsConfigurationFileFromProps,
    isExpiredSensorsConfigurationFile,
    isDisabledOauth2EditModal,
    sensorsConfigurationFile,
    handleOnClose,
    profile_name,
    handleSubmit,
    device_group,
    myRoleType,
    deviceType,
    isLoading,
    dispatch,
    pristine,
    modalID,
    modals,
    reset,
    type,
    data
  } = props;

  const modal = modals?.[modalID];

  useEffect(() => {
    if (modal?.opened && (!sensorsConfigurationFile || isExpiredSensorsConfigurationFile)) {
      getSensorsConfigurationFileFromProps();
    }

    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const typesList = [
    {
      value: type,
      label: i18n.t(type)
    }
  ];
  const namesList = [
    {
      value: device_group,
      label: i18n.t(device_group)
    }
  ];
  const allowAdvancedSettings = [
    'root',
    'solar_admin',
    'pv_installer',
    'pv_installer',
    'pv_installer_employee',
    'support',
    'oem',
    'end_user'
  ].includes(myRoleType);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              id={formID}
              className="m-login__form m-form pop-up-form add-sm-us"
              onSubmit={handleSubmit}
            >
              <div className="form-group m-form__group input-field zIndex21">
                <div className="flags-select-label">{`${i18n.t('deviceType')} *`}</div>
                <Field
                  name="type"
                  component={CustomSelect}
                  placeholder={i18n.t('deviceType')}
                  options={typesList}
                  validate={[required]}
                  className="m-input"
                  disabled
                />
              </div>
              <div className="form-group m-form__group input-field zIndex20">
                <div className="flags-select-label">{`${i18n.t('deviceName')} *`}</div>
                <Field
                  name="device_group"
                  component={CustomSelect}
                  placeholder={i18n.t('deviceName')}
                  options={namesList}
                  validate={[required]}
                  className="m-input"
                  disabled
                />
              </div>
              {Number.isInteger(profile_name) && (
                <div className="form-group m-form__group input-field zIndex19">
                  <Field
                    name="profile_name"
                    {...omit(additionalFields.ctaType, ['validate'])}
                    placeholder={
                      i18n.t(`ctaType.options.${profile_name}`) || i18n.t('CTANotSelected')
                    }
                    disabled
                  />
                </div>
              )}
              <DescriptionTagField
                deviceType={deviceType}
              />
              <TagField
                deviceType={deviceType}
                dispatch={dispatch}
                formID={formID}
              />
              <IPField
                device_group={device_group}
                formID={formID}
                type={type}
              />
              {allowAdvancedSettings && (
                <FormSection name="data">
                  <RenderFieldsByDevName
                    deviceName={device_group}
                    data={data}
                    myRoleType={myRoleType}
                    modal={modal}
                    type={type}
                    formID={formID}
                  />
                </FormSection>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              disabled={pristine && isDisabledOauth2EditModal}
              form={formID}
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              <i className="fa fa-save" />
              {i18n.t('save')}
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

EditDeviceWindow.propTypes = {
  sensorsConfigurationFile: PropTypes.instanceOf(Object),
  modals: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  isExpiredSensorsConfigurationFile: PropTypes.bool.isRequired,
  getSensorsConfigurationFile: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isDisabledOauth2EditModal: PropTypes.bool,
  device_group: PropTypes.string,
  profile_name: PropTypes.number,
  myRoleType: PropTypes.string,
  isLoading: PropTypes.bool,
  modalID: PropTypes.string,
  type: PropTypes.string
};

EditDeviceWindow.defaultProps = {
  isDisabledOauth2EditModal: true,
  isLoading: false,
  sensorsConfigurationFile: undefined,
  device_group: undefined,
  myRoleType: undefined,
  type: undefined,
  modalID: '',
  modals: {},
  data: {},
  profile_name: null
};

const form = reduxForm({
  form: formID,
  enableReinitialize: true
})(EditDeviceWindow);


const mapStateToProps = createStructuredSelector({
  device_group: editDeviceFormDevice_group,
  type: editDeviceFormType,
  data: editDeviceFormData,
  isExpiredSensorsConfigurationFile: isExpiredSensorsConfigurationFileSelector,
  isDisabledOauth2EditModal: isDisabledOauth2EditModalSelector,
  sensorsConfigurationFile: sensorsConfigurationFileSelector,
  profile_name: profileNameSelector,
  devicesList: devicesListSelector,
  myRoleType: myRoleTypeSelector,
  isLoading: isLoadingSelector,
  modals: modalsSelector
});

const mapDispatchToProps = {
  getSensorsConfigurationFile,
  closeModalWindow
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
