import { from } from 'rxjs';
import moment from 'moment-timezone';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, finalize } from 'rxjs/operators';

import * as actionTypes from '../constants';
import strapi from '../../../strapi';
import { handleErrorDetailed } from '../../../api_helper';
import {
  fillEmptySpacesByZero,
  setEndForBarChart,
  groupAllData,
  parseMinifiedData,
  trimDataByRange
} from '../utils';
import { chartScaleChanged } from '../actions';
import { addNotification } from '../../NotificationGenerator/actions';
import {
  getChartType,
  batteryDischargingNegation,
  fillEmptyRanges,
  generateEmptyBuffer
} from './utils';
import { parseData } from '../utils/activeDevices';
import getChartResolutionByIndex from '../utils/getChartResolutionByIndex';
import analytics from '../../../analytics';
import getEventObj, { GET_POINTS } from '../../../analytics/events';

/**
 * Is called when user changes scale by using default highchart zooming.
 * It sends request and handles data updating.
 * @memberof module:DashboardEpics
 */
export default function chartChangeRangeEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.CHART_WILL_CHANGE_RANGE),
    map((action) => action.payload),
    switchMap(({ min, max }) => {
      let fromDate = moment(min);
      let toDate = moment(max);
      let limitsReceived;
      const {
        dashboard: {
          chart,
          user: {
            chart_settings
          }
        },
        signIn: {
          user: {
            demo
          }
        }
      } = state$.value;

      let scaleMS = toDate.diff(fromDate);
      const chartType = getChartType(scaleMS);
      const realTimeData = (moment().diff(toDate) < 10000 && scaleMS <= 90000000); // 90000000 = 25 hours

      if (scaleMS < 900000) { // 15 хвилин
        const offset = Math.round(900000 - scaleMS) / 2;
        scaleMS = 900000;
        fromDate.subtract(offset, 'ms');
        toDate = moment(fromDate).add(scaleMS, 'ms');
        if (toDate.valueOf() > Date.now()) {
          toDate = moment();
          fromDate = moment(toDate).subtract(scaleMS, 'ms');
        }
        limitsReceived = { minZoom: true };
      }

      const fromMS = fromDate.valueOf();
      const toMS = toDate.valueOf();

      const hourURL = (chartType === 'monthly' || chartType === 'daily') ? '-hour' : '';

      const chartResolution = getChartResolutionByIndex(chart?.groupPixelWidth);

      const version = chartResolution === 'low' && scaleMS <= actionTypes.ONE_WEEK_MS
        ? 'v3'
        : 'v2';

      const url = chart.myself
        ? `/../data/${version}/get-my${hourURL}-points?from=${fromDate.toISOString()}&to=${toDate.toISOString()}${version === 'v3' ? '&chartResolution=low' : ''}`
        : `/../data/${version}/get-user${hourURL}-points/${chart.gatewayID}?from=${fromDate.toISOString()}&to=${toDate.toISOString()}${version === 'v3' ? '&chartResolution=low' : ''}`;

      return from(strapi.request('get', url)).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(GET_POINTS, {}, { myself: chart.myself, version, hourURL }));
        }),
        map((result) => {
          if (!result.error) {
            // TODO: Remove after fix on backend
            if (demo && (chartType === 'monthly' || chartType === 'daily')) {
              result.data = trimDataByRange(result.data, {
                from: toDate.valueOf(),
                to: fromDate.valueOf()
              });
            }

            result.data = parseMinifiedData(result.data);

            if (chartType === 'daily' || chartType === 'monthly') {
              setEndForBarChart(
                groupAllData(result.data, fromMS, chartType),
                chartType
              );
            } else {
              fillEmptySpacesByZero({
                data: result.data,
                interval: result.interval,
                from: fromMS,
                to: toMS
              });
            }
            const config = {
              scaleMS, chartType, limitsReceived, realTimeData
            };
            const response = {
              ...result,
              data: {
                ...result.data
              },
              active_devices: parseData(result.active_devices, fromMS, toMS, chart_settings)
            };
            if (result.data.battery_discharging) {
              batteryDischargingNegation(result.data.battery_discharging);
            }
            fillEmptyRanges(response.active_devices, fromMS, toMS);

            const buffer = realTimeData ? generateEmptyBuffer(response.active_devices) : undefined;

            return chartScaleChanged(
              fromMS,
              toMS,
              config,
              response,
              buffer
            );
          }
          return addNotification({
            type: 'error',
            text: result.message
          });
        })
      );
    })
  );
}
