export default {
  'CTA heat pump': [
    {
      index: 0,
      profileName: 'CTA Aeroheat Inverta All-In-One AH CI 8is',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 1,
      profileName: 'CTA Aeroheat Inverta All-In-One AH CI 12is',
      values: {
        minPower: 4900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 2,
      profileName: 'CTA Aeroheat Inverta Economy AH CI 8i/a',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 3,
      profileName: 'CTA Aeroheat Inverta Economy AH CI 12i/a',
      values: {
        minPower: 4900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 4,
      profileName: 'CTA Aeroheat Inverta AH CI 1-16iL,1-16i/a',
      values: {
        minPower: 5200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 5,
      profileName: 'CTA Aeroheat Invetra CNI 9a',
      values: {
        minPower: 2900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 6,
      profileName: 'CTA Aeroheat Invetra CN 5a',
      values: {
        minPower: 2500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 7,
      profileName: 'CTA Aeroheat Invetra CN 7a',
      values: {
        minPower: 3400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 8,
      profileName: 'CTA Aeroheat Invetra CN 9a',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 9,
      profileName: 'CTA Aeroheat CS 1-14iL, 1-14i/a',
      values: {
        minPower: 5800,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 10,
      profileName: 'CTA Aeroheat CS 1-18iL, 1-18i/a',
      values: {
        minPower: 4000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 11,
      profileName: 'CTA Aeroheat CS 1-25iL, 1-25i/a',
      values: {
        minPower: 6100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 12,
      profileName: 'CTA Aeroheat CS 30iL, 30i/a',
      values: {
        minPower: 6700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 50,
        boilerTempNormal: 50,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 13,
      profileName: 'CTA Aeroheat CM 18a',
      values: {
        minPower: 5600,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    }
  ]
};
