import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import UserPhoneInput from '../../components/ReduxFormFields/PhoneTextInput/UserPhoneInput';
import Tooltip from '../../components/Tooltip';
import '../../App.css';
import 'react-select/dist/react-select.css';
import {
  required,
  zipCode,
  minLength,
  maxLength,
  checkValidPhone
} from '../validation';
import CustomSelect from '../../components/ReduxFormFields/CustomSelect';
import LanguageSelector from '../../components/ReduxFormFields/LanguageSelector';
import CountrySelector from '../../components/ReduxFormFields/ContrySelector';
import DataList from '../DataList';
import AvatarPreviewer from './components/AvatarPreviewer';
import {
  statusList,
  defaultOEM,
  dataListPviId,
  getPvInstallersConfig,
  SETTING_PROFILE_FORM
} from './constants';
import i18n from '../../i18n';
import { getOEMs, getOEMsByID, settingPrClearValues } from './actions';
import { dataListCompaniesSelector, dataListOemsSelector, oemSelector, pvInstallerSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

const getAdminId = (companies, pviId) => {
  const chosenCompany = companies.find((item) => item?._id && item._id === pviId) || {};
  return chosenCompany?.adminId;
};

const isArrayEqual = (x, y) => _(x).xorWith(y, _.isEqual).isEmpty();

const transformResponse = (response) => {
  const data = response.list
    .filter(({ my_company: { _id, name, admin } }) => _id && name && admin)
    .map(({ my_company: { _id, name, admin: adminId } }) => ({ id: _id, _id, name, adminId }));
  return { data };
};


/**
 * Edit profile of user with roleType = 'end_user'
 * @class FormUser
 * @memberof module:SettingProfile
 */
export class FormUser extends Component {
  constructor(props) {
    super(props);
    this.statusList = statusList();
  }

  componentDidMount() {
    const {
      initialize,
      initialValues,
      getOEMs: getOEMsProps,
      myRoleType
    } = this.props;
    const hasInstallerRights = ['pv_installer_employee', 'pv_installer'].includes(myRoleType);
    if (hasInstallerRights) {
      getOEMsProps();
    }
    initialize({ ...initialValues });
  }

  componentDidUpdate(prevProps) {
    const {
      getOEMsByID: getOEMsByIDProps,
      myRoleType,
      company,
      dataListCompanies
    } = this.props;
    const hasSupportRights = ['root', 'solar_admin', 'support'].includes(myRoleType);
    if (hasSupportRights && company && company !== 'null') {
      if (prevProps.company !== company || !isArrayEqual(prevProps.dataListCompanies, dataListCompanies)) {
        const adminId = getAdminId(dataListCompanies, company);
        const request = {
          support: {
            url: '/../users/get-supported-oem-users',
            params: { pvInstallerId: company }
          },
          default: {
            url: `/../oem/connected-oems/${adminId}`,
            params: {}
          }
        };
        if (myRoleType === 'support' || adminId) {
          const { url, params } = request?.[myRoleType] || request.default;
          getOEMsByIDProps(url, params);
        }
      }
    }
  }

  componentWillUnmount() {
    const { myRoleType, dispatch } = this.props;
    if (myRoleType !== 'end_user') {
      dispatch(settingPrClearValues());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { company } = this.props;
    const {
      company: nextCompany,
      change
    } = nextProps;

    // If company did not changed than change the `OEM` field value to the default
    if (company && company !== nextCompany) {
      change('connectedOem', defaultOEM.value);
    }
  }

  render() {
    const {
      handleSubmit,
      goBack,
      openChangeEmail,
      openChangePassword,
      openChangeSMID,
      first_name,
      last_name,
      initialValues,
      myRoleType,
      userRoleName,
      myself,
      dataListCompanies,
      dataListOems,
      company,
      pristine,
      submitting,
      sendEmailRequest,
      status,
      pvInstaller,
      oem
    } = this.props;

    const { url, params } = getPvInstallersConfig[myRoleType] || getPvInstallersConfig.default;

    const companiesList = dataListCompanies
      .filter((item) => item?._id !== pvInstaller?._id)
      .map((item) => ({ value: item._id, label: item.name }))
      .concat(pvInstaller ? { value: pvInstaller?._id, label: pvInstaller?.name } : []);
    if (companiesList.length) {
      companiesList.unshift({ value: 'null', label: i18n.t('noComp') });
    }

    const oemsList = company && company !== 'null'
      ? dataListOems
        .filter((item) => item?.oem?._id !== oem?._id)
        .map((item) => ({ value: item.oem._id, label: `${item.oem.name}` }))
        .concat(oem && pvInstaller && pvInstaller?._id === company ? { value: oem?._id, label: oem?.name } : [])
      : [];
    if (oemsList.length) {
      oemsList.unshift({ value: 'null', label: i18n.t('NoOEM') });
    }

    const validateStatus = (value) => ['active', 'deactivated'].includes(value);
    const validateValue = ({ value }) => validateStatus(value);

    const hasServiceRights = ['root', 'solar_admin', 'support', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only'].includes(myRoleType);
    const hasSupportRights = ['root', 'solar_admin', 'support'].includes(myRoleType);
    const roleDependencies = { disabled: !myself && ['viewer', 'pv_installer_employee_read_only'].includes(myRoleType) };
    const serviceRoleDependencies = { disabled: company && company !== 'null' && oemsList.length };
    const displayConnectedOem = hasServiceRights && !!oemsList.length && !['pv_installer_employee_read_only'].includes(myRoleType);

    return (
      <form onSubmit={handleSubmit}>
        <DataList
          listID={dataListPviId}
          listURL={url}
          params={params}
          transformResponse={transformResponse}
          forceKeepData
        />
        <div className="nav-settings">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-auto mr-sm-auto">
                <h6 className="set-prof-head">
                  {i18n.t('settingsProfile')}
                  {!myself && (
                    <Fragment>
                      :&nbsp;
                      {i18n.t(userRoleName)}
                    </Fragment>
                  )}
                </h6>
              </div>
              {!roleDependencies.disabled && (
                <div className="col-auto">
                  <button
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
                    type="button"
                  >
                    {i18n.t('saveChanges')}
                  </button>
                </div>
              )}
              <div className="col-auto cont-for-canc-btn">
                <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
            <div className="col-md-3 col-auto user-photo">
              <Field
                name="avatar"
                type="file"
                userName={`${first_name} ${last_name}`}
                avatar={initialValues?.avatarURL}
                component={AvatarPreviewer}
                {...roleDependencies}
              />
            </div>
            <div className="col-md-8">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="company_name"
                      component={CustomTextInput}
                      label={i18n.t('userCompany')}
                      className="m-input"
                      autoComplete="off"
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="first_name"
                      component={CustomTextInput}
                      label={i18n.t('reqFirstName')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="last_name"
                      component={CustomTextInput}
                      label={i18n.t('reqLastName')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  {!myself && (
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="plant"
                        component={CustomTextInput}
                        label={i18n.t('plantLabel')}
                        showTooltip={i18n.t('plantTooltip')}
                        className="m-input"
                        autoComplete="off"
                        {...roleDependencies}
                      />
                    </div>
                  )}
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="old_sm_id"
                      component={CustomTextInput}
                      label={i18n.t('reqSMID')}
                      className="m-input"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                      disabled
                    />
                    {hasServiceRights && !['pv_installer_employee_read_only'].includes(myRoleType) && (
                      <div style={{ position: 'relative' }}>
                        <button
                          type="button"
                          onClick={openChangeSMID}
                          className="change-btn"
                          style={{ top: '2px' }}
                          disabled={['pv_installer_employee_read_only'].includes(myRoleType)}
                        >
                          {i18n.t('Gateway Replacement')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  {hasServiceRights && (
                    <div className="form-group m-form__group input-field">
                      <div className="flags-select-label">
                        {i18n.t('status')}
                      </div>
                      <Field
                        name="status"
                        component={CustomSelect}
                        placeholder={i18n.t('selectStatus')}
                        options={this.statusList}
                        isSearchable={false}
                        validate={[required]}
                        filterOption={validateValue}
                        {...roleDependencies}
                        disabled={!hasSupportRights}
                      />
                    </div>
                  )}
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      name="old_email"
                      disabled
                      component={CustomTextInput}
                      label={i18n.t('mail')}
                      className="m-input"
                    />
                    {!roleDependencies.disabled && (
                      <>
                        {!validateStatus(status) && (
                          <button
                            type="button"
                            onClick={sendEmailRequest}
                            className="resend-btn"
                          >
                            {i18n.t('resendEmail')}
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={openChangeEmail}
                          className="change-btn"
                        >
                          {i18n.t('changeMail')}
                        </button>
                      </>
                    )}
                  </div>
                  {myself && (
                    <div className="form-group m-form__group input-field input-field-for-ch-pass">
                      <Field
                        name="password"
                        type="password"
                        disabled
                        component={CustomTextInput}
                        label={i18n.t('pass')}
                        className="m-input"
                        input={{ value: 'password' }}
                      />
                      <button
                        type="button"
                        onClick={openChangePassword}
                        className="change-btn"
                      >
                        {i18n.t('changePass')}
                      </button>
                    </div>
                  )}
                  <div className="form-group m-form__group input-field input-filed-flags-select">
                    <div className="flags-select-label">
                      {i18n.t('Country')}
                    </div>
                    <Field
                      name="country"
                      id="country"
                      component={CountrySelector}
                      buttonClassName="country-btn"
                      label={i18n.t('Country')}
                      className="m-input flagSelectClosed"
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="phone"
                      component={UserPhoneInput}
                      label={i18n.t('phoneNumber')}
                      className="m-input"
                      autoComplete="off"
                      country={initialValues.country}
                      validate={[checkValidPhone]}
                      {...roleDependencies}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="row align-items-md-start justify-content-md-start justify-content-center">
            <div className="col-md-3 col-auto user-photo" />
            <div className="col-md-8">
              <div className="row justify-content-between align-items-start">
                <div className="col-lg-5">
                  <div className="form-group m-form__group input-field input-filed-flags-select">
                    <div className="flags-select-label">
                      {i18n.t('reqLanguage')}
                    </div>
                    <Field
                      name="language"
                      component={LanguageSelector}
                      buttonClassName="reqLanguage-btn"
                      id="reqLanguage"
                      label={i18n.t('reqLanguage')}
                      className="m-input flagSelectClosed"
                      {...roleDependencies}
                      // disabled={false}
                    />
                  </div>
                  {(!['pv_installer_employee_read_only', 'pv_installer', 'pv_installer_employee'].includes(myRoleType)) && (
                    <div className="form-group m-form__group input-field">
                      <div className="flags-select-label">
                        {i18n.t('reqPVI')}
                      </div>
                      <Field
                        name="company"
                        component={CustomSelect}
                        placeholder={i18n.t('selectPVI')}
                        options={companiesList}
                        // "null" used as default value for a "No PV Installer" label
                        defaultValue="null"
                        validate={[required]}
                        {...roleDependencies}
                        disabled={!hasSupportRights}
                      />
                    </div>
                  )}
                  {displayConnectedOem && (
                    <div className="form-group m-form__group input-field">
                      <div className="flags-select-label">
                        {i18n.t('oem')}
                      </div>
                      <Field
                        name="connectedOem"
                        component={CustomSelect}
                        placeholder={i18n.t('selectOEM')}
                        options={oemsList}
                        defaultValue={defaultOEM.value}
                        {...serviceRoleDependencies}
                        disabled={!displayConnectedOem}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-5">
                  <div className="form-group m-form__group input-field input-field-street">
                    <Field
                      name="street"
                      component={CustomTextInput}
                      label={i18n.t('street')}
                      className="m-input"
                      autoComplete="off"
                      validate={[minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="row city-zip">
                    <div className="form-group m-form__group input-field col-7 city-inp">
                      <Field
                        name="city"
                        component={CustomTextInput}
                        label={i18n.t('city')}
                        className="m-input"
                        autoComplete="off"
                        validate={[minLen3, maxLen50]}
                        {...roleDependencies}
                      />
                    </div>
                    <div className="form-group m-form__group input-field offset-1 col-4 zip-inp">
                      <Field
                        name="zip"
                        component={CustomTextInput}
                        label={i18n.t('zip')}
                        className="m-input"
                        autoComplete="off"
                        validate={[zipCode]}
                        {...roleDependencies}
                      />
                    </div>
                  </div>
                  {!myself && (
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="note"
                        component={CustomTextInput}
                        label={(
                          <>
                            {i18n.t('userSettingNotesLabel')}
                            {' '}
                            <Tooltip title={i18n.t('userSettingNotesTooltip')} placement="right" />
                          </>
                        )}
                        className="m-input"
                        autoComplete="off"
                        multiline
                        maxRows={6}
                        aria-label="note"
                        {...roleDependencies}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FormUser.propTypes = {
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  company: PropTypes.string,
  dataListCompanies: PropTypes.instanceOf(Array),
  dataListOems: PropTypes.instanceOf(Array),
  pristine: PropTypes.bool.isRequired,
  sendEmailRequest: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  openChangeSMID: PropTypes.func,
  getOEMs: PropTypes.func,
  getOEMsByID: PropTypes.func,
  status: PropTypes.string,
  dispatch: PropTypes.func,
  pvInstaller: PropTypes.instanceOf(Object),
  oem: PropTypes.instanceOf(Object)
};

FormUser.defaultProps = {
  dataListCompanies: [],
  dataListOems: [],
  company: '',
  pvInstaller: undefined,
  goBack: undefined,
  myRoleType: undefined,
  last_name: undefined,
  openChangePassword: undefined,
  openChangeEmail: undefined,
  first_name: undefined,
  openChangeSMID: undefined,
  sendEmailRequest: undefined,
  userRoleName: undefined,
  dispatch: undefined,
  myself: undefined,
  getOEMsByID: undefined,
  getOEMs: undefined,
  status: undefined,
  oem: undefined
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormUser);

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

const mapStateToProps = createStructuredSelector({
  first_name: (state) => valueSelector(state, 'first_name'),
  last_name: (state) => valueSelector(state, 'last_name'),
  avatar: (state) => valueSelector(state, 'avatar'),
  status: (state) => valueSelector(state, 'status'),
  company: (state) => valueSelector(state, 'company'),
  dataListCompanies: dataListCompaniesSelector,
  dataListOems: dataListOemsSelector,
  pvInstaller: pvInstallerSelector,
  oem: oemSelector
});
export default connect(mapStateToProps, { getOEMs, getOEMsByID })(form);
