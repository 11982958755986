import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import i18n from '../../../i18n';
import PureTextInput from '../../../components/ReduxFormFields/PureTextInput';
import FileSelector from '../../../components/ReduxFormFields/FileSelector';
import { openModalWindow, closeModalWindow } from '../../ModalWindow/actions';
import UpdateConfirmationWindow from './UpdateConfirmationWindow';
import { LargeButton } from '../../../components/ReduxFormFields/SimpleButton';
import { selectedRowsSelector } from '../selectors';

/**
 * Renders form of updating installed SM
 * @memberof module:InstalledSM
 */
class UpdateSMForm extends Component {
  render() {
    const {
      handleSubmit, openModalWindow: openModal, closeModalWindow: closeModal
    } = this.props;
    return (
      <Fragment>
        <form>
          <div className="row align-items-end">
            <div className="col-10 col-lg-8 col-md-8 col-sm-8 col-xl-6 mr-auto btn-sm-us marginBottom">
              <h6 className="set-prof-head gener-sett-header">{i18n.t('updFirmware')}</h6>
              <Field
                name="version"
                type="text"
                component={PureTextInput}
                placeholder={i18n.t('firmwareVersion')}
                className="update-name-inp"
              />
              <Field
                name="file"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp btn-inp-file-choose btn-attach"
                acceptTypes=""
                buttonLabel={i18n.t('updAttach')}
                component={FileSelector}
              />
            </div>
            <div className="col-auto marginBottom">
              <LargeButton
                onClick={openModal.bind(null, 'updateConfirmation')}
                title={i18n.t('updSubmit')}
                type="button"
              />
            </div>
          </div>
        </form>
        <UpdateConfirmationWindow
          modalID="updateConfirmation"
          confirmUpdate={handleSubmit}
          handleOnClose={closeModal.bind(null, 'updateConfirmation')}
        />
      </Fragment>
    );
  }
}

UpdateSMForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'updateSMForm'
})(UpdateSMForm);

const mapStateToProps = createStructuredSelector({
  selectedRows: selectedRowsSelector
});

export default connect(
  mapStateToProps,
  {
    openModalWindow, closeModalWindow
  }
)(form);
