import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

const Preloader = () => (
  <div className="mainPreLoaderParent">
    <div className="mainPreLoader">
      <PuffLoader
        size={70}
        color="#f7bd27"
        speedMultiplier={3}
      />
    </div>
  </div>
);

export default Preloader;
