import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';

/**
 * Components
 * @ignore
 */
import Tooltip from '../../Tooltip';
import CustomSelect from '../CustomSelect';

/**
 * Selectors
 * @ignore
 */
import {
  deviceTagsSelector
} from './selectors';

/**
 * Render TagField
 * @function TagField
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.deviceType one of: inverters, smart-meter, input-devices, ...
 * @param {string} props.formID form id
 * @param {string} props.tags list of tags
 * @param {Function} props.dispatch dispatch
 */
const TagField = (props) => {
  const {
    deviceType,
    dispatch,
    formID,
    tags
  } = props;

  const newTagCreatedCB = (newOption) => dispatch(change(formID, 'createNewTag', newOption));

  return (
    !['input-devices'].includes(deviceType)
   && (
   <div className="form-group m-form__group input-field">
     <div className="flags-select-label">
       {`${i18n.t('tagFieldLabel')}`}
       <div className="switch-tooltip tooltipInSelect">
         <Tooltip title={i18n.t('tagFieldTooltip')} placement="right" />
       </div>
     </div>
     <Field
       name="tag"
       component={CustomSelect}
       placeholder={i18n.t('tagFieldPlaceholder')}
       options={tags}
       className="m-input"
       creatable
       newOptionCreatedCB={newTagCreatedCB}
       maxLength={25}
     />
   </div>
   )
  );
};

TagField.propTypes = {
  deviceType: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
  tags: PropTypes.instanceOf(Array),
  dispatch: PropTypes.func.isRequired
};

TagField.defaultProps = {
  tags: []
};

const mapStateToProps = createStructuredSelector({
  tags: deviceTagsSelector
});

export default connect(mapStateToProps)(TagField);
