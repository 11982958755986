/**
 *
 * @description Fills empty data between last points of the data. Mutates arguments.
 * @param {object} options
 * @param {ChartData} options.data
 * @param {number} options.interval
 * @param {any} options.value
 * @returns {ChartData}
 */
const fillEmptyDataBetweenLastPoints = ({ data, interval, value }) => {
  if (data.length < 2) {
    return data;
  }

  const [from] = data[data.length - 2];
  const [to] = data[data.length - 1];

  const range = Math.round(to - from);

  if (range < interval) {
    return data;
  }

  const numberOfPointsToInsert = Math.floor(range / interval) - 1;
  const desiredInterval = Math.round(range / (numberOfPointsToInsert + 1));

  let count = 0;

  let timestamp = from;

  while (count < numberOfPointsToInsert) {
    timestamp += desiredInterval;

    data.push([timestamp, value]);

    count += 1;
  }

  return data;
};

export default fillEmptyDataBetweenLastPoints;
