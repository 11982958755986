import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  email, required, parseToLowerCase
} from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import Preloader from '../../components/Preloader';
import './index.css';
import { addDataModalSelector, isLoadingSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * Renders add viewer modal window for Viewers page
 * @memberof module:ViewersTable
 */
class AddViewerWindow extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { modal, reset } = this.props;
    const { modal: nextModal } = nextProps;
    if (modal && (!nextModal || (modal.opened && !nextModal.opened))) {
      reset();
    }
  }

  render() {
    const {
      handleOnClose, handleSubmit, modalID, pristine, isLoading
    } = this.props;
    return (
      <ModalWindow
        modalID={modalID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('addViewer')}</h5>
        </div>
        {isLoading ? <div className="viewer-preloader-container"><Preloader /></div> : (
          <div>
            <div className="modal-body">
              <form
                id="addViewer"
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
              >
                <div className="form-group m-form__group input-field">
                  <Field
                    name="email"
                    component={CustomTextInput}
                    label={i18n.t('regMail')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, email]}
                    parse={parseToLowerCase}
                    preventAuto
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form="addViewer"
                disabled={pristine}
                type="submit"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              >
                <i className="fa fa-paper-plane" />
                {i18n.t('send')}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

AddViewerWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  pristine: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

AddViewerWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const form = reduxForm({
  form: 'addViewerForm'
})(AddViewerWindow);

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: addDataModalSelector
});

export default connect(mapStateToProps)(form);
