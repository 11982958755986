import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import Preloader from '../../components/Preloader';
import './index.css';
import i18n from '../../i18n';
import { isLoadingSelector, removeGatewayModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * Renders delete gateway confirmation modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
class DeleteGatewayWindow extends React.Component {
  render() {
    const { handleOnClose, onSubmit, modal: { data }, modalID, isLoading } = this.props;
    return (
      <ModalWindow modalID={modalID}>
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('delGateway')}</h5>
        </div>
        {isLoading ? (<div className="installedsm-delete-prealoder-container"><Preloader /></div>) : (
          <div>
            <div className="modal-body">
              <div className="text-confirmation">{i18n.t('confirmDelGateway')}</div>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={onSubmit.bind(null, { id: data.id })}
              >
                <i className="fa fa-trash" />
                {` ${i18n.t('delete')}`}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

DeleteGatewayWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DeleteGatewayWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: removeGatewayModalSelector
});

export default connect(mapStateToProps)(DeleteGatewayWindow);
