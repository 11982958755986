import { from, of, startWith, endWith } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, finalize } from 'rxjs/operators';

import { handleErrorDetailed } from '../../../api_helper';
import * as actionTypes from '../constants';
import strapi from '../../../strapi';
import i18n from '../../../i18n';

import { addNotification } from '../../NotificationGenerator/actions';
import { closeModalWindow } from '../../ModalWindow/actions';
import {
  setDocumentationFilesLoading,
  setUploadFilesLoading,
  setDocumentationFiles,
  skipAction,
  setFilesForUpload,
  setSelectedFiles
} from './actions';

import { download } from '../helpers';
import analytics from '../../../analytics';
import getEventObj, { USERS_DOCUMENTATION_FILES } from '../../../analytics/events';

function fetchFiles($action) {
  return $action.pipe(
    ofType(actionTypes.GET_DOCUMENTATION_FILES),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(({ userId }) => {
      return from(strapi.request('get', `/users-documentation/files/${userId}`)).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(USERS_DOCUMENTATION_FILES));
        }),
        mergeMap((result) => {
          if (result?.error) {
            return of(addNotification({
              type: 'error',
              text: result.error.message || result.error
            }));
          }

          return of(setDocumentationFiles(result));
        }),
        startWith(setDocumentationFilesLoading(true)),
        endWith(setDocumentationFilesLoading(false))
      );
    }
    )
  );
}

function uploadFiles($action, $state) {
  return $action.pipe(
    ofType(actionTypes.UPLOAD_FILES),
    map((action) => action.payload),
    switchMap(({ userId, files }) => {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));

      return from(strapi.request('post', `/users-documentation/${userId}`, { data: formData })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result?.error) {
            return of(addNotification({
              type: 'error',
              text: result.error.message || result?.error
            }));
          }
          return of(
            setDocumentationFiles([...$state.value.documentation.files, ...result]),
            setFilesForUpload([]),
            addNotification({ type: 'success', text: i18n.t('uploadSuccess') }),
            closeModalWindow(actionTypes.UPLOAD_MODAL_ID)
          );
        }),
        startWith(setUploadFilesLoading(true)),
        endWith(setUploadFilesLoading(false))
      );
    })
  );
}

function downloadFiles($action) {
  return $action.pipe(
    ofType(actionTypes.DOWNLOAD_FILES),
    map((action) => action.payload),
    switchMap(({ filesIds }) => from(strapi.request('get', `/users-documentation/files?filesId=${filesIds.join(',')}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return addNotification({
            type: 'error',
            text: result.error.message || result.error
          });
        }
        result.forEach((url) => download(url));
        return of(skipAction());
      })
    ))
  );
}

function deleteFiles($action, $state) {
  return $action.pipe(
    ofType(actionTypes.DELETE_FILES),
    map((action) => action.payload),

    switchMap(({ filesIds }) => from(strapi.request('delete', `/users-documentation/files?filesId=${filesIds.join(',')}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const { deleteFilesModal = {} } = $state.value.modals;
        const { selectedFiles, files } = $state.value.documentation;

        const updatedFiles = files?.filter((file) => !filesIds.includes(file._id));
        const isNeedToCloseModal = deleteFilesModal.data?.ids?.every((id) => filesIds.includes(id));
        const updatedSelectedFiles = selectedFiles?.filter((file) => !filesIds.includes(file));

        if (result?.error) {
          return of(
            addNotification({
              type: 'error',
              text: result.error.message || result.error
            })
          );
        }

        return of(
          setDocumentationFiles(updatedFiles),
          isNeedToCloseModal ? closeModalWindow(actionTypes.DELETE_FILES_MODAL_ID) : skipAction(),
          addNotification({ type: 'success', text: i18n.t('deleteSuccess') }),
          setSelectedFiles(updatedSelectedFiles)
        );
      })
    ))
  );
}


export default combineEpics(fetchFiles, uploadFiles, downloadFiles, deleteFiles);
