import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import ReactTable from 'react-table';

import i18n from '../../../../i18n';
import { closeModalWindow, modalWindowSend, openModalWindow } from '../../../ModalWindow/actions';
import { addOEMToViewerModalID, dataListIDOEMsForViewer, getInstallersForViewerParams, getOEMSForViewerParams } from '../../constants';
// eslint-disable-next-line import/named
import { AddOEMToViewerWindow, DeleteOEMWindow } from '../../modals';
import DataList, { dataListReloadData } from '../../../DataList';
import { CompanyInfoSub } from '../../../../components/ReactTable/renderFunctions';
import { columnsOEM } from '../../columns';
import { getAllNotAttachedToViewerOems } from '../../actions';

const OEMTab = (props) => {
  const {
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    closeModalWindow: closeModal,
    connectedOemsUrl,
    transformResponse,
    onExpandedChange,
    viewerID,
    expanded
  } = props;
  const columns = useMemo(() => columnsOEM(), []);
  const dispatch = useDispatch();

  // Add OEMs
  const sendAddOEMs = (userIds) => {
    modalSend(
      addOEMToViewerModalID,
      {
        method: 'put',
        url: '/../users/attach-viewer-to-users',
        data: {
          viewerId: viewerID,
          userIds
        }
      },
      () => {
        dispatch(getAllNotAttachedToViewerOems({ viewerId: viewerID, ...getInstallersForViewerParams }));
        return dataListReloadData(dataListIDOEMsForViewer);
      }
    );
  };

  // Delete an OEM
  const sendDeleteOEM = ({ id }) => modalSend(
    'deleteData',
    {
      method: 'put',
      url: '/../users/remove-viewer-from-users',
      data: {
        viewerId: viewerID,
        userIds: [id]
      }
    },
    () => {
      dispatch(getAllNotAttachedToViewerOems({ viewerId: viewerID, ...getInstallersForViewerParams }));
      return dataListReloadData(dataListIDOEMsForViewer);
    }
  );

  return (
    <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
      <div className="pvInstallerAddBtn">
        <a
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
          onClick={openModal.bind(null, addOEMToViewerModalID, null)}
        >
          <span>
            <span>{i18n.t('addOEMbtn')}</span>
          </span>
        </a>
      </div>
      <DeleteOEMWindow
        modalID="deleteData"
        onSubmit={sendDeleteOEM}
        handleOnClose={closeModal.bind(null, 'deleteData')}
      />
      <AddOEMToViewerWindow
        modalID={addOEMToViewerModalID}
        handleSubmit={sendAddOEMs}
        viewerUserId={viewerID}
        handleOnClose={closeModal.bind(null, addOEMToViewerModalID)}
      />
      <DataList
        listID={dataListIDOEMsForViewer}
        listURL={connectedOemsUrl}
        params={{ ...getOEMSForViewerParams }}
        transformResponse={transformResponse}
        Component={ReactTable}
        componentProps={{
          defaultPageSize: 0,
          className: '-highlight m-datatable__table',
          columns,
          SubComponent: CompanyInfoSub,
          manual: true,
          onExpandedChange,
          expanded
        }}
      />
    </div>
  );
};

OEMTab.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  viewerID: PropTypes.string.isRequired,
  connectedOemsUrl: PropTypes.string.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  expanded: PropTypes.instanceOf(Object)
};

OEMTab.defaultProps = {
  expanded: undefined
};

export default connect(null, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(OEMTab);
