import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

class Switcher extends React.Component {
  componentDidMount() {
    const { input, defaultValue } = this.props;
    if (!input.value && typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, input } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      input.onChange(this.props.defaultValue);
    }
  }

  render() {
    const {
      input, ...custom
    } = this.props;

    return (
      <Switch
        checked={input.value}
        onChange={input.onChange}
        {...custom}
      />
    );
  }
}

Switcher.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

Switcher.defaultProps = {
  defaultValue: undefined
};

export default Switcher;
