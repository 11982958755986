import { createSelector } from 'reselect';
import { rolesWithCustomLogo } from './constants';

/**
 * @name houseFuseDefaultValue
 * @constant {Number}
 * @description Return default value If houseFuse equal undefined
 */
const houseFuseDefaultValue = 0;

/**
 * @name loadManagementValue
 * @constant {Boolean}
 * @description Return default value If loadManagement equal undefined
 */
const loadManagementValue = false;

const getUserDevices = (state) => state.userDevices?.user;
const getUser = (state) => state.signIn?.user;
const getGeneralSettingsModal = (state) => state.modals?.generalSettings;
const getSupportContracts = (state, ownProps) => ownProps.user.supportContractFlag;
const getIsInstallationCompleted = (state, ownProps) => ownProps?.user?.gateway?.isInstallationCompleted;
const getIsSupportContractFlagAllowedToBeChanged = (state) => state.userDevices?.isSupportContractFlagAllowedToBeChanged;
const getLastSensorsData = (state) => state.userDevices?.lastSensorsData;
const getUserSubscription = (state) => state?.userDevices?.userSubscription;
const getAllDataLists = (state) => state?.dataList;

// User devices selectors
export const selectedUserSelector = createSelector(
  [getUserDevices],
  (user) => user
);

/**
 * Id of selected user
 */
export const userIdSelector = createSelector(
  [getUserDevices],
  (user) => user?._id
);

export const houseFuseSelector = createSelector(
  [getUser, getUserDevices],
  (user, userDevices) => (user?.role?.type === 'end_user'
    ? user?.settings?.houseFuse
    : userDevices?.settings?.houseFuse) || houseFuseDefaultValue
);

export const loadManagementSelector = createSelector(
  [getUser, getUserDevices],
  (user, userDevices) => (user?.role?.type === 'end_user'
    ? user?.settings?.loadManagement
    : userDevices?.settings?.loadManagement) || loadManagementValue
);

export const supportEmailSubjectSelector = createSelector(
  [getUserDevices, getUser],
  (userDevice, user) => {
    const rolesForShowingCompany = ['pv_installer', 'pv_installer_employee', 'oem'];
    return `SM-Web-Case ${userDevice?.gateway?.sm_id} - ${userDevice?.first_name} ${userDevice?.last_name} ${rolesForShowingCompany.includes(user?.role?.type)
      ? `- ${userDevice?.company?.name}`
      : ''
    }`;
  });

// User selectors
export const userSelector = createSelector(
  [getUser],
  (user) => user
);

export const myRoleTypeSelector = createSelector(
  [getUser],
  (user) => user?.role?.type
);

export const compLogoSelector = createSelector(
  [getUser],
  (user) => user?.company_logo
);

export const visibleWebSelector = createSelector(
  [getUser],
  (user) => (rolesWithCustomLogo.includes(user?.role?.type)
    ? user?.my_company?.visible_web || user?.oem?.visible_web
    : user?.company?.visible_web)
);

// Other selectors
export const generalSettingsModalSelector = createSelector(
  [getGeneralSettingsModal],
  (generalSettings) => generalSettings
);

export const initialValuesSelector = createSelector(
  [getSupportContracts, getIsInstallationCompleted],
  (supportContracts, isInstallationCompleted) => ({
    support_contracts: supportContracts,
    isInstallationCompleted
  })
);

export const isSupportContractFlagAllowedToBeChangedSelector = createSelector(
  [getIsSupportContractFlagAllowedToBeChanged],
  (isSupportContractFlagAllowedToBeChanged) => isSupportContractFlagAllowedToBeChanged
);

export const userSubscriptionSelector = createSelector(
  [getUserSubscription],
  (subscription) => subscription
);

export const lastSensorsDataSelector = createSelector(
  [getLastSensorsData],
  (lastSensorsData) => lastSensorsData
);

export const getDataListSensors = createSelector(
  [getAllDataLists],
  (dataList) => dataList?.sensors
);

export const getSubSensorsFromSensors = createSelector(
  [getDataListSensors],
  (sensors) => sensors?.subSensorsData?.data
);

export const getDataFromSensors = createSelector(
  [getDataListSensors],
  (sensors) => sensors?.data
);
