import { EMPTY, from, of } from 'rxjs';
import { catchError, map, switchMap, delay, mergeMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { combineEpics, ofType } from 'redux-observable';
import { saveAs } from 'file-saver';

import { handleErrorDetailed, handleError } from '../../api_helper';
import strapi from '../../strapi';
import * as UserTypes from './constants';
import {
  receiveUserInfo,
  cancelUserInfo,
  changePrioritySuccess,
  cancelChangePriority,
  devicesSaveTypesAndNames,
  getListOfIP,
  saveIP,
  receiveUserSubscription,
  receiveIsSupportContractFlagAllowedToBeChanged,
  lastSensorsDataReceived,
  getUserInfo
} from './actions';
import { addNotification } from '../NotificationGenerator/actions';
import { dataListReloadData } from '../DataList/actions';
import { closeModalWindow } from '../ModalWindow/actions';
import i18n from '../../i18n';
import { signInCheckJWT } from '../SignIn/actions';
import { SOLAR_PAYMENT_SERVICE } from '../../config';

function getUserInfoEpic($action) {
  return $action.pipe(
    ofType(UserTypes.GET_USER),
    map((action) => action.payload.userId),
    switchMap((userId) => from(strapi.request('get', `/../users/solar-managers-users/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(cancelUserInfo(result.message));
        }

        return of(receiveUserInfo(result));
      })
    )
    )
  );
}

function getIsSupportContractFlagAllowedToBeChangedEpic($action) {
  return $action.pipe(
    ofType(UserTypes.IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED),
    map((action) => action.payload.userId),
    switchMap((userId) => from(
      strapi.request('get', `/../users/${userId}/is-support-contract-flag-allowed-to-be-changed`)
    ).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const { isSupportContractFlagAllowedToBeChanged: isAllow } = result;
        if (typeof isAllow === 'boolean') {
          return of(receiveIsSupportContractFlagAllowedToBeChanged(isAllow));
        }

        return of(receiveIsSupportContractFlagAllowedToBeChanged(false));
      })
    )
    )
  );
}

function changePriorityEpic($action) {
  return $action.pipe(
    ofType(UserTypes.CHANGE_PRIORITY),
    map((action) => action.payload.url),
    switchMap((url) => from(strapi.request('put', url)).pipe(
      catchError(handleErrorDetailed),
      map((result) => (result && !result.error ? changePrioritySuccess() : cancelChangePriority(result))
      )
    )
    )
  );
}

function changePrioritySuccessEpic($action) {
  return $action.pipe(
    ofType(UserTypes.CHANGE_PRIORITY_SUCCESS),
    switchMap(() => of(dataListReloadData(UserTypes.SENSORS_LIST_ID)))
  );
}

function devicesGetTNEpic($action) {
  return $action.pipe(
    ofType(UserTypes.DEVICES_GET_TYPES_AND_NAMES),
    map((action) => action.payload),
    switchMap(({ deviceType, cb }) => from(strapi.request('get', `/../sensor-types/${deviceType}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (!result.error) {
          if (typeof cb === 'function') {
            return of(cb(result), devicesSaveTypesAndNames(result));
          }
          return of(devicesSaveTypesAndNames(result));
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

function scanIPEpic($action) {
  return $action.pipe(
    ofType(UserTypes.SCAN_IP),
    map((action) => action.payload),
    switchMap(({ gateway, device }) => from(
      strapi.request('post', `/account/ip/scan/${gateway}`, {
        data: {
          type: device.type,
          name: device.device_group
        }
      })
    ).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          return getListOfIP(gateway);
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

let getIPAttemptsMade = 0;
function getListOfIPEpic($action, $state) {
  return $action.pipe(
    ofType(UserTypes.GET_LIST_OF_IP),
    map((action) => action.payload),
    switchMap(({ gateway }) => {
      const {
        modals: {
          searchIP: { opened }
        }
      } = $state.value;
      return from(strapi.request('get', `/account/ip/list/${gateway}`)).pipe(
        delay(5000),
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            getIPAttemptsMade += 1;
            if (getIPAttemptsMade > 12) {
              getIPAttemptsMade = 0;
              return of(
                addNotification({
                  type: 'error',
                  text: i18n.t('no IP found')
                }),
                closeModalWindow('searchIP')
              );
            }
            if (isEmpty(result) && opened) {
              return of(getListOfIP(gateway));
            }
            return of(saveIP(result));
          }
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        })
      );
    })
  );
}

function userDataSendRequestEpic($action) {
  return $action.pipe(
    ofType(UserTypes.USER_DATA_SEND_REQUEST),
    map((action) => action.payload),
    switchMap(({ method, url, config, onResult = {}, modalID }) => from(strapi.request(method, url, config)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (typeof onResult.callback === 'function') {
          return of(onResult.callback(result));
        }
        if (typeof onResult.cb === 'function') onResult.cb(result);
        let fileReceived;
        if (onResult.downloadFile) {
          if (result instanceof Blob) {
            fileReceived = true;
            saveAs(result, `sm-manager-${Date.now()}.csv`);
          }
        }

        if (result.ok || !result.error || fileReceived) {
          if (!onResult.successMessage) return EMPTY;
          return of(
            closeModalWindow(modalID),
            addNotification({
              type: 'success',
              text: i18n.t(onResult.successMessage)
            })
          );
        }
        if (!onResult.errorMessage) return EMPTY;
        return of(
          closeModalWindow(modalID),
          addNotification({
            type: 'error',
            text: i18n.t(onResult.errorMessage)
          })
        );
      })
    )
    )
  );
}

function sendSupportContracts(action$) {
  return action$.pipe(
    ofType(UserTypes.CHANGE_SUPPORT_CONTRACTS),
    map((action) => action.payload),
    switchMap(({ userId, supportContractFlag }) => from(
      strapi.request('put', `/../users/support-contract/${userId}`, {
        data: { supportContractFlag }
      })
    ).pipe(
      catchError(handleError),
      map((result) => {
        if (result?.ok === true) {
          return addNotification({
            type: 'success',
            text: i18n.t('success')
          });
        }

        return addNotification({
          type: 'error',
          text: i18n.t('error')
        });
      })
    )
    )
  );
}

function sendInstallationFinished(action$) {
  return action$.pipe(
    ofType(UserTypes.CHANGE_INSTALLATION_FINISHED),
    map((action) => action.payload),
    switchMap(({ gatewayId, isInstallationCompleted }) => from(
      strapi.request('put', `/../gateway/installation-status/${gatewayId}`, {
        data: { isInstallationCompleted }
      })
    ).pipe(
      catchError(handleError),
      map((result) => {
        if (typeof result === 'object') {
          return addNotification({
            type: 'success',
            text: i18n.t('success')
          });
        }

        return addNotification({
          type: 'error',
          text: i18n.t('error')
        });
      })
    )
    )
  );
}

function sendLoadManagement(action$) {
  return action$.pipe(
    ofType(UserTypes.UPDATE_LOAD_MANAGEMENT),
    map((action) => action.payload),
    switchMap(({ loadManagement, houseFuse, myRoleType, userId }) => from(
      strapi.request('put', `/../user/settings/${myRoleType === 'end_user' ? 'my' : userId}`, {
        data: { loadManagement, houseFuse }
      })
    ).pipe(
      catchError(handleError),
      mergeMap((result) => {
        if (!result || result?.error || typeof result === 'string') {
          return of(
            addNotification({
              type: 'error',
              text: i18n.t('error')
            })
          );
        }

        return of(
          myRoleType === 'end_user' ? signInCheckJWT() : getUserInfo(userId),
          addNotification({
            type: 'success',
            text: i18n.t('success')
          })
        );
      })
    )
    )
  );
}

function fetchLastSensorsDataEpic($action) {
  return $action.pipe(
    ofType(UserTypes.FETCH_LAST_SENSORS_DATA),
    map((action) => action.payload),
    switchMap(({ myRoleType, gatewayId }) => from(
      strapi.request('get', `/../data/sensors/${myRoleType === 'end_user' ? 'my' : gatewayId}`)
    ).pipe(
      catchError(handleErrorDetailed),
      map((res) => lastSensorsDataReceived(res))
    )
    )
  );
}

function getUserSubscription($action) {
  return $action.pipe(
    ofType(UserTypes.GET_USER_SUBSCRIPTION),
    map((action) => action.payload.userId),
    switchMap((userId) => (
      from(strapi.request('get', `${SOLAR_PAYMENT_SERVICE}/user/${userId}/web-view-url`)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result || result?.error) {
            return of({ type: 'SKIP' });
          }

          return of(receiveUserSubscription({ webViewUrl: result.webViewUrl, ...(result.subscription || {}) }));
        })
      )
    ))
  );
}

export default combineEpics(
  getUserInfoEpic,
  changePriorityEpic,
  changePrioritySuccessEpic,
  devicesGetTNEpic,
  scanIPEpic,
  getListOfIPEpic,
  userDataSendRequestEpic,
  getUserSubscription,
  sendSupportContracts,
  sendInstallationFinished,
  getIsSupportContractFlagAllowedToBeChangedEpic,
  sendLoadManagement,
  fetchLastSensorsDataEpic
);
