import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, take, concatMap } from 'rxjs/operators';

import { push } from 'react-router-redux';
import { handleError } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import i18n from '../../i18n';

function verifyEmailSendEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.CHANGE_EMAIL_VERIFY),
    map((action) => action.payload),
    take(1),
    concatMap(({ verifyCode }) => (
      from(strapi.request('post', '/../email-verify', {
        data: {
          code: verifyCode
        }
      })).pipe(
        catchError(handleError),
        mergeMap(
          (result) => {
            if (result.ok) {
              return of(
                push('/'),
                addNotification({
                  type: 'success',
                  text: i18n.t('newEmailConfirmed')
                })
              );
            }
            return of(
              push('/'),
              addNotification({
                type: 'error',
                text: result
              })
            );
          }
        )
      )
    ))
  );
}

export default combineEpics(
  verifyEmailSendEpic
);
