import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import SignInForm from './SignInForm';
import { addNotification, deleteNotification } from '../NotificationGenerator/actions';
import { receiveSignIn, signIn } from './actions';
import queryURLFormat from '../../utils/queryURLFormat';
import { getShortLanguageName, transformLanguageForAPI, getFullLanguageName, changeLanguageTo } from '../../i18n';

import '../../App.css';
import './index.scss';

/**
 * @module SignIn
 */
/**
 * @memberof module:SignIn
 * @description renders Sign In page
 * @extends Component
 */
const SignIn = (props) => {
  const {
    signIn: signInProps,
    replace: replaceURL,
    i18n,
    t
  } = props;

  /** Sends request to authorize
   * @param  {object} values - form values to authorize
   */
  const onSubmit = (values) => {
    signInProps({
      ...values,
      language: transformLanguageForAPI(i18n.language)
    });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('l');

  useEffect(() => {
    if (token) {
      const currentFullLanguage = getFullLanguageName(token);
      if (currentFullLanguage === '') {
        replaceURL(window.location.pathname + queryURLFormat({ l: 'de' }));
      } else if (currentFullLanguage !== i18n.language) {
        changeLanguageTo(currentFullLanguage, true);
      }
    } else {
      replaceURL(window.location.pathname + queryURLFormat({ l: getShortLanguageName(i18n.language) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <SignInForm onSubmit={onSubmit} t={t} />;
};

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  replace: PropTypes.instanceOf(Object).isRequired,
  i18n: PropTypes.instanceOf(Object),
  t: PropTypes.instanceOf(Object)
};

SignIn.defaultProps = {
  i18n: {},
  t: undefined
};

export default connect(null, {
  push,
  replace,
  signIn,
  receiveSignIn,
  addNotification,
  deleteNotification
})(SignIn);
