import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import Preloader from '../../components/Preloader';
import './index.css';
import { isLoadingSelector, removeDataModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

class DeleteAdminWindow extends React.Component {
  render() {
    const
      {
        onArchive,
        handleOnClose,
        onSubmit,
        data,
        modalID,
        isLoading
      } = this.props;

    return (
      <ModalWindow
        modalID={modalID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('delAdmin')}</h5>
        </div>
        {isLoading ? <div className="admin-delete-preload-container"><Preloader /></div> : (
          <div>
            <div className="modal-body">
              <div className="text-confirmation">{i18n.t('confirmDelAdmin')}</div>
            </div>
            <div className="modal-footer justify-content-between">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={onSubmit.bind(null, { id: data.id })}
              >
                <i className="fa fa-trash" />
                {i18n.t('delete')}
              </button>
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={onArchive.bind(null, { id: data.id })}
              >
                <i className="fa fa-archive" />
                {i18n.t('archive')}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

DeleteAdminWindow.propTypes = {
  data: PropTypes.instanceOf(Object),
  handleOnClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

DeleteAdminWindow.defaultProps = {
  data: {},
  isLoading: false
};

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  data: removeDataModalSelector
});

export default connect(mapStateToProps)(DeleteAdminWindow);
