import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import analytics from '.';

export default (Component) => {
  const GAHoc = ({ location }) => {
    useEffect(() => {
      analytics.init();
    }, []);

    useEffect(() => {
      analytics.sendPageview(location.pathname + location.search);
    }, [location]);

    return (
      <Component />
    );
  };

  GAHoc.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired
  };

  return withRouter(GAHoc);
};
