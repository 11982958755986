import * as types from '../constants';

export const getDocumentationFiles = (userId) => ({
  type: types.GET_DOCUMENTATION_FILES,
  payload: { userId }
});

export const setDocumentationFilesLoading = (filesLoading) => ({
  type: types.SET_DOCUMENTATION_FILES_LOADING,
  payload: { filesLoading }
});

export const setDocumentationFiles = (files) => ({
  type: types.SET_DOCUMENTATION_FILES,
  payload: { files }
});

export const setSelectedFiles = (selectedFiles) => ({
  type: types.SET_FILES_SELECTION,
  payload: { selectedFiles }
});

export const downloadFiles = (filesIds) => ({
  type: types.DOWNLOAD_FILES,
  payload: { filesIds }
});

export const uploadFiles = (userId, files) => ({
  type: types.UPLOAD_FILES,
  payload: { userId, files }
});

export const setUploadFilesLoading = (isUploadLoading) => ({
  type: types.UPLOAD_FILES_LOADING,
  payload: { isUploadLoading }
});

export const deleteFiles = (filesIds) => ({
  type: types.DELETE_FILES,
  payload: { filesIds }
});

export const setFilesForUpload = (filesForUpload) => ({
  type: types.SET_FILES_FOR_UPLOAD,
  payload: { filesForUpload }
});

export const skipAction = () => ({ type: types.SKIP_ACTION });
