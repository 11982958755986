import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render function for React-Table. Column for load guard - input device description.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {object} props.original.inputDevice - Input device
 * @param {string} props.original.inputDevice.description - Input device description
 */
const renderLoadGuardDescription = ({ original }) => <>{original?.inputDevice?.description}</>;

renderLoadGuardDescription.propTypes = {
  description: PropTypes.string
};

renderLoadGuardDescription.defaultProps = {
  description: ''
};

export default renderLoadGuardDescription;
