/* eslint-disable space-before-function-paren */
import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PuffLoader from 'react-spinners/PuffLoader';

import ModalWindow from '../../../ModalWindow';
import { closeModalWindow, openModalWindow } from '../../../ModalWindow/actions';
import { setSelectedFiles, uploadFiles, setFilesForUpload } from '../../store/actions';
import { filesSelector, filesForUploadSelector, isUploadLoadingSelector } from '../../store/selectors';
import { transformBytesToNiceSize, getSize } from '../../helpers';
import i18n from '../../../../i18n';

import './index.scss';

import { ReactComponent as CloseIco } from '../../../../assets/images/svg/close.svg';
import { MAX_FILE_SIZE, LIMIT_SIZE_MODAL_ID, UPLOAD_MODAL_ID } from '../../constants';

const { Dragger } = Upload;

const UploadModal = ({
  userId,
  setFilesForUpload: setFilesForUploadAction,
  uploadFiles: uploadFilesAction,
  files,
  closeModalWindow: closeModalWindowAction,
  openModalWindow: openModalWindowAction,
  filesForUpload,
  isUploadLoading
}) => {
  const handleResetFile = (uid) => {
    setFilesForUploadAction(filesForUpload.filter((file) => file.uid !== uid));
  };

  const addFiles = async (filesList) => {
    if (getSize(filesList) + getSize(files, 'fileSize') > MAX_FILE_SIZE) {
      openModalWindowAction(LIMIT_SIZE_MODAL_ID);
    } else {
      setFilesForUploadAction(filesList);
    }
  };


  const closeModal = () => {
    closeModalWindowAction(UPLOAD_MODAL_ID);
  };

  const handlerUploadFiles = () => {
    uploadFilesAction(userId, filesForUpload.map(({ originFileObj }) => originFileObj));
  };

  const props = {
    data: null,
    multiple: true,
    fileList: filesForUpload,
    name: 'file',
    showUploadList: false,
    iconRender: () => (
      <div className="upload-icon">
        <i className="fa fa-upload" />
      </div>
    ),
    customRequest: () => null,
    onChange: (info) => addFiles(info.fileList)
  };

  return (
    <ModalWindow modalID={UPLOAD_MODAL_ID}>
      <div className="modal-header upload-modal-header">
        <h5 className="modal-title download-title">{i18n.t('uploadFiles')}</h5>
        <div className="btn-upd-dashb">
          <button
            type="button"
            className="close-btn"
            onClick={closeModal}
          >
            <CloseIco />
          </button>
        </div>
      </div>
      <div className="modal-body upload-modal-body">
        {isUploadLoading ? (
          <div className="mainPreLoaderParent upload-loader">
            <div className="mainPreLoader">
              <PuffLoader
                size={50}
                color="#f7bd27"
                speedMultiplier={3}
              />
            </div>
          </div>
        )
          : (
            <section className="upload-section">
              <Dragger
                className="dragger"
                openFileDialogOnClick={false}
                {...props}
              >
                <ul className="files-list">
                  {filesForUpload.map((file) => (
                    <li key={file.uid}>
                      <span>
                        {file.name}
                        {' '}
                        <span className="file-size">
                          (
                          {' '}
                          {transformBytesToNiceSize(file.size)}
                          {' '}
                          )
                        </span>
                      </span>
                      <div className="btn-upd-dashb">
                        <button
                          type="button"
                          className="close-btn"
                          onClick={() => handleResetFile(file.uid)}
                        >
                          <CloseIco />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="chooseFile-area">
                  <Upload
                    {...props}
                  >
                    <button
                      type="button"
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                      onClick={() => null}
                    >
                      {i18n.t('chooseFile')}
                    </button>
                    <p className="drop-file-text">
                      {' '}
                      {i18n.t('chooseFilesSubtitle')}
                    </p>
                  </Upload>
                  {filesForUpload.length > 0 && (
                    <button
                      type="button"
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn upload-all-btn"
                      onClick={handlerUploadFiles}
                    >
                      {i18n.t('uploadALL')}
                    </button>
                  )}
                </div>
              </Dragger>
            </section>
          )
        }
      </div>
      <div className="upload-modal-footer" />
    </ModalWindow>
  );
};

UploadModal.propTypes = {
  userId: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Array).isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  setFilesForUpload: PropTypes.func.isRequired,
  filesForUpload: PropTypes.instanceOf(Array).isRequired,
  isUploadLoading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
  files: filesSelector,
  filesForUpload: filesForUploadSelector,
  isUploadLoading: isUploadLoadingSelector
});

export default connect(mapStateToProps, {
  setSelectedFiles,
  closeModalWindow,
  openModalWindow,
  uploadFiles,
  setFilesForUpload
})(UploadModal);
