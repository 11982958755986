import * as actionTypes from './constants';

const initialState = {
  isShowPlantColumnForUsers: localStorage.getItem('isShowPlantColumnForUsers') === 'true',
  isShowInstallationDateColumnForUsers: false,
  isShowLastLoginDateForUsers: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_OEMS: {
      return {
        ...state,
        oemsList: action.payload.oems
      };
    }
    case actionTypes.CLEAR_OEMS: {
      return {
        ...state,
        oemsList: null
      };
    }

    case actionTypes.SET_IS_SHOW_PLANT_COLUMN_FOR_USERS: {
      return {
        ...state,
        isShowPlantColumnForUsers: action.payload.isShowPlantColumnForUsers
      };
    }

    case actionTypes.SET_IS_SHOW_LAST_LOGIN_DATE_FOR_USERS: {
      return {
        ...state,
        isShowLastLoginDateForUsers: action.payload.isShowLastLoginDateForUsers
      };
    }

    case actionTypes.SET_IS_SHOW_INSTALLATION_DATE_FOR_USERS: {
      return {
        ...state,
        isShowInstallationDateColumnForUsers: action.payload.isShowInstallationDateColumnForUsers
      };
    }

    case actionTypes.UPDATE_COLUMN_PROPS_FOR_USER: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
