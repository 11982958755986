import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../components/Avatar';
import i18n from '../../../../i18n';
/**
 * Displays user avatar and avatar editing field with preview for setting profile
 * @memberof module:SettingProfile
 */
const AvatarPreviewer = (props) => {
  const { input: inputFromProps, avatar, userName, disabled } = props;
  const [imagePreviewUrl, setImagePreviewUrl] = useState(false);
  const fileInput = useRef();

  const _handleImageChange = (e, input) => {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      input.onChange(file);
    };
    reader.readAsDataURL(file);
  };

  const emulateClick = (e) => {
    fileInput.current.click(e);
  };

  return (
    <Fragment>
      <div className="row justify-content-center">
        <Avatar
          className="big"
          name={userName}
          avatar={avatar}
          background={imagePreviewUrl}
        />
      </div>
      <div className="row justify-content-center">
        <input
          className="fileInput"
          type="file"
          accept="image/jpeg,image/png,image/gif,image/bmp,image/x-windows-bmp,image/tiff,image/x-tiff"
          onChange={(e) => _handleImageChange(e, inputFromProps)}
          ref={fileInput}
          hidden
        />
        {!disabled
            && (
            <button
              type="button"
              className="photo-ch col-auto"
              onClick={emulateClick.bind(this)}
            >
              {i18n.t('changePhoto')}
            </button>
            )
          }
      </div>
    </Fragment>
  );
};

AvatarPreviewer.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  userName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

AvatarPreviewer.defaultProps = {
  disabled: undefined,
  avatar: false
};

export default AvatarPreviewer;
