import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import './index.scss';
import i18n from '../../i18n';
import Footer from '../../components/Footer';

/**
 * Renders Not found page
 */
const NotFound = () => (
  <div className="m-grid m-grid--hor m-grid--root m-page notFoundPage">
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1" id="m_login">

      <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6">
        <div className="m-error_container">
          <div className="m-error_subtitle m--font-light">
            <div className="errorCode">404</div>
            <div className="errorHeader">{i18n.t('oops')}</div>
          </div>
          <p className="m-error_description m--font-light">
            {i18n.t('wrongPath')}
          </p>
          <Link
            to="/"
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
          >
            {i18n.t('backHomebtn')}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  </div>
);

export default translate()(NotFound);
