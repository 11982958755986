export const GET_NOT_ATTACHED_TO_VIEWER_END_USERS = 'GET_NOT_ATTACHED_TO_VIEWER_END_USERS';
export const SET_NOT_ATTACHED_TO_VIEWER_END_USERS = 'SET_NOT_ATTACHED_TO_VIEWER_END_USERS';
export const SET_ALL_ATTACHED_TO_VIEWER_INSTALLERS = 'SET_ALL_ATTACHED_TO_VIEWER_INSTALLERS';
export const GET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS = 'GET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS';
export const SET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS = 'SET_ALL_NOT_ATTACHED_TO_VIEWER_INSTALLERS';
export const GET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS = 'GET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS';
export const SET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS = 'SET_ALL_NOT_ATTACHED_TO_VIEWER_OEMS';
export const GET_OEMS_FOR_VIEWER_LIST = 'GET_OEMS_FOR_VIEWER_LIST';
export const SET_OEMS_FOR_VIEWER_LIST = 'SET_OEMS_FOR_VIEWER_LIST';
export const GET_PV_INSTALLERS_FOR_VIEWER_LIST = 'GET_PV_INSTALLERS_FOR_VIEWER_LIST';
export const SET_PV_INSTALLERS_FOR_VIEWER_LIST = 'SET_PV_INSTALLERS_FOR_VIEWER_LIST';
export const GET_OEMS_LIST_FOR_VIEWER = 'GET_OEMS_LIST_FOR_VIEWER';
export const SET_OEMS_LIST_FOR_VIEWER = 'SET_OEMS_LIST_FOR_VIEWER';
export const GET_PV_INSTALLERS_FOR_VIEWER_ALL_LIST = 'GET_PV_INSTALLERS_FOR_VIEWER_ALL_LIST';
export const SET_LOADING = 'SET_LOADING';

export const addEndUsersToViewerModalID = 'addEndUsersToViewer';
export const addEndUsersToViewerForm = 'addEndUsersToViewerForm';
export const addPVInstallersToViewerModalID = 'addPVInstallersToViewer';
export const addPVInstallersToViewerForm = 'addPVInstallersToViewerForm';
export const addOEMToViewerModalID = 'addOEMToViewer';
export const addOEMToViewerForm = 'addOEMToViewerForm';
export const dataListIDViewer = 'currentViewer';
export const dataListIDEndUsersForViewer = 'viewersEndUsers';
export const dataListIDPVInstallersForViewer = 'viewersPVInstallers';
export const dataListIDOEMsForViewer = 'viewersOEMs';

export const dataListIDTabsForViewer = [
  dataListIDEndUsersForViewer,
  dataListIDPVInstallersForViewer,
  dataListIDOEMsForViewer
];

export const getViewerParams = {
  _start: 0,
  _sort: 'first_name:asc'
};

export const getEndUsersForViewerParams = {
  _start: 0,
  _sort: 'smid:asc'
};

export const getInstallersForViewerParams = {
  _start: 0,
  _sort: 'company:asc'
};

export const getOEMSForViewerParams = {
  _start: 0,
  _sort: 'oemCompany:asc'
};

export const selectAllCheckbox = 'selectAll';
