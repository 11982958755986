import React, { memo, useEffect, useState, useMemo } from 'react';
import { MenuItem, ListItemText, Checkbox, FormControl, Select } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import i18n from '../../../../../i18n';
import { SELECT_ALL, SELECT_DAYS, SELECT_DAYS_ERROR, SUNDAY } from '../../constants';
import { getSelectAll, getAllDaysWithoutSelectAll, getActiveDaysOptions } from '../../helper';
import ShowErrorValidation from '../ShowErrorValidation';

import './index.scss';

const SelectDays = memo((props) => {
  const {
    placeholder,
    input: {
      value: options,
      onChange
    },
    meta: {
      touched,
      error
    },
    languageToSync,
    forceError
  } = props;

  /**
   * @returns {Array<object>} return memoize list of objects from numbers
   */
  const daysOptions = useMemo(() => getActiveDaysOptions(options), [options]);

  const [days, setDays] = useState([
    {
      value: daysOptions?.every(({ value }) => value),
      key: SELECT_ALL,
      label: i18n.t(SELECT_ALL)
    },
    ...daysOptions
  ]);

  /**
   * When days|daysOptions|onChange changes
   * check if array of numbers from days is not equal to daysOptions array of numbers
   * and set new 'activeDays' into Redux field
   */
  useEffect(() => {
    const pureValues = days.filter((option) => option.key !== SELECT_ALL)
      .map(({ value }) => Number(value));

    const purePrevValues = daysOptions.map(({ value }) => Number(value));

    if (!isEqual(purePrevValues, pureValues)) {
      onChange(pureValues);
    }
  }, [days, onChange, daysOptions]);

  /**
   * Render active days options list
   * @param {array} checked array objects of days, example [..., { label: Monday, value: true, key: 'f3c2c9e2-e86d-4fa5-8236-85785318a1f9'}]
   * @returns {function} return html code with list of first 3 letters days or placeholder
   */
  const renderContentUsingChangedValue = (checked) => {
    if (checked.every(({ value }) => value === true)) {
      return <div className="days-week">{placeholder}</div>;
    }

    if (checked.every(({ value }) => value === false)) {
      return <div className="select-days">{i18n.t(SELECT_DAYS)}</div>;
    }

    return (
      <div className="days-week">
        {checked.reduce((acm, { label, value }) => (value === true ? (`${acm} ${i18n.t(label).slice(0, 3)}`) : acm), '')}
      </div>
    );
  };


  /**
   * Set new value for 'days' state
   * @param {object} event event
   * @returns {Array<object>} return updated days
   */
  const setData = ({ target: { name: checkboxName } }) => {
    setDays((prevDays) => {
      // eslint-disable-next-line arrow-body-style
      const updatedDays = prevDays.map((option) => {
        return option.key === checkboxName
          ? { ...option, value: !option.value }
          : { ...option };
      });

      const optionSelectAll = getSelectAll(updatedDays);
      const getAllDaysWithoutOptionSelectAll = getAllDaysWithoutSelectAll(updatedDays);

      const someOfDaysIsFalse = getAllDaysWithoutOptionSelectAll.some(({ value }) => !value);
      const someOfDaysIsTrue = getAllDaysWithoutOptionSelectAll.some(({ value }) => value);

      const everyOfDaysIsTrue = getAllDaysWithoutOptionSelectAll.every(({ value }) => value);

      const setBoolForSelectAll = (boolStatus) => [
        {
          ...optionSelectAll,
          value: boolStatus
        },
        ...getAllDaysWithoutOptionSelectAll
      ];

      /**
       * Checks if 'selectAll' option is checked, if it is checked then check all checkboxes
       */
      if (checkboxName === SELECT_ALL) {
        return updatedDays.map((day) => ({ ...day, value: optionSelectAll.value }));
      }

      /**
       * Checks if 'selectAll' is checked and some of the days are unchecked, if so then set 'selectAll' unchecked
       */
      if (someOfDaysIsTrue && someOfDaysIsFalse && optionSelectAll.value && checkboxName !== SELECT_ALL) {
        return setBoolForSelectAll(false);
      }

      /**
       * Checks if 'selectAll' is unchecked and all days are checked, if so then set 'selectAll' to true
       */
      if (everyOfDaysIsTrue && !optionSelectAll.value) {
        return setBoolForSelectAll(true);
      }

      return updatedDays;
    });
  };

  return (
    <FormControl className="days-form-control">
      <Select
        name="activeDays"
        value={days || []}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 138
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          getContentAnchorEl: null,
          classes: {
            paper: 'days-select-menu-paper',
            root: 'days-select-menu-root'
          }
        }}
        SelectDisplayProps={{
          style: {
            borderBottom: '1px solid #5e6774',
            borderRadius: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 0
          }
        }}
        displayEmpty
        multiple
        renderValue={renderContentUsingChangedValue}
      >
        {days.map(({ label, value, key }) => (
          <div key={key}>
            <MenuItem
              classes={{
                root: 'days-menu-item'
              }}
              disableTouchRipple
              value={label}
            >
              <Checkbox
                onChange={setData}
                name={key}
                disableRipple
                disableTouchRipple
                checked={value}
                className="days-checkbox"
              />
              <ListItemText className="days-list-item" primary={i18n.t(label)} />
            </MenuItem>
            {label !== i18n.t(SUNDAY) && <hr className="days-menu-item__HR" />}
          </div>
        ))}
      </Select>
      <ShowErrorValidation
        textOfError={i18n.t(SELECT_DAYS_ERROR)}
        validations={touched && (forceError || error || languageToSync)}
      />
    </FormControl>
  );
});

SelectDays.propTypes = {
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  languageToSync: PropTypes.string,
  forceError: PropTypes.string
};

SelectDays.defaultProps = {
  forceError: '',
  languageToSync: ''
};

export default SelectDays;
