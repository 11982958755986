import * as actionTypes from './constants';

export function sendIOTCommand(sensor_id) { // epic
  return {
    type: actionTypes.SEND_OIT_COMMAND,
    payload: {
      sensor_id
    }
  };
}
