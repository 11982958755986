import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/**
 * Components
 * @ignore
 */
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import { CancelButton } from '../../../components/UIKit';
import { LongCheckboxArr } from '../../../components/LongCheckboxArr';

/**
 * Actions
 * @ignore
 */
import {
  getAllInstallers,
  getAllConnectedInstallers,
  setAllInstallers
} from '../actions';
import {
  modalStartLoading,
  modalStopLoading
} from '../../ModalWindow/actions';

/**
 * Selectors
 * @ignore
 */
import {
  filteredInstallersSelector,
  listOfOEMsFromTableSelector,
  modalAddPvInstallerSelector,
  userRoleTypeSelector,
  isLoadingSelector
} from '../selectors';

import {
  addPvInstallerToOEM,
  GET_INSTALLERS_PARAMS,
  addPvInstallerToOEMForm,
  getConnectedInstallersRequestData
} from '../constants';

import i18n from '../../../i18n';
import '../index.css';

/**
 * Renders add PV installers to ORM modal window for PvInstallers page
 * @memberof module:PvInstallers
 */
const AddPVInstallerToOEMWindow = (props) => {
  const {
    listOfOEMsFromTable,
    filteredInstallers,
    handleOnClose,
    companyOemId,
    supportOemId,
    handleSubmit,
    myRoleType,
    isLoading,
    modalID,
    modal
  } = props;

  const dispatch = useDispatch();

  const [checkedFields, setCheckedFields] = useState([]);

  const {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS,
    URL
  } = useMemo(() => getConnectedInstallersRequestData({ myRoleType, supportOemId, companyOemId }), [companyOemId, myRoleType, supportOemId]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = checkedFields.reduce((acc, point) => (point.isChecked ? [...acc, point._id] : acc), []);
    handleSubmit(dataForReq);
  };

  const handleChange = useCallback((value) => {
    setCheckedFields((prev) => {
      const copy = [...prev];
      const indexOfPoint = copy.findIndex((point) => point._id === value);

      copy[indexOfPoint] = {
        ...copy[indexOfPoint],
        isChecked: !copy[indexOfPoint].isChecked
      };
      return copy;
    });
  }, []);

  useEffect(() => {
    dispatch(getAllInstallers(URL, GET_INSTALLERS_PARAMS));
    dispatch(getAllConnectedInstallers(GET_CONNECTED_INSTALLERS_URL, GET_CONNECTED_INSTALLERS_PARAMS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfOEMsFromTable]);

  useEffect(() => {
    if (!modal.opened && checkedFields.length) {
      setCheckedFields([]);
    }

    if (filteredInstallers.length) {
      setCheckedFields(filteredInstallers);
    }
    if (modal.opened) {
      if (!checkedFields.length) {
        dispatch(modalStartLoading(addPvInstallerToOEM));
      } else if (isLoading) {
        dispatch(modalStopLoading(addPvInstallerToOEM));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInstallers, checkedFields.length, isLoading, modal.opened]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setAllInstallers([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedInstallers = checkedFields.filter((point) => point.isChecked === true).length;

  return (
    <ModalWindow
      customClass="max-width-550"
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addInstaller')}</h5>
      </div>
      {isLoading ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body modal-add-installer-to-oem">
            <form
              id={addPvInstallerToOEM}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <LongCheckboxArr
                formId={addPvInstallerToOEMForm}
                handleChange={handleChange}
                noDataText="NoPVinstaller"
                checkedData={checkedFields}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              <span>
                {i18n.t('Selected')}
              </span>
              <span>
                {': '}
                {selectedInstallers}
              </span>
            </p>
            <div style={{ display: 'flex' }}>
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={addPvInstallerToOEM}
                disabled={!selectedInstallers}
                type="submit"
                className="ml-2 btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav installer-to-oem-btn"
              >
                {i18n.t('add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddPVInstallerToOEMWindow.propTypes = {
  companyOemId: PropTypes.string.isRequired,
  supportOemId: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  filteredInstallers: PropTypes.instanceOf(Array),
  myRoleType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  listOfOEMsFromTable: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool
};

AddPVInstallerToOEMWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false,
  listOfOEMsFromTable: [],
  filteredInstallers: []
};

const form = reduxForm({
  form: addPvInstallerToOEMForm
})(AddPVInstallerToOEMWindow);

const mapStateToProps = createStructuredSelector({
  myRoleType: userRoleTypeSelector,
  modal: modalAddPvInstallerSelector,
  filteredInstallers: filteredInstallersSelector,
  listOfOEMsFromTable: listOfOEMsFromTableSelector,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps)(form);
