import { createSelector } from 'reselect';

const getModal = (state) => state.modals?.addData;
const getIsLoading = (state) => state.modals?.isLoading;
const getModalData = (state) => state.modals?.removeData;
const getUser = (state) => state.signIn?.user;

export const modalSelector = createSelector([getModal], (modal) => modal);
export const modalDataSelector = createSelector([getModalData], (modalData) => modalData);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const compLogoSelector = createSelector([getUser], (user) => user?.company_logo);
export const visibleWebSelector = createSelector([getUser], (user) => user?.my_company?.visible_web);
