import React from 'react';
import PropTypes from 'prop-types';
import 'chartjs-adapter-moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import {
  chartDataSelector,
  houseFuseSelector,
  loadingSelector,
  smartMeterPhasesDataSelector,
  timezoneSelector,
  yAxisMaxSelector,
  yAxisMinSelector
} from '../selectors';

import i18n from '../../../i18n';
import '../index.scss';

Chart.register(zoomPlugin);

const chartGridColor = '#353535';
const ticksColor = '#a7a8b2';
const noDataLabelColor = 'rgba(255, 255, 255, .5)';
const houseFuseLineColor = '#3998F9';
const houseFuseLabelColor = '#3998F9';
const phase1Color = '#FF9E22';
const phase2Color = '#00F6E6';
const phase3Color = '#99BD68';

const LoadManagementDashboardContainer = (props) => {
  const {
    houseFuse,
    currentValues,
    chartData,
    loading,
    zoomHandler,
    yAxisMax,
    yAxisMin
  } = props;

  const noData = {
    beforeDraw(chart) {
      if (chart.data.datasets[0].data.every((item) => item === 0)) {
        const {
          ctx,
          chartArea: { width, height }
        } = chart;
        ctx.save();

        ctx.font = '20px Poppins';
        ctx.fillStyle = noDataLabelColor;
        ctx.fillText(i18n.t('chartsNoData'), width / 1.5, height / 2);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.restore();
      }
    }
  };

  const plotLine = {
    id: 'plotLine',
    afterDraw(chart, _args, options) {
      if (houseFuse) {
        const {
          ctx,
          chartArea: { right, left, width },
          scales: { y }
        } = chart;
        ctx.save();

        ctx.strokeStyle = houseFuseLineColor;
        ctx.lineWidth = 3;
        ctx.setLineDash([10, 10]);
        ctx.strokeRect(left, y.getPixelForValue(houseFuse), width, 0);
        ctx.strokeRect(left, y.getPixelForValue(houseFuse * -1), width, 0);
        ctx.restore();

        if (options.isShowLabel) {
          ctx.font = '500 16px Poppins';
          ctx.fillStyle = houseFuseLabelColor;
          ctx.strokeStyle = '#25272DB0';
          ctx.lineWidth = 20;
          ctx.strokeRect(right - 120, y.getPixelForValue(houseFuse) - 14, width, 0);
          ctx.fillText(i18n.t('houseFuseLabel'), right - 10, y.getPixelForValue(houseFuse) - 7);
          ctx.strokeStyle = '#25272DB0';
          ctx.lineWidth = 20;
          ctx.strokeRect(right - 120, y.getPixelForValue(houseFuse * -1) - 14, width, 0);
          ctx.fillText(i18n.t('houseFuseLabel'), right - 10, y.getPixelForValue(houseFuse * -1) - 7);
          ctx.textAlign = 'right';
          ctx.restore();
        }
      }
    }
  };

  const ChartData = [
    {
      name: i18n.t('phase1'),
      data: chartData?.L1,
      color: phase1Color
    },
    {
      name: i18n.t('phase2'),
      data: chartData?.L2,
      color: phase2Color
    },
    {
      name: i18n.t('phase3'),
      data: chartData?.L3,
      color: phase3Color
    }
  ];

  return (
    <div className="load-management-dashboard-container">
      <div className="bar-chart">
        <Bar
          data={{
            labels: [i18n.t('phase1'), i18n.t('phase2'), i18n.t('phase3')],
            datasets: [
              {
                data: [currentValues?.currentL1, currentValues?.currentL2, currentValues?.currentL3],
                backgroundColor: [phase1Color, phase2Color, phase3Color]
              }
            ]
          }}
          plugins={[plotLine, noData]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                enabled: false
              },
              legend: {
                display: false
              },
              plotLine: {
                isShowLabel: false
              }
            },
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                  drawOnChartArea: false,
                  drawTicks: false
                },
                ticks: {
                  color: [phase1Color, phase2Color, phase3Color],
                  padding: 10,
                  font: {
                    size: 16,
                    weight: 500,
                    family: 'Poppins'
                  }
                }
              },
              y: {
                ticks: {
                  color: ticksColor,
                  padding: 10,
                  font: {
                    size: 16,
                    weight: 400,
                    family: 'Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif'
                  }
                },
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                  color() {
                    return chartGridColor;
                  }
                },
                min: ((houseFuse * -1) <= yAxisMin) ? Math.ceil((houseFuse + 10) / 10) * -10 : yAxisMin,
                max: (houseFuse >= yAxisMax) ? Math.ceil((houseFuse + 10) / 10) * 10 : yAxisMax
              }
            }
          }}
        />
      </div>
      <div className="line-chart">
        <Line
          data={{
            labels: [],
            datasets: [
              {
                borderColor: ChartData[0].color,
                data: ChartData[0].data
              },
              {
                borderColor: ChartData[1].color,
                data: ChartData[1].data
              },
              {
                borderColor: ChartData[2].color,
                data: ChartData[2].data
              }
            ]
          }}
          options={{
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            elements: {
              point: {
                radius: 0
              }
            },
            plugins: {
              tooltip: {
                enabled: false
              },
              legend: {
                display: false
              },
              plotLine: {
                isShowLabel: true
              },
              zoom: {
                zoom: {
                  mode: 'x',
                  drag: {
                    enabled: true
                  },
                  onZoom: ({ chart }) => zoomHandler(chart.scales.x)


                }
              }
            },
            scales: {
              x: {
                ticks: {
                  maxTicksLimit: 12,
                  padding: 10,
                  maxRotation: 0,
                  minRotation: 0,
                  color: ticksColor,
                  font: {
                    size: 16,
                    weight: 400,
                    family: 'Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif'
                  },
                  callback(value, index, values) {
                    if (value === '00:00') {
                      return moment(values[index].value).format('DD.MMM');
                    }

                    return value;
                  }
                },
                type: 'time',
                time: {
                  displayFormats: {
                    hour: 'HH:mm',
                    minute: 'HH:mm',
                    day: 'DD.MMM'
                  }
                },
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                  color() {
                    return chartGridColor;
                  }
                }
              },
              y: {
                ticks: {
                  display: false
                },
                grid: {
                  drawBorder: false,
                  drawTicks: false,
                  color() {
                    return chartGridColor;
                  }
                },
                min: ((houseFuse * -1) <= yAxisMin) ? Math.ceil((houseFuse + 10) / 10) * -10 : yAxisMin,
                max: (houseFuse >= yAxisMax) ? Math.ceil((houseFuse + 10) / 10) * 10 : yAxisMax
              }
            }
          }}
          plugins={[plotLine, noData]}
        />
        <div className={loading ? 'loadingTextWithBG loading' : 'loadingTextWithBG'}>
          {i18n.t('load')}
          ...
        </div>
      </div>
    </div>
  );
};

LoadManagementDashboardContainer.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  currentValues: PropTypes.instanceOf(Object).isRequired,
  chartData: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  zoomHandler: PropTypes.func.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  yAxisMin: PropTypes.number.isRequired
};

const mapStateToProps = createStructuredSelector({
  currentValues: smartMeterPhasesDataSelector,
  chartData: chartDataSelector,
  loading: loadingSelector,
  houseFuse: houseFuseSelector,
  timezone: timezoneSelector,
  yAxisMax: yAxisMaxSelector,
  yAxisMin: yAxisMinSelector
});

export default connect(mapStateToProps)(LoadManagementDashboardContainer);
