import { hideIPField } from './constants';

/**
 * Relation devices with additional fields
 * @constant
 * @memberof module:UserDevices
 * @type {Object}
 */
export const fieldsForDevices = {

  // Inverters

  'ABB EQmatic Inv': [
    'tokenEQmatic',
    'SerialNumberSmartMeter',
    'invertMeasurement'
  ],
  'Fronius Symo': ['Device_ID'],
  'SolarEdge SE': [
    'Device_ID',
    'port'
  ],
  'SMA STP SUNNY TRIPOWER': ['Device_ID'],
  'SMA TRIPOWER SE': ['Device_ID'],
  'SMA Tripower Bat': [
    'Device_ID'
  ],
  'SolarMax S and MT': ['Device_ID'],
  'EM300 Production measurement': ['invertMeasurement'],
  'smart-me Cloud Inv': [
    'User',
    'Password',
    'SerialNumber',
    'invertMeasurement',
    hideIPField
  ],
  'AskomaSM Inv': [
    'Device_ID_RTU',
    'invertMeasurement',
    hideIPField
  ],
  'AskomaSM Inv2': [
    'Device_ID_RTU',
    'invertMeasurement',
    hideIPField
  ],
  'AskomaSM Inv3': [
    'Device_ID_RTU',
    'invertMeasurement',
    hideIPField
  ],
  'Shelly 3EM Inv': ['invertMeasurement'],
  'Shelly 1PM Inv': ['invertMeasurement'],
  'Schneider PowerTag Link Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'ABB UNO/PVI/TRIO': ['Device_ID'],
  'my-PV Power Meter Inv': [
    'invertMeasurement',
    'unlocked',
    'serialnumberMyPv'
  ],
  'Carlo Gavazzi EM24 Inv': ['invertMeasurement'],
  'Huawei SUN2000': ['Device_ID'],
  'MyStrom Switch Inv': ['invertMeasurement'],
  'Fronius SM Inv': ['Device_ID'],
  'Sungrow SH5.0-10RT': ['Device_ID'],
  'SungrowSG Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'Studer Next3 Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'Studer Next3 SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SMA Datamanger Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'MUCEasy SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SungrowSG SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Phoenix Contact EM3x SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Phoenix Contact EM3x EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'SMA Energy Meter 20 SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SMA Sunny Home SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SMA Datamanger SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'EMU M-Bus Center Inv': [
    'emuCenterSmartMeterType',
    'Device_ID',
    'invertMeasurement'
  ],
  'EMU PROF Inv': [
    'invertMeasurement'
  ],
  'E3DC Production measurement': ['Device_ID'],
  Goodwe: ['Device_ID'],
  'SolarLog Base Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'Socomec Countis Inv': ['Device_ID', 'invertMeasurement'],
  'Siemens PAC3200 Inv': [
    'invertMeasurement'
  ],
  'ZZZ Inv': [
    'Device_ID',
    'invertMeasurement'
  ],
  'Sofar HYD Inv': ['Device_ID', 'invertMeasurement', hideIPField],
  'Sofar KTLX-G3Inv': ['Device_ID', 'invertMeasurement', hideIPField],
  'SolarLogPRO380 Inv': [
    'Device_ID',
    'invertMeasurement',
    hideIPField
  ],
  'KElectric KE-P-80 Inv': [
    'invertMeasurement'
  ],

  // Smart Meters

  'Fronius Smart Meter': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Fronius Smart Meter 3P': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SolarEdge Energy Meter': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Expert Net Control 2312-1': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  EmonCmsSmartMeter: [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Eastron SDM630': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'B-control EM300': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'ordonet SDM630-2T': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  'smart-me Cloud': [
    'User',
    'Password',
    'SerialNumber',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  'CLEMAP ONE': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Carlo Gavazzi EM24': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'my-PV Power Meter': [
    'SmartMeterPosition',
    'invertMeasurement',
    'unlocked',
    'serialnumberMyPv'
  ],
  'my-PV Power Meter Wifi SM': [
    'SmartMeterPosition',
    'invertMeasurement',
    'unlocked',
    'serialnumberMyPv'
  ],
  AskomaSM: [
    'Device_ID_RTU',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  AskomaSM2: [
    'Device_ID_RTU',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  AskomaSM3: [
    'Device_ID_RTU',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  AskomaSMTCP: [
    'Device_ID_RTU',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'KOSTAL EM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Shelly 3 EM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Socomec Countis E27/E47': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'EMU M-Bus Center': [
    'emuCenterSmartMeterType',
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'EMU PROFESSIONAL': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Huawei SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SMA Energy Meter': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Siemens PAC3200': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Victron Energy Venus SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'neoom KJUUBE SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SolarLog Base': [
    'Device_ID',
    'invertMeasurement'
  ],
  OptecSM: [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  'Janitza UMG 604': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Carlo Gavazzi EM3xx RTU': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'ABB EQmatic': [
    'tokenEQmatic',
    'SerialNumberSmartMeter',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'ZZZ SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'UPM 209 SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Sofar HYD SM': ['Device_ID', hideIPField],
  'hbTec SM': [
    'port',
    'Device_ID',
    'User',
    'Password',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'hbTec Inv': [
    'port',
    'Device_ID',
    'User',
    'Password',
    'invertMeasurement'
  ],
  'Schneider PowerTag Link': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SolarLogPRO380 SM': [
    'Device_ID',
    'SmartMeterPosition',
    'invertMeasurement',
    hideIPField
  ],
  'KElectric KE-P-80 SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'Varta Storage SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],

  // Water Heaters
  'ASKOHEAT SM': [
    'storageMedium',
    'storageVolume',
    'installLocation',
    'legionellaProtection',
    'legionellaSensor',
    'targetTemp',
    'enableLowTariff',
    'targetTempLowTariff',
    'lowTarriffStartHourMin',
    'lowTariffEndHourMin',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'MyPV ELWA-E': [
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'myPV AC THOR': [
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'EGO Smart Heater Ethernet': [
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'ZZZ WW': [
    'switchingTimesEnable',
    'switchingTimes'
  ],
  Ohmpilot: [
    'ohmpilotMode',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  ShellyPro4PM_2Step: [
    'minPower2',
    'relaisChooseSet',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  WUT_IO_0_10V: [
    'Password',
    'outputChannel',
    'minPower2',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  WUT_IO_4_20mA: [
    'Password',
    'outputChannel',
    'minPower2',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  '2StepControl': [
    hideIPField,
    'warmWaterDevices2Step',
    'ip',
    'minPower2',
    'relaisChooseSet',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  '1StepOnOff': [
    hideIPField,
    'warmWaterDevices',
    'ip',
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  '3StepControl': [
    hideIPField,
    'warmWaterDevices3Step',
    'ip',
    'minPower2',
    'relaisChooseSet2',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Ochsner Europe': [
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes',
    hideIPField
  ],

  // Car Chargers

  'KEBA Wallbox P30': [
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'minPower',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'go-eChargerH': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'go-eChargerH2': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Juice Charger 2': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Etrel Inch Home': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Wallbe Eco 2.0': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Alfen EVE Single': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Alfen EVE Double': [
    'Device_ID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'easee Home': [
    'User',
    'Password',
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  Zaptec: [
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  'ABB Terra AC': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Weidmueller Business': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  PhoenixCharX: [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Schneider EVlinkPro': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Vestel EVC04': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  NRGkick: [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Mennekes Compact': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  'OCPP Charger': [
    'OcppUrl',
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  'Webasto Unite': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  AmtronPremium: [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  AmtronProfessional: [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  WallbePro: [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Juice Charger me': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Juice Charger 3': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Webasto Live': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Garo Wallbox GLB': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Chargeamps Charger': [
    'User',
    'Password',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  'Schneider EVlink': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'ZZZ Car': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'phaseSwitch',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Optec GLB Plus': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Optec GLB': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Optec ecs M3PRO SM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Optec ecs M3PRO Inv': [
    'invertMeasurement'
  ],
  'smart-me Pico': [
    'User',
    'Password',
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],
  Compleo: [
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'Zaptec Go': [
    'ChargerID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalChargingDuration',
    'carType',
    'maxCurrent',
    'installationLocation',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting',
    hideIPField
  ],


  // V2X

  'EVTEC sospeso': [
    'chargingCableNr',
    'maxDischargePower',
    'minimalSocAvailable',
    'maximalSocAvailable',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],
  'ZZZ BiCar': [
    'minimalSocAvailable',
    'maximalSocAvailable',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingMode',
    'constantCurrentSetting'
  ],

  // Car Charging System

  'easee System': [
    'User',
    'Password',
    'chargerIDMaster',
    'maximumCurrent',
    'enableReducedCurrent',
    'reducedCurrent',
    'reducedCurrentStart',
    'reducedCurrentEnd',
    'solarOptimized',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'Zaptec System': [
    'chargerIDMaster',
    'maximumCurrent',
    'enableReducedCurrent',
    'reducedCurrent',
    'reducedCurrentStart',
    'reducedCurrentEnd',
    'solarOptimized',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'Alfen SCN': [
    'maximumCurrent',
    'enableReducedCurrent',
    'reducedCurrent',
    'reducedCurrentStart',
    'reducedCurrentEnd',
    'solarOptimized',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'ZZZ System': [
    'User',
    'Password',
    'chargerIDMaster',
    'maximumCurrent',
    'enableReducedCurrent',
    'reducedCurrent',
    'reducedCurrentStart',
    'reducedCurrentEnd',
    'solarOptimized',
    'switchingTimesEnable',
    'switchingTimes'
  ],

  // Heat Pumps

  'Alpha Innotec Luxtronic 2.0': [
    'minPower',
    'powerEstimation',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Alpha Innotec Luxtronic 2.1': [
    'minPower',
    'powerEstimation',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready mit GUDE 2302': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'relaisChooseSet',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Heliotherm RCG X': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Stiebel Eltron ISG Web': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'deviceTypeStiebel',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'roomTempOverproductionEnable',
    'roomTempOverproduction',
    'roomTempNormal',
    'roomTempReduced',
    'roomTempOverproductionEnableHk2',
    'roomTempOverproductionHk2',
    'roomTempNormalHk2',
    'roomTempReducedHk2',
    'sgReadyStiebel',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready Smart-me': [
    'User',
    'Password',
    'SerialNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'PV Ready mit Shelly 1': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'PV Ready Switch': [
    hideIPField,
    'warmWaterDevices',
    'ip',
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready Shelly': [
    'deviceIP2',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready Switch': [
    hideIPField,
    'warmWaterDevices2Step',
    'ip',
    'relaisChooseSet',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready ShellyPro': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'relaisChooseSet',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'S+W Futura HSW': [
    'minPower',
    'powerEstimation',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOffsetEnable',
    'boilerTempOffsetMaximum',
    'boilerTempOffsetMinimum',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Hoval WP': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'roomTempOverproductionEnable',
    'roomTempOverproduction',
    'roomTempNormal',
    'roomTempReduced',
    'roomTempOverproductionEnableHk2',
    'roomTempOverproductionHk2',
    'roomTempNormalHk2',
    'roomTempReducedHk2',
    'roomTempOverproductionEnableHk3',
    'roomTempOverproductionHk3',
    'roomTempNormalHk3',
    'roomTempReducedHk3',
    'poolHeatingChoice',
    'temperatureVisualizationChoice',
    'smartCooling',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SG Ready Rutenbeck': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'blockingSetting',
    'relaisChooseSet',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'OERTLI WPM Econ5': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'rTUOrTcp',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'intelligentCooling',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'OERTLI WPM PCO5': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'rTUOrTcp',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'OERTLI WPM PCO5 HP': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'rTUOrTcp',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'OERTLI ECODAN': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'zone1TempOverproductionEnable',
    'zone1OverproductionTemp',
    'zone1NormalTemp',
    'zone1ReducedTemp',
    'zone2TempOverproductionEnable',
    'zone2OverproductionTemp',
    'zone2NormalTemp',
    'zone2ReducedTemp',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  Dimplex: [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'rTUOrTcp',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'intelligentCooling',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  Ecoforest: [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'rTUOrTcp',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Roth Werke WP 2.0': [
    'minPower',
    'powerEstimation',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'CTA heat pump': [
    // The field 'ctaType' is omitted in order to avoid fields duplication.
    // 'ctaType',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'CTA Aeroplus 2.0': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'CTA Aeroplus 2.1': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'CTC HP': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'M-TEC': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverprodAbs',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Bartl HP': [
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverprodAbs',
    'heatingCircuit1Enable',
    'offsetMinHeatingCircuit1',
    'offsetMaxHeatingCircuit1',
    'heatingCircuit2Enable',
    'offsetMinHeatingCircuit2',
    'offsetMaxHeatingCircuit2',
    'heatingCircuit3Enable',
    'offsetMinHeatingCircuit3',
    'offsetMaxHeatingCircuit3',
    'heatingCircuit4Enable',
    'offsetMinHeatingCircuit4',
    'offsetMaxHeatingCircuit4',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  LAMBDA: [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'NIBE S Serie': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'comfortOffsetEnable2',
    'ComfortOffsetMinimum2',
    'ComfortOffsetMaximum2',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'NIBE SMO40': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'boilerTempOverproductionEnable',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'NIBE Uplink': [
    'identifier',
    'secret',
    'callbackUrl',
    'tokenString',
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'comfortOffsetEnable',
    'ComfortOffsetMinimum',
    'ComfortOffsetMaximum',
    'boilerTempOverproductionEnable',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'Waterkotte HP': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverprodAbs',
    'zone1TempOverproductionEnable',
    'zone1OverproductionTemp',
    'zone1NormalTemp',
    'zone1ReducedTemp',
    'smartCooling',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'Waterkotte SmartController': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'thermalDisinfectionEnable',
    'heatingCircuitDirectEnable',
    'offsetMinHeatingCircuitDirect',
    'offsetMaxHeatingCircuitDirect',
    'heatingCircuit1Enable',
    'offsetMinHeatingCircuit1',
    'offsetMaxHeatingCircuit1',
    'heatingCircuit2Enable',
    'offsetMinHeatingCircuit2',
    'offsetMaxHeatingCircuit2',
    'heatingCircuit3Enable',
    'offsetMinHeatingCircuit3',
    'offsetMaxHeatingCircuit3',
    'smartCooling',
    'poolHeating',
    'poolOffset',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],
  'SmartGridready HP': [
    'smartGridreadyXml',
    'switchingTimesEnable',
    'switchingTimes',
    hideIPField
  ],
  'ZZZ HP': [
    'minPower',
    'inverterHeatPump',
    'maxPowerConsumption',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'boilerTempOverproductionEnable',
    'boilerTempOverproduction',
    'boilerTempNormal',
    'boilerTempReduced',
    'heatingBufferOptimizationEnable',
    'heatingBufferOverproduction',
    'heatingBufferReduced',
    'heatingFlowTemperatureEnable',
    'heatingFlowTemperatureOverproduction',
    'heatingFlowTemperatureEnableReduction',
    'heatpumpControlMethod',
    'switchingTimesEnable',
    'switchingTimes'
  ],

  // Smart Plugs

  'smart-me Plug': [
    'User',
    'Password',
    'SerialNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'tempControl',
    'tempControlLimit',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'smartPlugChargingType',
    hideIPField
  ],
  'MyStrom Switch': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'tempControl',
    'tempControlLimit',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'smartPlugChargingType'
  ],
  'Tasmota flashed': [
    'Device_ID',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'smartPlugChargingType'
  ],
  'Shelly Plug S': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'smartPlugChargingType'
  ],
  'Shelly Plug S EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  // Batteries

  'BYD B-BOX H with Victron Energy Venus GX': [
    'maxDischargePower',
    'maxChargePower'
  ],
  sonnenBatterie: [
    'controlMethod',
    'maxDischargePower',
    'maxChargePower'
  ],

  'Fronius BYD HV': ['Device_ID'],
  'Tesla Powerwall 2': [
    'User',
    'Password',
    'gatewayNr',
    'gatewaypw'
  ],
  'Max Storage Ultimate': ['Device_ID', hideIPField],
  'Huawei LUNA2000': ['Device_ID'],
  'Sofar HYD Bat': ['Device_ID', hideIPField],
  'ZZZ Bat': ['Device_ID'],
  ZZBatterySmartme: [
    'User',
    'Password',
    'SerialNumber',
    hideIPField
  ],
  'SolarEdge SE HD': ['Device_ID'],
  'Studer Next3 Bat': [
    'Device_ID'
  ],

  // Switches

  'Relaisbox GUDE 2302': [
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly 1': [
    'deviceIP',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly 1PM': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly 2.5': [
    'deviceIP',
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly 4 Pro': [
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Smart-me Relais': [
    'User',
    'Password',
    'SerialNumber',
    'relaisNumber2',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes',
    hideIPField
  ],
  'Shelly 3EM Switch': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly EM Switch': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'GUDE 2312': [
    'relaisNumber3',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Rutenbeck TCR IP 4': [
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Siemens PAC 2200 Relais': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly Pro 4PM': [
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly Pro 3': [
    'relaisNumber3',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly Pro 2PM': [
    'relaisNumber2',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'Shelly Pro 1PM': [
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  VirtualSwitch: [
    hideIPField,
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'WISE 4000': [
    'User',
    'Password',
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],
  'ZZZ SW': [
    'User',
    'Password',
    'relaisNumber',
    'minPower',
    'switchOnDelay',
    'switchOffDelay',
    'minimalOnDuration',
    'minimalOnDurationPerDay',
    'minimalOnDurationPerDayTime',
    'minimalOnDurationPerDayStart',
    'maximalOnDurationPerDay',
    'maximalOnDurationPerDayTime',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'switchingTimesEnable',
    'switchingTimes',
    'chargingTimes'
  ],

  // Energy Measurements

  'Shelly 1PM EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Shelly 2.5EM': [
    'relaisNumber2',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Shelly EM': [
    'relaisNumber2',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Shelly 3EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Shelly 4 Pro EM': [
    'relaisNumber',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'smart-me 3P EM': [
    'User',
    'Password',
    'SerialNumber',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'smart-me Plug EM': [
    'User',
    'Password',
    'SerialNumber',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'SMA Energy Meter 20': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'SMA Energy Meter 20 EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'myStrom Switch EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'my-PV Power Meter EM': [
    'invertMeasurement',
    'unlocked',
    'serialnumberMyPv',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Carlo Gavazzi EM24 EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'AskomaSM EM': [
    'Device_ID_RTU',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'AskomaSM EM2': [
    'Device_ID_RTU',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'AskomaSM EM3': [
    'Device_ID_RTU',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'Fronius EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'EMU M-Bus Center EM': [
    'emuCenterSmartMeterType',
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Socomec Countis E27/E47 EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'E3DC EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Siemens PAC3200 EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'ZZZ EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'ABB EQmatic EM': [
    'tokenEQmatic',
    'SerialNumberSmartMeter',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'invertMeasurement'
  ],
  'Shelly Pro 4PM EM': [
    'relaisNumber',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    'invertMeasurement'
  ],
  'EM300 EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'hbTec EM': [
    'port',
    'Device_ID',
    'User',
    'Password',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'EMU PROF EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'Schneider PowerTag Link EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'SolarLog Base EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'SolarLogPRO380 EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit',
    hideIPField
  ],
  'KElectric KE-P-80 EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'MUCEasy EM': [
    'Device_ID',
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],
  'UPM 209 EM': [
    'invertMeasurement',
    'activeDevicePowerLimitEnable',
    'activeDevicePowerLimit'
  ],

  // Input Devices

  'Siemens PAC 2200': [
    'invertMeasurement'
  ],
  'GUDE 2302': [
    'relaisNumber8',
    'invertMeasurement'
  ],
  'Smart-me Input': [
    'User',
    'Password',
    'SerialNumber',
    'invertMeasurement',
    hideIPField
  ],
  'Shelly Pro 4PM Input': [
    'relaisNumber4Input',
    'invertMeasurement'
  ],
  'Shelly Pro 2PM Input': [
    'relaisNumber2Input',
    'invertMeasurement'
  ],
  'Shelly Pro 1PM Input': [
    'invertMeasurement'
  ],
  'WISE 4000 Input': [
    'User',
    'Password',
    'relaisNumber4Input',
    'invertMeasurement'
  ],

  // External Platforms

  SIC: [hideIPField],

  // External Displays

  dingz: ['excessIndicationLimit'],

  // Temperature Measurements

  Shelly: ['Device_ID'],

  // Home Appliances
  'Miele Dryer': [
    hideIPField,
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'Miele Washer': [
    hideIPField,
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'Miele Dishwasher': [
    hideIPField,
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'Bosch HC Dryer': [
    hideIPField,
    'tokenString',
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'Bosch HC Washer': [
    hideIPField,
    'tokenString',
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'Bosch HC Dishwasher': [
    hideIPField,
    'tokenString',
    'minPower',
    'switchOnDelay',
    'switchingTimesEnable',
    'switchingTimes',
    'homeApplianceChargingMode'
  ],
  'ZZZ AP': [
    'Device_ID'
  ],
  'SOLARWATT MyReserve SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ],
  'SungrowSH5 SM': [
    'SmartMeterPosition',
    'invertMeasurement'
  ]
};

export default fieldsForDevices;
