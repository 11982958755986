import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { deleteNotification } from './actions';
import Notification from './Notification';
import { messagesSelector } from './selectors';

import './styles.scss';

/**
 * Renders list of notifications
 * @memberof module:Notification
 */
const NotificationList = ({ messages, deleteNotification: deleteNotificationFromProps }) => {
  const notifications = messages.map((message) => (
    <Notification key={message.id} message={message} deleteNotification={deleteNotificationFromProps} />
  ));
  return (
    <div id="toast-container" className="toast-bottom-right">
      {notifications}
    </div>
  );
};

NotificationList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  deleteNotification: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  messages: messagesSelector
});

export default connect(mapStateToProps, {
  deleteNotification
})(NotificationList);
