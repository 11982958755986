import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dataListSendRequest } from './actions';
import { setPagesSettingQueries } from '../PagesSettings/actions';
import { getPagesSettingSelector } from '../PagesSettings/selectors';

export const DataListFilterV2 = ({
  listID,
  Component,
  componentProps,
  listURL,
  transformResponse
}) => {
  const dispatch = useDispatch();
  const pages = useSelector(getPagesSettingSelector);

  const currentPage = pages[listID];
  const { queries } = currentPage;

  const addFilters = (filters) => {
    const data = {
      transformResponse,
      listURL,
      params: { ...queries, ...filters, _start: 0 }
    };
    data.params.search = data.params.search || undefined;
    dispatch(dataListSendRequest(listID, data));
    dispatch(setPagesSettingQueries(listID, { ...queries, ...filters }));
  };

  return (
    <Component
      onChange={addFilters}
      {...componentProps}
      allParams={queries}
    />
  );
};

DataListFilterV2.propTypes = {
  Component: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  listID: PropTypes.string.isRequired,
  listURL: PropTypes.string.isRequired,
  componentProps: PropTypes.instanceOf(Object)
};

DataListFilterV2.defaultProps = {
  componentProps: {}
};
