import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PuffLoader from 'react-spinners/PuffLoader';
import ModalWindow from '../../../ModalWindow';
import i18n from '../../../../i18n';
import { DELETE_INVITED_USER_MODAL_ID } from '../../constants';
import { closeModalWindow } from '../../../ModalWindow/actions';
import { fetchDeleteInvitedUser } from '../../store/actions';
import { getDeleteInvitedUserModalSelector, getDeletingInviteUserLoadingSelector } from '../../store/selectors';
import CancelButton from '../../../../components/UIKit/CancelButton';

const DeleteInvitedUserModal = ({
  closeModalWindow: closeModalWindowAction,
  fetchDeleteInvitedUser: fetchDeleteInvitedUserAction,
  modal,
  isLoading,
  userId
}) => {
  const handleOnClose = () => closeModalWindowAction(DELETE_INVITED_USER_MODAL_ID);

  const handleInvite = () => fetchDeleteInvitedUserAction(userId, modal.data.id);

  return (
    <ModalWindow modalID={DELETE_INVITED_USER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">
          {i18n.t('deleteInvitedUserTitle')}
        </h5>
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <PuffLoader
            size={50}
            color="#f7bd27"
            speedMultiplier={3}
          />
        </div>
      )
        : (
          <>
            <div className="modal-body">
              <p className="message">
                {i18n.t('deleteInvitedUserSubtitle')}
              </p>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={handleInvite}
              >
                <i className="fa fa-trash" />
                {' '}
                {i18n.t('delete')}
              </button>
            </div>
          </>
        )
      }
    </ModalWindow>
  );
};

DeleteInvitedUserModal.propTypes = {
  closeModalWindow: PropTypes.func.isRequired,
  fetchDeleteInvitedUser: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool.isRequired,
  userId: PropTypes.string
};

DeleteInvitedUserModal.defaultProps = {
  modal: {},
  userId: null
};

const mapStateToProps = createStructuredSelector({
  modal: getDeleteInvitedUserModalSelector,
  isLoading: getDeletingInviteUserLoadingSelector
});
export default connect(mapStateToProps, {
  closeModalWindow,
  fetchDeleteInvitedUser
})(DeleteInvitedUserModal);
