import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { createStructuredSelector } from 'reselect';
import 'react-table/react-table.css';


/**
 * Selectors
 * @ignore
 */
import {
  viewerDataListSelector,
  visibleWebSelector
} from './selectors';


/**
 * Components
 * @ignore
 */
import {
  TabContent,
  ViewerInfo
} from './components';
import DataList, {
  DataListPagination,
  DataListFilter
} from '../DataList';
import {
  EndUsersTab,
  OEMTab,
  PVInstallerTab
} from './Tabs';
import Pagination from '../../components/ReactTable/Pagination';
import SearchField from '../../components/SearchField';
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';


import {
  dataListIDViewer,
  dataListIDTabsForViewer,
  getViewerParams
} from './constants';

import { configSavedSettings } from '../../utils/pageSettings';

import i18n from '../../i18n';

class ViewersDetails extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};

    configSavedSettings(
      this,
      'viewers-details',
      {
        expander: true,
        subTabs: true,
        pathName,
        historyKey
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const {
      visibleWeb,
      viewerDataList,
      match
    } = this.props;

    const { expanded, activeTab } = this.state;

    const { viewerID } = match.params;

    const viewerDataListURL = `/../users/viewers/${viewerID}`;
    const connectedEndUsersUrl = `/../users/get-attached-to-viewer-end-users?viewerId=${viewerID}`;
    const connectedPvInstallersUrl = `/../users/get-pv-installer-users-attached-to-viewer?viewerId=${viewerID}`;
    const connectedOemsUrl = `/../users/get-oem-users-attached-to-viewer?viewerId=${viewerID}`;
    const viewerHasLoaded = !!viewerDataList.data?._id;

    const transformResponse = (data) => ({
      total: data.total,
      data: data.list
    });

    const subTabs = ['SMUs', 'pvInstallers', 'OEM'];

    const activeTabIndex = subTabs.indexOf(activeTab) === -1 ? 0 : subTabs.indexOf(activeTab);

    const searchByFields = {
      SMUs: 'sm_id,email,first_name,last_name,street,city,zip,phone',
      pvInstallers: 'sm_id,email,first_name,last_name,street,city,zip,phone,my_company',
      OEM: 'sm_id,email,first_name,last_name,street,city,zip,phone,oem'
    };

    const onExpandedChange = (all) => {
      this.saveExpanderChanges(all);
    };

    const generalTabProps = {
      transformResponse,
      onExpandedChange,
      viewerID,
      expanded
    };

    return (
      <>
        <TopBar
          visibleWeb={visibleWeb}
          showNavigation
        />
        <DataList
          listID={dataListIDViewer}
          listURL={viewerDataListURL}
          Component={ViewerInfo}
          params={{ ...getViewerParams }}
        />
        {viewerHasLoaded && (
          <div className="container-fluid">
            <div
              className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10"
              style={{ margin: '30px 0 10px' }}
            >
              <DataListFilter
                Component={SearchField}
                componentProps={{
                  searchByFields: searchByFields?.[activeTab] || searchByFields.SMUs,
                  placeholder: i18n.t('searchAllTablesElements')
                }}
                listID={dataListIDTabsForViewer[activeTabIndex]}
              />
            </div>
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index) => this.changeSubTab(subTabs[index])}
            >
              <TabList>
                <Tab>
                  <TabContent title={i18n.t('SMUs')} />
                </Tab>
                <Tab>
                  <TabContent title={i18n.t('pvInstallers')} />
                </Tab>
                <Tab>
                  <TabContent title={i18n.t('OEM')} />
                </Tab>
              </TabList>
              <TabPanel>
                <EndUsersTab
                  connectedEndUsersUrl={connectedEndUsersUrl}
                  {...generalTabProps}
                />
              </TabPanel>
              <TabPanel>
                <PVInstallerTab
                  connectedPvInstallersUrl={connectedPvInstallersUrl}
                  {...generalTabProps}
                />
              </TabPanel>
              <TabPanel>
                <OEMTab
                  connectedOemsUrl={connectedOemsUrl}
                  {...generalTabProps}
                />
              </TabPanel>
            </Tabs>
            <DataListPagination
              Component={Pagination}
              listID={dataListIDTabsForViewer[activeTabIndex]}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        )}
        <Footer />
      </>
    );
  }
}

ViewersDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  viewerDataList: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired
};

ViewersDetails.defaultProps = {
  visibleWeb: false
};

const mapStateToProps = createStructuredSelector({
  viewerDataList: viewerDataListSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps)(ViewersDetails);
