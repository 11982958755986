import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxReducer } from 'redux-form';
import notifications from './NotificationGenerator/reducer';
import signIn from './SignIn/reducer';
import forgotPassword from './ForgotPass/reducer';
import modals from './ModalWindow/reducer';
import signUp from './SignUp/reducer';
import signUp2 from './SignUp2/reducer';
import settingProfile from './SettingProfile/reducer';
import dataList from './DataList/reducer';
import userDevices from './UserDetails/reducer';
import installedSM from './InstalledSM/reducer';
import pvInstallersDetails from './PVInstallersDetails/reducer';
import supportDetails from './SupportDetails/reducer';
import viewerDetails from './ViewersDetails/reducer';
import users from './Users/reducer';
import monitoring from './Monitoring/reducer';
import monitoringNotifications from './MonitoringNotification/reducer';
import { dashboard, currentValues } from './Dashboard/reducers';
import verifyChangeEmail from './VerifyChangeEmail/reducer';
import devicesTable from './DevicesTable/reducer';
import sensorsOauth2 from './SensorsOauth2/reducer';
import loadManagementDashboard from './LoadManagementDashboard/reducer';
import overview from './Overview/reducer';
import cloudStatus from './CloudStatusWindow/reducer';
import documentation from './Documentation/store/reducer';
import payments from './Payments/reducer';
import invitedUsers from './InvitedUsers/store/reducer';
import pagesSettings from './PagesSettings/reducer';

export default combineReducers({
  form: reduxReducer,
  router: routerReducer,
  signIn,
  modals,
  notifications,
  users,
  pvInstallersDetails,
  supportDetails,
  viewerDetails,
  forgotPassword,
  signUp,
  signUp2,
  settingProfile,
  dataList,
  userDevices,
  dashboard,
  currentValues,
  installedSM,
  monitoring,
  monitoringNotifications,
  verifyChangeEmail,
  devicesTable,
  sensorsOauth2,
  loadManagementDashboard,
  overview,
  cloudStatus,
  documentation,
  payments,
  invitedUsers,
  pagesSettings
});
