import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { createStructuredSelector } from 'reselect';
import { getErrorInfo } from '../actions';
import { RenderSignal, RenderDate, renderFullDate } from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import '../index.css';
import { errorsSelector } from './selectors';

/**
 * Renders information when user expanded row
 * @param  {string} rowData - object with data about row
 */
class ExpandedRow extends Component {
  componentDidMount() {
    const { getErrorInfo: getErrorInfoProps, rowData: { original } } = this.props;

    const url = `../errormonitoring/errors/${original._id}`;
    getErrorInfoProps(url, original._id);
  }

  /**
   * Find and wrote into variable correct object with errors in array of all errors
   * @param {object} errors array of companies id which checked in table
   */

  parseErrors = (errors) => {
    const { rowData: { original } } = this.props;
    const foundedElement = Object.entries(errors).find((el) => el[0] === original._id);

    this.expandedArray = foundedElement ? foundedElement[1] : [];
  };

  render() {
    const { rowData: { original }, errors } = this.props;

    if (errors && Object.keys(errors).length) {
      this.parseErrors(errors);
    }

    return (
      <div className="table-details">
        <div className="container-fluid">
          <div className="column">
            {original.gateway && !(original.gateway.signal === 'connected' && !original.gateway.createdAt) ? (
              <div className="col-md-11 col-lg-11 column justify-content-start table-row">
                <div className="row ml-2">
                  <div className="col-5 col-lg-5 col-xl-5 table-row-details-head">
                    {i18n.t('SMID')}
                  </div>
                  <div className="col-2 col-lg-2 col-xl-2 table-row-details-head">
                    {i18n.t('signal')}
                  </div>
                  <div className="col-3 col-lg-3 col-xl-3 table-row-details-head">
                    {i18n.t('errorDate')}
                  </div>
                  <div className="col-2 col-lg-2 col-xl-2 table-row-details-head">
                    {i18n.t('errorStatus')}
                  </div>
                </div>
                <div className="row ml-2">
                  <div className="col-5 col-lg-5 col-xl-5 table-row-details-td">
                    {original.gateway.sm_id === 'Unknown Id' ? i18n.t('unknownId') : original.gateway.sm_id}
                  </div>
                  <div className="col-2 col-lg-2 col-xl-2 table-row-details-td">
                    {RenderSignal({ value: original.gateway?.signal })}
                  </div>
                  <div className="col-3 col-lg-3 col-xl-3 table-row-details-td">
                    {original.gateway.lastErrorDate ? RenderDate({ value: original.gateway.lastErrorDate }) : '--.--.----'}
                  </div>
                  <div className="col-2 col-lg-2 col-xl-2 table-row-details-td">
                    {original.gateway.errorStatus ? i18n.t(original.gateway.errorStatus) : '-'}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="column mt-2">
            {this.expandedArray && this.expandedArray.length ? (
              <div className="col-md-11 col-lg-11 column justify-content-start table-row">
                <div className="row">
                  <div className="table-row-details-head number-column">
                    #
                  </div>
                  <div className="device-column table-row-details-head">
                    {i18n.t('deviceName')}
                  </div>
                  <div className="signal-column table-row-details-head">
                    {i18n.t('tag')}
                  </div>
                  <div className="signal-column table-row-details-head">
                    {i18n.t('error')}
                  </div>
                  <div className="error-date-column table-row-details-head">
                    {i18n.t('errorDate')}
                  </div>
                  <div className="error-status-column table-row-details-head">
                    {i18n.t('errorStatus')}
                  </div>
                </div>
                {this.expandedArray.map((el, index) => (
                  <div className="row" key={`ErrorRow_ID_${shortid.generate()}`}>
                    <div className="number-column table-row-details-td">
                      {index + 1}
                    </div>
                    <div className="device-column table-row-details-td">
                      {el.sensor ? `${el.sensor.device_group} | ${el.sensor.device_type}` : i18n.t('unknownSensor')}
                    </div>
                    <div className="signal-column table-row-details-td">
                      {el.sensor?.tag?.name || ''}
                    </div>
                    <div className="signal-column table-row-details-td m--font-danger">
                      {el.errorCode}
                    </div>
                    <div className="error-date-column table-row-details-td">
                      {renderFullDate({ value: el.createdAt })}
                    </div>
                    <div className="error-status-column table-row-details-td">
                      {i18n.t(el.status)}
                    </div>
                  </div>
                ))}
              </div>
            ) : <></>}
          </div>

          {original.note ? (
            <div className="column mt-2">
              <div className="col-md-11 col-lg-11 column justify-content-start table-row">
                <div className="row ml-2">
                  <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
                    {i18n.t('note')}
                  </div>
                </div>
                <div className="row ml-2">
                  <div className="col-12 table-row-details-td undertable-note">{original.note}</div>
                </div>
              </div>
            </div>
          ) : <> </>}

        </div>
      </div>
    );
  }
}

ExpandedRow.propTypes = {
  rowData: PropTypes.instanceOf(Object).isRequired,
  getErrorInfo: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object)
};

ExpandedRow.defaultProps = {
  errors: {}
};

const mapStateToProps = createStructuredSelector({
  errors: errorsSelector
});

export default connect(mapStateToProps, {
  getErrorInfo
})(ExpandedRow);
