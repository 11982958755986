import React, { useState, useEffect, useMemo } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const style = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px'
  },
  searchBox: {
    width: '100%',
    border: 'none',
    backgroundColor: '#40424D'
  },
  chips: {
    backgroundColor: '#25272D',
    whiteSpace: 'normal'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    border: 'none',
    backgroundColor: '#40424D',
    top: '4px'
  },
  inputField: {
    maxWidth: '240px'
  }
};

/**
 *
 * @param {object[]} data
 * @param {string} field
 * @returns {string}
 */
const transformData = (data, field) => data.map(({ [field]: value }) => value).join(',');

const MultiFilter = ({
  onChange: handleSubmit,
  submitFieldName,
  placeholder,
  extraUpdate,
  fieldName,
  disabled,
  options,
  title
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const transformedOptions = useMemo(() => options
    .map((data) => ({ ...data, label: i18n.t(data[fieldName]) }))
    .sort((a, b) => a.label.localeCompare(b.label)), [options, fieldName]);

  const handleSelect = (data) => {
    setSelectedList(data);
    let submitData = { [submitFieldName]: transformData(data, fieldName) };

    if (extraUpdate) {
      const filteredOptions = extraUpdate(transformData(data, 'type'));
      submitData = { ...submitData, ...filteredOptions };
    }
    handleSubmit(submitData);
  };

  useEffect(() => {
    const selected = selectedList.filter(({ [fieldName]: field }) => options.map(({ [fieldName]: value }) => value).includes(field));
    setSelectedList(selected);
  }, [options]); // eslint-disable-line

  return (
    <>
      {title && <span>{`${title}:`}</span>}
      <Multiselect
        options={transformedOptions}
        placeholder={placeholder}
        showCheckbox
        emptyRecordMsg={i18n.t('noOptionsAvailable')}
        style={style}
        onSelect={handleSelect}
        onRemove={handleSelect}
        displayValue="label"
        selectedValues={selectedList}
        disable={disabled}
      />
    </>
  );
};

MultiFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  submitFieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  extraUpdate: PropTypes.func,
  disabled: PropTypes.bool
};

MultiFilter.defaultProps = {
  extraUpdate: undefined,
  disabled: false
};

export default MultiFilter;
