import {
  CHANGE_DATA_FOR_REQUEST,
  CHANGE_SCALE_TYPE,
  GET_CHART_DATA,
  GET_INIT_USER,
  GET_SMART_METER_DATA,
  RECEIVE_CHART_DATA,
  RECEIVE_INIT_USER,
  RECEIVE_SMART_METER_DATA,
  SEND_IOT_COMMAND,
  SEND_IOT_COMMAND_ERROR,
  SEND_IOT_COMMAND_SUCCESS
} from './constants';

export function sendIOTCommand(gatewayId) {
  return {
    type: SEND_IOT_COMMAND,
    payload: {
      gatewayId
    }
  };
}

export function sendIOTCommandSuccess() {
  return {
    type: SEND_IOT_COMMAND_SUCCESS
  };
}

export function sendIOTCommandError() {
  return {
    type: SEND_IOT_COMMAND_ERROR
  };
}
export function requestInitUser(userId) {
  return {
    type: GET_INIT_USER,
    payload: {
      userId
    }
  };
}

export function receiveInitUser(result) {
  return {
    type: RECEIVE_INIT_USER,
    payload: {
      result
    }
  };
}

export function getPhasesData(data) {
  return {
    type: GET_SMART_METER_DATA,
    payload: {
      data
    }
  };
}

export function receivePhasesData(result) {
  return {
    type: RECEIVE_SMART_METER_DATA,
    payload: {
      result
    }
  };
}

export function getChartData(data) {
  return {
    type: GET_CHART_DATA,
    payload: {
      data
    }
  };
}

export function receiveChartData(result) {
  return {
    type: RECEIVE_CHART_DATA,
    payload: {
      result
    }
  };
}

export function changeScaleType(data) {
  return {
    type: CHANGE_SCALE_TYPE,
    payload: {
      data
    }
  };
}

export function changeDataForRequest(data) {
  return {
    type: CHANGE_DATA_FOR_REQUEST,
    payload: {
      data
    }
  };
}
