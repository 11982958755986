import * as actionTypes from './constants';

export function receiveSignUp(email, verifyEmail) {
  return {
    type: actionTypes.SIGN_UP_RECEIVED,
    payload: {
      email,
      verifyEmail
    }
  };
}

export function signUpSend(type, language, fields) {
  return {
    type: actionTypes.SIGN_UP_SEND,
    payload: {
      type,
      language,
      fields
    }
  };
}

export function reveivedInfoFromServer() {
  return {
    type: actionTypes.RECEIVED_INFORMATION_FROM_SERVER
  };
}

export function changeEmailAction() { // Show form for new email
  return {
    type: actionTypes.SIGN_UP_CHANGE_EMAIL
  };
}
export function sendEmailChange(language, { old_email, new_email }) { // for epic, send request
  return {
    type: actionTypes.SIGN_UP_CHANGE_EMAIL_SEND,
    payload: {
      old_email,
      new_email,
      language
    }
  };
}

export function sendEmailChangeSucc(email, verifyEmail) { // Show message
  return {
    type: actionTypes.SIGN_UP_CHANGE_EMAIL_SUCC,
    payload: {
      email,
      verifyEmail
    }
  };
}

export function resendToEmail(email, language) { // send request and show message
  return {
    type: actionTypes.SIGN_UP_RESEND,
    payload: {
      email,
      language
    }
  };
}
