import React from 'react';
import PropTypes from 'prop-types';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { useDispatch } from 'react-redux';
import i18n from '../../../i18n';
import { DATA_LIST_ID, DATA_LIST_URL, WORK_STATUS_ARR } from '../../../containers/Monitoring/constants';
import { monitoringSendApplyWithFields } from '../../../containers/Monitoring/actions';
import analytics from '../../../analytics';
import getEventObj, { ERRORMONITORING_TICKETS } from '../../../analytics/events';
/**
 * Render function for React-Table. Column for status of user
 * @memberof module:ReactTable
 * @param  {string} value - work status of user
 */
const RenderWorkStatus = (props) => {
  const { value, original } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const clickHandler = (status) => {
    const data = {
      targets: [
        original._id
      ],
      values: {
        status
      }
    };
    analytics.sendEvent(getEventObj(ERRORMONITORING_TICKETS));
    dispatch(monitoringSendApplyWithFields(DATA_LIST_URL, DATA_LIST_ID, data));
    setOpen(false);
  };

  return (
    <div className="workStatus-dropdown">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="workStatus-dropdown-btn"
      >
        {i18n.t(value).length > 16 ? `${i18n.t(value).slice(0, 16)}...` : i18n.t(value)}
        <i className="fa fa-caret-down" />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {WORK_STATUS_ARR.map((status) => (
                    <MenuItem
                      key={status.key}
                      disabled={status.key === 'wait for the customer / new'}
                      onClick={clickHandler.bind(null, status.value)}
                    >
                      {i18n.t(status.value)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

RenderWorkStatus.propTypes = {
  value: PropTypes.string.isRequired,
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderWorkStatus;
