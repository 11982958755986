import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../App.css';
import { createStructuredSelector } from 'reselect';
import Logo from '../../components/Logo';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import { basicAuthSignIn } from './actions';
import i18n from '../../i18n';
import Footer from '../../components/Footer';

/**
 * @class BasicSignInForm
 * @memberof module:RootSignIn
 * @description renders Basic sign in form
 * @extends Component
 */

/**
 * BasicAuthSignIn form id
 */
const formId = 'rootSignInForm';
const BasicAuthSignIn = (props) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password, basicAuthSignIn: basicAuthSignInProps } = props;
    const data = {
      login: email,
      password
    };

    basicAuthSignInProps(data);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row align-items-center justify-content-center">
            <div className="col-auto mr-auto">
              <Logo />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-6 col-lg-8 col-md-10 col-sm-10">
                <div className="m-login__head">
                  <h3 className="m-login__title">{i18n.t('headerLogin')}</h3>
                </div>
                <div className="container-fluid">
                  <form
                    className="m-login__form m-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="email"
                        component={CustomTextInput}
                        label={i18n.t('mail')}
                        className="m-input"
                        autoComplete="off"
                        preventAuto
                      />
                    </div>
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="password"
                        component={CustomTextInput}
                        label={i18n.t('pass')}
                        className="m-input"
                        type="password"
                        autoComplete="off"
                        preventAuto
                      />
                    </div>
                    <div className="m-login__form-action">
                      <button
                        type="submit"
                        id="m_login_signin_submit"
                        className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary"
                      >
                        {i18n.t('loginbtn')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

BasicAuthSignIn.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  basicAuthSignIn: PropTypes.func.isRequired
};

BasicAuthSignIn.defaultProps = {
  email: '',
  password: ''
};
const selector = formValueSelector(formId);

const mapStateToProps = createStructuredSelector({
  email: (state) => selector(state, 'email'),
  password: (state) => selector(state, 'password')
});

const form = reduxForm({
  form: formId
})(BasicAuthSignIn);

export default connect(mapStateToProps, {
  basicAuthSignIn
})(form);
