import React, { Fragment } from 'react';
import shortid from 'shortid';
import Tooltip from '../../../../../components/Tooltip';
import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../../config';
import i18n from '../../../../../i18n';

const allowedRoles = [
  'root',
  'solar_admin',
  'support',
  'viewer',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'oem',
  'end_user'
];

const allowedToChangeSubscriptionRoles = ['solar_admin', 'root', 'pv_installer', 'pv_installer_employee', 'end_user', 'pv_installer_employee_read_only'];

export const getColumns = ({ user = {}, myRoleType, product = {}, redirectToPaymentHandler }) => {
  const { gateway = {} } = user;

  const isShowSubscription = allowedToChangeSubscriptionRoles.includes(myRoleType) && [HELION_APP_ID, SOLAR_MANAGER_APP_ID].includes(APP_ID);

  const isShowUserSubscriptionEditBtn = ['solar_admin', 'root', 'pv_installer', 'end_user'].includes(myRoleType) && [SOLAR_MANAGER_APP_ID, HELION_APP_ID].includes(APP_ID);

  return [
    [{
      title: 'fName',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => (
        <p>
          {user.first_name || ''}
          {' '}
          {user.last_name || ''}
        </p>
      )
    },
    {
      title: 'plantLabel',
      tooltip: 'plantTooltip',
      isAllowed: myRoleType !== 'end_user',
      Value: () => <p>{user.plant?.length > 16 ? `${user.plant?.slice(0, 16)}...` : user.plant}</p>
    },
    {
      title: 'SMID',
      tooltip: 'SMIDTolltip',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway.sm_id}</p>
    },
    {
      title: 'userSettingNotesLabel',
      tooltip: 'userSettingNotesTooltip',
      isAllowed: myRoleType !== 'end_user',
      Value: () => <p>{user.note?.length > 25 ? `${user.note?.slice(0, 25)}...` : user.note}</p>
    }],
    [{
      title: 'subscription',
      isAllowed: isShowSubscription && product.name,
      Value: () => (
        <span className="subscription-block-info">
          <span className={`plan-type ${product.name}`}>
            {product.name}
          </span>
          {isShowUserSubscriptionEditBtn && (
            <span className="dropdown">
              <button
                type="button"
                onClick={redirectToPaymentHandler}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill subsection-icon"
              >
                <i className="la la-pencil" />
              </button>
            </span>
          )}
        </span>
      )

    },
    {
      title: 'IP',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway.ip}</p>
    },
    {
      title: 'MAC',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway?.mac}</p>
    },
    {
      title: 'status',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{user.status}</p>
    }],
    [{
      title: 'signal',
      value: user.signal,
      isAllowed: allowedRoles.includes(myRoleType),
      isConnected: gateway.signal === 'connected',
      Value: () => (
        <span className="m--font-bold signal m--font-success">
          {
            gateway.signal === 'connected' ? (
              <Fragment>
                <span className="m-badge m-badge--success m-badge--dot" />
                &nbsp;
                <span className="m--font-bold signal m--font-success">{i18n.t(gateway.signal)}</span>
              </Fragment>
            )
              : (
                <Fragment>
                  <span className="m-badge m-badge--danger m-badge--dot" />
                  &nbsp;
                  <span className="m--font-bold signal m--font-danger">{i18n.t(gateway.signal)}</span>
                </Fragment>
              )
          }
        </span>
      )
    },
    {
      title: 'error',
      value: user.signal,
      isAllowed: gateway.has_errors || gateway.hasErrors,
      gatewayErrors: user?.gateway?.errors || [],
      Value: () => (
        <div className="m-datatable__cell">
          {(gateway.errors?.length > 0)
            ? (
              <span className="w-300">
                {(
                  gateway.errors.map(({ code }) => (
                    <span key={shortid.generate()} className="error_status_yes">
                      {code}
                      {i18n.exists(`error_${code}`) && <Tooltip title={i18n.t(`error_${code}`)} />}
                    </span>
                  ))
                )}
              </span>
            )
            : <span className="m--font-bold signal m--font-danger">{i18n.t('yes')}</span>
          }
        </div>
      )
    }]
  ];
};
