import { createSelector } from 'reselect';

const getSelectedRows = (state) => state.installedSM?.selected;
const getVersionsList = (state) => state.installedSM?.versionsList;
const getIsLoading = (state) => state.installedSM?.isLoading;
const getRemoveGatewayModal = (state) => state.modals?.removeGateway;
const getConfirmRemoveGatewayModal = (state) => state.modals?.confirmRemoveGateway;
const getRemoveFirmwareUpdateModal = (state) => state.modals?.removeFirmwareUpdate;
const getAddGatewayModal = (state) => state?.modals?.addGateway;
const getModals = (state) => state?.modals;
const getHardwaresList = (state) => state.installedSM?.hardwaresList;
const getModalsRemoveData = (state) => state?.modals?.removeData;
const getLicensesList = (state) => state.installedSM?.licensesList;
const getDefaultLicenseId = (state) => state.installedSM?.defaultLicenseId;
const getCloudStatusInfo = (state) => state.cloudStatus?.message;
const getIsLoadingModals = (state) => state.modals?.isLoading;

export const selectedRowsSelector = createSelector([getSelectedRows], (selectedRows) => selectedRows);
export const versionsListSelector = createSelector([getVersionsList], (versionsList) => versionsList);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const isLoadingSelectorModals = createSelector([getIsLoadingModals], (isLoading) => !!isLoading);
export const removeGatewayModalSelector = createSelector([getRemoveGatewayModal], (removeGateway) => removeGateway);
export const confirmRemoveGatewayModalSelector = createSelector([getConfirmRemoveGatewayModal], (removeGateway) => removeGateway);
export const removeFirmwareUpdateModalSelector = createSelector([getRemoveFirmwareUpdateModal], (removeFirmwareUpdate) => removeFirmwareUpdate);
export const addGatewayModalSelector = createSelector([getAddGatewayModal], (addGateway) => addGateway);
export const modalsSelector = createSelector([getModals], (modals) => modals);
export const hardwaresListSelector = createSelector([getHardwaresList], (hardwaresList) => hardwaresList);
export const modalsRemoveDataSelector = createSelector([getModalsRemoveData], (modalsRemoveData) => modalsRemoveData);
export const licensesListSelector = createSelector([getLicensesList], (licenses) => licenses);
export const defaultLicenseIdSelector = createSelector([getDefaultLicenseId], (id) => id);
export const cloudStatusInfoSelector = createSelector([getCloudStatusInfo], (data) => data);
