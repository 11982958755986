import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import {
  signInCheckJWT,
  signInValidJWT,
  signInInvalidJWT,
  logOut,
  signInGoBackIfSucc
} from '../SignIn/actions';
import { userSelector, verifyingJWTSelector } from './selectors';

import './index.scss';


export default (Component, allowedRoles) => {
  /**
   * Authorization checker created for testing authorization from iOS app
   * @memberof module:PermissionChecker
   */
  const CheckAuthorization = (props) => {
    const {
      user,
      verifyingJWT,
      tReady
    } = props;
    const cookies = new Cookies();
    const jwt = cookies.get('jwt');

    useEffect(() => {
      const {
        signInCheckJWT: signIn,
        signInInvalidJWT: signInInv
      } = props;


      if (jwt) {
        if (verifyingJWT) {
          signIn();
        }
      } else {
        signInInv();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT || !tReady) {
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          Loading... Authorization is being verified.
        </div>
      );
    }

    if (user) {
      if (!allowedRoles || allowedRoles.includes(user.role.type)) {
        return (
          <div className="check-authorization-wrapper">
            <div className="check-authorization-content">
              Cookies:
              {document.cookie}
            </div>
            {`Authorized successfull. Role type: ${user.role.type}`}
          </div>
        );
      }
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          {`Authorization failed. Current user (${user.role.type}) has no permissions for this page.`}
        </div>
      );
    }
    if (jwt) {
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          Authorization failed. Token is invalid.
        </div>
      );
    }
    return (
      <div className="check-authorization-wrapper">
        <div className="check-authorization-content">
          Cookies:
          {document.cookie}
        </div>
        Authorization failed. Not found jwt token in cookies.
      </div>
    );
  };


  CheckAuthorization.propTypes = {
    user: PropTypes.instanceOf(Object),
    signInCheckJWT: PropTypes.func,
    signInInvalidJWT: PropTypes.func,
    verifyingJWT: PropTypes.bool,
    tReady: PropTypes.instanceOf(Object)
  };

  CheckAuthorization.defaultProps = {
    user: undefined,
    signInCheckJWT: () => {},
    signInInvalidJWT: () => {},
    verifyingJWT: false,
    tReady: undefined
  };

  const mapStateToProps = createStructuredSelector({
    user: userSelector,
    verifyingJWT: verifyingJWTSelector
  });

  return translate()(
    connect(
      mapStateToProps,
      {
        signInCheckJWT,
        signInValidJWT,
        signInInvalidJWT,
        logOut,
        push,
        signInGoBackIfSucc
      }
    )(CheckAuthorization)
  );
};
