/* eslint-disable indent */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box } from '@mui/material';
import { changeSupportContractsRequest, changeInstallationFinishedRequest } from '../../../actions';
import { getColumns } from './columns';
import {
  initialValuesSelector,
  isSupportContractFlagAllowedToBeChangedSelector
} from '../../../selectors';
import { ItemsList } from '../ItemsList';
/**
 * Info about software and hardware for user. Viewed by everyone except end_user.
 * @memberof module:UserDetails
 * @param {UserObject} user User Object
 */
const SoftwareInfo = ({
  user,
  myRoleType,
  isSupportContractFlagAllowedToBeChanged,
  changeInstallationFinishedRequest: changeInstallationFinishedFromProps,
  changeSupportContractsRequest: changeSupportContractsFromProps
}) => {
  const gateway = user.gateway ? user.gateway : {};

  const handleSupportContractsChange = (_, newValue) => {
    changeSupportContractsFromProps(user._id, newValue);
  };
  const handleInstallationFinishedChange = (_, newValue) => {
    changeInstallationFinishedFromProps(gateway._id, newValue);
  };

  const columns = useMemo(() => getColumns({
    user,
    myRoleType,
    isSupportContractFlagAllowedToBeChanged,
    handleSupportContractsChange,
    handleInstallationFinishedChange
  }), [user, myRoleType, isSupportContractFlagAllowedToBeChanged]);// eslint-disable-line


  return (
    <Box className="main-info" sx={{ p: 4, pr: 10 }}>
      <Box className="main-content general-info">
        <ItemsList columns={columns} />
      </Box>
    </Box>
  );
};

SoftwareInfo.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  isSupportContractFlagAllowedToBeChanged: PropTypes.bool.isRequired,
  changeSupportContractsRequest: PropTypes.func.isRequired,
  changeInstallationFinishedRequest: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'settingSoftwareInfo',
  enableReinitialize: true
})(SoftwareInfo);

const mapStateToProps = createStructuredSelector({
  initialValues: initialValuesSelector,
  isSupportContractFlagAllowedToBeChanged: isSupportContractFlagAllowedToBeChangedSelector
});

export default connect(mapStateToProps, {
  changeSupportContractsRequest,
  changeInstallationFinishedRequest
})(form);
