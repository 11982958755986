import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router';
import { translate } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import {
  signInCheckJWT,
  signInValidJWT,
  signInInvalidJWT,
  logOut,
  signInGoBackIfSucc
} from '../SignIn/actions';
import Preloader from '../../components/Preloader';
import { userSelector, verifyingJWTSelector } from './selectors';

const ALLOWED_PATHS_FOR_SUPPORT_ROLE_WITHOUT_USERS = ['/setting-profile/', '/no-access', '/'];

export default (Component, allowedRoles, properties) => {
  /**
   * Checks if user is authorized. If he is authorized and his role type has permission to visit current page, it renders the page. Otherwise it does redirect him to login page.
   * @memberof module:PermissionChecker
   */
  const ProtectedRoute = (props) => {
    const {
      match,
      user,
      verifyingJWT,
      signInGoBackIfSucc: needGoBackAfterLogin,
      tReady
    } = props;

    useEffect(() => {
      const {
        signInCheckJWT: signIn,
        signInInvalidJWT: signInInv
      } = props;

      const cookies = new Cookies();
      const jwt = cookies.get('jwt');
      if (jwt) {
        if (verifyingJWT) {
          signIn();
        }
      } else {
        signInInv();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT || !tReady) {
      return <Preloader />;
    }

    if (user) {
      const {
        _id,
        role,
        my_company,
        supportedUsers
      } = user;
      const userRoleType = role?.type;
      const matchPath = match?.path;

      if (
        userRoleType === 'pv_installer'
        && my_company?.company_abbreviation
        && matchPath === '/employees'
      ) {
        return <Redirect to="/" />;
      }
      if (!allowedRoles || allowedRoles.includes(userRoleType)) {
        const isSupportNoAccess = userRoleType === 'support'
          && !supportedUsers?.length
          && !ALLOWED_PATHS_FOR_SUPPORT_ROLE_WITHOUT_USERS.includes(matchPath);

        if (isSupportNoAccess) {
          return <Redirect to="/no-access" />;
        }
        return (
          <Suspense fallback={<Preloader />}>
            <Component
              {...props}
              {...properties}
              myRoleType={userRoleType}
              myUserID={_id}
            />
          </Suspense>
        );
      }
      return <Redirect to="/" />;
    }
    needGoBackAfterLogin(true);
    return <Redirect push to="/login" />;
  };


  ProtectedRoute.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
    signInCheckJWT: PropTypes.func.isRequired,
    signInInvalidJWT: PropTypes.func.isRequired,
    verifyingJWT: PropTypes.bool,
    user: PropTypes.instanceOf(Object),
    signInGoBackIfSucc: PropTypes.func.isRequired,
    tReady: PropTypes.bool.isRequired
  };

  ProtectedRoute.defaultProps = {
    user: undefined,
    verifyingJWT: undefined
  };

  const mapStateToProps = createStructuredSelector({
    user: userSelector,
    verifyingJWT: verifyingJWTSelector
  });

  return translate()(
    connect(
      mapStateToProps,
      {
        signInCheckJWT,
        signInValidJWT,
        signInInvalidJWT,
        logOut,
        push,
        signInGoBackIfSucc
      }
    )(ProtectedRoute)
  );
};
