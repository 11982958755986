import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import ModalWindow from '../ModalWindow';
import { closeModalWindow } from '../ModalWindow/actions';
import { CLOUD_STATUS_MODAL_ID, FORM_ID } from './constants';
import i18n from '../../i18n';
import CustomTextField from '../../components/ReduxFormFields/CustomTextField';
import { required } from '../validation';
import { modalSelector, myRoleTypeSelector } from './selectors';

import './index.scss';
import CancelButton from '../../components/UIKit/CancelButton';

const CloudStatusWindow = (props) => {
  const {
    anyTouched,
    invalid,
    initialize,
    modal,
    handleSubmit,
    closeModalWindow: closeModal,
    myRoleType
  } = props;
  const { opened, data } = modal;

  const isAdmin = ['root', 'solar_admin'].includes(myRoleType);
  useEffect(() => {
    if (opened) {
      if (isAdmin) {
        initialize({ message: data?.message || '' });
      } else {
        const savedCreatedAt = JSON.parse(localStorage.getItem('cloudStatusMessage'));
        initialize({ message: data?.message || i18n.t('cloudStatusNoMessage') });

        if (!savedCreatedAt || (data?.createdAt && savedCreatedAt?.createdAt !== data?.createdAt)) {
          localStorage.setItem('cloudStatusMessage', JSON.stringify({ createdAt: data?.createdAt }));
        }
      }
    }

    if (!opened) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, data]);


  const onClose = () => {
    closeModal(CLOUD_STATUS_MODAL_ID);
  };

  return (
    <div className="cloudStatusModal">
      <ModalWindow
        modalID={CLOUD_STATUS_MODAL_ID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('cloudStatus')}</h5>
        </div>
        <div className="modal-body">
          <form
            id={FORM_ID}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form add-sm-us"
          >
            <div className="form-group m-form__group">
              {isAdmin && (
                <div className={`flags-select-label ${anyTouched && invalid ? 'textarea-custom-warning' : ''}`}>
                  {i18n.t('message')}
                  *
                </div>
              )}
              <Field
                name="message"
                component={CustomTextField}
                placeholder={i18n.t('messagePlaceholder')}
                className="cloud-status-message"
                readOnly={!isAdmin}
                validate={[required]}
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={onClose} i18nLabel={isAdmin ? 'cancel' : 'close'} />
          {isAdmin && (
            <button
              type="submit"
              form={FORM_ID}
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              <i className="fa fa-save" />
              {i18n.t('save')}
            </button>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

CloudStatusWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  anyTouched: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired
};

CloudStatusWindow.defaultProps = {
  modal: {}
};

const form = reduxForm({
  form: FORM_ID
})(CloudStatusWindow);

const mapStateToProps = createStructuredSelector({
  modal: modalSelector,
  myRoleType: myRoleTypeSelector
});

export default connect(mapStateToProps, {
  closeModalWindow
})(form);
