import * as actionTypes from './constants';

export function signUpVerify(verifyCode, language) { // epic
  return {
    type: actionTypes.SIGNUP2_VERIFY,
    payload: {
      verifyCode,
      language
    }
  };
}

export function signUpVerifySucc(fields, verifyCode) { // reducer
  return {
    type: actionTypes.SIGNUP2_VERIFY_SUCC,
    payload: {
      fields,
      verifyCode
    }
  };
}

export function signUpVerifyError(message) { // reducer
  return {
    type: actionTypes.SIGNUP2_VERIFY_ERROR,
    payload: {
      message
    }
  };
}

export function signUpSend(fields) { // epic
  return {
    type: actionTypes.SIGNUP2_SEND,
    payload: {
      fields
    }
  };
}

export function signUpShowStopMessage(message) { // reducer
  return {
    type: actionTypes.SIGNUP2_SHOW_STOP_MESSAGE,
    payload: {
      message
    }
  };
}
