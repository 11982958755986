import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { houseFuseSelector, smartMeterPhasesDataSelector } from '../selectors';

const LoadManagementCurrentValues = (props) => {
  const { houseFuse, currentValues } = props;

  return (
    <div className="loadManagementCurrentValues-container">
      <div>
        <span className="label">{i18n.t('houseFuseLimit')}</span>
        <div className="houseFuse">
          <span className="value phase-general">{houseFuse}</span>
          <span className="value-a">A</span>
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase1')}</span>
        <div className="phase1">
          {currentValues?.currentL1 ? (
            <>
              <span className="value phase-general">{currentValues?.currentL1}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase2')}</span>
        <div className="phase2">
          {currentValues?.currentL2 ? (
            <>
              <span className="value phase-general">{currentValues?.currentL2}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase3')}</span>
        <div className="phase3">
          {currentValues?.currentL3 ? (
            <>
              <span className="value phase-general">{currentValues?.currentL3}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
    </div>
  );
};

LoadManagementCurrentValues.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  currentValues: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  currentValues: smartMeterPhasesDataSelector,
  houseFuse: houseFuseSelector
});

export default connect(mapStateToProps)(LoadManagementCurrentValues);
