import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import i18n from '../../../i18n';
import fieldsForDevices from '../utils/fieldsForDevices';
import additionalFields from '../utils/additionalFields';
import displayField from '../utils/displayField';
import DeviceIdRTU from '../../../components/ReduxFormFields/DeviceIdRTU';
import IPField from '../../../components/ReduxFormFields/IPField';

import Tooltip from '../../../components/Tooltip';
import { getUpdatedFieldList } from './getUpdatedFieldList';
import '../styles.scss';

/**
 * Render note field
 * @function NoteField
 * @memberof module:RenderFieldsByDevName
 */
const NoteField = () => (
  <div className="form-group m-form__group">
    <div className="flags-select-label">
      {`${i18n.t(additionalFields.notes.label)}`}
      <div className="switch-tooltip tooltipInSelect">
        <Tooltip title={i18n.t(additionalFields.notes.showTooltip)} placement="right" />
      </div>
    </div>
    <Field
      name="notes"
      {...additionalFields.notes}
      placeholder={i18n.t(additionalFields.notes.label)}
    />
  </div>
);

/**
 * Renders additional fields for some device
 * @function RenderFieldsByDevName
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.deviceName Name of devices (which fields to render).
 */
const RenderFieldsByDevName = (props) => {
  const { deviceName, data, myRoleType, modal } = props;
  const fields = useMemo(() => getUpdatedFieldList(deviceName, data),
    [data.warmWaterDevices, deviceName, data.warmWaterDevices2Step, data.warmWaterDevices3Step] //eslint-disable-line
  );

  if (!fieldsForDevices?.[deviceName]) {
    return <NoteField />;
  }
  return (
    <Fragment>
      {fields.map((fieldID) => {
        if (!displayField(deviceName, fieldID, myRoleType)) {
          return null;
        }
        const { enabledIf, validateIf, disabledFor, validate, ...fieldProps } = additionalFields[fieldID];

        if (enabledIf) {
          const showField = enabledIf.every((condition) => {
            if (condition?.ignore?.includes(deviceName)) {
              return true;
            }
            const expectedValues = condition.values;
            const actualValue = data[condition.fieldID];
            return expectedValues.indexOf(actualValue) !== -1;
          });
          if (!showField) {
            return null;
          }
        }

        if (fieldID === 'Device_ID_RTU') {
          return (
            <Fragment key={`${fieldID}`}>
              <DeviceIdRTU
                sensorId={modal?.data?.id}
                value={data?.Device_ID_RTU}
              />
            </Fragment>
          );
        }

        const containerStyles = `form-group m-form__group input-field ${fieldProps.type === 'checkbox' ? ' devices-checkbox-parent' : ''}`;

        const validators = deviceName === validateIf?.deviceName && validateIf?.fieldName === fieldID
          ? validate.concat(validateIf?.validate)
          : validate;

        const disabledField = deviceName === disabledFor?.deviceName ? disabledFor?.roles : [];

        const isDisabledAutoComplete = fieldsForDevices?.[deviceName].includes('Password')
          && !fieldsForDevices?.[deviceName].includes('User');

        if (fieldID === 'ip') {
          return (
            <Fragment key={`${fieldID}`}>
              <IPField
                device_group={props.deviceName}
                type={props.type}
                formID={props.formID}
                isShow
              />
            </Fragment>
          );
        }

        return (
          <div key={`${fieldID}`} className={`${containerStyles} ${fieldID === 'switchingTimes' && 'switchingTimes-field'}`}>
            {isDisabledAutoComplete && fieldID === 'Password' && (
              <Field name="fakeField" component="input" type="email" className="fakeField" />
            )}
            <Field
              name={fieldID}
              {...fieldProps}
              label={i18n.t(additionalFields[fieldID].label)}
              validate={validators}
              deviceName={deviceName}
              disabled={disabledField.includes(myRoleType)}
            />
          </div>
        );
      })}
      <NoteField />
    </Fragment>
  );
};

RenderFieldsByDevName.propTypes = {
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  notes: PropTypes.string,
  formID: PropTypes.string,
  type: PropTypes.string
};

RenderFieldsByDevName.defaultProps = {
  deviceName: undefined,
  error: undefined,
  modal: {},
  data: {},
  notes: '',
  formID: '',
  type: ''
};

export default RenderFieldsByDevName;
