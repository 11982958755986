import React from 'react';
import PropTypes from 'prop-types';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import i18n from '../../i18n';
/**
 * Renders pagination list under table and implements functionality
 * @memberof module:ReactTable
 * @param  {object} props - Pagination properties.
 * @param  {number} props.pagesAmount - Amount of pages.
 * @param  {number} props.currentPage - Current page.
 * @param  {number} props.total - Total records in table.
 * @param  {function} props.goToPageNum - Redirects to some page with records.
 * @param  {function} props.changePageSize - Changes amount of records on page.
 */
const Pagination = ({
  pageSize, pagesAmount, currentPage, total, goToPageNum, changePageSize, savePaginationChanges
}) => {
  const disabledLink = currentPage > 1 ? '' : 'm-datatable__pager-link--disabled';

  const goToAndSave = (pageNum) => {
    goToPageNum(pageNum);
    if (savePaginationChanges) savePaginationChanges('start', (pageNum - 1) * pageSize);
  };
  const changeSizeAndSave = (size) => {
    changePageSize(+size);
    if (savePaginationChanges) savePaginationChanges('limit', +size);
  };

  const disabledPrevLink = currentPage > 1 ? '' : 'm-datatable__pager-link--disabled';

  const disabledNextLink = currentPage < (pagesAmount) ? '' : 'm-datatable__pager-link--disabled';
  if (!total) return null;
  return (
    <div className="m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
      <div className="m-datatable__pager m-datatable--paging-loaded clearfix">
        <ul className="m-datatable__pager-nav">
          <li>
            <a
              className={`m-datatable__pager-link m-datatable__pager-link--first ${disabledLink}`}
              onClick={disabledLink ? undefined : () => goToAndSave(1)}
            >
              <i className="la la-angle-double-left" />
            </a>
          </li>
          <li><a className={`m-datatable__pager-link m-datatable__pager-link--prev ${disabledPrevLink}`} onClick={disabledPrevLink ? undefined : () => goToAndSave(currentPage - 1)}><i className="la la-angle-left" /></a></li>
          {currentPage === 1
            ? <li><a className="m-datatable__pager-link m-datatable__pager-link-number m-datatable__pager-link--active curr-page">{currentPage}</a></li>
            : <li><a className="m-datatable__pager-link m-datatable__pager-link-number" onClick={() => goToAndSave(1)}>1</a></li> }
          {currentPage >= 5 ? <li><a className="m-datatable__pager-link "><i className="la la-ellipsis-h" /></a></li> : undefined}
          {currentPage >= 4 ? <li><a className="m-datatable__pager-link " onClick={() => goToAndSave(currentPage - 2)}>{currentPage - 2 }</a></li> : undefined}
          {currentPage >= 3 ? <li><a className="m-datatable__pager-link " onClick={() => goToAndSave(currentPage - 1)}>{currentPage - 1 }</a></li> : undefined}
          {currentPage === 1 ? undefined : <li><a className="m-datatable__pager-link m-datatable__pager-link-number m-datatable__pager-link--active curr-page">{currentPage}</a></li>}
          {currentPage < (pagesAmount - 1) ? <li><a className="m-datatable__pager-link m-datatable__pager-link-number" onClick={() => goToAndSave(currentPage + 1)}>{currentPage + 1 }</a></li> : undefined}
          {currentPage < (pagesAmount - 2) ? <li><a className="m-datatable__pager-link m-datatable__pager-link-number" onClick={() => goToAndSave(currentPage + 2)}>{currentPage + 2 }</a></li> : undefined}
          {currentPage < (pagesAmount - 3) ? <li><a className="m-datatable__pager-link m-datatable__pager-link--more-next"><i className="la la-ellipsis-h" /></a></li> : undefined}
          {currentPage === pagesAmount ? undefined : <li><a className="m-datatable__pager-link m-datatable__pager-link-number" onClick={() => goToAndSave(pagesAmount)}>{pagesAmount}</a></li>}
          <li><a className={`m-datatable__pager-link m-datatable__pager-link--next ${disabledNextLink}`} onClick={disabledNextLink ? undefined : () => goToAndSave(currentPage + 1)}><i className="la la-angle-right" /></a></li>
          <li><a className={`m-datatable__pager-link m-datatable__pager-link--last ${disabledNextLink}`} onClick={disabledNextLink ? undefined : () => goToAndSave(pagesAmount)}><i className="la la-angle-double-right" /></a></li>
        </ul>
        <FormControl className="m-datatable__pager-info">
          <NativeSelect
            onChange={(e) => changeSizeAndSave(e.target.value)}
            name="age"
            className="m-datatable__pager-size"
            classes={{
              select: 'nativeSelectRoot nativeSelect nativeSelectMenu',
              icon: 'nativeSelectMenuIcon'
            }}
            value={pageSize}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </NativeSelect>
          <span className="m-datatable__pager-detail records">
            {i18n.t('displaying')}
            {' '}
            {(pageSize * (currentPage - 1)) + 1 }
            {' - '}
            {(pageSize * currentPage <= total) ? pageSize * currentPage : total}
            {' '}
            {i18n.t('of')}
            {' '}
            {total}
            {' '}
            {i18n.t('records')}
          </span>
        </FormControl>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pagesAmount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number,
  goToPageNum: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  savePaginationChanges: PropTypes.func
};

Pagination.defaultProps = {
  total: undefined,
  savePaginationChanges: undefined
};

export default Pagination;
