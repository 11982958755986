export const GET_INIT_USER = 'GET_INIT_USER';
export const RECEIVE_INIT_USER = 'RECEIVE_INIT_USER';
export const GET_SMART_METER_DATA = 'GET_SMART_METER_DATA';
export const RECEIVE_SMART_METER_DATA = 'RECEIVE_SMART_METER_DATA';
export const GET_CHART_DATA = 'GET_CHART_DATA';
export const RECEIVE_CHART_DATA = 'RECEIVE_CHART_DATA';
export const CHANGE_SCALE_TYPE = 'CHANGE_SCALE_TYPE';
export const CHANGE_DATA_FOR_REQUEST = 'CHANGE_DATA_FOR_REQUEST';
export const SEND_IOT_COMMAND = 'SEND_IOT_COMMAND';
export const SEND_IOT_COMMAND_SUCCESS = 'SEND_IOT_COMMAND_SUCCESS';
export const SEND_IOT_COMMAND_ERROR = 'SEND_IOT_COMMAND_ERROR';
