import { actionTypes } from '../constants';

const initialState = {
  invitedUsers: [],
  isInvitedUsersLoading: false,
  isInviteUserLoading: false,
  isDeletingInviteUserLoading: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_IS_INVITED_USERS_LOADING:
    case actionTypes.SET_IS_DELETE_INVITED_USER_LOADING:
    case actionTypes.SET_IS_INVITE_USER_LOADING:
    case actionTypes.SET_INVITED_USERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
