import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/**
 * Utils
 * @ignore
 */
import {
  additionalFields,
  hasIPField
} from '../../../containers/DevicesTable/utils';


/**
 * Components
 * @ignore
 */
import Tooltip from '../../Tooltip';

/**
 * Selectors
 * @ignore
 */
import {
  gatewayIdSelector,
  myRoleTypeSelector,
  sensorIdSelector
} from './selector';

/**
 * Actions
 * @ignore
 */
import {
  removeIP,
  scanIP,
  getListOfIP
} from '../../../containers/UserDetails/actions';
import {
  openModalWindow
} from '../../../containers/ModalWindow/actions';
import {
  sendIOTCommand
} from './actions';
import {
  addNotification
} from '../../../containers/NotificationGenerator/actions';


/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';


/**
 * Constants
 * @ignore
 */
import {
  addDeviceFormId,
  editDeviceFormId
} from '../../../containers/DevicesTable/constants';

/**
 * Styles
 * @ignore
 */
import './index.scss';

/**
 * Render IPField
 * @function IPField
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.device_group Device name * field
 * @param {string} props.myRoleType user role type
 * @param {string} props.gateway_id gateway id
 * @param {string} props.sensorId id of current sensor
 * @param {string} props.formID form id
 * @param {string} props.type Device type * field
 * @param {Function} props.openModalWindow open modal window by modal id
 * @param {Function} props.getListOfIP get list of IP
 * @param {Function} props.removeIP_AC remove IP
 * @param {Function} props.scanIP_AC scan IP
 * @param {Function} props.addNotification action to show notification modal
 */
const IPField = (props) => {
  const {
    addNotification: addNotificationFromProps,
    sendIOTCommand: sendIOTCommand_AC,
    openModalWindow: openModal_AC,
    getListOfIP: getListOfIP_AC,
    removeIP: removeIP_AC,
    scanIP: scanIP_AC,
    device_group,
    myRoleType,
    gateway_id,
    sensorId,
    formID,
    type,
    isShow
  } = props;

  const onClickSearchHandler = () => {
    const gateway = myRoleType === 'end_user' ? '' : gateway_id;
    openModal_AC('searchIP', { formID });
    removeIP_AC();
    scanIP_AC(gateway, getListOfIP_AC, { type, device_group });
  };

  const sendIOTCommandHandler = () => {
    switch (formID) {
      case addDeviceFormId: {
        addNotificationFromProps({
          type: 'error',
          text: i18n.t('needsFirstToBeCreated')
        });
        break;
      }
      case editDeviceFormId: {
        sendIOTCommand_AC(sensorId);
        break;
      }
      default:
        break;
    }
  };

  return (
    (isShow || hasIPField(device_group)) && (
      <>
        <div
          className="form-group m-form__group input-field ip-field-container"
          style={{ position: 'relative' }}
        >
          <Field
            name="ip"
            {...additionalFields.ip}
            label={i18n.t(additionalFields.ip.label)}
            className="ip-field"
          />
          <a
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right search-ip-btn"
            onClick={onClickSearchHandler}
          >
            {i18n.t('Search IP')}
          </a>
          {['ASKOHEAT SM'].includes(device_group) && (
            <div className="identify-container">
              <a
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right identify-btn"
                onClick={sendIOTCommandHandler}
              >
                {i18n.t('identifyButton')}
              </a>
              <Tooltip title={i18n.t('identifyTooltip')} placement="right" />
            </div>
          )}
        </div>
      </>
    )
  );
};

IPField.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  sendIOTCommand: PropTypes.func.isRequired,
  getListOfIP: PropTypes.func.isRequired,
  formID: PropTypes.string.isRequired,
  removeIP: PropTypes.func.isRequired,
  scanIP: PropTypes.func.isRequired,
  device_group: PropTypes.string,
  sensorId: PropTypes.string,
  myRoleType: PropTypes.string,
  gateway_id: PropTypes.string,
  type: PropTypes.string,
  isShow: PropTypes.bool
};

IPField.defaultProps = {
  device_group: undefined,
  myRoleType: undefined,
  gateway_id: undefined,
  sensorId: undefined,
  type: undefined,
  isShow: false
};

const mapStateToProps = createStructuredSelector({
  myRoleType: myRoleTypeSelector,
  gateway_id: gatewayIdSelector,
  sensorId: sensorIdSelector
});

const mapDispatchToProps = {
  openModalWindow,
  addNotification,
  sendIOTCommand,
  getListOfIP,
  removeIP,
  scanIP
};

export default connect(mapStateToProps, mapDispatchToProps)(IPField);
