import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { userSelector } from '../selectors';
import i18n from '../../../i18n';
import '../index.scss';

function RenderRebootDropdown(props) {
  const { forceRebootRequest, rebootRequest, row: { original: { _id } } } = props;
  const { language } = useSelector(userSelector);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getDropdownWidth = (lang) => {
    switch (lang) {
      case 'French':
        return '171px';
      case 'Deutsch':
        return '151px';
      case 'Italian':
        return '125px';
      case 'Danish':
        return '129px';
      default:
        return '125px';
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleReboot = () => {
    rebootRequest(_id);
    setOpen(false);
  };

  const handleForceReboot = () => {
    forceRebootRequest(_id);
    setOpen(false);
  };

  return (
    <div className="reboot-dropdown-wrapper">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="reboot-dropdown-btn"
        style={{
          width: getDropdownWidth(language)
        }}
      >
        {i18n.t('rebootRequest')}
        <i className="fa fa-caret-down" />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleReboot}>{i18n.t('rebootRequest')}</MenuItem>
                  <MenuItem onClick={handleForceReboot}>{i18n.t('forceRebootRequest')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

RenderRebootDropdown.propTypes = {
  rebootRequest: PropTypes.func.isRequired,
  forceRebootRequest: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Object).isRequired
};

export default RenderRebootDropdown;
