import { createSelector } from 'reselect';

export const getUser = (state) => state?.signIn?.user;
export const verifyingJWT = (state) => state?.signIn?.verifyingJWT;

export const userSelector = createSelector(
  [getUser],
  (user) => user
);

export const verifyingJWTSelector = createSelector(
  [verifyingJWT],
  (jwt) => jwt
);
