import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import '../Users/index.css';
import columns from './columns';
import TopBar from '../../components/TopBar';
import SearchField from '../../components/SearchField';
import AddViewerWindow from './AddViewerWindow';
import DeleteViewerWindow from './DeleteViewerWindow';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import Pagination from '../../components/ReactTable/Pagination';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { LargeButton } from '../../components/ReduxFormFields/SimpleButton';
import { APP_ID } from '../../config';

/**
 * @module ViewersTable
 */
const dataListID = 'viewers';
const listURL = '/../users/viewers';
const transformResponse = (data) => ({
  total: data.total,
  data: data.list
});

/**
 * Renders page for viewers list
 * @memberof module:ViewersTable
 */
class Viewers extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);

    configSavedSettings(
      this,
      'viewers',
      {
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'first_name:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  render() {
    const {
      openModalWindow: openModal, closeModalWindow: closeModal, modalWindowSend: modalSend
    } = this.props;

    const sendAddUser = (fields) => modalSend(
      'addData',
      {
        method: 'post',
        url: '/../users/viewers',
        data: {
          ...fields,
          'rep-password': undefined,
          app_identifier: APP_ID
        }
      },
      () => dataListReloadData(dataListID)
    );

    const sendArchiveUser = ({ id }) => modalSend(
      'removeData',
      {
        method: 'delete',
        url: `/../users/viewers/${id}`
      },
      () => dataListReloadData(dataListID)
    );

    const sendRemoveUser = ({ id }) => modalSend(
      'removeData',
      {
        method: 'delete',
        url: `/../user/${id}`
      },
      () => dataListReloadData(dataListID)
    );

    return (
      <div className="main-table-container">
        <AddViewerWindow
          modalID="addData"
          onSubmit={sendAddUser}
          handleOnClose={closeModal.bind(null, 'addData')}
        />
        <DeleteViewerWindow
          modalID="removeData"
          onSubmit={sendRemoveUser}
          handleOnClose={closeModal.bind(null, 'removeData')}
          onArchive={sendArchiveUser}
        />
        <TopBar
          updateButton
          showNavigation
        />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {' '}
              -
              {' '}
              {i18n.t('viewers')}
            </span>
          </div>
          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="col-xl-12">
              <div className="row align-items-center">
                <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10 order-1 mr-auto">
                  <DataListFilter
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'email,first_name,last_name,company,street,city,zip,phone',
                      placeholder: i18n.t('searchAllTablesElements')
                    }}
                    listID={dataListID}
                  />
                </div>
                <div className="col-auto order-2 btn-sm-us">
                  <LargeButton
                    onClick={openModal.bind(null, 'addData', null)}
                    title={i18n.t('addViewerbtn')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataList
              listID={dataListID}
              listURL={listURL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                manual: true
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={dataListID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Viewers.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export default connect(null, {
  openModalWindow, closeModalWindow, modalWindowSend
})(Viewers);
