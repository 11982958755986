import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField } from '@mui/material';
import { addNotification } from '../../../containers/NotificationGenerator/actions';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import './index.scss';

const CallbackURL = (props) => {
  const {
    input,
    label,
    showTooltip,
    defaultValue,
    placement,
    meta: { touched, error },
    preventAuto,
    className,
    languageToSync,
    addNotification: addNotificationFromProps,
    ...custom
  } = props;

  const labelProps = {
    className: 'inputLabel'
  };

  const copyHandler = (text, result) => {
    if (result && text === input?.value) {
      addNotificationFromProps({
        type: 'success',
        text: i18n.t('copied')
      });
    }
  };

  return (
    <>
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="left" />
        </div>
      )}
      <TextField
        variant="standard"
        label={label}
        defaultValue={defaultValue}
        {...input}
        {...custom}
        classes={{
          root: `callbackUrlField customTextField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          },
          endAdornment: (
            <CopyToClipboard onCopy={copyHandler} text={input?.value}>
              <i className="la la-copy" />
            </CopyToClipboard>
          )
        }}
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={labelProps}
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning">{i18n.t(error)}</div>
      )}
    </>
  );
};

CallbackURL.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool,
  addNotification: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired
};

CallbackURL.defaultProps = {
  languageToSync: '',
  showTooltip: '',
  preventAuto: false,
  placement: undefined
};

export default connect(null, { addNotification })(CallbackURL);
