import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderHeaderWithTooltip,
  renderSensorData
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

/**
 * Creates smart-meter columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const smartMeter = (myRoleType, sensorsData) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('name'),
    id: 'device_group',
    accessor: (d) => i18n.t(d.device_group),
    minWidth: 180,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('tag'),
      tooltipText: i18n.t('tagFieldTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    id: 'tag',
    accessor: 'tag.name',
    minWidth: 180,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('IP'),
    accessor: 'ip',
    id: 'ip',
    minWidth: 160,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('MAC'),
    accessor: 'mac',
    id: 'mac',
    width: 160,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('signal'),
    accessor: 'signal',
    id: 'signal',
    minWidth: 170,
    Cell: RenderSignal,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('error'),
    accessor: 'errorcodes',
    id: 'errorcodes',
    minWidth: 60,
    Cell: RenderDevicesError,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('data'),
    accessor: 'sensors_data',
    id: 'sensors_data',
    minWidth: 60,
    Cell: (args) => renderSensorData({ ...args, sensorsData }),
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: '',
    accessor: 'buttons',
    id: 'buttons',
    width: myRoleType !== 'viewer' ? 120 : undefined,
    className: 'action-buttons',
    // eslint-disable-next-line arrow-body-style
    Cell: (row) => {
      return RenderButtons(row.original, {
        editRow: [
          'root',
          'solar_admin',
          'support',
          'oem',
          'pv_installer',
          'pv_installer_employee',
          'end_user'
        ].includes(myRoleType),
        remove: [
          'root',
          'solar_admin',
          'oem',
          'support',
          'pv_installer',
          'pv_installer_employee',
          'end_user'
        ].includes(myRoleType)
      });
    },
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  }
]);

export default smartMeter;
