import * as actionTypes from './constants';

const initialState = {
  sensorsPresettings: {},
  usersSensors: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USERS_SENSORS_RECEIVED:
      return {
        ...state,
        usersSensors: action.payload.data
      };
    case actionTypes.SAVE_DEVICES_TYPES_LIST:
      return {
        ...state,
        devicesTypesList: action.payload.data
      };
    case actionTypes.RECEIVE_SENSORS_CONFIGURATION_FILE:
      return {
        ...state,
        sensorsConfigurationFile: {
          data: action.payload.data,
          createdAt: new Date()
        }
      };
    default:
      return state;
  }
};
