import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import { confirmationModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * @memberof module:SettingProfile
 */
const ConfirmationWindow = (props) => {
  const { handleOnClose, onSubmit, data, modalID } = props;

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeSMID')}</h5>
      </div>
      <div className="modal-body">
        <div className="text-confirmation">{i18n.t('confirmDelData')}</div>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <button
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
          onClick={onSubmit.bind(null, data)}
        >
          <i className="fa fa-trash" />
          {' '}
          {i18n.t('delete')}
        </button>
      </div>
    </ModalWindow>
  );
};

ConfirmationWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired
};

ConfirmationWindow.defaultProps = {
  data: {}
};
const mapStateToProps = createStructuredSelector({
  data: confirmationModalSelector
});
export default connect(mapStateToProps)(ConfirmationWindow);
