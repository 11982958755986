import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CollapseSwitchOver from './CollapseSwitchOver';

import './index.scss';

const CollapseContainer = memo((props) => {
  const {
    handleDeleteNewSwitch,
    value,
    handleChangeSwitch,
    touched,
    globalError
  } = props;

  return (
    <div className="switching-times__collapse">
      <CollapseSwitchOver
        value={value}
        touched={touched}
        globalError={globalError}
        handleChangeSwitch={handleChangeSwitch}
      />
      <div
        onClick={handleDeleteNewSwitch}
        className="switching-times__collapse-trash"
      >
        <i className="las la-trash-alt" />
      </div>
    </div>
  );
});

CollapseContainer.propTypes = {
  handleDeleteNewSwitch: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  globalError: PropTypes.instanceOf(Object).isRequired,
  handleChangeSwitch: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired
};

export default CollapseContainer;
