import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import i18n from '../../../i18n';

/**
 * Renders filter of firmware versions for Installed SM page
 * @memberof module:InstalledSM
 */
const RenderFWVersionFilter = (props) => {
  const {
    onChange, versionsList, allParams
  } = props;

  return (
    versionsList && Array.isArray(versionsList) ? (
      <select
        className="form-control m-input m-input--air"
        id="pv_installer-reg"
        style={{
          height: 'calc(2.39rem + 4px)',
          cursor: 'pointer'
        }}
        onChange={
          (event) => onChange({
            firmware: event.target.value.trim()
          })
        }
        value={allParams.firmware || ''}
      >
        <option value="">{i18n.t('allVersions')}</option>
        {versionsList.map((version) => (
          <option key={`FWVSelectID_${shortid.generate()}`}>{version.version}</option>
        ))}
      </select>
    ) : null
  );
};
RenderFWVersionFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  versionsList: PropTypes.instanceOf(Array).isRequired,
  allParams: PropTypes.instanceOf(Object).isRequired
};

export default RenderFWVersionFilter;
