export const INSTALLED_SM_REPLACE_SELECTED = 'INSTALLED_SM_REPLACE_SELECTED';
export const INSTALLED_SM_SEND_UPDATE = 'INSTALLED_SM_SEND_UPDATE';
export const INST_SM_GET_FW_VERSIONS = 'INST_SM_GET_FW_VERSIONS';
export const INST_SM_FW_VERSIONS_RECEIVED = 'INST_SM_FW_VERSIONS_RECEIVED';
export const INSTALLED_SM_SEND_REQUEST = 'INSTALLED_SM_SEND_REQUEST';
export const INSTALLED_SM_REQUEST_STOP_LOADING = 'INSTALLED_SM_REQUEST_STOP_LOADING';
export const INST_SM_CHANGE_DEBUGGING = 'INST_SM_CHANGE_DEBUGGING';
export const FETCH_HARDWARE_LIST_REQUEST = 'FETCH_HARDWARE_LIST_REQUEST';
export const FETCH_HARDWARE_LIST_SUCCESS = 'FETCH_HARDWARE_LIST_SUCCESS';

export const DATA_LIST_ID = 'installed_sm';
export const DATA_LIST_URL = '/../gateway/list';

/**
 * Add gateway form id
 */
export const ADD_GATEWAY_FORM_ID = 'addGatewayForm';

/**
 * Max text length for gateways textarea.
 * 1000 strings 16 chars length every, plus one space and comma for each gateways.
 */
export const MAX_CHARS_COUNT = 18000;
