import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import TopBar from '../../components/TopBar';
import i18n from '../../i18n';
import ProductionStatistics from './components/ProductionStatistics';
import PlantsData from './components/PlantsData';
import OverviewPieCharts from './components/PieChart';
import CircleChart from './components/CircleChart';
import Widget from './components/Widget';
import { overviewDataSelector, visibleWebSelector } from './selectors';
import { requestOverviewData } from './actions';
import './index.v2.scss';

const Overview = (props) => {
  const { overviewData, requestOverviewData: requestOverviewDataFromProps, visibleWeb } = props;
  const {
    production,
    plants,
    supportContracts,
    monitoring,
    status,
    consumption,
    autarchy,
    totalEnergy
  } = overviewData;

  const { carChargers, heatpumps, waterHeaters, v2xChargers } = totalEnergy || {};

  useEffect(() => {
    requestOverviewDataFromProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopBar showNavigation visibleWeb={visibleWeb} />
      <div className="container-fluid overview">
        <div className="subheader-smu-table subheader-overview">
          <span className="emp-span">
            <i className="fa fa-home icons-employees" />
            {' '}
            -
            {' '}
            {i18n.t('overview')}
          </span>
        </div>
        <div className="overview-wrapper">
          <div className="overview-left-part">
            <ProductionStatistics productionData={production} />
            <PlantsData plants={plants} supportContracts={supportContracts} />
            <OverviewPieCharts monitoring={monitoring} status={status} />
          </div>
          <div className="overview-right-part">
            <CircleChart chartData={consumption} titleKey="overallSelfConsumption" />
            <CircleChart chartData={autarchy} titleKey="overallAutarchy" />

            <Widget widgetData={carChargers} deviceType="carCharger" />
            <Widget widgetData={waterHeaters} deviceType="waterHeater" />

            <Widget widgetData={heatpumps} deviceType="heatpump" />
            <Widget widgetData={v2xChargers} deviceType="v2x" />
          </div>
        </div>
      </div>
    </>
  );
};

Overview.propTypes = {
  overviewData: PropTypes.instanceOf(Object).isRequired,
  requestOverviewData: PropTypes.func.isRequired,
  visibleWeb: PropTypes.bool.isRequired
};

Overview.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  overviewData: overviewDataSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, { requestOverviewData })(Overview);
