import { createSelector } from 'reselect';

const getUserInfo = (state) => state.signIn?.user;
const getIsLoading = (state) => state.modals?.isLoading;
const getModalRemoveData = (state) => state.modals?.removeData;
const getModalAddData = (state) => state.modals?.addData;

export const userInfoSelector = createSelector([getUserInfo], (userInfo) => userInfo);
export const visibleWebSelector = createSelector([getUserInfo], (userInfo) => userInfo?.oem?.visible_web);
export const compLogoSelector = createSelector([getUserInfo], (userInfo) => userInfo?.company_logo);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const modalRemoveDataSelector = createSelector([getModalRemoveData], (modal) => modal);
export const modalAddDataSelector = createSelector([getModalAddData], (modal) => modal);
