import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import './index.sass';

const isChrome = window?.browser?.name === 'Chrome';
/**
 * Renders text input for redux form
 * @memberof module:ReduxFormFields
 */
class CustomTextInput extends Component {
  componentDidMount() {
    if (isChrome) {
      this.timeout = setTimeout(() => this.forceUpdate(), 1000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const {
      input,
      label,
      showTooltip,
      placement,
      meta: { touched, error },
      preventAuto,
      className,
      defaultValue,
      languageToSync,
      ...custom
    } = this.props;

    const labelProps = {
      className: 'inputLabel'
    };
    if (isChrome && this.myInputRef?.matches(':-webkit-autofill')) {
      labelProps.shrink = true;
    }

    return (
      <Fragment>
        {!!showTooltip && (
          <div className="switch-tooltip">
            <Tooltip title={i18n.t(showTooltip)} placement="right" />
          </div>
        )}
        <TextField
          variant="standard"
          inputRef={(node) => {
            this.myInputRef = node;
          }}
          label={label}
          defaultValue={defaultValue}
          {...input}
          {...custom}
          classes={{
            root: `customTextField ${className}`
          }}
          InputProps={{
            classes: {
              root: 'inputRoot',
              input: 'inputField'
            }
          }}
          inputProps={
            preventAuto
              ? {
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'off'
              }
              : {}
          }
          InputLabelProps={labelProps}
        />
        {touched && (error || languageToSync) && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )}
      </Fragment>
    );
  }
}

CustomTextInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool
};

CustomTextInput.defaultProps = {
  languageToSync: '',
  showTooltip: '',
  preventAuto: false,
  defaultValue: undefined,
  placement: undefined
};

export default CustomTextInput;
