import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import CommonPhoneInput from '../../components/ReduxFormFields/PhoneTextInput/CommonPhoneInput';
import '../../App.css';
import {
  required,
  zipCode,
  minLength,
  maxLength,
  checkValidPhone
} from '../validation';
import AvatarPreviewer from './components/AvatarPreviewer';
import CustomSelect from '../../components/ReduxFormFields/CustomSelect';
import LanguageSelector from '../../components/ReduxFormFields/LanguageSelector';
import { SETTING_PROFILE_FORM, statusList } from './constants';
import i18n from '../../i18n';
import { AccessRole } from './components/AccessRole';
import CancelButton from '../../components/UIKit/CancelButton';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
/**
 * Edit profile of user with roleType = 'pv_installer_employee'
 * @class FormEmployee
 * @memberof module:SettingProfile
 */
export class FormEmployee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: 'one',
      isFlagSelectOpen: false
    };
    this.statusList = statusList();
  }

  componentDidMount() {
    const { initialize, initialValues } = this.props;
    initialize(initialValues);
  }

  render() {
    const {
      handleSubmit,
      goBack,
      openChangeEmail,
      openChangePassword,
      myRoleType,
      userRoleName,
      first_name,
      last_name,
      initialValues,
      myself,
      pristine,
      submitting
    } = this.props;

    const hasInstallerRights = ['root', 'solar_admin', 'pv_installer', 'support', 'oem'].includes(myRoleType);
    const roleDependencies = { disabled: !myself && !hasInstallerRights };

    return (
      <form onSubmit={handleSubmit}>
        <div className="nav-settings">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-auto mr-sm-auto">
                <h6 className="set-prof-head">
                  {i18n.t('settingsProfile')}
                  {!myself && (
                    <Fragment>
                      :&nbsp;
                      {i18n.t(userRoleName)}
                    </Fragment>
                  )}
                </h6>
              </div>
              {!roleDependencies.disabled && (
                <div className="col-auto">
                  <button
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
                    type="button"
                  >
                    {i18n.t('saveChanges')}
                  </button>
                </div>
              )}
              <div className="col-auto cont-for-canc-btn">
                <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details role-type">
            <div className="col-md-3 col-auto user-photo">
              <Field
                name="avatar"
                type="file"
                userName={`${first_name} ${last_name}`}
                avatar={initialValues?.avatarURL}
                component={AvatarPreviewer}
                {...roleDependencies}
              />
              {hasInstallerRights && (
                <div className="d-flex justify-content-center notification-type-error-container m--margin-top-35">
                  <AccessRole />
                </div>
              )
              }
            </div>
            <div className="col-md-8">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  {hasInstallerRights && (
                    <div className="form-group m-form__group input-field">
                      <div className="flags-select-label">{i18n.t('status')}</div>
                      <Field
                        name="status"
                        component={CustomSelect}
                        placeholder={i18n.t('selectStatus')}
                        options={this.statusList}
                        isSearchable={false}
                        validate={[required]}
                        filterOption={({ value }) => (value === 'active' || value === 'deactivated')}
                        {...roleDependencies}
                      />
                    </div>
                  )}
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="first_name"
                      component={CustomTextInput}
                      label={i18n.t('reqFirstName')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="last_name"
                      component={CustomTextInput}
                      label={i18n.t('reqLastName')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="phone"
                      component={CommonPhoneInput}
                      label={i18n.t('phoneNumber')}
                      className="m-input"
                      autoComplete="off"
                      country={initialValues?.country || 'Switzerland'}
                      phone={initialValues?.phone}
                      validate={[checkValidPhone]}
                      {...roleDependencies}
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      disabled
                      name="old_email"
                      component={CustomTextInput}
                      label={i18n.t('mail')}
                      className="m-input"
                    />
                    {!roleDependencies.disabled && (
                      <>
                        <button
                          type="button"
                          onClick={openChangeEmail}
                          className="change-btn"
                        >
                          {i18n.t('changeMail')}
                        </button>
                      </>
                    )}
                  </div>
                  {myself && (
                    <div className="form-group m-form__group input-field input-field-for-ch-pass">
                      <Field
                        name="password"
                        type="password"
                        disabled
                        component={CustomTextInput}
                        label={i18n.t('pass')}
                        className="m-input"
                        input={{ value: 'password' }}
                      />
                      <button
                        type="button"
                        onClick={openChangePassword}
                        className="change-btn"
                      >
                        {i18n.t('changePass')}
                      </button>
                    </div>
                  )}
                  <div className="form-group m-form__group input-field input-filed-flags-select">
                    <div className="flags-select-label">{i18n.t('reqLanguage')}</div>
                    <Field
                      name="language"
                      component={LanguageSelector}
                      label={i18n.t('reqLanguage')}
                      buttonClassName="reqLanguage-btn"
                      id="reqLanguage"
                      className="m-input flagSelectClosed"
                      {...roleDependencies}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="row align-items-md-start justify-content-md-start justify-content-center">
            <div className="col-md-3 col-auto user-photo" />
            <div className="col-md-8">
              <div className="row justify-content-between align-items-start">
                <div className="col-lg-5">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="street"
                      component={CustomTextInput}
                      label={i18n.t('street')}
                      className="m-input"
                      autoComplete="off"
                      validate={[minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="row city-zip">
                    <div className="form-group m-form__group input-field col-7 city-inp city-inp-comp-set">
                      <Field
                        name="city"
                        component={CustomTextInput}
                        label={i18n.t('city')}
                        className="m-input"
                        autoComplete="off"
                        validate={[minLen3, maxLen50]}
                        {...roleDependencies}
                      />
                    </div>
                    <div className="form-group m-form__group input-field offset-1 col-4 zip-inp zip-inp-comp-set">
                      <Field
                        name="zip"
                        component={CustomTextInput}
                        label={i18n.t('zip')}
                        className="m-input"
                        autoComplete="off"
                        validate={[zipCode]}
                        {...roleDependencies}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FormEmployee.propTypes = {
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  dataList: PropTypes.instanceOf(Array),
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

FormEmployee.defaultProps = {
  openChangeEmail: undefined,
  myRoleType: undefined,
  last_name: undefined,
  first_name: undefined,
  openChangePassword: undefined,
  goBack: undefined,
  dataList: undefined,
  userRoleName: undefined,
  myself: undefined
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormEmployee);

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

const mapStateToProps = createStructuredSelector({
  first_name: (state) => valueSelector(state, 'first_name'),
  last_name: (state) => valueSelector(state, 'last_name'),
  avatar: (state) => valueSelector(state, 'avatar')
});

export default connect(
  mapStateToProps
)(form);
