/**
 * Validates if received data is correct for displaying
 * @memberof module:DashboardChart
 * @param {ChartData} data Array of chart data (consumption or production)
 * @param {Object} intervals
 * @param {number} intervals.dataInterval Current (real) interval between points (after grouping them on back-end). Milliseconds.
 * @param {number} intervals.dataSingleInterval Single interval between points (before grouping points on back-end). Milliseconds.
 * @param {number} scaleMS Distance between start and end of chart (milliseconds)
 * @param {string} message Message what will be shown in console (in case data has an error) to identify data name (ex. consumption or production)
 * @returns {undefined}
 */
const chartDataValidator = (data, { dataInterval, dataSingleInterval }, scaleMS, message) => {
  if (data.length > 1) {
    const chartsWidth = document.documentElement.clientWidth - 60;
    const minOffset = Math.ceil((scaleMS / chartsWidth) * 2);

    const maxInterval = Math.max(dataInterval * 1.1, dataSingleInterval * 4.1);
    const errors = data.filter(
      (element, index, array) => {
        if (index < array.length - 1) {
          if (!Array.isArray(element)) {
            console.error(`${message} Index: ${index}. Reason: element is not array.`, element);
            return true;
          }
          if (!Number.isFinite(element[0]) || Number.isNaN(element[0]) || element[0] < 0) {
            console.error(`${message} Index: ${index}. Reason: looks like timestamp is wrong - ${element[0]}`);
            return true;
          }
          if ((!Number.isFinite(element[1]) || Number.isNaN(element[1]) || element[1] < 0) && element[1] !== null) {
            console.error(`${message} Index: ${index}. Reason: looks like value of point is wrong - ${element[1]}`);
            return true;
          }
          if (element[0] > array[index + 1][0]) {
            console.error(`${message} Index: ${index}. Reason: next point has less timestamp (${element[0]} > ${array[index + 1][0]})`);
            return true;
          }
          if (
            array[index + 1][0] - element[0] > maxInterval
            && maxInterval > minOffset
            && (array[index + 1][1] !== null && element[1] !== null)
          ) {
            console.error(`${message} Index: ${index}. Reason: next point has too big interval (${array[index + 1][0] - element[0]} ms.). Expected max.: ${maxInterval} ms.`);
            return true;
          }
        }
        return false;
      }
    );
    console.log(`${message}. Found ${errors.length} errors.`);
    console.dir(data);
  }
};

export default chartDataValidator;
