import { EMPTY, from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { replace } from 'react-router-redux';
import FormData from 'form-data';
import { saveAs } from 'file-saver';
import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import * as actionTypes from './constants';
import {
  installedSMReplaceSelected,
  instSMFWVersionsRecieved,
  installedSMRequestStopLoading,
  fetchHardwareListSuccess
} from './actions';
import { addNotification } from '../NotificationGenerator/actions';
import { closeModalWindow } from '../ModalWindow/actions';
import i18n from '../../i18n';

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function iSMGetFWVersionsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.INST_SM_GET_FW_VERSIONS),
    map((action) => action.payload),
    switchMap(() => from(strapi.request('get', '/../firmware/avaliable')).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result)) {
            return instSMFWVersionsRecieved(result);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function iSMSendRequestEpic($action) {
  return $action.pipe(
    ofType(actionTypes.INSTALLED_SM_SEND_REQUEST),
    map((action) => action.payload),
    switchMap(({
      method, url, config, onResult = {}, modalID
    }) => from(strapi.request(method, url, config)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (typeof onResult.callback === 'function') {
          return of(
            onResult.callback(result),
            installedSMRequestStopLoading(modalID)
          );
        }
        if (typeof onResult.cb === 'function') onResult.cb(result);
        let fileReceived;
        if (onResult.downloadFile) {
          if (result instanceof Blob) {
            fileReceived = true;
            saveAs(result, `sm-manager-${Date.now()}.csv`);

            return of(installedSMRequestStopLoading(modalID));
          }
        }

        if (result.ok || !result.error || fileReceived) {
          if (!onResult.successMessage) {
            return of(
              EMPTY,
              installedSMRequestStopLoading(modalID)
            );
          }
          return of(
            closeModalWindow(modalID),
            installedSMRequestStopLoading(modalID),
            addNotification({
              type: 'success',
              text: i18n.t(onResult.successMessage)
            })
          );
        }
        if (!onResult.errorMessage) {
          return of(
            EMPTY,
            installedSMRequestStopLoading(modalID)
          );
        }
        return of(
          closeModalWindow(modalID),
          installedSMRequestStopLoading(modalID),
          addNotification({
            type: 'error',
            text: i18n.t(onResult.errorMessage)
          })
        );
      })
    )
    )
  );
}

/**
 * Sends selected rows to update
 * @memberof module:InstalledSM
 */
function iSMUpdateSelectedEpic($action, $state) {
  return $action.pipe(
    ofType(actionTypes.INSTALLED_SM_SEND_UPDATE),
    map((action) => action.payload.fields),
    switchMap((fields) => {
      const
        { installedSM: { selected } } = $state.value;
      const myForm = new FormData();
      const fieldsKeys = Object.keys(fields);
      if (typeof fields.file === 'undefined') {
        return of(
          closeModalWindow('updateConfirmation'),
          installedSMRequestStopLoading('updateConfirmation'),
          addNotification({
            type: 'error',
            text: i18n.t('selectFileForUpdate')
          })
        );
      }
      if (!fields.version || fields.version.length < 3 || fields.version.length > 50) {
        return of(
          closeModalWindow('updateConfirmation'),
          installedSMRequestStopLoading('updateConfirmation'),
          addNotification({
            type: 'error',
            text: i18n.t('invalidNameOfVersion')
          })
        );
      }
      if (selected.length <= 0) {
        return of(
          closeModalWindow('updateConfirmation'),
          installedSMRequestStopLoading('updateConfirmation'),
          addNotification({
            type: 'error',
            text: i18n.t('selectDevicesToUpdate')
          })
        );
      }
      fieldsKeys.forEach((fieldID) => {
        if (fieldID === 'version') {
          myForm.append(fieldID, fields[fieldID] ? fields[fieldID].trim() : '');
        } else {
          myForm.append(fieldID, fields[fieldID]);
        }
      });
      myForm.append('gateways', selected);

      return (
        from(strapi.request('post', '/../firmware/update', {
          data: myForm
        })).pipe(
          catchError(handleErrorDetailed),
          mergeMap((result) => {
            if (!result.error) {
              return of(
                addNotification({
                  type: 'success',
                  text: i18n.t('updateRequestCreated')
                }),
                replace(),
                installedSMReplaceSelected(),
                installedSMRequestStopLoading('updateConfirmation'),
                closeModalWindow('updateConfirmation')
              );
            }
            return of(
              addNotification({
                type: 'error',
                text: result.message
              })
            );
          })
        )
      );
    })
  );
}

function fetchHardwareListEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FETCH_HARDWARE_LIST_REQUEST),
    switchMap(() => (from(strapi.request('get', '/gateways/hardware')).pipe(
      catchError(handleErrorDetailed),
      map((result) => result && fetchHardwareListSuccess(result))
    )
    ))
  );
}

export default combineEpics(
  iSMUpdateSelectedEpic,
  iSMGetFWVersionsEpic,
  iSMSendRequestEpic,
  fetchHardwareListEpic
);
