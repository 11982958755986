import React from 'react';
import PropTypes from 'prop-types';
import { StatusMessage } from '../../components/UIKit';

/**
 * @module Notification
 */
/**
 * Renders one notification
 * @memberof module:Notification
 */
const Notification = (props) => {
  const onClick = () => {
    const { deleteNotification, message } = props;
    deleteNotification(message.id);
  };

  const { message } = props;
  const { type, text } = message;

  return (
    <StatusMessage type={type} text={text} onClick={onClick} />
  );
};

Notification.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
  deleteNotification: PropTypes.func.isRequired
};

export default Notification;
