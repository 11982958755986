import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import CustomTextArea from '../../components/ReduxFormFields/CustomTextArea';
import ModalWindow from '../ModalWindow';
import Preloader from '../../components/Preloader';
import i18n from '../../i18n';
import './index.css';
import { modalSelector, textSelector, noteTextSelector, isLoadingSelector, initialValuesSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

const MAX_CHARS_COUNT = 300;

/**
 * Modal window to change note
 * @memberof module:Monitoring
 */
const EditNoteWindow = (props) => {
  const {
    modal,
    reset,
    handleOnClose,
    handleOnSubmit,
    modalID,
    isLoading,
    noteText
  } = props;

  const {
    data: {
      ticketId
    }
  } = modal || {};

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleOnSubmit({ ticketId, noteText });
  };

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title text-capitalize">{i18n.t('note')}</h5>
      </div>
      {isLoading ? (<div className="note-edit-prealoder-container"><Preloader /></div>) : (
        <form
          id="monitoringNote"
          onSubmit={handleSubmit}
        >
          <div className="modal-body">
            <Field
              name="note"
              type="text"
              component={CustomTextArea}
              className="input-note"
              maxLength={MAX_CHARS_COUNT}
            />
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              {i18n.t('save')}
            </button>
          </div>
        </form>
      )}
    </ModalWindow>
  );
};

EditNoteWindow.propTypes = {
  modalID: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  noteText: PropTypes.string
};

EditNoteWindow.defaultProps = {
  modalID: '',
  modal: {
    data: {}
  },
  noteText: undefined,
  isLoading: false
};

const form = reduxForm({
  form: 'monitoringNoteForm',
  enableReinitialize: true
})(EditNoteWindow);

const mapStateToProps = createStructuredSelector({
  initialValues: initialValuesSelector,
  modal: modalSelector,
  text: textSelector,
  noteText: noteTextSelector,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps)(form);
