import React from 'react';
import { get } from 'lodash';
import {
  RenderHeaderWithTooltip,
  renderSingleButton,
  renderPaidLicense,
  renderSwitcher,
  RenderSignal,
  RenderStatus,
  renderLicense
} from '../../components/ReactTable/renderFunctions';
import { dataListUpdateRow } from '../DataList/actions';
import store from '../../store';
import { installedSMSendRequest } from './actions';
import i18n from '../../i18n';
import { DATA_LIST_ID } from './constants';
import { openModalWindow } from '../ModalWindow/actions';
import { SOLAR_MANAGER_APP_ID, HELION_APP_ID, APP_ID } from '../../config';
import RenderRebootDropdown from '../../components/ReactTable/renderFunctions/renderRebootDropdown';

const sendLogRequest = (gatewayID) => store.dispatch(
  installedSMSendRequest(
    'post',
    `/../account/request/log/${gatewayID}`,
    undefined,
    { successMessage: 'logRequestSuccessful', errorMessage: 'logRequestError' }
  )
);
const removeGateway = (gatewayID, sm_id) => {
  store.dispatch(openModalWindow('confirmRemoveGateway', { id: gatewayID, sm_id }));
};
const removeFirmwareUpdate = (gatewayID) => {
  store.dispatch(openModalWindow('removeFirmwareUpdate', { id: gatewayID }));
};

const sendRebootRequest = (gatewayID) => store.dispatch(
  installedSMSendRequest(
    'post',
    `/../account/request/reboot/${gatewayID}`,
    undefined,
    { successMessage: 'rebootRequestSuccessful', errorMessage: 'rebootRequestError' }
  )
);

const sendForceRebootRequest = (gatewayID) => store.dispatch(
  installedSMSendRequest(
    'post',
    `/../account/request/force-reboot/${gatewayID}`,
    undefined,
    { successMessage: 'rebootRequestSuccessful', errorMessage: 'rebootRequestError' }
  )
);
const toggleSwitcher = (rowID, enabled, gatewayID) => {
  store.dispatch(
    installedSMSendRequest(
      'post',
      `/../account/request/debug-log/${gatewayID}`,
      { data: { debug_logging: enabled } },
      { errorMessage: 'debugLoggingFailed' }
    )
  );
  store.dispatch(
    dataListUpdateRow('installed_sm', rowID, { debug_logging: enabled })
  );
};
const getDropdownWidth = (lang) => {
  switch (lang) {
    case 'French':
      return 182;
    case 'Deutsch':
      return 162;
    case 'Italian':
      return 136;
    case 'Danish':
      return 140;
    default:
      return 150;
  }
};

const columns = (_, language) => ([
  {
    Header: i18n.t('SMID'),
    accessor: 'sm_id',
    minWidth: 180,
    className: 'sm_id'
  },
  {
    Header: i18n.t('hardware'),
    accessor: 'hardware',
    minWidth: 180,
    className: ''
  },
  {
    Header: i18n.t('firmwareVersion'),
    accessor: 'firmware',
    minWidth: 175,
    className: ''
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    accessor: 'signal',
    minWidth: 160,
    Cell: (row) => RenderSignal(row),
    filterable: false,
    sortable: false
  },
  {
    Header: i18n.t('accStatus'),
    id: 'status',
    accessor: (d) => (d.owner?.status || ''),
    minWidth: 160,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    show: false
  },
  {
    Header: i18n.t('company'),
    id: 'company',
    accessor: (d) => (d.company?.name || i18n.t('noComp')),
    minWidth: 160,
    show: false
  },
  {
    Header: i18n.t('License'),
    accessor: (row) => ({
      licenseId: get(row, 'owner.license.licenseCode'),
      licenseTitle: get(row, 'owner.license.name', ''),
      userId: get(row, 'owner._id'),
      hide: !row.owner,
      parentListId: DATA_LIST_ID
    }),
    id: 'license',
    minWidth: 160,
    Cell: [HELION_APP_ID, SOLAR_MANAGER_APP_ID].includes(APP_ID) ? renderPaidLicense : renderLicense,
    sortable: false
  },
  {
    Header: i18n.t('sendLogRequest'),
    accessor: '',
    id: 'sendLogBtn',
    minWidth: 90,
    Cell: renderSingleButton({
      callback: sendLogRequest,
      title: i18n.t('sendLogRequest')
    }),
    sortable: false
  },
  {
    Header: () => <div className="rt-resizable-header-content rebootRequest-header">{i18n.t('rebootRequest')}</div>,
    accessor: 'rebootReqBtn',
    className: `rebootReqBtn ${language}`,
    id: 'rebootReqBtn',
    resizable: false,
    minWidth: getDropdownWidth(language),
    Cell: (row) => (
      // eslint-disable-next-line react/react-in-jsx-scope
      <RenderRebootDropdown
        rebootRequest={sendRebootRequest}
        forceRebootRequest={sendForceRebootRequest}
        row={row}
      />
    ),
    sortable: false
  },
  {
    Header: i18n.t('debugLogging'),
    accessor: 'debug_logging',
    className: 'switcher-logging',
    minWidth: 90,
    Cell: renderSwitcher({ callback: toggleSwitcher })
  },
  {
    Header: i18n.t('removeDevice'),
    accessor: '',
    minWidth: 90,
    Cell: renderSingleButton({
      callback: removeGateway,
      title: i18n.t('removeBtn')
    }),
    sortable: false
  },
  {
    Header: i18n.t('removeFirmware'),
    accessor: '',
    minWidth: 90,
    Cell: renderSingleButton({
      callback: removeFirmwareUpdate,
      title: i18n.t('removeBtn')
    }),
    sortable: false
  }
]);

export default columns;
