import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import i18n from '../../../../i18n';

import './index.scss';


const CircleChart = (props) => {
  const { titleKey, chartData } = props;
  const { lastMonth, lastYear, overall } = chartData || {};

  const options = {
    responsive: true,
    cutout: '25%',
    borderWidth: 0,
    elements: {
      arc: {
        roundedCornersFor: {
          start: 0,
          end: 0
        }
      }
    },
    hoverBorderColor: '#DB422D'
  };

  const chartPluginsSet = [{
    /**
     * After update. Calculation, needed for rounded edges.
     * @param {object} chart
     */
    afterUpdate(chart) {
      if (chart.options.elements.arc.roundedCornersFor !== undefined) {
        const arcValues = Object.values(chart.options.elements.arc.roundedCornersFor);

        arcValues.forEach((arcs) => {
          arcs = Array.isArray(arcs) ? arcs : [arcs];
          arcs.forEach((i) => {
            chart.config._config.data.datasets.forEach((item, index) => {
              const arc = chart.getDatasetMeta(index)?.data?.[i];
              if (arc) {
                arc.round = {
                  x: (chart.chartArea.left + chart.chartArea.right) / 2,
                  y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                  radius: (arc.outerRadius + arc.innerRadius) / 2,
                  thickness: (arc.outerRadius - arc.innerRadius) / 2,
                  backgroundColor: arc.options.backgroundColor
                };
              }
            });
          });
        });
      }
    },

    /**
     * After draw. Draw rounded edges.
     * @param {object} chart
     */
    afterDraw: (chart) => {
      if (chart.options.elements.arc.roundedCornersFor !== undefined) {
        const { ctx } = chart;
        const { roundedCornersFor } = chart.options.elements.arc;

        Object.keys(roundedCornersFor).forEach((position) => {
          const values = Array.isArray(roundedCornersFor[position]) ? roundedCornersFor[position] : [roundedCornersFor[position]];

          values.forEach((p) => {
            chart.config._config.data.datasets.forEach((item, index) => {
              const arc = chart.getDatasetMeta(index)?.data?.[p];
              if (arc) {
                const startAngle = (Math.PI / 2) - arc.startAngle;
                const endAngle = (Math.PI / 2) - arc.endAngle;
                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);
                ctx.fillStyle = arc.options.backgroundColor;
                ctx.beginPath();
                if (position === 'start') {
                  ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
                } else {
                  ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                  ctx.shadowColor = '#00000080';
                  ctx.shadowBlur = 0;
                  ctx.shadowOffsetX = 1;
                  ctx.shadowOffsetY = 0;
                }
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              }
            });
          });
        });
      }
    }
  }];


  const data = {
    datasets: [
      {
        data: [overall, 100 - overall],
        backgroundColor: ['#DB422D', '#db422d1a']
      },
      {},
      {
        data: [lastYear, 100 - lastYear],
        backgroundColor: ['#3998F9', '#3998F91a']
      },
      {},
      {
        data: [lastMonth, 100 - lastMonth],
        backgroundColor: ['#4CCD37', '#4CCD371a']
      }
    ]
  };

  return (
    <div className="circle-chart-wrapper">
      <div className="title">{i18n.t(titleKey)}</div>
      <Doughnut
        data={data}
        options={options}
        plugins={chartPluginsSet}
      />
      <div className="chart-info">
        <div className="chart-info-row">
          <div className="label">{i18n.t('1month')}</div>
          <div className="value month">
            {lastMonth}
            %
          </div>
        </div>
        <div className="chart-info-row">
          <div className="label">{i18n.t('1year')}</div>
          <div className="value year">
            {lastYear}
            %
          </div>
        </div>
        <div className="chart-info-row">
          <div className="label">{i18n.t('overall')}</div>
          <div className="value overall">
            {overall}
            %
          </div>
        </div>
      </div>
    </div>
  );
};

CircleChart.propTypes = {
  titleKey: PropTypes.string.isRequired,
  chartData: PropTypes.instanceOf(Object)
};

CircleChart.defaultProps = {
  chartData: undefined
};

export default CircleChart;
