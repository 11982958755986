import React from 'react';
import PropTypes from 'prop-types';
import ArrowDown from '../../../../../../assets/images/svg/ArrowDown.svg';
import { getFormattedListOfDays } from '../../../helper';
import i18n from '../../../../../../i18n';
import { OFF, ON, SELECT_ALL } from '../../../constants';

import './index.scss';

const Closed = (props) => {
  const {
    handleToggleCollapse,
    value: {
      from,
      to,
      activeDays,
      switchAction
    }
  } = props;

  const slicedWeekDays = getFormattedListOfDays(activeDays).includes(SELECT_ALL)
    ? i18n.t(getFormattedListOfDays(activeDays)[0])
    : getFormattedListOfDays(activeDays).map((day) => i18n.t(day).slice(0, 3)).join(' ');

  return (
    <div className="closed">
      <div className="closed-time__container">
        <div className="closed-time__range">
          <span>{`${from} - ${to}`}</span>
          <p>{slicedWeekDays}</p>
        </div>
        <div className="closed-time__switch">
          <h2>{switchAction === 1 ? i18n.t(ON) : i18n.t(OFF)}</h2>
        </div>
      </div>
      <div className="closed-button">
        <img src={ArrowDown} alt="Down" onClick={handleToggleCollapse} />
      </div>
    </div>
  );
};

Closed.propTypes = {
  handleToggleCollapse: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired
};

export default Closed;
