import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderAccess
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';


const columnsEmp = (myRoleType) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'full_name',
    minWidth: 180,
    className: '',
    Cell: (row) => RenderFullName(row)
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: 225,
    className: 'email-text-td',
    Cell: (row) => RenderEmail(row)
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: 160,
    className: 'city-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('access'),
    minWidth: 100,
    Cell: (row) => RenderAccess(row),
    sortable: false
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        editUser: { preLink: '/setting-profile/employee/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin' || myRoleType === 'pv_installer')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columnsEmp;
