import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { handleError } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { forgotPasswordReceived, forgotPasswordCanceled } from './actions';
import i18n from '../../i18n';

function sendForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FORGOT_PASSWORD_SEND),
    map((action) => action.payload),
    switchMap(({ fields: { email, language } }) => (
      from(strapi.request('post', '/../auth/forgot-password', {
        data: { email },
        params: { language }
      })).pipe(
        catchError(handleError),
        map(
          (result) => {
            if (result?.email) {
              return forgotPasswordReceived(result.email, result.verifyEmail);
            }

            return forgotPasswordCanceled(result);
          }
        )
      )
    ))
  );
}

function receiveForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FORGOT_PASSWORD_RECEIVED),
    map((action) => action.payload.user),
    switchMap(() => of(
      addNotification({
        type: 'success',
        text: i18n.t('messageWithListSent')
      })
    ))
  );
}

function cancelForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FORGOT_PASSWORD_CANCELED),
    map((action) => action.payload.message),
    switchMap((message) => of(
      addNotification({
        type: 'error',
        text: message
      })
    ))
  );
}

export default combineEpics(
  sendForgotPasswordEpic, receiveForgotPasswordEpic, cancelForgotPasswordEpic
);
