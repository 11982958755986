import { createSelector } from 'reselect';
import { COMPANY_DATALIST_ID } from './constants';

const getCompanyOemId = (state) => state.dataList?.[COMPANY_DATALIST_ID]?.data?._id;
const getSupportOemId = (state) => state.dataList?.[COMPANY_DATALIST_ID]?.data?.oem?._id;
const getIsLoading = (state) => state.modals?.isLoading;
const getModalDeleteData = (state) => state.modals.deleteData;
const getModalAddPvInstaller = (state) => state.modals.addPvInstallerToOEM || {};
const getUser = (state) => state.signIn?.user;
const getPvInstallers = (state) => state.pvInstallersDetails?.pvInstallersList || [];
const getAllConnectedInstallers = (state) => state.pvInstallersDetails?.allPvInstallersList || [];
const getListOfOEMsFromTable = (state) => state.dataList?.company_sm_oem?.data || [];

export const companyOemIdSelector = createSelector([getCompanyOemId], (id) => id);
export const supportOemIdSelector = createSelector([getSupportOemId], (id) => id);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const modalDeleteDataSelector = createSelector([getModalDeleteData], (modal) => modal);
export const modalAddPvInstallerSelector = createSelector([getModalAddPvInstaller], (modal) => modal);
export const userRoleTypeSelector = createSelector([getUser], (user) => user?.role?.type);
export const pvInstallersSelector = createSelector([getPvInstallers], (pvInstallersList) => pvInstallersList);
export const allPvInstallersListSelector = createSelector([getAllConnectedInstallers], (allPvInstallersList) => allPvInstallersList);
export const listOfOEMsFromTableSelector = createSelector([getListOfOEMsFromTable], (data) => data);

export const filteredInstallersSelector = createSelector([getPvInstallers, getAllConnectedInstallers],
  (pv_installers, allConnectedInstallers) => {
    const filteredInstallersNew = pv_installers
      .filter((installer) => installer?.my_company
        && !allConnectedInstallers.some((connectedInstaller) => installer._id === connectedInstaller._id)
      );

    const filteredInstallersData = filteredInstallersNew.map(({ _id, first_name, last_name, isChecked = false }) => (
      { _id, name: `${first_name} ${last_name}`, isChecked }
    ));

    return filteredInstallersData;
  }
);
