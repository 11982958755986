import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
/**
 * Render function for React-Table. Column for company name
 * @memberof module:ReactTable
 * @param  {string} value - company name
 */
const RenderGateway = ({ value }) => (!value ? (
  <Fragment>{i18n.t('noSolarManager')}</Fragment>
) : (
  <Fragment>{value}</Fragment>
));

RenderGateway.propTypes = {
  value: PropTypes.string
};

RenderGateway.defaultProps = {
  value: undefined
};

export default RenderGateway;
