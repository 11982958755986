import React from 'react';
import PropTypes from 'prop-types';
import formatDateTime from '../../../utils/formatDateTime';
/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderDate = ({ value }) => (
  <React.Fragment>{formatDateTime(value)}</React.Fragment>
);

RenderDate.propTypes = {
  value: PropTypes.string
};

RenderDate.defaultProps = {
  value: undefined
};

export default RenderDate;
