import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, groupBy, mergeMap, switchMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { receiveNotificationInfo } from './actions';

function SendRequestEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.SEND_REQUEST),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap((group) => group.pipe(
      switchMap(
        ({ data }) => (
          from(strapi.request('post', '/emailnotification/update-notification', { data })).pipe(
            catchError(handleErrorDetailed),
            mergeMap(
              (result) => {
                if (!result.error) {
                  return of(
                    receiveNotificationInfo(result)
                  );
                }

                return of(
                  addNotification({
                    type: 'error',
                    text: result.message
                  })
                );
              }
            )
          )
        )
      )
    )
    )
  );
}

export default combineEpics(
  SendRequestEpic
);
