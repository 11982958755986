import {
  RECEIVE_FORM_DATA,
  CLEAR_SENSORS_OAUTH2_FORM_DATA,
  SENSORS_OAUTH2_ALLOW_TO_DISABLED_EDIT_MODAL,
  REQUEST_ACCESS_TOKEN
} from './constants';

export function requestAccessToken(body, headers, currentModalId, currentModalData) {
  return {
    type: REQUEST_ACCESS_TOKEN,
    payload: { body, headers, currentModalId, currentModalData }
  };
}

export function receiveFormData(data) {
  return {
    type: RECEIVE_FORM_DATA,
    payload: { data }
  };
}

export function sensorsOauth2AllowedToDisabledEditModal(data) {
  return {
    type: SENSORS_OAUTH2_ALLOW_TO_DISABLED_EDIT_MODAL,
    payload: { data }
  };
}

export function clearSensorsOauth2FormData() {
  return {
    type: CLEAR_SENSORS_OAUTH2_FORM_DATA
  };
}
