import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { goBack } from 'react-router-redux';
import ViewByEndUsers from './components/ViewByEndUsers';
import ViewByAdmins from './components/ViewByAdmins';
import {
  getUserInfo,
  getIsSupportContractFlagAllowedToBeChanged,
  getUserSubscription,
  fetchLastSensorsData,
  clearUserData,
  resetSubscription
} from './actions';
import { settingPrGetUser } from '../SettingProfile/actions';
import Footer from '../../components/Footer';
import { selectedUserSelector, userSelector, userSubscriptionSelector } from './selectors';
import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../config';

import './index.scss';

/**
 * @module UserDetails
 */
/**
 * User details page
 * @memberof module:UserDetails
 */
const UserDetails = (props) => {
  const {
    myRoleType,
    match,
    user,
    getUserSubscription: getUserSubscriptionFromProps,
    fetchLastSensorsData: fetchLastSensorsDataFromProps,
    clearUserData: clearUserDataFromProps,
    resetSubscription: resetSubscriptionFromProps,
    userSubscription
  } = props;

  const { userID } = match?.params;

  useEffect(() => {
    if (myRoleType !== 'end_user') {
      const {
        getUserInfo: getUserInfoFromProps,
        getIsSupportContractFlagAllowedToBeChanged: getIsSupportContractFlagAllowedToBeChangedFromProps
      } = props;

      getUserInfoFromProps(userID);
      getIsSupportContractFlagAllowedToBeChangedFromProps(userID);
    } else {
      const { settingPrGetUser: settingPrGetUserFormProps } = props;
      settingPrGetUserFormProps(undefined, true, 'solar-managers-users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAllowedToShowSubscription = [SOLAR_MANAGER_APP_ID, HELION_APP_ID].includes(APP_ID) && ['pv_installer', 'pv_installer_employee', 'root', 'solar_admin', 'end_user'].includes(myRoleType);
    const isEndUser = ['end_user'].includes(myRoleType);

    if (isAllowedToShowSubscription && user?.company?.name) {
      getUserSubscriptionFromProps(isEndUser ? user._id : userID);
    }

    return () => {
      if (userSubscription.webViewUrl) {
        resetSubscriptionFromProps();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      clearUserDataFromProps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myRoleType !== 'end_user') {
      if (user?.gateway?._id) {
        fetchLastSensorsDataFromProps({ myRoleType, gatewayId: user?.gateway?._id });
      }
    } else {
      fetchLastSensorsDataFromProps({ myRoleType });
    }
  }, [fetchLastSensorsDataFromProps, myRoleType, user?.gateway?._id]);

  return (
    <Fragment>
      {myRoleType === 'end_user' ? (
        <ViewByEndUsers myRoleType={myRoleType} />
      ) : (
        <ViewByAdmins user={user} myRoleType={myRoleType} userID={match.params.userID} />
      )}
      <Footer />
    </Fragment>
  );
};

UserDetails.propTypes = {
  user: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserSubscription: PropTypes.func.isRequired,
  clearUserData: PropTypes.func.isRequired,
  settingPrGetUser: PropTypes.func.isRequired,
  fetchLastSensorsData: PropTypes.func.isRequired,
  getIsSupportContractFlagAllowedToBeChanged: PropTypes.func.isRequired,
  resetSubscription: PropTypes.func.isRequired,
  userSubscription: PropTypes.instanceOf(Object)
};

UserDetails.defaultProps = {
  user: undefined,
  userSubscription: {}
};

function mapStateToProps(_state, props) {
  const { myRoleType } = props;
  return createStructuredSelector({
    user: myRoleType !== 'end_user' ? selectedUserSelector : userSelector,
    userSubscription: userSubscriptionSelector
  });
}

export default connect(mapStateToProps, {
  getUserInfo,
  goBack,
  getUserSubscription,
  settingPrGetUser,
  fetchLastSensorsData,
  getIsSupportContractFlagAllowedToBeChanged,
  clearUserData,
  resetSubscription
})(UserDetails);
