import { EMPTY, from, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { handleError, trimAllFields } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import {
  receiveSignUp, sendEmailChangeSucc, reveivedInfoFromServer
} from './actions';
import { APP_ID } from '../../config';

function sendSignUpEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.SIGN_UP_SEND),
    map((action) => action.payload),
    switchMap(
      ({ type, language, fields }) => {
        let url;
        if (type === 'user') {
          url = '/../signup/user';
        } else if (type === 'company') {
          url = '/../signup/pv-installer';
        }
        return url
          ? from(strapi.request('post', url, {
            data: { ...trimAllFields(fields), app_identifier: APP_ID },
            params: { language }
          })
          ).pipe(
            catchError(handleError),
            mergeMap((result) => {
              if (result?.email) {
                return of(
                  reveivedInfoFromServer(),
                  receiveSignUp(result.email, result.verifyEmail)
                );
              }
              return of(
                reveivedInfoFromServer(),
                addNotification({
                  type: 'error',
                  text: result
                })
              );
            })
          )
          : EMPTY;
      })
  );
}

function sendSignUpChangeEmail(action$) {
  return action$.pipe(
    ofType(actionTypes.SIGN_UP_CHANGE_EMAIL_SEND),
    map((action) => action.payload),
    switchMap(({ old_email, new_email, language }) => from(
      strapi.request('post', '/../signup/change/email', {
        data: trimAllFields({ old_email, new_email }),
        params: { language }
      })
    ).pipe(
      catchError(handleError),
      map((result) => {
        if (result?.email) {
          return sendEmailChangeSucc(result.email, result.verifyEmail);
        }

        return addNotification({
          type: 'error',
          text: result
        });
      })
    )
    )
  );
}

function reSendSignUp(action$) {
  return action$.pipe(
    ofType(actionTypes.SIGN_UP_RESEND),
    map((action) => action.payload),
    switchMap(({ email, language }) => from(
      strapi.request('post', '/../signup/resend/verify', {
        data: { email },
        params: { language }
      })
    ).pipe(
      catchError(handleError),
      map((result) => {
        if (result?.email) {
          return null;
        }

        return addNotification({
          type: 'error',
          text: result
        });
      })
    )
    )
  );
}

export default combineEpics(sendSignUpEpic, sendSignUpChangeEmail, reSendSignUp);
