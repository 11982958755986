import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const Breadcrumbs = ({ crumbs }) => (
  <div className="subheader-smu-table">
    <span className="emp-span">
      <i className="fa fa-home icons-employees" />
      {crumbs.map(({ name, url }) => (
        <Fragment key={name}>
          -&nbsp;
          {url ? (
            <Link to={url} className="br-crump">
              {name}
            </Link>
          ) : name}
          {' '}
        </Fragment>
      )
      )}
    </span>
  </div>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  }))
};

Breadcrumbs.defaultProps = {
  crumbs: []
};
