import { createSelector } from 'reselect';

const getUser = (state) => state?.signIn?.user;
const getInitialData = (state) => state?.settingProfile?.initialData;
const getCloudStatusInfo = (state) => state.cloudStatus?.message;
const getLoadManagementDashboardUser = (state) => state.loadManagementDashboard?.user;

export const userSelector = createSelector([getUser], (user) => user);
export const cloudStatusInfoSelector = createSelector([getCloudStatusInfo], (data) => data);

export const logoSelector = createSelector(
  [getUser, getInitialData, getLoadManagementDashboardUser],
  (user, initialData, loadManagementDashboardUser) => {
    switch (user?.role?.type) {
      case 'oem':
      case 'pv_installer':
        return user?.company_logo;
      case 'solar_admin':
      case 'root':
      case 'support':
      case 'viewer':
        return initialData?.oem_company_logo
          || initialData?.company_logo
          || loadManagementDashboardUser?.oem_company_logo
          || loadManagementDashboardUser?.company_logo;
      default:
        if (user?.oem_company_logo) {
          return user?.oem_company_logo;
        } if (user?.company_logo) {
          return user?.company_logo;
        }
        break;
    }
    return undefined;
  }
);
