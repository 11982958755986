import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

import i18n from '../../i18n';

/**
 * Renders multiline text field for redux form
 * @memberof module:ReduxFormFields
 */
const CustomTextField = (props) => {
  const {
    value,
    input,
    showTooltip,
    meta: {
      touched,
      error
    },
    inputProps,
    ...custom
  } = props;

  useEffect(() => {
    input.onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <TextareaAutosize
        {...input}
        {...custom}
        {...inputProps}
        showtooltip={showTooltip}
        minRows={1}
        style={{ overflow: 'auto' }}
      />
      <div className="textarea-custom-warning">
        {
            touched && error
              ? i18n.t(error)
              : null
          }
      </div>
    </>
  );
};

CustomTextField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string,
  showTooltip: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object)
};

CustomTextField.defaultProps = {
  value: undefined,
  showTooltip: undefined,
  inputProps: {}
};

export default CustomTextField;
