import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import i18n from '../../../../../i18n';
import Tooltip from '../../../../../components/Tooltip';

export const ItemsList = ({ columns }) => columns.map((column) => (
  <ul className="list grid" key={shortid.generate()}>
    {column.map(({ title, isAllowed, Value, tooltip, noDots, titleClasses }) => {
      if (!isAllowed) return null;
      return (
        <Fragment key={title || shortid.generate()}>
          {title && (
            <li className="list-item">
              <h4 className={`list-item-title ${titleClasses || ''}`}>
                {i18n.t(title)}
                {tooltip && <Tooltip title={i18n.t(tooltip)} />}
                {!noDots && ':'}
              </h4>
            </li>
          )}
          <li className="list-item"><Value /></li>
          {!title && <li />}
        </Fragment>
      );
    })}
  </ul>
));

ItemsList.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired
};
