import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';
import ErrorHandler from './components/ErrorHandler';
import { changeLanguageTo, getLanguageFromStore } from './i18n';
import GAHoc from './analytics/GAHoc';

const App = () => {
  useEffect(() => {
    changeLanguageTo(
      getLanguageFromStore()
    );
  }, []);

  return (
    <ErrorHandler>
      <Switch>
        {routes.map(
          (route) => (
            <Route {...route} />
          )
        )}
      </Switch>
    </ErrorHandler>
  );
};

export default GAHoc(App);
