import React from 'react';
import Logo from '../../components/Logo';
import { LanguageSelector } from '../../components/UIKit';
import Footer from '../../components/Footer';
/**
 * Renders 'terms and condition' page.
 * @class Terms
 */
const Terms = () => (
  <div className="m-grid m-grid--hor m-grid--root m-page">
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1" id="m_login">
      <div className="container">
        <div className="m-login__logo row justify-content-center">
          <div className="col-lg-12 col-auto">
            <Logo />
          </div>
          <div className="col-auto">
            <LanguageSelector className="topLanguageSelector" />
          </div>
        </div>
      </div>
      <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
        <div className="m-login__container">
          <div className="row align-items-center">
            <div>Terms</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
);

export default Terms;
