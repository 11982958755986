import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import { clone, isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';
import TopBar from '../../components/TopBar';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import {
  addChoosedCompany,
  monitoringReplaceSelected,
  employeeSendRequest,
  saveAllDataListUsers,
  setMonitoringFilters
} from './actions';
import { closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import EditNoteWindow from './EditNoteWindow';
import ChangeColumnsModalWindow from '../../components/ChangeColumnsModalWindow';
import Footer from '../../components/Footer';
import Changer from './components/Changer';
import { MainMonitoringFilterV2 } from './components/MainMonitoringFilterV2';
import ExpandedRow from './components/ExpandedRow';
import Pagination from '../../components/ReactTable/Pagination';
import { DataListPagination, DataListV2 } from '../DataList';
import columns from './columns';
import { DATA_LIST_ID, DATA_LIST_URL, EMPLOYEE_LIST_ID, switchesArray } from './constants';
import SemiTabs from './components/SemiTabs';
import './index.css';
import {
  allDataOnPageSelector,
  allDataSelector,
  archivedErrorsSelector,
  choosedCompanySelector,
  choosedEmployeesSelector,
  companyUserIdSelector,
  installationCompletedSelector,
  modalSelector,
  oemIdSelector,
  selectedRowsSelector,
  supportContractCheckedSelector,
  visibleWebSelector
} from './selectors';
import { getPagesSettingSelector } from '../PagesSettings/selectors';

const transformResponse = (data) => {
  const clonedData = clone(data.list);
  const user = {
    first_name: 'Unknown User',
    last_name: '',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };
  const gateway = {
    sm_id: 'Unknown Id',
    _id: 'Unknown Id',
    signal: 'not connected',
    name: 'Unknown Name',
    id: 'Unknown Id',
    lastErrorDate: new Date(),
    errorStatus: 'Unknown Status'
  };

  const company = {
    _id: 'Unknown Id',
    name: 'Unknown Name'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }

    if (!obj.gateway) {
      obj.gateway = gateway;
    }

    if (!obj.company) {
      obj.company = company;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

const CheckboxTable = checkboxHOC(ReactTable);
/**
 * Renders Monitoring page
 * @memberof module:Monitoring
 */
class Monitoring extends Component {
  constructor(props) {
    super(props);
    const { myRoleType, pagesSettings } = this.props;

    this.state = {
      selectAll: false,
      columns: columns(myRoleType, pagesSettings[DATA_LIST_ID])
    };
    this.areAllSelected = true;
    this.defaultParams = pagesSettings[DATA_LIST_ID].queries;
  }

  componentDidMount() {
    const {
      setMonitoringFilters: setMonitoringFilterFields
    } = this.props;

    /**
     * Load saved filters from local storage
     */
    const monitoringFilter = JSON.parse(localStorage.getItem('monitoringFilter'))?.selectedFields || {};

    setMonitoringFilterFields(monitoringFilter);
  }

  componentDidUpdate(prevProps) {
    const {
      saveAllDataListUsers: saveAllDataListUsersProps,
      allDataOnPage,
      allData,
      myRoleType,
      pagesSettings: { [DATA_LIST_ID]: columnsNextSettings }
    } = this.props;
    const {
      allDataOnPage: prevAllDataOnPage,
      pagesSettings: { [DATA_LIST_ID]: columnsPrevSettings }
    } = prevProps;

    if (prevAllDataOnPage !== allDataOnPage) {
      const newArr = allDataOnPage.filter(({ _id }) => !allData.find(({ _id: idArr1 }) => _id === idArr1));
      saveAllDataListUsersProps(newArr);
    }

    if (!isEqual(columnsPrevSettings.columns, columnsNextSettings.columns)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ columns: columns(myRoleType, columnsNextSettings) });
    }
  }

  /**
   * Create array with company ids or user id
   * @param {Array} data Array with objects. Object contain all info about field
   * @param {Array} arrayOfChoosedFields id of chosen field
   * @param {String} choosedEl name of key which we should get from object
   * @returns {Array}
   */
  parseChoosedElement = (data, arrayOfChoosedFields, choosedEl) => Array.from(new Set(data.map((el) => {
    if (arrayOfChoosedFields.find((id) => id === el._id)) {
      return el[choosedEl] ? el[choosedEl]._id : 'no company';
    }
    return null;
  }).filter(Boolean)));

  /**
   * Create array with statuses of chosen field by checkbox
   * @param {Array} data Array with objects. Object contain all info about field
   * @param {Array} arrayOfChoosedFields id of chosen field
   * @param {String} choosedEl name of key which we should get from object
   * @returns {Array}
   */
  parseStatusOfTicket = (data, arrayOfChoosedFields, choosedEl) => Array.from(new Set(data.map((el) => {
    if (arrayOfChoosedFields.find((id) => id === el._id)) {
      return el[choosedEl];
    }
    return null;
  }).filter(Boolean)));

  /**
   * Form and send request to get all installers of choosed company
   * @param {Array} arrOfChoosedSides array with all choosed rows with checkbox
   */
  formAndSendRequest = (arrOfChoosedSides) => {
    const {
      allData,
      choosedEmployees,
      employeeSendRequest: employeeSendRequestProps,
      addChoosedCompany: addChoosedCompanyProps
    } = this.props;

    const listURL = '../users/employee';
    const userIdArray = this.parseChoosedElement(allData, arrOfChoosedSides, 'user');
    const companyIdArray = this.parseChoosedElement(allData, arrOfChoosedSides, 'company');
    const companyStatusArray = this.parseStatusOfTicket(allData, arrOfChoosedSides, 'status');

    addChoosedCompanyProps(companyIdArray, userIdArray, companyStatusArray);

    const params = {
      _limit: 50,
      _start: 0,
      _sort: 'first_name:asc',
      company: companyIdArray[0]
    };

    if (Array.from(companyIdArray).length === 1 && !companyIdArray.includes('no company')) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce((val, el) => (el[0] === companyIdArray[0] ? el[1].data.list : val), '');
      if (!allEmployeesOfCheckUser || (allEmployeesOfCheckUser && allEmployeesOfCheckUser.length === 0)) {
        employeeSendRequestProps(companyIdArray[0], EMPLOYEE_LIST_ID, { listURL, params, transformResponse }, 'employees');
      }
    }
  };

  /**
   * Toggles selection for a row
   * @param {number} key_ ID of row
   */
  toggleSelection = (key_) => {
    const key = key_.replace('select-', '');
    const {
      selectedRows,
      monitoringReplaceSelected: monitoringReplaceSelectedProps
    } = this.props;
    let selection = [...selectedRows];
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
      if (this.areAllSelected) this.areAllSelected = false;
    } else {
      selection.push(key);
      if (!this.areAllSelected) this.areAllSelected = true;
    }

    this.formAndSendRequest(selection);
    monitoringReplaceSelectedProps(selection);
  };

  /**
   * Toggles selection for all rows
   */
  toggleAll = () => {
    const {
      selectedRows,
      monitoringReplaceSelected: monitoringReplaceSelectedProps
    } = this.props;
    const { selectAll } = this.state;
    const areAllSelected = !selectAll;
    let selection = [...selectedRows];

    const wrappedInstance = this.checkboxTable.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (areAllSelected) {
      // eslint-disable-next-line no-restricted-syntax
      for (const record of currentRecords) {
        if (!selection.includes(record._original._id)) {
          selection.push(record._original._id);
        }
      }
    } else {
      const recorsdsId = currentRecords.map((record) => record._original._id);
      selection = selection.filter((select) => !recorsdsId.includes(select));
    }

    this.formAndSendRequest(selection);
    monitoringReplaceSelectedProps(selection);
    this.setState({ selectAll: areAllSelected });
  };

  saveExpanderChanges = (all) => {
    this.setState({ expanded: all });
  };

  /**
   * Checks if row is selected
   * @returns {boolean}
   */
  isSelected = (key) => {
    const { selectedRows } = this.props;
    return selectedRows.includes(key);
  };

  UNSAFE_componentWillUpdate() {
    this.areAllSelected = true;
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { expanded, selectAll } = this.state;
    const {
      myRoleType,
      modalWindowSend: sendModal,
      closeModalWindow: closeModal,
      allData,
      companyUserId,
      visibleWeb,
      oemId
    } = this.props;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTdProps: () => ({
        className: 'checkbox-react-table'
      }),
      getTrProps: (allComponentProps, currentProps) => {
        if (!allComponentProps || !currentProps) return {};
        const { selectAll: selectedAll } = this.state;
        const selected = this.isSelected(currentProps.original._id);
        if (this.areAllSelected && !selected) {
          this.areAllSelected = false;
        }

        if (allComponentProps.data.length - 1 === currentProps.index) {
          // if it's the last row
          if (this.areAllSelected && !selectedAll) {
            this.setState({ selectAll: true });
          } else if (!this.areAllSelected && selectedAll) {
            this.setState({ selectAll: false });
          }
        }

        return {
          style: {
            backgroundColor: selected ? '#33363e' : 'inherit'
          }
        };
      },
      getTheadThProps: () => ({
        className: 'checkbox-react-table-all'
      })
    };

    const sendSubmitChange = ({ ticketId, noteText }) => sendModal(
      'editNote',
      {
        method: 'put',
        url: `/../errormonitoring/ticket/${ticketId}`,
        data: {
          note: noteText || ''
        }
      },
      () => dataListReloadData(DATA_LIST_ID)
    );

    const accepRolesForNotifications = ['pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer', 'oem'];

    const getInitialParamsForMonitoring = (role) => {
      const params = { ...this.defaultParams };
      switch (role) {
        case 'oem':
          return { ...params, oems: oemId };
        case 'pv_installer_employee':
        case 'pv_installer_employee_read_only':
        case 'pv_installer':
          return { ...params, companies: companyUserId };
        default:
          return { ...params };
      }
    };

    return (
      <div className="main-table-container">
        <EditNoteWindow
          modalID="editNote"
          handleOnSubmit={sendSubmitChange}
          textNote={allData}
          handleOnClose={closeModal.bind(null, 'editNote')}
        />
        <ChangeColumnsModalWindow dataListID={DATA_LIST_ID} columns={switchesArray} />
        <TopBar
          updateButton
          showNavigation
          visibleWeb={visibleWeb}
        />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              {' '}
              -
              {' '}
              {i18n.t('monitoring')}
            </span>
          </div>
          {accepRolesForNotifications.includes(myRoleType) && (
            <div className="nav-monitoring">
              <SemiTabs />
            </div>
          )}
        </div>
        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12 monitoring-top-bar">
            <Changer />
            <MainMonitoringFilterV2 />
          </div>
        </div>
        <div className="container-fluid">
          <div className="monitoring-table table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataListV2
              listID={DATA_LIST_ID}
              listURL={DATA_LIST_URL}
              params={getInitialParamsForMonitoring(myRoleType)}
              transformResponse={transformResponse}
              Component={CheckboxTable}
              componentProps={{
                defaultPageSize: 0,
                className: 'monitoring-highlight m-datatable__table',
                columns: this.state.columns,
                SubComponent: (v) => <ExpandedRow rowData={v} />,
                manual: true,
                ref: (r) => {
                  this.checkboxTable = r;
                  return r;
                },
                ...checkboxProps,
                onExpandedChange: (all) => {
                  this.saveExpanderChanges(all);
                },
                expanded
              }}
            />
            <DataListPagination
              className="monitoring-table-pagination"
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Monitoring.propTypes = {
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  employeeSendRequest: PropTypes.func.isRequired,
  addChoosedCompany: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  saveAllDataListUsers: PropTypes.func.isRequired,
  allDataOnPage: PropTypes.instanceOf(Array),
  allData: PropTypes.instanceOf(Array),
  monitoringReplaceSelected: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  choosedEmployees: PropTypes.instanceOf(Object),
  companyUserId: PropTypes.string,
  visibleWeb: PropTypes.bool,
  setMonitoringFilters: PropTypes.func.isRequired,
  oemId: PropTypes.string,
  pagesSettings: PropTypes.instanceOf(Object).isRequired
};

Monitoring.defaultProps = {
  choosedEmployees: {},
  allDataOnPage: [],
  allData: [],
  companyUserId: '',
  oemId: undefined,
  visibleWeb: undefined
};

const mapStateToProps = createStructuredSelector({
  companyUserId: companyUserIdSelector,
  selectedRows: selectedRowsSelector,
  allDataOnPage: allDataOnPageSelector,
  allData: allDataSelector,
  choosedEmployees: choosedEmployeesSelector,
  choosedCompany: choosedCompanySelector,
  archivedErrors: archivedErrorsSelector,
  visibleWeb: visibleWebSelector,
  modal: modalSelector,
  supportContractChecked: supportContractCheckedSelector,
  installationCompleted: installationCompletedSelector,
  oemId: oemIdSelector,
  pagesSettings: getPagesSettingSelector

});

export default connect(
  mapStateToProps,
  {
    addChoosedCompany,
    saveAllDataListUsers,
    monitoringReplaceSelected,
    closeModalWindow,
    modalWindowSend,
    employeeSendRequest,
    setMonitoringFilters
  }
)(Monitoring);
