import * as actionTypes from './constants';

export function getOEMs() {
  return {
    type: actionTypes.GET_OEMS
  };
}

export function setOEMs(oems) {
  return {
    type: actionTypes.SET_OEMS,
    payload: {
      oems
    }
  };
}
export function clearOEMs() {
  return {
    type: actionTypes.CLEAR_OEMS
  };
}

export function getOEMsByID(url, params) {
  return {
    type: actionTypes.GET_OEMS_BY_ID,
    payload: {
      url,
      params
    }
  };
}

export function setIsShowPlantColumnForUsers(isShowPlantColumnForUsers) {
  return {
    type: actionTypes.SET_IS_SHOW_PLANT_COLUMN_FOR_USERS,
    payload: {
      isShowPlantColumnForUsers
    }
  };
}

export function setIsShowLastLoginDateColumnForUsers(isShowLastLoginDateForUsers) {
  return {
    type: actionTypes.SET_IS_SHOW_LAST_LOGIN_DATE_FOR_USERS,
    payload: {
      isShowLastLoginDateForUsers
    }
  };
}

export function setIsShowInstallationDateColumnForUsers(isShowInstallationDateColumnForUsers) {
  return {
    type: actionTypes.SET_IS_SHOW_INSTALLATION_DATE_FOR_USERS,
    payload: {
      isShowInstallationDateColumnForUsers
    }
  };
}

export function updateColumnPropsForUser(payload) {
  return {
    type: actionTypes.UPDATE_COLUMN_PROPS_FOR_USER,
    payload
  };
}
