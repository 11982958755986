import * as actionTypes from './constants';

export const setPagesSetting = (page, data) => ({
  type: actionTypes.SET_PAGES_SETTING,
  payload: { data, page }
});

export const setPagesSettingQueries = (page, data) => ({
  type: actionTypes.SET_PAGES_SETTING_QUERIES,
  payload: { data, page }
});

export const setPagesSettingColumns = (page, data) => ({
  type: actionTypes.SET_PAGES_SETTING_ADDITIONAL_COLUMNS,
  payload: { data, page }
});
export const setPagesSettingColumnsWidth = (page, data) => ({
  type: actionTypes.SET_PAGES_SETTING_COLUMNS_WIDTH,
  payload: { data, page }
});
