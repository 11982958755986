import { createSelector } from 'reselect';

const getInviteUseModal = (state) => state.modals.inviteUserModal;
const getDeleteInvitedUserModal = (state) => state.modals?.deleteInvitedUser;
const getInvitedUsers = (state) => state.invitedUsers.invitedUsers;
const getInvitedUsersLoading = (state) => state.invitedUsers.isInvitedUsersLoading;
const getInviteUserLoading = (state) => state.invitedUsers.isInviteUserLoading;
const getDeletingInviteUserLoading = (state) => state.invitedUsers.isDeletingInviteUserLoading;
const getCurrentFormValue = (state) => state.form?.inviteEmailForm?.values?.email;

export const getInviteUserModalSelector = createSelector([getInviteUseModal], (modal) => modal);
export const getDeleteInvitedUserModalSelector = createSelector([getDeleteInvitedUserModal], (modal) => modal);
export const getInvitedUsersSelector = createSelector([getInvitedUsers], (data) => data);
export const getInvitedUsersLoadingSelector = createSelector([getInvitedUsersLoading], (isLoading) => isLoading);
export const getInviteUserLoadingSelector = createSelector([getInviteUserLoading], (isLoading) => isLoading);
export const getDeletingInviteUserLoadingSelector = createSelector([getDeletingInviteUserLoading], (isLoading) => isLoading);
export const getCurrentFormValueSelector = createSelector([getCurrentFormValue], (email) => email);
