const getConnectedOem = (initialData) => {
  if (initialData?.connectedOem && typeof initialData.connectedOem === 'string') {
    return initialData.connectedOem;
  }
  if (initialData?.connectedOem?._id && typeof initialData.connectedOem._id === 'string') {
    return initialData.connectedOem._id;
  }
  return 'null';
};

export default getConnectedOem;
