import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';

import { closeModalWindow } from '../../containers/ModalWindow/actions';
import { isChangeColumnsModalOpenedSelector } from '../../containers/SettingProfile/selectors';

import ModalWindow from '../../containers/ModalWindow';
import CancelButton from '../UIKit/CancelButton';

import { getPagesSettingSelector } from '../../containers/PagesSettings/selectors';
import { setPagesSettingColumns } from '../../containers/PagesSettings/actions';

import i18n from '../../i18n';

import './index.scss';

const CHANGE_COLUMN = 'changeColumn';

const ChangeColumnsModalWindow = ({ dataListID, columns }) => {
  const dispatch = useDispatch();

  const { [dataListID]: { columns: columnsSettings } } = useSelector(getPagesSettingSelector);
  const isChangeColumnsModalOpened = useSelector(isChangeColumnsModalOpenedSelector);

  const [data, setData] = useState(columnsSettings);

  const handleCancelBtnClick = () => dispatch(closeModalWindow(CHANGE_COLUMN));
  const handleSave = () => {
    dispatch(setPagesSettingColumns(dataListID, data));
    dispatch(closeModalWindow(CHANGE_COLUMN));
  };
  const handleChange = (key) => setData((prev) => ({ ...prev, [key]: !prev[key] }));

  useEffect(() => {
    if (!isChangeColumnsModalOpened) {
      setData(columnsSettings);
    }
  }, [isChangeColumnsModalOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow modalID="changeColumn">
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editColumnsModalTitle')}</h5>
      </div>
      <div className="modal-body">
        {columns.map(({ i18nLabel, key }) => (
          <div className="text-confirmation switch-container" key={key}>
            <Switch
              checked={data[key]}
              width={51}
              height={26}
              onColor="#ffb008"
              className="react-switch"
              onChange={() => handleChange(key)}
            />
            <span className="tarif-header">
              {i18n.t(i18nLabel)}
            </span>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleCancelBtnClick} />
        <button
          onClick={handleSave}
          type="button"
          form="addUserAdm"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
        >
          <i className="fa fa-save" />
          {i18n.t('save')}
        </button>
      </div>
    </ModalWindow>
  );
};

ChangeColumnsModalWindow.propTypes = {
  dataListID: PropsTypes.string.isRequired,
  columns: PropsTypes.instanceOf(Array).isRequired
};

export default ChangeColumnsModalWindow;
