import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
/**
 * Render function for React-Table. User name
 * @memberof module:ReactTable
 * @param  {string} value - company name
 */
const renderUserName = ({
  original: {
    first_name, last_name, status, email
  }
}) => (
  <Fragment>
    {status === 'pending user confirmation'
      ? email
      : `${first_name} ${last_name}`
    }
  </Fragment>
);

renderUserName.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default renderUserName;
