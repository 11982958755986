import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import i18n from '../../../i18n';
import '../index.scss';
import { deleteDataModalSelector, isLoadingSelector } from '../selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * Renders delete OEM confirmation modal window for ViewerDetails page
 * @memberof module:ViewerDetails
 */
const DeleteEndUserWindow = (props) => {
  const {
    handleOnClose,
    onSubmit,
    modal,
    modalID,
    isLoading
  } = props;

  const data = modal ? modal.data : {};
  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('remEndUser')}</h5>
      </div>
      {isLoading ? <div className="pvinstaller-delete-preload-container"><Preloader /></div> : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('remEndUserFromViewer')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              type="button"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              onClick={onSubmit.bind(null, { id: data.id })}
            >
              <i className="fa fa-trash" />
              {` ${i18n.t('removeBtn')}`}
            </button>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

DeleteEndUserWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DeleteEndUserWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: deleteDataModalSelector
});

export default connect(mapStateToProps)(DeleteEndUserWindow);
