import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actionTypes from '../constants';
import { chartWillChangeScale, chartWillChangeRange } from '../actions';

/**
 * Epic. Is called before loading of dashboard. Function initializes user data.
 * @function
 * @memberof module:DashboardEpics
 */
export default function chartInitUser(action$) {
  return action$.pipe(
    ofType(actionTypes.CHART_INIT_USER),
    map((action) => action.payload.selectedConfig),
    switchMap(({ scaleType, min, max }) => {
      if (min && max) return of(chartWillChangeRange(min, max));
      const scaleType_ = scaleType || 't';
      return of(chartWillChangeScale(scaleType_));
    })
  );
}
