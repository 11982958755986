import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import TopBar from '../../components/TopBar';
import i18n from '../../i18n';
import './index.scss';

const NoAccess = (props) => {
  const {
    user
  } = props;

  if (
    user?.supportedUsers?.length
    || user?.attachedToViewerUsers
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div className="main-table-container">
      <TopBar updateButton />
      <div className="noAccessWarning">{i18n.t('noAccess')}</div>
    </div>
  );
};

NoAccess.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired
};

export default NoAccess;
