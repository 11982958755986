import React, { memo } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import './index.scss';

const CancelButton = memo((props) => {
  const {
    onClickHandler,
    i18nLabel,
    customButtonClass
  } = props;

  return (
    <button
      type="button"
      data-cy="cancel-button"
      className={`cancel-button${customButtonClass ? ` ${customButtonClass}` : ''}`}
      onClick={onClickHandler}
    >
      {i18n.t(i18nLabel)}
    </button>
  );
});

CancelButton.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  i18nLabel: PropTypes.string,
  customButtonClass: PropTypes.string
};

CancelButton.defaultProps = {
  customButtonClass: '',
  i18nLabel: 'cancel'
};

export default CancelButton;
