import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';
import { createStructuredSelector } from 'reselect';
import { sendPropFromField } from '../actions';
import i18n from '../../../i18n';
import '../index.css';
import { myRoleTypeSelector } from './selectors';
import { waitForTheCustomerNewStatus } from '../constants';

/**
 * Renders multi filter of pv installer, assign, work status fields for Monitoring page
 * @memberof module:Monitoring
 */

class ExpanderMultiFilter extends Component {
  constructor() {
    super();
    this.style = {
      multiselectContainer: {
        display: 'flex',
        alignItems: 'end',
        width: '230px',
        minHeight: '38px'
      },
      searchBox: {
        width: '100%',
        border: 'none',
        backgroundColor: '#40424D'
      },
      chips: {
        backgroundColor: '#25272D'
      },
      optionContainer: {
        position: 'absolute',
        width: '100%',
        border: 'none',
        backgroundColor: '#40424D',
        top: '4px'
      }
    };
  }

  async componentDidMount() {
    const { monitoring } = this.props;
    const monitoringFilter = JSON.parse(localStorage.getItem('monitoringFilter'));

    if (!monitoringFilter) {
      await localStorage.setItem('monitoringFilter', JSON.stringify(monitoring));
    }
  }

  componentDidUpdate() {
    const { monitoring } = this.props;

    localStorage.setItem('monitoringFilter', JSON.stringify(monitoring));
  }

  handleSelect = (selectedList) => {
    const {
      fieldName,
      sendPropFromField: sendPropFromFieldProps,
      onChangeField,
      queryName
    } = this.props;

    sendPropFromFieldProps([fieldName], selectedList);
    onChangeField({ [queryName]: selectedList?.map(({ id }) => id).join(',') || undefined }, true);
  };

  translateValue = (allList) => {
    const { myRoleType } = this.props;
    this.translatedMultiList = [];
    const getFilteredListOfOptions = ['solar_admin', 'pv_installer', 'pv_installer_employee', 'oem'].includes(myRoleType)
      ? allList
      : allList.filter(({ name }) => name !== waitForTheCustomerNewStatus);
    this.translatedMultiList = getFilteredListOfOptions.map((el) => {
      const copiedMultiSelect = Object.assign({}, el);
      copiedMultiSelect.name = `${i18n.t(el.name)}`;
      return copiedMultiSelect;
    });
  };

  render() {
    const { choosingMultiList, defaultValue, id, monitoring, fieldName } = this.props;

    if (choosingMultiList && choosingMultiList.options) {
      this.translateValue(choosingMultiList.options);
    }

    return (
      <Multiselect
        id={id}
        options={this.translatedMultiList}
        placeholder={defaultValue}
        showCheckbox
        emptyRecordMsg={i18n.t('noOptionsAvailable')}
        style={this.style}
        onSelect={this.handleSelect}
        onRemove={this.handleSelect}
        displayValue="name"
        selectedValues={monitoring?.selectedFields?.[fieldName]?.value || []}
      />
    );
  }
}

ExpanderMultiFilter.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  choosingMultiList: PropTypes.instanceOf(Object),
  sendPropFromField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  myRoleType: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  monitoring: PropTypes.instanceOf(Object),
  queryName: PropTypes.string
};

ExpanderMultiFilter.defaultProps = {
  choosingMultiList: {},
  monitoring: {},
  myRoleType: undefined,
  queryName: undefined
};

const mapStateToProps = createStructuredSelector({
  myRoleType: myRoleTypeSelector
});

export default connect(mapStateToProps, {
  sendPropFromField
})(ExpanderMultiFilter);
