import PropTypes from 'prop-types';
import formatDateTime from '../../../utils/formatDateTime';

// eslint-disable-next-line arrow-body-style
const renderLastLogin = (props) => {
  const {
    original: { user }
  } = props;

  return (user ? formatDateTime(user.last_login) : '');
};

renderLastLogin.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default renderLastLogin;
