import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../App.css';
import 'react-select/dist/react-select.css';
import './index.sass';
import { createStructuredSelector } from 'reselect';
import { closeModalWindow } from './actions';
import { modalsSelector } from './selectors';
/**
 * @module ModalWindow
 */
/**
 * Renders modal window
 * @memberof module:ModalWindow
 */
const ModalWindow = (props) => {
  const { modals, modalID, closeModalWindow: closeModal, children, customClass } = props;

  const modal = modals?.[modalID];

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      closeModal(modalID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={modal?.opened ? 'myModalWindow opened' : 'myModalWindow'}
      onClick={(event) => {
        if (
          event.target.className === 'myModalWindow opened'
          || event.target.className === 'modalContainer'
        ) {
          closeModal(modalID);
        }
      }}
    >
      <div className="modalContainer">
        <div className={`modal-dialog ${customClass}`}>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

ModalWindow.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  modals: PropTypes.instanceOf(Object)
};

ModalWindow.defaultProps = {
  modals: undefined,
  customClass: ''
};


const mapStateToProps = createStructuredSelector({
  modals: modalsSelector
});

export default connect(mapStateToProps, {
  closeModalWindow
})(ModalWindow);
