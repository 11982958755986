import { createSelector } from 'reselect';
import { dataListID, dataListPviId, rolesWithCustomLogo } from './constants';

const getUsers = (state) => state.dataList[dataListID];
const getUserInfo = (state) => state.signIn?.user;
const getIsLoading = (state) => state.modals?.isLoading;
const getModal = (state) => state.modals?.addData;
const getCompanies = (state) => state.dataList?.[dataListPviId]?.data;
const getOems = (state) => state.users?.oemsList;

export const userSelector = createSelector([getUsers], (user) => user);
export const userInfoSelector = createSelector([getUserInfo], (user) => user);
export const compLogoSelector = createSelector([getUserInfo], (user) => user?.company_logo);
export const visibleWebSelector = createSelector([getUserInfo], (user) => (rolesWithCustomLogo.includes(user?.role?.type)
  ? user?.my_company?.visible_web || user?.oem?.visible_web
  : user?.company?.visible_web)
);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const modalSelector = createSelector([getModal], (modal) => modal);
export const companiesSelector = createSelector([getCompanies], (data) => data);
export const oemsSelector = createSelector([getOems], (oemsList) => oemsList);
