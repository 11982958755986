import { of } from 'rxjs';
import FormData from 'form-data';

/**
 * Destructurises response data recieved from back-end. Return Object or string.
 * @param {Object} error Response from back-end
 * @returns {Object|String} Object with successfull data; or string if response has error
 */
export const handleError = (error) => (
  error.response?.data
    ? of(`${error.response.data.message}`)
    : of(`${error}`)
);

/**
 * Destructurises response data recieved from back-end. Always return Object.
 * @param {Object} error Response from back-end
 * @returns {Object} Object.response.data if successfull; or Object {error: boolean, message: string}
 */
export const handleErrorDetailed = (error) => (
  error.response?.data
    ? of({
      error: true,
      message: `${error.response.data.message}`
    })
    : of({
      error: true,
      message: `${error}`
    })
);

/**
 * Trims all string childs in Object.
 * @param {Object} fields Trimmed string
 * @returns {Object} Object with trimmed strings
 */
export const trimAllFields = (fields) => (
  Object.keys(fields).reduce((prev, key) => {
    const value = fields[key];
    return {
      ...prev,
      [`${key}`]: typeof value === 'string' ? value.trim() : value
    };
  }, {})
);

/**
 * Transforms data object to form data
 * @param data
 * @returns {FormData}
 */
export const transformToFormData = (data) => {
  const formData = new FormData();
  const fieldsKeys = Object.keys(data);
  fieldsKeys.forEach((fieldID) => {
    formData.append(fieldID, data[fieldID]);
  });
  return formData;
};
