import { createSelector } from 'reselect';

const getUser = (state) => state.signIn?.user;
const getInitialData = (state) => state?.settingProfile?.initialData;
const getLoadManagementDashboardUser = (state) => state.loadManagementDashboard?.user;

export const linkForLogoSelector = createSelector(
  [getUser, getInitialData, getLoadManagementDashboardUser],
  (user, initialData, loadManagementDashboardUser) => {
    switch (user?.role?.type) {
      case 'oem':
        if (user?.company_logo) {
          return user?.oem?.link_for_logo;
        }
        break;
      case 'pv_installer':
        if (user?.company_logo) {
          return user?.my_company?.link_for_logo;
        }
        break;
      case 'solar_admin':
      case 'support':
      case 'root':
      case 'viewer':
        return initialData?.connectedOem?.link_for_logo
          || initialData?.company?.link_for_logo
          || loadManagementDashboardUser?.connectedOem?.link_for_logo
          || loadManagementDashboardUser?.company?.link_for_logo;
      default:
        if (user?.oem_company_logo) {
          return user?.connectedOem?.link_for_logo;
        }
        if (user?.company_logo) {
          return user?.company?.link_for_logo;
        }
        break;
    }
    return undefined;
  }
);
