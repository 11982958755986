import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  getFormValues,
  reduxForm
} from 'redux-form';

/**
 * Components
 * @ignore
 */
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import Checkbox from '../../../components/ReduxFormFields/Checkbox';
import { CancelButton } from '../../../components/UIKit';
import { LongCheckboxArr } from '../../../components/LongCheckboxArr';

/**
 * Actions
 * @ignore
 */
import {
  setAllNotAttachedTOViewerInstallers,
  getAllNotAttachedToViewerInstallers
} from '../actions';

/**
 * Selectors
 * @ignore
 */
import {
  addPVInstallersModalSelector,
  attachedPVInstallersSelectorForViewer,
  getIsLoadingSelectorForViewersEndUsers,
  isLoadingSelector,
  notAttachedPVInstallersModalSelectorForViewer,
  registeredFieldsInstallerSelectorForViewer
} from '../selectors';

import {
  getInstallersForViewerParams,
  addPVInstallersToViewerForm,
  selectAllCheckbox
} from '../constants';

import i18n from '../../../i18n';

/**
 * Renders add Pv Installer to viewer modal window for ViewerDetails page
 * @memberof module:ViewerDetails
 */
const AddPVInstallerToViewerWindow = (props) => {
  const {
    notAttachedPVInstallers,
    handleOnClose,
    viewerUserId,
    handleSubmit,
    modalID,
    modal,
    reset,
    change,
    isLoadingState: { isPvInstallersListForViewerLoading }
  } = props;
  const dispatch = useDispatch();

  const selectedInstallers = useMemo(() => notAttachedPVInstallers.filter(({ isChecked }) => isChecked).length, [notAttachedPVInstallers]);

  const isLoadingData = isPvInstallersListForViewerLoading && !notAttachedPVInstallers.length;

  const handleChange = (value) => {
    if (isPvInstallersListForViewerLoading) return;

    const copy = [...notAttachedPVInstallers];
    const indexOfPoint = copy.findIndex((point) => point._id === value);
    copy[indexOfPoint] = {
      ...copy[indexOfPoint],
      isChecked: !copy[indexOfPoint].isChecked
    };
    dispatch(setAllNotAttachedTOViewerInstallers(copy));

    const isAllIsChecked = copy.every((point) => point.isChecked);
    change(selectAllCheckbox, isAllIsChecked);
  };

  const handleChangeAll = (event) => {
    dispatch(setAllNotAttachedTOViewerInstallers(notAttachedPVInstallers.map((point) => ({ ...point, isChecked: event.target.checked }))));
  };

  useEffect(() => {
    if (!modal.opened) {
      dispatch(setAllNotAttachedTOViewerInstallers(notAttachedPVInstallers.map((point) => ({ ...point, isChecked: false }))));
      reset();
    }
  }, [modal.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAllNotAttachedToViewerInstallers({ viewerId: viewerUserId, ...getInstallersForViewerParams }));

    return () => dispatch(setAllNotAttachedTOViewerInstallers([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = notAttachedPVInstallers.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit(dataForReq);
    reset();
  };

  return (
    <ModalWindow customClass="max-width-550" modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addInstaller')}</h5>
      </div>
      {isLoadingData ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body modal-add-installer-to-oem">
            <form
              id={addPVInstallersToViewerForm}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              {!!notAttachedPVInstallers.length && (
                <div className="form-group m-form__group checkbox-row">
                  <Field
                    type="checkbox"
                    name={selectAllCheckbox}
                    component={Checkbox}
                    className="m-input"
                    autoComplete="off"
                    label={i18n.t('selectAllPVInstallers')}
                    labelClass=""
                    showTooltip={false}
                    onChange={handleChangeAll}
                  />
                </div>
              )}
              <LongCheckboxArr
                formId={addPVInstallersToViewerForm}
                handleChange={handleChange}
                noDataText="NoPVinstaller"
                checkedData={notAttachedPVInstallers}
                disabled={isPvInstallersListForViewerLoading}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              {i18n.t('Selected')}
              {': '}
              {selectedInstallers}
            </p>
            <div>
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={addPVInstallersToViewerForm}
                disabled={!selectedInstallers}
                type="submit"
                className="ml-2 btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav installer-to-oem-btn"
              >
                {i18n.t('add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddPVInstallerToViewerWindow.propTypes = {
  isLoadingState: PropTypes.instanceOf(Object).isRequired,
  notAttachedPVInstallers: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  viewerUserId: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

AddPVInstallerToViewerWindow.defaultProps = {
  notAttachedPVInstallers: [],
  modal: {}
};

const form = reduxForm({
  form: addPVInstallersToViewerForm
})(AddPVInstallerToViewerWindow);

const mapStateToProps = createStructuredSelector({
  notAttachedPVInstallers: notAttachedPVInstallersModalSelectorForViewer,
  attachedPVInstallers: attachedPVInstallersSelectorForViewer,
  isLoadingState: getIsLoadingSelectorForViewersEndUsers,
  regFields: registeredFieldsInstallerSelectorForViewer,
  modal: addPVInstallersModalSelector,
  isLoading: isLoadingSelector,
  selectedItems: getFormValues(addPVInstallersToViewerForm)
});

export default connect(mapStateToProps)(form);
