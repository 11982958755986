import { DEFAULT_TIMEZONE } from '../../config';
import {
  CHANGE_DATA_FOR_REQUEST,
  CHANGE_SCALE_TYPE,
  GET_CHART_DATA,
  RECEIVE_CHART_DATA,
  RECEIVE_INIT_USER,
  RECEIVE_SMART_METER_DATA
} from './constants';

const initialState = {
  scaleType: null,
  loading: true,
  chart: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RECEIVE_INIT_USER: {
      const { gateway, settings, company_logo } = action.payload.result;
      const { sensors } = gateway;
      const smartMeterId = sensors?.find((device) => device?.device_type === 'smart-meter')?._id;

      return {
        ...state,
        user: {
          houseFuse: settings?.houseFuse,
          timezone: gateway?.timeZone || DEFAULT_TIMEZONE,
          gatewayId: gateway?._id,
          smartMeterId,
          companyLogo: company_logo,
          ...action.payload.result
        }
      };
    }
    case RECEIVE_SMART_METER_DATA: {
      return {
        ...state,
        loading: false,
        smartMeterData: action.payload.result
      };
    }
    case GET_CHART_DATA: {
      return {
        ...state,
        loading: true
      };
    }
    case RECEIVE_CHART_DATA: {
      return {
        ...state,
        loading: false,
        chartData: action.payload.result
      };
    }
    case CHANGE_SCALE_TYPE: {
      return {
        ...state,
        scaleType: action.payload.data
      };
    }

    case CHANGE_DATA_FOR_REQUEST: {
      return {
        ...state,
        chart: action.payload.data
      };
    }
    default:
      return state;
  }
};
