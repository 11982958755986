import i18n from '../../../i18n';

/**
 * Generates options for function select
 * @function getUniqueTypesList
 * @memberof module:UserDevices
 * @returns {Object[]} Array of unique option. Type is an Object: { value, label }.
 */
export const getFunctionOptions = () => [
  {
    label: 'switchOffBlocked',
    value: 1
  },
  {
    label: 'restrictToMin',
    value: 2
  },
  {
    label: 'restrictTo50',
    value: 3
  },
  {
    label: 'increaseToMax',
    value: 4
  },
  {
    label: 'fromGridMaximumUsage',
    value: 5
  },
  {
    label: 'fromGridBlocked',
    value: 6
  }
].map((option) => ({ ...option, label: i18n.t(option.label) }));

export default getFunctionOptions;
