import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import i18n from '../../../../../../i18n';
import { timeParser } from '../../../../../../containers/validation';
import SelectDays from '../../SelectDays';
import TimePicker from '../../../../TimePicker';
import ArrowUp from '../../../../../../assets/images/svg/ArrowUp.svg';
import SwitchingAction from '../../SwitchingAction';
import { SELECT_TIME, SELECT_ALL, SELECT_ACTION, OFF, ON, ERROR_TIMER_PICKER } from '../../../constants';
import { convertTimeIntoMinutes } from '../../../helper';
import ShowErrorValidation from '../../ShowErrorValidation';

import './index.scss';

const Opened = memo((props) => {
  const {
    value,
    handleToggleCollapse,
    handleChangeSwitch,
    globalError,
    touched
  } = props;
  const {
    from,
    to,
    activeDays,
    switchAction
  } = value;
  const {
    fromError,
    toError,
    daysError,
    actionError
  } = globalError;

  /**
   * @returns {string} return placeholder if switchAction === undefined, else return 'ON' or 'OFF'
   */
  const getValueForAction = useCallback(() => {
    if (switchAction !== undefined) {
      return switchAction === 1 ? ON : OFF;
    }
    return SELECT_ACTION;
  }, [switchAction]);

  /**
 * Set new value of 'switchAction'
 * @param {number} switchAction integer number
 * @returns {array} updated switchingTimes`s 'activeDays' array
 */
  const setNewSwitchAction = useCallback((action) => handleChangeSwitch({ switchAction: action }), [handleChangeSwitch]);

  /**
   * Set new array of 'activeDays'
   * @param {Array<number>} activeDays array of days
   * @example [1, 0, 1, 1, 1, 1, 1]
   * @returns {Array<number>} updated switchingTimes`s 'activeDays' array
   */
  const setNewDays = useCallback((arrayOfDaysNumber) => handleChangeSwitch({ activeDays: arrayOfDaysNumber }), [handleChangeSwitch]);

  /**
   * Set new value of 'from'
   * @param {string} newFrom time in format of 'HH:mm'
   * @returns {array} updated switchingTimes`s 'from' field
   */
  const setFromTimers = useCallback((newFrom) => handleChangeSwitch({ from: timeParser(newFrom) }), [handleChangeSwitch]);

  /**
   * Set new value of 'to'
   * @param {string} newTo time in format of 'HH:mm'
   * @returns {array} updated switchingTimes`s 'to' field
   */
  const setToTimers = useCallback((newTo) => handleChangeSwitch({ to: timeParser(newTo) }), [handleChangeSwitch]);

  const allDaysAreInactive = useMemo(() => activeDays?.every((option) => option === 0), [activeDays]);
  const fromIsMoreOrEqualToTo = useMemo(() => (from && to && convertTimeIntoMinutes(from) >= convertTimeIntoMinutes(to)), [from, to]);

  return (
    <>
      <div className="opened__main">
        <div className="opened__container">
          <div className="opened-time__container">
            <div className="opened-time__container__range">
              <div className="opened-time__range">
                <Field
                  label={i18n.t('fromDateLabel')}
                  name="from"
                  component={TimePicker}
                  className="m-input opened__time-picker"
                  placeholder={i18n.t(SELECT_TIME)}
                  input={{
                    value: from,
                    onChange: setFromTimers
                  }}
                  shrink
                  errorPosition="relative"
                />
              </div>
              <div className="opened-time__range">
                <Field
                  label={i18n.t('toDateLabel')}
                  name="to"
                  component={TimePicker}
                  className="m-input opened__time-picker"
                  placeholder={i18n.t(SELECT_TIME)}
                  input={{
                    value: to,
                    onChange: setToTimers
                  }}
                  shrink
                  errorPosition="relative"
                />
              </div>
            </div>
            <ShowErrorValidation
              textOfError={i18n.t(ERROR_TIMER_PICKER)}
              validations={touched && ((fromError || toError) && from && to)}
            />
          </div>
          <div className="opened-days__container">
            <div className="opened-days-active">
              <span>{i18n.t('activeDaysLabel')}</span>
              <Field
                label={i18n.t('selectDays')}
                name="activeDays"
                component={SelectDays}
                placeholder={i18n.t(SELECT_ALL)}
                input={{
                  value: activeDays,
                  onChange: setNewDays
                }}
                forceError={daysError}
              />
            </div>
          </div>
          <div className="opened-switch__container">
            <div className="opened-switch-action">
              <span>{i18n.t('switchActionLabel')}</span>
              <Field
                label={i18n.t('switchActionLabel')}
                name="switchAction"
                component={SwitchingAction}
                placeholder={i18n.t(SELECT_ACTION)}
                options={[
                  { label: i18n.t(ON), value: 1, key: ON },
                  { label: i18n.t(OFF), value: 0, key: OFF }
                ]}
                input={{
                  value: i18n.t(getValueForAction()),
                  onChange: setNewSwitchAction
                }}
                forceError={!from || !to || actionError !== undefined}
              />
            </div>
          </div>
        </div>
        <div className="opened-button">
          <img
            className={`opened-button__img ${!from
              || !to
              || switchAction === undefined
              || allDaysAreInactive
              || fromIsMoreOrEqualToTo
              ? 'is-disabled-img'
              : ''}`
            }
            src={ArrowUp}
            alt="Up"
            onClick={handleToggleCollapse}
          />
        </div>
      </div>
    </>
  );
});

Opened.propTypes = {
  handleToggleCollapse: PropTypes.func.isRequired,
  handleChangeSwitch: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  globalError: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.bool.isRequired,
  fromError: PropTypes.string,
  toError: PropTypes.string,
  daysError: PropTypes.string,
  actionError: PropTypes.string
};

Opened.defaultProps = {
  fromError: undefined,
  toError: undefined,
  daysError: undefined,
  actionError: undefined
};

export default Opened;
