import React, {
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  FormSection
} from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';


/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';


/**
 * Components
 * @ignore
 */
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import RenderFieldsByDevName from '../components/RenderFieldsByDevName';
import IPField from '../../../components/ReduxFormFields/IPField';
import TagField from '../../../components/ReduxFormFields/TagField';
import DescriptionTagField from '../../../components/ReduxFormFields/DescriptionTagField';


/**
 * Actions
 * @ignore
 */
import {
  closeModalWindow
} from '../../ModalWindow/actions';
import {
  getSensorsConfigurationFile,
  saveDevicesTypesList
} from '../actions';
import {
  devicesGetTypesAndNames
} from '../../UserDetails/actions';
import {
  clearSensorsOauth2FormData
} from '../../SensorsOauth2/actions';

/**
 * Utils
 * @ignore
 */
import {
  additionalFields,
  getNamesByType,
  devicesInitialValues
} from '../utils';
import ctaHeatPumpInitialValues from '../utils/ctaHeatPumpInitialValues';


/**
 * Selectors
 * @ignore
 */
import {
  isExpiredSensorsConfigurationFileSelector,
  sensorsConfigurationFileSelector,
  sensorsOauth2FormDataSelector,
  addDeviceFormDevice_group,
  addDeviceFormProfile_name,
  devicesListSelector,
  myRoleTypeSelector,
  isLoadingSelector,
  addDeviceFormData,
  addDeviceFormType,
  devicesTypesList,
  modalsSelector
} from './selectors';


/**
 * Validation
 * @ignore
 */
import {
  required
} from '../../validation';


/**
 * Constants
 * @ignore
 */
import {
  complexDeviceGroups,
  addDeviceFormId,
  ctaHeatPump
} from '../constants';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * AddDeviceWindow form id
 */
const formID = addDeviceFormId;

/**
 * Modal window to add device
 * @memberof module:UserDevices
 */
const AddDeviceWindow = (props) => {
  const {
    getSensorsConfigurationFile: getSensorsConfigurationFileFromProps,
    clearSensorsOauth2FormData: clearSensorsOauth2FormDataFromProps,
    devicesGetTypesAndNames: devicesGetTypesAndNames_,
    isExpiredSensorsConfigurationFile,
    sensorsConfigurationFile,
    sensorsOauth2FormData,
    uniqueDeviceTypes,
    handleOnClose,
    device_group,
    handleSubmit,
    profile_name,
    devicesList,
    deviceType,
    myRoleType,
    isLoading,
    initialize,
    dispatch,
    modalID,
    modals,
    title,
    type,
    data
  } = props;

  const modal = modals?.[modalID];

  useEffect(() => {
    if (modal?.opened && (!sensorsConfigurationFile || isExpiredSensorsConfigurationFile)) {
      getSensorsConfigurationFileFromProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSensorsConfigurationFileFromProps, modal?.opened]);

  useEffect(() => {
    if (!modal?.opened) {
      initialize();

      if (sensorsOauth2FormData) {
        clearSensorsOauth2FormDataFromProps();
      }
    }
    if (modal?.opened) {
      switch (deviceType) {
        case 'inverters':
          initialize({ type: 'Inverter' });
          break;
        case 'smart-meter':
        case 'sub-meter':
          initialize({ type: 'Smart Meter' });
          break;
        case 'input-devices':
          initialize({ type: 'Input Device' });
          break;
        default:
          if (sensorsOauth2FormData) {
            initialize(sensorsOauth2FormData);
          } else {
            initialize();
          }
          break;
      }
      devicesGetTypesAndNames_(deviceType === 'sub-meter' ? 'smart-meter' : deviceType, saveDevicesTypesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modal?.opened,
    deviceType,
    sensorsOauth2FormData,
    devicesGetTypesAndNames_,
    clearSensorsOauth2FormDataFromProps
  ]);

  useEffect(() => {
    if (type && !sensorsOauth2FormData) {
      initialize({ type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (device_group && !sensorsOauth2FormData) {
      initialize({
        type,
        device_group,
        ...devicesInitialValues[device_group]
      });
    }
    // NOTE: In dependencies is missing 'type', because we need
    // reinitialize form values only if 'device_group' is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_group]);

  useEffect(() => {
    if (Number.isFinite(profile_name) && device_group === ctaHeatPump) {
      const sensorParameters = ctaHeatPumpInitialValues[device_group].find(
        ({ index }) => index === profile_name
      );
      if (sensorParameters && !sensorsOauth2FormData) {
        const { index, values = {} } = sensorParameters;
        initialize({
          type,
          device_group,
          profile_name,
          data: { ctaType: index, ...values }
        });
      }
    }
    // NOTE: In dependencies are missing 'type' and 'device_group', because we need
    // reinitialize form values only if 'profile_name' is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_name]);

  const namesList = getNamesByType(devicesList, type);
  const allowAdvancedSettings = [
    'root',
    'solar_admin',
    'pv_installer',
    'pv_installer',
    'pv_installer_employee',
    'support',
    'oem',
    'end_user'
  ].includes(myRoleType);
  const isDeviceComplex = complexDeviceGroups.includes(device_group);

  return (
    <React.Fragment>
      <ModalWindow modalID={modalID}>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
        </div>
        {isLoading ? (
          <div className="admin-preloader-container">
            <Preloader />
          </div>
        ) : (
          <>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
                id={formID}
              >
                <div
                  style={
                    deviceType === 'inverters'
                      || deviceType === 'smart-meter'
                      || deviceType === 'sub-meter'
                      || deviceType === 'input-devices'
                      ? { display: 'none' }
                      : {}
                  }
                  className="form-group m-form__group input-field zIndex21"
                >
                  <div className="flags-select-label">{`${i18n.t('deviceType')} *`}</div>
                  <Field
                    name="type"
                    component={CustomSelect}
                    placeholder={i18n.t('selectType')}
                    options={uniqueDeviceTypes}
                    isSearchable={false}
                    validate={[required]}
                    className="m-input"
                  />
                </div>
                <div className="form-group m-form__group input-field zIndex20">
                  <div className="flags-select-label">{`${i18n.t('deviceName')} *`}</div>
                  <Field
                    name="device_group"
                    component={CustomSelect}
                    placeholder={type ? i18n.t('selectName') : i18n.t('firstSelectType')}
                    options={namesList}
                    isSearchable={false}
                    validate={[required]}
                    className="m-input"
                  />
                </div>
                {isDeviceComplex && (
                  <div className="form-group m-form__group input-field zIndex19">
                    <Field
                      name="profile_name"
                      {...additionalFields.ctaType}
                      placeholder={i18n.t('CTANotSelected')}
                    />
                  </div>
                )}
                <DescriptionTagField
                  deviceType={deviceType}
                />
                <TagField
                  deviceType={deviceType}
                  dispatch={dispatch}
                  formID={formID}
                />
                <IPField
                  device_group={device_group}
                  type={type}
                  formID={formID}
                />
                {allowAdvancedSettings && (
                  <FormSection name="data">
                    <RenderFieldsByDevName
                      deviceName={device_group}
                      data={data}
                      modal={modal}
                      myRoleType={myRoleType}
                      type={type}
                      formID={formID}
                    />
                  </FormSection>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="submit"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                form={formID}
              >
                <i className="fa fa-save" />
                {i18n.t('save')}
              </button>
            </div>
          </>
        )}
      </ModalWindow>
    </React.Fragment>
  );
};

AddDeviceWindow.propTypes = {
  sensorsConfigurationFile: PropTypes.instanceOf(Object),
  sensorsOauth2FormData: PropTypes.instanceOf(Object),
  uniqueDeviceTypes: PropTypes.instanceOf(Array),
  devicesList: PropTypes.instanceOf(Array),
  modals: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  isExpiredSensorsConfigurationFile: PropTypes.bool.isRequired,
  getSensorsConfigurationFile: PropTypes.func.isRequired,
  clearSensorsOauth2FormData: PropTypes.func.isRequired,
  devicesGetTypesAndNames: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  device_group: PropTypes.string,
  profile_name: PropTypes.number,
  myRoleType: PropTypes.string,
  isLoading: PropTypes.bool,
  modalID: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

AddDeviceWindow.defaultProps = {
  sensorsConfigurationFile: undefined,
  device_group: undefined,
  devicesList: undefined,
  myRoleType: undefined,
  type: undefined,
  uniqueDeviceTypes: [],
  modalID: '',
  title: '',
  modals: {},
  data: {},
  isLoading: false,
  sensorsOauth2FormData: null,
  profile_name: null
};

const form = reduxForm({
  form: formID
})(AddDeviceWindow);

const mapStateToProps = createStructuredSelector({
  device_group: addDeviceFormDevice_group,
  profile_name: addDeviceFormProfile_name,
  type: addDeviceFormType,
  data: addDeviceFormData,
  isExpiredSensorsConfigurationFile: isExpiredSensorsConfigurationFileSelector,
  sensorsConfigurationFile: sensorsConfigurationFileSelector,
  sensorsOauth2FormData: sensorsOauth2FormDataSelector,
  uniqueDeviceTypes: devicesTypesList,
  devicesList: devicesListSelector,
  myRoleType: myRoleTypeSelector,
  isLoading: isLoadingSelector,
  modals: modalsSelector
});

const mapDispatchToProps = {
  getSensorsConfigurationFile,
  clearSensorsOauth2FormData,
  devicesGetTypesAndNames,
  closeModalWindow
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
