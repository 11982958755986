import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap, finalize } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import {
  GET_CHART_DATA,
  GET_INIT_USER,
  GET_SMART_METER_DATA,
  SEND_IOT_COMMAND
} from './constants';
import { addNotification } from '../NotificationGenerator/actions';
import { receiveChartData, receiveInitUser, receivePhasesData, sendIOTCommandError, sendIOTCommandSuccess } from './actions';
import { fillDataGaps } from './utils';
import analytics from '../../analytics';
import getEventObj, { DATA_REAL_TIME } from '../../analytics/events';

function initUserEpic($action) {
  return $action.pipe(
    ofType(GET_INIT_USER),
    map((action) => action.payload.userId),
    switchMap((userId) => from(strapi.request('get', `/../users/solar-managers-users/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result.error) {
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        }

        return of(receiveInitUser(result));
      })
    )
    )
  );
}

function getPhasesDataEpic(action$) {
  return action$.pipe(
    ofType(GET_SMART_METER_DATA),
    map((action) => action.payload.data),
    switchMap(({ myself, gatewayId, smartMeterId }) => {
      const url = myself ? '/data/real-time' : `/data/real-time/${gatewayId}`;

      return from(strapi.request('get', url)).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(DATA_REAL_TIME, {}, { myself }));
        }),
        mergeMap((result) => {
          if (result.error) {
            return of(
              addNotification({
                type: 'error',
                text: result.message
              })
            );
          }

          const smartMeterData = result?.rawSensorData?.sensorsData?.find(
            (device) => device?._id === smartMeterId
          );
          return of(receivePhasesData(smartMeterData));
        })
      );
    })
  );
}

function getChartEpic(action$) {
  return action$.pipe(
    ofType(GET_CHART_DATA),
    map((action) => action.payload.data),
    switchMap(({ gatewayId, from: fromDate, to: toDate }) => {
      const url = `/../data/sensors/load-data?gatewayId=${gatewayId}&from=${fromDate}&to=${toDate}`;

      return from(strapi.request('get', url)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result.error) {
            return of(
              addNotification({
                type: 'error',
                text: result.message
              })
            );
          }

          const L1 = result.L1 ? fillDataGaps(result.L1, fromDate, toDate) : [];
          const L2 = result.L2 ? fillDataGaps(result.L2, fromDate, toDate) : [];
          const L3 = result.L3 ? fillDataGaps(result.L3, fromDate, toDate) : [];

          return of(receiveChartData({ L1, L2, L3 }));
        })
      );
    })
  );
}
function sendIOTCommand(action$) {
  return action$.pipe(
    ofType(SEND_IOT_COMMAND),
    map((action) => action.payload.gatewayId),
    switchMap((gatewayId) => {
      const url = `/../gateway/turn-on-load-managment/${gatewayId}`;

      return from(strapi.request('get', url)).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (result.error) {
            return sendIOTCommandError();
          }
          return sendIOTCommandSuccess();
        })
      );
    })
  );
}

export default combineEpics(initUserEpic, getPhasesDataEpic, getChartEpic, sendIOTCommand);
