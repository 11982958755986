import { parseDevicesData } from '../../utils';

/**
 * Saves timestamp for empty ranges and pushes empty ranges
 * @param data
 * @param categories
 * @param emptySpaces
 * @param category
 * @param start
 * @param end
 * @param index
 */
const pushEmptyRange = ({
  data, categories, emptySpaces, category, end, start, index
}) => {
  if (typeof emptySpaces[category] !== 'undefined'
    && emptySpaces[category].start < end) {
    const newRange = parseDevicesData([{
      x: emptySpaces[category].start,
      x2: end,
      y: categories[category]
    }])[0];
    newRange.y = category;
    newRange.partialFill = 1;
    newRange.color = '#2c2e34';
    data.splice(emptySpaces[category].index, 0, newRange);
  }
  if (typeof index !== 'undefined'
    && typeof start !== 'undefined') {
    emptySpaces[category] = {
      start,
      index
    };
  }
};

/**
 * Fills empty spaces in active_devices by ranges
 * @param activeDevices
 * @param from
 * @param to
 */
const fillEmptyRanges = (activeDevices, from, to) => {
  const { data, categories } = activeDevices;
  if (typeof data === 'undefined' || typeof categories === 'undefined') return;
  const emptySpaces = {};
  for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex += 1) {
    pushEmptyRange({
      data,
      categories,
      emptySpaces,
      category: categoryIndex,
      start: from,
      index: 0
    });
  }
  for (let i = 0; i < data.length; i += 1) {
    const range = data[i];
    if (range.partialFill !== 1) {
      pushEmptyRange({
        data,
        categories,
        emptySpaces,
        category: range.y,
        end: range.x,
        start: range.x2,
        index: i + 1
      });
    }
  }
  for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex += 1) {
    pushEmptyRange({
      data,
      categories,
      emptySpaces,
      category: categoryIndex,
      end: to
    });
  }
};

export default fillEmptyRanges;
