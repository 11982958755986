import * as actionTypes from '../constants';

const initialState = {
  files: [],
  filesLoading: false,
  pendingDeleteFiles: [],
  isUploadLoading: false,
  selectedFiles: [],
  filesForUpload: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_DOCUMENTATION_FILES:
      return {
        ...state,
        files: action.payload.files
      };
    case actionTypes.SET_DOCUMENTATION_FILES_LOADING:
      return {
        ...state,
        filesLoading: action.payload.filesLoading
      };
    case actionTypes.UPLOAD_FILES_LOADING:
      return {
        ...state,
        isUploadLoading: action.payload.isUploadLoading
      };
    case actionTypes.SET_FILES_FOR_UPLOAD:
      return {
        ...state,
        filesForUpload: action.payload.filesForUpload
      };
    case actionTypes.ADD_FILES:
      return {
        ...state,
        files: [...state.files, ...action.payload.files]
      };
    case actionTypes.SET_FILES_SELECTION:
      return {
        ...state,
        selectedFiles: action.payload.selectedFiles
      };
    case actionTypes.FILES_LOADING_IDS:
      return {
        ...state,
        filesLoadingIds: action.payload.filesLoadingIds
      };
    default:
      return state;
  }
};
