import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderPhoneNumber
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'first_name',
    minWidth: 180,
    className: '',
    Cell: RenderFullName
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: 225,
    className: 'email-text-td',
    Cell: RenderEmail
  },
  {
    Header: i18n.t('phoneNumber'),
    accessor: 'phone',
    minWidth: 180,
    className: 'phone_num-td',
    Cell: RenderPhoneNumber
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: 160,
    className: 'city-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: RenderStatus,
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: RenderDate
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        view: { preLink: '/supports/' },
        editUser: { preLink: '/setting-profile/support/' },
        remove: ['root', 'solar_admin'].includes(myRoleType)
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
