import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export const TabPanel = ({ children, value, index, classes, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ py: 3, px: 2 }} className={classes}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.string
};


TabPanel.defaultProps = {
  classes: ''
};
