import React from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import './index.scss';

const ThankYouPage = (props) => {
  const { onReSubmit,
    email,
    counter,
    sendSuccessfull,
    goBack: goBackFromProps
  } = props;

  return (
    <div
      className={
        `h-auto m-grid__item m-grid__item--fluid flex-column flex-nowrap thank-you-cont row align-items-center thank_you_email-container
        ${email && (sendSuccessfull || counter > 1) ? '' : 'display-none'}`
        }
    >
      <div className="container d-flex justify-content-center align-items-center h-100">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-8 thank_you_email-content">
            <h1>{i18n.t('thank')}</h1>
            <p>
              {i18n.t('sentMail')}
              {' '}
              <span className="thank_you_email">
                {email}
              </span>
              {'. '}
              <br />
              {i18n.t('instrResetPass')}
            </p>
            {counter < 3 && sendSuccessfull && (
            <p className="mail-links">
              <button type="button" onClick={onReSubmit}>
                {i18n.t('resendMessage')}
              </button>
            </p>
            )}
            <p className="warning-par">
              {i18n.t('adviceMakeSure')}
              {' '}
            </p>
          </div>
        </div>
      </div>
      <div className="btn-upd-dashb">
        <button
          type="button"
          onClick={goBackFromProps}
        >
          {i18n.t('backToLoginPage')}
        </button>
      </div>
    </div>
  );
};

ThankYouPage.propTypes = {
  sendSuccessfull: PropTypes.bool,
  email: PropTypes.string,
  counter: PropTypes.number.isRequired,
  onReSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};

ThankYouPage.defaultProps = {
  sendSuccessfull: undefined,
  email: undefined
};

export default connect(null, { goBack })(ThankYouPage);
