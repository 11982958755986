export const USERS_SENSORS_REQUEST = 'USERS_SENSORS_REQUEST';
export const USERS_SENSORS_RECEIVED = 'USERS_SENSORS_RECEIVED';
export const SAVE_DEVICES_TYPES_LIST = 'SAVE_DEVICES_TYPES_LIST';
export const GET_SENSORS_CONFIGURATION_FILE = 'GET_SENSORS_CONFIGURATION_FILE';
export const RECEIVE_SENSORS_CONFIGURATION_FILE = 'RECEIVE_SENSORS_CONFIGURATION_FILE';
export const SET_DEVICE_ID_RTU = 'SET_DEVICE_ID_RTU';

export const ctaHeatPump = 'CTA heat pump';
export const complexDeviceGroups = [ctaHeatPump];

/**
 * Affected device types for Load guard
 */
export const allowedAffectedDeviceTypes = [
  'Car Charging',
  'Water Heater',
  'Switches',
  'Heatpump',
  'Battery',
  'Car Charging System',
  'V2X'
];

/**
 * EditDeviceWindow form id
 */
export const editDeviceFormId = 'editDeviceForm';

/**
* AddDeviceWindow form id
*/
export const addDeviceFormId = 'addDeviceForm';
