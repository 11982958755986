import React, { useEffect } from 'react';
import {
  Field,
  reduxForm,
  formValueSelector
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../../ModalWindow';
import { openModalWindow, closeModalWindow } from '../../ModalWindow/actions';
import { required, requiredMultiple } from '../../validation';
import i18n from '../../../i18n';
import {
  devicesGetTypesAndNames
} from '../../UserDetails/actions';
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import MultipleSelect from '../../../components/ReduxFormFields/MultipleSelect';
import Preloader from '../../../components/Preloader';
import getFunctionOptions from '../utils/getFunctionOptions';

import './styles.scss';
import { affectedDevicesOptionsSelector, devicesListSelector, gatewayIdSelector, inputDevicesOptionsSelector, isLoadingSelector, modalsSelector, myRoleTypeSelector } from './selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * AddLoadGuardWindow form id
 */
const formID = 'addDeviceForm';

/**
 * Modal window to add load guard devices
 * @memberof module:UserDevices
 */
const AddLoadGuardWindow = (props) => {
  const {
    modals,
    initialize,
    handleOnClose,
    handleSubmit,
    title,
    modalID,
    isLoading,
    affectedDevicesOptions,
    inputDevicesOptions
  } = props;

  const modal = modals?.[modalID];

  useEffect(() => {
    if (!modal?.opened) {
      initialize();
    }
  }, [modal, initialize]);

  const functionOptions = getFunctionOptions();

  return (
    <React.Fragment>
      <ModalWindow modalID={modalID}>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
        </div>
        {isLoading ? (
          <div className="admin-preloader-container">
            <Preloader />
          </div>
        ) : (
          <>
            <div className="modal-body">
              <form
                noValidate
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
                id={formID}
              >
                <div className="form-group m-form__group input-field zIndex20">
                  <div className="flags-select-label">{`${i18n.t('inputDevice')} *`}</div>
                  <Field
                    name="inputDevice"
                    component={CustomSelect}
                    placeholder={i18n.t('selectInputDevice')}
                    options={inputDevicesOptions}
                    isSearchable={false}
                    validate={[required]}
                    className="m-input"
                  />
                </div>
                <div className="form-group m-form__group input-field zIndex19">
                  <div className="flags-select-label">{`${i18n.t('function')} *`}</div>
                  <Field
                    name="inputDeviceFunction"
                    component={CustomSelect}
                    placeholder={i18n.t('selectFunction')}
                    options={functionOptions}
                    isSearchable={false}
                    validate={[required]}
                    className="m-input"
                  />
                </div>
                <div className="form-group m-form__group flex-multiple-select">
                  <Field
                    label={`${i18n.t('affectedDevices')} *`}
                    name="affectedDevices"
                    component={MultipleSelect}
                    placeholder={i18n.t('selectAffectedDevices')}
                    options={affectedDevicesOptions}
                    validate={[requiredMultiple]}
                  />
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="submit"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                form={formID}
              >
                <i className="fa fa-save" />
                {i18n.t('save')}
              </button>
            </div>
          </>
        )}
      </ModalWindow>
    </React.Fragment>
  );
};

AddLoadGuardWindow.propTypes = {
  modalID: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  initialize: PropTypes.func.isRequired,
  modals: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  affectedDevicesOptions: PropTypes.instanceOf(Object).isRequired,
  inputDevicesOptions: PropTypes.instanceOf(Object).isRequired

};

AddLoadGuardWindow.defaultProps = {
  modalID: '',
  title: '',
  modals: {},
  isLoading: false
};

const form = reduxForm({
  form: formID
})(AddLoadGuardWindow);

const selector = formValueSelector(formID);

const mapStateToProps = createStructuredSelector({
  inputDevice: (state) => selector(state, 'inputDevice'),
  inputDeviceFunction: (state) => selector(state, 'inputDeviceFunction'),
  affectedDevices: (state) => selector(state, 'affectedDevices'),
  modals: modalsSelector,
  devicesList: devicesListSelector,
  gateway_id: gatewayIdSelector,
  myRoleType: myRoleTypeSelector,
  isLoading: isLoadingSelector,
  affectedDevicesOptions: affectedDevicesOptionsSelector,
  inputDevicesOptions: inputDevicesOptionsSelector
});

export default connect(mapStateToProps, {
  devicesGetTypesAndNames,
  openModalWindow,
  closeModalWindow
})(form);
