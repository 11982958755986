import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { sendPropFromField } from '../actions';
import i18n from '../../../i18n';
import { myRoleTypeSelector, selectedFieldsSelector } from './selectors';
import { waitForTheCustomerNewStatus } from '../constants';

/**
 * Renders lists of different types (assign, priority, work status) for Monitoring page
 * @memberof module:Monitoring
 */

class ExpanderSide extends Component {
  handleChange = async(event) => {
    const {
      fieldName,
      sendPropFromField: sendPropFromFieldProps,
      onChangeField
    } = this.props;

    await sendPropFromFieldProps([fieldName], event.target.value);
    onChangeField();
  };

  render() {
    const { choosingList, defaultValue, id, fieldName, selectedFields, myRoleType } = this.props;
    const getFilteredListOfOptions = ['solar_admin', 'pv_installer', 'pv_installer_employee', 'oem'].includes(myRoleType)
      ? choosingList
      : choosingList.filter(({ value }) => value !== waitForTheCustomerNewStatus);

    return (
      <select
        className="form-control m-input m-input--air"
        id={id}
        style={{
          height: 'calc(2.39rem + 4px)',
          cursor: 'pointer'
        }}
        onChange={this.handleChange}
        value={selectedFields && selectedFields[fieldName] ? selectedFields[fieldName].value : ''}
      >
        <option value="">{defaultValue}</option>
        {getFilteredListOfOptions ? getFilteredListOfOptions.filter(Boolean).map(({ value, key }) => (
          <option value={key} disabled={key === waitForTheCustomerNewStatus} key={`ChooseId_${shortid.generate()}`}>{i18n.t(value)}</option>
        )) : null}
      </select>
    );
  }
}

ExpanderSide.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  sendPropFromField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  myRoleType: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  choosingList: PropTypes.instanceOf(Array),
  selectedFields: PropTypes.instanceOf(Object)
};

ExpanderSide.defaultProps = {
  selectedFields: {},
  myRoleType: undefined,
  choosingList: []
};

const mapStateToProps = createStructuredSelector({
  selectedFields: selectedFieldsSelector,
  myRoleType: myRoleTypeSelector
});

export default connect(mapStateToProps, {
  sendPropFromField
})(ExpanderSide);
