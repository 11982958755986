import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

/**
 * Strapi API
 * @ignore
 */
import strapi from '../../../strapi';

/**
 * Helpers
 * @ignore
 */
import { handleErrorDetailed } from '../../../api_helper';

/**
 * Constants
 * @ignore
 */
import * as UserTypes from './constants';

/**
 * Actions
 * @ignore
 */
import { addNotification } from '../../../containers/NotificationGenerator/actions';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../../i18n';

function sendIOTCommandEpic($action) {
  return $action.pipe(
    ofType(UserTypes.SEND_OIT_COMMAND),
    map((action) => action.payload),
    switchMap(({ sensor_id }) => from(strapi.request('post', `/../sensors/identify/${sensor_id}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          return addNotification({
            type: 'success',
            text: i18n.t('success')
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

export default combineEpics(
  sendIOTCommandEpic
);
