import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import Tooltip from '../../../components/Tooltip';
import i18n from '../../../i18n';
import { addNotification } from '../../NotificationGenerator/actions';
import { updateLoadManagement } from '../actions';
import '../index.scss';
import {
  houseFuseSelector,
  loadManagementSelector,
  myRoleTypeSelector,
  userIdSelector
} from '../selectors';

const LoadManagement = (props) => {
  const {
    houseFuse,
    addNotification: addNotificationFromProps,
    loadManagement,
    myRoleType,
    updateLoadManagement: updateLoadManagementFromProps,
    userId
  } = props;

  const viewers = ['viewer', 'pv_installer_employee_read_only'];

  const [loadManagementValue, setLoadManagementValue] = useState(null);

  const submitHandler = (e) => {
    if (!houseFuse && !loadManagementValue) {
      addNotificationFromProps({
        type: 'error',
        text: i18n.t('loadManagementError')
      });
    } else if (houseFuse) {
      setLoadManagementValue(e.target.checked);
      updateLoadManagementFromProps(e.target.checked, houseFuse, myRoleType, userId);
    } else {
      setLoadManagementValue(false);
    }
  };

  return (
    <div className="load-management">
      <label
        htmlFor="loadManagement"
        className="m-checkbox m-checkbox--air m-checkbox--state-warning load-management-label"
      >
        <input
          name="loadManagement"
          id="loadManagement"
          type="checkbox"
          checked={loadManagementValue === null ? loadManagement : loadManagementValue}
          onChange={submitHandler}
          disabled={viewers.includes(myRoleType)}
        />
        {i18n.t('loadManagementLabel')}
        <span />
      </label>
      <Tooltip title={i18n.t('loadManagementTooltip')} placement="right" />
    </div>
  );
};

LoadManagement.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  addNotification: PropTypes.func.isRequired,
  loadManagement: PropTypes.bool.isRequired,
  myRoleType: PropTypes.string.isRequired,
  updateLoadManagement: PropTypes.func.isRequired,
  userId: PropTypes.string
};

LoadManagement.defaultProps = {
  userId: undefined
};

const mapStateToProps = createStructuredSelector({
  houseFuse: houseFuseSelector,
  loadManagement: loadManagementSelector,
  myRoleType: myRoleTypeSelector,
  userId: userIdSelector
});

export default connect(mapStateToProps, {
  addNotification,
  updateLoadManagement
})(LoadManagement);
