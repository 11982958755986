import React from 'react';
import Switch from 'react-switch';

const renderSwitcher = ({ callback }) => (row) => {
  const { index, original: { _id, debug_logging = false } } = row;
  const toggle = () => {
    callback(index, !debug_logging, _id);
  };

  return (
    <span>
      <Switch
        checked={debug_logging}
        onChange={toggle}
        width={50}
        height={25}
        onColor="#ffb008"
        className="switcher"
      />
    </span>
  );
};

export default renderSwitcher;
