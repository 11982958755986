import getCategoriesOfDevices from './getCategoriesOfDevices';

export const setColors = (data) => {
  const colors = {
    Battery: '#fff',
    'Water Heater': '#f66400',
    'Smart Plug': '#00df5e',
    'Car Charging': '#ebb33b',
    'Heat Pump': '#a0be43',
    Switches: '#a8c9eb',
    'External Platform': '#e82828'
  };
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      if (typeof colors[data[i].y] === 'string') data[i].color = colors[data[i].y];
      if (data[i].color === '#fff') {
        data[i].color = data[i].discharging === false ? {
          pattern: {
            path: {
              d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
              fill: '#fff'
            },
            width: 10,
            height: 10,
            color: 'red'
          }
        }
          : {
            pattern: {
              path: {
                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                fill: '#fff'
              },
              width: 10,
              height: 10,
              color: 'green'
            }
          };
      }
    }
    return data;
  }
  return undefined;
};

export const parseDevicesData = (data) => {
  const categories = getCategoriesOfDevices(data);
  if (data) {
    const dataWithColors = setColors(data);
    for (let i = 0; i < dataWithColors.length; i += 1) {
      dataWithColors[i].y = categories.indexOf(dataWithColors[i].y);
    }
    return dataWithColors;
  }
  return undefined;
};

export default parseDevicesData;
