import { createSelector } from 'reselect';

const getVerified = (state) => state.signUp2.verified;
const getInitialValues = (state) => state.signUp2.initialValues;
const getErrorMessage = (state) => state.signUp2.errorMessage;
const getStopMessage = (state) => state.signUp2.stopMessage;

export const verifiedSelector = createSelector([getVerified], (verified) => verified);
export const initialValuesSelector = createSelector([getInitialValues], (initialValues) => initialValues);
export const errorMessageSelector = createSelector([getErrorMessage], (errorMessage) => errorMessage);
export const stopMessageSelector = createSelector([getStopMessage], (stopMessage) => stopMessage);
