import * as actionTypes from './constants';

const initialState = {
  counter: 0
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SEND: {
      return {
        counter: state.counter + 1,
        isLoading: true
      };
    }
    case actionTypes.FORGOT_PASSWORD_CANCELED: {
      return {
        counter: state.counter - 1,
        isLoading: false
      };
    }
    case actionTypes.FORGOT_PASSWORD_RECEIVED: {
      return {
        ...state,
        sendSuccessfull: true,
        email: action.payload.email,
        verifyEmail: action.payload.verifyEmail,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
