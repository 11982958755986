import React, { Fragment } from 'react';
import i18n from '../../../i18n';
import store from '../../../store';
import { changePriority } from '../../../containers/UserDetails/actions';
/**
 * Render function for React-Table. Column of priority for devices with change priority buttons.
 * @memberof module:ReactTable
 * @param  {object} data - properties of users
 */
const RenderPriority = (data) => {
  const { value, column } = data;
  const { id, priority, userId, type, total } = value || {};
  const { isActive } = column || {};
  const url = `sensors/${type}/${userId}/${id}`;
  const handleClickUp = () => (isActive ? store.dispatch(changePriority(`/../${url}/up`)) : undefined);
  const handleClickDown = () => (isActive ? store.dispatch(changePriority(`/../${url}/down`)) : undefined);
  return (
    <Fragment>
      {priority}
      {type === 'devices' && (
        <div className="dev-nav-arrow">
          {priority !== 1 && (
            <a
              title={i18n.t('moveUpTooltip')}
              onClick={handleClickUp}
              className={`btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill dev-nav-prior${isActive ? '' : ' disabled'}`}
            >
              <i className="fa fa-angle-up" />
            </a>
          )}
          {total !== priority && (
            <a
              title={i18n.t('moveDownTooltip')}
              onClick={handleClickDown}
              className={`btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill dev-nav-prior${isActive ? '' : ' disabled'}`}
            >
              <i className="fa fa-angle-down" />
            </a>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default RenderPriority;
