import i18n from '../../../../i18n';

const getPVInstallersListOptions = (companies) => {
  const installersListOptions = companies.map((company) => ({
    label: company?.my_company?.name || i18n.t('noComp'),
    value: company?.my_company?._id
  }));
  const all = {
    label: i18n.t('selectAllPVInstallers'),
    value: 'all'
  };
  return installersListOptions.length ? [all, ...installersListOptions] : installersListOptions;
};

export default getPVInstallersListOptions;
