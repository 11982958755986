import {
  RenderSignal,
  RenderButtons,
  RenderPriority,
  RenderDevicesError,
  RenderHeaderWithTooltip
} from '../../../components/ReactTable/renderFunctions';
import renderSensorData from '../../../components/ReactTable/renderFunctions/renderSensorData';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

/**
 * Creates devices columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 * @param {string|number} userId ID of user being viewed
 * @param {number} total Devices amount
 */
const devices = (myRoleType, userId, total, sensorsData) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('priority'),
    id: 'priority',
    accessor: (d) => ({
      priority: d.priority,
      id: d.id,
      userId,
      type: 'devices',
      total
    }),
    width: 120,
    Cell: RenderPriority,
    filterable: false,
    sortable: false,
    className: 'priority-column',
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType),
    isActive: [
      'root',
      'solar_admin',
      'support',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('type'),
    id: 'type',
    accessor: (d) => i18n.t(d.type),
    minWidth: 180,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('name'),
    id: 'device_group',
    accessor: (d) => i18n.t(d.device_group),
    minWidth: 180,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('tag'),
      tooltipText: i18n.t('tagFieldTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    id: 'tag',
    accessor: 'tag.name',
    minWidth: 180,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('IP'),
    accessor: 'ip',
    minWidth: 160,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('MAC'),
    accessor: 'mac',
    width: 160,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('signal'),
    accessor: 'signal',
    minWidth: 170,
    Cell: RenderSignal,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('error'),
    accessor: 'errorcodes',
    minWidth: 60,
    Cell: RenderDevicesError,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('data'),
    accessor: 'sensors_data',
    minWidth: 60,
    Cell: (args) => renderSensorData({ ...args, sensorsData }),
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: myRoleType !== 'viewer' ? 120 : undefined,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(row.original, {
      editRow: [
        'root',
        'oem',
        'solar_admin',
        'support',
        'pv_installer',
        'end_user',
        'pv_installer_employee'
      ].includes(myRoleType),
      remove: [
        'root',
        'solar_admin',
        'oem',
        'support',
        'pv_installer',
        'end_user',
        'pv_installer_employee'
      ].includes(myRoleType)
    }),
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      'end_user',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  }
]);

export default devices;
