import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, ListItemText, MenuItem, FormControl } from '@mui/material';
import i18n from '../../../../../i18n';
import { ON, OFF, ERROR_FROM_TO_ACTION } from '../../constants';
import ShowErrorValidation from '../ShowErrorValidation';

import './index.scss';

const SwitchingAction = (props) => {
  const {
    options,
    placeholder,
    input: {
      value: currentValue,
      onChange
    },
    meta: {
      touched,
      error
    },
    languageToSync,
    forceError
  } = props;
  const [status, setStatus] = useState(currentValue);

  /**
 * Set new value for state 'status' and change redux 'switchAction' value
 * @param {object} event event
 */
  const handleChange = (event) => {
    const { target: { value } } = event;
    setStatus(value);
    onChange(value === ON ? 1 : 0);
  };

  /**
 * Set new value for switching action
 * @param {string} checked status of switching action or placeholder
 * @returns {HtmlTagObject} return html code with status or placeholder
 */
  const renderContentUsingChangedValue = (checked) => {
    if (checked === placeholder) {
      return <div className="switching-action-placeholder">{checked}</div>;
    }
    return <div className="switching-action-checked">{checked}</div>;
  };

  return (
    <FormControl className="switching-action-form-control">
      <Select
        name="switchAction"
        value={i18n.t(status)}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 180
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          getContentAnchorEl: null,
          classes: {
            paper: 'switching-action-select-menu-paper',
            root: 'switching-action-select-menu-root'
          }
        }}
        SelectDisplayProps={{
          style: {
            borderBottom: '1px solid #5e6774',
            borderRadius: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 0
          }
        }}
        renderValue={renderContentUsingChangedValue}
      >
        {options.map(({ label, key }) => (
          <MenuItem
            key={key}
            disableTouchRipple
            classes={{
              root: 'switching-action-menu-item'
            }}
            value={key}
          >
            <ListItemText className="switching-action-list-item" primary={label} />
            {label !== i18n.t(OFF) && <hr className="days-menu-item__HR" />}
          </MenuItem>
        ))}
      </Select>
      <ShowErrorValidation
        textOfError={i18n.t(ERROR_FROM_TO_ACTION)}
        validations={touched && (forceError || error || languageToSync)}
      />
    </FormControl>
  );
};

SwitchingAction.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  languageToSync: PropTypes.string,
  placeholder: PropTypes.string,
  forceError: PropTypes.string
};

SwitchingAction.defaultProps = {
  forceError: undefined,
  languageToSync: '',
  placeholder: ''
};

export default SwitchingAction;
