import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../App.css';
import { createStructuredSelector } from 'reselect';
import Logo from '../../components/Logo';
import { changeEmailVerify } from './actions';
import { LanguageSelector } from '../../components/UIKit';
import Footer from '../../components/Footer';
import { errorMessageSelector, verifiedSelector } from './selectors';
import i18n from '../../i18n';
/**
 * Page for confirming changed email.
 * @module VerifyChangeEmail
 */
/**
 * Renders main component of page for confirming changed email.
 * @memberof module:VerifyChangeEmail
 */
const VerifyChangeEmail = (props) => {
  useEffect(() => {
    const { changeEmailVerify: changeEmailVerifyFromProps, match } = props;
    const { verifyCode } = match?.params;
    changeEmailVerifyFromProps(verifyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { verified, errorMessage } = props;
  return (
    <Fragment>
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1">
        <div className="container">
          <div className="m-login__logo row justify-content-center">
            <div className="col-lg-12 col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-lg-12">
                <div className="loadingText">
                  {!verified && <Fragment>{i18n.t('linkVerifmess')}</Fragment>}
                  {verified && errorMessage && <Fragment>{errorMessage}</Fragment>}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

VerifyChangeEmail.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  changeEmailVerify: PropTypes.func.isRequired,
  verified: PropTypes.bool,
  errorMessage: PropTypes.string
};

VerifyChangeEmail.defaultProps = {
  verified: undefined,
  errorMessage: undefined
};

const mapStateToProps = createStructuredSelector({
  verified: verifiedSelector,
  errorMessage: errorMessageSelector
});

export default connect(mapStateToProps, {
  changeEmailVerify
})(VerifyChangeEmail);
