import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({
  name, label, value, selected, disabled, needBR, callback
}) => {
  const id = `radio_${name}_${value}`;
  return (
    <>
      <label
        htmlFor={id}
        className="customRadio"
      >
        <input
          type="radio"
          name={name}
          id={id}
          onChange={callback}
          value={value}
          checked={selected}
          disabled={disabled}
        />
        <div className={`checkmark ${disabled ? 'disabled' : ''}`} />
        {label}
      </label>
      {needBR && <br />}
    </>
  );
};

RadioInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object)
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  needBR: PropTypes.bool
};

RadioInput.defaultProps = {
  selected: false,
  name: undefined,
  label: undefined,
  disabled: false,
  needBR: true
};

export default RadioInput;
