import * as actionTypes from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.SETTING_PROF_GET_USER_SUCC:
      return {
        initialData: action.payload.initialData,
        user_received: action.payload.user_received
      };
    case actionTypes.SETTING_PROF_CHANGE:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SETTING_PROF_HAS_CHANGED:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SETTING_PROF_CLEAR_VALUES:
      return {
        isLoading: state.isLoading
      };
    case actionTypes.SET_OEMS_FOR_SETTING:
      return {
        ...state,
        oemsList: action.payload.oems
      };
    case actionTypes.SETTING_PROF_USER_IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};
