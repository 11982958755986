import React from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const options = {
  responsive: true,
  cutout: '70%'
};

const PieChart = (props) => {
  const { data } = props;

  return (
    <Doughnut
      data={data}
      options={options}
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired
};

export default PieChart;
