/**
 * Get label for react highcharts tooltip
 * @param {object} param
 * @param {object} param.settings chart settings
 * @param {string} param.sensorId sensor _id
 * @param {string} param.prefix label prefix
 * @returns {string}
 */
const getTooltipLabel = ({ settings, sensorId, prefix }) => {
  const sensorSettings = settings.find(({ _id }) => _id === sensorId);

  return `${prefix} - ${sensorSettings?.tag?.name || sensorSettings?.device_group} - ${sensorId}`;
};

export default getTooltipLabel;
