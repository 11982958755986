import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  getFormValues,
  reduxForm
} from 'redux-form';

/**
 * Components
 * @ignore
 */
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import Checkbox from '../../../components/ReduxFormFields/Checkbox';
import { CancelButton } from '../../../components/UIKit';
import { LongCheckboxArr } from '../../../components/LongCheckboxArr';

/**
 * Actions
 * @ignore
 */
import {
  setAllNotAttachedOems,
  getAllNotAttachedOems
} from '../actions';

/**
 * Selectors
 * @ignore
 */
import {
  addOemModalSelector,
  attachedOemsSelector,
  getIsLoadingSelectorForSupportEndUsers,
  isLoadingSelector,
  notAttachedOEMsSelector,
  registeredFieldsOemSelector
} from '../selectors';

import {
  getOEMSParams,
  addOEMToSupportForm,
  selectAllCheckbox
} from '../constants';

import i18n from '../../../i18n';

/**
 * Renders add OEM to support modal window for SupportDetails page
 * @memberof module:SupportDetails
 */
const AddOEMToSupportWindow = (props) => {
  const {
    notAttachedOEMs,
    handleOnClose,
    supportUserId,
    handleSubmit,
    modalID,
    modal,
    reset,
    change,
    isLoadingState: { isOemsListForSupportLoading }
  } = props;
  const dispatch = useDispatch();

  const selectedOEMs = useMemo(() => notAttachedOEMs.filter(({ isChecked }) => isChecked).length, [notAttachedOEMs]);

  const isLoadingData = isOemsListForSupportLoading && !notAttachedOEMs.length;

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = notAttachedOEMs.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit(dataForReq);
    reset();
  };

  const handleChange = (value) => {
    if (isOemsListForSupportLoading) return;

    const copy = [...notAttachedOEMs];
    const indexOfPoint = copy.findIndex((point) => point._id === value);
    copy[indexOfPoint] = {
      ...copy[indexOfPoint],
      isChecked: !copy[indexOfPoint].isChecked
    };
    dispatch(setAllNotAttachedOems(copy));

    const isAllIsChecked = copy.every((point) => point.isChecked);
    change(selectAllCheckbox, isAllIsChecked);
  };

  const handleChangeAll = (event) => {
    dispatch(setAllNotAttachedOems(notAttachedOEMs.map((point) => ({ ...point, isChecked: event.target.checked }))));
  };

  useEffect(() => {
    dispatch(getAllNotAttachedOems({ supportUserId, ...getOEMSParams }));

    return () => dispatch(setAllNotAttachedOems([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!modal.opened) {
      dispatch(setAllNotAttachedOems(notAttachedOEMs.map((point) => ({ ...point, isChecked: false }))));
      reset();
    }
  }, [modal.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow customClass="max-width-550" modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addOEM')}</h5>
      </div>
      {isLoadingData ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body modal-add-installer-to-oem">
            <form
              id={addOEMToSupportForm}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              {!!notAttachedOEMs.length && (
                <div className="form-group m-form__group checkbox-row">
                  <Field
                    type="checkbox"
                    name={selectAllCheckbox}
                    component={Checkbox}
                    className="m-input"
                    autoComplete="off"
                    label={i18n.t('selectAllOEMs')}
                    labelClass=""
                    showTooltip={false}
                    onChange={handleChangeAll}
                  />
                </div>
              )}
              <LongCheckboxArr
                formId={addOEMToSupportForm}
                handleChange={handleChange}
                noDataText="NoOEM"
                checkedData={notAttachedOEMs}
                disabled={isOemsListForSupportLoading}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              {i18n.t('Selected')}
              {': '}
              {selectedOEMs}
            </p>
            <div>
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={addOEMToSupportForm}
                disabled={!selectedOEMs}
                type="submit"
                className="ml-2 btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav installer-to-oem-btn"
              >
                {i18n.t('add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddOEMToSupportWindow.propTypes = {
  isLoadingState: PropTypes.instanceOf(Object).isRequired,
  notAttachedOEMs: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  supportUserId: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

AddOEMToSupportWindow.defaultProps = {
  notAttachedOEMs: [],
  modal: {}
};

const form = reduxForm({
  form: addOEMToSupportForm
})(AddOEMToSupportWindow);

const mapStateToProps = createStructuredSelector({
  isLoadingState: getIsLoadingSelectorForSupportEndUsers,
  notAttachedOEMs: notAttachedOEMsSelector,
  regFields: registeredFieldsOemSelector,
  attachedOems: attachedOemsSelector,
  isLoading: isLoadingSelector,
  modal: addOemModalSelector,
  selectedItems: getFormValues(addOEMToSupportForm)
});

export default connect(mapStateToProps)(form);
