import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootEpic from './containers/epics';
import reducers from './containers/reducers';
import debugActionsTime from './utils/debugActionsTime';

// temporary solution for undefined action
const hackBug = () => (next) => (action) => {
  if (!action) {
    return;
  }
  next(action);
};

const initialState = {};

const epicMiddleware = createEpicMiddleware();
export const history = createBrowserHistory();
const middleware = [
  routerMiddleware(history),
  epicMiddleware
];
let enhancer;

const nodeEnv = process.env.NODE_ENV;
if (nodeEnv !== 'production') {
  middleware.unshift(debugActionsTime);
  middleware.unshift(createLogger());

  const composeEnhancers = composeWithDevTools({
    shouldHotReload: false,
    trace: true,
    // disable default actions from redux-form
    actionsBlacklist: '^.+redux-form\/'
  });
  enhancer = composeEnhancers(applyMiddleware(...middleware));
} else {
  middleware.unshift(hackBug);
  enhancer = compose(applyMiddleware(...middleware));
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['pagesSettings']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  initialState,
  enhancer
);

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);

export default store;
