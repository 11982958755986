import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
/**
 * Render function for React-Table. Column for gateway errors
 * @memberof module:ReactTable
 * @param  {object} original - props of the gateway
 */
export const RenderError = ({ original: { gateway } }) => (
  (gateway?.has_errors || gateway?.hasErrors)
    ? (
      <Fragment>
        <span className="error_status_yes">{i18n.t('yes')}</span>
      </Fragment>
    )
    : (
      <Fragment>
        <span className="error_status_no">{i18n.t('no')}</span>
      </Fragment>
    )
);

RenderError.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderError;
