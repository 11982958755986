import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  formValueSelector,
  getFormValues,
  reduxForm
} from 'redux-form';

/**
 * Components
 * @ignore
 */
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import Checkbox from '../../../components/ReduxFormFields/Checkbox';
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import { CancelButton } from '../../../components/UIKit';
import { LongCheckboxArr } from '../../../components/LongCheckboxArr';

/**
 * Actions
 * @ignore
 */
import {
  getNotAttachedEndUsers,
  setNotAttachedEndUsers,
  getPVInstallersList,
  getOEMsList
} from '../actions';

/**
 * Selectors
 * @ignore
 */
import {
  getIsLoadingSelectorForSupportEndUsers,
  registeredFieldsEndUserSelector,
  notAttachedEndUsersSelector,
  addEndUsersModalSelector,
  pvInstallersSelector,
  oemsSelector
} from '../selectors';

import {
  addEndUsersToSupportForm,
  getEndUsersParams,
  getInstallersParams,
  getOEMSParams,
  selectAllCheckbox
} from '../constants';
import {
  getOEMsListOptions,
  getPVInstallersListOptions
} from './utils';

import i18n from '../../../i18n';

/**
 * Renders add End User to viewer modal window for SupportDetails page
 * @memberof module:SupportDetails
 */
const AddEndUserToSupportWindow = (props) => {
  const {
    filteredEndUsers,
    handleSubmit,
    selectedOEM,
    selectedPVInstaller,
    handleOnClose,
    notAttachedEndUsers,
    pvInstallers,
    modalID,
    supportUserId,
    modal,
    reset,
    oems,
    change,
    isLoadingState: { isNotAttachedUsersLoading, isOemsListForSupportLoading, isPvInstallersListForSupportLoading }
  } = props;
  const dispatch = useDispatch();

  const selectedEndUsers = useMemo(() => filteredEndUsers.filter(({ isChecked }) => isChecked).length, [filteredEndUsers]);

  const isLoadingData = isOemsListForSupportLoading || isPvInstallersListForSupportLoading || (isNotAttachedUsersLoading && !filteredEndUsers.length);

  useEffect(() => {
    if (!modal.opened) {
      dispatch(setNotAttachedEndUsers(filteredEndUsers.map((point) => ({ ...point, isChecked: false }))));
      reset();
    }
  }, [modal.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getNotAttachedEndUsers({ supportUserId, ...getEndUsersParams }));
    dispatch(getOEMsList(getOEMSParams));
    dispatch(getPVInstallersList(getInstallersParams));

    return () => dispatch(setNotAttachedEndUsers([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = filteredEndUsers.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit(dataForReq);
    reset();
  };

  const pickSearchOptions = (option, value) => {
    dispatch(getNotAttachedEndUsers(
      {
        supportUserId,
        ...getEndUsersParams,
        oemId: selectedOEM && selectedOEM !== 'all' ? selectedOEM : undefined,
        pvInstallerId: selectedPVInstaller && selectedPVInstaller !== 'all' ? selectedPVInstaller : undefined,
        [option]: value && value !== 'all' ? value : undefined
      }));
    change(selectAllCheckbox, false);
  };

  const handleChange = (value) => {
    if (isNotAttachedUsersLoading) return;

    const copy = [...filteredEndUsers];
    const indexOfPoint = copy.findIndex((point) => point._id === value);
    copy[indexOfPoint] = {
      ...copy[indexOfPoint],
      isChecked: !copy[indexOfPoint].isChecked
    };
    dispatch(setNotAttachedEndUsers(copy));

    const isAllIsChecked = copy.every((point) => point.isChecked);
    change(selectAllCheckbox, isAllIsChecked);
  };

  const handleChangeAll = (event) => {
    dispatch(setNotAttachedEndUsers(filteredEndUsers.map((point) => ({ ...point, isChecked: event.target.checked }))));
  };

  return (
    <ModalWindow customClass="max-width-550" modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>
      {isLoadingData ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className={`modal-body modal-add-user-to-support ${!!notAttachedEndUsers.length && 'full-list'}`}>
            <form
              id={addEndUsersToSupportForm}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="row users-filter">
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="oem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={getOEMsListOptions(oems)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('oemId', value)}
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="pvInstaller"
                      component={CustomSelect}
                      placeholder={i18n.t('selectPVI')}
                      options={getPVInstallersListOptions(pvInstallers)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('pvInstallerId', value)}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
              {!!notAttachedEndUsers.length && (
                <div className="form-group m-form__group checkbox-row">
                  <Field
                    type="checkbox"
                    name={selectAllCheckbox}
                    component={Checkbox}
                    className="m-input"
                    autoComplete="off"
                    label={i18n.t('selectAllEndUsers')}
                    labelClass=""
                    showTooltip={false}
                    onChange={handleChangeAll}
                  />
                </div>
              )}
              <LongCheckboxArr
                formId={addEndUsersToSupportForm}
                handleChange={handleChange}
                noDataText="noUser"
                checkedData={filteredEndUsers}
                disabled={isNotAttachedUsersLoading}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              {i18n.t('Selected')}
              {': '}
              {selectedEndUsers}
            </p>
            <div className="modal-buttons">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                form={addEndUsersToSupportForm}
                disabled={!selectedEndUsers}
                type="submit"
                className="ml-2 btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav installer-to-oem-btn"
              >
                {i18n.t('add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddEndUserToSupportWindow.propTypes = {
  isLoadingState: PropTypes.instanceOf(Object).isRequired,
  notAttachedEndUsers: PropTypes.instanceOf(Object),
  filteredEndUsers: PropTypes.instanceOf(Object),
  pvInstallers: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  oems: PropTypes.instanceOf(Object),
  supportUserId: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  selectedPVInstaller: PropTypes.string,
  selectedOEM: PropTypes.string
};

AddEndUserToSupportWindow.defaultProps = {
  notAttachedEndUsers: [],
  filteredEndUsers: [],
  pvInstallers: [],
  oems: [],
  selectedOEM: null,
  selectedPVInstaller: null,
  modal: {}
};

const form = reduxForm({
  form: addEndUsersToSupportForm
})(AddEndUserToSupportWindow);

const selector = formValueSelector(addEndUsersToSupportForm);

const mapStateToProps = createStructuredSelector({
  isLoadingState: getIsLoadingSelectorForSupportEndUsers,
  notAttachedEndUsers: notAttachedEndUsersSelector,
  filteredEndUsers: notAttachedEndUsersSelector,
  regFields: registeredFieldsEndUserSelector,
  pvInstallers: pvInstallersSelector,
  modal: addEndUsersModalSelector,
  oems: oemsSelector,
  selectedPVInstaller: (state) => selector(state, 'pvInstaller'),
  selectedItems: getFormValues(addEndUsersToSupportForm),
  selectedOEM: (state) => selector(state, 'oem')
});

export default connect(mapStateToProps)(form);
