import i18n from '../../../i18n';

/**
 * Gets devices names by some type
 * @function getNamesByType
 * @memberof module:UserDevices
 * @param {Object[]} devicesList Array of devices
 * @param {string} selectedType Type of devices to get.
 * @returns {Object[]} Array of unique names of devices. Type is an Object: { value, label }.
 */
export const getNamesByType = (devicesList = [], selectedType) => {
  const uniqueNames = [];
  devicesList.forEach((device) => {
    if (device.type === selectedType && uniqueNames.indexOf(device.name) === -1) {
      uniqueNames.push(device.name);
    }
  });
  uniqueNames.forEach((name, index) => {
    uniqueNames[index] = {
      value: name,
      label: i18n.t(name)
    };
  });
  return uniqueNames;
};

export default getNamesByType;
