import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Logo from '../Logo';
import Avatar from '../Avatar';
import CompanyLogo from '../CompanyLogo';
import { SimplePopover } from '../UIKit';
import '../../App.css';
import { logOut } from '../../containers/SignIn/actions';
import MainTabs from './MainTabs';
import i18n from '../../i18n';
import { cloudStatusInfoSelector, logoSelector, userSelector } from './selectors';
import { ReactComponent as CloudStatusWithMessageIcon } from '../../assets/images/svg/cloudStatusWithMessage.svg';
import { ReactComponent as CloudStatusIcon } from '../../assets/images/svg/cloudStatus.svg';
import { CLOUD_STATUS_MODAL_ID } from '../../containers/CloudStatusWindow/constants';
import { openModalWindow } from '../../containers/ModalWindow/actions';
import { requestCloudStatusMessage } from '../../containers/CloudStatusWindow/actions';
import CloudStatusWindow from '../../containers/CloudStatusWindow';
import { useHorizontalScroll } from '../../utils/useHorizontalScroll';

import { EMPLOYEE_ROLE_TYPES } from '../../containers/UserDetails/constants';
import analytics from '../../analytics';

import './styles.scss';
import getEventObj, { LOGOUT_EVENT } from '../../analytics/events';

/**
 * @module TopBar
 */
/**
 * Renders main TopBar
 * @memberof module:TopBar
 */
const TopBar = (props) => {
  const {
    requestCloudStatusMessage: requestCloudStatusMessageFromProps,
    openModalWindow: openModal,
    logOut: logOutDispatch,
    goBack: goB,
    cloudStatusInfo,
    showNavigation,
    goBackButton,
    visibleWeb,
    pageInfo,
    logo,
    user
  } = props;

  const {
    first_name,
    last_name,
    avatar,
    email,
    role
  } = user || {};

  const rolesWithCustomLogo = ['pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES];
  const isAdmin = ['root', 'solar_admin'].includes(role.type);
  const [isUserSawCloudMessage, setIsUserSawCloudMessage] = useState(false);
  const scrollRef = useHorizontalScroll();

  useEffect(() => {
    if (!isAdmin) {
      requestCloudStatusMessageFromProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOutHandler = useCallback(
    () => {
      logOutDispatch();
      const eventParams = {
        roleType: role.type
      };
      analytics.sendEvent(getEventObj(LOGOUT_EVENT, eventParams));
      localStorage.removeItem('cloudStatusMessage');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logOutDispatch]
  );

  const openCloudStatusModal = () => {
    openModal(CLOUD_STATUS_MODAL_ID, cloudStatusInfo);
    setIsUserSawCloudMessage(true);
  };

  const RenderUserBar = (innerProps) => (
    <React.Fragment>
      <span {...innerProps} className="user-name-user-icon">
        <span className="user-name-topbar">{`${first_name} ${last_name}`}</span>
        {' '}
        <span className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--hover-accentik m-btn--icon  m-btn--icon-dashb m-btn--icon-only m-btn--pill">
          <i className="la la-user" />
        </span>
        {' '}
      </span>
      {!isAdmin && (
        <span
          className="cloud-status-icon m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--hover-accentik m-btn--icon  m-btn--icon-dashb m-btn--icon-only m-btn--pill"
          onClick={openCloudStatusModal}
        >
          {isUserSawCloudMessage || JSON.parse(localStorage.getItem('cloudStatusMessage'))?.createdAt === cloudStatusInfo?.createdAt
            ? <CloudStatusIcon />
            : <CloudStatusWithMessageIcon />}
        </span>
      )}
      {role.type === 'end_user' && (
        <Link
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-dashb m-btn--icon-only m-btn--pill"
          to="/my-devices/"
          title={i18n.t('settings')}
        >
          <i className="la la-cog la-cog-dashb" />
        </Link>
      )}
    </React.Fragment>
  );

  if (!user || !user.role || !user.role.type) return null;
  return (
    <div className="topbar-dashb">
      {!isAdmin && (<CloudStatusWindow modalID={CLOUD_STATUS_MODAL_ID} />)}
      <div ref={scrollRef} className="container-fluid topbar-inner-container">
        <div className="row align-items-start justify-content-start topbar-inner-container-row">
          <div className="col-12 mr-auto">
            <div className="row justify-content-between align-items-center" style={{ flexWrap: 'nowrap' }}>
              {logo && visibleWeb && (
                <CompanyLogo logo={logo?.url} isTopBar />
              )}
              {(!rolesWithCustomLogo.includes(user.role.type) || (!logo || !visibleWeb)) && (
                <div className="col-auto">
                  <Logo />
                </div>
              )}
              {showNavigation
                && (
                  <div className="col-auto mr-auto">
                    <MainTabs />
                  </div>
                )}
              {!goBackButton
                && (
                  <div className="col-auto" style={{ float: 'right' }}>
                    <div
                      className="col-auto user-name text-right"
                      style={{ position: 'relative' }}
                    >
                      <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <SimplePopover
                          className="dropdown-menu dropdown-menu-right show"
                          ButtonComponent={RenderUserBar}
                        >
                          <div
                            className="m-dropdown__wrapper"
                            style={{ zIndex: 101, borderRadius: '10px' }}
                          >
                            <span
                              className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                              style={{ left: 'auto', right: '12.5px' }}
                            />
                            <div className="m-dropdown__inner">
                              <div
                                className="m-dropdown__header m--align-center"
                              >
                                <div className="m-card-user m-card-user--skin-dark">
                                  <Avatar
                                    name={`${first_name} ${last_name}`}
                                    avatar={avatar ? avatar.url : false}
                                    className="medium"
                                  />
                                  <div className="m-card-user__details">
                                    <span className="m-card-user__name">
                                      {`${first_name} ${last_name}`}
                                    </span>
                                    <a
                                      href={`mailto:${email}`}
                                      className="m-card-user__email m--font-weight-300 m-link"
                                    >
                                      {email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="m-dropdown__body">
                                <div className="m-dropdown__content">
                                  <ul className="m-nav m-nav--skin-light">
                                    <li className="m-nav__item">
                                      <Link
                                        to="/setting-profile/"
                                        className="m-nav__link"
                                      >
                                        <i className="m-nav__link-icon flaticon-user-ok" />
                                        <span className="m-nav__link-title">
                                          <span className="m-nav__link-wrap">
                                            <span className="m-nav__link-text">
                                              {i18n.t('editProfile')}
                                            </span>
                                          </span>
                                        </span>
                                      </Link>
                                    </li>
                                    <li className="m-nav__separator m-nav__separator--fit" />
                                    <li className="m-nav__item">
                                      <button
                                        type="button"
                                        onClick={logOutHandler}
                                        className="btn m-btn--pill btn-metal m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                      >
                                        {i18n.t('logout')}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SimplePopover>
                      </span>
                    </div>
                  </div>
                )
              }
              {pageInfo || goBackButton
                ? (
                  <div className="col-auto">
                    <div className="row">
                      {
                        pageInfo && (
                          <div className="col-auto dashb-page-info">{pageInfo}</div>
                        )
                      }
                      {goBackButton && (
                        <div className="col-auto btn-upd-dashb-cont">
                          <div className="btn-upd-dashb">
                            <button
                              type="button"
                              onClick={goB}
                            >
                              {i18n.t('back')}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : undefined
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  goBackButton: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  logOut: PropTypes.func.isRequired,
  showNavigation: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  requestCloudStatusMessage: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  pageInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  logo: PropTypes.instanceOf(Object),
  cloudStatusInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.instanceOf(Object)
  ]),
  visibleWeb: PropTypes.bool
};

TopBar.defaultProps = {
  user: {},
  goBackButton: false,
  showNavigation: false,
  pageInfo: false,
  logo: undefined,
  visibleWeb: undefined,
  cloudStatusInfo: {}
};

const mapStateToProps = createStructuredSelector({
  user: userSelector,
  logo: logoSelector,
  cloudStatusInfo: cloudStatusInfoSelector
});

export default connect(mapStateToProps, {
  logOut,
  goBack,
  openModalWindow,
  requestCloudStatusMessage
})(TopBar);
