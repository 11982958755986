import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../../ModalWindow/actions';
import EditGeneralSettingsWindow from '../components/EditGeneralSettingsWindow';
import UserInfo from './components/UserInfo';
import { receiveUserInfo } from '../actions';
import i18n from '../../../i18n';
import { TabPanel, GeneralInfo, Software } from './components';

import './index.scss';
import { signInUpdateUser } from '../../SignIn/actions';

/**
 * Block with user, software and general info. Viewed by everyone except end_user.
 */
const UserDetailsTabs = (props) => {
  const {
    closeModalWindow: closeModal,
    modalWindowSend: sendModal,
    user,
    myRoleType
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  const transformFieldsForAPI = (formFields) => {
    const transformToNumber = (name) => (fields) => ({ ...fields, [name]: Number(fields?.[name]) });
    const composer = compose(...['offset_watt', 'kWp', 'houseFuse'].map(transformToNumber));
    if (Number(formFields?.houseFuse) > 0) {
      return composer({ ...formFields });
    }

    return composer({ ...formFields, loadManagement: false });
  };

  const updateSettings = (response) => (myRoleType === 'end_user'
    ? signInUpdateUser({ ...user, settings: { ...response } })
    : receiveUserInfo({ ...user, settings: { ...response } }));

  const changeGeneralSettings = (fields) => sendModal(
    'generalSettings',
    {
      method: 'put',
      url: `/../user/settings/${myRoleType === 'end_user' ? 'my' : user._id}`,
      data: transformFieldsForAPI(fields)
    },
    updateSettings
  );

  const handleTabChange = (_, value) => setActiveTab(value);

  const tabProps = { user, myRoleType };
  return (
    <>
      <EditGeneralSettingsWindow
        modalID="generalSettings"
        onSubmit={changeGeneralSettings}
        initialValues={user.settings}
        enableReinitialize
        handleOnClose={closeModal.bind(null, 'generalSettings')}
      />
      <div className="user-settings">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{ px: 2 }}
        >
          <Tab disableRipple value={0} label={i18n.t('tabEndUserInfo')} />
          <Tab disableRipple value={1} label={i18n.t('generalSettings')} />
          <Tab disableRipple value={2} label={i18n.t('software')} />
        </Tabs>

        <TabPanel classes="main-info-tab" value={activeTab} index={0}>
          <UserInfo label={i18n.t('tabEndUserInfo')} {...tabProps} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <GeneralInfo label={i18n.t('generalSettings')} {...tabProps} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Software label={i18n.t('software')} {...tabProps} />
        </TabPanel>

      </div>
    </>
  );
};

UserDetailsTabs.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  myRoleType: PropTypes.string,
  modalWindowSend: PropTypes.func.isRequired
};

UserDetailsTabs.defaultProps = {
  myRoleType: undefined
};

export default connect(null, {
  openModalWindow,
  closeModalWindow,
  receiveUserInfo,
  modalWindowSend
})(UserDetailsTabs);
