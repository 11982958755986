import pick from 'lodash/pick';
import {
  CLEAR_DASHBOARD_DATA,
  DASHBOARD_SET_INITIAL_CURRENT_VALUES,
  DASHBOARD_UPD_CURRENT
} from '../constants';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case DASHBOARD_SET_INITIAL_CURRENT_VALUES:
      return pick(action.payload.data, ['production', 'consumption', 'battery', 'difference', 'import', 'autarchy_degree', 'timestamp', 'temperatures']);
    case DASHBOARD_UPD_CURRENT:
      // eslint-disable-next-line no-case-declarations
      const sensorsData = action.payload?.rawSensorData?.sensorsData || [];

      // eslint-disable-next-line no-case-declarations
      const temperatures = sensorsData
        .filter((sensor) => typeof sensor?.currentWaterTemp !== 'undefined')
        .reduce((result, { _id, currentWaterTemp }) => {
          result[_id] = currentWaterTemp;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const devicesCurrentPower = sensorsData
        .filter((sensor) => typeof sensor?.currentPower !== 'undefined')
        .reduce((result, { _id, currentPower }) => {
          result[_id] = currentPower;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const invertersCurrentPower = sensorsData
        .filter((sensor) => typeof sensor?.currentPower !== 'undefined')
        .reduce((result, { _id, currentPower }) => {
          result[_id] = currentPower;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const batteriesSoc = sensorsData
        .filter((sensor) => typeof sensor?.SOC !== 'undefined')
        .reduce((result, { _id, SOC }) => {
          result[_id] = SOC;
          return result;
        }, {});

      return {
        ...pick(action.payload.rawSensorData?.newPoint, ['production', 'consumption', 'battery', 'difference', 'import', 'autarchy_degree', 'timestamp']),
        temperatures,
        devicesCurrentPower,
        invertersCurrentPower,
        batteriesSoc
      };
    case CLEAR_DASHBOARD_DATA:
      return initialState;
    default:
      return state;
  }
};
