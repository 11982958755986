import i18n from '../../i18n';

export const SETTING_PROF_GET_USER = 'SETTING_PROF_GET_USER';
export const SETTING_PROF_GET_USER_SUCC = 'SETTING_PROF_GET_USER_SUCC';
export const SETTING_PROF_CHANGE = 'SETTING_PROF_CHANGE';
export const SETTING_PROF_HAS_CHANGED = 'SETTING_PROF_HAS_CHANGED';
export const SETTING_PROF_CLEAR_VALUES = 'SETTING_PROF_CLEAR_VALUES';
export const EMAIL_RESEND = 'EMAIL_RESEND';
export const GET_OEMS_FOR_SETTING = 'GET_OEMS_FOR_SETTING';
export const SET_OEMS_FOR_SETTING = 'SET_OEMS_FOR_SETTING';
export const GET_OEMS_BY_ID_FOR_SETTING = 'GET_OEMS_BY_ID_FOR_SETTING';
export const SETTING_PROF_USER_IS_LOADING = 'SETTING_PROF_USER_IS_LOADING';

/**
 * Returns list of all statuses
 * @function statusList
 * @memberof module:SettingProfile
 */
export const statusList = () => ([
  {
    value: 'active',
    label: i18n.t('active')
  }, {
    value: 'deactivated',
    label: i18n.t('deactivated')
  }, {
    value: 'pending user confirmation',
    label: i18n.t('pending user confirmation')
  }, {
    value: 'pending admin confirmation',
    label: i18n.t('pending admin confirmation')
  }, {
    value: 'archive',
    label: i18n.t('archive')
  }
]);

export const defaultCountry = 'Switzerland';

export const defaultOEM = { value: 'null', label: i18n.t('NoOEM') };

export const dataListPviId = 'companies';

export const getPvInstallersConfig = {
  support: {
    url: '/../users/get-supported-pv-installer-users',
    params: {
      _limit: 10000
    }
  },
  default: {
    url: '/../users/pv-installer-users',
    params: {
      _limit: 10000
    }
  }
};

/**
 * changeEmailForm form id
 */
export const CHANGE_EMAIL_FORM = 'changeEmailForm';

/**
 * changeSMIdForm form id
 */
export const CHANGE_SMID_FORM = 'changeSMIdForm';

/**
 * settingProfileForm form id
 */
export const SETTING_PROFILE_FORM = 'settingProfileForm';
