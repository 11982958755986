import fillEmptyData from '../epics/utils/fillEmptyData';

/**
 * @summary Finds and fills (if needed) empty spaces in data of chart by zero points.
 * @description Exactly this function does not fill zero by itself, but calls 'fillEmptyData' to make it.
 * @memberof module:DashboardChart
 * @param {Object} params
 * @param {ChartData} params.data.consumption AreaChart data of consumption
 * @param {ChartData} params.data.production AreaChart data of production
 * @param {ChartData} params.data.battery AreaChart data of battery
 * @param {ChartData} params.data.self_consumption AreaChart data of self_consumption
 * @param {ChartData} params.data.battery_charging AreaChart data of battery_charging
 * @param {Object} params.data.temperatures AreaSeries data of temperatures
 * @param {Object} params.data.devicesCurrentPower AreaSeries data of devicesCurrentPower
 * @param {Object} params.data.invertersCurrentPower AreaSeries data of invertersCurrentPower
 * @param {Object} params.data.batteriesSoc AreaSeries data of batteriesSoc
 * @param {number} params.interval Expected interval also current (real) interval between points (after grouping them on back-end). Milliseconds. Example: 10000
 * @param {timestamp} params.from Time when chart data should starts. Milliseconds.
 * @param {timestamp} params.to Time when chart data should ends. Milliseconds.
 */
// eslint-disable-next-line consistent-return
const fillEmptySpacesByZero = (params) => {
  const {
    data,
    interval,
    from,
    to
  } = params;

  Object.entries(data).forEach(([chartDataKey, chartData]) => {
    if (Array.isArray(chartData) && !['temperatures', 'devicesCurrentPower', 'invertersCurrentPower', 'batteriesSoc'].includes(chartDataKey)) {
      data[chartDataKey] = fillEmptyData({
        data: data[chartDataKey],
        interval,
        from,
        to,
        fill: null
      });
    } else if (typeof chartData === 'object' && chartData !== null) {
      Object.entries(chartData).forEach(([_id, values]) => {
        if (Array.isArray(values)) {
          data[chartDataKey][_id] = fillEmptyData({
            data: data[chartDataKey][_id],
            interval,
            from,
            to,
            fill: chartDataKey === 'temperatures' ? null : 0 // Because it is weird if temperature instantly becomes 0
          });
        }
      });
    }
  });
};

export default fillEmptySpacesByZero;
