import {
  parseFloatNumbersWithOneDigitAfterPoint,
  validateMinimalSocLessThanMaximalSoc,
  setDefaultLimitForCtaHeatPumpFields,
  onlyZeroAndLowerNegativesInteger,
  parseOnlyPositiveIntegerNumbers,
  parseStringOfLettersAndNumbers,
  requiredGeneralSwitchingTimes,
  parsePositiveNumbersOrNothing,
  parseIntegerNumbersNotStrict,
  parsePositiveAndZeroNumbers,
  parseStrictPositiveNumbers,
  parsePositiveIntegerOrZero,
  parseOnlyPositiveNumbers,
  parseOnlyNegativeNumbers,
  parseIntegerNumbers,
  toBeOneOrPositive,
  betweenPlusMinus2,
  parseOnlyNumbers,
  validateFloat,
  from100to9000,
  parseBoolean,
  validationIP,
  validatePort,
  toBeInteger,
  timeParser,
  from0to100,
  from6to32,
  minLength,
  maxLength,
  required,
  time
} from '../../validation';

import CustomTextInputHoc from '../../../components/ReduxFormFields/CustomTextInput/CustomTextInputHoc';
import CustomTextInput from '../../../components/ReduxFormFields/CustomTextInput';
import CustomTextField from '../../../components/ReduxFormFields/CustomTextField';
import SwitchingTimes from '../../../components/ReduxFormFields/SwitchingTimes';
import CallbackURL from '../../../components/ReduxFormFields/CallbackURL';
import TokenString from '../../../components/ReduxFormFields/TokenString';
import TimePicker from '../../../components/ReduxFormFields/TimePicker'; // eslint-disable-line import/no-named-as-default-member
import Checkbox from '../../../components/ReduxFormFields/Checkbox';
import Select from '../components/Select';

import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

const maxLen50 = maxLength(50);
const minLen3 = minLength(1);

/**
 * Object of ALL additional fields for devices
 * @constant
 * @memberof module:UserDevices
 * @type {Object}
 */
const additionalFields = {
  activeDevicePowerLimit: {
    label: 'activeDevicePowerLimit.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'activeDevicePowerLimit.tooltip',
    enabledIf: [
      {
        fieldID: 'activeDevicePowerLimitEnable',
        values: [true]
      }
    ]
  },
  activeDevicePowerLimitEnable: {
    label: 'activeDevicePowerLimitEnable.label',
    showTooltip: 'activeDevicePowerLimitEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  Baudrate: {
    label: 'Baudrate',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  blockingSetting: {
    label: 'blockingSetting.label',
    component: Select,
    options: () => [
      { label: i18n.t('blockingSetting.options.1'), value: 1 },
      { label: i18n.t('blockingSetting.options.2'), value: 2 },
      { label: i18n.t('blockingSetting.options.3'), value: 3 },
      { label: i18n.t('blockingSetting.options.4'), value: 4 }
    ],
    className: 'm-input',
    showTooltip: 'blockingSetting.tooltip',
    validate: [required],
    required: true
  },
  boilerTempOverproductionEnable: {
    label: 'boilerTempOverproductionEnable.label',
    showTooltip: 'boilerTempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  boilerTempOverproduction: {
    label: 'boilerTempOverproduction.label',
    showTooltip: 'boilerTempOverproduction.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: 'CTA heat pump',
      fieldName: 'boilerTempOverproduction',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempNormal: {
    label: 'boilerTempNormal.label',
    showTooltip: 'boilerTempNormal.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: 'CTA heat pump',
      fieldName: 'boilerTempNormal',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempReduced: {
    label: 'boilerTempReduced.label',
    showTooltip: 'boilerTempReduced.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: 'CTA heat pump',
      fieldName: 'boilerTempReduced',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempOffsetEnable: {
    label: 'boilerTempOffsetEnable.label',
    showTooltip: 'boilerTempOffsetEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  boilerTempOffsetMaximum: {
    label: 'boilerTempOffsetMaximum.label',
    showTooltip: 'boilerTempOffsetMaximum.tooltip',
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    parse: parsePositiveIntegerOrZero,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOffsetEnable',
        values: [true]
      }
    ]
  },
  boilerTempOffsetMinimum: {
    label: 'boilerTempOffsetMinimum.label',
    showTooltip: 'boilerTempOffsetMinimum.tooltip',
    parse: parseIntegerNumbersNotStrict,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, onlyZeroAndLowerNegativesInteger],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOffsetEnable',
        values: [true]
      }
    ]
  },
  ChargerID: {
    label: 'ChargerID.label',
    showTooltip: 'ChargerID.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  chargerIDMaster: {
    label: 'chargerIDMaster.label',
    showTooltip: 'chargerIDMaster.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  chargingMode: {
    label: 'chargingMode.label',
    component: Select,
    options: () => [
      { label: i18n.t('chargingMode.options.0'), value: 0 },
      { label: i18n.t('chargingMode.options.1'), value: 1 },
      { label: i18n.t('chargingMode.options.2'), value: 2 },
      { label: i18n.t('chargingMode.options.3'), value: 3 },
      { label: i18n.t('chargingMode.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  homeApplianceChargingMode: {
    label: 'homeApplianceChargingMode.label',
    component: Select,
    options: () => [
      { label: i18n.t('homeApplianceChargingMode.options.0'), value: 0 },
      { label: i18n.t('homeApplianceChargingMode.options.1'), value: 1 },
      { label: i18n.t('homeApplianceChargingMode.options.2'), value: 2 },
      { label: i18n.t('homeApplianceChargingMode.options.3'), value: 3 },
      { label: i18n.t('homeApplianceChargingMode.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  constantCurrentSetting: {
    label: 'constantCurrentSetting.label',
    showTooltip: 'constantCurrentSetting.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from6to32],
    required: true,
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [4]
      }
    ]
  },
  ComfortOffsetMaximum: {
    label: 'ComfortOffsetMaximum.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'ComfortOffsetMaximum.tooltip',
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable',
        values: [true]
      }
    ]
  },
  ComfortOffsetMinimum: {
    label: 'ComfortOffsetMinimum.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required, toBeInteger],
    required: true,
    parse: parseIntegerNumbers,
    showTooltip: 'ComfortOffsetMinimum.tooltip',
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable',
        values: [true]
      }
    ]
  },
  ComfortOffsetMinimum2: {
    label: 'ComfortOffsetMinimum2.label',
    showTooltip: 'ComfortOffsetMinimum2.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable2',
        values: [true]
      }
    ]
  },
  ComfortOffsetMaximum2: {
    label: 'ComfortOffsetMaximum2.label',
    showTooltip: 'ComfortOffsetMaximum2.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable2',
        values: [true]
      }
    ]
  },
  comfortOffsetEnable: {
    label: 'comfortOffsetEnable.label',
    showTooltip: 'comfortOffsetEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  comfortOffsetEnable2: {
    label: 'comfortOffsetEnable2.label',
    showTooltip: 'comfortOffsetEnable2.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  carType: {
    label: 'carType.label',
    showTooltip: 'carType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('carType.options.0'), value: 0 },
      { label: i18n.t('carType.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  chargingType: {
    label: 'chargingType',
    component: Select,
    options: () => [
      { label: i18n.t('chargeWithSolarPower'), value: 0 },
      { label: i18n.t('chargeInLowTariff'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  chargingCableNr: {
    label: 'chargingCableNr.label',
    showTooltip: 'chargingCableNr.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('chargingCableNr.options.0'), value: 0 },
      { label: i18n.t('chargingCableNr.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  controlMethod: {
    label: 'controlMethod.label',
    component: Select,
    options: () => [
      { label: i18n.t('controlMethod.options.0'), value: 0 },
      { label: i18n.t('controlMethod.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'controlMethod.tooltip'
  },
  ctaType: {
    label: 'ctaType.label',
    showTooltip: 'ctaType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ctaType.options.0'), value: 0 },
      { label: i18n.t('ctaType.options.1'), value: 1 },
      { label: i18n.t('ctaType.options.2'), value: 2 },
      { label: i18n.t('ctaType.options.3'), value: 3 },
      { label: i18n.t('ctaType.options.4'), value: 4 },
      { label: i18n.t('ctaType.options.5'), value: 5 },
      { label: i18n.t('ctaType.options.6'), value: 6 },
      { label: i18n.t('ctaType.options.7'), value: 7 },
      { label: i18n.t('ctaType.options.8'), value: 8 },
      { label: i18n.t('ctaType.options.9'), value: 9 },
      { label: i18n.t('ctaType.options.10'), value: 10 },
      { label: i18n.t('ctaType.options.11'), value: 11 },
      { label: i18n.t('ctaType.options.12'), value: 12 },
      { label: i18n.t('ctaType.options.13'), value: 13 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  Device_ID: {
    label: 'Device_ID.label',
    showTooltip: 'Device_ID.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  Device_ID_RTU: {
    label: 'Device_ID_RTU.label',
    showTooltip: 'Device_ID_RTU.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required],
    required: true
  },
  deviceTypeStiebel: {
    label: 'deviceTypeStiebel.label',
    showTooltip: 'deviceTypeStiebel.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('deviceTypeStiebel.options.1'), value: 1 },
      { label: i18n.t('deviceTypeStiebel.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  emuCenterSmartMeterType: {
    label: 'emuCenterSmartMeterType.label',
    showTooltip: 'emuCenterSmartMeterType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('emuCenterSmartMeterType.options.0'), value: 0 },
      { label: i18n.t('emuCenterSmartMeterType.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  enableLowTariff: {
    label: 'enableLowTariff.label',
    showTooltip: 'enableLowTariff.tooltip',
    type: 'checkbox',
    component: Checkbox,
    className: 'm-input',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    parse: parseBoolean
  },
  enableReducedCurrent: {
    label: 'enableReducedCurrent.label',
    showTooltip: 'enableReducedCurrent.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  excessIndicationLimit: {
    label: 'excessIndicationLimit.label',
    showTooltip: 'excessIndicationLimit.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  gridBlockEnable: {
    label: 'gridBlockEnable.label',
    showTooltip: 'gridBlockEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  gatewayNr: {
    label: 'gatewayNr.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'gatewayNr.tooltip'
  },
  gatewaypw: {
    label: 'gatewaypw.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'gatewaypw.tooltip'
  },
  heatingBufferOptimizationEnable: {
    label: 'heatingBufferOptimizationEnable.label',
    showTooltip: 'heatingBufferOptimizationEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingBufferOverproduction: {
    label: 'heatingBufferOverproduction.label',
    showTooltip: 'heatingBufferOverproduction.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingBufferReduced: {
    label: 'heatingBufferReduced.label',
    showTooltip: 'heatingBufferReduced.tooltip',
    parse: parseOnlyNegativeNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingBufferOverprodAbs: {
    label: 'heatingBufferOverprodAbs.label',
    showTooltip: 'heatingBufferOverprodAbs.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingCircuitDirectEnable: {
    label: 'heatingCircuitDirectEnable.label',
    showTooltip: 'heatingCircuitDirectEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit1Enable: {
    label: 'heatingCircuit1Enable.label',
    showTooltip: 'heatingCircuit1Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit2Enable: {
    label: 'heatingCircuit2Enable.label',
    showTooltip: 'heatingCircuit2Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit3Enable: {
    label: 'heatingCircuit3Enable.label',
    showTooltip: 'heatingCircuit3Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit4Enable: {
    label: 'heatingCircuit4Enable.label',
    showTooltip: 'heatingCircuit4Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingFlowTemperatureEnable: {
    label: 'heatingFlowTemperatureEnable.label',
    showTooltip: 'heatingFlowTemperatureEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingFlowTemperatureOverproduction: {
    label: 'heatingFlowTemperatureOverproduction.label',
    showTooltip: 'heatingFlowTemperatureOverproduction.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingFlowTemperatureEnable',
        values: [true]
      }
    ]
  },
  heatingFlowTemperatureEnableReduction: {
    label: 'heatingFlowTemperatureEnableReduction.label',
    showTooltip: 'heatingFlowTemperatureEnableReduction.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingFlowTemperatureEnable',
        values: [true]
      }
    ]
  },
  heatpumpControlMethod: {
    label: 'heatpumpControlMethod.label',
    showTooltip: 'heatpumpControlMethod.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('heatpumpControlMethod.options.0'), value: 0 },
      { label: i18n.t('heatpumpControlMethod.options.7'), value: 7 },
      { label: i18n.t('heatpumpControlMethod.options.8'), value: 8 },
      { label: i18n.t('heatpumpControlMethod.options.9'), value: 9 },
      { label: i18n.t('heatpumpControlMethod.options.10'), value: 10 },
      { label: i18n.t('heatpumpControlMethod.options.11'), value: 11 }
    ]
  },

  identifier: {
    label: 'identifier.label',
    showTooltip: 'identifier.tooltip',
    required: true,
    validate: [required],
    className: 'm-input',
    component: CustomTextInput
  },
  intelligentCooling: {
    label: 'intelligentCooling.label',
    showTooltip: 'intelligentCooling.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('intelligentCooling.options.0'), value: 0 },
      { label: i18n.t('intelligentCooling.options.1'), value: 1 }
    ]
  },
  input: {
    label: 'input.label',
    component: Select,
    options: () => [
      { label: i18n.t('input.options.1'), value: 1 },
      { label: i18n.t('input.options.2'), value: 2 },
      { label: i18n.t('input.options.3'), value: 3 },
      { label: i18n.t('input.options.4'), value: 4 },
      { label: i18n.t('input.options.5'), value: 5 },
      { label: i18n.t('input.options.6'), value: 6 },
      { label: i18n.t('input.options.7'), value: 7 },
      { label: i18n.t('input.options.8'), value: 8 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'gridBlockEnable',
        values: [true]
      }
    ]
  },
  installLocation: {
    label: 'installLocation.label',
    showTooltip: 'installLocation.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('installLocation.options.0'), value: 0 },
      { label: i18n.t('installLocation.options.1'), value: 1 },
      { label: i18n.t('installLocation.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  installationLocation: {
    label: 'installationLocation.label',
    showTooltip: 'installationLocation.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('installationLocation.options.0'), value: 0 },
      { label: i18n.t('installationLocation.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  inverterHeatPump: {
    label: 'inverterHeatPump.label',
    showTooltip: 'inverterHeatPump.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  invertMeasurement: {
    label: 'invertMeasurement',
    component: Select,
    options: () => [
      { label: i18n.t('turnedOff'), value: false },
      { label: i18n.t('turnedOn'), value: true }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  ip: {
    label: 'deviceIP',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true
  },
  deviceIP2: {
    label: 'deviceIP2.label',
    showTooltip: 'deviceIP2.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true
  },
  ipInputDevice: {
    label: 'ipInputDevice.label',
    showTooltip: 'ipInputDevice.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true,
    enabledIf: [
      {
        fieldID: 'gridBlockEnable',
        values: [true]
      }
    ]
  },
  kebaChargingType: {
    label: 'chargingType',
    component: Select,
    options: () => [
      { label: i18n.t('alwaysCharge'), value: '0' },
      { label: i18n.t('chargeWithSolarPower'), value: '1' },
      { label: i18n.t('chargeInLowTariff'), value: '2' },
      { label: i18n.t('doNotCharge'), value: '3' }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  legionellaProtection: {
    label: 'legionellaProtection.label',
    component: Select,
    options: () => [
      { label: i18n.t('legionellaProtection.options.0'), value: 0 },
      { label: i18n.t('legionellaProtection.options.1'), value: 1 },
      { label: i18n.t('legionellaProtection.options.2'), value: 2 },
      { label: i18n.t('legionellaProtection.options.3'), value: 3 },
      { label: i18n.t('legionellaProtection.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  legionellaSensor: {
    label: 'legionellaSensor.label',
    showTooltip: 'legionellaSensor.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('legionellaSensor.options.0'), value: 0 },
      { label: i18n.t('legionellaSensor.options.1'), value: 1 },
      { label: i18n.t('legionellaSensor.options.2'), value: 2 },
      { label: i18n.t('legionellaSensor.options.3'), value: 3 },
      { label: i18n.t('legionellaSensor.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  lowTariffEndHourMin: {
    label: 'lowTariffEndHourMin.label',
    showTooltip: 'lowTariffEndHourMin.tooltip',
    component: TimePicker,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  lowTarriffStartHourMin: {
    label: 'lowTarriffStartHourMin.label',
    showTooltip: 'lowTarriffStartHourMin.tooltip',
    component: TimePicker,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  reducedCurrentEnd: {
    label: 'reducedCurrentEnd.label',
    showTooltip: 'reducedCurrentEnd.tooltip',
    component: TimePicker,
    parse: timeParser,
    className: 'm-input',
    validate: [required, time],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  reducedCurrentStart: {
    label: 'reducedCurrentStart.label',
    showTooltip: 'reducedCurrentStart.tooltip',
    component: TimePicker,
    parse: timeParser,
    className: 'm-input',
    validate: [required, time],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  maxDischargePower: {
    label: 'maxDischargePower.label',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  maxChargePower: {
    label: 'maxChargePower.label',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  maximalOnDurationPerDay: {
    label: 'maximalOnDurationPerDay.label',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  maximalOnDurationPerDayTime: {
    label: 'maximalOnDurationPerDayTime.label',
    showTooltip: 'maximalOnDurationPerDayTime.tooltip',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'maximalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  maximalSocAvailable: {
    label: 'maximalSocAvailable.label',
    showTooltip: 'maximalSocAvailable.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100, validateMinimalSocLessThanMaximalSoc],
    required: true
  },
  maxPower: {
    label: 'maxPower',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  maxPowerConsumption: {
    label: 'maxPowerConsumption.label',
    showTooltip: 'maxPowerConsumption.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    parse: parseOnlyPositiveNumbers,
    required: true,
    enabledIf: [
      {
        ignore: ['Ochsner Europe'],
        fieldID: 'inverterHeatPump',
        values: [true]
      }
    ]
  },
  maximumCurrent: {
    label: 'maximumCurrent.label',
    showTooltip: 'maximumCurrent.tooltip',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    parse: parseOnlyPositiveIntegerNumbers,
    validate: [required],
    required: true
  },
  maxCurrent: {
    label: 'maxCurrent.label',
    showTooltip: 'maxCurrent.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('maxCurrent.options.0'), value: 0 },
      { label: i18n.t('maxCurrent.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  minimalChargingDuration: {
    label: 'minimalChargingDuration.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'minimalChargingDuration.tooltip'
  },
  minimalOnDuration: {
    label: 'minimalOnDuration.label',
    showTooltip: 'minimalOnDuration.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    disabledFor: {
      deviceName: 'CTA heat pump',
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'support', 'oem', 'viewer']
    }
  },
  minimalOnDurationPerDay: {
    label: 'minimalOnDurationPerDay.label',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  minimalOnDurationPerDayTime: {
    label: 'minimalOnDurationPerDayTime.label',
    showTooltip: 'minimalOnDurationPerDayTime.tooltip',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'minimalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  minimalOnDurationPerDayStart: {
    label: 'minimalOnDurationPerDayStart.label',
    showTooltip: 'minimalOnDurationPerDayStart.tooltip',
    component: TimePicker,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'minimalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  minPower: {
    label: 'minPower.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parsePositiveIntegerOrZero,
    showTooltip: 'minPower.tooltip',
    disabledFor: {
      deviceName: 'CTA heat pump',
      roles: ['end_user']
    }
  },
  minPower2: {
    label: 'minPower2.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parsePositiveIntegerOrZero,
    showTooltip: 'minPower2.tooltip'
  },
  minimalSocAvailable: {
    label: 'minimalSocAvailable.label',
    showTooltip: 'minimalSocAvailable.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true
  },
  NetworkKeepAliveCheckIntevalTime: {
    label: 'NetworkKeepAliveCheckIntevalTime',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  notes: {
    label: 'notes.label',
    showTooltip: 'notes.tooltip',
    component: CustomTextField,
    className: 'note-field'
  },
  oldIdRtu: {
    label: 'oldIdRtu.label',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'setIdRtu',
        values: [true]
      }
    ]
  },
  ohmpilotMode: {
    label: 'ohmpilotMode.label',
    showTooltip: 'ohmpilotMode.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ohmpilotMode.options.0'), value: 0 },
      { label: i18n.t('ohmpilotMode.options.1'), value: 1 },
      { label: i18n.t('ohmpilotMode.options.2'), value: 2 },
      { label: i18n.t('ohmpilotMode.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  offsetMinHeatingCircuitDirect: {
    label: 'offsetMinHeatingCircuitDirect.label',
    showTooltip: 'offsetMinHeatingCircuitDirect.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuitDirectEnable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuitDirect: {
    label: 'offsetMaxHeatingCircuitDirect.label',
    showTooltip: 'offsetMaxHeatingCircuitDirect.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuitDirectEnable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit1: {
    label: 'offsetMinHeatingCircuit1.label',
    showTooltip: 'offsetMinHeatingCircuit1.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseIntegerNumbers,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit1Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit2: {
    label: 'offsetMinHeatingCircuit2.label',
    showTooltip: 'offsetMinHeatingCircuit2.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit2Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit3: {
    label: 'offsetMinHeatingCircuit3.label',
    showTooltip: 'offsetMinHeatingCircuit3.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit3Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit4: {
    label: 'offsetMinHeatingCircuit4.label',
    showTooltip: 'offsetMinHeatingCircuit4.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit4Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit1: {
    label: 'offsetMaxHeatingCircuit1.label',
    showTooltip: 'offsetMaxHeatingCircuit1.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit1Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit2: {
    label: 'offsetMaxHeatingCircuit2.label',
    showTooltip: 'offsetMaxHeatingCircuit2.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit2Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit3: {
    label: 'offsetMaxHeatingCircuit3.label',
    showTooltip: 'offsetMaxHeatingCircuit3.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit3Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit4: {
    label: 'offsetMaxHeatingCircuit4.label',
    showTooltip: 'offsetMaxHeatingCircuit4.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit4Enable',
        values: [true]
      }
    ]
  },
  outputChannel: {
    label: 'outputChannel.label',
    showTooltip: 'outputChannel.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('outputChannel.options.0'), value: 0 },
      { label: i18n.t('outputChannel.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  OcppUrl: {
    label: 'OcppUrl.label',
    showTooltip: 'OcppUrl.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  Password: {
    label: 'pass',
    component: CustomTextInput,
    type: 'password',
    className: 'm-input',
    validate: [required],
    required: true
  },
  phaseSwitch: {
    label: 'phaseSwitch.label',
    showTooltip: 'phaseSwitch.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('phaseSwitch.options.0'), value: 0 },
      { label: i18n.t('phaseSwitch.options.1'), value: 1 },
      { label: i18n.t('phaseSwitch.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  port: {
    label: 'port.label',
    showTooltip: 'port.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validatePort],
    normalize: parseStrictPositiveNumbers,
    required: true
  },
  poolHeating: {
    label: 'poolHeating.label',
    showTooltip: 'poolHeating.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  poolHeatingChoice: {
    label: 'poolHeatingChoice.label',
    showTooltip: 'poolHeatingChoice.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('poolHeatingChoice.options.0'), value: 0 },
      { label: i18n.t('poolHeatingChoice.options.1'), value: 1 },
      { label: i18n.t('poolHeatingChoice.options.2'), value: 2 },
      { label: i18n.t('poolHeatingChoice.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  poolOffset: {
    label: 'poolOffset.label',
    showTooltip: 'poolOffset.tooltip',
    className: 'm-input',
    parse: parseOnlyPositiveNumbers,
    component: CustomTextInput,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'poolHeating',
        values: [true]
      }
    ]
  },
  powerEstimation: {
    label: 'powerEstimation.label',
    showTooltip: 'powerEstimation.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  productID: {
    label: 'productID.label',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  roomTempNormalHk2: {
    label: 'roomTempNormalHk2.label',
    showTooltip: 'roomTempNormalHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  reducedCurrent: {
    label: 'reducedCurrent.label',
    showTooltip: 'reducedCurrent.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  relaisChooseSet: {
    label: 'relaisChooseSet',
    component: Select,
    options: () => [
      { label: `${i18n.t('SwitchN')} 1 ${i18n.t('and')} 2`, value: 1 },
      { label: `${i18n.t('SwitchN')} 3 ${i18n.t('and')} 4`, value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisChooseSet2: {
    label: 'relaisChooseSet2.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisChooseSet2.options.1'), value: 1 },
      { label: i18n.t('relaisChooseSet2.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber: {
    label: 'relaisNumber.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber.options.1'), value: 1 },
      { label: i18n.t('relaisNumber.options.2'), value: 2 },
      { label: i18n.t('relaisNumber.options.3'), value: 3 },
      { label: i18n.t('relaisNumber.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber2: {
    label: 'relaisNumber2.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber2.options.1'), value: 1 },
      { label: i18n.t('relaisNumber2.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber3: {
    label: 'relaisNumber3.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber3.options.1'), value: 1 },
      { label: i18n.t('relaisNumber3.options.2'), value: 2 },
      { label: i18n.t('relaisNumber3.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber2Input: {
    label: 'relaisNumber2Input.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber2Input.options.1'), value: 1 },
      { label: i18n.t('relaisNumber2Input.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber4Input: {
    label: 'relaisNumber4Input.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber4Input.options.1'), value: 1 },
      { label: i18n.t('relaisNumber4Input.options.2'), value: 2 },
      { label: i18n.t('relaisNumber4Input.options.3'), value: 3 },
      { label: i18n.t('relaisNumber4Input.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber8: {
    label: 'relaisNumber8.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber8.options.1'), value: 1 },
      { label: i18n.t('relaisNumber8.options.2'), value: 2 },
      { label: i18n.t('relaisNumber8.options.3'), value: 3 },
      { label: i18n.t('relaisNumber8.options.4'), value: 4 },
      { label: i18n.t('relaisNumber8.options.5'), value: 5 },
      { label: i18n.t('relaisNumber8.options.6'), value: 6 },
      { label: i18n.t('relaisNumber8.options.7'), value: 7 },
      { label: i18n.t('relaisNumber8.options.8'), value: 8 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  roomTempOverproductionEnableHk2: {
    label: 'roomTempOverproductionEnableHk2.label',
    showTooltip: 'roomTempOverproductionEnableHk2.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproductionEnable: {
    label: 'roomTempOverproductionEnable.label',
    showTooltip: 'roomTempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproduction: {
    label: 'roomTempOverproduction.label',
    showTooltip: 'roomTempOverproduction.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempNormal: {
    label: 'roomTempNormal.label',
    showTooltip: 'roomTempNormal.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempReduced: {
    label: 'roomTempReduced.label',
    showTooltip: 'roomTempReduced.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempOverproductionHk2: {
    label: 'roomTempOverproductionHk2.label',
    showTooltip: 'roomTempOverproductionHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  roomTempReducedHk2: {
    label: 'roomTempReducedHk2.label',
    showTooltip: 'roomTempReducedHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  roomTempOverproductionEnableHk3: {
    label: 'roomTempOverproductionEnableHk3.label',
    showTooltip: 'roomTempOverproductionEnableHk3.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproductionHk3: {
    label: 'roomTempOverproductionHk3.label',
    showTooltip: 'roomTempOverproductionHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  roomTempNormalHk3: {
    label: 'roomTempNormalHk3.label',
    showTooltip: 'roomTempNormalHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  roomTempReducedHk3: {
    label: 'roomTempReducedHk3.label',
    showTooltip: 'roomTempReducedHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  rTUOrTcp: {
    label: 'rTUOrTcp.label',
    showTooltip: 'rTUOrTcp.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('rTUOrTcp.options.0'), value: 0 },
      { label: i18n.t('rTUOrTcp.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  serialnumberMyPv: {
    label: 'serialnumberMyPv.label',
    component: CustomTextInput,
    className: 'm-input',
    parse: parsePositiveNumbersOrNothing,
    showTooltip: 'serialnumberMyPv.tooltip'
  },
  SerialNumber: {
    label: 'serialNumber',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  SerialNumberSmartMeter: {
    label: 'serialNumberSmartMeter.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  setIdRtu: {
    label: 'setIdRtu.label',
    showTooltip: 'setIdRtu.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  secret: {
    label: 'secret.label',
    showTooltip: 'secret.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  tokenString: {
    label: 'tokenString.label',
    showTooltip: 'tokenString.tooltip',
    component: TokenString,
    className: 'm-input',
    validate: [required],
    required: true
  },
  sgReadyStiebel: {
    label: 'sgReadyStiebel.label',
    showTooltip: 'sgReadyStiebel.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  SmartMeterPosition: {
    label: 'SmartMeterPosition',
    component: Select,
    options: () => [
      { label: i18n.t('beforeInverter'), value: '0' },
      { label: i18n.t('afterInverter'), value: '1' }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  smartGridreadyXml: {
    label: 'smartGridreadyXml.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
    // TODO: check if need a xml parser or validator
  },
  smartPlugChargingType: {
    label: 'smartPlugChargingType.label',
    component: Select,
    options: () => [
      { label: i18n.t('smartPlugChargingType.options.1'), value: 1 },
      { label: i18n.t('smartPlugChargingType.options.2'), value: 2 },
      { label: i18n.t('smartPlugChargingType.options.3'), value: 3 },
      { label: i18n.t('smartPlugChargingType.options.4'), value: 4 },
      { label: i18n.t('smartPlugChargingType.options.5'), value: 5 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  solarOptimized: {
    label: 'solarOptimized.label',
    showTooltip: 'solarOptimized.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  storageMedium: {
    label: 'storageMedium.label',
    showTooltip: 'storageMedium.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('storageMedium.options.0'), value: 0 },
      { label: i18n.t('storageMedium.options.1'), value: 1 },
      { label: i18n.t('storageMedium.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  storageVolume: {
    label: 'storageVolume.label',
    showTooltip: 'storageVolume.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from100to9000],
    required: true
  },
  chargingTimes: {
    label: 'chargingTimes.label',
    component: Select,
    options: () => [
      { label: i18n.t('chargingTimes.options.1'), value: 1 },
      { label: i18n.t('chargingTimes.options.2'), value: 2 },
      { label: i18n.t('chargingTimes.options.3'), value: 3 },
      { label: i18n.t('chargingTimes.options.4'), value: 4 },
      { label: i18n.t('chargingTimes.options.5'), value: 5 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  switchOffDelay: {
    label: 'switchOffDelay.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required, toBeOneOrPositive],
    required: true,
    parse: parseOnlyPositiveIntegerNumbers,
    showTooltip: 'switchOffDelay.tooltip',
    disabledFor: {
      deviceName: 'CTA heat pump',
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'support', 'oem', 'viewer']
    }
  },
  switchOnDelay: {
    label: 'switchOnDelay.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required, toBeOneOrPositive],
    required: true,
    parse: parseOnlyPositiveIntegerNumbers,
    showTooltip: 'switchOnDelay.tooltip',
    disabledFor: {
      deviceName: 'CTA heat pump',
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'support', 'oem', 'viewer']
    }
  },
  switchingTimesEnable: {
    label: 'switchingTimesEnable.label',
    showTooltip: 'switchingTimesEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  switchingTimes: {
    component: SwitchingTimes,
    validate: [requiredGeneralSwitchingTimes],
    enabledIf: [
      {
        fieldID: 'switchingTimesEnable',
        values: [true]
      }
    ]
  },
  switchDelay: {
    label: 'switchDelay.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'switchDelay.tooltip'
  },
  targetTemp: {
    label: 'targetTemp.label',
    showTooltip: 'targetTemp.tooltip',
    parse: parseIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true
  },
  targetTempLowTariff: {
    label: 'targetTempLowTariff.label',
    showTooltip: 'targetTempLowTariff.tooltip',
    parse: parseIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  tempControl: {
    label: 'tempControl.checkboxLabel',
    showTooltip: 'tempControl.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  tempControlLimit: {
    label: 'tempControl.label',
    showTooltip: 'tempControl.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'tempControl',
        values: [true]
      }
    ]
  },
  tokenEQmatic: {
    label: 'tokenEQmatic.label',
    showTooltip: 'tokenEQmatic.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  temperatureVisualizationChoice: {
    label: 'temperatureVisualizationChoice.label',
    showTooltip: 'temperatureVisualizationChoice.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('temperatureVisualizationChoice.options.0'), value: 0 },
      { label: i18n.t('temperatureVisualizationChoice.options.1'), value: 1 },
      { label: i18n.t('temperatureVisualizationChoice.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  thermalDisinfectionEnable: {
    label: 'thermalDisinfectionEnable.label',
    showTooltip: 'thermalDisinfectionEnable.tooltip',
    className: 'm-input',
    parse: parseBoolean,
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  smartCooling: {
    label: 'smartCooling.label',
    showTooltip: 'smartCooling.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  UDPPort: {
    label: 'UDPPort',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  updateRequestIntervalTime: {
    label: 'updateRequestIntervalTime',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  unlocked: {
    label: 'unlocked.label',
    showTooltip: 'unlocked.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  User: {
    label: 'userName',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  callbackUrl: {
    label: 'callbackUrl.label',
    showTooltip: 'callbackUrl.tooltip',
    component: CallbackURL,
    className: 'm-input',
    validate: [required],
    required: true
  },
  waterTempOverproduction: {
    label: 'waterTempOverproduction.label',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  waterTempNormal: {
    label: 'waterTempNormal.label',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  warmWaterDevices: {
    label: 'warmWaterDevices.label',
    showTooltip: 'warmWaterDevices.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices.options.4'), value: 4 },
      { label: i18n.t('warmWaterDevices.options.5'), value: 5 },
      { label: i18n.t('warmWaterDevices.options.6'), value: 6 },
      { label: i18n.t('warmWaterDevices.options.7'), value: 7 },
      { label: i18n.t('warmWaterDevices.options.8'), value: 8 },
      { label: i18n.t('warmWaterDevices.options.9'), value: 9 },
      { label: i18n.t('warmWaterDevices.options.10'), value: 10 },
      { label: i18n.t('warmWaterDevices.options.11'), value: 11 },
      { label: i18n.t('warmWaterDevices.options.12'), value: 12 }
    ]
  },
  warmWaterDevices2Step: {
    label: 'warmWaterDevices2Step.label',
    showTooltip: 'warmWaterDevices2Step.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices2Step.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices2Step.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices2Step.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices2Step.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices2Step.options.4'), value: 4 },
      { label: i18n.t('warmWaterDevices2Step.options.5'), value: 5 },
      { label: i18n.t('warmWaterDevices2Step.options.6'), value: 6 }
    ]
  },
  warmWaterDevices3Step: {
    label: 'warmWaterDevices3Step.label',
    showTooltip: 'warmWaterDevices3Step.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices3Step.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices3Step.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices3Step.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices3Step.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices3Step.options.4'), value: 4 }
    ]
  },
  zone1TempOverproductionEnable: {
    label: 'zone1TempOverproductionEnable.label',
    showTooltip: 'zone1TempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  zone1OverproductionTemp: {
    label: 'zone1OverproductionTemp.label',
    showTooltip: 'zone1OverproductionTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone1NormalTemp: {
    label: 'zone1NormalTemp.label',
    showTooltip: 'zone1NormalTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone1ReducedTemp: {
    label: 'zone1ReducedTemp.label',
    showTooltip: 'zone1ReducedTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2TempOverproductionEnable: {
    label: 'zone2TempOverproductionEnable.label',
    showTooltip: 'zone2TempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  zone2OverproductionTemp: {
    label: 'zone2OverproductionTemp.label',
    showTooltip: 'zone2OverproductionTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2NormalTemp: {
    label: 'zone2NormalTemp.label',
    showTooltip: 'zone2NormalTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2ReducedTemp: {
    label: 'zone2ReducedTemp.label',
    showTooltip: 'zone2ReducedTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  }
};

export default additionalFields;
