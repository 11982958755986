import { createSelector } from 'reselect';

const getUser = (state) => state.signIn?.user;
const getUserDevice = (state) => state.userDevices;
const getModalEditRow = (state) => state?.modals?.editRow;

export const myRoleTypeSelector = createSelector(
  [getUser],
  (user) => user?.role?.type
);

export const sensorIdSelector = createSelector(
  [getModalEditRow],
  (editRow) => editRow?.data?.id
);

export const gatewayIdSelector = createSelector(
  [getUserDevice],
  (userDevices) => userDevices?.user?.gateway?._id
);
