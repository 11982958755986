import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { setAllInstallers, setAllConnectedInstallers } from './actions';

function oemGetInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(strapi.request('get', url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setAllInstallers(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

function getAllConnectedInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_CONNECTED_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(strapi.request('get', url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setAllConnectedInstallers(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

export default combineEpics(
  oemGetInstallersEpic,
  getAllConnectedInstallersEpic
);
