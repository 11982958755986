import { createSelector } from 'reselect';

const getSignInUser = (state) => state.signIn?.user;
const getUser = (state) => state.loadManagementDashboard?.user;
const getSmartMeter = (state) => state.loadManagementDashboard?.smartMeterData;
const getChartData = (state) => state.loadManagementDashboard?.chartData;
const getScaleType = (state) => state.loadManagementDashboard?.scaleType;
const getChart = (state) => state.loadManagementDashboard?.chart;
const getLoading = (state) => state.loadManagementDashboard?.loading;
const getCompanyLogo = (state) => state.loadManagementDashboard?.user?.companyLogo;

export const userSelector = createSelector([getUser], (user) => user);
export const companyLogoSelector = createSelector([getCompanyLogo], (companyLogo) => companyLogo);
export const timezoneSelector = createSelector([getUser], (user) => user?.timezone);
export const signInUserSelector = createSelector([getSignInUser], (user) => user);
export const chartDataSelector = createSelector([getChartData], (data) => data);
export const scaleTypeSelector = createSelector([getScaleType], (scaleType) => scaleType);
export const chartSelector = createSelector([getChart], (chart) => chart);
export const loadingSelector = createSelector([getLoading], (loading) => loading);
export const houseFuseSelector = createSelector([getUser], (user) => user?.houseFuse);
export const smartMeterPhasesDataSelector = createSelector([getSmartMeter], (data) => {
  const {
    currentL1,
    currentL2,
    currentL3,
    currentL1Mean,
    currentL2Mean,
    currentL3Mean
  } = data || {};

  return {
    currentL1,
    currentL2,
    currentL3,
    currentL1Mean,
    currentL2Mean,
    currentL3Mean
  };
});

export const yAxisMaxSelector = createSelector([getSmartMeter, getChartData], (smartMeter, chartData) => {
  const smartMeterMaxValue = Math.max(smartMeter?.currentL1 || 0, smartMeter?.currentL2 || 0, smartMeter?.currentL3 || 0);
  const phase1MaxValue = chartData?.L1 ? Math.max(...chartData.L1.map((value) => value[1])) : 0;
  const phase2MaxValue = chartData?.L2 ? Math.max(...chartData.L2.map((value) => value[1])) : 0;
  const phase3MaxValue = chartData?.L3 ? Math.max(...chartData.L3.map((value) => value[1])) : 0;
  const chartDataMaxValue = Math.max(phase1MaxValue, phase2MaxValue, phase3MaxValue);

  return Math.ceil(Math.max(smartMeterMaxValue, chartDataMaxValue) / 10) * 10;
});

export const yAxisMinSelector = createSelector([getSmartMeter, getChartData], (smartMeter, chartData) => {
  const smartMeterMinValue = Math.min(smartMeter?.currentL1 || 0, smartMeter?.currentL2 || 0, smartMeter?.currentL3 || 0);
  const phase1MinValue = chartData?.L1 ? Math.min(...chartData.L1.map((value) => value[1])) : 0;
  const phase2MinValue = chartData?.L2 ? Math.min(...chartData.L2.map((value) => value[1])) : 0;
  const phase3MinValue = chartData?.L3 ? Math.min(...chartData.L3.map((value) => value[1])) : 0;
  const chartDataMinValue = Math.min(phase1MinValue, phase2MinValue, phase3MinValue);

  return Math.floor(Math.min(smartMeterMinValue, chartDataMinValue) / 10) * 10;
});
