/**
 * Extract hours from time string
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {string} Hours string in format of '00' to '23'
 */
export const getFormattedHours = (time) => {
  const rawHours = time.split(':')[0].slice(0, 2);
  const hours = (+rawHours >= 24 || +rawHours[0] >= 3)
    ? rawHours.slice(0, 1).padStart(2, '0')
    : rawHours.padStart(2, '0');
  return hours;
};

/**
 * Extract minutes from time string
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {string} Minutes string in format of '00' to '59'
 */
export const getFormattedMinutes = (time) => {
  const [, mins = ''] = time.split(':');
  const rawMinutes = mins.slice(0, 2);
  const minutes = (rawMinutes.length && +rawMinutes[0] >= 6)
    ? rawMinutes.slice(0, 1).padStart(2, '0')
    : rawMinutes.padStart(2, '0');
  return minutes;
};

/**
 * Generates an array of number in string format with length of 2 characters
 * @param {number} length Length of the sequence (no more than 100)
 * @returns {array} Array of strings
 */
export const generateTimeStringSequence = (length) => {
  const maxLength = length < 100 ? length : 99;
  return [...Array(maxLength).keys()].map((value) => `${value}`.padStart(2, '0'));
};

/**
 * Get index of an hours value in array of hours
 * @param {array} hoursArr Array of strings in format of ['00', '01', '02', ...]
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {number} Index of the hours value in hours array
 */
export const getHoursIndex = (hoursArr, time) => {
  const hours = getFormattedHours(time);
  return hoursArr.indexOf(hours);
};

/**
 * Get index of an minutes value in array of minutes
 * @param {array} minutesArr Array of strings in format of ['00', '01', '02', ...]
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {number} Index of the minutes value in minutes array
 */
export const getMinutesIndex = (minutesArr, time) => {
  const minutes = getFormattedMinutes(time);
  return minutesArr.indexOf(minutes);
};
