import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { dataListSendRequest, dataListClean } from './actions';
import DataListPagination from './DataListPagination';
// eslint-disable-next-line import/no-named-as-default-member
import DataListFilter from './DataListFilter';
import handleSorting from './handleSorting';
import { allDataListsSelector } from './selectors';
import { DataListFilterV2 } from './DataListFilterV2';
import { DataListV2 } from './DataListV2';

export * from './actions';

export { DataListPagination, DataListFilter, handleSorting, DataListFilterV2, DataListV2 };

/**
 * Component brings in high level abstraction for loaded data.
 * It does fetching data (filtering, sorting, pagination) and keeping it in the redux store.
 * @module DataList
 */
/**
 * Renders DataList
 * @memberof module:DataList
 */
class DataList extends React.Component {
  constructor(props) {
    super(props);

    this.dataList = {
      params: {},
      loading: true
    };
  }

  componentDidMount() {
    const { forceUpdateOnMount } = this.props;
    this.sendInitRequest(forceUpdateOnMount);
  }

  componentWillUnmount() {
    const {
      forceKeepData,
      listID,
      type,
      dataListClean: dataListCleanFromProps
    } = this.props;
    if (!forceKeepData && type !== 'smart-meter') dataListCleanFromProps(listID);
  }

  /**
   * Sends initial request after component mounted
   * @param {boolean} [forceUpdate] Forces to send request (even if this DataList is already loaded)
   */
  sendInitRequest(forceUpdate) {
    const {
      dataListSendRequest: dataListSendRequestFromProps,
      allDataLists,
      listID,
      listURL,
      params,
      subSensorsConfig,
      transformResponse
    } = this.props;

    const usersFilter = JSON.parse(localStorage.getItem('usersFilter'));

    const newParams = {
      ...params
    };
    if (listID === 'sm_users' && usersFilter) {
      if (usersFilter.isInstallationCompleted !== undefined) {
        newParams.isInstallationCompleted = usersFilter.isInstallationCompleted;
      }

      if (usersFilter.supportContractFlag !== undefined) {
        newParams.supportContractFlag = usersFilter.supportContractFlag;
      }
    }

    if (forceUpdate
      || (!allDataLists?.[listID]
        || allDataLists[listID].listURL !== listURL
        || allDataLists[listID].errorMessage
        || allDataLists[listID].params !== params)) {
      const isParentExist = subSensorsConfig?.parentSensorUrl && (allDataLists?.[listID]?.listURL === subSensorsConfig?.parentSensorUrl);
      dataListSendRequestFromProps(
        listID,
        {
          listURL,
          params: newParams,
          transformResponse,
          subSensorsConfig,
          isParentExist
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listURL } = this.props;
    if (nextProps.listURL !== listURL) {
      this.sendInitRequest();
    }
  }

  render() {
    const
      {
        allDataLists,
        dataListSendRequest: dataListSendRequestFromProps,
        Component,
        componentProps,
        subSensorsConfig,
        listID
      } = this.props;
    const sorted = [];

    if (Component) {
      if (allDataLists?.[listID]) {
        this.dataList = { ...allDataLists[listID] };
      }
      if (this.dataList.params?._sort) {
        const actualSorting = this.dataList.params._sort.split(':');
        sorted.push({
          id: actualSorting[0],
          desc: actualSorting[1] === 'desc'
        });
      }

      return (
        <Component
          {...componentProps}
          onSortedChange={handleSorting.bind(null, dataListSendRequestFromProps, listID, this.dataList)}
          data={subSensorsConfig ? this.dataList?.subSensorsData?.data : this.dataList.data}
          sorted={sorted}
          loading={this.dataList.loading}
          {...componentProps}
        />
      );
    }
    return null;
  }
}

DataList.propTypes = {
  listID: PropTypes.string.isRequired,
  type: PropTypes.string,
  forceKeepData: PropTypes.bool,
  Component: PropTypes.func,
  componentProps: PropTypes.instanceOf(Object),
  forceUpdateOnMount: PropTypes.bool,
  subSensorsConfig: PropTypes.instanceOf(Object),
  listURL: PropTypes.string,
  dataListClean: PropTypes.func.isRequired,
  dataListSendRequest: PropTypes.func.isRequired,
  allDataLists: PropTypes.instanceOf(Object).isRequired,
  params: PropTypes.instanceOf(Object),
  transformResponse: PropTypes.func
};

DataList.defaultProps = {
  forceKeepData: false,
  forceUpdateOnMount: false,
  componentProps: {},
  params: {},
  transformResponse: undefined,
  Component: undefined,
  subSensorsConfig: undefined,
  type: undefined,
  listURL: undefined
};

const mapStateToProps = createStructuredSelector({
  allDataLists: allDataListsSelector
});

export default connect(mapStateToProps, {
  dataListSendRequest, dataListClean
})(DataList);
