import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  required,
  equals,
  passwordAdvanced,
  zipCode,
  minLength,
  maxLength,
  toBeTrueCustomWarning,
  email,
  parseToLowerCase,
  checkValidPhone
} from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import CommonPhoneInput from '../../components/ReduxFormFields/PhoneTextInput/CommonPhoneInput';
import UserPhoneInput from '../../components/ReduxFormFields/PhoneTextInput/UserPhoneInput';
import CountrySelector from '../../components/ReduxFormFields/ContrySelector';
import Checkbox from '../../components/ReduxFormFields/Checkbox';
import '../../App.css';
import { LanguageSelector } from '../../components/UIKit';
import Logo from '../../components/Logo';
import i18n from '../../i18n';
import Footer from '../../components/Footer';
import { endUserType, defaultCountry } from './constants';

import './index.scss';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
const requireTerms = toBeTrueCustomWarning('youMustAcceptTerms');
const validateConfirmPass = equals('password');
let ifRequiredField = () => undefined;
/**
 * @class SignUpForm2
 * @memberof module:SignUp2
 * @description renders SignUp Form (second step)
 * @extends Component
 */
export const SignUpForm2 = (props) => {
  const {
    handleSubmit,
    initialValues,
    verified,
    errorMessage,
    stopMessage,
    pristine,
    submitting
  } = props;

  let requiredStar = '';

  const unAcceptabelRoles = ['end_user', 'viewer', 'pv_installer_employee', 'pv_installer_employee_read_only'];

  if (verified && !(initialValues && unAcceptabelRoles.includes(initialValues.role.type))) {
    requiredStar = ' *';
    ifRequiredField = required;
  }

  const isEndUser = initialValues?.role?.type === endUserType;

  useEffect(() => {
    const { initialize } = props;
    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-lg-12">
                <div className="m-login__head">
                  <h3 className="m-login__title">{i18n.t('creatingAccbtn')}</h3>
                </div>
                {stopMessage && <div className="loadingText">{stopMessage}</div>}
                {!stopMessage && (
                  <Fragment>
                    {!verified && (
                      <div className="loadingText">
                        {!errorMessage && <Fragment>{i18n.t('linkVerifmess')}</Fragment>}
                        {errorMessage && <Fragment>{errorMessage}</Fragment>}
                      </div>
                    )}
                    {verified && (
                      <form className="m-login__form m-form">
                        <div className="row">
                          <div className="col-lg-6">
                            {initialValues.sm_id && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="sm_id"
                                  component={CustomTextInput}
                                  label={i18n.t('SMID')}
                                  className="m-input"
                                  disabled
                                />
                              </div>
                            )}
                            {initialValues.pv_installer && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="pv_installer"
                                  component={CustomTextInput}
                                  label={i18n.t('reqCompanyName')}
                                  className="m-input"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            )}
                            {initialValues.oem && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="oem"
                                  component={CustomTextInput}
                                  label={i18n.t('reqCompanyName')}
                                  className="m-input"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            )}
                            {initialValues.email && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="email"
                                  component={CustomTextInput}
                                  label={i18n.t('mail')}
                                  className="m-input"
                                  autoComplete="off"
                                  validate={[required, email]}
                                  parse={parseToLowerCase}
                                  disabled
                                  languageToSync={i18n.language}
                                />
                              </div>
                            )}
                            {!isEndUser && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="phone"
                                  component={CommonPhoneInput}
                                  label={i18n.t('phoneNumber')}
                                  country={initialValues.country || defaultCountry}
                                  className="m-input"
                                  autoComplete="off"
                                  validate={[checkValidPhone]}
                                  languageToSync={i18n.language}
                                />
                              </div>
                            )}
                            <div className="form-group m-form__group input-field">
                              <Field
                                name="password"
                                component={CustomTextInput}
                                label={i18n.t('reqPass')}
                                className="m-input"
                                type="password"
                                autoComplete="off"
                                validate={[required, passwordAdvanced]}
                                languageToSync={i18n.language}
                              />
                            </div>
                            <div className="form-group m-form__group input-field">
                              <Field
                                name="rep-password"
                                component={CustomTextInput}
                                label={i18n.t('repReqPass')}
                                type="password"
                                className="m-input"
                                autoComplete="off"
                                validate={[required, passwordAdvanced, validateConfirmPass]}
                                languageToSync={i18n.language}
                              />
                            </div>
                            <div className="form-group m-form__group input-field">
                              {initialValues.role.type === 'end_user' && (
                                <div className="form-group m-form__group input-field input-filed-flags-select">
                                  <div className="flags-select-label">
                                    {`${i18n.t('Country')} *`}
                                  </div>
                                  <Field
                                    name="country"
                                    id="country"
                                    component={CountrySelector}
                                    buttonClassName="country-btn"
                                    label={i18n.t('Country')}
                                    className="m-input flagSelectClosed"
                                    autoComplete="off"
                                    validate={[required]}
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              className="terms-condition-block form-group m-form__group m-login__form-sub input-field terms-cond"
                            >
                              <div className="m--align-left">
                                <Field
                                  type="checkbox"
                                  name="accept"
                                  component={Checkbox}
                                  labelClass="m-checkbox terms m-checkbox--light checkbox-terms"
                                  label={(
                                    <React.Fragment>
                                      {i18n.t('acception')}
                                      {' '}
                                      <a
                                        className="m-link m-link--light"
                                        href="https://www.solarmanager.ch/dokumente/agb/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        {i18n.t('termsCond')}
                                      </a>
                                    </React.Fragment>
                                  )}
                                  autoComplete="off"
                                  className=""
                                  languageToSync={i18n.language}
                                  validate={[requireTerms]}
                                />
                                <span className="m-form__help" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {isEndUser && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="company_name"
                                  component={CustomTextInput}
                                  label={i18n.t('userCompany')}
                                  className="m-input"
                                  autoComplete="off"
                                />
                              </div>
                            )}
                            <div className="form-group m-form__group input-field">
                              <Field
                                name="first_name"
                                component={CustomTextInput}
                                label={i18n.t('reqFirstName')}
                                className="m-input"
                                autoComplete="off"
                                validate={[required, minLen3, maxLen50]}
                                languageToSync={i18n.language}
                              />
                            </div>
                            <div className="form-group m-form__group input-field">
                              <Field
                                name="last_name"
                                component={CustomTextInput}
                                label={i18n.t('reqLastName')}
                                className="m-input"
                                autoComplete="off"
                                validate={[required, minLen3, maxLen50]}
                                languageToSync={i18n.language}
                              />
                            </div>
                            {isEndUser && (
                              <div className="form-group m-form__group input-field">
                                <Field
                                  name="phone"
                                  component={UserPhoneInput}
                                  label={i18n.t('phoneNumber')}
                                  country={defaultCountry}
                                  className="m-input"
                                  autoComplete="off"
                                  validate={[checkValidPhone]}
                                />
                              </div>
                            )}
                            <div className="form-group m-form__group input-field">
                              <Field
                                name="street"
                                component={CustomTextInput}
                                label={i18n.t('street') + requiredStar}
                                className="m-input"
                                autoComplete="off"
                                validate={[ifRequiredField, minLen3, maxLen50]}
                                languageToSync={i18n.language}
                              />
                            </div>
                            <div className="row city-zip">
                              <div className="form-group m-form__group input-field col-7 city-inp city-inp-comp-set">
                                <Field
                                  name="city"
                                  component={CustomTextInput}
                                  label={i18n.t('city') + requiredStar}
                                  className="m-input"
                                  autoComplete="off"
                                  validate={[ifRequiredField, minLen3, maxLen50]}
                                  languageToSync={i18n.language}
                                />
                              </div>
                              <div className="form-group m-form__group input-field offset-1 col-4 zip-inp zip-inp-comp-set">
                                <Field
                                  name="zip"
                                  component={CustomTextInput}
                                  label={i18n.t('zip') + requiredStar}
                                  className="m-input"
                                  autoComplete="off"
                                  validate={[ifRequiredField, zipCode]}
                                  languageToSync={i18n.language}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="m-login__form-action">
                          <button
                            onClick={handleSubmit}
                            disabled={pristine || submitting}
                            type="submit"
                            id="m_login_signup_submit"
                            className="btn m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                          >
                            {i18n.t('createBtn')}
                          </button>
                        </div>
                      </form>
                    )}
                  </Fragment>
                )}
                <div className="m-have-account">
                  <Link
                    to="/login"
                    id="m_login_signup_cancel"
                    className="m-link m-link--light m-login__account-link"
                  >
                    {i18n.t('haveAcc')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

SignUpForm2.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  verified: PropTypes.bool,
  errorMessage: PropTypes.string,
  stopMessage: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

SignUpForm2.defaultProps = {
  verified: undefined,
  initialValues: undefined,
  errorMessage: undefined,
  stopMessage: undefined
};

export default reduxForm({
  form: 'SignUpForm2'
})(SignUpForm2);
