export const CHANGE_FIELDS = 'CHANGE_FIELDS';
export const MONITORING_REPLACE_SELECTED = 'MONITORING_REPLACE_SELECTED';
export const MONITORING_ADD_SELECTED = 'MONITORING_ADD_SELECTED';
export const MONITORING_CLEAR_SELECTED = 'MONITORING_CLEAR_SELECTED';
export const CHANGE_ARCHIEVE_ERROR_SWITCH = 'CHANGE_ARCHIEVE_ERROR_SWITCH';
export const CHANGE_SUPPORT_CONTRACT_SWITCH = 'CHANGE_SUPPORT_CONTRACT_SWITCH';
export const CHANGE_INSTALLATION_COMPLETED_SWITCH = 'CHANGE_INSTALLATION_COMPLETED_SWITCH';
export const EMPLOYEE_SEND_REQUEST = 'EMPLOYEE_SEND_REQUEST';
export const EMPLOYEE_RECEIVED = 'EMPLOYEE_RECEIVED';
export const ADD_CHOOSED_COMPANY = 'ADD_CHOOSED_COMPANY';
export const ADD_CHOOSED_STATUS_OF_COMPANY = 'ADD_CHOOSED_STATUS_OF_COMPANY';
export const SAVE_ALL_DATA_LIST_USERS = 'SAVE_ALL_DATA_LIST_USERS';
export const MONITORING_GET_INSTALLERS = 'MONITORING_GET_INSTALLERS';
export const MONITORING_INSTALLERS_RECEIVED = 'MONITORING_INSTALLERS_RECEIVED';
export const MONITORING_CLEAR_FILTERS = 'MONITORING_CLEAR_FILTERS';
export const ADD_PARSED_DATA = 'ADD_PARSED_DATA';
export const GET_ERROR_INFO = 'GET_ERROR_INFO';
export const ERROR_INFO_RECEIVED = 'ERROR_INFO_RECEIVED';
export const SYNC_FILTERS_FROM_LOCAL_STORAGE = 'SYNC_FILTERS_FROM_LOCAL_STORAGE';
export const SET_IS_SHOW_PLANT_COLUMN = 'SET_IS_SHOW_PLANT_COLUMN';
export const SET_IS_SHOW_LAST_LOGIN_DATE_COLUMN = 'SET_IS_SHOW_LAST_LOGIN_DATE_COLUMN';
export const SET_IS_SHOW_INSTALLATION_DATE_COLUMN = 'SET_IS_SHOW_INSTALLATION_DATE_COLUMN';
export const UPDATE_COLUMN_PROPS_FOR_MONITORING = 'UPDATE_COLUMN_PROPS_FOR_MONITORING';

export const DATA_LIST_ID = 'monitoring';
export const EMPLOYEE_LIST_ID = 'company';
export const DATA_LIST_URL = '/../errormonitoring/tickets';

export const PRIORITY_ARR = [{
  key: 'low',
  value: 'low'
}, {
  key: 'medium',
  value: 'medium'
}, {
  key: 'high',
  value: 'high'
}];

export const WORK_STATUS_ARR = [{
  key: 'new',
  value: 'new'
}, {
  key: 'pending',
  value: 'pending'
}, {
  key: 'wait for the customer',
  value: 'wait for the customer'
}, {
  key: 'wait for the customer / new',
  value: 'wait for the customer / new'
}, {
  key: 'schedule service',
  value: 'schedule service'
}, {
  key: 'service planned',
  value: 'service planned'
}, {
  key: 'in progress',
  value: 'in progress'
}, {
  key: 'on hold',
  value: 'on hold'
}, {
  key: 'resolved',
  value: 'resolved'
}];

export const WORK_STATUS_OBJ = {
  options: [{
    name: 'new',
    id: 'new'
  }, {
    name: 'pending',
    id: 'pending'
  }, {
    name: 'wait for the customer',
    id: 'wait for the customer'
  }, {
    name: 'wait for the customer / new',
    id: 'wait for the customer / new'
  }, {
    name: 'schedule service',
    id: 'schedule service'
  }, {
    name: 'service planned',
    id: 'service planned'
  }, {
    name: 'in progress',
    id: 'in progress'
  }, {
    name: 'on hold',
    id: 'on hold'
  }, {
    name: 'resolved',
    id: 'resolved'
  }, {
    name: 'done',
    id: 'done'
  }]
};

export const GET_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'my_company:asc'
};

export const waitForTheCustomerNewStatus = 'wait for the customer / new';

export const switchesArray = [
  {
    key: 'plant',
    i18nLabel: 'editColumnsModalEnabledPlant'
  },
  {
    key: 'last_login',
    i18nLabel: 'editColumnsModalEnabledLastLogin'
  },
  {
    key: 'installationDate',
    i18nLabel: 'editColumnsModalEnabledInstallationDate'
  }
];
