import * as actionTypes from './constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.SIGNUP2_VERIFY_SUCC:
      return {
        verified: true,
        initialValues: {
          ...action.payload.fields,
          code: action.payload.verifyCode
        }
      };
    case actionTypes.SIGNUP2_VERIFY_ERROR:
      return {
        errorMessage: action.payload.message
      };
    case actionTypes.SIGNUP2_SHOW_STOP_MESSAGE:
      return {
        stopMessage: action.payload.message
      };
    default:
      return state;
  }
};
