import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

/**
 * Renders user avatar
 * @function Avatar
 */
export const Avatar = (props) => {
  const {
    name, avatar, className, background
  } = props;

  /**
   * Return first letter from user name
   * @param {string} name - user name.
   */
  const getLetter = (userName) => userName.charAt(0);

  /**
   * Return color for background, relative to name
   * @param {string} name - user name.
   */
  const getColor = (userName) => {
    const colors = ['#f8ad40', '#7191ad', '#f5753c', '#33517d', '#337d40', '#7d7133', '#7d3340', '#33467d'];

    const number = (userName.charCodeAt(0) + userName.length) % colors.length;
    return colors[number];
  };

  if (background) {
    return (
      <div
        style={{ backgroundImage: `url(${background})` }}
        className={`usr-default-avatar ${className}`}
      />
    );
  }
  if (avatar) {
    return (
      <div
        style={{ backgroundImage: `url(${avatar})` }}
        className={`usr-default-avatar ${className}`}
      />
    );
  }

  return (
    <div
      style={{ backgroundColor: getColor(name) }}
      className={`usr-default-avatar ${className}`}
    >
      {getLetter(name)}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool,
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

Avatar.defaultProps = {
  className: '',
  avatar: false,
  name: '',
  background: false
};

export default Avatar;
