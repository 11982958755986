import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import ModalWindow from '../../ModalWindow';
import CustomSelect from '../../../components/ReduxFormFields/CustomSelect';
import Preloader from '../../../components/Preloader';
import MultipleSelect from '../../../components/ReduxFormFields/MultipleSelect';

import { openModalWindow, closeModalWindow } from '../../ModalWindow/actions';

import { required, requiredMultiple } from '../../validation';

import getFunctionOptions from '../utils/getFunctionOptions';

import i18n from '../../../i18n';
import {
  affectedDevicesOptionsSelector,
  devicesListSelector,
  inputDevicesOptionsSelector,
  isLoadingSelector,
  modalsSelector,
  myRoleTypeSelector
} from './selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

/**
 * EditDeviceWindow form id
 */
const formID = 'editLoadguardForm';

/**
 * Modal window for editing device
 * @memberof module:UserDevices
 */
const EditDeviceWindow = (props) => {
  const {
    handleOnClose,
    handleSubmit,
    modalID,
    pristine,
    isLoading,
    modals,
    reset,
    affectedDevicesOptions,
    inputDevicesOptions
  } = props;

  const modal = modals?.[modalID];

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const functionOptions = getFunctionOptions();

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              id={formID}
              className="m-login__form m-form pop-up-form add-sm-us"
              onSubmit={handleSubmit}
            >
              <div className="form-group m-form__group input-field zIndex20">
                <div className="flags-select-label">{`${i18n.t('inputDevice')} *`}</div>
                <Field
                  name="inputDevice"
                  component={CustomSelect}
                  placeholder={i18n.t('selectInputDevice')}
                  options={inputDevicesOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  disabled
                />
              </div>
              <div className="form-group m-form__group input-field zIndex19">
                <div className="flags-select-label">{`${i18n.t('function')} *`}</div>
                <Field
                  name="inputDeviceFunction"
                  component={CustomSelect}
                  placeholder={i18n.t('selectFunction')}
                  options={functionOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                />
              </div>
              <div className="form-group m-form__group flex-multiple-select">
                <Field
                  label={`${i18n.t('affectedDevices')} *`}
                  name="affectedDevices"
                  component={MultipleSelect}
                  placeholder={i18n.t('selectAffectedDevices')}
                  options={affectedDevicesOptions}
                  validate={[requiredMultiple]}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              disabled={pristine}
              form={formID}
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              <i className="fa fa-save" />
              {i18n.t('save')}
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

EditDeviceWindow.propTypes = {
  modalID: PropTypes.string,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  modals: PropTypes.instanceOf(Object),
  pristine: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  affectedDevicesOptions: PropTypes.instanceOf(Object).isRequired,
  inputDevicesOptions: PropTypes.instanceOf(Object).isRequired
};

EditDeviceWindow.defaultProps = {
  modalID: '',
  modals: {},
  isLoading: false
};

const form = reduxForm({
  form: formID,
  enableReinitialize: true
})(EditDeviceWindow);

const selector = formValueSelector(formID);

const mapStateToProps = createStructuredSelector({
  inputDevice: (state) => selector(state, 'inputDevice'),
  inputDeviceFunction: (state) => selector(state, 'inputDeviceFunction'),
  affectedDevices: (state) => selector(state, 'affectedDevices'),
  modals: modalsSelector,
  devicesList: devicesListSelector,
  myRoleType: myRoleTypeSelector,
  isLoading: isLoadingSelector,
  affectedDevicesOptions: affectedDevicesOptionsSelector,
  inputDevicesOptions: inputDevicesOptionsSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow
})(form);
