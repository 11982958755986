import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { goBack, replace } from 'react-router-redux';
import FormData from 'form-data';
import { map, switchMap, catchError, mergeMap, finalize } from 'rxjs/operators';

import { handleErrorDetailed, handleError } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { signInUpdateUser } from '../SignIn/actions';
import { addNotification } from '../NotificationGenerator/actions';
import { settingPrGetUserSucc, settingPrHasChanged, setOEMs } from './actions';
import { openModalWindow, closeModalWindow } from '../ModalWindow/actions';
import i18n, { changeLanguageTo } from '../../i18n';
import { APP_ID } from '../../config';
import { parseStringOfNumbers } from '../validation';
import analytics from '../../analytics';
import getEventObj, { USER_ME, USER_UPDATE } from '../../analytics/events';

function settingPrGetUserEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.SETTING_PROF_GET_USER),
    map((action) => action.payload),
    switchMap(({ userID, myself, roleLink, successfulCB }) => {
      let url;
      const params = {};
      if (myself) {
        url = '/../user/me';
        params.app = APP_ID;
        params.isWeb = true;
      } else if (roleLink === 'oem') {
        url = `/../${roleLink}/${userID}`;
      } else {
        url = `/../users/${roleLink}/${userID}`;
      }

      return from(strapi.request('get', url, { params })).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(USER_ME, {}, { url }));
        }),
        map((result) => {
          const state = state$.value;
          if (!result.error) {
            if (typeof successfulCB === 'function' && state.signIn?.user?._id) {
              successfulCB(result, myself);
            }
            return settingPrGetUserSucc(result, myself);
          }

          if (myself) {
            strapi.clearToken();
          }

          return addNotification({
            type: 'error',
            text: result.message
          });
        })
      );
    })
  );
}

function settingPrChangeEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.SETTING_PROF_CHANGE),
    map((action) => action.payload),
    switchMap(({ myself, roleLink, userID, fields, modalID }) => {
      let url;
      const {
        signIn: {
          user: {
            role: { type: myRoleType }
          }
        }
      } = state$.value;

      if (myself && myRoleType === 'oem') {
        url = '/../oem';
      } else if (myself && myRoleType !== 'oem') {
        url = '/../user/update';
      } else if (roleLink === 'oem') {
        url = `/../${roleLink}/${userID}`;
      } else {
        url = `/../users/${roleLink}/${userID}`;
      }

      const myForm = new FormData();
      const protectedFields = ['old_email', 'password', 'avatarURL', 'companyLogoUrl', 'old_sm_id', '_submitConfirmed'];

      Object.entries(fields).forEach(([fieldID, fieldValue]) => {
        const isProtected = protectedFields.includes(fieldID);
        const isLogoAvatarOrBool = fieldID === 'company_logo' || fieldID === 'avatar' || typeof fieldValue === 'boolean';

        if (isProtected) {
          return;
        }

        if (fieldID === 'phone') {
          myForm.append(fieldID, parseStringOfNumbers(fieldValue || ''));
          return;
        }

        if (isLogoAvatarOrBool) {
          myForm.append(fieldID, fieldValue);
          return;
        }

        if (typeof fieldValue === 'string') {
          myForm.append(fieldID, fieldValue.trim());
        }
      });

      if (fields.save_data === false && !fields._submitConfirmed) {
        return of(
          openModalWindow('confirmationWindow', {
            sm_id: fields.sm_id,
            save_data: fields.save_data
          })
        );
      }

      return from(strapi.request('put', url, { data: myForm })).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(USER_UPDATE, {}, { myself, myRoleType }));
        }),
        mergeMap((result) => {
          if (myself && result.language) changeLanguageTo(result.language, true);
          if (!result.error) {
            if (modalID) {
              if (result.change_email) {
                return of(
                  closeModalWindow(modalID),
                  settingPrHasChanged(),
                  replace(),
                  addNotification({
                    type: 'success',
                    text: i18n.t('changeEmailConfirmSent')
                  })
                );
              }
              return of(
                closeModalWindow(modalID),
                settingPrGetUserSucc(result, myself),
                settingPrHasChanged(),
                replace(),
                addNotification({
                  type: 'success',
                  text: i18n.t('changesWereSaved')
                })
              );
            }
            return of(
              goBack(),
              settingPrHasChanged(),
              addNotification({
                type: 'success',
                text: i18n.t('changesWereSaved')
              }),
              ...(myself ? [signInUpdateUser(result)] : [])
            );
          }

          return of(
            settingPrHasChanged(),
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        })
      );
    })
  );
}

function reSendEmail(action$) {
  return action$.pipe(
    ofType(actionTypes.EMAIL_RESEND),
    map((action) => action.payload),
    switchMap(({ email, language }) => from(
      strapi.request('post', '/../signup/resend/verify', {
        data: { email },
        params: { language }
      })
    ).pipe(
      catchError(handleError),
      map((result) => {
        if (result?.email) {
          return addNotification({
            type: 'success',
            text: i18n.t('emailSends')
          });
        }

        return addNotification({
          type: 'error',
          text: result
        });
      })
    )
    )
  );
}

function getOEMsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_FOR_SETTING),
    map((action) => action.payload),
    switchMap(() => from(strapi.request('get', '/../oem/connected-oems')).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

function getOEMsByIDEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_BY_ID_FOR_SETTING),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(strapi.request('get', url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs(result.list);
          }
          return addNotification({
            type: 'error',
            text: result
          });
        }
        return addNotification({
          type: 'error',
          text: result.message
        });
      })
    )
    )
  );
}

export default combineEpics(
  settingPrGetUserEpic,
  settingPrChangeEpic,
  reSendEmail,
  getOEMsEpic,
  getOEMsByIDEpic
);
