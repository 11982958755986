import React from 'react';
import PropTypes from 'prop-types';

const style = {
  color: 'white',
  fontSize: '2.1vh',
  marginTop: '10px'
};

const PowerFlowDetailsInfo = ({ i18n }) => (
  <div className="container" style={style}>
    {i18n.t('descriptionForPowerFlowScreen')}
  </div>
);

PowerFlowDetailsInfo.propTypes = {
  i18n: PropTypes.instanceOf(Object).isRequired
};

export default PowerFlowDetailsInfo;
