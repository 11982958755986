import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import Preloader from '../../components/Preloader';
import i18n from '../../i18n';
import CustomSelect from '../../components/ReduxFormFields/CustomSelect';
import CustomTextField from '../../components/ReduxFormFields/CustomTextField';
import { required, gateways as gatewaysValidator } from '../validation';
import { fetchHardwareListRequest } from './actions';
import { ADD_GATEWAY_FORM_ID, MAX_CHARS_COUNT } from './constants';

import './index.css';
import {
  addGatewayModalSelector,
  defaultLicenseIdSelector,
  hardwaresListSelector,
  isLoadingSelectorModals,
  licensesListSelector,
  modalsSelector
} from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * Renders add gateway modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
const AddGatewayWindow = (props) => {
  const {
    modals,
    modalID,
    initialize,
    fetchHardwareListRequest: fetchHardwareList,
    hardwaresList,
    defaultLicenseId,
    handleOnClose,
    handleSubmit,
    isLoading,
    gateways,
    licensesList,
    initialLicense,
    hardware
  } = props;

  const modal = modals?.[modalID];

  const hardwaresOptions = hardwaresList.map((value) => ({ value, label: value }));
  const licenseOptions = licensesList.map((value) => ({ label: value.name, value: value._id }));

  useEffect(() => {
    if (modal?.opened) {
      fetchHardwareList();
    }
  }, [fetchHardwareList, modal?.opened]);

  useEffect(() => {
    if (modal?.opened && hardwaresList.length && defaultLicenseId) {
      initialize({
        hardware: hardware || hardwaresList[0],
        initialLicense: initialLicense || defaultLicenseId,
        gateways: gateways ? gateways : ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultLicenseId,
    gateways,
    hardware,
    hardwaresList,
    initialLicense,
    modal?.opened
  ]);

  useEffect(() => {
    if (!modal?.opened) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addGateway')}</h5>
      </div>
      {isLoading ? (
        <div className="add-gateway-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body modal-body-gateway">
            <form
              noValidate
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-gateway"
              id={ADD_GATEWAY_FORM_ID}
            >
              <div className="form-group m-form__group input-field">
                <div className="flags-select-label">{`${i18n.t('hardwareLabel')} *`}</div>
                <Field
                  name="hardware"
                  component={CustomSelect}
                  placeholder={i18n.t('selectHardware')}
                  options={hardwaresOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  creatable
                  props={{
                    inputProps: {
                      autoComplete: 'new-password', // to disable autocomplete, "off" does not work
                      autoCorrect: 'off',
                      spellCheck: false
                    }
                  }}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <div className="flags-select-label">{`${i18n.t('License')} *`}</div>
                <Field
                  name="initialLicense"
                  component={CustomSelect}
                  options={licenseOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  props={{
                    inputProps: {
                      autoComplete: 'new-password' // to disable autocomplete, "off" does not work
                    }
                  }}
                />
              </div>
              <div className="form-group m-form__group">
                <div className="flags-select-label">{`${i18n.t('nameOrSmid')} *`}</div>
                <Field
                  name="gateways"
                  placeholder={i18n.t('nameOrSmidPlaceholder')}
                  component={CustomTextField}
                  className="name-or-smid-note"
                  maxLength={MAX_CHARS_COUNT}
                  validate={[required, gatewaysValidator]}
                  props={{
                    inputProps: {
                      autoComplete: 'new-password', // to disable autocomplete, "off" does not work
                      autoCorrect: 'off',
                      spellCheck: false
                    }
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              form={ADD_GATEWAY_FORM_ID}
            >
              <i className="fa fa-save" />
              {i18n.t('save')}
            </button>
          </div>
        </>
      )}
    </ModalWindow>
  );
};

const form = reduxForm({
  form: ADD_GATEWAY_FORM_ID,
  enableReinitialize: true
})(AddGatewayWindow);

AddGatewayWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modals: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  initialize: PropTypes.func.isRequired,
  fetchHardwareListRequest: PropTypes.func.isRequired,
  gateways: PropTypes.string,
  hardwaresList: PropTypes.arrayOf(PropTypes.string),
  licensesList: PropTypes.instanceOf(Object),
  defaultLicenseId: PropTypes.string.isRequired,
  initialLicense: PropTypes.string.isRequired,
  hardware: PropTypes.string.isRequired
};

AddGatewayWindow.defaultProps = {
  modals: {},
  isLoading: false,
  gateways: '',
  hardwaresList: [],
  licensesList: []
};

const selector = formValueSelector(ADD_GATEWAY_FORM_ID);

const mapStateToProps = createStructuredSelector({
  gateways: (state) => selector(state, 'gateways'),
  hardware: (state) => selector(state, 'hardware'),
  initialLicense: (state) => selector(state, 'initialLicense'),
  isLoading: isLoadingSelectorModals,
  modal: addGatewayModalSelector,
  modals: modalsSelector,
  hardwaresList: hardwaresListSelector,
  licensesList: licensesListSelector,
  defaultLicenseId: defaultLicenseIdSelector
});

export default connect(mapStateToProps, {
  fetchHardwareListRequest
})(form);
