export const DATA_LIST_ID = 'documentation';
export const UPLOAD_MODAL_ID = 'uploadModal';
export const LIMIT_SIZE_MODAL_ID = 'limitSizeModal';
export const DELETE_FILES_MODAL_ID = 'deleteFilesModal';

export const GET_FILES_COUNT = 'GET_FILES_COUNT';
export const SET_FILES_COUNT = 'SET_FILES_COUNT';
export const GET_DOCUMENTATION_FILES = 'GET_DOCUMENTATION_FILES';
export const SET_DOCUMENTATION_FILES_LOADING = 'SET_DOCUMENTATION_FILES_LOADING';
export const SET_DOCUMENTATION_FILES = 'SET_DOCUMENTATION_FILES';
export const ADD_FILES = 'ADD_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const SET_FILES_SELECTION = 'SET_FILES_SELECTION';
export const DOWNLOAD_FILES = 'DOWNLOAD_FILES';
export const FILES_LOADING_IDS = 'FILES_LOADING_IDS';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const SET_FILES_FOR_UPLOAD = 'SET_FILES_FOR_UPLOAD';
export const UPLOAD_FILES_LOADING = 'UPLOAD_FILES_LOADING';

export const SKIP_ACTION = 'SKIP_ACTION';

export const MAX_FILE_SIZE = 104857600;

export const allowedRolesForUpdateAndDeleteFiles = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'support', 'oem'];
