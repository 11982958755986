import * as actionTypes from './constants';

export function getAllInstallers(url, params) {
  return {
    type: actionTypes.GET_ALL_INSTALLERS,
    payload: {
      url,
      params
    }
  };
}

export function setAllInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_INSTALLERS,
    payload: {
      installers
    }
  };
}

export function getAllConnectedInstallers(url, params) {
  return {
    type: actionTypes.GET_ALL_CONNECTED_INSTALLERS,
    payload: {
      url,
      params
    }
  };
}

export function setAllConnectedInstallers(installers) {
  return {
    type: actionTypes.SET_ALL_CONNECTED_INSTALLERS,
    payload: {
      installers
    }
  };
}
