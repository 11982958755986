import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import ReactTable from 'react-table';

// eslint-disable-next-line import/no-named-as-default-member
import ChooseLicenseWindow from '../../../../components/ChooseLicenseWindow';
import { CompanyInfoSub } from '../../../../components/ReactTable/renderFunctions';
import i18n from '../../../../i18n';
import DataList, { dataListReloadData } from '../../../DataList';
import { closeModalWindow, modalWindowSend, openModalWindow } from '../../../ModalWindow/actions';
import { addPVInstallersToViewerModalID, dataListIDPVInstallersForViewer, getInstallersForViewerParams } from '../../constants';
// eslint-disable-next-line import/named
import { AddPVInstallerToViewerWindow, DeletePVInstallerWindow } from '../../modals';
import { columnsPVI } from '../../columns';
import { getAllNotAttachedToViewerInstallers } from '../../actions';

const PVInstallerTab = memo((props) => {
  const {
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    closeModalWindow: closeModal,
    connectedPvInstallersUrl,
    transformResponse,
    onExpandedChange,
    viewerID,
    expanded
  } = props;
  const dispatch = useDispatch();

  // Delete PV Installer
  const sendDeletePVInstaller = ({ id }) => modalSend(
    'deleteData',
    {
      method: 'put',
      url: '/../users/remove-viewer-from-users',
      data: {
        viewerId: viewerID,
        userIds: [id]
      }
    },
    () => {
      dispatch(getAllNotAttachedToViewerInstallers({ viewerId: viewerID, ...getInstallersForViewerParams }));
      return dataListReloadData(dataListIDPVInstallersForViewer);
    }
  );

  // Add PV Installer
  const sendAddInstallers = (userIds) => {
    modalSend(
      addPVInstallersToViewerModalID,
      {
        method: 'put',
        url: '/../users/attach-viewer-to-users',
        data: {
          viewerId: viewerID,
          userIds
        }
      },
      () => {
        dispatch(getAllNotAttachedToViewerInstallers({ viewerId: viewerID, ...getInstallersForViewerParams }));
        return dataListReloadData(dataListIDPVInstallersForViewer);
      }
    );
  };

  return (
    <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
      <div className="pvInstallerAddBtn">
        <a
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
          onClick={openModal.bind(null, addPVInstallersToViewerModalID, null)}
        >
          <span>
            <span>{i18n.t('addPVIbtn')}</span>
          </span>
        </a>
      </div>
      <DeletePVInstallerWindow
        modalID="deleteData"
        onSubmit={sendDeletePVInstaller}
        handleOnClose={closeModal.bind(null, 'deleteData')}
      />
      <AddPVInstallerToViewerWindow
        modalID={addPVInstallersToViewerModalID}
        handleSubmit={sendAddInstallers}
        viewerUserId={viewerID}
        handleOnClose={closeModal.bind(null, addPVInstallersToViewerModalID)}
      />
      <DataList
        listID={dataListIDPVInstallersForViewer}
        listURL={connectedPvInstallersUrl}
        params={{ ...getInstallersForViewerParams }}
        transformResponse={transformResponse}
        Component={ReactTable}
        componentProps={{
          defaultPageSize: 0,
          className: '-highlight m-datatable__table',
          columns: columnsPVI(),
          SubComponent: CompanyInfoSub,
          manual: true,
          onExpandedChange,
          expanded
        }}
      />
      <ChooseLicenseWindow />
    </div>
  );
});

PVInstallerTab.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  viewerID: PropTypes.string.isRequired,
  connectedPvInstallersUrl: PropTypes.string.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  expanded: PropTypes.instanceOf(Object)
};

PVInstallerTab.defaultProps = {
  expanded: undefined
};

export default connect(null, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(PVInstallerTab);
