import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router';
import PuffLoader from 'react-spinners/PuffLoader';
import { translate } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import {
  signInCheckJWT,
  signInValidJWT,
  signInInvalidJWT,
  logOut,
  signInGoBackIfSucc
} from '../SignIn/actions';
import strapi from '../../strapi';
import Preloader from '../../components/Preloader';
import { userSelector, verifyingJWTSelector } from './selectors';

const AuthorizationApp = (Component, allowedRoles, properties) => {
  /**
   * Analog of ProtectedRoute but used for iOS app
   * @memberof module:PermissionChecker
   */
  const ProtectedRoute = (props) => {
    const {
      user,
      verifyingJWT,
      signInGoBackIfSucc: needGoBackAfterLogin,
      tReady,
      match
    } = props;

    const { jwt } = match.params;

    strapi.setToken(jwt);

    useEffect(() => {
      const {
        signInCheckJWT: signIn,
        signInInvalidJWT: signInInv
      } = props;

      if (jwt) {
        if (verifyingJWT) {
          signIn();
        }
      } else {
        signInInv();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT || !tReady) {
      return (
        <div className="mainPreLoaderParent">
          <div className="mainPreLoader">
            <PuffLoader
              size={70}
              color="#f7bd27"
              speedMultiplier={3}
            />
          </div>
        </div>
      );
    }

    if (user) {
      if (!allowedRoles || allowedRoles.includes(user.role.type)) {
        return (
          <Suspense fallback={<Preloader />}>
            <Component
              {...props}
              {...properties}
              myRoleType={user.role.type}
              myUserID={user._id}
              runByApp
            />
          </Suspense>
        );
      }
      return <Redirect to="/" />;
    }
    needGoBackAfterLogin(true);
    return <Redirect push to="/login" />;
  };

  ProtectedRoute.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
    signInCheckJWT: PropTypes.func.isRequired,
    signInValidJWT: PropTypes.func.isRequired,
    signInInvalidJWT: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    verifyingJWT: PropTypes.bool,
    signInGoBackIfSucc: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object),
    tReady: PropTypes.bool.isRequired
  };

  ProtectedRoute.defaultProps = {
    user: undefined,
    verifyingJWT: false
  };

  const mapStateToProps = createStructuredSelector({
    user: userSelector,
    verifyingJWT: verifyingJWTSelector
  });

  return translate()(
    connect(
      mapStateToProps,
      {
        signInCheckJWT,
        signInValidJWT,
        signInInvalidJWT,
        logOut,
        push,
        signInGoBackIfSucc
      }
    )(ProtectedRoute)
  );
};

export default AuthorizationApp;
