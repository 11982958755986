import * as actionTypes from './constants';

export function settingPrGetUser(userID, myself, roleLink, successfulCB) { // epic
  return {
    type: actionTypes.SETTING_PROF_GET_USER,
    payload: {
      userID,
      myself,
      roleLink,
      successfulCB
    }
  };
}

export function settingPrClearValues() { // reducer
  return {
    type: actionTypes.SETTING_PROF_CLEAR_VALUES
  };
}

export function settingPrGetUserSucc(initialData, myself) { // reducer
  return {
    type: actionTypes.SETTING_PROF_GET_USER_SUCC,
    payload: {
      initialData,
      myself,
      user_received: true
    }
  };
}

export function settingPrChange(myself, roleLink, userID, fields) { // epic
  return {
    type: actionTypes.SETTING_PROF_CHANGE,
    payload: {
      myself,
      roleLink,
      userID,
      fields
    }
  };
}

export function settingPrHasChanged() { // epic
  return {
    type: actionTypes.SETTING_PROF_HAS_CHANGED
  };
}

export function settingPrChangeModal(myself, roleLink, userID, modalID, fields, successfulCB) { // epic
  return {
    type: actionTypes.SETTING_PROF_CHANGE,
    payload: {
      myself,
      roleLink,
      userID,
      fields,
      modalID,
      successfulCB
    }
  };
}

export function resendToEmail(email, language) { // send request and show message
  return {
    type: actionTypes.EMAIL_RESEND,
    payload: {
      email,
      language
    }
  };
}

export function getOEMs() {
  return {
    type: actionTypes.GET_OEMS_FOR_SETTING
  };
}

export function setOEMs(oems) {
  return {
    type: actionTypes.SET_OEMS_FOR_SETTING,
    payload: {
      oems
    }
  };
}

export function getOEMsByID(url, params) {
  return {
    type: actionTypes.GET_OEMS_BY_ID_FOR_SETTING,
    payload: {
      url,
      params
    }
  };
}

export function userIsLoading() {
  return {
    type: actionTypes.SETTING_PROF_USER_IS_LOADING
  };
}
