import React from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { useSelector } from 'react-redux';

import { searchSelector } from './selector';

import './index.css';

/**
 * Renders Search Field for tables
 * @param  {function} onChange
 * @param  {string} searchByFields
 * @param  {string} placeholder - placeholder of search input
 * @param  {string|boolean} singleSearch
 */
const SearchField = (props) => {
  const {
    onChange,
    searchByFields,
    placeholder,
    singleSearch
  } = props;

  const searchValue = useSelector(searchSelector);
  const debounceInputChange = (event) => {
    const newValue = event.target.value.replace(/\s+/g, ' ');

    const valueToFilters = singleSearch
      ? {
        [singleSearch]: newValue
      }
      : {
        fields: newValue !== '' ? searchByFields : '',
        search: newValue
      };
    onChange(valueToFilters);
  };

  return (
    <>
      <div className="form-group m-form__group row align-items-center">
        <div className="m-input-icon m-input-icon--left col-md-12">
          <DebounceInput
            className="form-control m-input m-input--solid search"
            placeholder={placeholder}
            id="generalSearch"
            minLength={3}
            value={searchValue || ''}
            debounceTimeout={300}
            onChange={debounceInputChange}
          />
          <span className="m-input-icon__icon m-input-icon__icon--left search">
            <span><i className="la la-search" /></span>
          </span>
        </div>
      </div>
    </>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  searchByFields: PropTypes.string.isRequired,
  singleSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

SearchField.defaultProps = {
  singleSearch: false
};

export default SearchField;
