import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ConfigProvider from 'antd/lib/config-provider';
import DatePicker from 'antd/lib/date-picker';

import locales from './locales';
import i18n from '../../i18n';

import './styles.scss';

/**
 * @typedef {moment.Moment} Moment
 */

/**
 * Renders calendar picker
 * @memberof module:UsersTable
 */
const CustomDatePicker = (props) => {
  const {
    handleChange,
    value,
    maxDetail,
    disabledDate,
    className,
    validateDate
  } = props;

  const [timestamp, setTimestamp] = useState(moment(value).isValid() ? moment(value) : moment());

  useEffect(() => {
    const nextValue = moment(value);
    if (nextValue.isValid()) {
      if (typeof validateDate === 'function') {
        if (validateDate(nextValue)) {
          setTimestamp(nextValue);
        }
      } else {
        setTimestamp(nextValue.valueOf());
      }
    }
  }, [value, validateDate]);

  const customDatepickerContainerRef = useRef();

  /**
   * Defines if date is valid or not
   * @param {Moment} current - moment date object
   * @returns {boolean}
   */
  const isValidDate = (current) => current.isAfter(moment());

  /**
   * Returns html element - container of the floating layer of the datepicker,
   * while the default is to create a div element in body.
   * @returns {Object} - Node
   */
  const getCustomDatepickerContainerNode = () => customDatepickerContainerRef?.current;

  return (
    <div
      className={`custom-datepicker-container ${className}`}
      ref={customDatepickerContainerRef}
    >
      <ConfigProvider locale={locales?.[i18n.language] || locales.english}>
        <DatePicker
          value={moment(timestamp)}
          onChange={handleChange}
          open
          getPopupContainer={getCustomDatepickerContainerNode}
          popupStyle={{
            position: 'relative',
            opacity: 1,
            pointerEvents: 'all'
          }}
          style={{
            /**
             * Hide input field
             */
            display: 'none'
          }}
          className="custom-antd-datepicker"
          disabledDate={disabledDate || isValidDate}
          picker={maxDetail}
          showToday={false}
          prevIcon={<>&#8249;</>} // ‹
          superPrevIcon={<>&#171;</>} // «
          nextIcon={<>&#8250;</>} // ›
          superNextIcon={<>&#187;</>} // »
        />
      </ConfigProvider>
    </div>
  );
};

CustomDatePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.number
  ]),
  maxDetail: PropTypes.string,
  disabledDate: PropTypes.func,
  className: PropTypes.string,
  validateDate: PropTypes.func
};

CustomDatePicker.defaultProps = {
  value: moment(),
  maxDetail: 'date',
  disabledDate: undefined,
  className: '',
  validateDate: undefined
};

export default CustomDatePicker;
