import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
/**
 * Renders file selector for redux form
 * @memberof module:ReduxFormFields
 */
class FileSelector extends Component {
  _handleImageChange = (e, input) => {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      input.onChange(file);
      this.FileName.innerHTML = file.name;
    };
    reader.readAsDataURL(file);
  };

  emulateClick(e) {
    this.FileSelector.click(e);
  }

  render() {
    const
      {
        input, className, buttonLabel
      } = this.props;

    return (
      <Fragment>
        <input
          className="FileSelector"
          type="file"
          onChange={(e) => this._handleImageChange(e, input)}
          ref={(r) => { this.FileSelector = r; }}
          hidden
        />
        <button
          className={className}
          onClick={this.emulateClick.bind(this)}
          type="button"
        >
          {buttonLabel}
        </button>
        <div ref={(r) => { this.FileName = r; }} className="uploadFileName" />
      </Fragment>
    );
  }
}

FileSelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired
};

export default FileSelector;
