import * as actionTypes from './constants';

export function openModalWindow(modalID, data) {
  return {
    type: actionTypes.MODAL_WINDOW_OPEN,
    payload: {
      modalID,
      data
    }
  };
}

export function closeModalWindow(modalID) {
  return {
    type: actionTypes.MODAL_WINDOW_CLOSE,
    payload: {
      modalID
    }
  };
}

export function modalWindowSend(modalID, requestConfig, cb, reloadPage) {
  return {
    type: actionTypes.MODAL_WINDOW_SEND,
    payload: {
      modalID,
      requestConfig,
      cb,
      reloadPage
    }
  };
}

export function modalStopLoading(modalID) {
  return {
    type: actionTypes.MODAL_STOP_LOADING,
    payload: {
      modalID
    }
  };
}

export function modalStartLoading(modalID) {
  return {
    type: actionTypes.MODAL_START_LOADING,
    payload: {
      modalID
    }
  };
}
