import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for load guard - input device name.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {object} props.original.inputDevice - Input device
 * @param {string} props.original.inputDevice.device_group - Input device name
 */
const renderInputDeviceName = ({ original }) => {
  const name = original?.inputDevice?.device_group;
  return <>{i18n.t(name)}</>;
};

renderInputDeviceName.propTypes = {
  device_group: PropTypes.string
};

renderInputDeviceName.defaultProps = {
  device_group: ''
};

export default renderInputDeviceName;
