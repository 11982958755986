import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  required,
  minLength,
  maxLength
} from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import { changeSMIDModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

/**
 * ChangeSMIdWindow form id
 */
const changeSMID = 'changeSMID';

/**
 * Modal window for changin email from edit profile page.
 * @memberof module:SettingProfile
 */
const ChangeSMIdWindow = (props) => {
  const { handleOnClose, handleSubmit, modalID, pristine, openScanQR, modal, reset } = props;

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeSMID')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={changeSMID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field" style={{ position: 'relative' }}>
            <Field
              name="sm_id"
              component={CustomTextInput}
              label={i18n.t('reqSMID')}
              className="m-input"
              validate={[required, minLen3, maxLen50]}
            />
            <a
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right search-ip-btn"
              onClick={openScanQR}
            >
              {i18n.t('QRLink')}
            </a>
          </div>
          <div className="row justify-content-between data_SMID_container">{i18n.t('existingDataOver')}</div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <button
          form={changeSMID}
          disabled={pristine}
          type="submit"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
        >
          {i18n.t('changeBtn')}
        </button>
      </div>
    </ModalWindow>
  );
};

ChangeSMIdWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  openScanQR: PropTypes.func.isRequired
};

ChangeSMIdWindow.defaultProps = {
  modal: {
    data: {}
  }
};

const form = reduxForm({
  form: 'changeSMIdForm'
})(ChangeSMIdWindow);

const mapStateToProps = createStructuredSelector({
  modal: changeSMIDModalSelector
});

export default connect(mapStateToProps)(form);
