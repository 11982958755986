import * as actionTypes from './constants';

const monitoringFilter = JSON.parse(localStorage.getItem('monitoringFilter'));
const checked = monitoringFilter ? monitoringFilter?.checked : false;
const supportContractChecked = monitoringFilter ? monitoringFilter?.supportContractChecked : false;
const isInstallationCompleted = monitoringFilter ? monitoringFilter?.isInstallationCompleted : false;
const isShowPlantColumn = JSON.parse(localStorage.getItem('isShowPlantColumn')) || false;
const isShowLastLoginDateColumn = JSON.parse(localStorage.getItem('isShowLastLoginDateColumn')) || false;
const isShowInstallationDateColumn = JSON.parse(localStorage.getItem('isShowInstallationDateColumn')) || false;

const initialState = {
  selectedRows: [],
  checked,
  supportContractChecked,
  isInstallationCompleted,
  status: [],
  data: [],
  selectedFields: {},
  isShowPlantColumn,
  isShowLastLoginDateColumn,
  isShowInstallationDateColumn
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_SEND_REQUEST:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          company: action.payload.company,
          listURL: action.payload.dataList.listURL,
          loading: true,
          params: action.payload.dataList.params,
          transformResponse: action.payload.dataList.transformResponse,
          errorMessage: undefined
        }
      };
    case actionTypes.SAVE_ALL_DATA_LIST_USERS: {
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.dataListUsers
        ]
      };
    }
    case actionTypes.EMPLOYEE_RECEIVED: {
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.companyID]: {
            ...state[action.payload.listID],
            listURL: action.payload.listURL,
            data: action.payload.data,
            total: action.payload.total,
            errorMessage: undefined,
            loading: false
          }
        }
      };
    }
    case actionTypes.ADD_CHOOSED_COMPANY:
      return {
        ...state,
        choosedCompany: {
          ...state[action.payload.choosedCompany],
          value: action.payload.choosedCompany
        },
        choosedUser: {
          ...state[action.payload.choosedUser],
          value: action.payload.choosedUser
        },
        choosedStatus: action.payload.choosedStatus ? [
          ...action.payload.choosedStatus
        ] : []
      };
    case actionTypes.MONITORING_REPLACE_SELECTED:
      return {
        ...state,
        selectedRows: action.payload.newSelected
      };
    case actionTypes.CHANGE_ARCHIEVE_ERROR_SWITCH:
      return {
        ...state,
        checked: action.payload.checked
      };
    case actionTypes.CHANGE_SUPPORT_CONTRACT_SWITCH:
      return {
        ...state,
        supportContractChecked: action.payload.supportContractChecked
      };
    case actionTypes.CHANGE_INSTALLATION_COMPLETED_SWITCH:
      return {
        ...state,
        isInstallationCompleted: action.payload.isInstallationCompleted
      };
    case actionTypes.ADD_PARSED_DATA:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.arr
      };
    case actionTypes.MONITORING_ADD_SELECTED: {
      const { selectedFields } = state;
      return {
        ...state,
        selectedFields: {
          ...selectedFields,
          [action.payload.fieldName]: {
            ...state[action.payload.fieldName],
            value: action.payload.selections
          }
        }
      };
    }
    case actionTypes.MONITORING_CLEAR_SELECTED: {
      return {
        ...state,
        selectedFields: {
          ...state.selectedFields,
          assignField: null,
          priorityField: null,
          workStatusField: null
        }
      };
    }
    case actionTypes.MONITORING_CLEAR_FILTERS: {
      return {
        ...state,
        checked: false,
        supportContractChecked: false,
        isInstallationCompleted: false,
        selectedFields: {},
        isShowPlantColumn: false,
        isShowLastLoginDateColumn: false,
        isShowInstallationDateColumn: false
      };
    }
    case actionTypes.MONITORING_INSTALLERS_RECEIVED:
      return {
        ...state,
        installers: action.payload.installers
      };
    case actionTypes.ERROR_INFO_RECEIVED: {
      const { errors } = state;
      return {
        ...state,
        errors: {
          ...errors,
          [action.payload.taskId]: action.payload.errorsList
        }
      };
    }
    case actionTypes.SYNC_FILTERS_FROM_LOCAL_STORAGE:
      return {
        ...state,
        selectedFields: {
          ...state.selectedFields,
          ...action.payload.fields
        }
      };
    case actionTypes.SET_IS_SHOW_PLANT_COLUMN:
      return {
        ...state,
        isShowPlantColumn: action.payload.data
      };
    case actionTypes.SET_IS_SHOW_LAST_LOGIN_DATE_COLUMN:
      return {
        ...state,
        isShowLastLoginDateColumn: action.payload.data
      };
    case actionTypes.SET_IS_SHOW_INSTALLATION_DATE_COLUMN:
      return {
        ...state,
        isShowInstallationDateColumn: action.payload.data
      };

    case actionTypes.UPDATE_COLUMN_PROPS_FOR_MONITORING: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
