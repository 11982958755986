import * as actionTypes from '../constants';

const initialState = {
  chart: {
    showDevicesCurves: false
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CHART_INIT_USER:
      return {
        ...state,
        user: {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          chart_settings: action.payload.chart_settings,
          active_devices: action.payload.active_devices,
          demo: !!action.payload?.demo,
          timezone: action.payload.timezone
        },
        chart: {
          myself: action.payload.myself,
          gatewayID: action.payload.gatewayID,
          groupPixelWidth: action.payload.groupPixelWidth,
          showDevicesCurves: state.chart.showDevicesCurves
        }
      };
    case actionTypes.CLEAR_DASHBOARD_DATA:
      return initialState;
    case actionTypes.DASHBOARD_RELOAD_CHART_SETTINGS:
      return {
        ...state,
        chart: {
          ...state.chart,
          chart_settings: action.payload.chart_settings,
          active_devices: action.payload.active_devices
        }
      };
    case actionTypes.CHART_WILL_CHANGE_RANGE:
    case actionTypes.CHART_WILL_CHANGE_SCALE:
      return {
        ...state,
        chart: {
          ...state.chart,
          loading: true
        }
      };
    case actionTypes.CHART_DATA_RECEIVED:
      return {
        ...state,
        chart: {
          ...state.chart,
          dataChart: action.payload.chartData
        }
      };
    case actionTypes.CHART_CHANGED_IN_REALTIME:
      return {
        ...state,
        chart: {
          ...state.chart,
          from: action.payload.from,
          to: action.payload.to,
          scaleMS: action.payload.scaleMS,
          data: action.payload.data,
          dataSum: action.payload.dataSum,
          active_devices: action.payload.active_devices
        },
        chartBuffer: action.payload.buffer
      };
    case actionTypes.CHART_SCALE_CHANGED:
      return {
        ...state,
        chart: {
          groupPixelWidth: state.chart.groupPixelWidth,
          myself: state.chart.myself,
          gatewayID: state.chart.gatewayID,
          from: action.payload.from,
          to: action.payload.to,
          data: action.payload.data,
          dataSum: action.payload.dataSum,
          scaleMS: action.payload.scaleMS,
          scaleType: action.payload.scaleType,
          limitsReceived: action.payload.limitsReceived,
          realTimeData: action.payload.realTimeData,
          expectedScaleMS: action.payload.expectedScaleMS,
          loading: false,
          dataInterval: action.payload.dataInterval,
          dataSingleInterval: action.payload.dataSingleInterval,
          chartType: action.payload.chartType,
          active_devices: action.payload.active_devices,
          showDevicesCurves: state.chart.showDevicesCurves
        },
        chartBuffer: action.payload.buffer
      };
    case actionTypes.CHART_LOADED:
      return {
        ...state,
        plotWidth: action.payload.plotWidth
      };
    case actionTypes.CHART_DEFINE_BUFFER:
      return {
        ...state,
        chartBuffer: action.payload.newBuffer
      };
    case actionTypes.TOGGLE_SHOW_DEVICES_CURVES_SWITCH:
      return {
        ...state,
        chart: {
          ...state.chart,
          showDevicesCurves: !state.chart.showDevicesCurves
        }
      };
    default:
      return state;
  }
};
