import { from, of, startWith, endWith } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../../api_helper';
import { actionTypes, DELETE_INVITED_USER_MODAL_ID, INVITE_MODAL_ID } from '../constants';
import strapi from '../../../strapi';
import i18n from '../../../i18n';

import { addNotification } from '../../NotificationGenerator/actions';
import { closeModalWindow } from '../../ModalWindow/actions';
import {
  setIsInvitedUsersLoading,
  setInvitedUsers,
  setIsInviteUserLoading,
  setIsDeletingInviteUserLoading
} from './actions';


function fetchInvitedUsersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_INVITED_USERS_REQUEST),
    map((action) => action.payload),
    switchMap(({ userId }) => from(strapi.request('get', `/users/${userId}/viewers`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(addNotification({ type: 'error', text: result.error.message || result.error }));
        }

        return of(setInvitedUsers(result));
      }),
      startWith(setIsInvitedUsersLoading(true)),
      endWith(setIsInvitedUsersLoading(false))
    ))
  );
}

function fetchInviteUserEpic($action, $state) {
  return $action.pipe(
    ofType(actionTypes.INVITE_USER_REQUEST),
    map((action) => action.payload),
    switchMap(({ userId, email }) => from(strapi.request('post', `/users/${userId}/share-plant`, { data: { email } })).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(
            addNotification({ type: 'error', text: result.message || result.error }),
            closeModalWindow(INVITE_MODAL_ID)
          );
        }

        const { invitedUsers } = $state.value.invitedUsers;

        return of(
          setInvitedUsers([...invitedUsers, result]),
          addNotification({ type: 'success', text: i18n.t('success') }),
          closeModalWindow(INVITE_MODAL_ID)
        );
      }),
      startWith(setIsInviteUserLoading(true)),
      endWith(setIsInviteUserLoading(false))
    )
    )
  );
}

function fetchDeleteInvitedUserEpic($action, $state) {
  return $action.pipe(
    ofType(actionTypes.DELETE_INVITED_USER_REQUEST),
    map((action) => action.payload),
    switchMap(({ userId, viewerId }) => from(strapi.request('delete', `/users/${userId}/viewers?viewerId=${viewerId}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(
            addNotification({ type: 'error', text: result.message || result.error }),
            closeModalWindow(DELETE_INVITED_USER_MODAL_ID)
          );
        }
        const { invitedUsers } = $state.value.invitedUsers;
        const filteredInvitedUsers = invitedUsers.filter(({ _id }) => _id !== viewerId);

        return of(
          setInvitedUsers(filteredInvitedUsers),
          addNotification({ type: 'success', text: i18n.t('success') }),
          closeModalWindow(DELETE_INVITED_USER_MODAL_ID)
        );
      }),
      startWith(setIsDeletingInviteUserLoading(true)),
      endWith(setIsDeletingInviteUserLoading(false))
    )
    )
  );
}

export default combineEpics(fetchInvitedUsersEpic, fetchInviteUserEpic, fetchDeleteInvitedUserEpic);
