import * as actionTypes from './constants';

const initialState = {

};
/**
 * [ listID,  ] : {
  listURL: string,
  total: number,
  loading: bool,
  data: array,
  errorMessage: string,
  transformResponse: function,
  params: Object
}
 * */
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.DATA_LIST_SEND_REQUEST: {
      const {
        listID,
        dataList: {
          params,
          listURL,
          transformResponse
        }
      } = action?.payload;

      return {
        ...state,
        [listID]: {
          ...state[listID],
          listURL,
          loading: true,
          params: {
            ...params,
            isInstallationCompleted: (listID === 'sm_users' && params.isInstallationCompleted !== undefined) ? params.isInstallationCompleted : undefined,
            supportContractFlag: (listID === 'sm_users' && params.supportContractFlag !== undefined) ? params.supportContractFlag : undefined
          },
          transformResponse,
          errorMessage: undefined
        }
      };
    }
    case actionTypes.DATA_LIST_RECEIVED:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          listURL: action.payload.listURL,
          data: action.payload.data,
          total: action.payload.total,
          errorMessage: undefined,
          loading: false
        }
      };
    case actionTypes.ATTACH_SUB_SENSORS:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          listURL: action.payload.parentSensorUrl,
          errorMessage: undefined,
          loading: false,
          subSensorsData: {
            ...state[action.payload.listID].subSensorsData,
            listURL: action.payload.listURL,
            data: action.payload.data.data,
            total: action.payload.data.total
          }
        }
      };
    case actionTypes.DATA_LIST_GOT_ERROR:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          data: [],
          total: 0,
          listURL: action.payload.listURL,
          errorMessage: action.payload.errorMessage,
          loading: false
        }
      };
    case actionTypes.CHANGE_SUPPORT_CONTRACT_USERS:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          params: {
            ...state[action.payload.listID].params,
            supportContractFlag: action.payload.supportContractFlag
          }
        }
      };
    case actionTypes.CHANGE_INSTALLATION_COMPLETED_USERS:
      return {
        ...state,
        [action.payload.listID]: {
          ...state[action.payload.listID],
          params: {
            ...state[action.payload.listID].params,
            isInstallationCompleted: action.payload.isInstallationCompleted
          }
        }
      };
    case actionTypes.DATA_LIST_CLEAN:
      return {
        ...state,
        [action.payload.listID]: undefined
      };
    case actionTypes.DATA_LIST_UPDATE_ROW: {
      const { listID, rowID, updates } = action.payload;
      const dataList = state[listID];
      dataList.data[rowID] = { ...dataList.data[rowID], ...updates };
      return { ...state };
    }
    default:
      return state;
  }
};
