/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import i18n from '../../i18n';
import './index.scss';

export const LongCheckboxArr = ({ handleChange, noDataText, checkedData, formId, disabled }) => {
  if (!checkedData.length) {
    return (
      <div className="no-installer">
        {i18n.t(noDataText)}
      </div>
    );
  }

  const rowRenderer = ({ index, style }) => (
    <div
      style={style}
      key={index}
      onClick={() => handleChange(checkedData[index]._id)}
      className="form-group m-form__group checkbox-row"
    >
      <label
        htmlFor={formId}
        className="m-checkbox m-checkbox--air m-checkbox--state-warning checkbox-label-color"
      >
        <input
          onChange={() => null}
          className="m-input"
          type="checkbox"
          disabled={disabled}
          checked={(checkedData[index].isChecked || false)}
        />
        {`${checkedData[index].name}`}
        <span />
      </label>
    </div>
  );


  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          height={300}
          rowCount={checkedData.length}
          rowHeight={50}
          width={width}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

LongCheckboxArr.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checkedData: PropTypes.instanceOf(Array),
  noDataText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

LongCheckboxArr.defaultProps = {
  checkedData: [],
  disabled: false
};
