import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, startWith, endWith } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import * as actionTypes from './constants';
import { addNotification } from '../NotificationGenerator/actions';
import {
  setNotAttachedEndUsers,
  setOEMsList,
  setPVInstallersList,
  setAllNotAttachedInstallers,
  setAllAttachedInstallers,
  setAllNotAttachedOems,
  setLoadingSupport
} from './actions';
import { modalStopLoading } from '../ModalWindow/actions';
import i18n from '../../i18n';

function getNotAttachedEndUsersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_NOT_ATTACHED_END_USERS),
    map((action) => action.payload),
    switchMap(({ params, modalID }) => from(strapi.request('get', '/../users/get-not-supported-end-users', { params })).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const stopLoadingAction = modalID ? [modalStopLoading(modalID)] : [];
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return of(setNotAttachedEndUsers(result.list.map(({ _id, first_name, last_name, isChecked = false }) => ({ _id, name: `${first_name} ${last_name}`, isChecked }))));
          }
          return of(
            ...stopLoadingAction,
            addNotification({
              type: 'error',
              text: i18n.t('error')
            })
          );
        }
        return of(
          ...stopLoadingAction,
          addNotification({
            type: 'error',
            text: result?.message
          })
        );
      }),
      startWith(setLoadingSupport({ isNotAttachedUsersLoading: true })),
      endWith(setLoadingSupport({ isNotAttachedUsersLoading: false }))
    )
    )
  );
}

function getOEMsListEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_OEMS_LIST),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../oem', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result?.list)) {
            return setOEMsList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      })
    )
    )
  );
}

function getPVInstallersListEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_PV_INSTALLERS_LIST),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/pv-installer-users', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setPVInstallersList(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      })
    )
    )
  );
}

function getAllNotAttachedPVInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_NOT_ATTACHED_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ params }) => from(
      strapi.request('get', '/../users/get-not-supported-pv-installer-users', { params })
    ).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setAllNotAttachedInstallers(result.list.map(({ _id, my_company: { name }, isChecked = false }) => ({ _id, name, isChecked })));
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoadingSupport({ isPvInstallersListForSupportLoading: true })),
      endWith(setLoadingSupport({ isPvInstallersListForSupportLoading: false }))
    )
    )
  );
}

function getAllAttachedPVInstallersEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_ATTACHED_INSTALLERS),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/get-supported-pv-installer-users', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setAllAttachedInstallers(result.list);
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      })
    )
    )
  );
}

function getAllNotAttachedOEMsEpic($action) {
  return $action.pipe(
    ofType(actionTypes.GET_ALL_NOT_ATTACHED_OEMS),
    map((action) => action.payload),
    switchMap(({ params }) => from(strapi.request('get', '/../users/get-not-supported-oem-users', { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result?.error) {
          if (Array.isArray(result.list)) {
            return setAllNotAttachedOems(result.list.map(({ _id, oem: { name }, isChecked = false }) => ({ _id, name, isChecked })));
          }
          return addNotification({
            type: 'error',
            text: i18n.t('error')
          });
        }
        return addNotification({
          type: 'error',
          text: result?.message
        });
      }),
      startWith(setLoadingSupport({ isOemsListForSupportLoading: true })),
      endWith(setLoadingSupport({ isOemsListForSupportLoading: false }))
    )
    )
  );
}

export default combineEpics(
  getAllAttachedPVInstallersEpic,
  getNotAttachedEndUsersEpic,
  getAllNotAttachedOEMsEpic,
  getAllNotAttachedPVInstallersEpic,
  getOEMsListEpic,
  getPVInstallersListEpic
);
