import React from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Renders multiple select for redux form
 * @memberof module:ReduxFormFields
 * @param  {Object} props - React component properties
 * @param  {Object[]} props.options - list of options for multiple select
 * @param  {string} props.label - label text
 * @param  {string} props.placeholder - placeholder for select
 * @param  {Object} props.input
 * @param  {string} props.input.name - multiple select component name
 * @param  {function} props.input.onChange - on change handler
 * @param  {Object[]|string} props.input.value - current value
 * @param  {Object} props.meta
 * @param  {boolean} props.meta.invalid
 * @param  {boolean} props.meta.touched
 * @param  {boolean} props.meta.error
 */
const MultipleSelect = (props) => {
  const empty = [];
  const {
    options,
    label,
    placeholder,
    input: {
      name,
      onChange,
      value: currentValue = empty
    },
    meta: {
      invalid,
      touched,
      error
    },
    required
  } = props;

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <div className="multiple-select-container">
      <FormControl classes={{
        root: 'form-control-multiple-select'
      }}
      >
        {
          label && (
          <div id="multiple-checkbox-label" className="input-label-multiple-select">
            {required ? `${i18n.t(label)} *` : i18n.t(label)}
          </div>
          )
        }
        <Select
          name={name}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={currentValue || empty}
          onChange={handleChange}
          input={<Input />}
          displayEmpty
          classes={{
            icon: 'multiple-select-icon'
          }}
          renderValue={(selected) => {
            if (!selected?.length) {
              return <span className="affected-device">{i18n.t(placeholder)}</span>;
            }
            return (
              <>
                {selected.map((_id) => {
                  const labelValue = options.find(({ value }) => value === _id)?.label || '';
                  return (
                    <React.Fragment key={_id}>
                      <span className="affected-device">{i18n.t(labelValue)}</span>
                      <br />
                    </React.Fragment>
                  );
                })}
              </>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            getContentAnchorEl: null,
            classes: {
              paper: 'multiple-select-menu'
            }
          }}
        >
          {options.map(({ value }) => {
            const formattedLabel = options.find((item) => item.value === value)?.label || '';
            return (
              <MenuItem
                key={value}
                value={value}
                className="multiple-select-menu-item"
              >
                <div className="labeled-checkbox-container">
                  <Checkbox
                    checked={(currentValue || empty)?.indexOf(value) > -1}
                    className="multiple-select-checkbox"
                  />
                  <ListItemText
                    primary={i18n.t(formattedLabel)}
                    classes={{
                      root: 'multiple-select-label',
                      primary: 'multiple-select-label-primary'
                    }}
                  />
                </div>
              </MenuItem>
            );
          })}
        </Select>
        {
          invalid && touched && error
            ? (
              <FormHelperText
                error={!!error}
                classes={{
                  root: 'multiple-select-helper-text',
                  error: 'validation-error'
                }}
              >
                {i18n.t(error)}
              </FormHelperText>
            )
            : null
          }
      </FormControl>
    </div>
  );
};

MultipleSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  required: PropTypes.bool
};

MultipleSelect.defaultProps = {
  value: [],
  options: [],
  label: '',
  placeholder: '',
  onChange: () => {},
  required: false
};

export default MultipleSelect;
