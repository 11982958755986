import moment from 'moment-timezone';

/**
 * Returns range parameters by scaleType
 * @param scaleType
 * @param chart
 * @memberof module:Dashboard
 * @returns {{limitsReceived: *, expectedScaleMS: *, from: *, to: *}}
 */
export default function getRangeByScaleType(scaleType, chart) {
  let from;
  let to;
  let expectedScaleMS;
  let limitsReceived;
  const { chartType } = chart;

  const now = moment().valueOf();

  switch (scaleType) {
    case 'y': {
      from = moment(now).startOf('year');
      to = moment(now).endOf('year');
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 'm': {
      from = moment(now).startOf('month');
      to = moment(now).endOf('month');
      expectedScaleMS = 2592000000;
      break;
    }
    case 'w': {
      from = moment(now).subtract(7, 'days');
      to = moment(now);
      expectedScaleMS = 604800000;
      break;
    }
    case 'd': {
      from = moment(now).subtract(1, 'day');
      to = moment(now);
      expectedScaleMS = 86400000;
      break;
    }
    case 'h': {
      from = moment(now).subtract(1, 'hour');
      to = moment(now);
      expectedScaleMS = 3600000;
      break;
    }
    case 't': {
      from = moment(now).startOf('day');
      to = moment(now);
      expectedScaleMS = 86400000;
      break;
    }
    default: {
      from = chart.from ? moment(chart.from) : moment().startOf('day');
      to = chart.to ? moment(chart.to) : moment();
      const oldScaleMS = chart.scaleMS ? chart.scaleMS : to.diff(from);

      if (scaleType === 'back') {
        if (chartType === 'monthly') {
          from = from.subtract(1, 'year').startOf('year');
          to = from.clone().endOf('year');
        } else if (chartType === 'daily') {
          from = from.subtract(1, 'month').startOf('month');
          to = from.clone().endOf('month');
        } else {
          from.subtract(Math.round(oldScaleMS / 2), 'ms');
          to = moment(from).add(oldScaleMS, 'ms');
        }
        expectedScaleMS = oldScaleMS;
      } else if (scaleType === 'forward') {
        if (chartType === 'monthly') {
          from = from.add(1, 'year').startOf('year');
          to = from.clone().endOf('year');
        } else if (chartType === 'daily') {
          from = from.add(1, 'month').startOf('month');
          to = from.clone().endOf('month');
        } else {
          to.add(Math.round(oldScaleMS / 2), 'ms');
          if (to.isAfter(moment(now))) {
            to = moment(now);
          }
          from = moment(to).subtract(oldScaleMS, 'ms');
        }
        expectedScaleMS = oldScaleMS;
      } else if (typeof scaleType === 'number') {
        let newScale = oldScaleMS * scaleType;
        if (newScale < 900000) {
          // 15 хвилин
          newScale = 900000;
          limitsReceived = { minZoom: true };
        } else if (newScale > 31622400000) {
          // 366 днів
          newScale = 31622400000;
          limitsReceived = { maxZoom: true };
        }
        const offset = (oldScaleMS - newScale) / 2;
        from.add(offset, 'ms');
        to.subtract(offset, 'ms');
        if (to.isAfter(moment(now))) {
          to = moment(now);
          from = moment(now).subtract(newScale, 'ms');
        }
        expectedScaleMS = newScale;
      }
    }
  }

  return {
    from, to, expectedScaleMS, limitsReceived
  };
}
