import { createSelector } from 'reselect';
import { dataListID } from '../../containers/Users/constants';

const getAllDataLists = (state) => state?.dataList;

export const usersDataListSelector = createSelector(
  [getAllDataLists],
  (allDataLists) => allDataLists?.[dataListID] || {}
);

export const usersDataListParamsSelector = createSelector(
  [usersDataListSelector],
  (usersDataList) => usersDataList?.params || {}
);

export const searchSelector = createSelector(
  [usersDataListParamsSelector],
  (usersDataListParams) => usersDataListParams?.search
);
