import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, finalize } from 'rxjs/operators';

import * as actionTypes from '../constants';
import strapi from '../../../strapi';
import { handleErrorDetailed } from '../../../api_helper';
import { dashboardSetInitialCurrentValues } from '../actions';
import { addNotification } from '../../NotificationGenerator/actions';
import { CURRENT_VALUES_TIME_RANGE } from '../constants';
import analytics from '../../../analytics';
import getEventObj, { DATA_REAL_TIME } from '../../../analytics/events';

/**
 * Changes dashboard current values data:
 * CURRENT CONSUMPTION, CURRENT IMPORT | CURRENT EXPORT, CURRENT PRODUCTION, HOT WATER, BATTERY
 * @memberof module:DashboardEpics
 */
export default function getInitialCurrentValuesEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.DASHBOARD_GET_INITIAL_CURRENT_VALUES),
    map((action) => action.payload),
    switchMap(({ myself, gatewayId }) => {
      const url = myself
        ? '/data/real-time'
        : `/data/real-time/${gatewayId}`;

      return from(strapi.request('get', url, { params: { isWeb: true } })).pipe(
        catchError(handleErrorDetailed),
        finalize(() => {
          analytics.sendEvent(getEventObj(DATA_REAL_TIME, {}, { myself }));
        }),
        mergeMap((result) => {
          if (result.error) {
            return of(
              addNotification({
                type: 'error',
                text: result.message
              })
            );
          }

          const newPoint = result?.rawSensorData?.newPoint;
          const sensorsData = result?.rawSensorData?.sensorsData;

          const temperatures = Array.isArray(sensorsData)
            ? sensorsData
              .filter((sensorData) => !Number.isNaN(sensorData?.currentWaterTemp))
              .reduce((obj, sensorData) => {
                obj[sensorData?._id] = sensorData.currentWaterTemp;
                return obj;
              }, {})
            : [];

          // Set current values only if new point has a timestamp and it is valid
          if (newPoint?.timestamp && newPoint.timestamp >= Date.now() - CURRENT_VALUES_TIME_RANGE) {
            return of(
              dashboardSetInitialCurrentValues({ ...newPoint, temperatures })
            );
          }

          // Set empty values
          return of(dashboardSetInitialCurrentValues({}));
        })
      );
    })
  );
}
