import * as actionTypes from './constants';

export function basicAuthSignIn(data) { // epic
  return {
    type: actionTypes.BASIC_AUTH_SIGN_IN,
    payload: {
      data
    }
  };
}
