import {
  RECEIVE_FORM_DATA,
  CLEAR_SENSORS_OAUTH2_FORM_DATA,
  SENSORS_OAUTH2_ALLOW_TO_DISABLED_EDIT_MODAL
} from './constants';

const initialState = { formData: null, isDisabledOauth2EditModal: true };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RECEIVE_FORM_DATA:
      return {
        ...state,
        formData: action.payload.data
      };
    case SENSORS_OAUTH2_ALLOW_TO_DISABLED_EDIT_MODAL:
      return {
        ...state,
        isDisabledOauth2EditModal: action.payload.data
      };
    case CLEAR_SENSORS_OAUTH2_FORM_DATA:
      return {
        ...state,
        formData: null
      };
    default:
      return state;
  }
};
