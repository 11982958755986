import { createSelector } from 'reselect';
import {
  addEndUsersToSupportForm,
  addEndUsersToSupportModalID,
  addOEMToSupportForm,
  addOEMToSupportModalID,
  addPVInstallersToSupportForm,
  addPVInstallersToSupportModalID,
  dataListIDEndUsers,
  dataListIDOEMs,
  dataListIDPVInstallers,
  dataListIDSupport
} from './constants';

const getUser = (state) => state.signIn?.user;
const getSupportDataList = (state) => state.dataList?.[dataListIDSupport] || {};

const getAddPVInstallersModal = (state) => state.modals?.[addPVInstallersToSupportModalID];
const getAddEndUsersModal = (state) => state.modals[addEndUsersToSupportModalID];
const getAddOemModal = (state) => state.modals?.[addOEMToSupportModalID];

const getDeleteDataModal = (state) => state.modals.deleteData;
const getIsLoading = (state) => state.modals?.isLoading;

const getNotAttachedPVInstallers = (state) => state.supportDetails?.notAttachedPVInstallers;
const getNotAttachedOEMs = (state) => state.supportDetails?.notAttachedOEMs;
const getNotAttachedEndUsers = (state) => state.supportDetails?.notAttachedEndUsers;
const getPvInstallers = (state) => state.supportDetails?.pvInstallers;
const getOems = (state) => state.supportDetails?.oems;

const getAttachedPVInstallers = (state) => state.dataList?.[dataListIDPVInstallers]?.data;
const getAttachedOems = (state) => state.dataList?.[dataListIDOEMs]?.data;
const getAttachedEndUsers = (state) => state.dataList?.[dataListIDEndUsers]?.data;

const getRegisteredFieldsInstaller = (state) => state.form?.[addPVInstallersToSupportForm]?.registeredFields;
const getRegisteredFieldsEndUser = (state) => state.form?.[addEndUsersToSupportForm]?.registeredFields;
const getRegisteredFieldsOem = (state) => state.form?.[addOEMToSupportForm]?.registeredFields;

const getIsPvInstallersListForSupportLoading = (state) => state.supportDetails?.isPvInstallersListForSupportLoading;
const getIsOemsListForSupportLoading = (state) => state.supportDetails?.isOemsListForSupportLoading;
const getIsNotAttachedUsersLoading = (state) => state.supportDetails?.isNotAttachedUsersLoading;

export const supportDataListSelector = createSelector([getSupportDataList], (supportDataList) => supportDataList);
export const visibleWebSelector = createSelector([getUser], (user) => user?.oem?.visible_web);
export const compLogoSelector = createSelector([getUser], (user) => user?.company_logo);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
export const deleteDataModalSelector = createSelector([getDeleteDataModal], (deleteData) => deleteData);

export const notAttachedPVInstallersModalSelector = createSelector([getNotAttachedPVInstallers], (notAttachedPVInstallers) => notAttachedPVInstallers);
export const notAttachedEndUsersSelector = createSelector([getNotAttachedEndUsers], (notAttachedEndUsers) => notAttachedEndUsers);
export const notAttachedOEMsSelector = createSelector([getNotAttachedOEMs], (notAttachedOEMs) => notAttachedOEMs);

export const attachedPVInstallersSelector = createSelector([getAttachedPVInstallers], (data) => data);
export const attachedEndUsersSelector = createSelector([getAttachedEndUsers], (data) => data);
export const attachedOemsSelector = createSelector([getAttachedOems], (data) => data);

export const addPVInstallersModalSelector = createSelector([getAddPVInstallersModal], (addPVInstallersModal) => addPVInstallersModal);
export const addEndUsersModalSelector = createSelector([getAddEndUsersModal], (addEndUsersModal) => addEndUsersModal);
export const addOemModalSelector = createSelector([getAddOemModal], (addOemModal) => addOemModal);

export const oemsSelector = createSelector([getOems], (oems) => oems);
export const pvInstallersSelector = createSelector([getPvInstallers], (pvInstallers) => pvInstallers);

export const registeredFieldsInstallerSelector = createSelector([getRegisteredFieldsInstaller], (registeredFields) => registeredFields);
export const registeredFieldsEndUserSelector = createSelector([getRegisteredFieldsEndUser], (registeredFields) => registeredFields);
export const registeredFieldsOemSelector = createSelector([getRegisteredFieldsOem], (registeredFields) => registeredFields);

export const getIsLoadingSelectorForSupportEndUsers = createSelector(
  [
    getIsNotAttachedUsersLoading,
    getIsOemsListForSupportLoading,
    getIsPvInstallersListForSupportLoading
  ],
  (isNotAttachedUsersLoading, isOemsListForSupportLoading, isPvInstallersListForSupportLoading) => (
    {
      isNotAttachedUsersLoading,
      isOemsListForSupportLoading,
      isPvInstallersListForSupportLoading
    }
  )
);
