import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ResetPassForm from './ResetPassForm';
import { resetPassSend } from './actions';
import '../../App.css';
import i18n, { transformLanguageForAPI, changeLanguageTo } from '../../i18n';

/**
 * @module ResetPass
 */

/**
 * @memberof module:ResetPass
 * @description renders Reset Password form
 * @extends Component
 */

const ResetPass = (props) => {
  const { match } = props;
  const { verifyCode } = match?.params || {};

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lang') || i18n.language;
    changeLanguageTo(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Sends request to reset password
   * @param  {object} values - form values to reset password
   */

  const onSubmit = (values) => {
    const { resetPassSend: resetPassSendFromProps } = props;
    resetPassSendFromProps(transformLanguageForAPI(i18n.language), values);
  };

  return verifyCode ? (
    <ResetPassForm
      onSubmit={onSubmit}
      initialValues={{
        code: verifyCode
      }}
    />
  ) : null;
};

ResetPass.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  resetPassSend: PropTypes.func.isRequired
};

export default connect(null, {
  push,
  resetPassSend
})(ResetPass);
