import { from, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { saveAs } from 'file-saver';
import * as actionTypes from './constants';
import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';

function basicAuthSignInEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.BASIC_AUTH_SIGN_IN),
    map((action) => action.payload),
    switchMap(({
      data
    }) => (
      from(strapi.request('post', '/v1/auth/basic/login', {
        data
      })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error || typeof result === 'string') {
            const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, 'credentials.txt');
            return result;
          }
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        })
      )
    )
    )
  );
}

export default combineEpics(
  basicAuthSignInEpic
);
