import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

/**
 * Redirects to home page, depended on role type
 * @memberof module:PermissionChecker
 */
const HomePage = (props) => {
  const {
    user,
    myRoleType
  } = props;

  switch (myRoleType) {
    case 'end_user': {
      return (
        <Redirect to="/dashboard/" />
      );
    }

    case 'support': {
      if (
        !user?.supportedUsers?.length
      ) {
        return (
          <Redirect to="/no-access" />
        );
      }

      return (
        <Redirect to="/overview/" />
      );
    }

    case 'viewer': {
      if (
        !user?.attachedToViewerUsers
      ) {
        return (
          <Redirect to="/no-access" />
        );
      }
      return (
        <Redirect to="/overview/" />
      );
    }

    default: {
      return (
        <Redirect to="/overview/" />
      );
    }
  }
};

HomePage.propTypes = {
  myRoleType: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired
};

export default HomePage;
