import { combineEpics } from 'redux-observable';
import initUser from './initUser';
import chartChangeScale from './chartChangeScale';
import chartChangeRange from './chartChangeRange';
import dashbaordUpdCurrent from './dashbaordUpdCurrent';
import updateChart from './updateChart';
import getInitialCurrentValues from './getInitialCurrentValues';

/**
 * @module DashboardEpics
 */

export default combineEpics(
  initUser,
  chartChangeScale,
  chartChangeRange,
  dashbaordUpdCurrent,
  updateChart,
  getInitialCurrentValues
);
