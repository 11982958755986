export const GET_NOT_ATTACHED_END_USERS = 'GET_NOT_ATTACHED_END_USERS';
export const SET_NOT_ATTACHED_END_USERS = 'SET_NOT_ATTACHED_END_USERS';
export const GET_ALL_ATTACHED_INSTALLERS = 'GET_ALL_ATTACHED_INSTALLERS';
export const SET_ALL_ATTACHED_INSTALLERS = 'SET_ALL_ATTACHED_INSTALLERS';
export const GET_ALL_NOT_ATTACHED_INSTALLERS = 'GET_ALL_NOT_ATTACHED_INSTALLERS';
export const SET_ALL_NOT_ATTACHED_INSTALLERS = 'SET_ALL_NOT_ATTACHED_INSTALLERS';
export const GET_ALL_NOT_ATTACHED_OEMS = 'GET_ALL_NOT_ATTACHED_OEMS';
export const SET_ALL_NOT_ATTACHED_OEMS = 'SET_ALL_NOT_ATTACHED_OEMS';
export const GET_OEMS_LIST = 'GET_OEMS_LIST';
export const SET_OEMS_LIST = 'SET_OEMS_LIST';
export const GET_PV_INSTALLERS_LIST = 'GET_PV_INSTALLERS_LIST';
export const SET_PV_INSTALLERS_LIST = 'SET_PV_INSTALLERS_LIST';
export const SET_LOADING_SUPPORT = 'SET_LOADING_SUPPORT';

export const addEndUsersToSupportModalID = 'addEndUsersToSupport';
export const addEndUsersToSupportForm = 'addEndUsersToSupportForm';
export const addPVInstallersToSupportModalID = 'addPVInstallersToSupport';
export const addPVInstallersToSupportForm = 'addPVInstallersToSupportForm';
export const addOEMToSupportModalID = 'addOEMToSupport';
export const addOEMToSupportForm = 'addOEMToSupportForm';
export const dataListIDSupport = 'currentSupport';
export const dataListIDEndUsers = 'supportsEndUsers';
export const dataListIDPVInstallers = 'supportsPVInstallers';
export const dataListIDOEMs = 'supportsOEMs';

export const dataListIDTabs = [
  dataListIDEndUsers,
  dataListIDPVInstallers,
  dataListIDOEMs
];

export const getSupportsParams = {
  _start: 0,
  _sort: 'first_name:asc'
};

export const getEndUsersParams = {
  _start: 0,
  _sort: 'smid:asc'
};

export const getInstallersParams = {
  _start: 0,
  _sort: 'my_company:asc'
};

export const getOEMSParams = {
  _start: 0,
  _sort: 'oemCompany:asc'
};

export const selectAllCheckbox = 'selectAll';
