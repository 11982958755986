import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import '../Users/index.css';
import { createStructuredSelector } from 'reselect';
import { uniqBy } from 'lodash';
import columns from './columns';
import TopBar from '../../components/TopBar';
import SearchField from '../../components/SearchField';
import {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
} from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import DataList, { DataListPagination, DataListFilter, dataListReloadData } from '../DataList';
import { allDataListsSelector } from '../DataList/selectors';
import UpdateSMForm from './components/UpdateSMForm';
import MultiFilter from './components/MultiFilter';
import i18n from '../../i18n';
import {
  installedSMReplaceSelected,
  installedSMSendRequest,
  installedSMSendUpdate,
  instSMGetFWVersions
} from './actions';
import { devicesGetTypesAndNames } from '../UserDetails/actions';
import { saveDevicesTypesList } from '../DevicesTable/actions';
import RenderFWVersionFilter from './components/RenderFWVersionFilter';
import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import ChooseLicenseWindow from '../../components/ChooseLicenseWindow';
import {
  ADD_GATEWAY_FORM_ID,
  DATA_LIST_ID,
  DATA_LIST_URL
} from './constants';
import DeleteGatewayWindow from './DeleteGatewayWindow';
import ConfirmDeleteGatewayWindow from './ConfirmDeleteWindow';
import DeleteFirmwareWindow from './DeleteFirmwareWindow';
import AddGatewayWindow from './AddGatewayWindow';
import CloudStatusWindow from '../CloudStatusWindow';
import { CLOUD_STATUS_MODAL_ID } from '../CloudStatusWindow/constants';
import { receiveCloudStatusMessage, requestCloudStatusMessage } from '../CloudStatusWindow/actions';
import store from '../../store';
import { cloudStatusInfoSelector, selectedRowsSelector, versionsListSelector } from './selectors';
import { devicesListSelector, getInstalledSMSearchParamsSelector } from '../DevicesTable/modals/selectors';
import { userSelector } from '../../components/ReactTable/selectors';
import analytics from '../../analytics';
import getEventObj, { DATA_EXPORT_GATEWAYS_INFO, DATA_EXPORT_USERS_INFO } from '../../analytics/events';


/**
 * @module InstalledSM
 */

const reloadDataList = () => store.dispatch(dataListReloadData('installed_sm'));

const transformResponse = (data) => ({
  total: data.total,
  data: data.list
});

const CheckboxTable = checkboxHOC(ReactTable);
/**
 * Renders Installed SM page
 * @memberof module:InstalledSM
 */
class InstalledSM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      deviceTypeOptions: [],
      deviceNameOptions: []
    };

    const {
      myRoleType,
      history,
      user
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    const { language } = user;
    this.columns = columns(myRoleType, language);
    this.selectAll = true;

    configSavedSettings(
      this,
      'installed-sm',
      {
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'sm_id:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  componentDidMount() {
    const {
      instSMGetFWVersions: instSMGetFWVersionsAC,
      requestCloudStatusMessage: requestCloudStatusMessageFromProps,
      devicesGetTypesAndNames: devicesGetTypesAndNamesFromProps
    } = this.props;

    instSMGetFWVersionsAC();
    requestCloudStatusMessageFromProps();
    devicesGetTypesAndNamesFromProps('all', (data) => {
      this.setState({
        deviceTypeOptions: uniqBy(data.map(({ type }) => ({ type })), 'type'),
        deviceNameOptions: uniqBy(data.map(({ name }) => ({ name })), 'name')
      });

      return saveDevicesTypesList(data);
    });
  }

  /**
   * Export all users
   */
  exportCSV = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    analytics.sendEvent(getEventObj(DATA_EXPORT_USERS_INFO));
    sendRequest(
      'get',
      '/../data/export-users-info',
      { responseType: 'blob' },
      { errorMessage: 'exportRequestError', downloadFile: true }
    );
  };

  exportLogFile = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest(
      'post',
      '/../gateway/reboot-service-logs',
      { responseType: 'blob' },
      { errorMessage: 'exportRequestError', downloadFile: true }
    );
  };

  /**
   * Export all devices
   */
  exportAllDevicesCSV = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    analytics.sendEvent(getEventObj(DATA_EXPORT_GATEWAYS_INFO));
    sendRequest(
      'get',
      '/../data/export-gateways-info',
      { responseType: 'blob' },
      { errorMessage: 'exportRequestError', downloadFile: true }
    );
  };

  removeFirmwareUpdate = ({ id }) => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest('DELETE', `/../firmware-update/${id}`, undefined, {
      successMessage: 'removeFirmwareRequestSuccessful',
      errorMessage: 'removeFirmwareRequestError',
      cb: reloadDataList
    }, 'removeFirmwareUpdate');
  };

  confirmRemoveGateway = (value) => {
    const {
      closeModalWindow: closeModal,
      openModalWindow: openModal
    } = this.props;

    closeModal('confirmRemoveGateway');
    openModal('removeGateway', { id: value?.gatewayId });
  };

  removeGateway = ({ id }) => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest('DELETE', `/../gateway/${id}`, undefined, {
      successMessage: 'removeGatewayRequestSuccessful',
      errorMessage: 'removeGatewayRequestError',
      cb: reloadDataList
    }, 'removeGateway');
  };

  transformGatewayFormFieldsForApi = (fields) => ({
    ...fields,
    gateways: fields.gateways
      .split(/[\s\,]+/)
      .filter((gateway) => gateway)
      .join(',')
  });

  addGateway = (fields) => {
    const {
      modalWindowSend: modalSend
    } = this.props;
    return modalSend(
      ADD_GATEWAY_FORM_ID,
      {
        method: 'post',
        url: '/../gateway/list',
        data: this.transformGatewayFormFieldsForApi(fields)
      }
    );
  };

  addCloudStatusMessage = (fields) => {
    const {
      modalWindowSend: modalSend,
      receiveCloudStatusMessage: receiveCloudStatusMessageFromProps
    } = this.props;

    modalSend(
      CLOUD_STATUS_MODAL_ID,
      {
        method: 'post',
        url: '/../maintenance-message',
        data: fields
      }
    );
    if (fields?.message) {
      receiveCloudStatusMessageFromProps(fields);
    }
  };

  /**
   * Toggles selection for a row
   * @param {number} key_ ID of row
   */
  toggleSelection = (key_) => {
    const key = key_.replace('select-', '');
    const {
      selectedRows,
      installedSMReplaceSelected: installedSMReplaceSelectedProps
    } = this.props;
    let selection = [...selectedRows];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
      if (this.selectAll) this.selectAll = false;
    } else {
      selection.push(key);
      if (!this.selectAll) this.selectAll = true;
    }
    installedSMReplaceSelectedProps(selection);
  };

  /**
   * Toggles selection for all rows
   */
  toggleAll = () => {
    const {
      selectedRows,
      installedSMReplaceSelected: installedSMReplaceSelectedProps
    } = this.props;
    const { selectAll: selectedAll } = this.state;
    const selectAll = !selectedAll;
    const selection = [...selectedRows];

    const wrappedInstance = this.checkboxTable.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (selectAll) {
      currentRecords.forEach((item) => {
        const index = selection.indexOf(item._original._id);
        if (index === -1) {
          selection.push(item._original._id);
        }
      });
    } else {
      currentRecords.forEach((item) => {
        const index = selection.indexOf(item._original._id);
        if (index !== -1) {
          selection.splice(index, 1);
        }
      });
    }
    installedSMReplaceSelectedProps(selection);
    this.setState({ selectAll });
  };

  /**
   * Checks if row is selected
   * @returns {boolean}
   */
  isSelected = (key) => {
    const { selectedRows } = this.props;
    return selectedRows.includes(key);
  };

  /**
   * update device name options
   * @param {string[] } data
   * @returns {{name: string}[]}
   */
  extraUpdateDeviceNameOptions = (data) => {
    const { devicesList = [], allDataList } = this.props;
    const deviceNameSubmitParams = allDataList?.installed_sm?.params?.device_names || '';

    const filteredNameOptions = devicesList.filter(({ type }) => data.split(',').includes(type)).map(({ name }) => ({ name }));


    this.setState({
      deviceNameOptions: filteredNameOptions
    });

    return {
      device_names: deviceNameSubmitParams?.split(',')
        .filter((name) => filteredNameOptions.map((props) => props.name).includes(name)).join(',') || ''
    };
  };

  UNSAFE_componentWillUpdate() {
    this.selectAll = true;
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
    const {
      closeModalWindow: closeModal,
      selectedRows,
      versionsList,
      installedSMSendUpdate: installedSMSendUpdateProps,
      openModalWindow: openModal,
      searchParams,
      cloudStatusInfo,
      myRoleType
    } = this.props;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTdProps: () => ({
        className: 'checkbox-react-table'
      }),
      getTrProps: (allComponentProps, currentProps) => {
        if (!allComponentProps || !currentProps) return {};
        const { selectAll: selectedAll } = this.state;
        const selected = this.isSelected(currentProps.original._id);
        if (this.selectAll && !selected) {
          this.selectAll = false;
        }

        if (allComponentProps.data.length - 1 === currentProps.index) {
          // if it's the last row
          if (this.selectAll && !selectedAll) {
            this.setState({ selectAll: true });
          } else if (!this.selectAll && selectedAll) {
            this.setState({ selectAll: false });
          }
        }

        return {
          style: {
            backgroundColor: selected ? '#33363e' : 'inherit'
          }
        };
      },
      getTheadThProps: () => ({
        className: 'checkbox-react-table-all'
      })
    };

    return (
      <div className="main-table-container">
        <AddGatewayWindow
          modalID={ADD_GATEWAY_FORM_ID}
          onSubmit={this.addGateway}
          handleOnClose={closeModal.bind(null, ADD_GATEWAY_FORM_ID)}
        />
        <DeleteFirmwareWindow
          modalID="removeFirmwareUpdate"
          onSubmit={this.removeFirmwareUpdate}
          handleOnClose={closeModal.bind(null, 'removeFirmwareUpdate')}
        />
        <ConfirmDeleteGatewayWindow
          modalID="confirmRemoveGateway"
          handleOnClose={closeModal.bind(null, 'confirmRemoveGateway')}
          onSubmit={this.confirmRemoveGateway}
        />
        <DeleteGatewayWindow
          modalID="removeGateway"
          onSubmit={this.removeGateway}
          handleOnClose={closeModal.bind(null, 'removeGateway')}
        />
        <CloudStatusWindow
          modalID={CLOUD_STATUS_MODAL_ID}
          onSubmit={this.addCloudStatusMessage}
        />
        <TopBar updateButton showNavigation />
        <ChooseLicenseWindow />
        <div className="container-fluid">
          <div className="subheader-smu-table">
            <span className="emp-span">
              <i className="fa fa-home icons-employees" />
              -&nbsp;
              {i18n.t('installedSM')}
            </span>
          </div>

          <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="col-xl-12">
              <UpdateSMForm onSubmit={installedSMSendUpdateProps} />
              <div className="row w-100">
                <div className="col-12 col-xl-4 col-lg-5 col-md-6 col-sm-12 sear-sel-inps search-field">
                  <DataListFilter
                    Component={SearchField}
                    componentProps={{
                      searchByFields: 'sm_id,company,firmware,hardware',
                      placeholder: i18n.t('searchAllTablesElements'),
                      singleSearch: 'sm_id_contains'
                    }}
                    listID={DATA_LIST_ID}
                  />
                </div>
                <div className="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center filter-element version-field">
                  <div className="w-100 form-group m-form__group">
                    <DataListFilter
                      Component={RenderFWVersionFilter}
                      componentProps={{
                        versionsList
                      }}
                      listID={DATA_LIST_ID}
                    />
                  </div>
                </div>
                <div className="d-flex multi-select-wrapper">
                  <div className="multi-select">
                    <DataListFilter
                      Component={MultiFilter}
                      componentProps={{
                        options: this.state.deviceTypeOptions,
                        placeholder: i18n.t('selectType'),
                        title: i18n.t('deviceType'),
                        fieldName: 'type',
                        submitFieldName: 'types',
                        extraUpdate: this.extraUpdateDeviceNameOptions
                      }}
                      listID={DATA_LIST_ID}
                    />
                  </div>
                  <div className="multi-select">
                    <DataListFilter
                      Component={MultiFilter}
                      componentProps={{
                        options: this.state.deviceNameOptions,
                        placeholder: i18n.t('selectName'),
                        title: i18n.t('deviceName'),
                        submitFieldName: 'device_names',
                        fieldName: 'name',
                        disabled: !searchParams?.types
                      }}
                      listID={DATA_LIST_ID}
                    />
                  </div>
                </div>
                {['root', 'solar_admin'].includes(myRoleType) && (
                  <div className="col-auto pt-1 px-2 d-flex align-items-center filter-element">
                    <button
                      type="button"
                      onClick={this.exportLogFile}
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                    >
                      {i18n.t('exportLogBtn')}
                    </button>
                  </div>
                )}
                <div className="col-auto pt-1 px-2 d-flex align-items-center filter-element">
                  <button
                    type="button"
                    onClick={this.exportCSV}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('exportBtn')}
                  </button>
                </div>
                <div className="col-auto pt-1 px-2 d-flex align-items-center filter-element">
                  <button
                    type="button"
                    onClick={this.exportAllDevicesCSV}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('exportAllDevices')}
                  </button>
                </div>
                <div className="col-auto pt-1 px-2 d-flex align-items-center filter-element">
                  <button
                    type="button"
                    onClick={openModal.bind(null, ADD_GATEWAY_FORM_ID, null)}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('addGatewayBtn')}
                  </button>
                </div>
                <div className="col-auto pt-1 px-2 d-flex align-items-center filter-element">
                  <button
                    type="button"
                    onClick={openModal.bind(null, CLOUD_STATUS_MODAL_ID, cloudStatusInfo)}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('cloudStatus')}
                  </button>
                </div>
                <div className="col-auto pt-2 ml-auto d-flex align-items-center filter-element">
                  <span>
                    <span className="smu-select-sp-num">
                      {selectedRows ? selectedRows.length : 0}
                      &nbsp;
                    </span>
                    <span className="smu-select-sp">
                      {i18n.t('SMUSelected')}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded installed-table">
            <DataList
              listID={DATA_LIST_ID}
              listURL={DATA_LIST_URL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={CheckboxTable}
              componentProps={{
                ref: (r) => {
                  this.checkboxTable = r;
                  return r;
                },
                ...checkboxProps,
                defaultPageSize: 0,
                // resizable: false,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                manual: true
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

InstalledSM.propTypes = {
  myRoleType: PropTypes.string.isRequired,
  installedSMSendUpdate: PropTypes.func.isRequired,
  instSMGetFWVersions: PropTypes.func.isRequired,
  installedSMReplaceSelected: PropTypes.func.isRequired,
  installedSMSendRequest: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  versionsList: PropTypes.instanceOf(Array).isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  cloudStatusInfo: PropTypes.instanceOf(Object),
  openModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  requestCloudStatusMessage: PropTypes.func.isRequired,
  receiveCloudStatusMessage: PropTypes.func.isRequired,
  devicesGetTypesAndNames: PropTypes.func.isRequired,
  devicesList: PropTypes.instanceOf(Array),
  allDataList: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
  searchParams: PropTypes.instanceOf(Object)
};

InstalledSM.defaultProps = {
  cloudStatusInfo: {},
  devicesList: [],
  searchParams: {},
  user: {}
};

const mapStateToProps = createStructuredSelector({
  selectedRows: selectedRowsSelector,
  versionsList: versionsListSelector,
  cloudStatusInfo: cloudStatusInfoSelector,
  user: userSelector,
  devicesList: devicesListSelector,
  searchParams: getInstalledSMSearchParamsSelector,
  allDataList: allDataListsSelector
});

export default connect(
  mapStateToProps,
  {
    openModalWindow,
    closeModalWindow,
    modalWindowSend,
    installedSMReplaceSelected,
    installedSMSendUpdate,
    instSMGetFWVersions,
    installedSMSendRequest,
    receiveCloudStatusMessage,
    requestCloudStatusMessage,
    devicesGetTypesAndNames
  }
)(InstalledSM);
