import React from 'react';
import SwiperCore, { Keyboard, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import {
  getFormattedHours,
  getFormattedMinutes,
  generateTimeStringSequence,
  getHoursIndex,
  getMinutesIndex
} from './utils';

import './styles.scss';

/**
 * Array of strings to slide in an "hours swiper" - ['00', '01', '02', ..., '23']
 * @ignore
 */
const hoursArr = generateTimeStringSequence(24);

/**
 * Array of strings to slide in an "minutes swiper" - ['00', '01', '02', ..., '59']
 * @ignore
 */
const minutesArr = generateTimeStringSequence(60);

/**
 * Default time string for incorrect or empty input values.
 * @ignore
 */
const defaultTime = '00:00';

SwiperCore.use([Keyboard, Mousewheel]);

export class CustomTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fireHoursOnChange: true,
      fireMinutesOnChange: true
    };
  }

  componentDidUpdate(prevProps) {
    const { time: prevTime = defaultTime } = prevProps;
    const { time = defaultTime } = this.props;

    const prevHoursIndex = getHoursIndex(hoursArr, prevTime);
    const prevMinutesIndex = getMinutesIndex(minutesArr, prevTime);
    const hoursIndex = getHoursIndex(hoursArr, time);
    const minutesIndex = getMinutesIndex(minutesArr, time);

    if (!this.hoursSwiper?.destroyed) {
      if (hoursIndex !== prevHoursIndex) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          { fireHoursOnChange: false },
          () => {
            if (!this.hoursSwiper?.destroyed) {
              this.hoursSwiper.slideToLoop(hoursIndex, 0, false);
            }
            this.setState({ fireHoursOnChange: true });
          }
        );
      }
    }

    if (!this.minutesSwiper?.destroyed) {
      if (minutesIndex !== prevMinutesIndex) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          { fireMinutesOnChange: false },
          () => {
            if (!this.minutesSwiper?.destroyed) {
              this.minutesSwiper.slideToLoop(minutesIndex, 0, false);
            }
            this.setState({ fireMinutesOnChange: true });
          }
        );
      }
    }
  }

  componentWillUnmount() {
    const { time, onChange } = this.props;
    if (this.hoursSwiper) {
      this.hoursSwiper.destroy();
    }
    if (this.minutesSwiper) {
      this.minutesSwiper.destroy();
    }
    if (time === '') {
      onChange(time);
    } else {
      const hours = getFormattedHours(time);
      const minutes = getFormattedMinutes(time);
      onChange(`${hours}:${minutes}`);
    }
  }

  onHoursInit = (swiper) => {
    this.hoursSwiper = swiper;
  };

  onMinutesInit = (swiper) => {
    this.minutesSwiper = swiper;
  };

  changeHours = ({ realIndex }) => {
    const {
      time = defaultTime,
      onChange
    } = this.props;
    const { fireHoursOnChange } = this.state;
    if (fireHoursOnChange) {
      const currentHours = hoursArr[+realIndex];
      const minutes = getFormattedMinutes(time);
      const newTime = `${currentHours}:${minutes}`;
      onChange(newTime);
    }
  };

  changeMinutes = ({ realIndex }) => {
    const {
      time = defaultTime,
      onChange
    } = this.props;
    const { fireMinutesOnChange } = this.state;
    if (fireMinutesOnChange) {
      const currentMinutes = minutesArr[+realIndex];
      const hours = getFormattedHours(time);
      const newTime = `${hours}:${currentMinutes}`;
      onChange(newTime);
    }
  };

  render() {
    const { time = defaultTime } = this.props;
    const initialHoursIndex = getHoursIndex(hoursArr, time);
    const initialMinutesIndex = getMinutesIndex(minutesArr, time);

    return (
      <>
        <div className="time-picker-container">
          <div className="active-mask" />
          <div className="time-picker">
            <div className="picker">
              <Swiper
                onInit={this.onHoursInit}
                onSlideChange={this.changeHours}
                slidesPerView={3}
                direction="vertical"
                centeredSlides
                keyboard
                loop
                initialSlide={initialHoursIndex}
                freeMode
                freeModeSticky
                mousewheel={{
                  enabled: true,
                  sensitivity: 0.5
                }}
              >
                {hoursArr.map((hour) => (
                  <SwiperSlide key={hour}>
                    <div className="time">{hour}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="time-colons">:</div>
            <div className="picker">
              <Swiper
                onInit={this.onMinutesInit}
                onSlideChange={this.changeMinutes}
                slidesPerView={3}
                direction="vertical"
                keyboard
                centeredSlides
                loop
                initialSlide={initialMinutesIndex}
                freeMode
                freeModeSticky
                mousewheel={{
                  enabled: true,
                  sensitivity: 0.5
                }}
              >
                {minutesArr.map((minute) => (
                  <SwiperSlide key={minute}>
                    <div className="time">{minute}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CustomTimePicker.propTypes = {
  time: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomTimePicker;
