import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as actionTypes from './constants';
import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';

function oAuthSignInEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.OAUTH_SIGN_IN),
    map((action) => action.payload),
    switchMap(({
      data
    }) => (
      from(strapi.request('post', '/v1/auth/oauth/login', { // u can change it to localhost for test
        data
      })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            window.location.href = result;
            return result;
          }
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        }))
    )
    )
  );
}

export default combineEpics(
  oAuthSignInEpic
);
