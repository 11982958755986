import { createSelector } from 'reselect';

const getPriority = (state) => state.monitoring?.selectedFields?.priorityField;
const getAssign = (state) => state.monitoring?.selectedFields?.assignField;
const getWorkStatus = (state) => state.monitoring?.selectedFields?.workStatusField;
const getPvInstallerFilter = (state) => state.monitoring?.selectedFields?.PVInstallerFilter;
const getAssignFilter = (state) => state.monitoring?.selectedFields?.AssignFilter;
const getWorkStatusFilter = (state) => state.monitoring?.selectedFields?.workStatusFilter;
const getCustomSearch = (state) => state.monitoring?.selectedFields?.CustomSearch;
const getSelectedFields = (state) => state.monitoring?.selectedFields;
const getChoosedEmployees = (state) => state.monitoring?.employees;
const getChoosedCompany = (state) => state.monitoring?.choosedCompany?.value;
const getSelectedTickets = (state) => state.monitoring?.selectedRows;
const getAllData = (state) => state.monitoring?.data;
const getErrors = (state) => state.monitoring?.errors;
const getChecked = (state) => state.monitoring?.checked;
const getInstallationCompleted = (state) => state.monitoring?.isInstallationCompleted;
const getSupportContractChecked = (state) => state.monitoring?.supportContractChecked;
const getAllInstallersList = (state) => state.monitoring?.installers;
const getParsedInstallers = (state) => state.monitoring?.parsedInstallers;
const getParsedAssignee = (state) => state.monitoring?.parsedAssignee;
const getEmployeesForFilter = (state) => state.monitoring?.employeesForFilter;
const getSorting = (state) => state.dataList?.monitoring?.params;
const getMonitoring = (state) => state.monitoring;
const getUser = (state) => state.signIn?.user;

export const prioritySelector = createSelector([getPriority], (priorityField) => priorityField);
export const assignSelector = createSelector([getAssign], (assignField) => assignField);
export const workStatusSelector = createSelector([getWorkStatus], (workStatusField) => workStatusField);
export const pvInstallerFilterSelector = createSelector([getPvInstallerFilter], (PVInstallerFilter) => PVInstallerFilter);
export const assignFilterSelector = createSelector([getAssignFilter], (assignFilter) => assignFilter);
export const workStatusFilterSelector = createSelector([getWorkStatusFilter], (workStatusFilter) => workStatusFilter);
export const customSearchSelector = createSelector([getCustomSearch], (CustomSearch) => CustomSearch);
export const selectedFieldsSelector = createSelector([getSelectedFields], (selectedFields) => selectedFields);
export const choosedEmployeesSelector = createSelector([getChoosedEmployees], (employees) => employees);
export const choosedCompanySelector = createSelector([getChoosedCompany], (company) => company);
export const selectedTicketsSelector = createSelector([getSelectedTickets], (selectedRows) => selectedRows);
export const allDataSelector = createSelector([getAllData], (data) => data);
export const errorsSelector = createSelector([getErrors], (errors) => errors);
export const checkedSelector = createSelector([getChecked], (checked) => checked);
export const installationCompletedSelector = createSelector([getInstallationCompleted], (isInstallationCompleted) => isInstallationCompleted);
export const supportContractCheckedSelector = createSelector([getSupportContractChecked], (supportContractChecked) => supportContractChecked);
export const allInstallersListSelector = createSelector([getAllInstallersList], (installers) => installers);
export const parsedInstallersSelector = createSelector([getParsedInstallers], (parsedInstallers) => parsedInstallers);
export const parsedAssigneeSelector = createSelector([getParsedAssignee], (parsedAssignee) => parsedAssignee);
export const employeesForFilterSelector = createSelector([getEmployeesForFilter], (employeesForFilter) => employeesForFilter);
export const sortingSelector = createSelector([getSorting], (params) => params);
export const monitoringSelector = createSelector([getMonitoring], (monitoring) => monitoring);
export const userSelector = createSelector([getUser], (user) => user);
export const userIdSelector = createSelector([getUser], (user) => user?._id);
export const myRoleTypeSelector = createSelector([getUser], (user) => user?.role?.type);
export const userCompanyIdSelector = createSelector([getUser], (user) => user?.my_company?._id || user?.company?._id);
