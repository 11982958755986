import { createSelector } from 'reselect';

const getFormValues = (state) => state.form.notificationForm?.values;
const getDefaultUserNotification = (state) => state.signIn.user?.emailnotification;
const getResponseNotification = (state) => state.monitoringNotifications?.notificationsValue;
const getCompLogo = (state) => state.signIn?.user?.company_logo;
const getVisibleWeb = (state) => (state.signIn?.user?.my_company ? state.signIn?.user?.my_company?.visible_web : state.signIn?.user?.company?.visible_web);
const getUser = (state) => state.signIn?.user;

export const formValuesSelector = createSelector([getFormValues], (values) => values);
export const defaultUserNotificationSelector = createSelector([getDefaultUserNotification], (emailnotification) => emailnotification);
export const responseNotificationSelector = createSelector([getResponseNotification], (notificationsValue) => notificationsValue);
export const compLogoSelector = createSelector([getCompLogo], (logo) => logo);
export const visibleWebSelector = createSelector([getVisibleWeb], (visibleWeb) => visibleWeb);
export const myRoleTypeSelector = createSelector([getUser], (user) => user?.role?.type);
