import * as actionTypes from './constants';

export function monitoringSendApplyWithFields(url, listID, config) { // epic
  return {
    type: actionTypes.CHANGE_FIELDS,
    payload: {
      url,
      listID,
      config
    }
  };
}

export function monitoringReplaceSelected(newSelected = []) {
  return {
    type: actionTypes.MONITORING_REPLACE_SELECTED,
    payload: {
      newSelected
    }
  };
}

export function employeeSendRequest(companyID, listID, dataList, field) { // epic , reducer
  return {
    type: actionTypes.EMPLOYEE_SEND_REQUEST,
    payload: {
      companyID,
      listID,
      dataList,
      field
    }
  };
}

export function employeeReceived(companyID, listID, listURL, { data, total }, field) { // reducer
  return {
    type: actionTypes.EMPLOYEE_RECEIVED,
    payload: {
      companyID,
      listID,
      listURL,
      data,
      total,
      field
    }
  };
}

export function addChoosedCompany(choosedCompany, choosedUser, choosedStatus) {
  return {
    type: actionTypes.ADD_CHOOSED_COMPANY,
    payload: {
      choosedCompany,
      choosedUser,
      choosedStatus
    }
  };
}

export function changeArchieveErrorSwitch(checked) {
  return {
    type: actionTypes.CHANGE_ARCHIEVE_ERROR_SWITCH,
    payload: {
      checked
    }
  };
}

export function changeSupportContractSwitch(supportContractChecked) {
  return {
    type: actionTypes.CHANGE_SUPPORT_CONTRACT_SWITCH,
    payload: {
      supportContractChecked
    }
  };
}

export function changeInstallationCompletedSwitch(isInstallationCompleted) {
  return {
    type: actionTypes.CHANGE_INSTALLATION_COMPLETED_SWITCH,
    payload: {
      isInstallationCompleted
    }
  };
}

export function saveAllDataListUsers(dataListUsers) {
  return {
    type: actionTypes.SAVE_ALL_DATA_LIST_USERS,
    payload: {
      dataListUsers
    }
  };
}

export function sendPropFromField(fieldName, selections) {
  return {
    type: actionTypes.MONITORING_ADD_SELECTED,
    payload: {
      fieldName,
      selections
    }
  };
}

export function clearPropsFromFields() {
  return {
    type: actionTypes.MONITORING_CLEAR_SELECTED
  };
}

export function monitoringClearFilters() {
  return {
    type: actionTypes.MONITORING_CLEAR_FILTERS
  };
}

export function addParsedData(arr, fieldName) {
  return {
    type: actionTypes.ADD_PARSED_DATA,
    payload: {
      arr,
      fieldName
    }
  };
}

export function monitoringGetInstallers(url, params) { // epic
  return {
    type: actionTypes.MONITORING_GET_INSTALLERS,
    payload: {
      url,
      params
    }
  };
}

export function monitoringInstallersReceived(installers) { // reducer
  return {
    type: actionTypes.MONITORING_INSTALLERS_RECEIVED,
    payload: {
      installers
    }
  };
}

export function getErrorInfo(url, taskId) { // epic
  return {
    type: actionTypes.GET_ERROR_INFO,
    payload: {
      url,
      taskId
    }
  };
}

export function errorInfoReceived(errorsList, taskId) { // reducer
  return {
    type: actionTypes.ERROR_INFO_RECEIVED,
    payload: {
      errorsList,
      taskId
    }
  };
}

export function setMonitoringFilters(fields) { // reducer
  return {
    type: actionTypes.SYNC_FILTERS_FROM_LOCAL_STORAGE,
    payload: {
      fields
    }
  };
}
export function setIsShowPlantColumn(data) {
  return {
    type: actionTypes.SET_IS_SHOW_PLANT_COLUMN,
    payload: {
      data
    }
  };
}

export function setIsShowLastLoginDateColumn(data) {
  return {
    type: actionTypes.SET_IS_SHOW_LAST_LOGIN_DATE_COLUMN,
    payload: {
      data
    }
  };
}

export function setIsShowInstallationDateColumn(data) {
  return {
    type: actionTypes.SET_IS_SHOW_INSTALLATION_DATE_COLUMN,
    payload: {
      data
    }
  };
}

export function updateColumnPropsForMonitoring(payload) {
  return {
    type: actionTypes.UPDATE_COLUMN_PROPS_FOR_MONITORING,
    payload
  };
}
