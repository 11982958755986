/**
 * Short id of language like its spelled in react-flags-select
 * @ignore
 */
export const countries = ['US', 'DE', 'FR', 'IT', 'DK'];


/**
 * Language long id to short
 * @ignore
 */
export const countryLabelsBack = {
  English: 'US',
  Deutsch: 'DE',
  French: 'FR',
  Italian: 'IT',
  Danish: 'DK'
};


/**
 * Language short id to long
 * @ignore
 */
export const countryLabels = {
  US: 'English',
  DE: 'Deutsch',
  FR: 'French',
  IT: 'Italian',
  DK: 'Danish'
};
