import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import Tooltip from '../../Tooltip';
import CustomTimePicker from './CustomTimePicker';
import {
  getFormattedHours,
  getFormattedMinutes
} from './utils';
import i18n from '../../../i18n';

import './styles.scss';

const defaultTime = '00:00';

const isChrome = window?.browser?.name === 'Chrome';

export class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false
    };
  }

  onFocus = () => {
    const {
      input: {
        onFocus = () => { }
      }
    } = this.props;
    this.setState({ showPicker: true });
    return onFocus();
  };

  onBlur = ({ target: { defaultValue: time = defaultTime } }) => {
    const {
      input: {
        onBlur = () => { }
      }
    } = this.props;
    this.setState({ showPicker: false });
    if (time === '') {
      return onBlur(time);
    }
    const hours = getFormattedHours(time);
    const minutes = getFormattedMinutes(time);
    return onBlur(`${hours}:${minutes}`);
  };

  render() {
    const {
      input,
      label,
      showTooltip,
      placement,
      meta: { touched, error },
      preventAuto,
      shrink,
      className,
      defaultValue,
      errorPosition,
      languageToSync,
      forceError,
      ...custom
    } = this.props;

    const { showPicker } = this.state;

    const {
      value: time = defaultTime,
      onChange
    } = input;

    const handleTimePickerChange = ({ target: { value } }) => {
      onChange(value);
    };

    const labelProps = {
      className: 'inputLabel'
    };

    if (shrink) {
      labelProps.shrink = shrink;
    } else if (isChrome && this.myInputRef?.matches(':-webkit-autofill')) {
      labelProps.shrink = true;
    }
    const errorMessage = forceError || error;

    return (
      <>
        {!!showTooltip && (
          <div className="switch-tooltip">
            <Tooltip title={i18n.t(showTooltip)} placement="left" />
          </div>
        )}
        <TextField
          inputRef={(node) => {
            this.myInputRef = node;
          }}
          label={label}
          InputLabelProps={labelProps}
          defaultValue={defaultValue}
          {...input}
          {...custom}
          onChange={handleTimePickerChange}
          className={`customTextField ${className}`}
          InputProps={{
            classes: {
              root: 'inputRoot',
              input: 'inputField'
            }
          }}
          inputProps={
            preventAuto
              ? {
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'off'
              }
              : {}
          }
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          variant="standard"
        />
        {showPicker && (
          <CustomTimePicker
            onChange={onChange}
            time={time}
          />
        )}
        <div className="time-picker-warning-container">
          {touched && (errorMessage || languageToSync) && (
            <div className="inputCustomWarning" style={{ position: errorMessage ? errorPosition : 'absolute', zIndex: shrink ? 'undefined' : 99999 }}>
              {i18n.t(errorMessage)}
            </div>
          )}
        </div>
      </>
    );
  }
}

TimePicker.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  languageToSync: PropTypes.string,
  errorPosition: PropTypes.string,
  preventAuto: PropTypes.bool,
  shrink: PropTypes.bool,
  forceError: PropTypes.string
};

TimePicker.defaultProps = {
  languageToSync: '',
  showTooltip: '',
  preventAuto: false,
  shrink: true,
  defaultValue: undefined,
  placement: undefined,
  errorPosition: 'absolute',
  forceError: ''
};

export default TimePicker;
