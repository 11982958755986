import * as actionTypes from './constants';

export function installedSMReplaceSelected(newSelected = []) { // reducer
  return {
    type: actionTypes.INSTALLED_SM_REPLACE_SELECTED,
    payload: {
      newSelected
    }
  };
}

export function installedSMSendUpdate(fields) { // epic
  return {
    type: actionTypes.INSTALLED_SM_SEND_UPDATE,
    payload: {
      fields
    }
  };
}

export function installedSMSendRequest(method, url, config, onResult, modalID) {
  return {
    type: actionTypes.INSTALLED_SM_SEND_REQUEST,
    payload: {
      method,
      url,
      config,
      onResult,
      modalID
    }
  };
}

export function installedSMRequestStopLoading(modalID) {
  return {
    type: actionTypes.INSTALLED_SM_REQUEST_STOP_LOADING,
    payload: {
      modalID
    }
  };
}

export function instSMGetFWVersions() { // epic
  return {
    type: actionTypes.INST_SM_GET_FW_VERSIONS
  };
}

export function instSMFWVersionsRecieved(versionsList) { // reducer
  return {
    type: actionTypes.INST_SM_FW_VERSIONS_RECEIVED,
    payload: {
      versionsList
    }
  };
}

export function fetchHardwareListRequest() { // epic
  return {
    type: actionTypes.FETCH_HARDWARE_LIST_REQUEST
  };
}

export function fetchHardwareListSuccess(hardwareList) { // reducer
  return {
    type: actionTypes.FETCH_HARDWARE_LIST_SUCCESS,
    payload: {
      hardwareList
    }
  };
}
