import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { openModalWindow } from '../../../containers/ModalWindow/actions';
import store from '../../../store';
import i18n from '../../../i18n';

import '../index.scss';

const openModal = (modalID, data) => store.dispatch(openModalWindow(modalID, data));

/**
 * Render function for React-Table. Column with buttons for Monitoring page.
 * @memberof module:ReactTable
 * @param  {Object} props
 * @param  {Object} props.user - user object
 * @param  {string} props.user._id - user _id
 * @param  {string} props.user.id - user id
 * @param  {string} props.user.sm_id - user solar manager id
 * @param  {string} props._id - monitoring error id
 * @param  {string} props.note - monitoring error note
 * @param  {Object} showBtns - Defines the button what will be shown.
 */
const renderMonitoringTableButtons = (props, showBtns) => {
  const
    {
      user: {
        _id,
        id,
        email
      },
      gateway: {
        sm_id
      },
      _id: ticketId,
      note
    } = props;

  return (
    <Fragment>
      {showBtns?.sendEmail && (
      <a
        title={i18n.t('sendEmail')}
        href={`mailto:${email}?subject=${sm_id}`}
        className="send-email-btn btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i id="send-email-icon" className="send-email-icon la la-envelope" />
      </a>
      )}
      {showBtns?.editNote && (
      <a
        title={i18n.t('editNote')}
        onClick={openModal.bind(null, 'editNote', { ticketId, note })}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <i className="la la-pencil-square" />
      </a>
      )}
      {showBtns?.devices && (
      <Link
        to={`${showBtns.devices.preLink}${id || _id}/`}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        title={i18n.t('settings')}
      >
        <i className="la la-cog" />
      </Link>
      )}
    </Fragment>
  );
};
renderMonitoringTableButtons.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    id: PropTypes.string,
    sm_id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  ticketId: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  showBtns: PropTypes.shape({
    sendEmail: PropTypes.bool,
    editNote: PropTypes.bool,
    devices: PropTypes.bool
  }).isRequired
};

renderMonitoringTableButtons.defaultProps = {
  id: ''
};

export default renderMonitoringTableButtons;
