import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderAccess = ({ original }) => {
  const { type: roleType } = original?.role || {};

  const labels = {
    pv_installer_employee: <div className="access-label">R/W</div>,
    pv_installer_employee_read_only: <div className="access-label">R</div>
  };

  return labels[roleType] || null;
};

RenderAccess.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderAccess;
