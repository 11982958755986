export const CHART_WILL_CHANGE_SCALE = 'CHART_WILL_CHANGE_SCALE';
export const CHART_WILL_CHANGE_RANGE = 'CHART_WILL_CHANGE_RANGE';
export const CHART_SCALE_CHANGED = 'CHART_SCALE_CHANGED';
export const CHART_INIT_USER = 'CHART_INIT_USER';
export const DASHBOARD_UPD_CURRENT = 'DASHBOARD_UPD_CURRENT';
export const CHART_CHANGED_IN_REALTIME = 'CHART_CHANGED_IN_REALTIME';
export const DASHBOARD_RELOAD_CHART_SETTINGS = 'DASHBOARD_RELOAD_CHART_SETTINGS';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export const CHART_LOADED = 'CHART_LOADED';
export const CHART_WILL_UPDATE = 'CHART_WILL_UPDATE';
export const CHART_DATA_RECEIVED = 'CHART_DATA_RECEIVED';
export const CHART_DEFINE_BUFFER = 'CHART_DEFINE_BUFFER';
export const DASHBOARD_GET_INITIAL_CURRENT_VALUES = 'DASHBOARD_GET_INITIAL_CURRENT_VALUES';
export const DASHBOARD_SET_INITIAL_CURRENT_VALUES = 'DASHBOARD_SET_INITIAL_CURRENT_VALUES';
export const TOGGLE_SHOW_DEVICES_CURVES_SWITCH = 'TOGGLE_SHOW_DEVICES_CURVES_SWITCH';

/**
 * Valid time range for new point data - 5 minutes
 */
export const CURRENT_VALUES_TIME_RANGE = 300000;

export const ONE_HOUR_MS = 3600000;
export const ONE_DAY_MS = 86400000;
export const ONE_WEEK_MS = 604800000;
