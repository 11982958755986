import moment from 'moment-timezone';

const MIN_POINT_DISTANCE = 7200000; // two hours

function pushToStart(original, newPoint) {
  if (!original[0] || original[0][0] > newPoint[0] + MIN_POINT_DISTANCE) {
    original.unshift(newPoint);
  }
}

function pushToEnd(original, newPoint) {
  if (!original[original.length - 1]
    || original[original.length - 1][0] + MIN_POINT_DISTANCE < newPoint[0]) {
    original.push(newPoint);
  }
}

/**
 *
 * @param x_consumption
 * @param x_production
 * @param x_selfConsumption
 * @param chartType
 * @returns {boolean}
 */
const setEndForBarChart = (
  { x_consumption, x_production, self_consumption },
  chartType) => {
  let lastPoint = [];
  let firstPoint = [];
  if (x_consumption.length <= 0) return true;

  const lastTimestamp = x_consumption[x_consumption.length - 1][0];
  if (chartType === 'monthly') {
    const lastMonthOfYear = moment(lastTimestamp).endOf('year').startOf('month');
    lastPoint = [lastMonthOfYear.valueOf(), 0];
    firstPoint = [moment(lastTimestamp).startOf('year').valueOf(), 0];
  } else if (chartType === 'daily') {
    const lastDayOfMonth = moment(lastTimestamp).endOf('month').startOf('day');
    lastPoint = [lastDayOfMonth.valueOf(), 0];
    firstPoint = [moment(lastTimestamp).startOf('month').valueOf(), 0];
  }

  pushToStart(x_consumption, firstPoint);
  pushToStart(x_production, firstPoint);
  pushToStart(self_consumption, firstPoint);

  pushToEnd(x_consumption, lastPoint);
  pushToEnd(x_production, lastPoint);
  pushToEnd(self_consumption, lastPoint);

  return true;
};

export default setEndForBarChart;
