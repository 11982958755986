import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as workerTimers from 'worker-timers';
import moment from 'moment-timezone';
import { replace } from 'react-router-redux';

import TopBar from '../../components/TopBar';
import LoadManagementCurrentValues from './components/LoadManagementCurrentValues';
import LoadManagementDashboardContainer from './components/LoadManagementDashboardContainer';
import { closeModalWindow, openModalWindow } from '../ModalWindow/actions';
import LoadManagementChartControls from './components/LoadManagementChartControls';
import CalendarPickerModal from '../Dashboard/components/CalendarPickerModal';
import {
  requestInitUser,
  getPhasesData,
  getChartData,
  receiveInitUser,
  changeDataForRequest,
  changeScaleType,
  sendIOTCommand
} from './actions';
import {
  chartSelector,
  companyLogoSelector,
  scaleTypeSelector,
  signInUserSelector,
  timezoneSelector,
  userSelector
} from './selectors';
import { chartChangeRange, formatLoadManagementInterval, getRange } from './utils';

import './index.scss';

const oneMinuteInMS = 60000;
const fiveMinutesInMS = 300000;
const dayInMS = 86400000;

const LoadManagementDashboard = (props) => {
  const {
    match,
    myUserID,
    requestInitUser: requestInitUserFromProps,
    user,
    signInUser,
    getPhasesData: getPhasesDataFromProps,
    getChartData: getChartDataFromProps,
    receiveInitUser: receiveInitUserFromProps,
    changeDataForRequest: changeDataForRequestFromProps,
    changeScaleType: changeScaleTypeFromProps,
    sendIOTCommand: sendIOTCommandFromProps,
    replace: replaceFromProps,
    scaleType,
    location,
    timezone,
    chart
  } = props;

  const { userId } = match.params;
  const myself = !(userId && userId !== myUserID);
  const userName = user ? `${user.first_name} ${user.last_name}` : false;
  const { gatewayId, smartMeterId } = user || {};
  const searchParams = new URLSearchParams(window.location.search);
  const scaleTypeFromURL = searchParams.get('type');
  const fromFromURL = searchParams.get('from');
  const toFromURL = searchParams.get('to');
  const now = moment().valueOf();
  const realTimeData = moment(now).diff(chart.to) <= fiveMinutesInMS * 2;

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }

    return () => {
      moment.tz.setDefault();
    };
  }, [timezone]);

  const [tabHasFocus, setTabHasFocus] = useState(true);

  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true);
    };

    const handleBlur = () => {
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (gatewayId && tabHasFocus && smartMeterId) {
      sendIOTCommandFromProps(gatewayId);
    }

    const loadManagementIOTRequest = workerTimers.setInterval(() => {
      if (gatewayId && tabHasFocus && smartMeterId) {
        sendIOTCommandFromProps(gatewayId);
      }
    }, oneMinuteInMS);

    return () => {
      workerTimers.clearInterval(loadManagementIOTRequest);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId, smartMeterId, tabHasFocus]);

  useEffect(() => {
    if (signInUser?.role?.type === 'end_user') {
      receiveInitUserFromProps(signInUser);
    } else if (userId) {
      requestInitUserFromProps(userId);
    }

    if (scaleTypeFromURL) {
      if (['t', 'w', 'd', 'h'].includes(scaleTypeFromURL)) {
        changeScaleTypeFromProps({ type: scaleTypeFromURL });
      } else if (scaleTypeFromURL === 'range') {
        changeDataForRequestFromProps({
          from: new Date(Number(fromFromURL)).toISOString(),
          to: new Date(Number(toFromURL)).toISOString(),
          scaleMS: toFromURL - fromFromURL
        });
      }
    } else {
      changeScaleTypeFromProps({ type: 't' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gatewayId && smartMeterId) {
      getPhasesDataFromProps({ myself, gatewayId, smartMeterId });
    }

    const currentValuesInterval = workerTimers.setInterval(() => {
      if (gatewayId && smartMeterId) {
        getPhasesDataFromProps({ myself, gatewayId, smartMeterId });
      }
    }, 10000);

    return () => {
      workerTimers.clearInterval(currentValuesInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (gatewayId) {
      const { from, to, expectedScaleMS } = getRange(scaleType?.type, chart);
      changeDataForRequestFromProps({
        from: from.toISOString(),
        to: to.toISOString(),
        scaleMS: expectedScaleMS
      });
      getChartDataFromProps({ gatewayId, from: from.toISOString(), to: to.toISOString() });
    }

    const chartDataInterval = workerTimers.setInterval(() => {
      if (gatewayId) {
        const { from, to, expectedScaleMS } = getRange(scaleType?.type, chart);
        changeDataForRequestFromProps({
          from: from.toISOString(),
          to: to.toISOString(),
          scaleMS: expectedScaleMS
        });
        getChartDataFromProps({ gatewayId, from: from.toISOString(), to: to.toISOString() });
      }
    }, fiveMinutesInMS);

    return () => {
      workerTimers.clearInterval(chartDataInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleType, user]);

  const closeDatePicker = () => {
    const { closeModalWindow: closeModal } = props;
    closeModal('datePickerModal');
  };

  const openDatePicker = () => {
    const { openModalWindow: openModal } = props;
    openModal('datePickerModal', null);
  };

  const handleChangeCalendar = (date) => {
    closeDatePicker();
    const from = moment(date).startOf('day');
    const to = moment(date).endOf('day');
    getChartDataFromProps({ gatewayId, from: from.toISOString(), to: to.toISOString() });
    chartChangeRange(from, to, replaceFromProps, location.pathname);
    changeDataForRequestFromProps({
      from: from.toISOString(),
      to: to.toISOString(),
      scaleMS: dayInMS
    });
  };

  const zoomHandler = (xScale) => {
    chartChangeRange(xScale.min, xScale.max, replaceFromProps, location.pathname);
    changeScaleTypeFromProps({ type: 'range' });
    changeDataForRequestFromProps({
      from: new Date(xScale.min).toISOString(),
      to: new Date(xScale.max).toISOString(),
      scaleMS: xScale.max - xScale.min
    });
  };

  const min = new Date(chart.from).getTime();
  const max = new Date(chart.to).getTime();

  return (
    <>
      <TopBar
        visibleWeb
        goBackButton={!myself}
        pageInfo={!myself ? userName : false}
      />
      <LoadManagementCurrentValues />
      <LoadManagementChartControls
        scaleType={scaleType}
        location={location}
        openDatePicker={openDatePicker}
        realTimeData={realTimeData}
        intervalStr={formatLoadManagementInterval(chart.from, chart.to)}
        zoom
      />
      {
        user
          ? (
            <LoadManagementDashboardContainer
              min={min}
              max={max}
              zoomHandler={zoomHandler}
            />
          )
          : null
      }
      <CalendarPickerModal
        modalID="datePickerModal"
        handleOnClose={closeDatePicker}
        handleChange={handleChangeCalendar}
      />
    </>
  );
};

LoadManagementDashboard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  myUserID: PropTypes.string.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  requestInitUser: PropTypes.func.isRequired,
  getPhasesData: PropTypes.func.isRequired,
  getChartData: PropTypes.func.isRequired,
  receiveInitUser: PropTypes.func.isRequired,
  replace: PropTypes.instanceOf(Object).isRequired,
  changeDataForRequest: PropTypes.func.isRequired,
  changeScaleType: PropTypes.func.isRequired,
  sendIOTCommand: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  signInUser: PropTypes.instanceOf(Object).isRequired,
  chart: PropTypes.instanceOf(Object).isRequired,
  scaleType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

LoadManagementDashboard.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  user: userSelector,
  companyLogo: companyLogoSelector,
  signInUser: signInUserSelector,
  scaleType: scaleTypeSelector,
  chart: chartSelector,
  timezone: timezoneSelector
});

export default connect(mapStateToProps, {
  closeModalWindow,
  openModalWindow,
  requestInitUser,
  getPhasesData,
  getChartData,
  receiveInitUser,
  changeDataForRequest,
  replace,
  changeScaleType,
  sendIOTCommand
})(LoadManagementDashboard);
