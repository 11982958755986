import moment from 'moment-timezone';

const HOUR_IN_MS = 3600000;

export function* getStartOfPeriod(type, start, limitItems = 999, utcOffset) {
  let item = 1;
  const date = typeof utcOffset === 'number' ? moment(start).utcOffset(utcOffset) : moment(start);
  yield date.startOf(type).valueOf();
  while (item <= limitItems) {
    yield date.add('1', type).startOf(type).valueOf();
    item += 1;
  }
}

/**
 * Groups data (summary) by time periods (month, year)
 * @param data {array} Array of points: [timestamp, value]
 * @param type {string} 'year' | 'month' | 'day' | 'week'
 * @param start {number|string|Object} timestamp
 * @param limitItems
 * @param utcOffset {number} Moment.js utcOffset
 * @returns {array} grouped data
 */
export const groupData = (data, type, start, limitItems, utcOffset) => {
  const groupedData = [];
  const limitIterator = getStartOfPeriod(type, start, limitItems, utcOffset);
  let next = limitIterator.next();
  let current;
  let summary = 0;
  const dataLength = data.length;
  for (let i = 0; i < dataLength; i += 1) {
    const [timestamp, value] = data[i];

    if (!current || current.value <= timestamp) {
      if (next.value <= timestamp) {
        if (current && summary !== 0) {
          groupedData.push([
            current.value + HOUR_IN_MS, // Adding one hour to fix bug with daylight saving time
            summary
          ]);
          summary = 0;
        }
        current = next;
        next = limitIterator.next();
        i -= 1;
        if (next.done) break;
      } else {
        summary += value;
        if (i + 1 === dataLength && summary !== 0) {
          groupedData.push([
            current.value + HOUR_IN_MS, // Adding one hour to fix bug with daylight saving time
            summary
          ]);
        }
      }
    }
  }
  return groupedData;
};

/**
 * Serializes grouping
 * @param data {Object} Object of all the data
 * @param fromMS {number|string|Object}
 * @param chartType {'monthly'|'daily'}
 * @returns {Object} groupedData
 */
export const groupAllData = (data, fromMS, chartType) => {
  const dataKeys = Object.keys(data);
  if (chartType === 'monthly') {
    for (let i = 0; i < dataKeys.length; i += 1) {
      const dataKey = dataKeys[i];
      if (data[dataKey]) {
        data[dataKey] = groupData(data[dataKey], 'month', fromMS, 12);
      }
    }
  }
  if (chartType === 'daily') {
    for (let i = 0; i < dataKeys.length; i += 1) {
      const dataKey = dataKeys[i];
      if (data[dataKey]) {
        data[dataKey] = groupData(data[dataKey], 'day', fromMS, 60);
      }
    }
  }
  return data;
};

export default groupAllData;
