import { actionTypes } from '../constants';


export const fetchInvitedUsers = (userId) => ({
  type: actionTypes.GET_INVITED_USERS_REQUEST,
  payload: { userId }
});

export const fetchDeleteInvitedUser = (userId, viewerId) => ({
  type: actionTypes.DELETE_INVITED_USER_REQUEST,
  payload: { userId, viewerId }
});

export const fetchInviteUser = (email, userId) => ({
  type: actionTypes.INVITE_USER_REQUEST,
  payload: { email, userId }
});

export const setInvitedUsers = (invitedUsers) => ({
  type: actionTypes.SET_INVITED_USERS,
  payload: { invitedUsers }
});

export const setIsInvitedUsersLoading = (isInvitedUsersLoading) => ({
  type: actionTypes.SET_IS_INVITED_USERS_LOADING,
  payload: { isInvitedUsersLoading }
});

export const setIsInviteUserLoading = (isInviteUserLoading) => ({
  type: actionTypes.SET_IS_INVITE_USER_LOADING,
  payload: { isInviteUserLoading }
});

export const setIsDeletingInviteUserLoading = (isDeletingInviteUserLoading) => ({
  type: actionTypes.SET_IS_DELETE_INVITED_USER_LOADING,
  payload: { isDeletingInviteUserLoading }
});
