import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import RadioInput from './components/RadioInput';

class RadioButtons extends React.Component {
  componentDidMount() {
    const { input, defaultValue } = this.props;
    if (!input.value && typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, input } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      input.onChange(this.props.defaultValue);
    }
  }

  render() {
    const {
      input, variants, InputElement, ...custom
    } = this.props;

    const Input = InputElement || RadioInput;

    return (
      variants.map((variant) => (
        <Input
          {...variant}
          callback={input.onChange.bind(null, variant.value)}
          name={input.name}
          selected={variant.value === input.value}
          key={`radio_${input.name}_${variant.value}`}
          {...custom}
        />
      ))
    );
  }
}

RadioButtons.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  variants: PropTypes.instanceOf(Array).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  InputElement: PropTypes.func
};

RadioButtons.defaultProps = {
  defaultValue: undefined,
  InputElement: null
};

export default RadioButtons;
