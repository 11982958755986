import i18n from '../../../i18n';

/**
 * Gets unique types from devices list
 * @function getUniqueTypesList
 * @memberof module:UserDevices
 * @param {Object[]} devicesList Array of devices
 * @returns {Object[]} Array of unique types. Type is an Object: { value, label }.
 */
const getUniqueTypesList = (devicesList = []) => {
  const uniqueTypes = [];
  devicesList.forEach((device) => {
    if (uniqueTypes.indexOf(device.type) === -1) {
      uniqueTypes.push(device.type);
    }
  });
  uniqueTypes.forEach((type, index) => {
    uniqueTypes[index] = {
      value: type,
      label: i18n.t(type)
    };
  });
  return uniqueTypes;
};

export default getUniqueTypesList;
