import React from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 * @ignore
 */
import ModalWindow from '../../containers/ModalWindow';

/**
 * SVG
 * @ignore
 */
import warningIcon from '../../assets/images/svg/warning.svg';

/**
 * Internationalization
 * @ignore
 */
import i18n from '../../i18n';

/**
 * Styles
 * @ignore
 */
import './index.scss';
import CancelButton from '../UIKit/CancelButton';

/**
 * Renders creating new user note window
 * @memberof module:ModalWindow
 */
const CreatingNewUserNoteWindow = (props) => {
  const {
    toggleModal,
    modalID
  } = props;

  return (
    <ModalWindow
      customClass="creatingNewUserNote"
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">
          <img
            src={warningIcon}
            alt="Warning icon"
            className="creating-new-user-note-warning-icon"
          />
          {i18n.t('creatingNewUserNoteTitle')}
        </h5>
      </div>
      <div className="modal-body">
        <div className="text-confirmation">
          {i18n.t('creatingNewUserNoteContent')}
        </div>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={toggleModal.bind(this, 0)} i18nLabel="goToSelfRegistration" />
        <button
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
          onClick={toggleModal.bind(this, 1)}
        >
          {i18n.t('imAnInstaller')}
        </button>
      </div>
    </ModalWindow>
  );
};

CreatingNewUserNoteWindow.propTypes = {
  modalID: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default CreatingNewUserNoteWindow;
