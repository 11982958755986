import * as actionTypes from './constants';


export function dataListSendRequest(listID, dataList, showNotification = true) { // epic , reducer
  return {
    type: actionTypes.DATA_LIST_SEND_REQUEST,
    payload: {
      listID,
      dataList,
      showNotification
    }
  };
}

export function dataListReloadData(listID, result, isSubSensorUrl) {
  return {
    type: actionTypes.DATA_LIST_RELOAD_DATA,
    payload: {
      listID,
      isSubSensorUrl
    }
  };
}


export function attachSubSensors(listID, listURL, data, parentSensorUrl) {
  return {
    type: actionTypes.ATTACH_SUB_SENSORS,
    payload: {
      listID,
      listURL,
      data,
      parentSensorUrl
    }
  };
}

export function dataListReceived(listID, listURL, { data, total }) { // reducer
  return {
    type: actionTypes.DATA_LIST_RECEIVED,
    payload: {
      listID,
      listURL,
      data,
      total
    }
  };
}
export function dataListGotError(listID, listURL, errorMessage) { // reducer
  return {
    type: actionTypes.DATA_LIST_GOT_ERROR,
    payload: {
      listID,
      listURL,
      errorMessage
    }
  };
}
export function dataListClean(listID) { // reducer
  return {
    type: actionTypes.DATA_LIST_CLEAN,
    payload: {
      listID
    }
  };
}
export function dataListUpdateRow(listID, rowID, updates) {
  return {
    type: actionTypes.DATA_LIST_UPDATE_ROW,
    payload: {
      updates,
      listID,
      rowID
    }
  };
}

export function changeSupportContractUsers(listID, supportContractFlag) {
  return {
    type: actionTypes.CHANGE_SUPPORT_CONTRACT_USERS,
    payload: {
      listID,
      supportContractFlag
    }
  };
}

export function changeInstallationCompletedUsers(listID, isInstallationCompleted) {
  return {
    type: actionTypes.CHANGE_INSTALLATION_COMPLETED_USERS,
    payload: {
      listID,
      isInstallationCompleted
    }
  };
}
