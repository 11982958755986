import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import { modalsSelector } from './selectors';
import i18n from '../../i18n';
import CancelButton from '../UIKit/CancelButton';

import './index.scss';

const QRCodeScanner = (props) => {
  const {
    handleOnClose,
    modalID,
    scanQR
  } = props;

  const QRCompRef = useRef();
  const modals = useSelector(modalsSelector);

  // const openImageDialog = () => {
  //   if (QRCompRef.current) {
  //     QRCompRef.current.openImageDialog();
  //   }
  // };

  const handleError = (err) => {
    console.error(err);
    handleOnClose();
  };

  const modal = modals?.[modalID];

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <CancelButton onClickHandler={handleOnClose} customButtonClass="QR-code-header-button" />
        <h5 className="modal-title">
          {i18n.t('QRLink')}
        </h5>
      </div>
      <div className="modal-body">
        {modal?.opened
          ? (
            <QrReader
              ref={QRCompRef}
              delay={300}
              onError={handleError}
              onScan={scanQR}
              className="QRCodeScanner"
              style={{ width: '100%', marginBottom: '30px' }}
            />
          )
          : (
            null
          )
        }
      </div>
    </ModalWindow>
  );
};

QRCodeScanner.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  scanQR: PropTypes.func.isRequired
};

export default QRCodeScanner;
