import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import strapi from '../../strapi';
import { REQUEST_OVERVIEW_DATA } from './constants';
import { addNotification } from '../NotificationGenerator/actions';
import { receiveOverviewData } from './actions';


function overviewEpic($action) {
  return $action.pipe(
    ofType(REQUEST_OVERVIEW_DATA),
    map((action) => action.payload),
    switchMap(() => from(strapi.request('get', '/../overview')).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(
            addNotification({
              type: 'error',
              text: result.message
            })
          );
        }

        return of(receiveOverviewData(result));
      })
    )
    )
  );
}

export default combineEpics(overviewEpic);
