import React from 'react';
import PropTypes from 'prop-types';

import AddDeviceWindow from './AddDeviceWindow';
import AddLoadGuard from './AddLoadGuard';

const AddDeviceWindowHoc = (props) => {
  const { deviceType } = props;
  const modal = {
    devices: <AddDeviceWindow {...props} />,
    inverters: <AddDeviceWindow {...props} />,
    'smart-meter': <AddDeviceWindow {...props} />,
    'sub-meter': <AddDeviceWindow {...props} />,
    'input-devices': <AddDeviceWindow {...props} />,
    loadguards: <AddLoadGuard {...props} />,
    default: null
  };
  return modal[deviceType] || modal.default;
};

AddDeviceWindowHoc.defaultProps = {
  deviceType: PropTypes.string.isRequired
};

export default AddDeviceWindowHoc;
