import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import waterHeaterIcon from '../../../../../assets/images/svg/water-heater.svg';
import waterHeaterBlueIcon from '../../../../../assets/images/svg/water-heater-blue.svg';
import waterHeaterRedIcon from '../../../../../assets/images/svg/water-heater-red.svg';

/**
 *  water temperature after which icon will change
 */
const waterHeaterTempForHotIcon = 55;

/**
 *  water temperature before which icon will change
 */
const waterHeaterTempForColdIcon = 40;

const renderWaterHeaterIcon = (temp) => {
  if (+temp > waterHeaterTempForHotIcon) {
    return waterHeaterRedIcon;
  }

  if (+temp < waterHeaterTempForColdIcon) {
    return waterHeaterBlueIcon;
  }

  return waterHeaterIcon;
};

const waterHeater1StepOnOffDeviceGroup = '1StepOnOff';
const waterHeater2StepControlDeviceGroup = '2StepControl';
const waterHeater3StepControlDeviceGroup = '3StepControl';

const showWaterHeaterCurrentPower = [
  'ASKOHEAT SM',
  'myPV AC THOR',
  'MyPV ELWA-E',
  'Ohmpilot',
  'EGO Smart Heater Ethernet',
  'ZZZ WW'
];

const showWaterHeaterCurrentPower1StepOnOff = [1, 4, 6];
const showWaterHeaterCurrentPower2StepControl = [1];
const showWaterHeaterCurrentPower3StepControl = [1];

const showWaterHeaterCurrentPercentOn = ['WUT_IO_0_10V'];

const showWaterHeaterCurrentPercentOn1StepOnOff = [0, 2, 3, 5, 7, 8, 9, 10, 11];
const showWaterHeaterCurrentPercentOn2StepControl = [0, 2, 3, 4, 5];
const showWaterHeaterCurrentPercentOn3StepControl = [0, 2, 3];

const WaterHeaterTooltip = (props) => {
  const {
    currentWaterTemp,
    convertedCurrentPower,
    currentPercentOn,
    consumedForLast24h,
    device_group,
    warmWaterDevices,
    warmWaterDevices2Step,
    warmWaterDevices3Step
  } = props;

  const isShowWaterHeaterCurrentPower = showWaterHeaterCurrentPower.includes(device_group)
    || (device_group === waterHeater1StepOnOffDeviceGroup
      && showWaterHeaterCurrentPower1StepOnOff.includes(warmWaterDevices))
    || (device_group === waterHeater2StepControlDeviceGroup
      && showWaterHeaterCurrentPower2StepControl.includes(warmWaterDevices2Step))
    || (device_group === waterHeater3StepControlDeviceGroup
      && showWaterHeaterCurrentPower3StepControl.includes(warmWaterDevices3Step));

  const isShowWaterHeaterCurrentPercentOn = showWaterHeaterCurrentPercentOn.includes(device_group)
    || (device_group === waterHeater1StepOnOffDeviceGroup
      && showWaterHeaterCurrentPercentOn1StepOnOff.includes(warmWaterDevices))
    || (device_group === waterHeater2StepControlDeviceGroup
      && showWaterHeaterCurrentPercentOn2StepControl.includes(warmWaterDevices2Step))
    || (device_group === waterHeater3StepControlDeviceGroup
      && showWaterHeaterCurrentPercentOn3StepControl.includes(warmWaterDevices3Step));

  return (
    <>
      <img
        src={
          Number.isFinite(currentWaterTemp)
            ? renderWaterHeaterIcon(currentWaterTemp)
            : waterHeaterBlueIcon
        }
        alt="Water heater Icon"
      />
      <div className="sensorData-title">
        <span>{i18n.t('waterTemp')}</span>

        {isShowWaterHeaterCurrentPower && <span>{i18n.t('currentPowerForDevice')}</span>}
        {isShowWaterHeaterCurrentPercentOn && <span>{i18n.t('switchState')}</span>}
        <span>{i18n.t('last24h')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {currentWaterTemp || defaultValue}
          °C
        </span>

        {isShowWaterHeaterCurrentPower && (
          <span>
            {convertedCurrentPower}
            {i18n.t('kWPeakOutputUnits')}
          </span>
        )}
        {isShowWaterHeaterCurrentPercentOn && (
        <span>
          {currentPercentOn || defaultValue}
          %
        </span>
        )}
        <span>
          {consumedForLast24h || defaultValue}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

WaterHeaterTooltip.propTypes = {
  currentWaterTemp: PropTypes.number,
  currentPercentOn: PropTypes.number,
  consumedForLast24h: PropTypes.number,
  device_group: PropTypes.string.isRequired,
  warmWaterDevices: PropTypes.number,
  warmWaterDevices2Step: PropTypes.number,
  warmWaterDevices3Step: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

WaterHeaterTooltip.defaultProps = {
  warmWaterDevices3Step: undefined,
  warmWaterDevices2Step: undefined,
  consumedForLast24h: undefined,
  currentWaterTemp: undefined,
  currentPercentOn: undefined,
  warmWaterDevices: undefined
};

export default WaterHeaterTooltip;
