import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sendRequest } from './actions';
import SemiTabs from '../Monitoring/components/SemiTabs';
import TopBar from '../../components/TopBar';
import RadioButtons from '../../components/ReduxFormFields/RadioButtons';
import Switcher from '../../components/ReduxFormFields/Switcher';
import Checkbox from '../../components/ReduxFormFields/Checkbox';

import i18n from '../../i18n';
import {
  defaultUserNotificationSelector,
  formValuesSelector,
  myRoleTypeSelector,
  responseNotificationSelector,
  visibleWebSelector
} from './selectors';

import './index.css';

/**
 * Renders NotificationMonitoring page
 * @memberof module:NotificationMonitoring
 */
const MonitoringNotifications = (props) => {
  const {
    defaultUserNotification,
    responseNotification,
    visibleWeb,
    myRoleType,
    sendRequest: sendRequestProps,
    formValues
  } = props;

  const notificationObject = Object.keys(responseNotification).length
    ? responseNotification
    : defaultUserNotification;

  useEffect(() => {
    if (Object.keys(formValues).length) {
      sendRequestProps(formValues);
    }
  }, [formValues, sendRequestProps]);

  return (
    <div className="main-table-container">
      <TopBar
        updateButton
        showNavigation
        visibleWeb={visibleWeb}
      />
      <div className="container-fluid">
        <div className="subheader-smu-table">
          <span className="emp-span">
            <i className="fa fa-home icons-employees" />
            {' '}
            -
            {' '}
            {i18n.t('monitoring')}
          </span>
        </div>
        <div className="nav-monitoring">
          <SemiTabs />
        </div>
        <form id="monitoringNotifications">
          <div className="notification-switch-container">
            <div className="notification-switch-field m--margin-top-50">
              <h6 className="set-prof-head m--margin-right-20">{i18n.t('emailNotification')}</h6>
              <Field
                name="isAllowed"
                component={Switcher}
                props={{
                  defaultValue: notificationObject.isAllowed ? notificationObject.isAllowed : false,
                  width: 50,
                  height: 25,
                  onColor: '#ffb008',
                  className: 'switcher'
                }}
              />
            </div>
          </div>
          <div className="notification-frequency-container m--margin-top-50">
            <h6 className="freq-text">{i18n.t('frequencyOfSendingEmail')}</h6>
            <Field
              name="frequency"
              component={RadioButtons}
              props={{
                defaultValue: notificationObject.frequency
                  ? notificationObject.frequency
                  : 'monthly',
                variants: [
                  {
                    label: <span className="white dataSMID">{i18n.t('daily')}</span>,
                    value: 'daily'
                  },
                  {
                    label: <span className="white dataSMID">{i18n.t('weekly')}</span>,
                    value: 'weekly'
                  },
                  {
                    label: <span className="white dataSMID">{i18n.t('monthly')}</span>,
                    value: 'monthly'
                  }
                ]
              }}
            />
            {['oem', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only'].includes(myRoleType) ? (
              <div className="every-new-error">
                <Field
                  type="checkbox"
                  name="everyNewError"
                  component={Checkbox}
                  defaultValue={notificationObject?.everyNewError || false}
                  labelClass="every-new-error-label"
                  label={i18n.t('everyNewError')}
                />
              </div>
            ) : (
              null
            )}
          </div>
          <div className="notification-type-error-container m--margin-top-60">
            <h6 className="freq-text">{i18n.t('whatErrorsShouldBeReceived')}</h6>
            <Field
              name="receiveAssignedErrors"
              component={RadioButtons}
              props={{
                defaultValue: notificationObject.receiveAssignedErrors
                  ? notificationObject.receiveAssignedErrors
                  : false,
                variants: [
                  {
                    label: <span className="white dataSMID">{i18n.t('allErrors')}</span>,
                    value: false
                  },
                  {
                    label: <span className="white dataSMID">{i18n.t('onlyErrors')}</span>,
                    value: true
                  }
                ]
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

MonitoringNotifications.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object),
  defaultUserNotification: PropTypes.instanceOf(Object),
  responseNotification: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string,
  visibleWeb: PropTypes.bool
};

MonitoringNotifications.defaultProps = {
  formValues: {},
  defaultUserNotification: {},
  responseNotification: {},
  visibleWeb: undefined,
  myRoleType: undefined
};

const form = reduxForm({
  form: 'notificationForm'
})(MonitoringNotifications);

const mapStateToProps = createStructuredSelector({
  formValues: formValuesSelector,
  defaultUserNotification: defaultUserNotificationSelector,
  myRoleType: myRoleTypeSelector,
  responseNotification: responseNotificationSelector,
  visibleWeb: visibleWebSelector
});

export default connect(mapStateToProps, {
  sendRequest
})(form);
