import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleError, handleErrorDetailed, trimAllFields } from '../../api_helper';
import * as SignInTypes from './constants';
import strapi from '../../strapi';
import { addNotification } from '../NotificationGenerator/actions';
import { signUpVerifySucc, signUpVerifyError, signUpShowStopMessage } from './actions';
import { receiveSignIn } from '../SignIn/actions';

function signUp2VerifyEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.SIGNUP2_VERIFY),
    map((action) => action.payload),
    switchMap(({ verifyCode, language }) => (
      from(strapi.request('post', '/../signup/verify', {
        data: {
          code: verifyCode,
          language
        },
        params: {
          language
        }
      })).pipe(
        catchError(handleError),
        mergeMap(
          (result) => {
            if (result?.email) {
              return of(
                signUpVerifySucc(result, verifyCode)
              );
            }

            return of(
              signUpVerifyError(result),
              addNotification({
                type: 'error',
                text: result
              })
            );
          }
        )
      )
    ))
  );
}
function signUp2SendEpic(action$) {
  return action$.pipe(
    ofType(SignInTypes.SIGNUP2_SEND),
    map((action) => action.payload.fields),
    switchMap((fields) => (
      from(strapi.request('post', '/../signup/complete', {
        data: {
          ...trimAllFields(fields),
          email: undefined,
          pv_installer: undefined,
          'rep-password': undefined
        },
        params: {
          language: fields.language
        }
      })).pipe(
        catchError(handleErrorDetailed),
        mergeMap(
          (result) => {
            if (result && !result.error) {
              if (result.jwt) {
                strapi.setToken(result.jwt);

                return of(
                  receiveSignIn(result.user, false)
                );
              }

              return of(
                signUpShowStopMessage(result.message)
              );
            }

            return of(
              addNotification({
                type: 'error',
                text: result
              })
            );
          }
        )
      )
    ))
  );
}


export default combineEpics(
  signUp2VerifyEpic, signUp2SendEpic
);
