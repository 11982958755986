const PIXEL_BUFFER = 2;

/**
 * Calculates delay of updates for realtime chart.
 * @param plotWidth {number} Pixel width of plot area of the chart.
 * @param timeDistance {number} Range of viewed data (milliseconds).
 * @returns {number} Delay in milliseconds.
 */
function calculateDelay(plotWidth, timeDistance) {
  const timePerPixel = timeDistance / plotWidth;
  return timePerPixel * PIXEL_BUFFER;
}

export default calculateDelay;
