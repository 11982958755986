import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders signle button for react table
 * @memberof module:ReactTable
 */
const renderSingleButton = ({ callback, title }) => (row) => {
  const { original: { _id, sm_id } } = row;

  return (
    <Fragment>
      <a
        onClick={callback.bind(null, _id, sm_id)}
        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp send-log-request"
      >
        {title}
      </a>
    </Fragment>
  );
};

renderSingleButton.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default renderSingleButton;
