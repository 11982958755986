import { createSelector } from 'reselect';

const getSendSuccessfull = (state) => state.forgotPassword.sendSuccessfull;
const getEmail = (state) => state.forgotPassword.email;
const getVerifyEmail = (state) => state.forgotPassword.verifyEmail;
const getCounter = (state) => state.forgotPassword.counter;
const getIsLoading = (state) => state.forgotPassword.isLoading;

export const sendSuccessfullSelector = createSelector([getSendSuccessfull], (sendSuccessfull) => sendSuccessfull);
export const emailSelector = createSelector([getEmail], (email) => email);
export const verifyEmailSelector = createSelector([getVerifyEmail], (verifyEmail) => verifyEmail);
export const counterSelector = createSelector([getCounter], (counter) => counter);
export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);
