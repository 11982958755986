import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment-timezone';
import 'chartjs-adapter-moment';
import i18n from '../../../i18n';
import './style.scss';
import '../../Dashboard/index.scss';

const chartGridColor = '#353535';
const subLabelTextColor = 'rgba(198, 200, 210, 0.46)';
const rangeAreaFillBackgroundColor = 'rgba(255, 184, 34, .35)';
const tickLabelColor = '#C6C8D2';
const predictionLineColor = 'rgba(255, 184, 34, 1)';

const PredictionChart = ({ expected, ranges }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const startRange = ranges.map((item) => [item[0], item[1]]);
  const endRange = ranges.map((item) => [item[0], item[2]]);

  const subLabels = {
    id: 'subLabels',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { bottom },
        scales: { x }
      } = chart;

      ctx.save();
      const now = moment().valueOf();
      const afterAfterTomorrow = moment(now).add(3, 'days').toDate();
      const subLabelText = (text, tick) => {
        ctx.font = '9pt Poppins';
        ctx.fillStyle = subLabelTextColor;
        ctx.textAlign = 'center';
        ctx.fillText(text, x.getPixelForTick(tick), bottom + 35);
      };

      const indexesForSubLabel = [];

      if (
        moment(x.ticks[0]?.value).day() === moment(now).day()
        || moment(x.ticks[0]?.value).day() === moment(now).add(1, 'day').day()
      ) {
        indexesForSubLabel.push(0);
      }

      for (let i = 1; i < x.ticks.length; i += 1) {
        if (moment(x.ticks[i - 1]?.value).day() !== moment(x.ticks[i]?.value).day()) {
          indexesForSubLabel.push(i);
        }
      }

      if (indexesForSubLabel.length === 4) {
        subLabelText(i18n.t('today'), indexesForSubLabel[0]);
        subLabelText(i18n.t('tomorrow'), indexesForSubLabel[1]);
        subLabelText(i18n.t('afterTomorrow'), indexesForSubLabel[2]);
        subLabelText(moment(afterAfterTomorrow).format('DD-MM-YYYY'), indexesForSubLabel[3]);
      }

      if (indexesForSubLabel.length === 3) {
        subLabelText(i18n.t('tomorrow'), indexesForSubLabel[0]);
        subLabelText(i18n.t('afterTomorrow'), indexesForSubLabel[1]);
        subLabelText(moment(afterAfterTomorrow).format('DD-MM-YYYY'), indexesForSubLabel[2]);
      }
    }
  };

  return (
    <div className="dashboardApp">
      <div className="chartFlexItem">
        <Line
          data={{
            labels: [],
            datasets: [
              {
                borderColor: 'transparent',
                data: endRange,
                fill: 1,
                backgroundColor: rangeAreaFillBackgroundColor
              },
              {
                borderColor: predictionLineColor,
                data: expected,
                borderWidth: 1
              },
              {
                borderColor: 'transparent',
                data: startRange,
                fill: 1,
                backgroundColor: rangeAreaFillBackgroundColor
              }
            ]
          }}
          options={{
            events: [],
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0.4
              }
            },
            plugins: {
              tooltip: {
                enabled: false
              },
              legend: {
                display: false
              }
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    hour: 'HH:mm'
                  }
                },
                ticks: {
                  padding: 10,
                  maxRotation: 0,
                  minRotation: 0,
                  color: tickLabelColor,
                  font: {
                    size: 14,
                    weight: 400,
                    family: "'Lucida Grande', 'Lucida Sans' 'Unicode', 'Arial', 'Helvetica', sans-serif"
                  },
                  /**
                   * @description Defines wether show the tick or not based on screen size and number of ticks available
                   * @param {string} value date time label in 'HH:MM' format
                   * @returns {string|null}
                   */
                  callback(value) {
                    // 72 ticks
                    if (windowWidth >= 5000) {
                      return value;
                    }
                    // 36 ticks
                    if (windowWidth >= 2500) {
                      return ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00'].includes(value) ? value : null;
                    }
                    // 24 ticks
                    if (windowWidth >= 1800) {
                      return ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00'].includes(value) ? value : null;
                    }
                    // 18 ticks
                    if (windowWidth >= 1350) {
                      return ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'].includes(value) ? value : null;
                    }
                    // 12 ticks
                    if (windowWidth >= 900) {
                      return ['00:00', '06:00', '12:00', '18:00'].includes(value) ? value : null;
                    }
                    // 9 ticks
                    if (windowWidth >= 675) {
                      return ['00:00', '08:00', '16:00'].includes(value) ? value : null;
                    }
                    // 6 ticks
                    if (windowWidth >= 450) {
                      return ['00:00', '12:00'].includes(value) ? value : null;
                    }
                    // 3 ticks
                    return ['00:00'].includes(value) ? value : null;
                  }
                },
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                  color() {
                    return chartGridColor;
                  }
                }
              },
              y: {
                ticks: {
                  padding: 10,
                  color: tickLabelColor,
                  font: {
                    size: 14,
                    weight: 400,
                    family: "'Lucida Grande', 'Lucida Sans' 'Unicode', 'Arial', 'Helvetica', sans-serif"
                  },
                  beginAtZero: true,
                  callback(value) {
                    return `${value / 1000} kW`;
                  }
                },
                grid: {
                  drawBorder: false,
                  drawTicks: false,
                  color() {
                    return chartGridColor;
                  }
                }
              }
            }
          }}
          plugins={[subLabels]}
        />
      </div>
    </div>
  );
};

PredictionChart.propTypes = {
  expected: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

PredictionChart.defaultProps = {
  expected: [],
  ranges: []
};

export default PredictionChart;
