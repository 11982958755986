import React, { useEffect } from 'react';
import PropTypes, {
  number, arrayOf, func, bool
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Chart from './components/Chart';
import { dataListSendRequest } from '../DataList/actions';
import Preloader from '../../components/Preloader';
import { getLoadingStatus, getPredictionData, getTimezone } from './selectors/index';
import HelmetConfig from '../../components/HelmetConfig';
import PredictionError from '../../components/PredictionError';
import { DATA_LIST_ID } from './constants';
import './style.scss';

const PredictionChart = ({
  ranges, expected, isLoading, dataListSendRequest: request, timezone
}) => {
  useEffect(
    () => {
      moment.tz.setDefault(timezone);

      return () => {
        moment.tz.setDefault();
      };
    },
    [timezone]
  );

  useEffect(
    () => {
      request(
        DATA_LIST_ID,
        {
          listURL: 'prediction',
          method: 'GET'
        },
        false
      );
    },
    [request]
  );

  if (isLoading) {
    return (
      <HelmetConfig>
        <Preloader />
      </HelmetConfig>
    );
  }

  if (expected.length === 0 && ranges.length === 0) {
    return (
      <HelmetConfig>
        <PredictionError />
      </HelmetConfig>
    );
  }
  return (
    <HelmetConfig>
      <div className="prediction-chart-height">
        <Chart expected={expected} ranges={ranges} timezone={timezone} />
      </div>
    </HelmetConfig>
  );
};

PredictionChart.propTypes = {
  dataListSendRequest: func.isRequired,
  ranges: arrayOf(arrayOf(number)).isRequired,
  expected: arrayOf(arrayOf(number)).isRequired,
  isLoading: bool.isRequired,
  timezone: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  ...getPredictionData(state),
  isLoading: getLoadingStatus(state),
  timezone: getTimezone(state)
});

export default connect(
  mapStateToProps,
  { dataListSendRequest }
)(PredictionChart);
