import React from 'react';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import additionalFields from '../../../containers/DevicesTable/utils/additionalFields';
import { isAddModalFormOpenedSelector } from './selector';
import { addNotification } from '../../../containers/NotificationGenerator/actions';
import { setDeviceIdRtu } from '../../../containers/DevicesTable/actions';
import { modalStartLoading } from '../../../containers/ModalWindow/actions';

import './styles.scss';

/**
 * EditDeviceWindow form id
 */
const modalId = 'editDeviceForm';

/**
 * Renders groupe of device_id fields
 * @function DeviceIdRTU
 * @memberof module:RenderFieldsByDevName
 * @param {object} props
 * @param {boolean} props.isAddModalFormOpened boolean value if add device modal is open
 * @param {Function} props.addNotification action to show notification modal
 */
const DeviceIdRTU = (props) => {
  const {
    isAddModalFormOpened,
    addNotification: addNotificationFromProps,
    setDeviceIdRtu: setDeviceIdRtuAC,
    modalStartLoading: modalStartLoadingAC,
    sensorId,
    value
  } = props;

  /**
   * Check if modal that opened is 'addDeviceForm' - show error,
   * otherwise if 'editDeviceForm' - change Device_ID and Device_ID_RTU fields
   */
  const onClickSetIdHandler = () => {
    if (isAddModalFormOpened) {
      addNotificationFromProps({
        type: 'error',
        text: i18n.t('setIdErrorMessage')
      });
    }
    if (sensorId) {
      modalStartLoadingAC(modalId);
      setDeviceIdRtuAC({ sensorId, value, modalId });
    }
  };

  return (
    <div className="form-group m-form__group input-field device-id-rtu">
      <Field
        name="Device_ID_RTU"
        {...additionalFields.Device_ID_RTU}
        label={i18n.t(additionalFields.Device_ID_RTU.label)}
        showTooltip=""
        className="device-id-RTU-field"
      />
      <div className="container-of-button-and-tooltip">
        <button
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right button-on-click-set-Id"
          onClick={onClickSetIdHandler}
        >
          {i18n.t('Set ID')}
        </button>
        <div className="tooltip-set-id-RTU-container">
          <Tooltip title={i18n.t(additionalFields.Device_ID_RTU.showTooltip)} placement="left" />
        </div>
      </div>
    </div>
  );
};

DeviceIdRTU.propTypes = {
  value: PropTypes.string,
  sensorId: PropTypes.string,
  setDeviceIdRtu: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  modalStartLoading: PropTypes.func.isRequired,
  isAddModalFormOpened: PropTypes.bool
};

DeviceIdRTU.defaultProps = {
  value: '',
  sensorId: '',
  isAddModalFormOpened: false
};

const mapStateToProps = createStructuredSelector({
  isAddModalFormOpened: isAddModalFormOpenedSelector
});

export default connect(mapStateToProps, {
  addNotification,
  setDeviceIdRtu,
  modalStartLoading
})(DeviceIdRTU);
