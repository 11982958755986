import React from 'react';
import { Field } from 'redux-form';
import i18n from '../../../../../i18n';
import formatDateTime from '../../../../../utils/formatDateTime';
import Tooltip from '../../../../../components/Tooltip';
import Checkbox from '../../../../../components/ReduxFormFields/Checkbox';
import { EMPLOYEE_ROLE_TYPES } from '../../../constants';

const allowedRolesToDisplaySupportContract = [
  'root',
  'solar_admin',
  'pv_installer',
  ...EMPLOYEE_ROLE_TYPES,
  'oem',
  'support',
  'viewer'
];
const allowedRolesToUpdateInstallationCompleted = [
  'root',
  'solar_admin',
  'oem',
  'pv_installer',
  'pv_installer_employee',
  'support'
];
const allowedRolesToUpdateSupportContract = [
  'root',
  'solar_admin',
  'pv_installer',
  'pv_installer_employee',
  'oem',
  'support'
];

export const getColumns = ({
  user = {},
  myRoleType,
  handleSupportContractsChange,
  isSupportContractFlagAllowedToBeChanged,
  handleInstallationFinishedChange
}) => {
  const { gateway = {} } = user;

  return [
    [
      {
        title: 'hardware',
        isAllowed: true,
        Value: () => <p>{gateway.hardware}</p>
      },
      {
        title: 'SMversion',
        isAllowed: true,
        Value: () => <p>{gateway.firmware}</p>
      },
      {
        title: 'date',
        isAllowed: gateway.firmware_update_date,
        Value: () => <p>{formatDateTime(gateway.firmware_update_date)}</p>
      },
      {
        title: 'createdAt',
        isAllowed: true,
        Value: () => <p>{formatDateTime(user.createdAt)}</p>
      }
    ],
    [
      {
        isAllowed: allowedRolesToDisplaySupportContract.includes(myRoleType),
        Value: () => (
          <div className="support-contracts-container m-0">
            <div>
              <div className="d-flex align-items-baseline support-contracts">
                <Field
                  name="support_contracts"
                  type="checkbox"
                  component={Checkbox}
                  label={i18n.t('supportContracts')}
                  labelClass="support-contracts"
                  onChange={handleSupportContractsChange}
                  disabled={!allowedRolesToUpdateSupportContract.includes(myRoleType) || !isSupportContractFlagAllowedToBeChanged}
                />
                <div>
                  <Tooltip title={i18n.t('supportContractsTooltip')} />
                </div>
              </div>
              {!isSupportContractFlagAllowedToBeChanged
              && (
              <span className="support-contracts-info-text m-0">
                {i18n.t('descriptionForDisabledSupportContract')}
              </span>
              )}
            </div>
          </div>

        )
      },
      {
        isAllowed: allowedRolesToDisplaySupportContract.includes(myRoleType),
        Value: () => (
          <div className="support-contracts-container m-0">
            <div>
              <div className="d-flex align-items-baseline support-contracts">
                <Field
                  name="isInstallationCompleted"
                  type="checkbox"
                  component={Checkbox}
                  label={i18n.t('installationFinished')}
                  labelClass="support-contracts"
                  onChange={handleInstallationFinishedChange}
                  disabled={!allowedRolesToUpdateInstallationCompleted.includes(myRoleType)}
                />
                <div>
                  <Tooltip title={i18n.t('installationStageTooltip')} />
                </div>
              </div>
            </div>
          </div>

        )
      }
    ]
  ];
};
