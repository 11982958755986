import { RECEIVE_OVERVIEW_DATA, REQUEST_OVERVIEW_DATA } from './constants';

export function requestOverviewData() {
  return {
    type: REQUEST_OVERVIEW_DATA
  };
}

export function receiveOverviewData(data) {
  return {
    type: RECEIVE_OVERVIEW_DATA,
    payload: {
      data
    }
  };
}
