import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-table/react-table.css';

import Logo from './Logo';
import Table from './Table';
import i18n from '../../../i18n';

const SupportInfo = ({ data, loading }) => {
  const {
    first_name = '',
    last_name = ''
  } = data;
  if (loading) {
    return (
      <div className="whiteStatusText">
        {i18n.t('load')}
        ...
      </div>
    );
  }
  if (!data) {
    return <div className="whiteStatusText">{i18n.t('companyNotFound')}</div>;
  }

  const fullName = `${first_name}${first_name ? ' ' : ''}${last_name}`;
  const { country, phone, status, zip, street, email, city } = data;

  const tableData = [
    [
      {
        label: i18n.t('fName'),
        value: fullName
      },
      {
        label: i18n.t('street'),
        value: street
      }
    ],
    [
      {
        label: i18n.t('mail'),
        value: email,
        type: 'email'
      },
      {
        label: i18n.t('city'),
        value: city
      }
    ],
    [
      {
        label: i18n.t('phone'),
        value: phone && country ? formatPhoneNumberIntl(`+${phone}`) : phone || ''
      },
      {
        label: i18n.t('zip'),
        value: zip
      }
    ],
    [
      {
        label: i18n.t('status'),
        value: i18n.t(status),
        type: 'status'
      }
    ]
  ];

  return (
    <div className="container-fluid">
      <div className="m-portlet--mobile">
        <div className="subheader-smu-table">
          <span className="emp-span">
            <i className="fa fa-home icons-employees" />
            {' '}
            -
            {' '}
            <Link to="/supports" className="br-crump">
              {i18n.t('Support')}
            </Link>
            {' '}
            -
            {' '}
            <span>{fullName}</span>
          </span>
        </div>
      </div>
      <div className="container-fluid" style={{ marginBottom: '15px' }}>
        <div className="row align-items-center">
          <div className="company-logo avatar-logo col-auto">
            <Logo
              url={data?.avatar?.url || false}
              name={fullName}
            />
          </div>
          <div className="col-auto mr-auto">
            <Table data={tableData} />
          </div>
          <div className="col-auto align-self-start">
            <Link
              title={i18n.t('editProfile')}
              to={`../setting-profile/support/${data.id}/`}
              className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
            >
              <i className="la la-edit" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

SupportInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool
};

SupportInfo.defaultProps = {
  loading: undefined,
  data: {}
};

export default SupportInfo;
