import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import DataList, { dataListReloadData } from '../../containers/DataList';
import i18n from '../../i18n';
import store from '../../store';
import { closeModalWindow, modalWindowSend } from '../../containers/ModalWindow/actions';
import RadioButtons from '../ReduxFormFields/RadioButtons';
import LicenseElement from './LicenseElement';
import selectLicenses from './selectors/selectLicenses';

import {
  FORM_ID, MODAL_ID, DATA_LIST_ID, DATA_LIST_URL
} from './constants';
import { CancelButton } from '../UIKit';
/**
 * @const {array<string>}
 * @name helionLicenseId
 * @description helionLicenseId array of Helion licenses id, if the licenseId is in the array, you need to hide this license from the modal window
 */
const helionLicenseId = ['10', '11', '12'];

/**
 * Renders modal window for choosing user or installer license
 */
class ChooseLicenseWindow extends React.Component {
  constructor(props) {
    super(props);
    this.license = React.createRef();
  }

  afterOpen = (nextProps) => {
    const { initialize } = this.props;
    const license = nextProps?.modal?.data?.license;
    const licenseCode = nextProps?.modal?.data?.licenseCode;
    initialize({
      license: license || licenseCode
    });
  };

  afterClose = () => {
    const { reset } = this.props;
    reset();
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { license } = this.props;
    const userId = this.props?.modal?.data?.userId;
    const parentListId = this.props?.modal?.data?.parentListId;
    const gatewayId = this.props?.modal?.data?.gatewayId;

    store.dispatch(
      modalWindowSend(
        MODAL_ID,
        {
          method: 'put',
          url: userId ? `/../user/change-license/${userId}` : `/gateway/license/${gatewayId}`,
          data: {
            newLicense: license
          }
        },
        () => dataListReloadData(parentListId)
      )
    );
  };

  onClose = () => {
    store.dispatch(
      closeModalWindow(MODAL_ID)
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { modal } = this.props;
    const { modal: nextModal } = nextProps;
    if (modal && (!nextModal || (modal.opened && !nextModal.opened))) {
      this.afterClose();
    } else if ((!modal || !modal.opened) && (nextModal?.opened)) {
      this.afterOpen(nextProps);
    }
  }

  render() {
    const { pristine, licenses } = this.props;
    const filteredLicenses = licenses.filter((license) => !helionLicenseId.includes(license.value));

    return (
      <div className="wideModal">
        <ModalWindow
          modalID={MODAL_ID}
        >
          <DataList
            listID={DATA_LIST_ID}
            listURL={DATA_LIST_URL}
            forceKeepData
          />
          <div className="modal-header">
            <h5 className="modal-title">{i18n.t('License options')}</h5>
          </div>
          <div
            className="modal-body"
            style={{
              paddingTop: '35px'
            }}
          >
            <form
              id="addUserAdm"
              onSubmit={this.onSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <Field
                name="license"
                component={RadioButtons}
                props={{
                  variants: filteredLicenses,
                  InputElement: LicenseElement
                }}
                ref={(node) => { this.license = node; }}
              />
            </form>
          </div>
          <div
            className="modal-footer"
            style={{
              borderTop: '1px solid #40424d',
              paddingTop: '25px',
              marginTop: '25px'
            }}
          >
            <CancelButton onClickHandler={this.onClose} />
            <button
              form="addUserAdm"
              disabled={pristine}
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              <i className="fa fa-save" />
              {i18n.t('save')}
            </button>
          </div>
        </ModalWindow>
      </div>
    );
  }
}

ChooseLicenseWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  modal: PropTypes.instanceOf(Object),
  licenses: PropTypes.arrayOf(Object),
  license: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  gatewayId: PropTypes.string
};

ChooseLicenseWindow.defaultProps = {
  modal: {
    data: {}
  },
  licenses: [],
  gatewayId: undefined,
  license: ''
};

const form = reduxForm({
  form: FORM_ID
})(ChooseLicenseWindow);

const selectValue = formValueSelector(FORM_ID);

const mapStateToProps = (state) => ({
  licenses: selectLicenses(state),
  modal: state.modals?.[MODAL_ID],
  license: selectValue(state, 'license')
});

export default connect(mapStateToProps)(form);
