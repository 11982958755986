import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import PieChart from './PieChart';
import './index.scss';

const OverviewPieCharts = (props) => {
  const { monitoring, status } = props;
  const { installationOngoing, connected, notConnected: notConnectedStatus } = status || {};
  const { error, notConnected: notConnectedMonitoring, ok, warning } = monitoring || {};

  const statusData = {
    datasets: [
      {
        data: [installationOngoing, notConnectedStatus, connected],
        backgroundColor: ['#3998F9', '#DB422D', '#4CCD37'],
        borderWidth: 0
      }
    ]
  };

  const monitoringData = {
    datasets: [
      {
        data: [ok, warning, error, notConnectedMonitoring],
        backgroundColor: ['#1BBC9B', '#FF9E22', '#F66400', '#DB422D'],
        borderWidth: 0
      }
    ]
  };

  return (
    <div className="overview-pie-charts-wrapper">
      <div className="status-pie-chart">
        <div className="title">{i18n.t('status')}</div>
        <PieChart data={statusData} />
        <div className="additional-info">
          <div className="info-item">
            <div className="circle-marker installation-ongoing" />
            <div className="value">{installationOngoing}</div>
            <div className="name">{i18n.t('installationOngoing')}</div>
          </div>
          <div className="info-item">
            <div className="circle-marker not-connected" />
            <div className="value">{notConnectedStatus}</div>
            <div className="name">{i18n.t('not connected')}</div>
          </div>
          <div className="info-item">
            <div className="circle-marker connected" />
            <div className="value">{connected}</div>
            <div className="name">{i18n.t('connected')}</div>
          </div>
          <div className="empty" />
        </div>
      </div>
      <div className="monitoring-pie-chart">
        <div className="title">{i18n.t('monitoring')}</div>
        <PieChart data={monitoringData} />
        <div className="additional-info">
          <div className="info-item">
            <div className="circle-marker ok" />
            <div className="value">{ok}</div>
            <div className="name">{i18n.t('ok')}</div>
          </div>
          <div className="info-item">
            <div className="circle-marker warning" />
            <div className="value">{warning}</div>
            <div className="name">{i18n.t('warning')}</div>
          </div>
          <div className="info-item">
            <div className="circle-marker error" />
            <div className="value">{error}</div>
            <div className="name">{i18n.t('error')}</div>
          </div>

          <div className="info-item">
            <div className="circle-marker not-connected" />
            <div className="value">{notConnectedMonitoring}</div>
            <div className="name">{i18n.t('not connected')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

OverviewPieCharts.propTypes = {
  monitoring: PropTypes.instanceOf(Object),
  status: PropTypes.instanceOf(Object)
};

OverviewPieCharts.defaultProps = {
  monitoring: undefined,
  status: undefined
};

export default OverviewPieCharts;
