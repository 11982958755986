import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { REQUEST_CLOUD_STATUS_MESSAGE } from './constants';
import { handleErrorDetailed } from '../../api_helper';

import { addNotification } from '../NotificationGenerator/actions';
import strapi from '../../strapi';
import { receiveCloudStatusMessage } from './actions';

function getCloudStatusMessageEpic($action) {
  return $action.pipe(
    ofType(REQUEST_CLOUD_STATUS_MESSAGE),
    map((action) => action.payload),
    switchMap(() => from(strapi.request('get', '/../maintenance-message')).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result?.error) {
          return addNotification({
            type: 'error',
            text: result.message
          });
        }

        return receiveCloudStatusMessage(result);
      })
    )
    )
  );
}

export default combineEpics(getCloudStatusMessageEpic);
