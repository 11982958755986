import { createSelector } from 'reselect';

const getSensorsConfigurationFile = (state) => state.devicesTable?.sensorsConfigurationFile;
const getModals = (state) => state.modals;
const getAddDeviceForm = (state) => state.form?.addDeviceForm;
const getEditDeviceForm = (state) => state.form?.editDeviceForm;

export const sensorsConfigurationFileSelector = createSelector(
  [getSensorsConfigurationFile],
  (data) => data?.data
);
export const modalsSelector = createSelector(
  [getModals],
  (modals) => modals
);
export const deviceFormSelector = createSelector(
  [getModals, getAddDeviceForm, getEditDeviceForm],
  (isOpenedAddDeviceModal, addDeviceform, editDeviceform) => (isOpenedAddDeviceModal?.addDevice?.opened ? addDeviceform : editDeviceform)
);
