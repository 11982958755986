export const GET_USER = 'GET_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const CANCEL_USER = 'CANCEL_USER';
export const CHANGE_PRIORITY = 'CHANGE_PRIORITY';
export const CHANGE_PRIORITY_SUCCESS = 'CHANGE_PRIORITY_SUCCESS';
export const CHANGE_PRIORITY_CANCEL = 'CHANGE_PRIORITY_CANCEL';
export const DEVICES_GET_TYPES_AND_NAMES = 'DEVICES_GET_TYPES_AND_NAMES';
export const DEVICES_SAVE_TYPES_AND_NAMES = 'DEVICES_SAVE_TYPES_AND_NAMES';
export const DEVICES_TAGS_RECIVED = 'DEVICES_TAGS_RECIVED';
export const DEVICES_TAGS_ADD = 'DEVICES_TAGS_ADD';
export const SCAN_IP = 'SCAN_IP';
export const GET_LIST_OF_IP = 'GET_LIST_OF_IP';
export const SAVE_IPS = 'SAVE_IPS';
export const REMOVE_IPS = 'REMOVE_IPS';
export const USER_DATA_SEND_REQUEST = 'USER_DATA_SEND_REQUEST';
export const CHANGE_SUPPORT_CONTRACTS = 'CHANGE_SUPPORT_CONTRACTS';
export const CHANGE_INSTALLATION_FINISHED = 'CHANGE_INSTALLATION_FINISHED';
export const IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED = 'IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED';
export const RECEIVE_IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED = 'RECEIVE_IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED';
export const UPDATE_LOAD_MANAGEMENT = 'UPDATE_LOAD_MANAGEMENT';
export const FETCH_LAST_SENSORS_DATA = 'FETCH_LAST_SENSORS_DATA';
export const LAST_SENSORS_DATA_RECEIVED = 'LAST_SENSORS_DATA_RECEIVED';
export const RECEIVE_USER_SUBSCRIPTION = 'RECEIVE_USER_SUBSCRIPTION';
export const GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION';

export const SENSORS_LIST_ID = 'sensors';

/**
 * Roles for visible web
 */
export const rolesWithCustomLogo = ['oem', 'pv_installer'];
export const EMPLOYEE_ROLE_TYPES = ['pv_installer_employee', 'pv_installer_employee_read_only'];
