import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import i18n from '../../../../../i18n';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../../../../ModalWindow/actions';
import { ItemsList } from '../ItemsList';
import { getColumns } from './columns';

import './index.scss';
/**
 * General user info. Viewed by everyone except end_user.
 * @memberof module:UserDetails
 */
const GeneralInfo = (props) => {
  const { openModalWindow: openModal, user, myRoleType } = props;
  const isViewersRoles = ['pv_installer_employee_read_only', 'viewer'].includes(myRoleType);
  const columns = useMemo(() => getColumns({ user, myRoleType }), [user, myRoleType]);


  return (
    <Box className="main-info" sx={{ p: 4, pr: 10 }}>
      {!isViewersRoles && (
        <div className="user-info-link">
          <a
            onClick={openModal.bind(null, 'generalSettings', null)}
            className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
            title={i18n.t('editTooltip')}
          >
            <i className="la la-edit" />
          </a>
        </div>
      )}
      <Box className="main-content general-info">
        <ItemsList columns={columns} />
      </Box>
    </Box>
  );
};

GeneralInfo.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string
};

GeneralInfo.defaultProps = {
  myRoleType: undefined
};

export default connect(null, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend
})(GeneralInfo);
