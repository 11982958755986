import React from 'react';
import PropTypes from 'prop-types';

import store from '../../../store';
import { openModalWindow } from '../../../containers/ModalWindow/actions';
import { redirectToPaymentPage } from '../../../containers/Payments/actions';
import { MODAL_ID } from '../../ChooseLicenseWindow/constants';
import i18n from '../../../i18n';
// import strapi from '../../../strapi';
// import { SOLAR_PAYMENT_SERVICE } from '../../../config';

import '../index.scss';

/**
 * @const {array<string>}
 * @name helionLicenseId
 * @description helionLicenseId array of Helion licenses id,
 * if the licenseId is in the array, you need to hide the edit button next to those licenses
 */
const helionLicenseId = ['10', '11', '12'];

/**
 * Open modal window with licenses
 * @param {string} modalID - id of modal window which will be opened
 * @param {Object} data - initial values for modal window
 */
const openModal = (modalID, data) => store.dispatch(openModalWindow(modalID, data));

/**
 * Render license cell for React Table
 * @param {string} endUserId - end user id
 * @returns {node}
 */
const redirectToPayment = (endUserId, url) => store.dispatch(redirectToPaymentPage({ endUserId, url }));

/**
 *
 * @param {Object} props
 * @param {Object} props.value
 * @param {string} props.value.userId - end user id
 * @param {string} props.value.licenseId - end user's current license id
 * @param {string} props.value.licenseTitle - end user's current license title
 * @param {boolean} props.value.hide - hide or show modal window with licenses
 * @param {string} props.value.parentListId - short page name or key
 */
const renderPaidLicense = (props) => {
  const {
    value: {
      userId, licenseId, licenseTitle, hide, parentListId
    },
    original: {
      initialLicense
    }
  } = props;

  const { name } = initialLicense || {};

  if (hide) {
    return null;
  }


  const handlerOpenModal = () => openModal(MODAL_ID, {
    userId,
    license: licenseId,
    parentListId
  });

  // eslint-disable-next-line
  const handlerClick = async () => {
    if (!userId) {
      handlerOpenModal();
      return;
    }
    if (helionLicenseId.includes(licenseId)) {
      redirectToPayment(userId);
      return;
    }

    handlerOpenModal();

    // TODO: to comment before release sprint-7 (uncomment after)
    // try {
    //   const data = await straps.request('get', `${SOLAR_PAYMENT_SERVICE}/user/${userId}/web-view-url`);
    //   if (data?.webViewUrl && data.subscription) {
    //     redirectToPayment(userId, data.webViewUrl);
    //     return;
    //   }
    //   handlerOpenModal();
    // } catch {
    //   handlerOpenModal();
    // }
  };


  return (
    <>
      <a
        title={i18n.t('editTooltip')}
        onClick={handlerClick}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill render-paid-license"
      >
        <i className="la la-pencil" />
      </a>
      <span>{licenseTitle || name}</span>
    </>
  );
};

renderPaidLicense.propTypes = {
  value: PropTypes.instanceOf(Object)
};

renderPaidLicense.defaultProps = {
  value: {}
};

export default renderPaidLicense;
