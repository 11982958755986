import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  email, required, minLength, maxLength, parseToLowerCase
} from '../../validation';
import CustomTextInput from '../../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../../ModalWindow';
import Preloader from '../../../components/Preloader';
import i18n from '../../../i18n';
import '../index.css';
import { isLoadingSelector, modalSelector } from '../selectors';
import CancelButton from '../../../components/UIKit/CancelButton';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

class AddUserWindow extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { modal, reset } = this.props;
    const { modal: nextModal } = nextProps;
    if (modal && (!nextModal || (modal.opened && !nextModal.opened))) {
      reset();
    }
  }

  render() {
    const
      {
        handleOnClose, handleSubmit, modalID, pristine, isLoading
      } = this.props;

    return (
      <ModalWindow
        modalID={modalID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('addSMU')}</h5>
        </div>
        {isLoading ? (<div className="user-add-prealoder-container"><Preloader /></div>) : (
          <div>
            <div className="modal-body">
              <form
                id="addUserForEmp"
                onSubmit={handleSubmit}
                className="m-login__form m-form pop-up-form add-sm-us"
              >
                <div className="form-group m-form__group input-field">
                  <Field
                    name="sm_id"
                    component={CustomTextInput}
                    label={i18n.t('reqSMID')}
                    className="m-input"
                    validate={[required, minLen3, maxLen50]}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="email"
                    component={CustomTextInput}
                    label={i18n.t('regMail')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, email]}
                    parse={parseToLowerCase}
                    preventAuto
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                disabled={pristine}
                type="submit"
                form="addUserForEmp"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
              >
                <i className="fa fa-paper-plane" />
                {i18n.t('send')}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

AddUserWindow.propTypes = {
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

AddUserWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const form = reduxForm({
  form: 'addUserForm'
})(AddUserWindow);

const mapStateToProps = createStructuredSelector({
  modal: modalSelector,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps)(form);
