import { createSelector } from 'reselect';

export const getIsLoading = (state) => state.modals?.isLoading;
export const getModal = (state) => state.modals?.addData;
export const getRemoveDataModal = (state) => state.modals?.removeData?.data || {};

export const isLoadingSelector = createSelector([getIsLoading], (isLoading) => !!isLoading);

export const modalSelector = createSelector([getModal], (modal) => modal);
export const removeDataModalSelector = createSelector([getRemoveDataModal], (modal) => modal);
