import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SignUpLayout from './SignUpLayout';
import { signUpSend, sendEmailChange, resendToEmail, changeEmailAction } from './actions';
import DataList from '../DataList';
import { openModalWindow, closeModalWindow } from '../ModalWindow/actions';
import '../../App.css';
import QRCodeScanner from '../../components/QRCodeScanner';
import './index.scss';
import { transformLanguageForAPI } from '../../i18n';
import {
  allDataListsSelector,
  changeEmailSelector,
  emailSelector,
  resendEmailSelector,
  sendSuccessfullSelector,
  verifyEmailSelector
} from './selectors';
import { SIGN_UP_USER_FORM } from './constants';

/**
 * renders Sign Up page
 * @extends Component
 */
const SignUp = (props) => {
  const {
    sendSuccessfull,
    email: emailFromProps,
    verifyEmail,
    resendEmail,
    changeEmail,
    changeEmailAction: changeEmailActionFromProps,
    allDataLists,
    openModalWindow: openModal,
    closeModalWindow: closeModal,
    resendToEmail: resendToEmailfromProps,
    i18n,
    t
  } = props;

  const resendToEmail_ = resendToEmailfromProps.bind(
    null,
    emailFromProps,
    transformLanguageForAPI(i18n.language)
  );
  const dataListID = 'companies';
  const dataListURL = '/../company/list';

  const companies = allDataLists[dataListID]?.data || [];

  const [sm_id, setSm_id] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const { match } = props;
    setSm_id(match.params.sm_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Sends request to register company
   */

  const onSubmitCompany = (values) => {
    const { signUpSend: signUpSendFromProps } = props;
    signUpSendFromProps('company', transformLanguageForAPI(i18n.language), values);
  };

  /**
   * @description Sends request to register user
   */

  const onSubmitUser = (values) => {
    const { signUpSend: signUpSendFromProps } = props;
    signUpSendFromProps('user', transformLanguageForAPI(i18n.language), values);
  };

  /**
   * @description Sends request to change email
   */

  const onSubmitChangeEmail = (values) => {
    const { sendEmailChange: sendEmailChangeFromProps } = props;
    sendEmailChangeFromProps(transformLanguageForAPI(i18n.language), values);
  };

  /**
   * @description opens window to scan QR code
   */

  const scanQR = (result) => {
    const { formStates } = props;
    if (formStates.sm_id) {
      setSm_id(formStates.sm_id);
    }
    if (formStates.email) {
      setEmail(formStates.email);
    }
    if (typeof result !== 'string') return;
    setSm_id(result);
    closeModal('QRCodeScanner');
  };

  return (
    <Fragment>
      <QRCodeScanner
        modalID="QRCodeScanner"
        handleOnClose={closeModal.bind(null, 'QRCodeScanner')}
        scanQR={scanQR}
        t={t}
      />
      <DataList listID={dataListID} listURL={dataListURL} />
      <SignUpLayout
        onSubmitCompany={onSubmitCompany}
        onSubmitUser={onSubmitUser}
        onSubmitChangeEmail={onSubmitChangeEmail}
        sentEmails={{ email: emailFromProps, verifyEmail }}
        sendSuccessfull={sendSuccessfull}
        resendEmail={resendEmail} // boolean
        actions={{ resendToEmail: resendToEmail_, changeEmailAction: changeEmailActionFromProps }}
        changeEmail={changeEmail}
        companies={companies}
        openQRScanner={openModal.bind(null, 'QRCodeScanner', null)}
        sm_id={sm_id}
        email={email}
        i18n={i18n}
        t={t}
      />
    </Fragment>
  );
};

SignUp.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  signUpSend: PropTypes.func.isRequired,
  sendEmailChange: PropTypes.func.isRequired,
  resendToEmail: PropTypes.func.isRequired,
  changeEmailAction: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  allDataLists: PropTypes.instanceOf(Object).isRequired,
  formStates: PropTypes.instanceOf(Object).isRequired,
  sendSuccessfull: PropTypes.bool,
  email: PropTypes.string,
  verifyEmail: PropTypes.bool,
  resendEmail: PropTypes.bool,
  changeEmail: PropTypes.bool,
  i18n: PropTypes.instanceOf(Object),
  t: PropTypes.instanceOf(Object)
};

SignUp.defaultProps = {
  sendSuccessfull: undefined,
  email: undefined,
  verifyEmail: undefined,
  resendEmail: undefined,
  changeEmail: undefined,
  i18n: {},
  t: undefined
};

const mapStateToProps = createStructuredSelector({
  allDataLists: allDataListsSelector,
  sendSuccessfull: sendSuccessfullSelector,
  email: emailSelector,
  verifyEmail: verifyEmailSelector,
  resendEmail: resendEmailSelector,
  changeEmail: changeEmailSelector,
  formStates: getFormValues(SIGN_UP_USER_FORM)
});

export default connect(mapStateToProps, {
  signUpSend,
  sendEmailChange,
  resendToEmail,
  changeEmailAction,
  openModalWindow,
  closeModalWindow
})(SignUp);
