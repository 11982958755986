import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ModalWindow from '../../../ModalWindow';
import i18n from '../../../../i18n';

import { LIMIT_SIZE_MODAL_ID } from '../../constants';
import { closeModalWindow } from '../../../ModalWindow/actions';
import { ReactComponent as WarningIco } from '../../../../assets/images/svg/warning.svg';
import './index.scss';

const LImitSizeModal = ({ closeModalWindow: closeModalWindowAction }) => {
  const handleOnClose = () => {
    closeModalWindowAction(LIMIT_SIZE_MODAL_ID);
  };

  return (
    <ModalWindow modalID={LIMIT_SIZE_MODAL_ID}>
      <div className="modal-header limit-size-header">
        <WarningIco />
        <h5 className="modal-title download-title">
          {i18n.t('limitSizeUploadFailed')}
        </h5>
      </div>
      <div className="modal-body">
        <p className="message">
          {i18n.t('limitSizeUploadFailedMessage')}
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
          onClick={handleOnClose}
        >
          {i18n.t('ok')}
        </button>
      </div>
    </ModalWindow>
  );
};

LImitSizeModal.propTypes = {
  closeModalWindow: PropTypes.func.isRequired
};

export default connect(null, { closeModalWindow })(LImitSizeModal);
