import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { filteredListOfOptions } from './helper';
import { dataListID } from '../../containers/Users/constants';
import { dataListSendRequest } from '../../containers/DataList';
import { setPagesSettingQueries } from '../../containers/PagesSettings/actions';
import { getPagesSettingSelector } from '../../containers/PagesSettings/selectors';

import { LargeButton } from '../ReduxFormFields/SimpleButton';

import i18n from '../../i18n';

import './index.scss';

const fixValue = (value) => ([i18n.t('workStatusFilterPlaceholder'), i18n.t('workStatusFilterPlaceholder')].includes(value) ? undefined : JSON.parse(value));

const AdditionalDataListFilters = ({ transformResponse, listURL }) => {
  const dispatch = useDispatch();

  const { [dataListID]: settings } = useSelector(getPagesSettingSelector);
  const { isInstallationCompleted, supportContractFlag, search } = settings.queries;
  const disableButton = settings.queries.isInstallationCompleted === undefined && supportContractFlag === undefined && !search;

  const handleChange = (data) => {
    dispatch(dataListSendRequest(
      dataListID,
      {
        listURL,
        params: { ...settings.queries, ...data },
        transformResponse
      }
    ));
    dispatch(setPagesSettingQueries(dataListID, data));
  };

  return (
    <div className="additional-filters__container">
      <div className="support-contracts-filter__container">
        <span>
          {i18n.t('supportContracts')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ supportContractFlag: fixValue(e.target.value) })}
          value={String(supportContractFlag)}
        >
          {filteredListOfOptions.map(({ value, key }) => (
            <option
              value={key}
              key={`supportContracts_${value}`}
            >
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      <div className="installation-finished-filter__container">
        <span>
          {i18n.t('installationFinished')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ isInstallationCompleted: fixValue(e.target.value) })}
          value={String(isInstallationCompleted)}
        >
          {filteredListOfOptions.map(({ value, key }) => (
            <option
              value={key}
              key={`installationFinished_${value}`}
            >
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      <div className={`col-auto ml-auto pr-0 btn-sm-us m--margin-bottom-10 reset-additional-filters-btn__container ${disableButton && 'disable-button'}`}>
        <LargeButton
          onClick={() => handleChange({ isInstallationCompleted: undefined, supportContractFlag: undefined, search: undefined })}
          title={i18n.t('resetFilters')}
          disabled={disableButton}
        />
      </div>
    </div>
  );
};

AdditionalDataListFilters.propTypes = {
  transformResponse: PropTypes.func,
  listURL: PropTypes.string.isRequired
};

AdditionalDataListFilters.defaultProps = {
  transformResponse: undefined
};

export default AdditionalDataListFilters;
