export const GET_OEMS = 'GET_OEMS';
export const SET_OEMS = 'SET_OEMS';
export const CLEAR_OEMS = 'CLEAR_OEMS';
export const GET_OEMS_BY_ID = 'GET_OEMS_BY_ID';
export const SET_IS_SHOW_PLANT_COLUMN_FOR_USERS = 'SET_IS_SHOW_PLANT_COLUMN_FOR_USERS';
export const SET_IS_SHOW_LAST_LOGIN_DATE_FOR_USERS = 'SET_IS_SHOW_LAST_LOGIN_DATE_FOR_USERS';
export const SET_IS_SHOW_INSTALLATION_DATE_FOR_USERS = 'SET_IS_SHOW_INSTALLATION_DATE_FOR_USERS';
export const UPDATE_COLUMN_PROPS_FOR_USER = 'UPDATE_COLUMN_PROPS_FOR_USER';

export const dataListPviId = 'companies';

export const getPvInstallersConfig = {
  support: {
    url: '/../users/get-supported-pv-installer-users',
    params: {
      _limit: 10000
    }
  },
  default: {
    url: '/../users/pv-installer-users',
    params: {
      _limit: 10000
    }
  }
};

/**
 * dataList id
 */
export const dataListID = 'sm_users';

/**
 * Roles for visible web
 */
export const rolesWithCustomLogo = ['oem', 'pv_installer'];

export const switchesArray = [
  {
    key: 'plant',
    i18nLabel: 'editColumnsModalEnabledPlant'
  },
  {
    key: 'last_login',
    i18nLabel: 'editColumnsModalEnabledLastLogin'
  },
  {
    key: 'installationDate',
    i18nLabel: 'editColumnsModalEnabledInstallationDate'
  }
];
