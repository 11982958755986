import React from 'react';
import PropTypes from 'prop-types';

import EditDeviceWindow from './EditDeviceWindow';
import EditLoadGuard from './EditLoadGuard';

const EditDeviceWindowHoc = (props) => {
  const { deviceType } = props;
  const modal = {
    devices: <EditDeviceWindow {...props} />,
    inverters: <EditDeviceWindow {...props} />,
    'smart-meter': <EditDeviceWindow {...props} />,
    'sub-meter': <EditDeviceWindow {...props} />,
    'input-devices': <EditDeviceWindow {...props} />,
    loadguards: <EditLoadGuard {...props} />,
    default: null
  };
  return modal[deviceType] || modal.default;
};

EditDeviceWindowHoc.defaultProps = {
  deviceType: PropTypes.string.isRequired
};

export default EditDeviceWindowHoc;
