import * as actionTypes from './constants';

export function oAuthSignIn(data) { // epic
  return {
    type: actionTypes.OAUTH_SIGN_IN,
    payload: {
      data
    }
  };
}
