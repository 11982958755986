import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import logoImg from './logo.png';
import logoImg2 from './askoma.png';
import isAskoma from '../../utils/isAskoma';

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = () => (
  <div className="m-login__log">
    <Link to="/">
      <img src={isAskoma() ? logoImg2 : logoImg} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

export default Logo;
