const generateEmptyBuffer = (activeDevices) => ({
  bufferCreated: Date.now(),
  mainChart: {
    data: {},
    dataSum: {}
  },
  activeDevices: activeDevices || {
    devices: [],
    activity: []
  }
});

export default generateEmptyBuffer;
