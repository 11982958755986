import { createSelector } from 'reselect';
import { get } from 'lodash';
import { DATA_LIST_ID } from '../constants';

const serializeLicenses = (licenses) => licenses.map(({ title, id, description }) => ({
  title,
  value: id,
  description
}));

const getLicenses = (state) => get(state, ['dataList', DATA_LIST_ID, 'data'], []);

export default createSelector(getLicenses, serializeLicenses);
