import {
  renderInputDeviceName,
  renderLoadGuardDescription,
  renderInputDeviceFunction,
  renderAffectedDevices,
  RenderButtons
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
/**
 * Creates load-guard columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const loadGuard = (myRoleType) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('inputDevice'),
    id: 'inputDevice',
    accessor: 'device_group',
    minWidth: 180,
    Cell: renderInputDeviceName,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('description'),
    id: 'description',
    accessor: 'description',
    minWidth: 180,
    Cell: renderLoadGuardDescription,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('function'),
    id: 'inputDeviceFunction',
    accessor: 'inputDeviceFunction',
    minWidth: 180,
    Cell: renderInputDeviceFunction,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('affectedDevices'),
    id: 'affectedDevices',
    accessor: 'affectedDevices',
    minWidth: 180,
    Cell: renderAffectedDevices,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: myRoleType !== 'viewer' ? 120 : undefined,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(row.original, {
      editRow: [
        'root',
        'solar_admin',
        'support',
        'oem',
        'pv_installer',
        'pv_installer_employee'
      ].includes(myRoleType),
      remove: [
        'root',
        'solar_admin',
        'support',
        'oem',
        'pv_installer',
        'pv_installer_employee'
      ].includes(myRoleType)
    }),
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'support',
      'viewer',
      'oem',
      'pv_installer',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  }
]);

export default loadGuard;
