import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { createStructuredSelector } from 'reselect';
import columns from './columns';
import TopBar from '../../components/TopBar';
import { UserInfoSub } from '../../components/ReactTable/renderFunctions';
import CompanyInfo from './components/CompanyInfo';
import SearchField from '../../components/SearchField';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
// eslint-disable-next-line import/no-named-as-default-member
import ChooseLicenseWindow from '../../components/ChooseLicenseWindow';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import Pagination from '../../components/ReactTable/Pagination';
import {
  COMPANY_DATALIST_ID,
  addPvInstallerToOEM,
  getConnectedInstallersRequestData,
  getAllInstallersRequestData
} from './constants';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/actions';
import { getAllConnectedInstallers } from './actions';
import AddPVInstallerToOEMWindow from './components/AddPVInstallerToOEMWindow';
import RemoveOEMWindow from './components/RemoveOEMWindow';

import Footer from '../../components/Footer';
import { configSavedSettings } from '../../utils/pageSettings';
import { companyOemIdSelector, supportOemIdSelector } from './selectors';

class PVInstallersDetails extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);

    configSavedSettings(
      this,
      'company-details',
      {
        expander: true,
        subTabs: true,
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'my_company:asc'
        },
        pathName,
        historyKey
      }
    );
  }

  componentDidMount() {
    moment.tz.setDefault();
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded } = this.state;
    const {
      myRoleType,
      match,
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: modalSend,
      getAllConnectedInstallers: getAllConnectedInstallersProps,
      companyOemId,
      supportOemId
    } = this.props;


    const { companyID } = match.params;
    const companyDataListURL = `/../oem/${companyID}/`;
    const dataListIDUsers = 'company_sm_oem';

    const {
      GET_CONNECTED_INSTALLERS_URL,
      GET_CONNECTED_INSTALLERS_PARAMS
    } = getConnectedInstallersRequestData({
      myRoleType,
      supportOemId,
      companyOemId,
      defaultRoleParams: { oemId: companyOemId }
    });

    const {
      GET_ALL_INSTALLERS_URL,
      GET_ALL_INSTALLERS_PARAMS
    } = getAllInstallersRequestData({
      myRoleType,
      companyOemId,
      supportRoleParams: { ...this.defaultParams, oemId: supportOemId },
      defaultRoleParams: { ...this.defaultParams, company: companyOemId }
    });

    const transformResponse = (data) => ({
      total: data.total,
      data: data.list
    });

    const sendAddInstallers = (fields) => {
      modalSend(
        addPvInstallerToOEM,
        {
          method: 'put',
          url: `/../oem/pv-installer/${companyOemId}`,
          data: { pvInstallerIds: fields }
        },
        () => {
          getAllConnectedInstallersProps(GET_CONNECTED_INSTALLERS_URL, GET_CONNECTED_INSTALLERS_PARAMS);
          return dataListReloadData(dataListIDUsers);
        }
      );
    };

    const sendRemoveUser = ({ id }) => modalSend(
      'deleteData',
      {
        method: 'delete',
        url: `/../oem/pv-installer/${companyOemId}?pv-installer_id=${id}`
      },
      () => dataListReloadData(dataListIDUsers)
    );

    return (
      <Fragment>
        <ChooseLicenseWindow />
        <TopBar showNavigation />
        <DataList listID={COMPANY_DATALIST_ID} listURL={companyDataListURL} Component={CompanyInfo} />

        {supportOemId && (
          <div className="container-fluid">
            <div className="d-flex control-panel">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10">
                <DataListFilter
                  Component={SearchField}
                  componentProps={{
                    searchByFields: 'sm_id,email,first_name,last_name,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListIDUsers}
                />
              </div>
            </div>
            <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
              {myRoleType !== 'viewer' && (
                <div className="OEMAddBtn">
                  <a
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                    onClick={openModal.bind(null, addPvInstallerToOEM, null)}
                  >
                    <span>
                      <span>{i18n.t('addPViToOEMtn')}</span>
                    </span>
                  </a>
                </div>
              )}
              <h3 className="pvInstallers-title">{i18n.t('pvInstallers')}</h3>
              <RemoveOEMWindow
                modalID="deleteData"
                onSubmit={sendRemoveUser}
                handleOnClose={closeModal.bind(null, 'deleteData')}
              />
              <AddPVInstallerToOEMWindow
                modalID={addPvInstallerToOEM}
                handleSubmit={sendAddInstallers}
                companyOemId={companyOemId}
                supportOemId={supportOemId}
                handleOnClose={closeModal.bind(null, addPvInstallerToOEM)}
              />
              <DataList
                listID={dataListIDUsers}
                listURL={GET_ALL_INSTALLERS_URL}
                params={GET_ALL_INSTALLERS_PARAMS}
                transformResponse={transformResponse}
                Component={ReactTable}
                componentProps={{
                  defaultPageSize: 0,
                  className: '-highlight m-datatable__table',
                  columns: this.columns,
                  SubComponent: UserInfoSub,
                  manual: true,
                  onExpandedChange: (all) => {
                    this.saveExpanderChanges(all);
                  },
                  expanded
                }}
              />
            </div>
            <DataListPagination
              Component={Pagination}
              listID={dataListIDUsers}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        )}
        <Footer />
      </Fragment>
    );
  }
}

PVInstallersDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  companyOemId: PropTypes.string,
  supportOemId: PropTypes.string,
  getAllConnectedInstallers: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

PVInstallersDetails.defaultProps = {
  companyOemId: undefined,
  supportOemId: undefined
};

const mapStateToProps = createStructuredSelector({
  companyOemId: companyOemIdSelector,
  supportOemId: supportOemIdSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  getAllConnectedInstallers
})(PVInstallersDetails);
