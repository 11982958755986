import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { goBack } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';
import ChangePassWindow from './ChangePassWindow';
import ChangeEmailWindow from './ChangeEmailWindow';
import ChangeSMIdWindow from './ChangeSMIdWindow';
import {
  settingPrGetUser,
  settingPrChange,
  settingPrChangeModal,
  settingPrGetUserSucc,
  resendToEmail,
  userIsLoading
} from './actions';
import { openModalWindow, closeModalWindow, modalWindowSend } from '../ModalWindow/actions';
import { getInitialValues, GetLayout } from './RenderSettingByUserRole';
import './index.scss';
import '../../App.css';
import { transformToFormData } from '../../api_helper';
import ConfirmationWindow from './ConfirmationWindow';
import store from '../../store';
import QRCodeScanner from '../../components/QRCodeScanner';
import Preloader from '../../components/Preloader';
import { transformLanguageForAPI } from '../../i18n';
import { CHANGE_EMAIL_FORM, CHANGE_SMID_FORM } from './constants';
import {
  emailSelector,
  initialDataSelector,
  isLoadingSelector,
  myUserSelector,
  smIdDataSelector
} from './selectors';
import analytics from '../../analytics';
import getEventObj, { USER_UPDATE } from '../../analytics/events';

/**
 * Setting profile page.
 * @module SettingProfile
 */
/**
 * Main component of setting profile page
 * @memberof module:SettingProfile
 */
const SettingProfile = (props) => {
  const {
    openModalWindow: openModal,
    closeModalWindow: closeModal,
    modalWindowSend: sendModal,
    settingPrChange: settingPrChangeFromProps,
    settingPrChangeModal: settingPrChangeModalFromProps,
    settingPrGetUserSucc: settingPrGetUserSuccFromProps,
    goBack: goBackFromProps,
    myRoleType,
    initialData,
    changeSMIdForm,
    sm_id_data,
    t,
    isLoading,
    settingPrGetUser: settingPrGetUserFromProps,
    myUser,
    match,
    userIsLoading: usersDataIsLoading
  } = props;

  const { userID: userIdFromProps, roleLink } = match.params;

  const myself = useRef(false);
  const userID = useRef(userIdFromProps);

  useEffect(() => {
    if (!userIdFromProps || userIdFromProps === myUser._id) {
      myself.current = true;
      userID.current = myUser._id;
    } else {
      myself.current = false;
      userID.current = userIdFromProps;
    }

    usersDataIsLoading();

    settingPrGetUserFromProps(userID.current, myself.current, roleLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scanQR = (value) => {
    if (typeof value !== 'string') return;
    store.dispatch(change('changeSMIdForm', 'sm_id', value));
    closeModal('QRCodeScanner');
  };

  const resendEmail = () => {
    const { resendToEmail: resendToEmailProps, i18n, userEmail } = props;
    resendToEmailProps(userEmail, transformLanguageForAPI(i18n.language));
  };

  const submitDeleteData = () => {
    settingPrChangeModalFromProps(
      myself,
      roleLink,
      initialData?.id,
      'changeSMID',
      { ...sm_id_data, _submitConfirmed: true },
      (res) => settingPrGetUserSuccFromProps(res)
    );
    closeModal('confirmationWindow');
  };

  const sendPasswordChanges = (fields) => {
    analytics.sendEvent(getEventObj(USER_UPDATE));
    sendModal(
      'changePassword',
      {
        method: 'put',
        url: '/../user/update',
        data: transformToFormData(fields)
      },
      (res) => settingPrGetUserSucc(res)
    );
  };
  useEffect(() => {
    if (!isLoading && !initialData) {
      if (!userIdFromProps || userIdFromProps === myUser._id) {
        myself.current = true;
        userID.current = myUser._id;
      } else {
        myself.current = false;
        userID.current = userIdFromProps;
      }

      settingPrGetUserFromProps(userID.current, myself.current, roleLink);
    }
  }, [isLoading, initialData, userID, myself, roleLink, settingPrGetUserFromProps, userIdFromProps, myUser._id]);

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor settings-user-prof">
        {isLoading ? (
          <div className="profile-edit-prealoder-container">
            <Preloader />
          </div>
        ) : (
          <>
            <ChangePassWindow
              modalID="changePassword"
              onSubmit={sendPasswordChanges}
              handleOnClose={closeModal.bind(null, 'changePassword')}
            />
            <ChangeSMIdWindow
              modalID="changeSMID"
              handleOnClose={closeModal.bind(null, 'changeSMID')}
              onSubmit={settingPrChangeModalFromProps.bind(
                null,
                myself.current,
                roleLink,
                initialData?.id,
                'changeSMID'
              )}
              initialValues={{ old_sm_id: initialData?.sm_id, save_data: true }}
              enableReinitialize
              openScanQR={openModal.bind(null, 'QRCodeScanner', null)}
            />
            <QRCodeScanner
              modalID="QRCodeScanner"
              handleOnClose={closeModal.bind(null, 'QRCodeScanner')}
              scanQR={scanQR}
              t={t}
            />
            <ConfirmationWindow
              modalID="confirmationWindow"
              handleOnClose={closeModal.bind(null, 'confirmationWindow', changeSMIdForm)}
              onSubmit={submitDeleteData.bind(null)}
            />
            <GetLayout
              userRoleType={initialData?.role?.type}
              userRoleName={initialData?.role?.name}
              myRoleType={myRoleType}
              sendEmailRequest={resendEmail}
              openChangeEmail={openModal.bind(null, 'changeEmail', null)}
              openChangeSMID={openModal.bind(null, 'changeSMID', null)}
              openChangePassword={openModal.bind(null, 'changePassword', null)}
              myself={myself.current}
              goBack={goBackFromProps}
              onSubmit={settingPrChangeFromProps.bind(
                null,
                myself.current,
                roleLink,
                initialData?.id || initialData?._id
              )}
              initialValues={getInitialValues(initialData)}
              enableReinitialize
            />
            <ChangeEmailWindow
              modalID="changeEmail"
              onSubmit={settingPrChangeModalFromProps.bind(
                null,
                myself.current,
                roleLink,
                initialData?._id,
                'changeEmail'
              )}
              handleOnClose={closeModal.bind(null, 'changeEmail')}
              initialValues={{ old_email: initialData?.email }}
              enableReinitialize
            />
          </>
        )}
      </div>
    </div>
  );
};

SettingProfile.propTypes = {
  settingPrGetUser: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  myUser: PropTypes.instanceOf(Object).isRequired,
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  resendToEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  settingPrChange: PropTypes.func.isRequired,
  settingPrChangeModal: PropTypes.func.isRequired,
  settingPrGetUserSucc: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  initialData: PropTypes.instanceOf(Object),
  changeSMIdForm: PropTypes.instanceOf(Object),
  sm_id_data: PropTypes.instanceOf(Object),
  email: PropTypes.string,
  userEmail: PropTypes.string,
  i18n: PropTypes.instanceOf(Object),
  userIsLoading: PropTypes.func.isRequired,
  t: PropTypes.instanceOf(Object)
};

SettingProfile.defaultProps = {
  initialData: undefined,
  email: undefined,
  userEmail: undefined,
  changeSMIdForm: undefined,
  sm_id_data: undefined,
  t: undefined,
  i18n: {},
  isLoading: false
};

const emailFormSelector = formValueSelector(CHANGE_EMAIL_FORM);
const smidFormSelector = formValueSelector(CHANGE_SMID_FORM);

const mapStateToProps = createStructuredSelector({
  initialData: initialDataSelector,
  myUser: myUserSelector,
  changeSMIdForm: (state) => smidFormSelector(state, 'changeSMIdForm'),
  sm_id_data: smIdDataSelector,
  isLoading: isLoadingSelector,
  email: emailSelector,
  userEmail: (state) => emailFormSelector(state, 'old_email')
});

export default connect(mapStateToProps, {
  goBack,
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  settingPrGetUser,
  resendToEmail,
  settingPrChange,
  settingPrChangeModal,
  settingPrGetUserSucc,
  userIsLoading
})(SettingProfile);
