/**
 * Calculates self consumption for the point of data
 * @param {Object} newPoint
 * @param {number} [newPoint.x_consumption]
 * @param {number} [newPoint.x_production]
 * @returns {number} - self consumption for the point
 */
const calculateSelfConsumptionForPoint = ({ x_consumption, x_production }) => {
  const xConsumption = Number.isFinite(x_consumption) ? x_consumption : 0;
  const xProduction = Number.isFinite(x_production) ? x_production : 0;
  return Math.min(xConsumption, xProduction);
};

export default calculateSelfConsumptionForPoint;
