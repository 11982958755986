import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { initialize } from 'redux-form';

import { handleErrorDetailed } from '../../api_helper';
import * as actionTypes from './constants';
import strapi from '../../strapi';
import { receiveSensorsConfigurationFile, usersSensorsReceived } from './actions';
import { addNotification } from '../NotificationGenerator/actions';
import { modalStopLoading } from '../ModalWindow/actions';

import { dataListReloadData } from '../DataList';
import { SENSORS_LIST_ID } from '../UserDetails/constants';

function getUserInfoEpic($action) {
  return $action.pipe(
    ofType(actionTypes.USERS_SENSORS_REQUEST),
    map((action) => action.payload.userId),
    switchMap((userId) => from(strapi.request('get', `/../users/solar-managers-users/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result && !result.error) {
          if (result?.gateway) {
            return usersSensorsReceived(result?.gateway?.sensors);
          }
        }
        return addNotification({
          type: 'error',
          text: result.error.message || result.error
        });
      })
    )
    )
  );
}

function getSensorsConfigurationFile($action) {
  return $action.pipe(
    ofType(actionTypes.GET_SENSORS_CONFIGURATION_FILE),
    switchMap(() => from(strapi.request('get', '/../sensors/auth/oauth2')).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result && !result.error) {
          return receiveSensorsConfigurationFile(result);
        }
        return addNotification({
          type: 'error',
          text: result.error.message || result.error
        });
      })
    )
    )
  );
}

function setDeviceId($action, $state) {
  return $action.pipe(
    ofType(actionTypes.SET_DEVICE_ID_RTU),
    map((action) => action.payload),
    switchMap(({ sensorId, value, modalId }) => {
      const state = $state.value;

      const {
        signIn: {
          user: {
            role: { type: myRoleType }
          }
        },
        userDevices: {
          user: { id: userId }
        },
        form: {
          editDeviceForm: { values: listOfValues }
        }
      } = state;

      const url = myRoleType === 'end_user'
        ? `/../sensors/set-device-id/my/${sensorId}`
        : `/../sensors/set-device-id/${userId}/${sensorId}`;

      const body = {
        data: {
          Device_ID_RTU: value
        }
      };

      return from(strapi.request('put', url, body)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result?.error) {
            return addNotification({
              type: 'error',
              text: result.error.message || result.error
            });
          }

          return of(
            initialize({ ...listOfValues, data: result?.data }),
            modalStopLoading(modalId),
            dataListReloadData(SENSORS_LIST_ID)
          );
        })
      );
    })
  );
}

export default combineEpics(getUserInfoEpic, getSensorsConfigurationFile, setDeviceId);
