import moment from 'moment-timezone';
import i18n from '../../../i18n';

/**
 * Formats date and time for showing at top of chart
 * @memberof module:DashboardChart
 * @param {((timestamp|dateString))} from_ Date and time when chart data starts
 * @param {((timestamp|dateString))} to_ Date and time when chart data ends
 * @param {boolean} [debugMode=false] True if need to show more detailed date
 * @param {string} chartType
 */
const formatInterval = (from_, to_, debugMode, chartType) => {
  if (!from_ || !to_) return null;
  const from = moment(from_);

  const to = moment(to_);

  if (debugMode) {
    return `${from.format('DD.MM.YYYY HH:mm:ss')} - ${to.format(
      'DD.MM.YYYY HH:mm:ss'
    )}`;
  }

  if (chartType === 'monthly') {
    return `${from.format('YYYY')}`;
  }
  if (chartType === 'daily') {
    return `${from.format('MMM, YYYY')}`;
  }

  const formatByLanguage = i18n.language === 'deutsch' ? 'Do MMM' : 'MMM Do';

  if (from.format('Y') === to.format('Y')) {
    let showYear = ', YYYY';
    if (moment().format('Y') === to.format('Y')) {
      showYear = '';
    }
    if (from.format('DDD') === to.format('DDD')) {
      return `${from.format(`${formatByLanguage}${showYear}`)}`;
    }
    return `${from.format(`${formatByLanguage}${showYear}`)} - ${to.format(
      `${formatByLanguage}${showYear}`
    )}`;
  }
  return `${from.format(`${formatByLanguage}, YYYY`)} - ${to.format(`${formatByLanguage}, YYYY`)}`;
};

export default formatInterval;
