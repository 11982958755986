import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form';
import ModalWindow from '../ModalWindow';
import Preloader from '../../components/Preloader';
import i18n from '../../i18n';
import { confirmRemoveGatewayModalSelector, isLoadingSelector } from './selectors';
import { required } from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';

import './index.css';
import CancelButton from '../../components/UIKit/CancelButton';

/**
 * Renders delete gateway confirmation modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
const ConfirmDeleteGatewayWindow = (props) => {
  const {
    handleOnClose,
    modal: { data, opened },
    modalID,
    isLoading,
    handleSubmit,
    reset
  } = props;

  const equalToGatewayId = useCallback((value) => (value && value === data?.sm_id ? undefined : 'confirmDeletingError'), [data?.sm_id]);

  useEffect(() => {
    if (!opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('confirmDeleting')}</h5>
      </div>
      {isLoading ? (<div className="installedsm-delete-prealoder-container"><Preloader /></div>) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation confirm-deleting-text">{i18n.t('confirmDeletingDesc')}</div>
            <form
              id="confirmDeleteGatewayForm"
              className="confirmation-field"
              onSubmit={handleSubmit}
            >
              <Field
                name="gatewayId"
                label={i18n.t('smidLabel')}
                className="m-input"
                component={CustomTextInput}
                validate={[required, equalToGatewayId]}
              />
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              type="submit"
              form="confirmDeleteGatewayForm"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              {` ${i18n.t('confirmBtn')}`}
            </button>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

ConfirmDeleteGatewayWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool
};

ConfirmDeleteGatewayWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const form = reduxForm({
  form: 'confirmDeleteGatewayForm'
})(ConfirmDeleteGatewayWindow);

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: confirmRemoveGatewayModalSelector
});

export default connect(mapStateToProps)(form);
