import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import Preloader from '../../components/Preloader';
import './index.css';
import { isLoadingSelector, removeDataModalSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';
/**
 * Modal window for confirm deleting supports.
 * @memberof module:SupportsTable
 */
class DeleteSupportWindow extends React.Component {
  render() {
    const
      {
        onArchive,
        handleOnClose,
        onSubmit,
        modal,
        modalID,
        isLoading
      } = this.props;

    const data = modal ? modal.data : {};
    return (
      <ModalWindow
        modalID={modalID}
      >
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('delSupport')}</h5>
        </div>
        {isLoading ? <div className="support-delete-preload-container"><Preloader /></div> : (
          <div>
            <div className="modal-body">
              <div className="text-confirmation">{i18n.t('confirmDelSupport')}</div>
            </div>
            <div className="modal-footer justify-content-between">
              <CancelButton onClickHandler={handleOnClose} />
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={onSubmit.bind(null, { id: data.id })}
              >
                <i className="fa fa-trash" />
                {' '}
                {i18n.t('delete')}
              </button>
              <button
                type="button"
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
                onClick={onArchive.bind(null, { id: data.id })}
              >
                <i className="fa fa-archive" />
                {i18n.t('archive')}
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }
}

DeleteSupportWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  onArchive: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DeleteSupportWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingSelector,
  modal: removeDataModalSelector
});

export default connect(mapStateToProps)(DeleteSupportWindow);
