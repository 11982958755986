export const DATA_LIST_SEND_REQUEST = 'DATA_LIST_SEND_REQUEST';
export const DATA_LIST_RELOAD_DATA = 'DATA_LIST_RELOAD_DATA';
export const DATA_LIST_RECEIVED = 'DATA_LIST_RECEIVED';
export const DATA_LIST_GOT_ERROR = 'DATA_LIST_GOT_ERROR';
export const DATA_LIST_CLEAN = 'DATA_LIST_CLEAN';
export const DATA_LIST_UPDATE_ROW = 'DATA_LIST_UPDATE_ROW';
export const CHANGE_SUPPORT_CONTRACT_USERS = 'CHANGE_SUPPORT_CONTRACT_USERS';
export const CHANGE_INSTALLATION_COMPLETED_USERS = 'CHANGE_INSTALLATION_COMPLETED_USERS';
export const RESET_USERS_FILTERS = 'RESET_USERS_FILTERS';
export const ATTACH_SUB_SENSORS = 'ATTACH_SUB_SENSORS';
