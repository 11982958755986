import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tooltip from '../../../components/Tooltip';
import ExpanderSide from './ExpanderSide';
import { monitoringSendApplyWithFields, clearPropsFromFields } from '../actions';
import '../index.css';
import { DATA_LIST_ID, DATA_LIST_URL, PRIORITY_ARR, WORK_STATUS_ARR } from '../constants';
import i18n from '../../../i18n';
import {
  allDataSelector,
  assignSelector,
  choosedCompanySelector,
  choosedEmployeesSelector,
  prioritySelector,
  selectedTicketsSelector,
  workStatusSelector
} from './selectors';
import analytics from '../../../analytics';
import getEventObj, { ERRORMONITORING_TICKETS } from '../../../analytics/events';

/**
 * Renders changer side with apply button for Monitoring page
 * @memberof module:Monitoring
 */

class Changer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayToChange: [],
      objToChange: {}
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedTickets, clearPropsFromFields: clearApplyFields } = this.props;

    if (!selectedTickets.length && prevProps.selectedTickets.length !== selectedTickets.length) {
      clearApplyFields();
    }
  }

  handleSubmit = () => {
    const { monitoringSendApplyWithFields: monitoringSendApplyWithFieldsProps } = this.props;

    analytics.sendEvent(getEventObj(ERRORMONITORING_TICKETS));
    monitoringSendApplyWithFieldsProps(DATA_LIST_URL, DATA_LIST_ID, this.state.objToChange);
  };

  /**
   * Format list of employee to send props for ExpanderSides component
   * @param {array} choosedCompany array of companies id which checked in table
   * @param {object} choosedEmployees object with checked company info where we can take all employees
   */

  formatListOfEmployee = (choosedCompany, choosedEmployees) => {
    const { allData } = this.props;
    this.choosedList = [{ key: 'aaaabbbbccccddddeeeeffff', value: 'noUser' }]; // default key from back-end part for set noUser

    if (choosedCompany.length === 1) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce(
        (val, el) => (el[0] === choosedCompany[0] ? el[1].data : val),
        ''
      );

      if (allEmployeesOfCheckUser) {
        // add chosen Company Admin in options list
        const company = allData.find((el) => el?.company?._id && el.company._id === choosedCompany[0])?.company;

        if (company?.admin?._id && company?.name) {
          this.choosedList.push({
            key: company.admin._id,
            value: `Admin ${company.name}`
          });
        }

        // add all users of chosen field in options list
        const assignedField = allEmployeesOfCheckUser.map((el) => ({
          key: el._id,
          value: `${el.first_name} ${el.last_name}`
        }));
        this.choosedList.push(...assignedField);
      }
    }
  };

  /**
   * Set choosed item from field to state.
   */

  setFields = () => {
    const { assign, workStatus, priority, selectedTickets } = this.props;

    const arr = [
      { assigned: assign?.value },
      { status: workStatus?.value },
      { priority: priority?.value }
    ];
    const arrFiltered = arr.filter((el) => Object.values(el).filter(Boolean).length);
    const data = arrFiltered.reduce((obj, item) => ({ ...obj, ...item }), {});

    const objToChange = {};
    objToChange.targets = selectedTickets;
    objToChange.values = data;

    this.setState({ arrayToChange: arrFiltered, objToChange });
  };

  render() {
    const { choosedCompany, choosedEmployees, selectedTickets } = this.props;

    const disabledButton = this.state.arrayToChange.length < 1;

    if (choosedCompany) {
      this.formatListOfEmployee(choosedCompany, choosedEmployees);
    }

    return (
      <>
        {selectedTickets.length ? (
          <div className="row apply-monitoring-container">
            <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
              <div className="row align-items-center">
                <span>
                  {i18n.t('assigned')}
                  :
                </span>
                <ExpanderSide
                  id="monitoring_expander_filter-reg"
                  defaultValue={i18n.t('selectAssign')}
                  onChangeField={this.setFields}
                  choosingList={this.choosedList}
                  fieldName="assignField"
                  listID={DATA_LIST_ID}
                />
              </div>
            </div>
            <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
              <div className="row align-items-center">
                <span>
                  {i18n.t('priority')}
                  :
                </span>
                <ExpanderSide
                  id="monitoring_expander_filter-reg"
                  defaultValue={i18n.t('selectPriority')}
                  choosingList={PRIORITY_ARR}
                  onChangeField={this.setFields}
                  fieldName="priorityField"
                  listID={DATA_LIST_ID}
                />
              </div>
            </div>
            <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
              <div className="row align-items-center">
                <span>
                  {i18n.t('workStatusChangerLabel')}
                  :
                </span>
                <ExpanderSide
                  id="monitoring_expander_filter-reg"
                  defaultValue={i18n.t('workStatusChangerPlaceholder')}
                  choosingList={WORK_STATUS_ARR}
                  onChangeField={this.setFields}
                  fieldName="workStatusField"
                  listID={DATA_LIST_ID}
                />
              </div>
            </div>
            <div className="col-xl-3 col-12 m--margin-top-15">
              <div className="row justify-content-end align-items-center">
                <Tooltip
                  title={i18n.t('monitoringToolTip')}
                  placement="right-start"
                  style={{ fontSize: 20 }}
                />
                <button
                  type="button"
                  className="btn m-btn--pill m-btn--air btn-monitoring"
                  onClick={this.handleSubmit}
                  disabled={disabledButton}
                >
                  <i className="fa fa-paper-plane mr-2" />
                  {i18n.t('apply')}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

Changer.propTypes = {
  monitoringSendApplyWithFields: PropTypes.func.isRequired,
  choosedCompany: PropTypes.instanceOf(Array),
  priority: PropTypes.instanceOf(Object),
  assign: PropTypes.instanceOf(Object),
  choosedEmployees: PropTypes.instanceOf(Object),
  workStatus: PropTypes.instanceOf(Object),
  selectedTickets: PropTypes.instanceOf(Array),
  allData: PropTypes.instanceOf(Array),
  clearPropsFromFields: PropTypes.func.isRequired
};

Changer.defaultProps = {
  priority: {},
  choosedCompany: [],
  choosedEmployees: {},
  assign: {},
  workStatus: {},
  selectedTickets: [],
  allData: []
};

const mapStateToProps = createStructuredSelector({
  priority: prioritySelector,
  assign: assignSelector,
  workStatus: workStatusSelector,
  choosedEmployees: choosedEmployeesSelector,
  choosedCompany: choosedCompanySelector,
  selectedTickets: selectedTicketsSelector,
  allData: allDataSelector
});

export default connect(mapStateToProps, {
  monitoringSendApplyWithFields,
  clearPropsFromFields
})(Changer);
