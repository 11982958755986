/**
 * Action statuses
 * @ignore
 */
export const ON = 'on';
export const OFF = 'off';

/**
 * List of days name
 * @ignore
 */
export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const SUNDAY = 'sunday';

/**
 * List of errors
 * @ignore
 */
export const SWITCHING_ACTION_ERROR = 'switchActionError';
export const SELECT_DAYS_ERROR = 'selectDaysError';
export const ERROR_FROM_TO_ACTION = 'errorFromToAction';
export const ERROR_TIMER_PICKER = 'errorTimerPicker';

/**
 * List of placeholders
 * @ignore
 */
export const SELECT_DAYS = 'selectDays';
export const SELECT_TIME = 'selectTime';
export const SELECT_ACTION = 'selectAction';

/**
 * List of useful constants for SwitchingTimes ReduxFormField
 * @ignore
 */
export const SELECT_ALL = 'selectAllSwitchingDays';
export const SWITCHING_TIMES_TOOLTIP = 'switchingTimesTooltip';
export const SWITCHING_TIMES_LABEL = 'Switching Times';
