const formatDateTime = (dtString) => {
  if (!dtString) return '';
  const dateTime = new Date(dtString);
  const day = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();

  return `${day}.${month}.${year}`;
};

export default formatDateTime;
