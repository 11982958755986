import React, { memo } from 'react';
import PropTypes from 'prop-types';


const ShowErrorValidation = memo((props) => {
  const { textOfError, validations } = props;
  return (
    <div className="time-picker-warning-container">
      {validations && (
      <div className="inputCustomWarning">
        {textOfError}
      </div>
      )}
    </div>
  );
});

ShowErrorValidation.propTypes = {
  validations: PropTypes.bool.isRequired,
  textOfError: PropTypes.string.isRequired
};

export default ShowErrorValidation;
