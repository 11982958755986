import * as actionTypes from './constants';

export function pvInstallersSendRequest(method, url, config, onResult) {
  return {
    type: actionTypes.PV_INSTALLERS_SEND_REQUEST,
    payload: {
      method,
      url,
      config,
      onResult
    }
  };
}
