import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  required, equals, notEqualValue, passwordAdvanced
} from '../validation';
import CustomTextInput from '../../components/ReduxFormFields/CustomTextInput';
import ModalWindow from '../ModalWindow';
import i18n from '../../i18n';
import Preloader from '../../components/Preloader';
import { changePasswordSelector, modalIsLoadingSelector } from './selectors';
import CancelButton from '../../components/UIKit/CancelButton';


const validateConfirmPass = equals('password');
const validateOldPassword = notEqualValue('old_password', 'newOldPass');

/**
 * ChangePassWindow form id
 */
const changePassWindowId = 'changePassWindow';

/**
 * Modal window for changin password from edit profile page.
 * Only for himself.
 * @memberof module:SettingProfile
 */
const ChangePassWindow = (props) => {
  const { handleOnClose, handleSubmit, modalID, pristine, isLoading, modal, reset } = props;

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changePass')}</h5>
      </div>
      {isLoading ? <div className="preloader-container"><Preloader /></div> : (
        <>
          <div className="modal-body">
            <form
              id={changePassWindowId}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="old_password"
                  component={CustomTextInput}
                  label={i18n.t('reqOldPass')}
                  className="m-input"
                  type="password"
                  autoComplete="off"
                  validate={[required]}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="password"
                  component={CustomTextInput}
                  label={i18n.t('reqNewPass')}
                  className="m-input"
                  type="password"
                  autoComplete="off"
                  preventAuto
                  validate={[required, passwordAdvanced]}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="passwordConfirmation"
                  component={CustomTextInput}
                  label={i18n.t('repReqNewPass')}
                  type="password"
                  className="m-input"
                  autoComplete="off"
                  validate={[required, passwordAdvanced, validateConfirmPass, validateOldPassword]}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <button
              form={changePassWindowId}
              disabled={pristine}
              type="submit"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav"
            >
              {i18n.t('changeBtn')}
            </button>
          </div>
        </>
      )}

    </ModalWindow>
  );
};

ChangePassWindow.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  modal: PropTypes.instanceOf(Object),
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

ChangePassWindow.defaultProps = {
  modal: {
    data: {}
  },
  isLoading: false
};

const form = reduxForm({
  form: 'changePassForm'
})(ChangePassWindow);

const mapStateToProps = createStructuredSelector({
  modal: changePasswordSelector,
  isLoading: modalIsLoadingSelector
});

export default connect(mapStateToProps)(form);
