import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import PropTypes from 'prop-types';

const defaultImageName = 'english';
const languageImagesMap = {
  deutsch: 'deutsch',
  danish: 'danish',
  english: defaultImageName
};

const DashboardInfo = ({ goBack: goBackfromProps, i18n }) => (
  <InfoBlock language={languageImagesMap[i18n.language] || defaultImageName}>
    <div className="btn-upd-dashb-cont">
      <div className="btn-upd-dashb" style={{ float: 'right' }}>
        <button
          type="button"
          onClick={goBackfromProps}
        >
          {i18n.t('back')}
        </button>
      </div>
    </div>
  </InfoBlock>
);

const InfoBlock = styled.div`
  background: url(/../images/dashboard-info/${({ language }) => language}.png) no-repeat center;
  width: 100%;
  height: 100%;
  background-size: contain;
  position: absolute;
  bottom: 0;
`;

InfoBlock.propTypes = {
  language: PropTypes.string.isRequired
};

DashboardInfo.propTypes = {
  goBack: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired
};

export default connect(null, { goBack })(DashboardInfo);
