import * as actionTypes from './constants';

export function getUserInfo(userId) {
  return {
    type: actionTypes.GET_USER,
    payload: {
      userId
    }
  };
}

export function getIsSupportContractFlagAllowedToBeChanged(userId) {
  return {
    type: actionTypes.IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED,
    payload: {
      userId
    }
  };
}

export function receiveIsSupportContractFlagAllowedToBeChanged(data) {
  return {
    type: actionTypes.RECEIVE_IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED,
    payload: {
      data
    }
  };
}

export function receiveUserSubscription(data) {
  return {
    type: actionTypes.RECEIVE_USER_SUBSCRIPTION,
    payload: {
      data
    }
  };
}

export function resetSubscription() {
  return {
    type: actionTypes.RESET_SUBSCRIPTION
  };
}

export function receiveUserInfo(data) {
  return {
    type: actionTypes.RECEIVE_USER,
    payload: {
      data
    }
  };
}

export function cancelUserInfo(message) {
  return {
    type: actionTypes.CANCEL_USER,
    payload: {
      message
    }
  };
}

export function changePriority(url) {
  return {
    type: actionTypes.CHANGE_PRIORITY,
    payload: {
      url
    }
  };
}

export function cancelChangePriority(message) {
  return {
    type: actionTypes.CHANGE_PRIORITY_CANCEL,
    payload: {
      message
    }
  };
}

export function changePrioritySuccess() {
  return {
    type: actionTypes.CHANGE_PRIORITY_SUCCESS
  };
}

export function devicesGetTypesAndNames(deviceType, cb) {
  return {
    type: actionTypes.DEVICES_GET_TYPES_AND_NAMES,
    payload: {
      deviceType,
      cb
    }
  };
}

export function devicesSaveTypesAndNames(data) {
  return {
    type: actionTypes.DEVICES_SAVE_TYPES_AND_NAMES,
    payload: {
      data
    }
  };
}

export function scanIP(gateway, cb, device) {
  return {
    type: actionTypes.SCAN_IP,
    payload: {
      gateway,
      cb,
      device
    }
  };
}

export function getListOfIP(gateway) {
  return {
    type: actionTypes.GET_LIST_OF_IP,
    payload: {
      gateway
    }
  };
}

export function saveIP(ips) {
  return {
    type: actionTypes.SAVE_IPS,
    payload: {
      ips
    }
  };
}

export function removeIP(ips) {
  return {
    type: actionTypes.REMOVE_IPS,
    payload: {
      ips
    }
  };
}

export function userDataSendRequest(method, url, config, onResult, modalID) {
  return {
    type: actionTypes.USER_DATA_SEND_REQUEST,
    payload: {
      method,
      url,
      config,
      onResult,
      modalID
    }
  };
}

export function changeSupportContractsRequest(userId, supportContractFlag) {
  return {
    type: actionTypes.CHANGE_SUPPORT_CONTRACTS,
    payload: {
      userId,
      supportContractFlag
    }
  };
}

export function changeInstallationFinishedRequest(gatewayId, isInstallationCompleted) {
  return {
    type: actionTypes.CHANGE_INSTALLATION_FINISHED,
    payload: {
      gatewayId,
      isInstallationCompleted
    }
  };
}

export function updateLoadManagement(loadManagement, houseFuse, myRoleType, userId) {
  return {
    type: actionTypes.UPDATE_LOAD_MANAGEMENT,
    payload: { loadManagement, houseFuse, myRoleType, userId }
  };
}

export function fetchLastSensorsData({ myRoleType, gatewayId }) {
  return {
    type: actionTypes.FETCH_LAST_SENSORS_DATA,
    payload: {
      myRoleType,
      gatewayId
    }
  };
}

export function lastSensorsDataReceived({ data }) {
  return {
    type: actionTypes.LAST_SENSORS_DATA_RECEIVED,
    payload: {
      data
    }
  };
}

export function getUserSubscription(userId) {
  return {
    type: actionTypes.GET_USER_SUBSCRIPTION,
    payload: {
      userId
    }
  };
}

export function clearUserData() {
  return {
    type: actionTypes.CLEAR_USER_DATA
  };
}
