import * as actionTypes from './constants';

const INITIAL_STATE = {
  user: {},
  isSupportContractFlagAllowedToBeChanged: true,
  lastSensorsData: []
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case actionTypes.RECEIVE_USER:
      return {
        ...state,
        user: action.payload.data
      };
    case actionTypes.DEVICES_SAVE_TYPES_AND_NAMES:
      return {
        ...state,
        devicesList: action.payload.data
      };
    case actionTypes.DEVICES_TAGS_RECIVED: {
      state.deviceTags = action.payload.data;
      return state;
    }
    case actionTypes.DEVICES_TAGS_ADD: {
      state.deviceTags = { ...state.deviceTags };
      const { newTag } = action.payload;
      state.deviceTags.push(newTag);
      return state;
    }
    case actionTypes.SAVE_IPS:
      return {
        ...state,
        ips: action.payload.ips,
        load: false
      };
    case actionTypes.GET_LIST_OF_IP:
      return {
        ...state,
        load: true
      };
    case actionTypes.REMOVE_IPS:
      return {
        ...state,
        ips: {}
      };
    case actionTypes.CHANGE_SUPPORT_CONTRACTS:
      return {
        ...state,
        userId: action.payload.userId,
        supportContract: action.payload.supportContract
      };
    case actionTypes.CHANGE_INSTALLATION_FINISHED:
      return {
        ...state,
        userId: action.payload.userId,
        isInstallationCompleted: action.payload.isInstallationCompleted
      };
    case actionTypes.RECEIVE_IS_SUPPORT_CONTACT_FLAG_ALLOWED_TO_BE_CHANGED:
      return {
        ...state,
        isSupportContractFlagAllowedToBeChanged: action.payload.data
      };
    case actionTypes.RECEIVE_USER_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: action.payload.data
      };
    case actionTypes.LAST_SENSORS_DATA_RECEIVED:
      return {
        ...state,
        lastSensorsData: action.payload.data
      };
    case actionTypes.CLEAR_USER_DATA:
      return {
        ...state,
        isSupportContractFlagAllowedToBeChanged: true,
        user: {}
      };
    case actionTypes.RESET_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: {}
      };
    default:
      return state;
  }
};
