import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import carChargingSystemIcon from '../../../../../assets/images/svg/car-charging-system.svg';

const CarChargingSystemTooltip = (props) => {
  const { consumedForLast24h, convertedCurrentPower } = props;

  return (
    <>
      <img src={carChargingSystemIcon} alt="Car Charging System Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        {Number.isFinite(consumedForLast24h) && <span>{i18n.t('last24h')}</span>}
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        {Number.isFinite(consumedForLast24h) && (
        <span>
          {consumedForLast24h}
          {i18n.t('kwh')}
        </span>
        )}
      </div>
    </>
  );
};

CarChargingSystemTooltip.propTypes = {
  consumedForLast24h: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

CarChargingSystemTooltip.defaultProps = {
  consumedForLast24h: undefined
};

export default CarChargingSystemTooltip;
