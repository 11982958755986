import React from 'react';

import PropTypes from 'prop-types';
import CustomTextInput from './index';
import { checkNotNumber } from '../../../containers/validation';


const isDotKeyCode = (keyCode) => keyCode === 190 || keyCode === 110;

const CustomTextInputHoc = (props) => {
  const { isInteger, isFloat, ...rest } = props;

  const onKeyDown = (e) => {
    const checkIsInteger = isInteger && checkNotNumber(e.key);
    const checkIsFloat = isFloat && (!isDotKeyCode(e.keyCode) && checkNotNumber(e.key));
    if (checkIsInteger || checkIsFloat) {
      e.preventDefault();
    }
  };

  return <CustomTextInput {...rest} onKeyDown={onKeyDown} />;
};

CustomTextInputHoc.propTypes = {
  isFloat: PropTypes.bool,
  isInteger: PropTypes.bool
};

CustomTextInputHoc.defaultProps = {
  isFloat: false,
  isInteger: false
};

export default CustomTextInputHoc;
