import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import PuffLoader from 'react-spinners/PuffLoader';
import { DocumentationTableHeader, UploadModal, LImitSizeModal, DeleteFilesModal } from './components';
import TopBar from '../../components/TopBar';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import i18n from '../../i18n';
import { getColumns } from './columns';
import { filesSelector, selectedFilesSelector, downloadModalFileIdSelector, filesLoadingSelector } from './store/selectors';
import { setSelectedFiles, getDocumentationFiles, downloadFiles } from './store/actions';
import { closeModalWindow, openModalWindow } from '../ModalWindow/actions';
import { UPLOAD_MODAL_ID, DELETE_FILES_MODAL_ID } from './constants';

import './index.scss';


const CheckboxTable = checkboxHOC(ReactTable);

const Documentation = ({
  files,
  selectedRowsIds,
  match,
  myRoleType,
  setSelectedFiles: setSelectedFilesAction,
  downloadFilesId,
  closeModalWindow: closeModalWindowAction,
  getDocumentationFiles: getDocumentationFilesAction,
  isFilesLoading,
  downloadFiles: downloadFilesAction,
  openModalWindow: openModalWindowAction
}) => {
  const { userId } = match.params;
  const [selectedAll, setSelectedAll] = useState(false);

  const columns = useMemo(() => getColumns(myRoleType), [myRoleType, userId]); // eslint-disable-line

  const crumbs = myRoleType === 'end_user' ? [
    { name: i18n.t('settings'), url: '/my-devices' },
    { name: i18n.t('documentationTitle') }
  ] : [
    { name: i18n.t('SMUs'), url: '/users' },
    { name: i18n.t('settings'), url: `/users/${userId}` },
    { name: i18n.t('documentationTitle') }
  ];
  const checkSelected = (key) => selectedRowsIds.includes(key);

  const handlerToggleAll = () => {
    if (selectedAll) setSelectedFilesAction([]);
    else setSelectedFilesAction(files.map(({ _id }) => _id));
    setSelectedAll(!selectedAll);
  };

  const handlerResetSelected = () => {
    setSelectedFilesAction([]);
    setSelectedAll(false);
  };

  const handlerToggleSelection = (key) => {
    const [, id] = key.split('-');
    const isSelected = checkSelected(id);

    let data = [];
    if (isSelected) data = [...selectedRowsIds.filter((item) => item !== id)];
    else data = [...selectedRowsIds, id];
    setSelectedFilesAction(data);

    if (data.length === files.length) setSelectedAll(true);
    else setSelectedAll(false);
  };

  const checkboxProps = {
    columns,
    data: files,
    className: '-highlight m-datatable__table',
    selectAll: selectedAll,
    selectType: 'checkbox',
    isSelected: checkSelected,
    toggleSelection: handlerToggleSelection,
    toggleAll: handlerToggleAll,
    getTheadThProps: () => ({ className: `checkbox-react-table-all ${files.length ? '' : 'selected-all-hidden'}` }),
    getTdProps: () => ({ className: 'checkbox-react-table' }),
    getTrProps: (allComponentProps, currentProps) => {
      if (!allComponentProps || !currentProps) return {};
      const isSelected = checkSelected(currentProps.original._id);

      return {
        style: {
          backgroundColor: isSelected ? '#33363e' : 'inherit'
        }
      };
    },
    noDataText: i18n.t('noFilesFound'),
    pageSize: 1000
  };

  const handlerDeleteFiles = () => {
    openModalWindowAction(DELETE_FILES_MODAL_ID, { ids: selectedRowsIds });
  };

  useEffect(() => {
    if (downloadFilesId) {
      downloadFilesAction([downloadFilesId]);
      closeModalWindowAction('downloadFile');
    }
  }, [downloadFilesId, userId]);// eslint-disable-line

  useEffect(() => {
    if (userId) getDocumentationFilesAction(userId);
  }, [userId]);// eslint-disable-line

  useEffect(() => {
    const isResetSelectedAll = !selectedRowsIds.length && selectedAll;
    if (isResetSelectedAll) setSelectedAll(false);
  }, [files, selectedRowsIds]);// eslint-disable-line

  return (
    <>
      <TopBar updateButton showNavigation />
      {isFilesLoading ? (
        <div className="d-flex position-absolute h-100 w-100 top-0 left-0 justify-content-center align-items-center">
          <PuffLoader
            size={50}
            color="#f7bd27"
            speedMultiplier={3}
          />
        </div>
      )
        : (
          <div className="main-table-container documentation-wrapper">
            <UploadModal userId={userId} modalID={UPLOAD_MODAL_ID} />
            <LImitSizeModal />
            <DeleteFilesModal />
            <Breadcrumbs crumbs={crumbs} />
            <DocumentationTableHeader
              filesCount={files.length}
              selectedRowsIds={selectedRowsIds}
              resetSelectedRows={handlerResetSelected}
              deleteFiles={handlerDeleteFiles}
              downloadFiles={downloadFilesAction}
              myRoleType={myRoleType}
            />
            <CheckboxTable {...checkboxProps} />
          </div>
        )
      }
    </>
  );
};


Documentation.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  selectedRowsIds: PropTypes.instanceOf(Array).isRequired,
  files: PropTypes.instanceOf(Array).isRequired,
  myRoleType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  downloadFilesId: PropTypes.string,
  closeModalWindow: PropTypes.func.isRequired,
  getDocumentationFiles: PropTypes.func.isRequired,
  isFilesLoading: PropTypes.bool.isRequired,
  downloadFiles: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired
};

Documentation.defaultProps = {
  downloadFilesId: null
};


const mapStateToProps = createStructuredSelector({
  files: filesSelector,
  selectedRowsIds: selectedFilesSelector,
  downloadFilesId: downloadModalFileIdSelector,
  isFilesLoading: filesLoadingSelector
});
export default connect(mapStateToProps, {
  setSelectedFiles,
  closeModalWindow,
  getDocumentationFiles,
  downloadFiles,
  openModalWindow
})(Documentation);
