import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ForgotPassForm from './ForgotPassForm';
import { forgotPasswordSend } from './actions';
import '../../App.css';
import { transformLanguageForAPI } from '../../i18n';
import {
  counterSelector,
  emailSelector,
  isLoadingSelector,
  sendSuccessfullSelector,
  verifyEmailSelector
} from './selectors';
/**
 * @module ForgotPass
 */

/**
 * @description renders page to restore password
 * @memberof module:ForgotPass
 * @extends Component
 */
const ForgotPass = (props) => {
  const {
    forgotPasswordSend: forgotPass,
    isLoading,
    sendSuccessfull,
    email,
    verifyEmail,
    counter,
    i18n,
    t
  } = props;

  const onSubmit = (values) => {
    forgotPass(counter, {
      ...values,
      language: transformLanguageForAPI(i18n.language)
    });
  };

  const onReSubmit = () => {
    forgotPass(counter, {
      email,
      language: transformLanguageForAPI(i18n.language)
    });
  };

  return (
    <ForgotPassForm
      onSubmit={onSubmit}
      onReSubmit={onReSubmit}
      email={email}
      verifyEmail={verifyEmail}
      sendSuccessfull={sendSuccessfull}
      counter={counter}
      isLoading={isLoading}
      t={t}
    />
  );
};

ForgotPass.propTypes = {
  forgotPasswordSend: PropTypes.func.isRequired,
  sendSuccessfull: PropTypes.func,
  email: PropTypes.string,
  verifyEmail: PropTypes.bool,
  counter: PropTypes.number.isRequired,
  i18n: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  t: PropTypes.instanceOf(Object)
};

ForgotPass.defaultProps = {
  sendSuccessfull: undefined,
  email: undefined,
  verifyEmail: undefined,
  i18n: {},
  isLoading: false,
  t: undefined
};

const mapStateToProps = createStructuredSelector({
  sendSuccessfull: sendSuccessfullSelector,
  email: emailSelector,
  verifyEmail: verifyEmailSelector,
  counter: counterSelector,
  isLoading: isLoadingSelector
});

export default connect(mapStateToProps, {
  forgotPasswordSend
})(ForgotPass);
