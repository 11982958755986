import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import DataList from '../../../DataList';

import columns from '../../columns';

import { SENSORS_LIST_ID } from '../../../UserDetails/constants';

import i18n from '../../../../i18n';

/**
/**
 * @module DevicesTable
 */
/**
 * Devices tab from DevicesTable
 * @memberof module:DevicesTable
 */
const DevicesTab = (props) => {
  const {
    userId,
    myRoleType,
    openAddDevices,
    defaultParams,
    transformResponse,
    devicesUrl,
    total,
    sensorsData
  } = props;

  const notAllowedRoles = ['viewer', 'pv_installer_employee_read_only'];

  return (
    <>
      {!notAllowedRoles.includes(myRoleType) && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddDevices}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addDevicebtn')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataList
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columns(myRoleType, userId, 'devices', total, sensorsData),
            showPagination: false
          }}
        />
      </div>
    </>
  );
};

DevicesTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  sensorsData: PropTypes.instanceOf(Object),
  devicesUrl: PropTypes.string.isRequired,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  openAddDevices: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired
};

DevicesTab.defaultProps = {
  userId: undefined,
  myRoleType: undefined,
  sensorsData: undefined,
  total: undefined
};

export default DevicesTab;
