import ReactGA from 'react-ga4';
import { REACT_GA_TRACKING_ID } from '../config';

function init() {
  // Enable debug mode on the local development environment
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(REACT_GA_TRACKING_ID, { debug: true });
}

/**
 * Event - Add custom tracking event
 * @param {string} payload.nameEvent
 * @param {Object} payload.params
 */
function sendEvent(payload) {
  if (payload) {
    ReactGA.event(payload?.nameEvent, { ...payload?.params });
  }
}

function sendPageview(path) {
  ReactGA.send({ hitType: 'pageview', page: path });
}

export default {
  init,
  sendEvent,
  sendPageview
};
