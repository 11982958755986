import * as actionTypes from './constants';

export function chartWillChangeScale(scaleType) {
  return {
    type: actionTypes.CHART_WILL_CHANGE_SCALE,
    payload: {
      scaleType
    }
  };
}

export function updateChart(userSettings, myself) {
  return {
    type: actionTypes.CHART_WILL_UPDATE,
    payload: {
      userSettings,
      myself
    }
  };
}

export function dataReceived(chartData) {
  return {
    type: actionTypes.CHART_DATA_RECEIVED,
    payload: {
      chartData
    }
  };
}

export function clearDashboardData() {
  return {
    type: actionTypes.CLEAR_DASHBOARD_DATA
  };
}

export function dashboardReloadChartSettings({ chart_settings, active_devices }) {
  return {
    type: actionTypes.DASHBOARD_RELOAD_CHART_SETTINGS,
    payload: {
      chart_settings,
      active_devices
    }
  };
}

export function chartInitUser({
  gatewayID, myself, successfulCB, first_name, last_name, chart_settings, active_devices, groupPixelWidth, selectedConfig, demo, timezone
}, noChangeScale) {
  return {
    type: actionTypes.CHART_INIT_USER,
    payload: {
      myself,
      gatewayID,
      successfulCB,
      first_name,
      last_name,
      chart_settings,
      active_devices,
      groupPixelWidth,
      noChangeScale,
      selectedConfig,
      demo,
      timezone
    }
  };
}

export function chartWillChangeRange(min, max) {
  return {
    type: actionTypes.CHART_WILL_CHANGE_RANGE,
    payload: {
      min,
      max
    }
  };
}

export function chartScaleChanged(from, to, config, response, buffer) {
  const {
    scaleMS, expectedScaleMS = scaleMS, scaleType = '0', chartType, limitsReceived, realTimeData
  } = config;
  const {
    data, x: dataSum, interval: dataInterval, singleInterval: dataSingleInterval, active_devices
  } = response;
  return {
    type: actionTypes.CHART_SCALE_CHANGED,
    payload: {
      from,
      to,
      scaleMS,
      scaleType,
      expectedScaleMS,
      chartType,
      data,
      limitsReceived,
      dataSum,
      realTimeData,
      dataInterval,
      dataSingleInterval,
      active_devices,
      buffer
    }
  };
}

export function dashboardUpdCurrent(rawSensorData) {
  return {
    type: actionTypes.DASHBOARD_UPD_CURRENT,
    payload: {
      rawSensorData
    }
  };
}

export function chartChangedInRealTime(data, dataSum, from, to, scaleMS, active_devices, buffer) {
  return {
    type: actionTypes.CHART_CHANGED_IN_REALTIME,
    payload: {
      from,
      to,
      scaleMS,
      data,
      dataSum,
      active_devices,
      buffer
    }
  };
}

export function chartDefineBuffer(newBuffer) {
  return {
    type: actionTypes.CHART_DEFINE_BUFFER,
    payload: {
      newBuffer
    }
  };
}

export function dashboardGetInitialCurrentValues({ myself, gatewayId }) { // epic
  return {
    type: actionTypes.DASHBOARD_GET_INITIAL_CURRENT_VALUES,
    payload: {
      myself,
      gatewayId
    }
  };
}

export function dashboardSetInitialCurrentValues(data) { // reducer
  return {
    type: actionTypes.DASHBOARD_SET_INITIAL_CURRENT_VALUES,
    payload: {
      data
    }
  };
}

export function toggleShowDevicesCurvesSwitch() { // reducer
  return {
    type: actionTypes.TOGGLE_SHOW_DEVICES_CURVES_SWITCH
  };
}
