import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { delay, map, switchMap } from 'rxjs/operators';

import { ADD_NOTIFICATION } from './constants';
import { deleteNotification } from './actions';

function addNotification(action$) {
  return action$.pipe(
    ofType(ADD_NOTIFICATION),
    map((action) => action.id),
    delay(5000),
    switchMap((id) => of(deleteNotification(id)))
  );
}

export default combineEpics(
  addNotification
);
