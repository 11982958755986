import * as actionTypes from './constants';

const initialState = {
  dashboard: {
    activeSlide: 0
  },
  sm_users: {
    queries: {
      _sort: 'sm_id:asc',
      search: '',
      supportContractFlag: undefined,
      isInstallationCompleted: undefined,
      _limit: 10,
      _start: 0
    },
    columns: {
      plant: false,
      last_login: false,
      installationDate: false
    },
    width: {
      sm_id: 180,
      last_name: 180,
      signal: 170,
      plant: 160,
      hasErrors: 100,
      company: 180,
      status: 169, // ?? 169
      last_login: 115,
      installationDate: 165,
      buttons: 120
    }
  },
  monitoring: {
    queries: {
      _sort: 'lastErrorDate:asc',
      _limit: 10,
      _start: 0,
      search: undefined,
      done: false,
      isInstallationCompleted: undefined,
      supportContractStatus: undefined,
      status: undefined,
      assigned: undefined,
      companies: undefined
    },
    columns: {
      plant: false,
      last_login: false,
      installationDate: false
    },
    width: {
      signal: 70,
      plant: 160,
      name: 140,
      company: 130,
      createdAt: 130,
      lastErrorDate: 150,
      assigned: 185,
      priority: 110,
      status: 185,
      buttons: 120
    }
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_PAGES_SETTING:
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          ...action.payload.data
        }
      };
    case actionTypes.SET_PAGES_SETTING_QUERIES:
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          queries: {
            ...state[action.payload.page].queries,
            ...action.payload.data
          }
        }
      };
    case actionTypes.SET_PAGES_SETTING_COLUMNS_WIDTH:
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          width: {
            ...state[action.payload.page].width,
            ...action.payload.data
          }
        }
      };
    case actionTypes.SET_PAGES_SETTING_ADDITIONAL_COLUMNS:
      return {
        ...state,
        [action.payload.page]: {
          ...state[action.payload.page],
          columns: {
            ...state[action.payload.page].columns,
            ...action.payload.data
          }
        }
      };

    default:
      return state;
  }
};
