import devices from './devices';
import inverters from './inverters';
import smartMeter from './smartMeter';
import subMeters from './subMeters';
import inputDevices from './inputDevices';
import loadGuard from './loadGuard';

/**
 * Creates columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 * @param {string|number} userId ID of user being viewed
 * @param {string} type Devices type
 * @param {number} total Devices amount
 */
const columns = (myRoleType, userId, type, total, sensorsData) => {
  const columnsTypes = {
    devices: devices(myRoleType, userId, total, sensorsData),
    inverters: inverters(myRoleType, sensorsData),
    'smart-meter': smartMeter(myRoleType, sensorsData),
    'sub-meter': subMeters(myRoleType, sensorsData),
    'input-devices': inputDevices(myRoleType, sensorsData),
    loadguards: loadGuard(myRoleType)
  };
  return columnsTypes[type];
};

export default columns;
