import { omit } from 'lodash';

/**
 * @name omitFakeField
 * @type {Function}
 * @description Will delete the redundant field "fakeField" if it exists, otherwise, return all fields
 */
const omitFakeField = (fields) => {
  if (fields?.data?.fakeField) {
    const newFields = { ...fields, data: { ...omit(fields?.data, ['fakeField']) } };
    return newFields;
  }
  if (fields?.data?.switchingTimes === []) {
    const newFields = { ...fields, data: { ...omit(fields?.data, ['switchingTimes']) } };
    return newFields;
  }

  return fields;
};

export default omitFakeField;
