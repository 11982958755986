import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const TabContent = ({ title }) => (
  <div className="row tabs-cont align-items-center">
    <div className="col-auto mr-auto">
      <div className="m-portlet__head-tools">
        <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links tab">
          <div className="nav-item m-tabs__item">
            <div className="nav-link m-tabs__link">{title}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

TabContent.propTypes = {
  title: PropTypes.string.isRequired
};

export default TabContent;
