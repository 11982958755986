import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import './index.scss';

const PrimaryButton = (props) => {
  const {
    onClickHandler,
    i18nLabel,
    iconClass,
    customButtonClass
  } = props;

  return (
    <button
      type="button"
      className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav${customButtonClass ? ` ${customButtonClass}` : ''}`}
      onClick={onClickHandler}
    >
      <i className={iconClass} />
      {i18n.t(i18nLabel)}
    </button>
  );
};

PrimaryButton.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  i18nLabel: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  customButtonClass: PropTypes.string
};

PrimaryButton.defaultProps = {
  customButtonClass: ''
};

export default PrimaryButton;
