import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'lodash';
import i18n from '../../../i18n';
import { DATA_LIST_ID, DATA_LIST_URL, EMPLOYEE_LIST_ID } from '../../../containers/Monitoring/constants';
import { addChoosedCompany, employeeSendRequest, monitoringSendApplyWithFields } from '../../../containers/Monitoring/actions';
import { allDataSelector, choosedCompanySelector, choosedEmployeesSelector } from '../../../containers/Monitoring/components/selectors';
import analytics from '../../../analytics';
import getEventObj, { ERRORMONITORING_TICKETS } from '../../../analytics/events';

const transformResponse = (data) => {
  const clonedData = clone(data.list);

  return {
    total: data.total,
    data: clonedData
  };
};

const RenderAssignedStatus = (props) => {
  const { row, original } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const currentCompany = useSelector(choosedCompanySelector);
  const currentEmployees = useSelector(choosedEmployeesSelector);
  const allData = useSelector(allDataSelector);
  const assignedUser = row.assigned ? `${row.assigned?.first_name} ${row.assigned?.last_name}` : i18n.t('noAssign');

  useEffect(() => {
    if (open) {
      dispatch(addChoosedCompany([original.company._id], [original.user._id], [original.status]));

      const listURL = '../users/employee';
      const params = {
        _limit: 50,
        _start: 0,
        _sort: 'first_name:asc',
        company: original.company._id
      };

      dispatch(employeeSendRequest(original.company._id, EMPLOYEE_LIST_ID, { listURL, params, transformResponse }, 'employees'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const formatListOfEmployee = (choosedCompany, choosedEmployees) => {
    const choosedList = [{ key: 'aaaabbbbccccddddeeeeffff', value: 'noUser' }]; // default key from back-end part for set noUser

    if (choosedCompany.length === 1) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce(
        (val, el) => (el[0] === choosedCompany[0] ? el[1].data : val),
        ''
      );

      if (allEmployeesOfCheckUser) {
        // add chosen Company Admin in options list
        const company = allData.find((el) => el?.company?._id && el.company._id === choosedCompany[0])?.company;

        if (company?.admin?._id && company?.name) {
          choosedList.push({
            key: company.admin._id,
            value: `Admin ${company.name}`
          });
        }

        // add all users of chosen field in options list
        const assignedField = allEmployeesOfCheckUser.map((el) => ({
          key: el._id,
          value: `${el.first_name} ${el.last_name}`
        }));
        choosedList.push(...assignedField);
      }
    }

    return choosedList;
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const clickHandler = (assigned) => {
    const data = {
      targets: [
        original._id
      ],
      values: {
        assigned
      }
    };
    analytics.sendEvent(getEventObj(ERRORMONITORING_TICKETS));
    dispatch(monitoringSendApplyWithFields(DATA_LIST_URL, DATA_LIST_ID, data));
    setOpen(false);
  };

  return (
    <div className="workStatus-dropdown">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="workStatus-dropdown-btn"
      >
        {assignedUser.length > 16 ? `${assignedUser.slice(0, 16)}...` : assignedUser}
        <i className="fa fa-caret-down" />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {currentCompany && currentEmployees && formatListOfEmployee(currentCompany, currentEmployees).map((assigned) => (
                    <MenuItem key={assigned.key} onClick={clickHandler.bind(null, assigned.key)}>{i18n.t(assigned.value)}</MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

RenderAssignedStatus.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object).isRequired
};

export default RenderAssignedStatus;
